import { useNavigation } from '@react-navigation/native';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { SimpleHeader } from '@components/Headers/SimpleHeader';
import { AddMemberType } from '@components/Invite/SelectMembersList';
import { Box } from '@components/Restyle';
import useMe from '@hooks/useMe';
import useSelectedMembers from '@hooks/useSelectedMembers';
import useUsers from '@hooks/useUsers';
import { AddMembers } from '@screens/Members/AddMembers';
import { defaultTaskRole } from '@utils/tasks';

export const AddEditTaskMembersFromModal = ({ route }) => {
  const navigation = useNavigation();
  const { users } = useUsers();
  const ownerId = route.params.ownerId;
  const [selected, setSelected] = useState([]);
  const { setFieldValue, setTouched, touched, getFieldProps } =
    useFormikContext();
  const { selectedMembers, setSelectedMembers } = useSelectedMembers();
  const [changesMade, setChangesMade] = useState(false);
  const { me } = useMe();
  const { name: fieldName, value } = getFieldProps('users');
  const usersAndMe = users.filter((u) => u.id !== ownerId && u.id !== me.id);

  const setMembersWithRoles = () => {
    const newUsers = selected.map((s) => {
      return {
        ...usersAndMe.find((u) => u.id === s.id),
        role:
          selectedMembers.find((m) => m.id === s.id)?.role || defaultTaskRole,
      };
    });

    setTouched({ ...touched, [fieldName]: true });
    setFieldValue(fieldName, [...newUsers]);
    setSelectedMembers(newUsers as AddMemberType[]);
    setChangesMade(true);
  };

  useEffect(() => {
    setSelected(value.filter((i) => i.id !== ownerId && i.id !== me?.id) || []);
  }, [value]);

  const handleNextPress = () => {
    setMembersWithRoles();
    setSelected([]);
    setChangesMade(false);
    navigation.goBack();
  };

  return (
    <Box style={{ flex: 1, backgroundColor: 'white' }}>
      <SimpleHeader title='Add Members' />
      <AddMembers
        onNextPress={() => {
          changesMade && handleNextPress();
        }}
        updateUsers={(newUsers) => {
          setSelected(newUsers);
          setChangesMade(true);
        }}
        hideTeamTab
        isInviting
        isEdit={true}
        changesMade={changesMade}
        disabled={!selected}
        users={selected}
      />
    </Box>
  );
};
