import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
} from '@react-navigation/material-top-tabs';
import {
  CommonActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { UploadProgressData } from 'expo-file-system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Dimensions,
  Modal,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Alert } from '@components/Alert';
import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import { DateRangePickerIcon } from '@components/DateRangePicker/DateRangePickerIcon.web';
import { DateRange } from '@components/DateRangePicker/DateRangePickerModal.web';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import Popup from '@components/shared/Popup/Popup';
import {
  Document,
  DocumentAttributes,
  DocumentSortOption,
  GetProjectDocument,
  LocalFile,
  Project,
  useAddProjectFilesMutation,
  User,
} from '@graphql/generated';
import useDownloadFiles from '@hooks/useDownloadFiles';
import useFileProcessor from '@hooks/useFileProcessor';
import useMe from '@hooks/useMe';
import { useProjectFromQuery } from '@hooks/useProjectFromQuery';
import { useProjectMembership } from '@hooks/useProjectMembership';
import { FilesStackScreenProps } from '@navigation/files/files-stack';
import { DownloadFiles } from '@screens/Files/DownloadFiles';
import { AllProjectFiles } from '@screens/Projects/AllProjectFiles.web';
import { ProjectMedia } from '@screens/Projects/ProjectMedia.web';
import { ProjectTaskFiles } from '@screens/Projects/ProjectTaskFiles.web';
import theme from '@themes/theme';
import { useFilePicker } from '@utils/filePicker';

export type ProjectFilesTabParamsList = {
  'all-project-files': undefined;
  'project-media': undefined;
  'project-task-files': undefined;
};

export enum FilterType {
  NoFilter = '',
  MyUploads = 'MyUploads',
}

export interface ProjectFilesProps {
  projectId: Project['id'];
  sortBy?: DocumentSortOption;
  ownerId?: User['id'];
  isSelectMode: boolean;
  onSelectedModeChange: (isSelected: boolean) => void;
  selectedList: Array<Document | LocalFile>;
  handelSelectedItem: (item: Document | LocalFile) => void;
  deleteloader?: boolean;
  dateRange?: DateRange;
}

const ProjectFiles: React.FC = () => {
  const navigation = useNavigation();
  const { me } = useMe();
  const { t } = useTranslation('models');
  const { downloadFiles } = useDownloadFiles();
  const insets = useSafeAreaInsets();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [forwardFileLoading, setForwardFileLoading] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<DocumentSortOption>(
    DocumentSortOption.DateDesc
  );
  const [filterBy, setFilterBy] = useState<FilterType>(FilterType.NoFilter);
  const [ownerId, setOwnerId] = useState('');
  const [isFilterOrSort, setIsFilterOrSort] = useState<boolean>(true);
  const screenWidth = Dimensions.get('screen').width;

  const Tab = createMaterialTopTabNavigator<ProjectFilesTabParamsList>();
  const route = useRoute<FilesStackScreenProps<'project-files'>['route']>();

  const { projectId } = route.params;
  const { project } = useProjectFromQuery({
    projectId: projectId || '',
  });
  const { isViewer } = useProjectMembership(project);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<
    Array<Document | LocalFile>
  >([]);
  const [index, setIndex] = useState(0);
  const [showMsg, setShowMsg] = useState(false);

  const [isMedia, setIsMedia] = useState<boolean>(false);
  const [showUpload, setShowUpload] = useState(true);
  const [dateRange, setDateRange] = useState<DateRange>({});
  const MAX_FILE_PHOTO_COUNT = 1000;
  const { launchImageSelection, launchFileSelection } = useFilePicker();

  // const downloadImage = async (uri: string) => {
  //   const filename = uri.split('/').pop();
  //   const localUri = `${FileSystem.cacheDirectory}${filename}`;
  //   const { uri: localFileUri } = await FileSystem.downloadAsync(uri, localUri);
  //   return localFileUri;
  // };

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', (event) => {
      const index = event.data.state.routes[0].state?.index || 0;
      setIndex(index);
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    setIsSelectMode(false);
    setSelectedDocuments([]);
  }, [index]);

  useEffect(() => {
    if (selectedDocuments && selectedDocuments.length == 0) {
      setIsSelectMode(false);
    }
    if (index != 1 || selectedDocuments.length <= 8) {
      setShowMsg(false);
    }
    setForwardFileLoading(false);
  }, [selectedDocuments]);

  const handelSelectedItem = (item: Document | LocalFile) => {
    const item0 = selectedDocuments?.find((item2) => {
      return item2.id == item.id;
    });
    if (item0) {
      const newArray = [...selectedDocuments].filter(
        (item3) => item3.id != item.id
      );
      setSelectedDocuments([...newArray]);
    } else {
      if (selectedDocuments.length < 8) {
        setSelectedDocuments([...selectedDocuments, item]);
      } else {
        Alert.alert('Maximum 8 files can be selected at a time.');
      }
    }
  };

  const filterTitleAndMenuList = () => {
    return (
      <Box
        style={[
          styles.popupBox,
          { top: insets.top + 32, right: theme.spacing.xl },
        ]}>
        <Popup
          width={200}
          title={t('projects.projectDetails.filesWithMedia.filter.title')}
          menuList={[
            {
              name: t(
                'projects.projectDetails.filesWithMedia.filter.myUploads'
              ),
              onSelect: () => {
                if (filterBy === FilterType.MyUploads) {
                  setFilterBy(FilterType.NoFilter);
                  setOwnerId('');
                } else {
                  setFilterBy(FilterType.MyUploads);
                  setOwnerId(me?.id || '');
                }
              },
              isSelected: filterBy === FilterType.MyUploads,
            },
          ]}
          closePopFn={() => setModalOpen(!modalOpen)}
        />
      </Box>
    );
  };
  const sortTitleAndMenuList = () => {
    return (
      <Box
        style={[
          styles.popupBox,
          { top: insets.top + 32, right: theme.spacing.m },
        ]}>
        <Popup
          width={200}
          title={t('projects.projectDetails.filesWithMedia.sort.title')}
          menuList={[
            {
              name: t(
                'projects.projectDetails.filesWithMedia.sort.dateUploaded'
              ),
              onSelect: () => setSortBy(DocumentSortOption.DateDesc),
              isSelected: sortBy === DocumentSortOption.DateDesc,
            },
            {
              name: t('projects.projectDetails.filesWithMedia.sort.name'),
              onSelect: () => setSortBy(DocumentSortOption.NameAsc),
              isSelected: sortBy === DocumentSortOption.NameAsc,
            },
          ]}
          closePopFn={() => setModalOpen(!modalOpen)}
        />
      </Box>
    );
  };

  const { processFiles } = useFileProcessor();

  const [addProjectFiles] = useAddProjectFilesMutation({
    refetchQueries: [
      'listProjectDocuments',
      { query: GetProjectDocument, variables: { id: projectId } },
    ],
  });

  const [_uploadProgress, setUploadProgress] = useState({});
  const callback = (
    documentClientId: string,
    uploadProgress: UploadProgressData
  ) => {
    const progress =
      uploadProgress.totalBytesSent / uploadProgress.totalBytesExpectedToSend;
    setUploadProgress({ ...uploadProgress, [documentClientId]: progress });
  };

  const getPhotos = (imageList: LocalFile[]) => {
    const documents = imageList.map((item, index): Document => {
      const {
        name,
        clientId,
        contentType,
        isImage = false,
        isAudio = false,
        duration = null,
      } = item;
      const documentId = `${projectId}-${index}`;

      return {
        __typename: 'Document',
        id: documentId,
        owner: me!,
        name: name || clientId,
        clientId,
        contentType: contentType,
        isImage,
        createdAt: new Date().toISOString(),
        size: item.size,
        file: {
          ...item,
          __typename: 'File',
          id: documentId,
          cdnBaseUrl: '',
          url: item.url,
        },
        isAudio,
        duration,
        expired: false,
      };
    });

    processFiles(documents, callback).then((response) => {
      projectId &&
        addProjectFiles({
          variables: {
            id: projectId,
            attributes: imageList.map((item): DocumentAttributes => {
              const {
                name,
                clientId,
                contentType,
                isImage = false,
                isAudio = false,
              } = item;
              const { blobId } =
                response.find(
                  (uploadResultItem) => uploadResultItem.clientId === clientId
                ) || {};

              return {
                name,
                contentType,
                isImage,
                isAudio,
                blobId,
                clientId,
              } as DocumentAttributes;
            }),
          },
          onCompleted: () => {
            {
              isMedia &&
                navigation.dispatch(CommonActions.navigate('project-media'));
            }
          },
        });
    });
  };

  const shouldEnableSelectMode = (isSelected: boolean, isViewer: boolean) =>
    isSelected && !isViewer;

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <Box flexDirection='row' alignItems='center' paddingHorizontal='m'>
        <CustomTabBar {...props} spacing={theme.spacing.m} />
        <Box flexDirection='row' alignItems='center'>
          <Box flex={1} alignItems='flex-end' marginRight='s'>
            <DateRangePickerIcon
              variant='s'
              dateRange={dateRange}
              onChange={setDateRange}
            />
          </Box>
        </Box>
        <Icon
          name='Filter'
          variant='s'
          color='textPrimary'
          marginRight='s'
          onPress={() => {
            setIsFilterOrSort(true);
            setModalOpen(true);
          }}
        />
        <Icon
          name='BarChart2'
          variant='s'
          color='textPrimary'
          onPress={() => {
            setIsFilterOrSort(false);
            setModalOpen(true);
          }}
        />
        {showUpload && (
          <Button
            marginLeft='s'
            isSmall
            height={32}
            backgroundColor='textPrimary'
            prefix={<Icon variant='s' name='Upload' color='white' />}
            onPress={async () => {
              if (isMedia) {
                await launchImageSelection(MAX_FILE_PHOTO_COUNT).then(
                  (r) => r && getPhotos(r)
                );
              } else {
                await launchFileSelection().then((r) => {
                  if (!r) return;

                  const documents = r.map((item) => {
                    return {
                      ...item,
                      path: '',
                      isAudio: false,
                      isImage: false,
                    };
                  });
                  getPhotos(documents);
                });
              }
            }}
            borderRadius='xs'
            variant='create'
            prefixMarginRight='xs'
            paddingHorizontal='m'
            accessibilityLabel={t('projects.files.upload')}>
            {t('projects.files.upload')}
          </Button>
        )}
        {isSelectMode && (
          <Box
            backgroundColor='white'
            paddingHorizontal='m'
            style={{
              ...StyleSheet.absoluteFillObject,
            }}>
            <Box
              flex={1}
              flexDirection='row'
              alignItems='center'
              backgroundColor='grey05'
              borderRadius='xs'
              height={50}
              paddingHorizontal='m'>
              <Text variant='labelEmphasized' color='white'>
                {t('shared:selectedWithCount', {
                  count: selectedDocuments.length,
                })}
              </Text>
              <Icon
                name='X'
                color='white'
                variant='l'
                marginLeft='xs'
                onPress={() => {
                  setSelectedDocuments([]);
                  setIsSelectMode(false);
                }}
              />

              <Box flex={1} />
              <Icon
                name='Download'
                color='white'
                variant='l'
                onPress={() => {
                  downloadFiles(
                    selectedDocuments,
                    false,
                    props.state.index === 1
                  );
                }}
              />
              {forwardFileLoading && (
                <Box
                  width={40}
                  style={{ marginTop: -37, marginRight: -4, marginLeft: 8 }}>
                  {(Platform.OS === 'ios' || Platform.OS === 'web') && (
                    <ActivityIndicatorLoading
                      color={theme.colors.white}></ActivityIndicatorLoading>
                  )}
                  {Platform.OS === 'android' && (
                    <ActivityIndicator
                      color={theme.colors.white}
                      size={25}
                      style={[
                        {
                          position: 'absolute',
                          left: 0,
                          right: 0,
                          top: 7.5,
                        },
                      ]}
                    />
                  )}
                </Box>
              )}
              {/* {!forwardFileLoading && (
                <Icon
                  name='Forward'
                  color='white'
                  variant='l'
                  paddingTop='xxs'
                  marginRight='m'
                  onPress={async () => {
                    if (
                      (index === 1 && selectedDocuments.length > 8) ||
                      (index !== 1 && selectedDocuments.length > 9)
                    ) {
                      setShowMsg(true);
                      setTimeout(() => {
                        setShowMsg(false);
                      }, 3000);
                    } else {
                      const newArray = new Array<Document>();

                      const selectedDocs = selectedDocuments.filter(
                        (f) =>
                          f.__typename === 'Document' &&
                          !f.file.url.startsWith('file:') &&
                          !f.file.url.startsWith('data:')
                      );
                      const selectedFile: [Document] = selectedDocuments.filter(
                        (f) =>
                          !(
                            f.__typename === 'Document' &&
                            !f.file.url.startsWith('file:') &&
                            !f.file.url.startsWith('data:')
                          )
                      );

                      setForwardFileLoading(true);

                      if (selectedDocs.length > 0) {
                        Promise.all(
                          selectedDocs.map((doc1) =>
                            downloadImage((doc1 as Document).file?.url)
                          )
                        )
                          .then((fileurls) => {
                            for (let x = 0; x < fileurls.length; x++) {
                              const xitem = selectedDocs[x] as Document;
                              xitem.file.url = fileurls[x];
                              newArray.push(cloneDeep(xitem));
                            }
                            setPreviewDocuments([...newArray, ...selectedFile]);
                            setForwardFileLoading(false);
                            navigation.navigate(
                              'project-forward-media-message'
                            );
                          })
                          .catch((err) => {
                            setForwardFileLoading(false);
                            console.log('error when forward file to chat', err);
                            Alert.alert(
                              'Error happened when get file to forward to chat, Please try again.'
                            );
                          })
                          .finally(() => {
                            setForwardFileLoading(false);
                          });
                      } else {
                        setForwardFileLoading(false);
                        setPreviewDocuments([...selectedFile]);
                        navigation.navigate('project-forward-media-message');
                      }
                    }
                  }}
                />
              )} */}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <Modal
        animationType='fade'
        transparent={true}
        visible={modalOpen}
        onRequestClose={() => setModalOpen(false)}>
        <TouchableWithoutFeedback onPress={() => setModalOpen(!modalOpen)}>
          <Box style={styles.modal} />
        </TouchableWithoutFeedback>
        {isFilterOrSort ? filterTitleAndMenuList() : sortTitleAndMenuList()}
      </Modal>

      <Box mx='xs' mt='m' flex={1}>
        <Tab.Navigator
          screenOptions={{ swipeEnabled: false }}
          sceneContainerStyle={{
            backgroundColor: 'transparet',
          }}
          tabBar={renderTabBar}>
          <Tab.Screen
            name='all-project-files'
            options={{
              tabBarLabel: t('projects.projectDetails.tabs.allFiles'),
              tabBarAccessibilityLabel: t(
                'projects.projectDetails.tabs.allFiles'
              ),
            }}
            listeners={{
              tabPress: () => {
                isMedia && setIsMedia(false);
                !showUpload && setShowUpload(true);
              },
            }}
            children={() => (
              <Box flex={1}>
                <Box flex={1}>
                  <AllProjectFiles
                    projectId={projectId || ''}
                    sortBy={sortBy}
                    ownerId={ownerId}
                    isSelectMode={isSelectMode}
                    onSelectedModeChange={(isSelected: boolean) => {
                      setIsSelectMode(
                        shouldEnableSelectMode(isSelected, isViewer)
                      );
                    }}
                    selectedList={selectedDocuments}
                    handelSelectedItem={handelSelectedItem}
                    dateRange={dateRange}
                  />
                </Box>
              </Box>
            )}
          />
          <Tab.Screen
            name='project-media'
            options={{
              tabBarLabel: t('projects.projectDetails.tabs.media'),
              tabBarAccessibilityLabel: t('projects.projectDetails.tabs.media'),
            }}
            listeners={{
              tabPress: () => {
                !isMedia && setIsMedia(true);
                !showUpload && setShowUpload(true);
              },
            }}
            children={() => (
              <Box flex={1}>
                <Box flex={1}>
                  <ProjectMedia
                    projectId={projectId || ''}
                    sortBy={sortBy}
                    ownerId={ownerId}
                    isSelectMode={isSelectMode}
                    onSelectedModeChange={(isSelected: boolean) => {
                      setIsSelectMode(
                        shouldEnableSelectMode(isSelected, isViewer)
                      );
                    }}
                    selectedList={selectedDocuments}
                    handelSelectedItem={handelSelectedItem}
                    dateRange={dateRange}
                  />
                </Box>
              </Box>
            )}
          />
          <Tab.Screen
            name='project-task-files'
            options={{
              tabBarLabel: t('projects.projectDetails.tabs.taskFiles'),
              tabBarAccessibilityLabel: t(
                'projects.projectDetails.tabs.taskFiles'
              ),
            }}
            listeners={{
              tabPress: () => {
                showUpload && setShowUpload(false);
              },
            }}
            children={() => (
              <Box flex={1}>
                <Box flex={1}>
                  <ProjectTaskFiles
                    projectId={projectId || ''}
                    sortBy={sortBy}
                    ownerId={ownerId}
                    isSelectMode={isSelectMode}
                    onSelectedModeChange={(isSelected: boolean) => {
                      setIsSelectMode(
                        shouldEnableSelectMode(isSelected, isViewer)
                      );
                    }}
                    selectedList={selectedDocuments}
                    deleteloader={false}
                    handelSelectedItem={handelSelectedItem}
                    dateRange={dateRange}
                  />
                </Box>
                <DownloadFiles />
              </Box>
            )}
          />
        </Tab.Navigator>
      </Box>
      {showMsg &&
        ((index === 1 && selectedDocuments.length > 8) ||
          (index !== 1 && selectedDocuments.length > 9)) && (
          <Box
            backgroundColor='alertRedMild'
            opacity={0.8}
            marginHorizontal='s'
            paddingHorizontal='s'
            paddingVertical='xs'
            borderRadius='xs'
            style={{
              position: 'absolute',
              bottom: 80,
              width: screenWidth - theme.spacing.s * 2,
            }}>
            <Box flex={1}>
              <Text color='alertRed' variant='labelSmall' lineHeight={16}>
                {index === 1
                  ? t(
                      'projects.projectDetails.filesWithMedia.chatSendingImageLimit'
                    )
                  : t(
                      'projects.projectDetails.filesWithMedia.chatSendingFileLimit'
                    )}
              </Text>
            </Box>
          </Box>
        )}
    </>
  );
};

export default ProjectFiles;

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    height: Dimensions.get('window').height,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  popupBox: {
    position: 'absolute',
    zIndex: 99,
  },
  topShadow: {
    backgroundColor: 'white',
    shadowColor: 'black',
    shadowOpacity: 0.06,
    shadowOffset: { width: 0, height: 25 },
    shadowRadius: 25,
    zIndex: 99,
  },
});
