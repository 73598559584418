import { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

type Props = {
  onDrop?: (files: File[]) => void;
  children:
    | ReactNode
    | ((props: {
        /** The Input element to render */
        Input: () => JSX.Element;
        /** Whether the user is currently dragging over the `DropZone` */
        isDragActive: boolean;
      }) => ReactNode);
};

/**
 * A component that allows a user to drag and drop files for upload.
 * This component only renders on Web.
 */
export const DropZone = ({ onDrop, children }: Props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const Input = () => <input {...getInputProps()} />;
  return (
    <div
      {...getRootProps({
        style: { display: 'flex', flex: 1, height: '100%' },
      })}>
      {typeof children === 'function'
        ? children({ Input, isDragActive })
        : children}
    </div>
  );
};
