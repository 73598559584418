import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ScrollView } from 'react-native';

import { Alert } from '@components/Alert';
import Avatar from '@components/Avatar/Avatar';
import { TaskTagList } from '@components/Chat/TaskTagList';
import { ModalHeader } from '@components/Headers/ModalHeader';
import { ContactType } from '@components/Invite/Contact';
import ContactList from '@components/Invite/ContactList';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { ChatDrawerScreen } from '@components/Web/Drawer/WebDrawerContext';
import {
  useCompleteTaskMutation,
  useListContactUsersQuery,
  useSearchLazyQuery,
  TaskSortOption,
  SearchableResult,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import { useListTasksFromQuery } from '@hooks/useListTasksFromQuery';
import { useSelected } from '@hooks/useSelected';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { Search } from '@root/types';
import theme from '@themes/theme';
import { convertUsersToContacts } from '@utils/convertUsersToContacts';
import { isTaskSearchResult } from '@utils/typeGuards';

const DEBOUNCE = 500;
const TaskModal = ({ complete: isComplete }: { complete?: boolean }) => {
  const { t: tModels } = useTranslation('models');
  const { t: tShared } = useTranslation('shared');
  const { navigateToChatDrawer } = useAppNavigation();
  const { activeChat } = useActiveChat();
  const { users: groupUsers = [] } = activeChat || {};
  const [selected, updateSelected] = useSelected<ContactType>();
  const [search, setSearch] = useState<string>('');
  const [selectedTask, setSelectedTask] = useState<
    (typeof tasks)[number] | undefined
  >(undefined);

  const [combinedUsers, setCombinedUsers] = useState<ContactType[]>([]);
  useListContactUsersQuery({
    onCompleted: (d) => {
      const filteredUsers = d.listContactUsers.filter(
        // get group users
        (u) => !groupUsers.find((g) => g.id === u.id)
      );
      const convertedUsers = convertUsersToContacts(filteredUsers);
      setCombinedUsers([
        ...convertUsersToContacts(groupUsers),
        ...convertedUsers,
      ]);
    },
  });

  const [refreshing, setRefreshing] = useState(false);
  const perPageCount = 10;
  const {
    tasks,
    loading: tasksLoading,
    fetchFromCursor,
    refetch,
  } = useListTasksFromQuery({
    first: perPageCount,
    sortBy: TaskSortOption.NameAsc,
    onCompleted: () => setRefreshing(false),
    onError: () => setRefreshing(false),
  });

  // GLOBAL SEARCH WHILE USER IS TYPING

  const [loading, setLoading] = useState(false);

  const [globalSearch, { data }] = useSearchLazyQuery();

  const taskResults =
    (data &&
      (data.search as Search<SearchableResult>[])
        .filter(isTaskSearchResult)
        .map((r) => r.record)) ||
    [];

  const globalSearchCall = () =>
    globalSearch({
      variables: {
        term: search,
        size: 20,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: false,
        includeTasks: true,
      },
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    });

  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [search]
  );

  useEffect(() => {
    if (search) {
      setLoading(true);
      debouncedGlobalSearch();
    }

    return () => debouncedGlobalSearch.cancel();
  }, [search]);

  // END GLOBAL SEARCH

  // NOTE: Temporary assignment until task assignment is functional within chat.
  // The assignTaskUsers.graphql file was removed until it can be revised to match
  // member types
  const assignTaskUsers = (_payload: unknown) => void 0;
  const assignTaskUsesLoading = false;

  const assignTask = () => {
    const selectedUserIds = selected.map((u) => u.id);
    const { id = '' } = selectedTask || {};

    assignTaskUsers({
      variables: {
        id,
        userIds: selectedUserIds,
      },
    });
  };

  const [completeTask, { loading: completeTaskLoading }] =
    useCompleteTaskMutation({
      onCompleted: () =>
        activeChat &&
        navigateToChatDrawer({
          screen: ChatDrawerScreen.details,
          activeChat,
        }),
    });

  const completeTaskAlert = (task: (typeof tasks)[number]) => {
    if (!task) return;
    Alert.alert('Complete Task', tModels('tasks.complete.message'), [
      { text: tShared('cancel'), style: 'cancel' },
      {
        text: tModels('tasks.complete.title'),
        onPress: () => {
          completeTask({
            variables: {
              id: task.id,
            },
          });
        },
      },
    ]);
  };

  const roleLabel = 'tasks.roles.assigned';

  if (completeTaskLoading || assignTaskUsesLoading) {
    return (
      <Box mt='xs'>
        <ActivityIndicatorLoading />
      </Box>
    );
  }

  return (
    <Box height='100%'>
      <Box p='m' borderBottomColor='grey02' borderBottomWidth={1}>
        <ModalHeader
          onCancel={() =>
            activeChat &&
            navigateToChatDrawer({
              screen: ChatDrawerScreen.details,
              activeChat,
            })
          }
          cancelColor='greenSecondary'
          onSubmit={(!isComplete && assignTask) || undefined}
          submitLabel={
            selectedTask && !isComplete && selected.length
              ? tShared('confirm')
              : undefined
          }
          title={tModels('tasks.modalTitle.title', {
            context:
              (isComplete && 'isComplete') ||
              (selectedTask && 'selectedTask') ||
              '',
          })}
        />
      </Box>
      <Box m='m' flex={1}>
        <Box mb='m' flexDirection='row' alignItems='center'>
          <SearchInput
            onTextChanged={(t: string) => setSearch(t)}
            value={search}
          />
        </Box>

        <Box flex={1}>
          {selectedTask ? (
            <>
              <Box>
                {!!selected.length && (
                  <ScrollView>
                    <FlatList
                      style={{
                        marginTop: theme.spacing.m,
                        paddingBottom: theme.spacing.m,
                      }}
                      horizontal
                      data={selected}
                      ItemSeparatorComponent={() => <Box marginRight='m' />}
                      ListHeaderComponent={() => <Box marginRight='m' />}
                      ListFooterComponent={() => <Box marginRight='m' />}
                      renderItem={({ item }) => (
                        <Box
                          key={item.id}
                          alignItems='center'
                          alignSelf='flex-start'>
                          <Avatar
                            avatar={item.avatar}
                            onDismiss={() => updateSelected(item)}
                          />
                          <Text
                            variant='labelSmall'
                            marginTop='xs'
                            style={{ maxWidth: 64 }}
                            numberOfLines={1}>
                            {item.firstName}
                          </Text>
                        </Box>
                      )}
                    />
                  </ScrollView>
                )}
              </Box>
              <Box flex={1}>
                <Box
                  backgroundColor='grey01'
                  paddingVertical='xs'
                  paddingHorizontal='m'>
                  <Text variant='labelEmphasized'>
                    {tModels('tasks.modalListLabel.label', {
                      context: search ? 'allContacts' : 'chatMembers',
                    })}
                  </Text>
                </Box>
                <ContactList
                  contacts={(search
                    ? combinedUsers
                    : convertUsersToContacts(groupUsers)
                  ).map((u) => {
                    if (selectedTask?.userIds.find((uid) => uid === u.id)) {
                      // TODO: Figure out how to pass roleLabel as translated string
                      return { ...u, roleLabel };
                    }

                    return u;
                  })}
                  filterVal={search}
                  headerSpace='none'
                  footerSpace={0}
                  isEditing
                  selected={selected.map((s) => s.id)}
                  onPress={(i: ContactType) => updateSelected(i)}
                  disabledRoles={[roleLabel]}
                />
              </Box>
            </>
          ) : (
            <ScrollView>
              <TaskTagList
                withoutPadding
                refetch={refetch}
                setRefreshing={setRefreshing}
                fetchMore={fetchFromCursor}
                onSelect={(tag) =>
                  isComplete ? completeTaskAlert(tag) : setSelectedTask(tag)
                }
                filterVal={search}
                taskTags={search ? taskResults : tasks}
                onRefresh={() => {
                  setRefreshing(true);
                  refetch();
                }}
                refreshing={refreshing}
                loading={loading || tasksLoading}
                listEmptyComponent={
                  <Box>
                    <Text
                      variant='bodySecondary'
                      color='grey04'
                      textAlign='center'
                      mt='m'>
                      No tasks found
                    </Text>
                  </Box>
                }
              />
            </ScrollView>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TaskModal;
