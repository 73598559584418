import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { ComponentProps } from "react";
import { enableFreeze } from "react-native-screens";

import theme from "../../themes/theme";

const navigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: theme.colors.white,
  },
};

enableFreeze();

type Props = Omit<ComponentProps<typeof NavigationContainer>, "theme">;

export const ThemedNavigationContainer = (props: Props) => {
  return <NavigationContainer {...props} theme={navigationTheme} />;
};
