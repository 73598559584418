import { ProjectMemberRole } from '@graphql/generated';

export const selectableProjectRoles = Object.values(ProjectMemberRole).filter(
  (r) => r !== ProjectMemberRole.Owner
);
export const defaultProjectRole = ProjectMemberRole.Editor;
export const getProjectRoleEnumValue = (role: string) => {
  const indexOfRole = Object.values(ProjectMemberRole).indexOf(
    role as ProjectMemberRole
  );
  return Object.values(ProjectMemberRole)[indexOfRole];
};
