import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import { Platform } from 'react-native';

import MainHeader from '@components/Headers/MainHeader';
import CreateTask from '@components/Tasks/CreateTask';
import { Task } from '@graphql/generated';
import { TasksTabbar } from '@navigation/tasks/tasks-tab-bar';
import { Tasks } from '@screens/Tasks/Tasks.web';

type TasksTabStackParamsList = {
  tasks?: { taskId?: Task['id'] };
  'task-create': undefined;
};

export type TasksTabStackScreenProps<T extends keyof TasksTabStackParamsList> =
  StackScreenProps<TasksTabStackParamsList, T>;

const Stack = createStackNavigator<TasksTabStackParamsList>();

const TasksTabStack: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => <MainHeader location='My Tasks' />,
        headerShown: Platform.OS !== 'web',
      }}>
      <Stack.Screen
        name='tasks'
        component={Platform.OS === 'web' ? Tasks : TasksTabbar}
      />
      <Stack.Screen name='task-create' component={CreateTask} />
    </Stack.Navigator>
  );
};

export default TasksTabStack;
