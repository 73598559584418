import { createStackNavigator } from '@react-navigation/stack';

import Files from '@screens/Files/Files';
import ProjectFiles from '@screens/Projects/ProjectFiles';
const Stack = createStackNavigator();

const FilesTabStack: React.FC = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='files'
        component={Files}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name='project-files'
        component={ProjectFiles}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default FilesTabStack;
