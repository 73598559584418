import ReactNativeAsyncStorage from "@react-native-async-storage/async-storage";

export interface IAsyncStorage {
  /** Fetches an item for a `key` */
  getItem: (key: string) => Promise<string | null>;
  /** Sets the value for a `key` */
  setItem: (key: string, value: string) => Promise<void>;
  /** Removes the value for `key` */
  deleteItem: (key: string) => Promise<void>;
}

export const AsyncStorage: IAsyncStorage = {
  getItem: ReactNativeAsyncStorage.getItem,
  setItem: ReactNativeAsyncStorage.setItem,
  deleteItem: ReactNativeAsyncStorage.removeItem,
};
