import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectModal } from '@components/FormModals/SelectModal';
import Box from '@components/Restyle/Box';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { ProjectMemberRole, ProjectSortOption } from '@graphql/generated';
import { useListProjectsBasicInfoFromQuery } from '@hooks/useListProjectsBasicInfoFromQuery';
import { useAppNavigation } from '@navigation/useAppNavigation';

interface ProjectSelectProps {
  isBottomSheet?: boolean;
  onSelectFinish?: () => void;
}

const ProjectSelect = ({
  isBottomSheet,
  onSelectFinish,
}: ProjectSelectProps) => {
  const { t } = useTranslation('models');

  const { projects, fetchFromCursor, refreshControl, refreshing, loading } =
    useListProjectsBasicInfoFromQuery({ sortBy: ProjectSortOption.NameAsc });

  const { setFieldValue, getFieldProps, setTouched, touched } =
    useFormikContext();
  const { name: fieldName, value } = getFieldProps('projectId');

  useEffect(() => {
    return () => setTouched({ ...touched, projectId: true });
  }, []);
  const navigation = useAppNavigation();

  const filteredProject = projects.filter(
    (item) => item.currentUserRole !== ProjectMemberRole.Viewer
  );

  if (loading)
    return (
      <Box
        style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
        <ActivityIndicatorLoading />
      </Box>
    );

  return (
    <SelectModal
      title='Project'
      list={filteredProject}
      setSelected={(id: any) => {
        setTouched({ ...touched, [fieldName]: true });
        setFieldValue(fieldName, id);
        onSelectFinish ? onSelectFinish() : navigation.goBack();
      }}
      value={value}
      singleSelect
      emptyContentMessage={t('resourceNotFoundProject.message')}
      onClose={navigation.goBack}
      isBottomSheet={isBottomSheet}
      isProjectSelect={true}
      loading={refreshing || loading}
      fetchMore={fetchFromCursor}
      refreshControl={refreshControl}
    />
  );
};

export default ProjectSelect;
