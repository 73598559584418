import { object, string, array, bool, date, mixed } from 'yup';

import { TaskPriority, TaskStatus } from '@graphql/generated';
import { userSchema } from '@schemas/userSchema';

const createTaskFormSchema = object({
  name: string().required('Name is a required field'),
  description: string().nullable(),
  projectId: string().required('Project is required'),
  users: array().of(userSchema).ensure(),
  skillIds: array().of(string()).ensure(),
  phaseId: string().nullable(),
  // NOTE: oneOf was not respecting undefined and null values though documentation suggests undefined
  // will not fail the validator.
  priority: mixed()
    .oneOf([undefined, ...Object.values(TaskPriority)])
    .required(),
  status: mixed().oneOf([null, undefined, ...Object.values(TaskStatus)]),
  frequency: string(),
  recurring: bool(),
  fixedOrder: bool(),
  dependencyIds: array().of(string()).ensure(),
  startDate: date().nullable(),
  dueDate: date()
    .when('startDate', (startDate, schema) =>
      startDate
        ? schema.min(startDate, 'Due date cannot be before start time')
        : schema
    )
    .nullable(),
  completedAt: date().nullable(),
});

export default createTaskFormSchema;
