import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform } from 'react-native';

import Popup, { MenuItem } from '../shared/Popup/Popup';

type ChatPopupProps = {
  closePopFn?: () => void;
};

const ChatPopup: React.FC<ChatPopupProps> = ({ closePopFn }) => {
  const navigation = useNavigation();

  const menuList: MenuItem[] = [
    {
      iconName: 'Edit',
      name: 'Edit',
      onSelect: () =>
        Platform.OS !== 'web' &&
        navigation.navigate('chats-stack', { screen: 'chat-select' }),
    },
    {
      iconName: 'Users',
      name: 'New Group',
      onSelect: () =>
        Platform.OS !== 'web' &&
        navigation.navigate('chats-stack', { screen: 'create-group-stack' }),
    },
    {
      iconName: 'Archive',
      name: 'Archived Chats',
      onSelect: () =>
        navigation.navigate('chats-stack', {
          screen: 'archived-chats',
        }),
    },
  ];
  return <Popup width={220} menuList={menuList} closePopFn={closePopFn} />;
  //width changed to fit "Archived Chats" text earlier it was 179
};

export default ChatPopup;
