import React, { ComponentProps } from 'react';

import Avatar from '@components/Avatar/Avatar';
import { Box, Text } from '@components/Restyle';
import { WebIcon } from '@components/shared/Icon/WebIcon';
import { PopupIcon } from '@components/Web/PopupIcon';
import { File } from '@graphql/generated';
import theme from '@themes/theme';

type Props = {
  title: React.ReactNode | string;
  titleVariant?: ComponentProps<typeof Text>['variant'];
  subtitle?: React.ReactNode | string;
  subtitleVariant?: ComponentProps<typeof Text>['variant'];
  children: React.ReactNode;
  /** True if the drawer should display with elevated styles */
  elevated?: boolean;
  /** True if the drawer content should display with elevated styles */
  elevatedContent?: boolean;
  /** `true` when the drawer is displayed in the open state. Defaults to `true` if not provided */
  open?: boolean;
  onClose?: () => void;
  menuList?: ComponentProps<typeof PopupIcon>['popupProps']['menuList'];
  width?: ComponentProps<typeof Box>['width'];
  avatar?: File;
  isGroupChat?: boolean;
  showAvatar?: boolean;
  showHeader?: boolean;
  onSearch?: () => void;
  chatExpanded?: boolean;
  onExpandCollapse?: () => void;
  chatId?: string;
} & (
  | {
      /** Fired when the open toggle is pressed. Requires the `open` prop to be provided */
      onOpenToggle: () => void;
      /** `true` when the drawer is displayed in the open state. */
      open: boolean;
    }
  | {
      /** `true` when the drawer is displayed in the open state. Defaults to `true` if not provided */
      open?: boolean;
      onOpenToggle?: never;
    }
);

export const Drawer = ({
  title,
  titleVariant = 'labelEmphasized',
  subtitle,
  subtitleVariant = 'labelSmall',
  children,
  onOpenToggle,
  open = true,
  onClose,
  elevated,
  elevatedContent,
  menuList,
  isGroupChat,
  avatar,
  showAvatar,
  onSearch,
  chatExpanded = false,
  onExpandCollapse,
  chatId,
}: Props) => {
  const isSearching =
    title === 'Chat Search' || title === 'Chat history search';
  return (
    <Box
      accessibilityLabel='Chat history'
      flex={1}
      borderLeftWidth={1}
      borderColor='grey02'
      flexDirection='column'
      backgroundColor='white'
      {...(elevated
        ? {
            shadowColor: 'black',
            shadowOpacity: 0.16,
            shadowRadius: 16,
          }
        : undefined)}>
      <Box
        flexDirection='row'
        zIndex={1}
        mt='s'
        mx='l'
        borderTopWidth={1}
        borderBottomWidth={1}
        borderColor='white'
        alignItems='center'
        style={{ marginBottom: 11 }}>
        {typeof title !== 'undefined' && title != '' && (
          <Box flexDirection='row' zIndex={1} flex={1}>
            {showAvatar && (
              <Box marginRight='xs'>
                <Avatar
                  id={chatId}
                  size='default'
                  isMultiple={isGroupChat}
                  label={typeof title === 'string' ? title : ''}
                  avatar={avatar}
                />
              </Box>
            )}
            <Box
              flexDirection='column'
              flex={1}
              py='xxs'
              justifyContent='center'>
              {typeof title === 'string' ? (
                <Text
                  variant={titleVariant}
                  color='textPrimary'
                  numberOfLines={1}
                  mb='xxxs'>
                  {title}
                </Text>
              ) : (
                title
              )}
              {typeof subtitle !== 'undefined' && !isSearching && (
                <Text
                  variant={subtitleVariant}
                  color='grey04'
                  numberOfLines={1}>
                  {subtitle}
                </Text>
              )}
            </Box>
          </Box>
        )}

        <Box
          flexDirection='row'
          justifyContent='center'
          flex={title ? undefined : 1}
          height={48}>
          {menuList && open && (
            <Box
              flexDirection='row'
              width={menuList.length > 0 ? 56 : 28}
              justifyContent='space-between'
              alignItems='center'
              mr='xs'>
              {!isSearching && (
                <>
                  {onSearch && (
                    <WebIcon
                      accessibilityLabel='Chat search'
                      name='Search'
                      color='textPrimary'
                      variant='l'
                      onPress={onSearch}
                    />
                  )}
                  {onExpandCollapse && (
                    <WebIcon
                      name={chatExpanded ? 'Minimize2' : 'Maximize2'}
                      color='textPrimary'
                      variant='l'
                      onPress={onExpandCollapse}
                    />
                  )}
                  {menuList.length > 0 && (
                    <PopupIcon
                      accessibilityLabel={
                        title == 'Chat' ? 'Chat list option' : 'Chat option'
                      }
                      name='MoreVertical'
                      color='textPrimary'
                      popupProps={{
                        position: 'left',
                        offset: [0, theme.spacing.m],
                        menuList,
                      }}
                      variant='l'
                    />
                  )}
                </>
              )}
            </Box>
          )}
          {onOpenToggle && !onClose && (
            <Box flexDirection='row' width={32} alignItems='center'>
              <WebIcon
                accessibilityLabel={open ? 'Hide chats' : 'Show chats'}
                name={open ? 'ChevronsRight' : 'ChevronsLeft'}
                onPress={onOpenToggle}
                color='textPrimary'
                variant='l'
              />
            </Box>
          )}
        </Box>

        {onClose && (
          <WebIcon name='X' onPress={onClose} color='textPrimary' variant='l' />
        )}
      </Box>
      <Box
        flex={1}
        {...(elevatedContent
          ? {
              shadowColor: 'black',
              shadowOpacity: 0.05,
              shadowRadius: 25,
              shadowOffset: { width: 0, height: -5 },
            }
          : undefined)}>
        {children}
      </Box>
    </Box>
  );
};
