import React from 'react';

import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { Project, Task, User } from '@graphql/generated';
import { TaskPanel } from '@navigation/tasks/task-panel';

interface TaskWebPanelProps {
  idtask: string;
  isShortModal?: boolean;
  externalNavigate?: any;
  onNavigation: (task: Task) => void;
  onPressCreator?: (task: Task) => void;
  onPressMember?: (item: User) => void;
  onPressProject?: (item: Project) => void;
}
export const TaskWebPanel: React.FC<TaskWebPanelProps> = ({
  idtask,
  onNavigation,
  onPressCreator,
  onPressMember,
  onPressProject,
}) => {
  const {
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIsTaskWebPanelMaximize,
    isTaskWebPanelMaximize,
  } = useWebDrawer();
  return (
    <TaskPanel
      isFromFullScreen={true}
      key={idtask}
      idtask={idtask}
      onClose={() => {
        setIsTaskWebPanelOpen(false);
        setIsTaskWebPanelMaximize(false);
        setIdTaskDetailOpen('');
      }}
      isMaximize={isTaskWebPanelMaximize}
      onMaximize={() => {
        setIsTaskWebPanelMaximize(!isTaskWebPanelMaximize);
      }}
      onNavigation={onNavigation}
      onPressCreator={onPressCreator}
      onPressMember={onPressMember}
      onPressProject={onPressProject}
    />
  );
};
