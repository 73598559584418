import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { ActionMap } from '@root/types';

export const searchLocations = [
  'Chats',
  'Projects',
  'Tasks',
  'Files',
  'Contacts',
] as const;
export type SearchLocation = (typeof searchLocations)[number];

enum SearchActionType {
  setSearch,
  setViewAllEvent,
  setIsSearchFocus,
  setLocation,
  setIsSearching,
  storeRecentSearchTerm,
  setHistory,
  deleteHistoryItem,
  setSearchWidth,
}

type SearchStatePayload = {
  [SearchActionType.setSearch]: string;
  [SearchActionType.setIsSearchFocus]: boolean;
  [SearchActionType.setViewAllEvent]: boolean;
  [SearchActionType.setLocation]: SearchLocation;
  [SearchActionType.setIsSearching]: boolean;
  [SearchActionType.storeRecentSearchTerm]: string;
  [SearchActionType.setHistory]: HistoryItem[];
  [SearchActionType.deleteHistoryItem]: string;
  [SearchActionType.setSearchWidth]: number;
};

type HistoryItem = { id: string; value: string };

type GlobalSearchProjectState = {
  search: string;
  isSearchFocus: boolean;
  isViewAllPress: boolean;
  location: SearchLocation[];
  isSearching: boolean;
  history: HistoryItem[];
  searchWidth: number;
};

type SearchContext = GlobalSearchProjectState & {
  setSearch: (search: GlobalSearchProjectState['search']) => void;
  setIsSearchFocus: (focus: GlobalSearchProjectState['isSearchFocus']) => void;
  setViewAllEvent: (focus: GlobalSearchProjectState['isViewAllPress']) => void;
  setLocation: (filter: GlobalSearchProjectState['location'][number]) => void;
  setIsSearching: (
    isSearching: GlobalSearchProjectState['isSearching']
  ) => void;
  storeRecentSearchTerm: () => void;
  deleteHistoryItem: (historyItemId: string) => void;
  setSearchWidth: (collapsed: GlobalSearchProjectState['searchWidth']) => void;
};

const initialSearchState: GlobalSearchProjectState = {
  search: '',
  isSearchFocus: false,
  isViewAllPress: true,
  location: [],
  isSearching: false,
  history: [],
  searchWidth: 500,
};

type SearchActions =
  ActionMap<SearchStatePayload>[keyof ActionMap<SearchStatePayload>];
const reducer = (
  state: GlobalSearchProjectState,
  action: SearchActions
): GlobalSearchProjectState => {
  switch (action.type) {
    case SearchActionType.setSearch:
      return {
        ...state,
        isSearching: true,
        search: action.payload,
      };
    case SearchActionType.setIsSearchFocus:
      return {
        ...state,
        isSearching: action.payload,
        isSearchFocus: action.payload,
      };
    case SearchActionType.setViewAllEvent:
      return {
        ...state,
        isViewAllPress: action.payload,
      };
    case SearchActionType.setLocation: {
      const filterIndex = state.location.indexOf(action.payload);
      return {
        ...state,
        isSearching: true,
        location:
          filterIndex === -1
            ? [action.payload]
            : state.location.filter((_, i) => i !== filterIndex),
      };
    }
    case SearchActionType.setIsSearching: {
      if (action.payload) {
        return { ...state, isSearching: true };
      } else {
        return {
          ...state,
          isSearching: false,
          isViewAllPress: false,
          search: '',
          location: [],
        };
      }
    }
    case SearchActionType.setHistory: {
      return {
        ...state,
        history: action.payload,
      };
    }
    case SearchActionType.deleteHistoryItem: {
      const newHistory = state.history.filter((i) => i.id !== action.payload);
      const jsonValue = JSON.stringify(newHistory);

      AsyncStorage.setItem('@search_history_project', jsonValue);
      return {
        ...state,
        history: newHistory,
      };
    }
    case SearchActionType.setSearchWidth:
      return {
        ...state,
        searchWidth: action.payload,
      };
    default:
      return state;
  }
};

const searchContext = createContext<SearchContext | undefined>(undefined);

export const GlobalSearchProviderProject: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { Provider } = searchContext;
  const [state, dispatch] = useReducer(reducer, {
    ...initialSearchState,
  });

  const setSearch = (search: typeof state.search) => {
    dispatch({ type: SearchActionType.setSearch, payload: search });
  };
  const setViewAllEvent = (search: typeof state.isViewAllPress) => {
    dispatch({ type: SearchActionType.setViewAllEvent, payload: search });
  };

  const setIsSearchFocus = (focus: typeof state.isSearchFocus) => {
    dispatch({ type: SearchActionType.setIsSearchFocus, payload: focus });
  };

  const setLocation = (filter: (typeof state.location)[number]) => {
    dispatch({ type: SearchActionType.setLocation, payload: filter });
  };

  const setIsSearching = (isSearching: typeof state.isSearching) => {
    dispatch({ type: SearchActionType.setIsSearching, payload: isSearching });
  };

  const setHistory = (history: typeof state.history) => {
    dispatch({ type: SearchActionType.setHistory, payload: history });
  };

  const setSearchWidth = (focus: typeof state.searchWidth) => {
    dispatch({ type: SearchActionType.setSearchWidth, payload: focus });
  };

  const deleteHistoryItem = (historyItemId: string) => {
    dispatch({
      type: SearchActionType.deleteHistoryItem,
      payload: historyItemId,
    });
  };

  const storeRecentSearchTerm = async () => {
    // whatever is in search, store it
    if (state.search.trim().length > 0) {
      // remove oldest history item, add new history item
      try {
        const newHistory = [];
        const filteredHistory = state.history.filter(
          (i) => i.value.trim() !== state.search.trim()
        );

        // if you did filter, slice - if not, remain.
        if (filteredHistory.length === state.history.length) {
          newHistory.push(...state.history.slice(0, 4));
        } else {
          newHistory.push(...filteredHistory);
        }

        newHistory.push({
          id: Date.now().toString(),
          value: state.search,
        });

        const jsonValue = JSON.stringify(newHistory);

        await AsyncStorage.setItem('@search_history_project', jsonValue);
        readHistory();
      } catch (e) {
        // saving error
        console.error('Error saving history', e);
      }
    }
  };

  const readHistory = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem('@search_history_project');
      const storedHistory =
        jsonValue !== null ? (JSON.parse(jsonValue) as HistoryItem[]) : [];
      const sortedHistory = storedHistory.sort((a, b) => +b.id - +a.id);
      // set history
      setHistory(sortedHistory);
    } catch (e) {
      // error reading value
      console.error('Error reading history', e);
    }
  };

  useEffect(() => {
    readHistory();
  }, []);

  useEffect(() => {
    state.isSearchFocus && storeRecentSearchTerm();
  }, [state.isSearchFocus]);

  return (
    <Provider
      value={{
        ...state,
        setSearch,
        setViewAllEvent,
        setIsSearchFocus,
        setLocation,
        setIsSearching,
        storeRecentSearchTerm,
        deleteHistoryItem,
        setSearchWidth,
      }}>
      {children}
    </Provider>
  );
};

export const useGlobalSearchProject = (): SearchContext => {
  const context = useContext(searchContext);
  if (context === undefined) {
    throw new Error('useGlobalSearchProject must be used within a Provider');
  }
  return context;
};

export default useGlobalSearchProject;
