import { TriggersConfig } from 'react-native-controlled-mentions';

import theme from '../../themes/theme';

export const triggersConfig: TriggersConfig<'mention'> = {
  mention: {
    trigger: '@',
    textStyle: {
      color: theme.colors.blue,
      fontFamily: 'Inter_600SemiBold',
    },
    isInsertSpaceAfterMention: true,
  },
};

export const triggersConfigPlain: TriggersConfig<'mention'> = {
  mention: {
    trigger: '@',
    textStyle: {
      ...theme.textVariants.body1,
      color: theme.colors.black,
      fontFamily: 'Inter_500Medium',
    },
  },
};
