import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TouchableOpacity,
  StyleSheet,
  Platform,
  Dimensions,
} from 'react-native';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import theme from '@themes/theme';

const EmptyStateNoFilesTeams: React.FC = () => {
  const [showmodal, setShowModal] = useState(true);
  const [margin, setMargin] = useState(80);

  const { t } = useTranslation('models');
  return (
    <Box style={{ marginBottom: margin }}>
      {showmodal && (
        <Box
          style={[
            styles.boxStyle,
            {
              width:
                Platform.OS === 'web'
                  ? '95%'
                  : Dimensions.get('window').width - 32,
            },
          ]}>
          <Box justifyContent='center'>
            <Icon
              height={40}
              width={50}
              name='WinnerIsometric1'
              marginRight='xs'
            />
          </Box>
          <Box flex={1} justifyContent='center'>
            <Text variant='labelSmall' color='white'>
              {t('teams.files.explore')}
            </Text>
            <Text
              variant='metadata'
              color='white'
              marginTop='xxs'
              numberOfLines={2}>
              {t('teams.files.scale')}
            </Text>
          </Box>
          <Box>
            <TouchableOpacity
              onPress={() => {
                setMargin(0);
                setShowModal(false);
              }}>
              <Icon name='X' variant='s' color='grey04' />
            </TouchableOpacity>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  boxStyle: {
    flexDirection: 'row',
    marginVertical: theme.spacing.s,
    marginHorizontal: theme.spacing.m,
    position: 'absolute',
    height: 68,
    padding: theme.spacing.xs,
    backgroundColor: 'black',
    borderRadius: theme.spacing.xs,
  },
});

export default EmptyStateNoFilesTeams;
