import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { Box, Text } from '@components/Restyle';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { useSearchLazyQuery } from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';
import useGlobalSearchProject from '@hooks/useGlobalSearchProjects';

interface MyTagModalHeaderProps {
  filterVal: string;
  onChangeFilterVal: (newFilterVal: string) => void;
  isTagSearch?: boolean;
  isEditTagSearch?: boolean;
  onCancelPress?: () => void;
}
export const MyTagModalHeader: React.FC<MyTagModalHeaderProps> = ({
  filterVal,
  onChangeFilterVal,
  isTagSearch,
  isEditTagSearch,
  onCancelPress,
}) => {
  const { t } = useTranslation();
  const { setIsTagModalOpen } = useChatInput();

  const {
    search,
    setSearch,
    storeRecentSearchTerm,
    setIsSearching,
    setViewAllEvent,
  } = useGlobalSearchProject();

  useEffect(() => {
    return () => {
      setSearch('');
      setIsSearching(false);
      setViewAllEvent(true);
    };
  }, []);

  const [globalSearch] = useSearchLazyQuery();

  const [, setLoading] = useState(false);
  const globalSearchCall = () =>
    globalSearch({
      variables: {
        term: search,
        size: 20,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: true,
        includeTasks: true,
      },
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    });
  const DEBOUNCE = 500;
  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [search]
  );
  useEffect(() => {
    if (search) {
      setLoading(true);
      debouncedGlobalSearch();
    }
    return () => {
      debouncedGlobalSearch.cancel();
      setLoading(false);
    };
  }, [search]);

  return (
    <>
      <Box
        paddingHorizontal='m'
        paddingBottom='m'
        borderBottomColor='grey02'
        marginTop='xxs'
        borderBottomWidth={1}>
        <Box marginTop='m' flexDirection='row' alignItems='center'>
          <SearchInput
            onTextChanged={(v) => {
              setSearch(v);
              onChangeFilterVal(v);
            }}
            value={filterVal}
            autoFocus
            isTagSearch={isTagSearch}
            isEditTagSearch={isEditTagSearch}
            onBlur={() => {
              storeRecentSearchTerm();
            }}
          />
          <TouchableOpacity
            onPress={() => {
              setIsTagModalOpen(false);
              onCancelPress && onCancelPress();
            }}>
            <Text marginLeft='xs' variant='buttonLabel' color='greenSecondary'>
              {t('shared:cancel')}
            </Text>
          </TouchableOpacity>
        </Box>
      </Box>
    </>
  );
};
