const regex = /chat\/(?<chatId>\d+)(\/|$)(?<messageCursor>\d+)?(\/|$)?/;
export const urlToChatAndMessageCursor = (url: string) => {
  const results = url.match(regex);
  if (!results?.groups?.chatId) return undefined;

  return {
    chatId: results.groups.chatId,
    messageCursor: results.groups.messageCursor,
  };
};

export const urlMatchesId = (url: string, id: string) => {
  // const urlId = notification.content.data.url as string;
  const results = url.match(regex);
  if (!results?.groups?.chatId) return false;

  return results.groups.chatId === id;
};
