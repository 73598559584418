/* eslint-disable no-nested-ternary */
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import {
  Platform,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
} from 'react-native';

import useMe from '@hooks/useMe';

import theme from '../../../themes/theme';
import CachedImage from '../../components/CachedImage/CachedImage';
import { File } from '../../graphql/generated';
import useColors from '../../hooks/useColors';
import { Box, Text } from '../Restyle/index';
import Icon from '../shared/Icon/Icon';

export interface AvatarProps {
  id?: string;
  /**
   * How large should the avatar be?
   */
  size?: 'memberList' | 'xs' | 'small' | 'medium' | 'default' | 'large';
  /**
   * What are the initials?
   */
  label?: string | null;
  /**
   * What background color to use?
   */
  backgroundColor?: string | undefined;
  /**
   * What avatar url to use?
   */
  avatar?: File | null;
  /**
   * Whether there is a T subscript?
   */
  marginLeft?: number;
  onPress?: () => void;
  onDismiss?: () => void;
  isMultiple?: boolean;
  skipCache?: boolean;
  disableAvatarPress?: boolean;
  isSmall?: boolean;
  isSenderFriendRequest?: boolean;
  width?: number;
  height?: number;
}

/**
 * User avatar component
 */
const Avatar = ({
  id,
  size = 'default',
  label = null,
  backgroundColor = undefined,
  avatar = null,
  marginLeft = 0,
  onPress,
  onDismiss,
  isMultiple,
  skipCache = true,
  disableAvatarPress,
  isSmall = false,
  isSenderFriendRequest = false,
  width,
  height,
}: AvatarProps) => {
  const { me } = useMe();
  const navigation = useNavigation();
  const { getComputedColor, getGroupColor } = useColors();
  const defaultIcon = isMultiple ? 'Users' : 'User';

  if (label) {
    label = label.toUpperCase();
    const matches = label.match(/\b(\w)/g);
    if (matches && matches.length > 1) {
      label = matches.join('');
    }
    if (label.length > 1) {
      label = label.substring(0, 2);
    }
  }

  if (!backgroundColor) {
    const color = isMultiple ? getGroupColor(id) : getComputedColor(label);
    backgroundColor = isSenderFriendRequest ? theme.colors.grey04 : color.value;
  }

  const onAvatarPress = () => {
    navigation.navigate('user-profile', {
      userId: id,
    });
  };

  const renderItem = (style: AvatarStyleSheet) => {
    const boxSize =
      width && height
        ? { width, height, borderRadius: width / 2, zIndex: -1 }
        : style.avatarBox;
    return (
      <TouchableOpacity
        onPress={onPress ? onPress : onAvatarPress}
        activeOpacity={onPress || (id && id !== me?.id) ? 0.2 : 1}
        disabled={(!onPress && !id) || disableAvatarPress}>
        <Box
          style={[
            {
              backgroundColor: backgroundColor,
              marginLeft: marginLeft,
            },
            isSmall ? { width: 24, height: 24 } : boxSize,
          ]}
          alignItems='center'
          justifyContent='center'
          borderWidth={0}
          borderColor='white'>
          {avatar ? (
            <CachedImage
              image={avatar}
              skipCache={skipCache}
              width={width ? width : style.avatarBox.width}
              height={height ? height : style.avatarBox.height}
              borderRadius={width ? width / 2 : style.avatarBox.borderRadius}
            />
          ) : label && !isMultiple ? (
            <Box>
              {size === 'xs' ? (
                <Text variant='metadata' color='white'>
                  {label}
                </Text>
              ) : size === 'small' ? (
                <Text variant='link' color='white'>
                  {label}
                </Text>
              ) : size === 'medium' ? (
                <Text variant='buttonLabel' color='white'>
                  {label}
                </Text>
              ) : size === 'large' ? (
                <Text variant='heading1' color='white'>
                  {label}
                </Text>
              ) : (
                <Text variant='heading2' color='white'>
                  {label}
                </Text>
              )}
            </Box>
          ) : (
            <Box>
              {size === 'xs' ? (
                <Icon name={defaultIcon} color='white' variant='xs' />
              ) : size === 'small' ? (
                <Icon name={defaultIcon} color='white' variant='s' />
              ) : size === 'medium' ? (
                <Icon name={defaultIcon} color='white' variant='l' />
              ) : size === 'large' ? (
                <Icon
                  name={defaultIcon}
                  color='white'
                  variant='l'
                  width={40}
                  height={40}
                />
              ) : (
                <Icon
                  name={defaultIcon}
                  color='white'
                  variant='l'
                  width={32}
                  height={32}
                />
              )}
            </Box>
          )}
        </Box>
        {!!onDismiss && (
          <Box
            style={{
              position: 'absolute',
              top: -theme.spacing.xxs,
              right: -theme.spacing.xs,
            }}>
            <Icon
              name='X2'
              onPress={onDismiss}
              color='black'
              variant='l'
              accessibilityLabel='Remove selected member'
            />
          </Box>
        )}
      </TouchableOpacity>
    );
  };

  const avatarBoxStyleForMemberListSummary = () => ({
    backgroundColor: avatar ? theme.colors.white : backgroundColor,
    marginLeft: marginLeft,
  });

  const renderItemForMemberListSummary = () => {
    return (
      <Box
        style={[
          avatarBoxStyleForMemberListSummary(),
          isSmall
            ? { width: 24, height: 24 }
            : stylesForMemberListSummary.avatarBox,
        ]}
        borderRadius='s'
        borderColor='white'
        alignItems='center'
        justifyContent='center'
        borderWidth={1}>
        {avatar ? (
          <CachedImage
            image={avatar}
            width={isSmall ? 24 : stylesForMemberListSummary.avatar.width}
            height={isSmall ? 24 : stylesForMemberListSummary.avatar.height}
            borderRadius={
              isSmall ? 12 : stylesForMemberListSummary.avatar.borderRadius
            }
          />
        ) : label ? (
          <Text variant='metadata' color='white'>
            {label}
          </Text>
        ) : (
          <Icon name={defaultIcon} color='white' variant='xs' />
        )}
      </Box>
    );
  };

  if (size === 'memberList') {
    return renderItemForMemberListSummary();
  }

  if (size === 'xs') {
    return renderItem(stylesXS);
  }

  if (size === 'small') {
    return renderItem(stylesSmall);
  }

  if (size === 'medium') {
    return renderItem(stylesMedium);
  }

  if (size === 'large') {
    return renderItem(stylesLarge);
  }

  return renderItem(stylesDefault);
};

type AvatarStyleSheet = {
  avatarBox: {
    width: number;
    height: number;
    borderRadius: number;
    zIndex?: number;
  };
  labelTBox: {
    width: number;
    height: number;
    right: number;
  };
};

const stylesDefault = StyleSheet.create<AvatarStyleSheet>({
  avatarBox: {
    width: 48,
    height: 48,
    borderRadius: 24,
    zIndex: -1,
  },
  labelTBox: {
    width: 12,
    height: 12,
    right: 0.8,
  },
});

const stylesXS = StyleSheet.create<AvatarStyleSheet>({
  avatarBox: {
    width: 24,
    height: 24,
    borderRadius: 16,
  },
  labelTBox: {
    width: 10,
    height: 10,
    right: 0,
  },
});

const stylesSmall = StyleSheet.create<AvatarStyleSheet>({
  avatarBox: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  labelTBox: {
    width: 10,
    height: 10,
    right: 0,
  },
});

const stylesMedium = StyleSheet.create<AvatarStyleSheet>({
  avatarBox: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  labelTBox: {
    width: 10,
    height: 10,
    right: 1.9,
  },
});

const stylesLarge = StyleSheet.create<AvatarStyleSheet>({
  avatarBox: {
    width: 56,
    height: 56,
    borderRadius: 28,
  },
  labelTBox: {
    width: 12,
    height: 12,
    right: 1.9,
  },
});

const stylesForMemberListSummary = StyleSheet.create({
  avatarBox:
    Platform.OS === 'web'
      ? {
          width: Dimensions.get('window').width < 1052 ? 26 : 32,
          height: Dimensions.get('window').width < 1052 ? 26 : 32,
          borderRadius: Dimensions.get('window').width < 1052 ? 13 : 16,
        }
      : { width: 24, height: 24 },
  avatar:
    Platform.OS === 'web'
      ? {
          width: 30,
          height: 30,
          borderRadius: 15,
        }
      : {
          width: 22,
          height: 22,
          borderRadius: 11,
        },
});

export default Avatar;
