import { TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import { Task } from '@graphql/generated';
import theme from '@themes/theme';

const TAG_WIDTH = 100;

interface TaskSearchResultProps {
  task: Task;
  searchValue: string;
  onPress: () => void;
}

export const TaskSearchResult: React.FC<TaskSearchResultProps> = ({
  task,
  searchValue = '',
  onPress,
}) => {
  const { name, project } = task;
  const { name: projectName } = project;

  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        borderColor='grey02'
        borderWidth={1}
        justifyContent='space-between'
        flexDirection='row'
        padding='m'>
        <Text
          variant='body'
          color='black'
          style={{ flex: 1 }}
          ellipsizeMode='tail'
          numberOfLines={1}
          marginRight='s'>
          <Highlighter
            autoEscape
            highlightStyle={{
              color: theme.colors.greenSecondary,
              backgroundColor: theme.colors.background,
            }}
            searchWords={searchValue.split(' ')}
            textToHighlight={name}
          />
        </Text>

        <Box flexDirection='row' alignItems='center'>
          <Box style={{ width: TAG_WIDTH }} marginRight='m'>
            <Tag
              label={projectName}
              variant='folder'
              prefix={
                <Icon name='Folder' variant='xs' color='greenSecondary' />
              }
              padding='xxs'
              borderRadius='xxs'
            />
          </Box>

          <Box
            style={{ width: 24, height: 24 }}
            borderRadius='xxxs'
            backgroundColor='black'
            alignItems='center'
            justifyContent='center'>
            <Icon name='TT' />
          </Box>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};
