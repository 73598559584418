import { FlashList } from '@shopify/flash-list';
import React, { useState } from 'react';

import { Box, ShadowBox, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import theme from '@themes/theme';

export type ProjectsFiltersPopupProps = {
  onSelectMenu: (menu: string) => void;
  listMenu: string[];
  selectedMenu: string;
};
const ESTIMATED_CONTACT_ITEM_SIZE = 38;
export const ProjectsFiltersPopup: React.FC<ProjectsFiltersPopupProps> = ({
  onSelectMenu,
  listMenu,
  selectedMenu,
}) => {
  const [hoveredId, setHoveredId] = useState(selectedMenu);

  return (
    <ShadowBox
      flex={1}
      width={131}
      height={138}
      alignItems='flex-start'
      justifyContent='center'
      borderRadius='xs'
      backgroundColor='white'
      variant='base'>
      <Box
        mt='s'
        mb='s'
        backgroundColor='white'
        flex={1}
        style={{ maxHeight: 138, width: 131, height: 138 }}>
        <FlashList
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          ListFooterComponent={() => <Box marginBottom='m' />}
          data={listMenu}
          renderItem={({ item }) => (
            <Box
              style={{
                paddingVertical: 9,
                backgroundColor:
                  hoveredId !== '' && hoveredId == item
                    ? theme.colors.grey01
                    : theme.colors.white,
              }}
              onMouseEnter={() => {
                setHoveredId(item);
                onSelectMenu(item);
              }}>
              <Box
                flexDirection='row'
                alignItems='center'
                justifyContent='space-between'>
                <Box mr='m' marginLeft='m' flex={1}>
                  <Text variant='webBodySecondary' color='textPrimary'>
                    {item}
                  </Text>
                </Box>
                <Box
                  width={20}
                  height={20}
                  marginRight='xs'
                  alignItems='center'
                  justifyContent='center'
                  pointerEvents='none'>
                  <Icon name='ChevronRight' color='textPrimary' />
                </Box>
              </Box>
            </Box>
          )}
          estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
        />
      </Box>
    </ShadowBox>
  );
};
