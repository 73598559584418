import React, { useEffect, useState } from 'react';

import ProjectList from '@components/ProjectList/ProjectList';
import { Box } from '@components/Restyle/index';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { Project } from '@graphql/generated';
import { useListProjectsFromQuery } from '@hooks/useListProjectsFromQuery';
import useSearch from '@hooks/useSearch';
import { useAppNavigation } from '@navigation/useAppNavigation';

export const ArchivedProjects: React.FC = () => {
  const navigation = useAppNavigation();

  const { search, filter } = useSearch();
  const { projects, loading, refreshControl, fetchFromCursor, refreshing } =
    useListProjectsFromQuery({
      archivesOnly: true,
    });

  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);

  useEffect(() => {
    if (filter.length > 0) {
      setFilteredProjects([]);
      return;
    }

    if (search) {
      setFilteredProjects(filterByBody());
      return;
    }
    setFilteredProjects(projects);
  }, [search, filter, loading]);

  if (loading) {
    return <ActivityIndicatorLoading />;
  }

  const filterByBody = () => {
    return projects?.filter((m) =>
      m?.name?.toLowerCase().includes(search.toLowerCase())
    );
  };

  return (
    <Box flex={1}>
      <ProjectList
        projects={filteredProjects}
        onClick={navigation.navigateToProject}
        expanded={false}
        highlightedValue={search}
        loading={loading}
        alphabetical={false}
        fetchMore={fetchFromCursor}
        refreshing={refreshing}
        refreshControl={refreshControl}
      />
    </Box>
  );
};
