import { UploadProgressData } from 'expo-file-system/build/FileSystem.types';

import { Document, useCreateDirectUploadsMutation } from '@graphql/generated';
import {
  getFileInfoPromises,
  uploadFilesAsync,
  createDirectUploadAttributesFromFileInfo,
} from '@utils/fileUtils';

const useFileProcessor = () => {
  const [createDirectUploads] = useCreateDirectUploadsMutation();

  const processFiles = async (
    documents: Document[],
    callback?: (
      documentClientId: string,
      uploadProgress: UploadProgressData
    ) => void
  ): Promise<{ blobId: string; clientId: string }[]> => {
    return getFileInfoPromises(documents)
      .then(createDirectUploadAttributesFromFileInfo)
      .then((directUploads) => {
        return createDirectUploads({
          variables: { attributes: directUploads },
        });
      })
      .then(({ data }) => {
        const { createDirectUploads: createDirectUploadsData } = data || {
          createDirectUploads: [],
        };
        return uploadFilesAsync(createDirectUploadsData, documents, callback);
      });
  };

  return { processFiles };
};

export default useFileProcessor;
