import { useNavigation } from '@react-navigation/native';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal, { ModalItemType } from '@components/Modals/Modal';
import { SmallButtonType } from '@components/shared/Button/SmallButton';
import { FormValues } from '@components/Tasks/TaskFormStack';
import { TaskStatus } from '@graphql/generated';

export const TaskDetailEditStatus = () => {
  const { values, isSubmitting, submitForm, setFieldValue } =
    useFormikContext<FormValues>();
  const { status } = values;

  const { t } = useTranslation();

  const navigation = useNavigation();

  type StatusType<T> = Partial<T> & { value: TaskStatus };
  const statuses: StatusType<SmallButtonType>[] = [
    {
      title: t('models:tasks.create.status.notStarted'),
      value: TaskStatus.NotStarted,
      prefixIcon: 'MinusSquare',
    },
    {
      title: t('models:tasks.create.status.ongoing'),
      value: TaskStatus.Ongoing,
      prefixIcon: 'RefreshCw',
    },
    {
      title: t('models:tasks.create.status.onHold'),
      value: TaskStatus.OnHold,
      prefixIcon: 'Pause',
    },
    {
      title: t('models:tasks.create.status.completed'),
      value: TaskStatus.Completed,
      prefixIcon: 'CheckSquare',
    },
    {
      title: t('models:tasks.create.status.overdue'),
      value: TaskStatus.Overdue,
      prefixIcon: 'Frown',
    },
  ];
  const [selectedStatus, setSelectedStatus] = useState(status);
  const navItems: ModalItemType[] = statuses.map<ModalItemType>((item) => ({
    iconName: item.prefixIcon,
    title: item.title!,
    trailingIconName: item.value == selectedStatus ? 'Check' : undefined,
    onSelect: () => {
      setSelectedStatus(item.value);
    },
  }));

  return (
    <Modal
      goBack={() => {
        navigation.goBack();
      }}
      showHeader={true}
      headerTitle={t('models:tasks.create.status.title')}
      items={navItems}
      headerSaveDisable={selectedStatus === status || isSubmitting}
      onSave={() => {
        setFieldValue('status', selectedStatus);
        setFieldValue(
          'completedAt',
          selectedStatus === TaskStatus.Completed ? new Date() : null
        );
        submitForm();
      }}
    />
  );
};
