import { array, object, string } from 'yup';

import { locationSchema } from '@schemas/locationSchema';
import 'yup-phone';

const saveProfileSchema = object({
  avatar: object().nullable(),
  name: string()
    .trim()
    .matches(/\s+/, 'Last Name is required.')
    .required('Please fill in the name field'),
  email: string().required('Please fill in the email field'),
  phoneNumber: string()
    .phone(undefined, false, 'The phone number is invalid.')
    .required('Please fill in the phone number field'),
  location: locationSchema.nullable(),
  skillIds: array().of(string()).ensure(),
  timeZone: string().required('Please select timeZone'),
});

export default saveProfileSchema;
