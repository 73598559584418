import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import { useEffect, useRef, useState } from 'react';
import {
  Animated,
  LayoutChangeEvent,
  Platform,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import theme from '../../../themes/theme';
import { Box, Text } from '../Restyle';

export type CustomerTabbarProps = {
  spacing?: number;
  initialRouteName?: string;
  contentContainerStyle?: StyleProp<ViewStyle>;
};

type IProps = MaterialTopTabBarProps & CustomerTabbarProps;

const CustomTabBar = ({
  state,
  descriptors,
  navigation,
  spacing = Platform.OS === 'web' ? theme.spacing.m : 10,
  initialRouteName,
  contentContainerStyle,
}: IProps): JSX.Element => {
  const scaleX_value = useRef(new Animated.Value(0)).current;
  const translateX_value = useRef(new Animated.Value(0)).current;
  let isAnimating = false;

  type Position = {
    index: number;
    width: number;
    x: number;
  };

  const [positions, setPositions] = useState<Position[]>([]);

  // get the label widths on mount
  const onLayout = (event: LayoutChangeEvent, index: number, label: string) => {
    const { width, x } = event.nativeEvent.layout;

    const calcX = x + width / 2;

    setPositions((pos) => [...pos, { index: index, width: width, x: calcX }]);

    if (initialRouteName && initialRouteName === label) {
      scaleX_value.setValue(width);
      translateX_value.setValue(calcX);
    }

    if (!initialRouteName && index === 0) {
      scaleX_value.setValue(width);
      translateX_value.setValue(calcX);
    }
  };

  useEffect(() => {
    const newPos = positions.findIndex((i) => i.index === state.index);
    positions.length > 0 && animate(newPos);
  }, [state, spacing]);

  const getNewPosX = (position: Position) => {
    if (
      Platform.OS !== 'web' &&
      spacing !== theme.spacing.m &&
      spacing !== theme.spacing.l &&
      position.index !== 0
    ) {
      return position.x - theme.spacing.s * position.index;
    } else {
      return position.x;
    }
  };

  const animate = (newPos: number) => {
    isAnimating = true;
    Animated.parallel([
      Animated.timing(translateX_value, {
        toValue: getNewPosX(positions[newPos]),
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(scaleX_value, {
        toValue: positions[newPos].width,
        duration: 100,
        useNativeDriver: true,
      }),
    ]).start(() => {
      isAnimating = false;
    });
  };

  // basic labels as suggested by react navigation
  const labels = state.routes.map((route, index) => {
    const { options } = descriptors[route.key];
    const label = route.params?.tabBarLabel || options.tabBarLabel;
    const isFocused = state.index === index;

    const onPress = () => {
      if (isAnimating) {
        return;
      }
      const event = navigation.emit({
        type: 'tabPress',
        target: route.key,
        canPreventDefault: true,
      });

      if (!isFocused && !event.defaultPrevented) {
        // The `merge: true` option makes sure that the params inside the tab screen are preserved
        // eslint-disable-next-line
        // @ts-ignore
        navigation.navigate({ name: route.name, merge: true });
      }
    };

    return (
      <Box
        key={route.key}
        alignItems='center'
        justifyContent='center'
        style={{ marginRight: spacing }}
        onLayout={(event) => onLayout(event, index, label)}>
        <TouchableOpacity
          accessibilityRole='button'
          accessibilityState={isFocused ? { selected: true } : {}}
          onPress={onPress}>
          <Box accessibilityLabel={label} paddingVertical='xs'>
            <Text
              variant='labelEmphasized'
              color={isFocused ? 'black' : 'grey04'}>
              {label}
            </Text>
          </Box>
        </TouchableOpacity>
      </Box>
    );
  });

  return (
    <Box height={42} flex={1}>
      <Animated.View style={{ ...styles.container, ...contentContainerStyle }}>
        {labels}
        <Animated.View
          style={[
            styles.indicator,
            {
              transform: [
                { translateX: translateX_value },
                { scaleX: scaleX_value },
              ],
            },
          ]}
        />
      </Animated.View>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 42,
  },
  indicator: {
    backgroundColor: theme.colors.greenSecondary,
    height: 2,
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    width: 1,
  },
});

export default CustomTabBar;
