import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableWithoutFeedback } from 'react-native';

import Avatar from '@components/Avatar/Avatar';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import Tag from '@components/shared/Tags/Tag';
import { User } from '@graphql/generated';
import useSearch from '@hooks/useSearch';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { ProjectTagType } from '@src/types/project';
import { TaskTagType } from '@src/types/task';

export type TagsCollectionType = {
  tasks: TaskTagType[];
  project: ProjectTagType;
  author: User;
};

export interface TagsCollectionProps {
  filterVal?: string;
  tagsCollection: TagsCollectionType;
  showAuthor: boolean;
  onDelete?: (index: number) => void;
  onDeleteProject?: () => void;
  onLongPress?: () => void;
  borderless?: boolean;
  isRow?: boolean;
  fromImagesPreview?: boolean;
}

const TagsCollection: React.FC<TagsCollectionProps> = ({
  filterVal = '',
  tagsCollection,
  showAuthor,
  onDelete,
  onDeleteProject,
  onLongPress,
  borderless,
  isRow,
  fromImagesPreview = false,
}) => {
  const { tasks, project, author } = tagsCollection;
  const { name } = author;
  const navigation = useAppNavigation();
  const { navigateToTask } = useAppNavigation();
  const taskLength = tasks.filter(
    (t) => t.deletedAt && t.deletedAt !== null
  ).length;
  const { t } = useTranslation();
  const { search } = useSearch();
  filterVal = filterVal ? filterVal : search;
  //console.log('in tags collection :::::::::::', project);
  return (
    <>
      <TouchableWithoutFeedback onLongPress={onLongPress}>
        <Box flexDirection='row' alignItems='center'>
          {showAuthor && (
            <Box marginRight='s'>
              <TouchableWithoutFeedback onLongPress={onLongPress}>
                <Avatar avatar={author.avatar} size='small' label={name} />
              </TouchableWithoutFeedback>
            </Box>
          )}
          {project?.name && (
            <Box
              flexWrap='wrap'
              flexDirection={fromImagesPreview ? 'row' : 'column'}
              borderColor='grey02'
              borderWidth={!borderless ? 1 : 0}
              borderRadius='xs'
              paddingHorizontal={!isRow ? 'xs' : undefined}
              paddingTop='xs'>
              <Box maxWidth='100%' flexDirection='row'>
                <Tag
                  filterVal={filterVal}
                  labelVariant='labelSmall'
                  variant='secondaryReversed'
                  prefixMarginRight='xxs'
                  padding='xxs'
                  marginBottom='xs'
                  borderRadius='xxs'
                  label={project?.name}
                  prefix={<Icon variant='xs' name='Folder' color='white' />}
                  onPress={() => {
                    navigation.navigateToProject(project);
                  }}
                  onLongPress={onLongPress}
                  onDismiss={tasks.length === 0 ? onDeleteProject : undefined}
                />
                <Box flex={1}></Box>
              </Box>
              {tasks?.map((t, i) => (
                <Box
                  key={t.id}
                  maxWidth='100%'
                  flexDirection='row'
                  ml={fromImagesPreview ? 'xxs' : 'none'}>
                  <Tag
                    filterVal={filterVal}
                    labelVariant='labelSmall'
                    prefixMarginRight='xxs'
                    variant={t.deletedAt ? 'folder' : 'reversed'}
                    padding='xxs'
                    borderRadius='xxs'
                    label={t.name}
                    marginBottom='xs'
                    prefix={
                      <Icon
                        variant='xs'
                        name='Hash'
                        color={t.deletedAt ? 'grey04' : 'white'}
                      />
                    }
                    onPress={() => {
                      !t.deletedAt && navigateToTask(t);
                    }}
                    onLongPress={() => !t.deletedAt && onLongPress}
                    onDismiss={onDelete ? () => onDelete(i) : undefined}
                    type='tasks'
                    deletedAt={t.deletedAt}
                  />
                  <Box flex={1}></Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </TouchableWithoutFeedback>

      {taskLength > 0 && (
        <Box
          flex={1}
          flexDirection='row'
          alignSelf='flex-end'
          marginBottom='none'
          style={{ cursor: 'default' }}
          marginVertical='s'>
          <Icon name='Trash2' color='grey03' width={18} height={18} />
          <Text variant='link' color='grey04' marginLeft='xxs'>
            {taskLength == 1
              ? t('shared:taskSingleDelete')
              : t('shared:taskMultiDelete')}
          </Text>
          <Box flex={1}></Box>
        </Box>
      )}
    </>
  );
};

export default TagsCollection;
