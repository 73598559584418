import { PlatformPressable } from '@react-navigation/elements';
import { ComponentProps, ReactNode, useState } from 'react';
import { MouseEvent } from 'react-native';

type Props = Omit<ComponentProps<typeof PlatformPressable>, 'children'> & {
  children: (data: {
    /** `true` when the mouse curser is within the container or the platform is not web */
    hover: boolean;
  }) => ReactNode;
};

/** Provides access to an object that exposes the hover state of the component */
export const Hoverable = ({ children, ...props }: Props) => {
  const [hover, setHover] = useState(false);
  const hoverIn = (event: MouseEvent) => {
    setHover(true);
    props.onHoverIn?.(event);
  };
  const hoverOut = (event: MouseEvent) => {
    setHover(false);
    props.onHoverOut?.(event);
  };
  return (
    <PlatformPressable
      {...props}
      onHoverIn={hoverIn || props.onHoverIn}
      onHoverOut={hoverOut || props.onHoverOut}
      accessible={false}
      pressOpacity={1}>
      {children({ hover })}
    </PlatformPressable>
  );
};
