import * as Clipboard from 'expo-clipboard';
import React, { ComponentProps, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-notifications';

import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { PopupIcon } from '@components/Web/PopupIcon';
import {
  ListProjectsDocument,
  ListTasksDocument,
  Project,
  useCreateInviteMutation,
  useLeaveProjectMutation,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import { useProjectMembership } from '@hooks/useProjectMembership';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { useAppNavigation } from '@navigation/useAppNavigation';
import theme from '@themes/theme';

import { ConfirmModal } from './ConfirmModal';

type Props = Pick<ComponentProps<typeof PopupIcon>, 'backgroundColor'> & {
  project: Project;
  popPosition?: string;
  popSize?: 'big' | 'small';
};

export const ProjectDetailModal = (props: Props) => {
  const navigation = useAppNavigation();
  const { setShowProjectColorAndIconModal } = useWebDrawerNavigator();
  const { setIsCreateProjectOpen, setEditProjectId } = useWebDrawer();
  const [showLeaveProjectModal, setShowLeaveProjectModal] = useState(false);
  const toastRef = useRef();
  const { t } = useTranslation('models');

  const { project, popPosition, popSize } = props;

  const { members } = project || { members: [] };
  const { isAdminOrHigher, isEditorOrHigher, isMember } =
    useProjectMembership(project);
  const { me } = useMe();

  const isTeamMember = project?.currentUserTeamRole === 'MEMBER';
  const isTeamOwnerOrAdmin =
    project?.currentUserTeamRole === 'OWNER' ||
    project?.currentUserTeamRole === 'ADMIN';

  const isOwner = me?.id === project?.creator.id;

  const [leaveProject] = useLeaveProjectMutation({
    onCompleted: () => {
      navigation.navigate('projects-stack', {
        screen: 'allprojects',
      });
    },
    refetchQueries: [
      'listProjectsRecent',
      'listProjects',
      { query: ListTasksDocument },
      { query: ListProjectsDocument },
    ],
  });

  const menuList: ComponentProps<typeof PopupIcon>['popupProps']['menuList'] = [
    {
      iconName: 'Pin',
      name: 'Pin',
      isComingSoon: true,
    },
    ...(isAdminOrHigher || isTeamOwnerOrAdmin
      ? [
          {
            iconName: 'Edit',
            name: t('projects.edit.title'),
            onSelect: () => {
              setEditProjectId(project.id);
              setIsCreateProjectOpen(true);
            },
          },
          {
            iconName: 'Image',
            name: t('projects.edit.setColorAndIcon'),
            onSelect: () => {
              setShowProjectColorAndIconModal(true);
              navigation.navigate('projects-stack', {
                screen: 'colorSelect',
                params: { projectId: project.id },
              });
            },
          },
        ]
      : []),
    ...(isEditorOrHigher || isTeamOwnerOrAdmin
      ? [
          {
            iconName: 'Duplicate',
            name: 'Duplicate',
            onSelect: () => {
              setShowProjectColorAndIconModal(true);
              navigation.navigate('projects-stack', {
                screen: 'duplicate',
                params: { projectId: project.id },
              });
            },
          },
        ]
      : []),
    ...((isEditorOrHigher || isTeamOwnerOrAdmin) && members && members.length
      ? [
          {
            iconName: 'Chat',
            name: 'Announcement',
            isComingSoon: true,
          },
        ]
      : []),
    {
      iconName: 'Share',
      name: 'Share',
      onSelect: () => {
        createInviteMutation({
          variables: {
            projectId: project.id,
          },
        });
      },
    },
    ...(!isTeamOwnerOrAdmin || (isTeamOwnerOrAdmin && isMember && !isOwner)
      ? [
          {
            iconName: 'LogOut',
            name: 'Leave',
            onSelect: () => {
              setShowLeaveProjectModal(true);
            },
          },
        ]
      : []),
    ...(isAdminOrHigher || !isTeamMember
      ? [
          {
            iconName: 'Archive',
            name: 'Archive',
            isComingSoon: true,
          },
          {
            iconName: 'Trash2',
            name: 'Delete',
            isRed: true,
            isComingSoon: true,
          },
        ]
      : []),
  ];

  const [createInviteMutation] = useCreateInviteMutation({
    variables: {},
    onCompleted: (data) => {
      copyToClipboard(
        data?.createInvite.replace(
          'register/register-with-email',
          'invited-user'
        )
      );
    },
  });

  const copyToClipboard = async (link: string) => {
    toastRef.current.show('Copied', {
      duration: 2000,
    });

    await Clipboard.setStringAsync(link || '');
  };

  return (
    <>
      <PopupIcon
        enableHoverEffect={false}
        {...props}
        name='MoreVertical'
        variant={popSize === 'big' ? 'l' : 's'}
        paddingHorizontal={popSize === 'big' ? 'xxs' : 'xs'}
        paddingVertical={popSize === 'big' ? 'xxs' : 'm'}
        color='textPrimary'
        popupProps={{
          menuList,
          position:
            popPosition === 'top' ? ['left', 'top'] : ['left', 'bottom'],
          offset: [0, theme.spacing.m],
        }}
      />
      {showLeaveProjectModal && (
        <ConfirmModal
          showModal={showLeaveProjectModal}
          onClose={() => setShowLeaveProjectModal(false)}
          onPress={() => {
            leaveProject({
              variables: {
                id: project.id || '',
              },
            });
          }}
          buttonText={t('models:leaveProject.title')}
          title={t('models:leaveProject.title')}
          message={t('models:leaveProject.message')}
        />
      )}
      <Toast ref={toastRef} />
    </>
  );
};
