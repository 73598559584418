import React, { useMemo } from 'react';
import { TouchableOpacity, Platform } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import MemberListSummary from '@components/MemberListSummary/MemberListSummary';
import { Box, Text } from '@components/Restyle/index';
import Icon from '@components/shared/Icon/Icon';
import { TaskMember } from '@graphql/generated';
import theme from '@themes/theme';

export interface TaskTagProps {
  taskName: string;
  projectName: string;
  members: TaskMember;
  filterVal?: string;
  onSelect?: () => void;
  showMember?: boolean;
  showProject?: boolean;
}

const TaskTag = ({
  taskName,
  members,
  filterVal = '',
  onSelect,
  showMember,
}: TaskTagProps) => {
  const renderIcon = useMemo(() => {
    return <Icon name='Hash' variant='s' color='greenSecondary' />;
  }, []);

  const renderHighlighter = useMemo(() => {
    return (
      <Highlighter
        autoEscape
        highlightStyle={{
          color: theme.colors.black,
          fontFamily: 'Inter_600SemiBold',
          backgroundColor: theme.colors.yellowBright,
        }}
        searchWords={filterVal?.split(' ') || ''}
        textToHighlight={taskName}
      />
    );
  }, [filterVal]);

  const userAvatars = (members: TaskMember[]) => {
    return (
      members?.map((u) => {
        const { user } = u;
        const { name, id, avatar } = user;
        return {
          id: id,
          label: name,
          avatar: avatar,
          size: 'memberList',
        };
      }) || []
    );
  };

  return (
    <TouchableOpacity onPress={onSelect} accessibilityLabel={taskName}>
      <Box flexDirection='row' alignItems='center'>
        {renderIcon}
        <Box flex={1} marginHorizontal='xs'>
          <Text variant='bodySecondary' numberOfLines={2} marginRight='m'>
            {renderHighlighter}
          </Text>
        </Box>
        {showMember && (
          <Box
            flexDirection='row'
            style={{ maxWidth: Platform.OS === 'web' ? '22%' : '16%' }}>
            <Box flexDirection='row'>
              <MemberListSummary
                avatars={userAvatars(members)}
                maxAvatars={2}
              />
            </Box>
          </Box>
        )}
      </Box>
    </TouchableOpacity>
  );
};

export default TaskTag;
