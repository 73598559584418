import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';

import { Box } from '@components/Restyle';
import CreateProfile from '@screens/Auth/CreateProfile';
import CreateProfileForGoogleAndApple from '@screens/Auth/CreateProfileForGoogleAndApple.web';
import RegisterWithPhoneVerifycodeNewWeb from '@screens/Auth/RegisterWithPhoneVerifycodeNew.web';
import SignUpWithEmail from '@screens/Auth/SignUpWithEmail.web';
import CreateTeam from '@screens/Teams/CreateTeam.web';

type RegisterStackParamsList = {
  'signup-with-email': undefined;
  'create-profile': undefined;
  'create-profile-for-google-and-apple': undefined;
  'create-team': undefined;
  'register-with-phone-verify-code': { isRegiserWithPhoneNumber: boolean };
};

export type RegisterStackScreenProps<T extends keyof RegisterStackParamsList> =
  StackScreenProps<RegisterStackParamsList, T>;

const Stack = createStackNavigator<RegisterStackParamsList>();

const RegisterStackNew = () => {
  return (
    <Box flex={1}>
      <Stack.Navigator>
        <Stack.Screen
          name='signup-with-email'
          component={SignUpWithEmail}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name='create-profile'
          component={CreateProfile}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name='create-team'
          component={CreateTeam}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name='create-profile-for-google-and-apple'
          component={CreateProfileForGoogleAndApple}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name='register-with-phone-verify-code'
          component={RegisterWithPhoneVerifycodeNewWeb}
          options={{
            headerShown: false,
          }}
          initialParams={{ isRegiserWithPhoneNumber: false }}
        />
      </Stack.Navigator>
    </Box>
  );
};

export default RegisterStackNew;
