import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions, Platform } from 'react-native';

import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

const EmptyStateNoProjectTag: React.FC = () => {
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();
  const EMPTY_ICON_WIDTH = 132;
  const EMPTY_ICON_HEIGHT = 118;
  const EMPTY_ICON_WIDTH_MOBILE = width > 375 ? width / 3 : width / 1.8;
  const EMPTY_ICON_HEIGHT_MOBILE = height > 750 ? height / 7 : height / 5;
  return (
    <Box
      flex={1}
      alignItems='center'
      justifyContent='center'
      alignContent='center'
      paddingBottom='l'>
      <Box
        flex={1}
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        flexDirection='column'
        alignSelf='center'>
        <Icon
          paddingBottom='xs'
          name='NoSearchProjectsColor'
          width={
            Platform.OS == 'web' ? EMPTY_ICON_WIDTH : EMPTY_ICON_WIDTH_MOBILE
          }
          height={
            Platform.OS == 'web' ? EMPTY_ICON_HEIGHT : EMPTY_ICON_HEIGHT_MOBILE
          }></Icon>
        <Text variant='body1' textAlign='center' padding='xs' color='grey05'>
          {t('models:chat.chatMenuList.noTag')}
        </Text>
      </Box>
    </Box>
  );
};

export default EmptyStateNoProjectTag;
