import { object, string, array } from "yup";

import { userSchema } from "@schemas/userSchema";

const addMembersSchema = object({
  users: array()
    .of(userSchema)
    .ensure()
    .min(1, "At least 1 member is required")
    .required("required"),
  selectedUserIds: array().of(string()),
});

export default addMembersSchema;
