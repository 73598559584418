import { Chat, ChatDetailFragmentDoc } from '@graphql/generated';
import { useApolloClient } from '@hooks/useApolloClient';

export const useGetChatFromCache = (chatId: Chat['id']) => {
  const { client } = useApolloClient();

  const chat: Chat | undefined | null = client?.cache.readFragment({
    id: `Chat:${chatId}`,
    fragment: ChatDetailFragmentDoc,
    fragmentName: 'ChatDetail',
  });

  return { chat };
};
