import { useTranslation } from 'react-i18next';
import { Linking, Platform } from 'react-native';

import { Alert } from '@components/Alert';
import { Maybe } from '@graphql/generated';

export const useLocation = () => {
  const { t } = useTranslation();
  const title = t('shared:alert');
  const message = t('shared:canNotOpenMap');
  return {
    openAddress: (
      address?: string,
      latitude?: Maybe<number>,
      longitude?: Maybe<number>
    ) => openAddress(title, message, address, latitude, longitude),
  };
};

const openAddress = async (
  title: string,
  message: string,
  address?: string,
  latitude?: Maybe<number>,
  longitude?: Maybe<number>
) => {
  if (address || (latitude && longitude)) {
    const encodedAddress = encodeURIComponent(address || '');
    let url;
    if (Platform.OS === 'ios') {
      url =
        latitude && longitude
          ? `maps:${latitude},${longitude}?q=${encodedAddress}`
          : `http://maps.apple.com/?q=${encodedAddress}`;
    } else if (Platform.OS === 'android') {
      url = `geo:${latitude ? latitude : '0'},${
        longitude ? longitude : '0'
      }?q=${encodedAddress}`;
    } else {
      url = `${process.env.G_MAP_URL}?q=${encodedAddress}`;
      if (latitude && longitude) {
        url += `&ll=${latitude},${longitude}`;
      }
    }

    if (Platform.OS === 'web') {
      window.open(url, '_blank');
    } else {
      Linking.openURL(url).catch((err) => {
        console.error('An error occurred', err);
        Alert.alert(title, message);
      });
    }
  } else {
    Alert.alert(title, message);
  }
};
