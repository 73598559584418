import {
  ListTasksDocument,
  Task,
  TaskStatus,
  useCompleteTaskMutation,
} from '@graphql/generated';
import { useApolloClient } from '@hooks/useApolloClient';

export const useToggleCompleteTask = () => {
  const { client } = useApolloClient();

  const [completeTask] = useCompleteTaskMutation();

  const toggleCompleteTask = (t: Task) => {
    const { id, completedAt } = t;
    const cache = client?.cache;
    if (!cache) return;

    completeTask({
      variables: {
        id,
      },
      optimisticResponse: {
        completeTask: {
          __typename: 'Task',
          id: id,
          completedAt: completedAt ? null : new Date().toISOString(),
          status: completedAt ? TaskStatus.Ongoing : TaskStatus.Completed,
        },
      },
      refetchQueries: [{ query: ListTasksDocument }, 'listTasks'],
    });
  };

  return { toggleCompleteTask };
};
