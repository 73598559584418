import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectModal } from '@components/FormModals/SelectModal';
import { useTasks } from '@hooks/useTasks';
import { useAppNavigation } from '@navigation/useAppNavigation';

export const DependencySelect = () => {
  const { t } = useTranslation();
  const tasks = useTasks();
  const { setFieldValue, setTouched, touched, getFieldProps } =
    useFormikContext();
  const { name: fieldName, value } = getFieldProps('dependencyIds');
  const navigation = useAppNavigation();

  return (
    <SelectModal
      title={t('shared:dependencies')}
      list={tasks}
      showIcon={false}
      onClose={navigation.goBack}
      setSelected={(ids: any) => {
        setFieldValue(fieldName, [...ids]);
        setTouched({ ...touched, [fieldName]: true });
      }}
      value={value}
      emptyContentMessage={t('models:resourceNotFound.message', {
        resource: 'dependency',
        resourcePlural: 'dependencies',
      })}
    />
  );
};
