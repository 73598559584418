import { PlatformPressable } from '@react-navigation/elements';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactType } from '@components/Invite/Contact';
import ContactList from '@components/Invite/ContactList';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { ChatDrawerScreen } from '@components/Web/Drawer/WebDrawerContext';
import {
  useCreateChatMutation,
  useListContactUsersQuery,
  useListUsersLazyQuery,
  User,
} from '@graphql/generated';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { convertUsersToContacts } from '@utils/convertUsersToContacts';

const NewChat = () => {
  const { t } = useTranslation('models');
  const { data: listUsersData } = useListContactUsersQuery();
  const [listUsers, { data: taskTagUsers }] = useListUsersLazyQuery();
  const { listContactUsers } = listUsersData || { listContactUsers: [] };
  const { navigateToChatDrawer: navigateTo } = useAppNavigation();

  const [createChat] = useCreateChatMutation({
    onCompleted: (data) => {
      const { createChat: createChatData } = data;
      navigateTo({
        screen: ChatDrawerScreen.details,
        activeChat: createChatData,
      });
    },
    refetchQueries: ['listChats'],
  });
  const [search, setSearch] = useState<string>('');

  const listUsersToContacts = convertUsersToContacts(
    listContactUsers as User[]
  );

  const listUsersToContactType = convertUsersToContacts(
    (taskTagUsers?.listUsers as User[]) || []
  ).filter((u) => !listUsersToContacts.some((c) => c.id === u.id));

  const sortedListUsersToContacts = listUsersToContacts.slice().sort((a, b) => {
    return a.firstName?.localeCompare(b.firstName);
  });
  const openChat = ({ firstName, lastName, id }: ContactType) => {
    const chatName = [firstName, lastName].filter((item) => !item).join(' ');

    createChat({
      variables: {
        attributes: {
          name: chatName,
          userIds: [id],
        },
      },
    });
  };

  useEffect(() => {
    search && listUsers({ variables: { term: search } });
  }, [search]);

  return (
    <Box flex={1}>
      <Box marginHorizontal='m' flexDirection='row' alignItems='center'>
        <SearchInput onTextChanged={setSearch} value={search} />
      </Box>
      {!search && (
        <Box m='m'>
          <PlatformPressable
            onPress={() =>
              navigateTo({ screen: ChatDrawerScreen.newGroupChat })
            }>
            <Box
              flexDirection='row'
              justifyContent='flex-start'
              alignItems='center'>
              <Icon name='Users' variant='m' backgroundColor='grey02' />
              <Text ml='m' variant='labelEmphasized'>
                {t('chat.labels.newGroupChat')}
              </Text>
            </Box>
          </PlatformPressable>
        </Box>
      )}
      <Box flex={1} marginTop='m'>
        <ContactList
          contacts={sortedListUsersToContacts}
          taskTagUsers={search ? listUsersToContactType : undefined}
          filterVal={search}
          onPress={(item: ContactType) => openChat(item)}
        />
      </Box>
    </Box>
  );
};

export default NewChat;
