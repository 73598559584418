import {
  createRestyleComponent,
  createVariant,
  color,
  border,
  spacing,
  ColorProps,
  BorderProps,
  SpacingProps,
  VariantProps,
} from '@shopify/restyle';
import React, { ComponentProps } from 'react';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import theme, { Theme } from '@themes/theme';

type RestyleProps = ColorProps<Theme> &
  BorderProps<Theme> &
  SpacingProps<Theme> &
  VariantProps<Theme, 'tagVariants'>;

const RestyleTag = createRestyleComponent<RestyleProps, Theme>([
  color,
  border,
  spacing,
  createVariant({ themeKey: 'tagVariants' }),
]);

export type MultiTagsProps = RestyleProps & {
  labelList: { id: string; name?: string }[];
  labelVariant?: 'labelSmall' | 'metadata' | 'label' | 'labelCompletedTask';
  prefixMarginRight?: keyof typeof theme.spacing;
  isPill?: boolean;
  onPressClose: (id: string) => void;

  prefixText?: string;
  filterVal?: string;
  type?: string;
  deletedAt?: string;
  isCompleted?: boolean;
};

export const MultiTags: React.FC<MultiTagsProps> = ({
  labelList,
  labelVariant = 'metadata',
  onPressClose,
  prefixText = '',
  ...rest
}: MultiTagsProps) => {
  const color: ComponentProps<typeof Text>['color'] =
    (theme.tagVariants[rest.variant as keyof typeof theme.tagVariants]
      ?.color as ComponentProps<typeof Text>['color']) ?? 'blue';

  return (
    <RestyleTag {...rest}>
      {labelList.map((c, index) => (
        <Box key={c.id} flexDirection='row'>
          <Text
            marginLeft={index > 0 ? 'xs' : 'none'}
            variant={labelVariant}
            color={color}
            numberOfLines={1}
            style={{
              flexShrink: 1,
              alignSelf: 'flex-start',
            }}>
            {(index === 0 ? prefixText : '') + c.name}
          </Text>
          <Box
            width={16}
            height={16}
            marginLeft='xxs'
            alignItems='center'
            justifyContent='center'>
            <Icon
              name='X2'
              height={16}
              onPress={() => {
                onPressClose(c.id);
              }}
            />
          </Box>
        </Box>
      ))}
    </RestyleTag>
  );
};

export default MultiTags;
