import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Platform, View } from 'react-native';

import { AlphaSortSlider } from '@components/AlphaSortSlider/AlphaSortSlider';
import EmptyStateNoSearch from '@components/EmptyState/EmptyStateNoSearch';
import { Box, Text } from '@components/Restyle';
import theme from '@themes/theme';

import Contact, { ContactType } from './Contact';

interface ContactListProps {
  contacts: ContactType[];
  taskTagUsers?: ContactType[];
  onPress: (item: ContactType) => void;
  filterVal?: string;
  alphabetize?: boolean;
  headerSpace?: keyof typeof theme.spacing;
  footerSpace?: number;
  isEditing?: boolean;
  isInviting?: boolean;
  loading?: boolean;
  selected?: string[];
  shouldDisplayPhoneContactLabel?: boolean;
  disabledRoles?: string[];
  showMessageIcon?: boolean;
  onMessagePress?: (item: ContactType) => void;
  excludeList?: ContactType[];
  showEmptyStateNoSearch?: boolean;
}

const ESTIMATED_CONTACT_ITEM_SIZE = 40;

const ContactList: React.FC<ContactListProps> = ({
  contacts,
  taskTagUsers,
  filterVal,
  alphabetize,
  onPress,
  headerSpace,
  footerSpace,
  isEditing,
  isInviting,
  loading,
  selected,
  shouldDisplayPhoneContactLabel,
  disabledRoles,
  showMessageIcon = false,
  showEmptyStateNoSearch = false,
  onMessagePress,
  excludeList = [],
}) => {
  const { t } = useTranslation();
  const [contactList, setContactList] = useState<ContactType[]>(contacts);
  const [filteredContacts, setFilteredContacts] =
    useState<ContactType[]>(contacts);
  const [alphabetizedSectionList, setAlphabetizedSectionList] = useState<
    (ContactType | string)[]
  >([]);
  const [alphabetizeList, setAlphabetizeList] = useState<string[]>([]);
  const [selectedContact, setSelectedContact] = useState<
    ContactType | undefined
  >();

  useEffect(() => {
    const newlist = excludeContacts(contacts);
    setContactList(newlist);
  }, [excludeList, contacts]);

  useEffect(() => {
    if (filterVal) {
      const filtered = contactList.filter((contact) => {
        return contact.displayName
          .toLowerCase()
          .includes(filterVal.toLowerCase());
      });
      setFilteredContacts(filtered);
    } else {
      setFilteredContacts(contactList);
    }
  }, [filterVal, contactList]);

  useEffect(() => {
    getAlphabetizedSectionList();
  }, [filteredContacts.length]);

  const excludeContacts = (contacts: ContactType[]) => {
    if (excludeList.length > 0) {
      const newContacts = contacts.filter(
        (contact) => !excludeList.some((excluded) => excluded.id === contact.id)
      );
      return newContacts;
    }
    return contacts;
  };

  const flashListRef = useRef<FlashList<string>>(null);

  const sortContacts = () => {
    let numsSpecials: ContactType[] = []; // numbers and ASCII Before A
    let letters: ContactType[] = []; // letters
    let asterisks: ContactType[] = []; //ASCII after z

    filteredContacts.forEach((s) => {
      if (s.displayName) {
        if (s.displayName[0].match(/[a-z]/i)) {
          // check first letter
          letters.push(s);
        } else if (s.displayName[0].charCodeAt(0) < 65) {
          numsSpecials.push(s);
        } else {
          asterisks.push(s);
        }
      }
    });

    letters = letters.sort((a, b) => {
      return (
        a.displayName[0].toUpperCase().charCodeAt(0) -
        b.displayName[0].toUpperCase().charCodeAt(0)
      );
    });
    numsSpecials = numsSpecials.sort((a, b) => {
      return a.displayName[0].charCodeAt(0) - b.displayName[0].charCodeAt(0);
    });
    asterisks = asterisks.sort((a, b) => {
      return a.displayName[0].charCodeAt(0) - b.displayName[0].charCodeAt(0);
    });

    return { letters, numsSpecials, asterisks };
  };

  const getAlphabetizedSectionList = () => {
    const tmpAlphabetizeList = [];
    const { letters, numsSpecials, asterisks } = sortContacts();

    const formattedAlpha: (ContactType | string)[] = [];
    const formattedNumSpecial: (ContactType | string)[] = [];
    const formattedAsterisk: (ContactType | string)[] = [];

    if (numsSpecials.length > 0) {
      formattedNumSpecial.push('#');
      numsSpecials.forEach((obj) => {
        formattedNumSpecial.push(obj);
      });
    }
    tmpAlphabetizeList.push('#');

    letters.forEach((obj) => {
      const key = obj.displayName.charAt(0).toUpperCase();

      if (formattedAlpha.includes(key)) {
        formattedAlpha.push(obj);
      } else {
        formattedAlpha.push(key);
        formattedAlpha.push(obj);
      }
    });
    for (
      let charCode = 'A'.charCodeAt(0);
      charCode <= 'Z'.charCodeAt(0);
      charCode++
    ) {
      tmpAlphabetizeList.push(String.fromCharCode(charCode));
    }

    if (asterisks.length > 0) {
      formattedAsterisk.push('*');
      asterisks.forEach((obj) => {
        formattedAsterisk.push(obj);
      });
    }
    tmpAlphabetizeList.push('*');
    setAlphabetizeList(tmpAlphabetizeList);
    setAlphabetizedSectionList([
      ...formattedNumSpecial,
      ...formattedAlpha,
      ...formattedAsterisk,
    ]);
  };
  if (loading) return <ActivityIndicator />;
  const taskTagUserIds = taskTagUsers?.map((i) => i.id);
  const [friendRequestSuccess, setFriendRequestSuccess] = useState(false);
  const toggelFriendRequestSuccess = (friendRequest: boolean) => {
    setFriendRequestSuccess(friendRequest);
  };

  const renderContact = (item: ContactType, disabled?: boolean) => {
    const isTaskTagUser = taskTagUserIds?.includes(item.id);
    return (
      <Contact
        key={item.id}
        toggelFriendRequestSuccess={
          isTaskTagUser ? toggelFriendRequestSuccess : () => {}
        }
        isTaskTagUser={isTaskTagUser}
        contact={{ ...item, roleLabel: item.roleLabel }}
        filterVal={filterVal}
        onPress={() => {
          if (!isEditing) {
            setSelectedContact((current) => {
              return current?.id === item.id ? undefined : item;
            });
          }
          onPress(item);
        }}
        isEditing={isEditing}
        isSelected={
          (selected && selected.some((i) => i === item.id)) || item.isAssigned
        }
        isInviting={isInviting}
        shouldDisplayPhoneContactLabel={shouldDisplayPhoneContactLabel}
        disabled={disabled || item.isAssigned || isTaskTagUser}
        showMessageIcon={showMessageIcon}
        onMessagePress={() => onMessagePress && onMessagePress(item)}
      />
    );
  };

  const contactsAndUsersList = () => {
    return [
      ...filteredContacts,
      t('shared:moreOnTaskTag'),
      ...(taskTagUsers || []),
    ];
  };

  if (taskTagUsers && taskTagUsers.length > 0) {
    // section list

    return (
      <View style={{ flex: 1 }}>
        <FlashList
          keyboardDismissMode='on-drag'
          ItemSeparatorComponent={() => <Box marginTop='m' />}
          ListFooterComponent={() => <Box marginBottom='listFooter' />}
          data={contactsAndUsersList()}
          renderItem={({ item }) => {
            if (typeof item === 'string') {
              return (
                <Box
                  mt='s'
                  mb='s'
                  marginHorizontal='m'
                  pt='l'
                  borderTopWidth={1}
                  borderColor='grey02'>
                  <Text variant='labelSmall'>{item}</Text>
                </Box>
              );
            } else {
              return renderContact(item);
            }
          }}
          getItemType={(item) => {
            // To achieve better performance, specify the type based on the item
            return typeof item === 'string' ? 'sectionHeader' : 'row';
          }}
          estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
        />

        {friendRequestSuccess && Platform.OS !== 'web' && (
          <View style={{ marginBottom: theme.spacing.l }}>
            <Box
              backgroundColor='black'
              opacity={0.8}
              paddingHorizontal='s'
              paddingVertical='xs'
              borderRadius='xs'
              alignSelf='center'>
              <Text
                color='white'
                variant='labelSmall'
                lineHeight={16}
                textAlign='center'>
                {t('models:chat.connectionRequestSend')}
              </Text>
            </Box>
          </View>
        )}
      </View>
    );
  }

  return (
    <>
      {alphabetize ? (
        <Box flex={1} position='relative'>
          <FlashList
            ref={flashListRef}
            extraData={selected}
            showsVerticalScrollIndicator={false}
            estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
            keyboardDismissMode='on-drag'
            ItemSeparatorComponent={() => <Box marginTop='m' />}
            ListFooterComponent={() => <Box paddingBottom='listFooter' />}
            ListEmptyComponent={
              showEmptyStateNoSearch ? (
                <Box
                  px={Platform.OS !== 'web' ? undefined : 'l'}
                  style={{ marginTop: -theme.spacing.listFooter / 2 }}>
                  <EmptyStateNoSearch showSmall />
                </Box>
              ) : undefined
            }
            data={alphabetizedSectionList}
            renderItem={({ item }) => {
              if (typeof item === 'string') {
                return (
                  <Box
                    backgroundColor='grey01'
                    paddingVertical='xs'
                    paddingLeft='m'>
                    <Text color='textPrimary' variant='labelEmphasized'>
                      {item}
                    </Text>
                  </Box>
                );
              } else {
                // On the web, keep clicking after clicking the contact
                if (onMessagePress) {
                  return renderContact(item, false);
                } else {
                  const disabled =
                    (!!item.roleLabel &&
                      disabledRoles?.includes(item.roleLabel)) ||
                    selectedContact?.id === item.id;

                  return renderContact(item, disabled);
                }
              }
            }}
            getItemType={(item) => {
              // To achieve better performance, specify the type based on the item
              return typeof item === 'string' ? 'sectionHeader' : 'row';
            }}
          />
          {Platform.OS !== 'web' && alphabetizedSectionList.length > 0 && (
            <AlphaSortSlider
              flashListRef={flashListRef}
              alphabetizeList={alphabetizeList}
            />
          )}
        </Box>
      ) : (
        <FlashList
          extraData={selected}
          estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
          keyboardDismissMode='on-drag'
          data={filteredContacts}
          renderItem={({ item }) => renderContact(item)}
          ItemSeparatorComponent={() => <Box marginTop='m' />}
          ListHeaderComponent={() =>
            headerSpace ? <Box marginBottom={headerSpace} /> : null
          }
          ListFooterComponent={() =>
            showEmptyStateNoSearch ? undefined : (
              <Box
                style={{
                  paddingBottom:
                    footerSpace !== undefined
                      ? footerSpace
                      : theme.spacing.listFooter,
                }}
              />
            )
          }
          ListEmptyComponent={
            showEmptyStateNoSearch ? (
              <Box
                px={Platform.OS !== 'web' ? undefined : 'l'}
                style={{ marginTop: -theme.spacing.listFooter / 2 }}>
                <EmptyStateNoSearch showSmall />
              </Box>
            ) : undefined
          }
        />
      )}
    </>
  );
};

export default ContactList;
