import React, { ComponentProps, useCallback, useRef, useState } from 'react';
import { Modal, TouchableOpacity } from 'react-native';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { FilesFilterPopup } from '@components/Web/PopupFilesFilter.web';
import { useOutsideClick } from '@hooks/useOutsideClick.web';

type PositionTuple = ['left' | 'right', 'top' | 'bottom'];

type Position = PositionTuple | 'top' | 'bottom' | 'left' | 'right';

type Props = Omit<ComponentProps<typeof TouchableOpacity>, 'onPress'> & {
  title: string;
  onDismiss?: any;
  setFilesFilter: (value: string) => void;
  boxProps: Partial<{
    position: Position;
    offset: [number, number] | number;
  }>;
  maxHeight?: number;
};

export const PopupFilesFilterButtonWeb = ({
  onDismiss,
  setFilesFilter,
  boxProps: { position, offset, ...popupPropsRest },
  title,
  maxHeight,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const togglePopup = () => setVisible((v) => !v);

  const ref2 = useRef<HTMLDivElement>();

  const isEventPointInModal = (event: MouseEvent) => {
    const rect = ref2?.current?.getBoundingClientRect();
    return (
      rect &&
      event.x > rect.x &&
      event.x < rect.x + rect.width &&
      event.y > rect.y &&
      event.y < rect.y + rect.height
    );
  };
  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (!isEventPointInModal(event)) {
      setVisible(false);
    }
  }, []);

  const ref = useOutsideClick<HTMLDivElement>(handleOutsideClick);

  // Store the natural dimensions of the popup container
  const [layout, setLayout] = useState<
    { width: number; height: number } | undefined
  >();

  const adjustBounds = (
    position: Props['boxProps']['position'] = 'bottom',
    offset: Props['boxProps']['offset'] = 0,
    client: DOMRect,
    popupLayout: { width: number; height: number }
  ) => {
    const [offsetLeftRight, offsetTopBottom] =
      typeof offset === 'number' ? [offset, offset] : offset;

    const normalizedPosition = (typeof position !== 'string' && position) ||
      ((position === 'left' || position === 'right') && [position, 'bottom']) ||
      ((position === 'top' || position === 'bottom') && ['left', position]) || [
        'left',
        'bottom',
      ];

    const [leftRight, topBottom] = normalizedPosition;
    const bounds = {
      top:
        topBottom === 'top'
          ? client.top - popupLayout.height - offsetTopBottom
          : client.top + client.height + offsetTopBottom,
      left:
        leftRight === 'left'
          ? client.left + client.width - popupLayout.width - offsetLeftRight
          : client.left + offsetLeftRight,
    };

    return bounds;
  };

  const adjustedBounds =
    ref.current &&
    layout &&
    adjustBounds(position, offset, ref.current.getBoundingClientRect(), layout);

  const onModalDismiss = () => {
    onDismiss && onDismiss();
    setVisible(false);
  };

  const backgroundColor1 = () => {
    if (mouseOver) return 'grey01';
    return visible ? 'grey02' : 'white';
  };

  return (
    <Box ref={ref}>
      <TouchableOpacity
        accessibilityLabel='Files Filter'
        onPress={() => {
          togglePopup();
        }}>
        <Box
          height={32}
          flexDirection='row'
          borderWidth={1}
          px='m'
          py='xs'
          borderColor='grey02'
          borderRadius='xs'
          alignItems='center'
          justifyContent='center'
          onMouseEnter={() => {
            setMouseOver(true);
          }}
          onMouseLeave={() => {
            setMouseOver(false);
          }}
          backgroundColor={backgroundColor1()}>
          <Box
            alignItems='flex-start'
            justifyContent='center'
            style={{ flex: 1 }}>
            <Text variant='webBodySecondary' color='textPrimary'>
              {title}
            </Text>
          </Box>
          <Box
            width={16}
            height={16}
            marginLeft='xs'
            alignItems='center'
            justifyContent='center'
            pointerEvents='none'>
            <Icon variant='s' name='ChevronDown' color='textPrimary' />
          </Box>
        </Box>
      </TouchableOpacity>

      {!layout && (
        <Box
          position='absolute'
          left='-200vw'
          visible={!layout}
          onLayout={({ nativeEvent: { layout } }) => {
            setLayout((curr) => curr ?? layout);
          }}>
          {setFilesFilter && (
            <FilesFilterPopup
              setFilesFilter={setFilesFilter}
              {...popupPropsRest}
              onDismiss={() => onModalDismiss()}
              maxHeight={maxHeight}
            />
          )}
        </Box>
      )}
      <Modal
        visible={visible}
        transparent
        onDismiss={() => onModalDismiss()}
        onRequestClose={() => onModalDismiss()}>
        <Box ref={ref2} style={{ ...adjustedBounds }} width={layout?.width}>
          <Box flexShrink={1}>
            {setFilesFilter && (
              <FilesFilterPopup
                {...popupPropsRest}
                setFilesFilter={setFilesFilter}
                onDismiss={() => onModalDismiss()}
                maxHeight={maxHeight}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
