import React, { createContext, useContext, useState } from 'react';

import { Task } from '@graphql/generated';

type CopyItems = {
  includeCheckLists: boolean;
  includeCreator: boolean;
  includeAssignees: boolean;
  includeViewers: boolean;
  includeFiles: boolean;
};

type TaskContext = {
  currentTask: Task | undefined;
  setCurrentTask: React.Dispatch<React.SetStateAction<Task | undefined>>;
  copyItems: CopyItems | undefined;
  setCopyItems: React.Dispatch<React.SetStateAction<CopyItems | undefined>>;
  showDeleteTaskModal: boolean;
  setShowDeleteTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const taskContext = createContext<TaskContext | undefined>(undefined);

export const TaskProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = taskContext;
  const [currentTask, setCurrentTask] = useState<Task>();
  const [copyItems, setCopyItems] = useState<CopyItems>();
  const [showDeleteTaskModal, setShowDeleteTaskModal] =
    useState<boolean>(false);

  return (
    <Provider
      value={{
        currentTask,
        setCurrentTask,
        copyItems,
        setCopyItems,
        showDeleteTaskModal,
        setShowDeleteTaskModal,
      }}>
      {children}
    </Provider>
  );
};

const useTask = (): TaskContext => {
  const context = useContext(taskContext);
  if (context === undefined) {
    throw new Error('useTask must be used within a Provider');
  }
  return context;
};

export default useTask;
