/* eslint-disable no-nested-ternary */
import React from 'react';
import { Dimensions } from 'react-native';

import { Box } from '@components/Restyle';
import { ChatDrawer } from '@components/Web/Chats/ChatDrawer.web';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import useActiveChat from '@hooks/useActiveChat';

export const ChatPanel = () => {
  const { chatListOpen, chatExpanded } = useWebDrawer();
  const { isOpenChat } = useActiveChat();
  const chatWidth = Dimensions.get('window').width <= 1280 ? 480 : 550;
  const width = `${isOpenChat || chatListOpen ? chatWidth - 80 : 0}px`;

  return (
    <Box
      flex={chatExpanded ? 1 : undefined}
      alignSelf='flex-end'
      flexDirection='row'
      width={chatExpanded ? undefined : width}
      style={{ marginRight: chatExpanded && chatListOpen ? chatWidth : 80 }}>
      <ChatDrawer />
    </Box>
  );
};
