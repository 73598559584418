import { useState } from 'react';

export type ColorType = {
  id: string;
  value: string;
  name: string;
  lightValue: string;
  lightName: string;
};

const useColors = (): ColorsContext => {
  const [colors] = useState<ColorType[]>([
    {
      id: 'green',
      value: '#035B60',
      name: 'Dark Green',
      lightValue: '#DCF2EC',
      lightName: 'greenSecondaryMild',
    },
    {
      id: 'purple',
      value: '#655D8A',
      name: 'Purple',
      lightValue: '#EBE7FF',
      lightName: 'purpleMild',
    },
    {
      id: 'blue',
      value: '#1572A1',
      name: 'Blue',
      lightValue: '#DCEEFF',
      lightName: 'blueMild',
    },
    {
      id: 'magenta',
      value: '#A85796',
      name: 'Magenta',
      lightValue: '#F6EAF8',
      lightName: 'magentaLight',
    },
    {
      id: 'orange',
      value: '#CC7351',
      name: 'Orange',
      lightValue: '#FFECE6',
      lightName: 'orangeMild',
    },
    {
      id: 'yellow',
      value: '#E6B566',
      name: 'Yellow',
      lightValue: '#FDF4E0',
      lightName: 'yellowVividMild',
    },
  ]);

  const [newAvatarColors] = useState<ColorType[]>([
    {
      id: 'avatar1',
      value: '#FF4444',
      name: 'Alert Red',
      lightValue: '',
      lightName: '',
    },
    {
      id: 'avatar2',
      value: '#FC7F5B',
      name: 'Orange',
      lightValue: '',
      lightName: '',
    },
    {
      id: 'avatar3',
      value: '#138EFF',
      name: 'Blue',
      lightValue: '',
      lightName: '',
    },
    {
      id: 'avatar4',
      value: '#138EFF',
      name: 'Purple',
      lightValue: '',
      lightName: '',
    },
    {
      id: 'avatar5',
      value: '#C072CD',
      name: 'Light Magenta',
      lightValue: '',
      lightName: '',
    },
    {
      id: 'avatar6',
      value: '#FBBD42',
      name: 'Vivid Yellow',
      lightValue: '',
      lightName: '',
    },
  ]);

  const getRandomColor = () =>
    colors[Math.floor(colors.length * Math.random())];

  const getGroupColor = (chatId: string): ColorType => {
    if (!chatId) return newAvatarColors[0];

    const chatId_number = parseInt(chatId, 10);

    const color =
      newAvatarColors[Math.floor(chatId_number % newAvatarColors.length)];

    if (!color) return newAvatarColors[1];

    return color;
  };

  const getComputedColor = (label: string): ColorType => {
    if (!label) return newAvatarColors[0];

    if (label.length > 1) {
      const firstCharacters = label.substring(0, 2).toUpperCase();
      const i = firstCharacters.toUpperCase().charCodeAt(0) - 65 || 0;
      const h = firstCharacters.toUpperCase().charCodeAt(1) - 65 || 0;

      const color =
        newAvatarColors[
          Math.floor(Math.floor((i + h) / 2) % newAvatarColors.length)
        ];

      if (!color) return newAvatarColors[1];

      return color;
    }

    const i = label.toUpperCase().charCodeAt(0) - 65 || 0;

    const color =
      newAvatarColors[
        Math.floor(Math.floor((i + 0) / 2) % newAvatarColors.length)
      ];

    if (!color) return newAvatarColors[1];

    return color;
  };

  const getMappedColor = (color: string) => {
    const colorFound = colors?.find((c: ColorType) => c.id === color);

    return colorFound ? colorFound.value : colors[0].value;
  };

  const getMappedLightColor = (color: string) => {
    const colorFound = colors?.find((c: ColorType) => c.id === color);

    return colorFound ? colorFound.lightValue : colors[0].lightValue;
  };

  return {
    colors,
    getMappedColor,
    getRandomColor,
    getComputedColor,
    getGroupColor,
    getMappedLightColor,
  };
};

export default useColors;
