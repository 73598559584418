import React from 'react';

import ReplyMessageParts from '@components/Chat/ReplyMessageParts';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Message } from '@graphql/generated';
import theme from '@themes/theme';
import { triggersConfig } from '@utils/mentions';

interface ReplyMessageProps {
  message: Message;
  onDismiss?: () => void;
}

export const ReplyMessage: React.FC<ReplyMessageProps> = ({
  message,
  onDismiss,
}) => {
  const { author, body, attachments } = message;

  return (
    <Box
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      backgroundColor='greenSecondaryMild'
      borderRadius='xs'
      padding='xs'
      marginBottom='xxs'
      style={{
        paddingLeft: 10,
        backgroundColor: theme.colors.greenSecondary + '24',
      }}>
      <Box flex={1}>
        <Box flex={1} flexDirection='row' alignItems='center'>
          <Icon name='Reply' variant='s' marginRight='xs' />
          <Text variant='labelEmphasized' color='textPrimary'>
            {author?.firstName}
          </Text>
        </Box>
        <ReplyMessageParts
          attachments={attachments}
          value={body || ''}
          configs={[triggersConfig.mention]}
          numberOfLines={1}
          ellipsizeMode='tail'
        />
      </Box>
      {!!onDismiss && (
        <Icon
          name='X3'
          marginLeft='xxs'
          onPress={onDismiss}
          color='black'
          variant='l'
        />
      )}
    </Box>
  );
};
