import { ResponsiveValue } from '@shopify/restyle';
import * as Clipboard from 'expo-clipboard';
import { openURL } from 'expo-linking';
import linkifyIt from 'linkify-it';
import React from 'react';
import { Platform, TextProps } from 'react-native';
import {
  Config,
  isTriggerConfig,
  parseValue,
  Part,
} from 'react-native-controlled-mentions';
import Highlighter from 'react-native-highlight-words';
import Hyperlink from 'react-native-hyperlink';
import tlds from 'tlds';

import { ChatDrawerScreen } from '@components/Web/Drawer/WebDrawerContext';
import { useAlert } from '@hooks/useAlert';
import { useAppNavigation } from '@navigation/useAppNavigation';

import { EVERYONE_ID } from './MentionSuggestions';
import theme, { Theme } from '../../../themes/theme';
import { useCreateChatMutation } from '../../graphql/generated';
import useActiveChat from '../../hooks/useActiveChat';
import { Alert } from '../Alert';
import { Text } from '../Restyle';

const linkify = linkifyIt();
const linkifyConfig = linkify.tlds(tlds);

type MessagePartsType = {
  value: string;
  configs: Config[];
  styleLinks?: boolean;
  filterVal?: string;
  textColor?: ResponsiveValue<keyof Theme['colors'], Theme['breakpoints']>;

  // if rendering mention from a non-chat detail screen
  navigateToChatOnMentionPress?: boolean;
} & TextProps;

const MessageParts: React.FC<MessagePartsType> = ({
  value,
  configs,
  styleLinks,
  filterVal = '',
  textColor = 'textSecondary',
  navigateToChatOnMentionPress,
  ...rest
}) => {
  const { parts } = parseValue(value, configs);
  const { setActiveChat } = useActiveChat();
  const navigate = useAppNavigation();
  const { showAlert } = useAlert();

  const [createChat] = useCreateChatMutation({
    onCompleted: (data) => {
      const { createChat: createChatData } = data;
      setActiveChat(createChatData);

      if (navigateToChatOnMentionPress) {
        navigate.navigateToChatDrawer({
          screen: ChatDrawerScreen.details,
          chatId: createChatData.id,
        });
      }
    },
    refetchQueries: ['listChats'],
  });

  const highlightText = (body: string) => {
    if (!filterVal) {
      return body;
    }
    return (
      <Highlighter
        autoEscape
        highlightStyle={{
          backgroundColor: theme.colors.yellowBright,
        }}
        searchWords={filterVal.split(' ')}
        textToHighlight={body}
      />
    );
  };

  const renderPart = (part: Part, index: number) => {
    // Mention type part
    if (part.config && isTriggerConfig(part.config)) {
      const { id: userId } = part.data || { userId: '' };
      const disablePress = !userId || userId === EVERYONE_ID;

      const onPress = () => {
        if (userId) {
          // with parts, open new chat with id
          // find name from users
          createChat({
            variables: {
              attributes: {
                userIds: [userId],
              },
            },
          });
        }
      };

      return (
        <Text
          key={index}
          style={!filterVal && part.config.textStyle}
          variant={Platform.OS === 'web' ? 'webBodySecondary' : 'body1'}
          color={textColor}
          onPress={disablePress ? undefined : onPress}>
          {highlightText(part.text)}
        </Text>
      );
    }

    return (
      <Text
        variant={Platform.OS === 'web' ? 'webBodySecondary' : 'body1'}
        color={textColor}
        key={index}
        style={{
          borderWidth: 0,
          borderColor: 'transparent',
          ...Platform.select({
            web: {
              wordBreak: 'break-word',
            },
          }),
        }}>
        {highlightText(part.text)}
      </Text>
    );
  };
  const renderParts = (
    <Text
      paddingTop='xxxs'
      {...rest}
      style={{
        alignSelf: 'flex-start',
        ...(Platform.OS === 'web' ? { lineHeight: 16 } : {}),
      }}>
      {parts.map(renderPart)}
    </Text>
  );

  if (styleLinks) {
    return (
      <Hyperlink
        linkify={linkifyConfig}
        onLongPress={(url) => {
          Clipboard.setStringAsync(url).then(() => {
            const copiedConfirm = `${url} copied to clipboard`;
            showAlert(copiedConfirm);
          });
        }}
        onPress={(url) => {
          if (Platform.OS == 'web') {
            window.open(url, '_blank');
          } else {
            openURL(url).catch(() => Alert.alert(`invalid URL: ${url}`));
          }
        }}
        linkStyle={{
          color: theme.colors.blue,
          alignSelf: 'flex-start',
        }}>
        {renderParts}
      </Hyperlink>
    );
  } else {
    return <>{renderParts}</>;
  }
};

export default MessageParts;
