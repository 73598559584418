import { useNavigation } from '@react-navigation/native';
import React from 'react';

import Popup, { MenuItem } from '../shared/Popup/Popup';

type ProjectListPopupProps = {
  closePopFn?: () => void;
};

const ProjectListPopup: React.FC<ProjectListPopupProps> = ({ closePopFn }) => {
  const navigation = useNavigation();

  const menuList: MenuItem[] = [
    {
      iconName: 'Hash',
      name: 'New Task',
      onSelect: () => navigation.navigate('task-create-modal'),
    },
    {
      iconName: 'Archive',
      name: 'Archived',
      onSelect: () =>
        navigation.navigate('archived-projects', {
          screen: 'archived-projects',
        }),
    },
  ];
  return (
    <Popup width={250} menuList={menuList} closePopFn={closePopFn}></Popup>
  );
};

export default ProjectListPopup;
