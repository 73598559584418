import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-native';

import { Box, Text } from '@components/Restyle';

export const LoadingScreen: React.FC = () => {
  const { t } = useTranslation('models');
  return (
    <Box
      alignItems='center'
      justifyContent='center'
      height='100%'
      backgroundColor='grey01'>
      <Box
        alignItems='center'
        borderRadius='m'
        backgroundColor='white'
        style={{
          paddingVertical: 32,
          paddingHorizontal: 40,
          shadowColor: 'black',
          shadowOpacity: 0.05,
          shadowOffset: { width: 0, height: 25 },
          shadowRadius: 25,
          position: 'absolute',
          width: 492,
          overflow: 'hidden',
        }}>
        <Box
          height={100}
          width={100}
          alignContent='center'
          justifyContent='center'>
          <Image
            style={{ width: 84, height: 84 }}
            source={require('../../../assets/images/loadinganimation.gif')}
          />
        </Box>
        <Text variant='heading2' color='black' style={{ marginTop: 32 }}>
          {t('onBoarding.modal.greatStart')}
        </Text>
        <Text variant='body' color='black' mt='m' style={{ lineHeight: 21 }}>
          {t('onBoarding.modal.connectionRequest')}
        </Text>
      </Box>
    </Box>
  );
};
