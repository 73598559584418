import { WatchQueryFetchPolicy } from '@apollo/client';

import { Project, useGetProjectQuery } from '@graphql/generated';
import {
  logError,
  LogNetworkError,
  LogGraphError,
} from '@utils/logNonFatalError';
/**
 * Retrieves a project for the provided `projectId`
 */
export const useProjectFromQuery = ({
  projectId,
  fetchPolicy = 'cache-and-network',
}: {
  projectId: Project['id'];
  fetchPolicy?: WatchQueryFetchPolicy;
}) => {
  const {
    data: { getProject } = {},
    loading,
    error,
  } = useGetProjectQuery({
    variables: {
      id: projectId,
    },
    onError: (error) => {
      if (error.networkError) {
        const event: LogNetworkError = {
          message: 'Project Network Failure',
          errorMessage: error.message,
          statusCode:
            'statusCode' in error.networkError
              ? error.networkError.statusCode
              : -1,
          extra: [{ projectId }],
        };
        logError(event);
      }
      error.graphQLErrors.forEach((graphError) => {
        const event: LogGraphError = {
          message: 'Project GraphQL Error',
          errorMessage: graphError.message,
          operationName: 'getProject',
          extra: [{ projectId }],
        };
        logError(event);
      });
    },
    fetchPolicy,
  });

  return { project: getProject as Project | undefined, loading, error };
};
