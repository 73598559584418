import { PlatformPressable } from '@react-navigation/elements';
import React, {
  ComponentProps,
  ReactNode,
  useRef,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, TouchableOpacity } from 'react-native';

import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import { CollapsableSectionList } from '@components/shared/CollapsableSectionList';
import { TaskPriorityBadge } from '@components/TaskCard/TaskPriorityBadge';
import {
  ProjectMemberRole,
  Task,
  TaskGroup,
  TaskMemberRole,
  // TaskStatus,
} from '@graphql/generated';
import { useToggleCompleteTask } from '@hooks/useToggleCompleteTask';

type Header = { id: TaskGroup; name: string; count: number };

type SectionData = {
  sectionHeader: Header;
  data: Task[];
  hasNextPage?: boolean;
};

export type TaskSection = {
  sectionData: SectionData;
  loadMore?: () => void;
};

export interface TaskListProps {
  tasks: Task[] | TaskSection[];
  onPress?: (task: Task) => void;
  selectedIds?: string[];
  isEditing?: boolean;
  onDependencyPress?: (dependencyIds: string[]) => void;
  filterVal?: string;
  isSearch?: boolean;
  refreshControl?: ComponentProps<typeof FlatList>['refreshControl'];
  onLoadMore?: () => void;
}

export const TaskListTable = ({
  tasks,
  onPress,
  onLoadMore,
}: TaskListProps) => {
  const { t } = useTranslation('models');
  const listTableRef = useRef();
  const [widthChanged, setWidthChanged] = useState(false);

  const sectionHeader = (item: Header) => {
    return (
      <Box flex={1} py='xs'>
        <Text color='textPrimary' variant='labelEmphasized'>
          {t('projects.taskList.section', {
            context: item.name,
          })}
        </Text>
      </Box>
    );
  };
  const { toggleCompleteTask } = useToggleCompleteTask();
  const renderItem = (item: Task) => {
    const { currentUserRole, currentUserProjectRole } = item;
    const isEditorOrHigher =
      currentUserProjectRole !== ProjectMemberRole.Viewer;

    const canComplete =
      currentUserRole === TaskMemberRole.Assignee ||
      currentUserRole === TaskMemberRole.Owner;

    const complete = (
      <RadioButton
        disabled={isEditorOrHigher ? !isEditorOrHigher : !canComplete}
        onPress={() => toggleCompleteTask(item)}
        isSelected={!!item.completedAt}
        iconVariant='m'
      />
    );
    const nameAndComplete = (
      <Box flexDirection='row' alignItems='center'>
        <Box justifyContent='center' marginRight='xs'>
          {complete}
        </Box>
        <Text variant='labelEmphasized' style={{ wordBreak: 'break-word' }}>
          {item.name}
        </Text>
      </Box>
    );
    // commenting code for future reference as per TA-2464
    // const status = (
    //   <Box>
    //     <TaskStatusChip
    //       status={
    //         item.completedAt
    //           ? TaskStatus.Completed
    //           : item.status || TaskStatus.NotStarted
    //       }
    //     />
    //   </Box>
    // );
    const priority = (
      <Box>
        <TaskPriorityBadge priority={item.priority} />
      </Box>
    );
    const start = !!item.startDate && (
      <Text>
        {t('format:monthAndDay', {
          val: item.startDate,
        })}
      </Text>
    );
    const end = !!item.dueDate && (
      <Text>
        {t('format:monthAndDay', {
          val: item.dueDate,
        })}
      </Text>
    );
    return (
      <PlatformPressable onPress={() => onPress?.(item)}>
        <Row
          nameAndComplete={nameAndComplete}
          // status={status}
          priority={priority}
          start={start}
          end={end}
        />
      </PlatformPressable>
    );
  };

  const renderListHeader = () => (
    <Box mx='m' borderTopWidth={1} borderTopColor='grey02'>
      <Row
        nameAndComplete={
          <Text variant='metadata' color='grey04'>
            {t('projects.taskList.name')}
          </Text>
        }
        status={
          <Text variant='metadata' color='grey04'>
            {t('projects.taskList.status')}
          </Text>
        }
        priority={
          <Text variant='metadata' color='grey04'>
            {t('projects.taskList.priority')}
          </Text>
        }
        start={
          <Text variant='metadata' color='grey04'>
            {t('projects.taskList.start')}
          </Text>
        }
        end={
          <Text variant='metadata' color='grey04'>
            {t('projects.taskList.end')}
          </Text>
        }
      />
    </Box>
  );

  const tasksAreGrouped = (
    tasks: Task[] | TaskSection[]
  ): tasks is TaskSection[] => {
    return (tasks as TaskSection[])[0]?.sectionData !== undefined;
  };

  const renderLoadMoreComponent = (item?: Header) => {
    let loadMoreData: (() => void) | undefined;

    if (tasksAreGrouped(tasks)) {
      const taskSections = tasks as TaskSection[];

      const taskSection = taskSections.find(
        (t) => t.sectionData.sectionHeader.id === item?.id
      );

      const { loadMore } = taskSection || { loadMore: undefined };
      loadMoreData = loadMore;
    } else {
      loadMoreData = onLoadMore || undefined;
    }

    if (!loadMoreData) return;

    return (
      <Box alignItems='center'>
        <TouchableOpacity
          accessibilityLabel={t('shared:loadMore')}
          onPress={loadMoreData}>
          <Box
            marginVertical='s'
            backgroundColor='grey01'
            p='s'
            borderRadius='xs'
            alignSelf='flex-start'>
            <Text variant='labelSmall' textAlign='center'>
              {t('shared:loadMore')}
            </Text>
          </Box>
        </TouchableOpacity>
      </Box>
    );
  };

  useCallback(() => {
    if (!listTableRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setWidthChanged(!widthChanged);
    });
    resizeObserver.observe(listTableRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <Box
      ref={listTableRef}
      flex={1}
      alignContent='center'
      justifyContent='center'
      key={
        tasksAreGrouped(tasks)
          ? tasks.flatMap((t) => t.sectionData.data).toString()
          : tasks.length
      }>
      {tasksAreGrouped(tasks) && (
        <CollapsableSectionList<Header, Task>
          data={tasks.map((d) => d.sectionData)}
          renderSectionHeader={sectionHeader}
          renderItem={renderItem}
          dataListHeaderStyle={{
            backgroundColor: 'grey02',
            marginHorizontal: 'm',
            paddingHorizontal: 'xs',
          }}
          ListHeaderComponent={renderListHeader}
          renderSectionFooterComponent={renderLoadMoreComponent}
        />
      )}

      {!tasksAreGrouped(tasks) && (
        <FlatList
          data={tasks}
          ListHeaderComponent={renderListHeader}
          renderItem={({ item }) => <Box mx='m'>{renderItem(item)}</Box>}
          ListFooterComponent={onLoadMore && renderLoadMoreComponent()}
          extraData={widthChanged}
        />
      )}
    </Box>
  );
};

const Column = ({
  children,
  flexBasis,
}: { children: ReactNode } & Pick<ComponentProps<typeof Box>, 'flexBasis'>) => {
  return (
    <Box
      flexBasis={flexBasis}
      justifyContent='center'
      p='s'
      alignItems='flex-start'>
      {children}
    </Box>
  );
};

const Row = ({
  nameAndComplete,
  // status,
  priority,
  start,
  end,
}: {
  nameAndComplete: ReactNode;
  // status: ReactNode;
  priority: ReactNode;
  start: ReactNode;
  end: ReactNode;
}) => {
  return (
    <Box
      borderWidth={1}
      borderTopWidth={0}
      borderColor='grey02'
      flexDirection='row'
      flexWrap='nowrap'
      justifyContent='space-between'>
      <Column flexBasis='30%'>{nameAndComplete}</Column>
      {/* commenting code for future reference as per TA-2464 
 <Column flexBasis='20%'>{status}</Column> */}
      <Column flexBasis='20%'>{priority}</Column>
      <Column flexBasis='15%'>{start}</Column>
      <Column flexBasis='15%'>{end}</Column>
    </Box>
  );
};
