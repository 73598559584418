import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Chat } from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import useMe from '@hooks/useMe';

const useChatAvatar = () => {
  const { activeChat } = useActiveChat();
  const { me } = useMe();
  const { t } = useTranslation('shared');

  const getChatAvatar = useCallback(
    (chat?: Chat) => {
      const useChat = chat ? chat : activeChat;
      const { users, isGroupChat } = useChat || {};
      if (users) {
        if (!isGroupChat) {
          const user = users?.find((user) => {
            return user.id != me?.id;
          });
          if (user) {
            return user.avatar;
          } else {
            if (users[0].id === me?.id) return me.avatar;
            return users[0].avatar;

          }
        }
      }
      return null;
    },
    [activeChat, me?.id, me?.avatar?.url]
  );

  const getChatAvatarLabel = useCallback(
    (chat?: Chat) => {
      const useChat = chat ? chat : activeChat;
      const { users, isGroupChat } = useChat || {};
      if (users) {
        if (!isGroupChat) {
          const user = users?.find((user) => {
            return user.id != me?.id;
          });
          if (user) {
            return user.name;
          } else {
            if (users[0].id === me?.id) {
              return t('myDisplayName', { displayName: me.name });
            }

            return t('myDisplayName', { displayName: users[0].name });
          }
        }
      }
      return useChat?.name;
    },
    [activeChat, me?.id, me?.avatar?.url]
  );

  return { getChatAvatar, getChatAvatarLabel };
};

export default useChatAvatar;
