import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Switch, TouchableOpacity } from 'react-native';

import Avatar from '@components/Avatar/Avatar';
import TeamAvatar from '@components/Avatar/TeamAvatar';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import { File, Team } from '@graphql/generated';
import Images from '@themes/images';
import theme from '@themes/theme';

export type ListNavigationItemProps = {
  iconName?: keyof typeof Images;
  iconColor?: keyof typeof theme.colors;
  iconBackgroundColor?: keyof typeof theme.colors;
  title?: string;
  titleColor?: keyof typeof theme.colors;
  description?: string;
  onPress?: () => void;
  isLarge?: boolean;
  iconUrl?: string;
  avatar?: File | null;
  onToggle?: (val: boolean) => void;
  toggleValue?: boolean;
  notificationCount?: number;
  prefixMarginRight?: keyof typeof theme.spacing;
  team?: Team;
  disabled?: boolean;
  comingSoon?: boolean;
  accessibilityLabel?: string;
  hideChevron?: boolean;
  hasNewActivity?: boolean;
};
const ListNavigationItem: React.FC<ListNavigationItemProps> = ({
  iconBackgroundColor,
  iconColor,
  iconName,
  title,
  titleColor,
  description,
  onPress,
  isLarge,
  iconUrl,
  avatar,
  onToggle,
  toggleValue,
  notificationCount = 0,
  prefixMarginRight,
  team,
  disabled = false,
  comingSoon = false,
  accessibilityLabel,
  hideChevron,
  hasNewActivity = false,
}) => {
  const { t } = useTranslation();
  const dotWidth = theme.spacing.xs;
  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={onPress ? 0.2 : 1}
      disabled={disabled}
      accessibilityLabel={accessibilityLabel || title}>
      <Box
        flexDirection='row'
        justifyContent='space-around'
        alignItems='center'
        marginBottom={avatar ? 'm' : 'xs'}>
        {team && (
          <Box marginRight='xs'>
            <TeamAvatar team={team} />
          </Box>
        )}
        {avatar && (
          <Box marginRight='xs'>
            <Avatar avatar={avatar} />
          </Box>
        )}
        {iconUrl && (
          <Image
            source={{ uri: iconUrl }}
            style={{
              width: 40,
              height: 40,
              borderRadius: 8,
              marginRight: theme.spacing.xs,
            }}
          />
        )}

        {iconName && (
          <Icon
            name={iconName}
            variant='l'
            color={iconColor || 'textPrimary'}
            backgroundColor={iconBackgroundColor}
            marginRight={prefixMarginRight || 'm'}
          />
        )}

        <Box flex={1} alignContent='center'>
          <Box flex={1} flexDirection='row' justifyContent='flex-start'>
            <Text
              variant={isLarge ? 'labelEmphasized' : 'labelSmall'}
              color={disabled ? 'grey03' : titleColor || 'textPrimary'}>
              {title}
            </Text>
            {hasNewActivity && (
              <Box
                width={dotWidth}
                height={dotWidth}
                backgroundColor='greenSecondary'
                borderRadius='xs'
              />
            )}
          </Box>
          {description && (
            <Text
              variant='metadata'
              color={disabled ? 'grey03' : 'textSecondary'}
              marginTop='xs'>
              {description}
            </Text>
          )}
        </Box>
        {comingSoon && (
          <Box marginRight='s'>
            <Tag
              variant='secondary'
              label={t('shared:comingSoon')}
              borderRadius='xxs'
              padding='xxs'
              marginLeft='m'
            />
          </Box>
        )}
        <Box justifyContent='center' flexDirection='row' alignItems='center'>
          {!disabled && notificationCount > 0 && (
            <Box
              backgroundColor='alertRed'
              borderRadius='xl'
              width={20}
              height={20}
              alignItems='center'
              justifyContent='center'
              marginRight='xs'>
              <Text variant='labelSmall' color='white'>
                {notificationCount}
              </Text>
            </Box>
          )}
          {onToggle ? (
            <Switch
              trackColor={{
                false: theme.colors.grey03,
                true: theme.colors.greenSecondary,
              }}
              onValueChange={onToggle}
              value={toggleValue}
            />
          ) : (
            hideChevron || (
              <Icon
                name='ChevronRight'
                variant={isLarge ? 'l' : 'm'}
                color={disabled ? 'grey03' : 'textPrimary'}
              />
            )
          )}
        </Box>
      </Box>
    </TouchableOpacity>
  );
};
export default ListNavigationItem;
