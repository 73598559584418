import PhoneInput, { PhoneInputProps } from '@sesamsolutions/phone-input';
import { FieldProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import theme from '../../../../themes/theme';
import Box from '../../Box/Box';
import Text from '../../Text/Text';
import Icon from '../Icon/Icon';

interface PhoneFieldProps {
  autoFocus: boolean;
  label: string;
  isFullWidth?: boolean;
  isRequired?: boolean;
  phoneInputProps?: PhoneInputProps;
  isLarge?: boolean;
  showCharsRemaining?: boolean;
  borderless?: boolean;
  isLargeText?: boolean;
  marginBottom?: keyof typeof theme.spacing;
  accessibilityLabel?: string;
  validateOnChange?: boolean;
}

const PhoneField: React.FC<PhoneFieldProps & FieldProps> = ({
  autoFocus,
  label,
  isFullWidth = true,
  isRequired,
  field,
  phoneInputProps,
  isLarge,
  marginBottom,
  form: { touched, errors, setTouched, setFieldValue },
  borderless,
  isLargeText,
  accessibilityLabel,
  validateOnChange = true,
}) => {
  const { t } = useTranslation('forms');
  // const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <Box
      width={isFullWidth ? '100%' : undefined}
      marginBottom={isLarge ? 'l' : marginBottom || 'm'}>
      {label && (
        <Box
          flexDirection='row'
          alignItems='flex-start'
          justifyContent='space-between'
          marginBottom={isLarge ? 's' : 'xs'}>
          {!borderless && <Text variant='labelSmall'>{label}</Text>}
          {isRequired && !borderless && (
            <Text variant='metadataSecondary'>{t('required')}</Text>
          )}
        </Box>
      )}

      <Box
        accessibilityLabel={accessibilityLabel}
        flexDirection='row'
        style={
          !isLarge && {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
          }
        }>
        <PhoneInput
          style={[
            borderless && { flex: 1 },
            borderless && isRequired && { height: 48 },
            !borderless && styles.input,
            isLargeText && styles.largeText,
            !!touched[field.name] &&
              !!errors[field.name] && {
                borderColor: theme.colors.alertRed,
              },
          ]}
          onChangePhoneNumber={(value) => {
            setTouched({ ...touched, [field.name]: validateOnChange });
            setFieldValue(field.name, value);
          }}
          autoFocus={autoFocus}
          value={field.value}
          {...phoneInputProps}
        />
        {borderless && isRequired && !field.value?.length && (
          <Text variant='metadataSecondary'>{t('required')}</Text>
        )}
      </Box>
      {!!touched[field.name] && !!errors[field.name] && (
        <Box
          flexDirection='row'
          alignItems='center'
          marginTop={isLarge ? 's' : 'xs'}>
          <Icon
            name='AlertTriangle'
            color='alertRed'
            variant='s'
            marginRight='xxs'
          />
          <Text variant='error'>{errors[field.name]}</Text>
        </Box>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  input: {
    width: '100%',
    paddingHorizontal: theme.spacing.m,
    borderWidth: 1,
    borderRadius: theme.spacing.xs,
    borderColor: theme.colors.grey02,
    backgroundColor: theme.colors.white,
    fontFamily: 'Inter_400Regular',
    height: 48,
  },
  largeText: {
    ...theme.textVariants.labelLarge,
    color: theme.colors.textPrimary,
  },
});

export default PhoneField;
