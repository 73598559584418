import { createBox } from '@shopify/restyle';
import { ReactNode } from 'react';

import theme, { Theme } from '@themes/theme';

const Box = createBox<Theme>();

interface BoxHoverProps {
  children: ReactNode;
  paddingHorizontal?: keyof typeof theme.spacing;
  isHovered?: boolean;
  isPressing?: boolean;
  isSelected?: boolean;
  alphabetize?: boolean;
  alphabetical?: boolean;
  isProject?: boolean;
  noItemSeparatorComponent?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const BoxHover: React.FC<BoxHoverProps> = ({
  children,
  paddingHorizontal,
  isHovered,
  isPressing,
  isSelected,
  alphabetize,
  alphabetical,
  isProject,
  noItemSeparatorComponent,
  onMouseEnter,
  onMouseLeave,
}) => {
  const bgHovered = isHovered ? 'grey01' : 'white';
  const bg = isPressing || isSelected ? 'greenSecondaryMild' : bgHovered;
  return (
    <>
      <Box
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        backgroundColor={bg}
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        paddingHorizontal={paddingHorizontal}
        paddingVertical={isProject ? undefined : 'xs'}
        borderColor='greenSecondary'
        borderTopWidth={isPressing ? 1 : 0}
        borderBottomWidth={isPressing ? 1 : 0}>
        {children}
      </Box>
      {!noItemSeparatorComponent && (
        <Box
          height={isPressing ? 0 : 1}
          backgroundColor='grey02'
          marginHorizontal={!alphabetize && !alphabetical ? undefined : 'm'}
        />
      )}
    </>
  );
};
