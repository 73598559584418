import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TouchableOpacity,
  StyleSheet,
  FlatList,
  ListRenderItemInfo,
  Platform,
} from 'react-native';

import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';
import { logApolloError } from '@utils/logNonFatalError';

import theme from '../../../themes/theme';
import Button from '../../components/shared/Button/Button';
import {
  useDuplicateTaskMutation,
  ListTasksDocument,
  Task,
} from '../../graphql/generated';
import RadioButton from '../RadioButton/RadioButton';
import { Text, Box, ShadowBox } from '../Restyle';

type TaskItem = { id: number; name: string; isSelected: boolean };

export type DataType = {
  id: number;
  value?: string;
  name: string;
  isSelected: boolean;
  list?: TaskItem[];
};

export const taskItemsToCopy = [
  {
    id: 1,
    value: '',
    name: 'Task Details',
    isSelected: false,
    list: [{ id: 1, value: '', name: 'Check Lists', isSelected: false }],
  },
  {
    id: 2,
    value: '',
    name: 'All Members',
    isSelected: false,
    list: [
      { id: 1, value: '', name: 'Creator', isSelected: false },
      { id: 2, value: '', name: 'Assignees', isSelected: false },
      { id: 3, value: '', name: 'Viewers', isSelected: false },
    ],
  },
  {
    id: 3,
    value: '',
    name: 'All Files',
    isSelected: false,
    list: [{ id: 1, value: '', name: 'With Tags', isSelected: false }],
  },
];

const CopyTaskDetails: React.FC<{ onComplete: (task: Task) => void }> = ({
  onComplete,
}) => {
  const navigation = useNavigation();
  const route = useRoute<TasksStackScreenProps<'task-copy-modal'>['route']>();
  const { t } = useTranslation();
  const {
    params: { taskId = '' },
  } = route || {};

  const [selectedList, setIsSelected] = useState(taskItemsToCopy);
  const [isNoneSelected, setIsNoneSelected] = useState(true);
  const [duplicateTask, { loading }] = useDuplicateTaskMutation({
    errorPolicy: 'none', // Allows graphql errors to be passed to onError handler when data is null.
    onCompleted: (data) => {
      if (data?.duplicateTask) {
        const { duplicateTask } = data;

        onComplete(duplicateTask);
      }
    },
    onError: (error) => logApolloError(error, 'Duplicate Task', [{ taskId }]),
    refetchQueries: [{ query: ListTasksDocument }, 'listTasks'],
  });

  const createTask = () => {
    if (!loading) {
      duplicateTask({
        variables: {
          id: taskId,
          includeCheckLists: selectedList[0].list[0].isSelected,
          includeCreator: selectedList[1].list[0].isSelected,
          includeAssignees: selectedList[1].list[1].isSelected,
          includeViewers: true,
          includeFiles: selectedList[2].list[0].isSelected,
        },
      });
    }
  };

  useEffect(() => {
    const resetList = taskItemsToCopy.map((i) => {
      const newList = i.list.map((l) => {
        return {
          ...l,
          isSelected: false,
        };
      });
      // reset selection
      return {
        ...i,
        isSelected: false,
        list: newList,
      };
    });

    setIsSelected(resetList);
  }, []);

  const onRadioButtonClick = (index: number, _index?: number) => {
    const newList = [...selectedList];
    let _isNoneSelected = true;
    newList.map((item, i: number) => {
      if (_index === -1 && index === i) {
        newList[index].isSelected = !newList[index].isSelected;
      }
      let isAllSelected = true;
      item.list?.map((_item: DataType, _i: number) => {
        if (_index === -1 && index === i) {
          _item.isSelected = newList[index].isSelected;
        } else if (index === i && _index === _i) {
          _item.isSelected = !_item.isSelected;
        }
        if (!_item.isSelected && isAllSelected) {
          isAllSelected = false;
        }
        if (_item.isSelected && _isNoneSelected) {
          _isNoneSelected = false;
        }
      });
      item.isSelected = isAllSelected;
    });
    setIsNoneSelected(_isNoneSelected);
    setIsSelected([...newList]);
  };

  const itemRenderItem = (item: DataType, index: number) => {
    return item.list?.map((_item: DataType, _index) => (
      <Box
        key={item.id + _item.id}
        paddingTop='m'
        paddingBottom='xs'
        borderBottomColor='grey02'
        style={[
          styles._item,
          index + 1 === selectedList.length &&
            _index + 1 === item.list?.length &&
            styles._borderBottom,
        ]}>
        <RadioButton
          onPress={() => onRadioButtonClick(index, _index)}
          isSelected={_item.isSelected}
          key={_item.id}
          iconVariant='m'
          text={_item.name}
          textVariant='labelEmphasized'
          textColor='textSecondary'
        />
      </Box>
    ));
  };

  const renderItem = ({ item, index }: ListRenderItemInfo<DataType>) => {
    return (
      <Box paddingBottom='m' paddingTop='xs'>
        <RadioButton
          onPress={() => onRadioButtonClick(index, -1)}
          isSelected={item.isSelected}
          key={item.id}
          iconVariant='m'
          text={item.name}
          textVariant='labelEmphasized'
        />
        {itemRenderItem(item, index)}
      </Box>
    );
  };

  const renderCopyTaskDetailsList = () => (
    <Box borderRadius='xs' flexDirection='column'>
      <FlatList
        data={selectedList}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        style={styles.list}
      />
      {Platform.OS === 'web' ? (
        <Box padding='m' flexDirection='row' justifyContent='flex-end'>
          <Button
            variant='primary'
            onPress={createTask}
            disabled={isNoneSelected || loading}>
            {loading ? (
              <Box padding='m'>
                <ActivityIndicatorLoading />
              </Box>
            ) : (
              t('forms:confirm')
            )}
          </Button>
        </Box>
      ) : (
        <Box padding='m'>
          <Button
            variant='primary'
            onPress={createTask}
            disabled={isNoneSelected || loading}>
            {loading ? (
              <ActivityIndicatorLoading />
            ) : (
              t('models:tasks.create.title')
            )}
          </Button>
        </Box>
      )}
    </Box>
  );

  return Platform.OS === 'web' ? (
    <Box marginTop='m'>{renderCopyTaskDetailsList()}</Box>
  ) : (
    <Box flex={1} justifyContent='center'>
      <TouchableOpacity
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        onPress={() => navigation.pop()}
        activeOpacity={1}
      />
      <ShadowBox variant='base' borderRadius='xs' marginHorizontal='m'>
        <Box
          padding='m'
          marginBottom='xs'
          flexDirection='row'
          justifyContent='space-between'
          borderBottomWidth={StyleSheet.hairlineWidth}
          borderBottomColor='lightPurple'>
          <Text variant='labelLarge'>Select</Text>
          <TouchableOpacity onPress={() => navigation.pop()}>
            <Text variant='buttonLabel' color='greenSecondary'>
              Cancel
            </Text>
          </TouchableOpacity>
        </Box>
        {renderCopyTaskDetailsList()}
      </ShadowBox>
    </Box>
  );
};

const styles = StyleSheet.create({
  list: {
    paddingHorizontal: theme.spacing.m,
  },
  _item: {
    marginLeft: 32,
    borderBottomWidth: 1,
  },
  _borderBottom: {
    borderBottomWidth: 0,
    borderBottomColor: theme.colors.white,
  },
});

export default CopyTaskDetails;
