import React from 'react';

import Box from '@components/Restyle/Box';
import Icon from '@components/shared/Icon/Icon';

export const CheckBoxButton: React.FC<{ checked: boolean }> = ({ checked }) =>
  checked ? (
    <Icon name='CheckBoxSelected' variant='m' color='white' disabled={false} />
  ) : (
    <Box
      width={20}
      height={20}
      borderWidth={2}
      borderColor='grey03'
      style={{ borderRadius: 1 }}></Box>
  );
