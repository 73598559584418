import { Search } from '../../types';
import {
  Task,
  Project,
  Message,
  Document,
  User,
  SearchableResult,
} from '../graphql/generated';

export const isTaskSearchResult = (
  item: Search<SearchableResult>
): item is Search<Task> => item.record.__typename === 'Task';

export const isMessageSearchResult = (
  item: Search<SearchableResult>
): item is Search<Message> => item.record.__typename === 'Message';

export const isProjectSearchResult = (
  item: Search<SearchableResult>
): item is Search<Project> => item.record.__typename === 'Project';

export const isContactSearchResult = (
  item: Search<SearchableResult>
): item is Search<User> => item.record.__typename === 'User';

export const isDocumentSearchResult = (
  item: Search<SearchableResult>
): item is Search<Document> => item.record.__typename === 'Document';
