import * as Clipboard from 'expo-clipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, StyleSheet, FlatList } from 'react-native';
import { EmojiType } from 'rn-emoji-keyboard/src/types';

import { Box, ShadowBox, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import Tag from '@components/shared/Tags/Tag';
import useChatInput from '@hooks/useChatInput';
import useEmoji from '@hooks/useEmoji';
import useMe from '@hooks/useMe';
import documentIcons from '@themes/documentIcons';
import Images from '@themes/images';
import theme from '@themes/theme';
import { AsyncStorage } from '@utils/storage';

interface EditMessageHighlightModalProps {
  onDismiss?: () => void;
  onEditTagPress: () => void;
  onForwardPress: () => void;
  onDeletePress: () => void;
  onEmoji: (emoji: string) => void;
  isArchived?: boolean;
}

export const EditMessageHighlightModal: React.FC<
  EditMessageHighlightModalProps
> = ({
  onDismiss,
  onEditTagPress,
  onDeletePress,
  onEmoji,
  isArchived = false,
}) => {
  const { selectedMessage, setReplyMessage } = useChatInput();
  const isLeaveOn = selectedMessage?.chat?.leaveOn;
  const {
    toggleEmojiPickerOpened,
    setShowMessageHighlightModal,
    setMessageId,
    setFromValue,
  } = useEmoji();
  const { me } = useMe();
  const { t } = useTranslation();
  const taskAssignments = selectedMessage?.attachments?.filter((item) => {
    return item.__typename === 'MessageTaskAssignment';
  });
  const taskComplete = selectedMessage?.attachments?.filter((item) => {
    return item.__typename === 'MessageTaskComplete';
  });

  const isAssignTask = taskAssignments && taskAssignments.length > 0;
  const isCompleteTask = taskComplete && taskComplete.length > 0;

  const replyEmojis: EmojiType[] = [
    {
      emoji: '😃',
      name: 'grinning face with big eyes',
      unicode_version: '0.6',
      toneEnabled: false,
      slug: '',
    },
    {
      emoji: '👍',
      name: 'thumbs up',
      unicode_version: '0.6',
      toneEnabled: true,
      slug: '',
    },
    {
      emoji: '👀',
      name: 'eyes',
      unicode_version: '0.6',
      toneEnabled: false,
      slug: '',
    },
    {
      emoji: '👌',
      name: 'OK hand',
      unicode_version: '0.6',
      toneEnabled: true,
      slug: '',
    },
    {
      emoji: '😐',
      name: 'neutral face',
      unicode_version: '0.7',
      toneEnabled: false,
      slug: '',
    },
    {
      emoji: '✅',
      name: 'check mark button',
      unicode_version: '0.7',
      toneEnabled: false,
      slug: '',
    },
  ];

  const onEmojiPress = async (emojiObject: EmojiType) => {
    const data = await AsyncStorage.getItem('recentEmoji');
    if (data) {
      const newEmojis = [emojiObject, ...JSON.parse(data)].filter(
        (cVal, cIndex, obj) => {
          return obj.findIndex((x) => x.name === cVal.name) === cIndex;
        }
      );
      AsyncStorage.setItem('recentEmoji', JSON.stringify(newEmojis));
    }
    setFromValue('MessageReaction');
    setMessageId(selectedMessage?.id ?? '');

    onEmoji(emojiObject.emoji);
  };
  let menuList: {
    name: string;
    iconName: keyof typeof Images | keyof typeof documentIcons;
    onSelect: () => void;
    comingSoon?: boolean;
    color?: keyof typeof theme.colors | undefined;
  }[] = [
    {
      name: 'Edit Tag',
      iconName: 'Hash',
      onSelect: () => {
        onEditTagPress();
      },
    },
    {
      name: 'Reply',
      iconName: 'ArrowUpLeft2',
      onSelect: () => {
        setReplyMessage(selectedMessage?.chatId ?? '', selectedMessage);
      },
    },
    {
      name: 'Copy',
      iconName: 'Copy',
      onSelect: () => {
        copyToClipboard();
      },
    },
    {
      name: 'Forward',
      iconName: 'Forward',
      onSelect: () => {},
      comingSoon: true,
    },
  ];

  if (isArchived || isLeaveOn) {
    menuList = [...menuList.slice(2, 4)];
  }
  me?.id === selectedMessage?.authorId &&
    !isArchived &&
    menuList.push({
      name: 'Delete',
      iconName: 'Trash2',
      onSelect: () => onDeletePress(),
      color: 'alertRed',
    });

  if (isAssignTask || isCompleteTask) {
    menuList.splice(0, 1);
    menuList.splice(1, 1);
  }

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(selectedMessage?.body || '');
  };

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        onDismiss && onDismiss();
        setShowMessageHighlightModal(false);
        onEmoji('');
      }}>
      <TouchableOpacity
        activeOpacity={1}
        onPress={(e) => e.stopPropagation()}
        style={styles.outer}>
        <ShadowBox
          backgroundColor='white'
          p='m'
          borderRadius='xs'
          style={{
            width: '100%',
            height: (isArchived ? 16 : 83) + menuList.length * 48,
            // borderRadius: 8,
          }}>
          <Box
            flexDirection='row'
            justifyContent='space-between'
            mb={!isArchived ? 'xs' : 'xxs'}>
            {!isArchived &&
              replyEmojis.map((item: EmojiType, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() => onEmojiPress(item)}>
                    <Text
                      style={{
                        fontSize: 24,
                        lineHeight: 36,
                      }}>
                      {item.emoji}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            {!isArchived && (
              <Icon
                name='PlusBold'
                variant='xxl'
                color='grey05'
                onPress={() => {
                  setFromValue('MessageReaction');
                  setMessageId(selectedMessage?.id ?? '');
                  toggleEmojiPickerOpened(true);
                }}
              />
            )}
          </Box>
          {menuList && (
            <FlatList
              data={menuList}
              scrollEnabled={false}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    item.onSelect();
                  }}
                  accessibilityLabel={item.name}>
                  <Box
                    flexDirection='row'
                    marginTop={isArchived ? 'none' : 'l'}
                    marginBottom={isArchived ? 'l' : 'none'}
                    alignItems='center'>
                    <Box flex={1} flexDirection='row'>
                      <Icon
                        name={item.iconName}
                        variant='l'
                        color={item.color || 'textPrimary'}
                      />
                      <Text
                        marginLeft='m'
                        variant='body'
                        color={item.color || 'textPrimary'}>
                        {item.name}
                      </Text>
                    </Box>
                    {item.comingSoon && (
                      <Tag
                        label={t('shared:comingSoon')}
                        labelVariant='metadata'
                        variant='secondary'
                        padding='xxs'
                        borderRadius='xxs'
                      />
                    )}
                  </Box>
                </TouchableOpacity>
              )}
            />
          )}
        </ShadowBox>
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    zIndex: 100,
    alignItems: 'center',
    borderRadius: theme.borderRadii.xs,
  },
  outer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
});
