import React, { useState, createContext, useContext } from 'react';

import { TagsCollectionType } from '@components/Chat/TagsCollection';
import { Document } from '@graphql/generated';

type PreviewDocumentsContext = {
  previewDocuments: Document[] | undefined;
  setPreviewDocuments: React.Dispatch<
    React.SetStateAction<Document[] | undefined>
  >;
  currentDocument: Document | undefined;
  setCurrentDocument: React.Dispatch<
    React.SetStateAction<Document | undefined>
  >;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  tagsCollections: TagsCollectionType[][];
  setTagsCollections: React.Dispatch<
    React.SetStateAction<TagsCollectionType[][]>
  >;
  refreshList: boolean;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
  isViewer: boolean;
  setIsViewer: React.Dispatch<React.SetStateAction<boolean>>;
  isTeamMember: boolean;
  setIsTeamMember: React.Dispatch<React.SetStateAction<boolean>>;
};

const previewDocumentsContext = createContext<
  PreviewDocumentsContext | undefined
>(undefined);

export const PreviewDocumentsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { Provider } = previewDocumentsContext;
  const [previewDocuments, setPreviewDocuments] = useState<
    Document[] | undefined
  >();
  const [currentDocument, setCurrentDocument] = useState<
    Document | undefined
  >();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [tagsCollections, setTagsCollections] = useState<
    TagsCollectionType[][] | []
  >([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [isViewer, setIsViewer] = useState<boolean>(false);
  const [isTeamMember, setIsTeamMember] = useState<boolean>(false);

  return (
    <Provider
      value={{
        previewDocuments,
        setPreviewDocuments,
        currentDocument,
        setCurrentDocument,
        activeIndex,
        setActiveIndex,
        tagsCollections,
        setTagsCollections,
        refreshList,
        setRefreshList,
        isViewer,
        setIsViewer,
        isTeamMember,
        setIsTeamMember,
      }}>
      {children}
    </Provider>
  );
};

export const usePreviewDocuments = (): PreviewDocumentsContext => {
  const context = useContext(previewDocumentsContext);
  if (context === undefined) {
    throw new Error('usePreviewDocuments must be used within a Provider');
  }
  return context;
};
