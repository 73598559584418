import React, { createContext, useContext } from 'react';

import { User, useListContactUsersQuery } from '@graphql/generated';
import { useAuthContext } from '@src/context/authContext';

type contactsContext = {
  contacts: User[];
  loading: boolean;
};

const contactsContext = createContext<contactsContext | undefined>(undefined);

export const ContactsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = contactsContext;
  const { token } = useAuthContext();
  const { data: listUsersData, loading } = useListContactUsersQuery({
    skip: !token,
  });
  const { listContactUsers: contacts } = listUsersData || {
    listContactUsers: [],
  };

  return (
    <Provider
      value={{
        contacts,
        loading,
      }}>
      {children}
    </Provider>
  );
};

export const useContacts = (): contactsContext => {
  const context = useContext(contactsContext);
  if (context === undefined) {
    throw new Error('useContacts must be used within a Provider');
  }
  return context;
};
