import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { DateRange } from '@components/DateRangePicker/DateRangePickerModal';
import { ContactType } from '@components/Invite/Contact';
import { Box, Text } from '@components/Restyle';
import MultiTags from '@components/shared/Tags/MultiTags.web';
import { PopupTaskButtonWeb } from '@components/Web/PopupTaskButton.web';
import { Project, TaskPriority } from '@graphql/generated';

export const TasksFilterBar: React.FC<{
  selectedProjects?: Project[];
  setSelectedProjects?: (x: Project[]) => void;
  initialAssignees?: ContactType[];
  selectedAssignees: ContactType[];
  setSelectedAssignees: (x: ContactType[]) => void;
  selectedDueDateRange: DateRange;
  setSelectedDueDateRange: (x: DateRange) => void;
  selectedPriorities: TaskPriority[];
  setSelectedPriorities: (x: TaskPriority[], marginTop?: string) => void;
}> = ({
  selectedProjects,
  setSelectedProjects,
  selectedPriorities,
  setSelectedPriorities,
  selectedAssignees,
  setSelectedAssignees,
  initialAssignees,
  selectedDueDateRange,
  setSelectedDueDateRange,
}) => {
  const { t } = useTranslation('models');

  const [barWidth, setBarWidth] = useState(0);
  const [filterProjectTagWidth, setFilterProjectTagWidth] = useState(0);
  const [filterPriorityTagWidth, setFilterPriorityTagWidth] = useState(0);
  const [filterDueDateTagWidth, setFilterDueDateTagWidth] = useState(0);
  const [filterAssigneeTagWidth, setFilterAssigneeTagWidth] = useState(0);

  const popupMarginTop = 6;
  const offsetPopButton: Partial<any> = {
    position: 'left',
    offset: [0, popupMarginTop],
  };
  const spaceBetweenTags = 6;
  const AssigneeTag = () => {
    if (selectedAssignees.length === 0) return <></>;
    return (
      <Box
        onLayout={(event) => {
          setFilterAssigneeTagWidth(event.nativeEvent.layout.width);
        }}>
        <MultiTags
          prefixText='Assignee: '
          key='dateRange'
          labelList={selectedAssignees}
          labelVariant='metadata'
          variant='pickerForWeb'
          padding='xxs'
          marginTop='xxs'
          borderRadius='xxs'
          onPressClose={(ownerid) => {
            setSelectedAssignees(
              selectedAssignees.filter((c) => c.id != ownerid)
            );
          }}
        />
      </Box>
    );
  };

  const DueDateRangeTag = () => {
    const { start, end } = selectedDueDateRange;
    if (!start && !end) return <></>;

    const startStr = start ? dayjs(start).format('MM / DD / YYYY') : '';
    const endStr = end ? dayjs(end).format('MM / DD / YYYY') : '';
    let prefixStr = 'Due: ';
    let rangeStr = startStr;
    if (!end) prefixStr = 'Due From: ';
    else if (!start) {
      prefixStr = 'Due To: ';
      rangeStr = endStr;
    } else rangeStr = startStr + ' - ' + endStr;
    return (
      <Box
        onLayout={(event) => {
          setFilterDueDateTagWidth(event.nativeEvent.layout.width);
        }}>
        <MultiTags
          prefixText={prefixStr}
          key='dateRange'
          labelList={[{ id: '0', name: rangeStr }]}
          labelVariant='metadata'
          variant='pickerForWeb'
          padding='xxs'
          marginTop='xxs'
          borderRadius='xxs'
          onPressClose={(_) => {
            setSelectedDueDateRange({ start: undefined, end: undefined });
          }}
        />
      </Box>
    );
  };

  const displayForTaskPriority = (a: TaskPriority) => {
    switch (a) {
      case TaskPriority.High:
        return t('models:tasks.create.priority.high');
      case TaskPriority.Medium:
        return t('models:tasks.create.priority.medium');
      case TaskPriority.Low:
        return 'No Priority';
    }
  };
  const PriorityTag = () => {
    if (selectedPriorities.length === 0) return <></>;
    const x = selectedPriorities.map((a, index) => ({
      id: index.toString(),
      name: displayForTaskPriority(a),
    }));
    return (
      <Box
        onLayout={(event) => {
          setFilterPriorityTagWidth(event.nativeEvent.layout.width);
        }}>
        <MultiTags
          prefixText='Priority: '
          key='dateRange'
          labelList={x}
          labelVariant='metadata'
          variant='pickerForWeb'
          padding='xxs'
          marginTop='xxs'
          borderRadius='xxs'
          onPressClose={(a) => {
            setSelectedPriorities(
              selectedPriorities.filter((_, index) => index.toString() !== a)
            );
          }}
        />
      </Box>
    );
  };

  const ProjectTag = () => {
    if (!selectedProjects || selectedProjects.length === 0) return <></>;
    return (
      <Box
        onLayout={(event) => {
          setFilterProjectTagWidth(event.nativeEvent.layout.width);
        }}>
        <MultiTags
          prefixText='Project: '
          key='dateRange'
          labelList={selectedProjects}
          labelVariant='metadata'
          variant='pickerForWeb'
          padding='xxs'
          marginTop='xxs'
          borderRadius='xxs'
          onPressClose={(a) => {
            setSelectedProjects &&
              setSelectedProjects(selectedProjects.filter((c) => c.id !== a));
          }}
        />
      </Box>
    );
  };

  const ClearButton = () => {
    return (
      <Box mt='xs' style={{ marginLeft: 10 }}>
        <TouchableOpacity
          onPress={() => {
            setSelectedProjects && setSelectedProjects([]);
            setSelectedAssignees([]);
            setSelectedPriorities([]);
            setSelectedDueDateRange({ start: undefined, end: undefined });
          }}>
          <Text variant='metadata' color='grey04'>
            {t('shared:clear')}
          </Text>
        </TouchableOpacity>
      </Box>
    );
  };

  return (
    <>
      <Box
        ml='m'
        mr='s'
        mt='xs'
        mb='xxs'
        flexDirection='row'
        justifyContent='flex-start'
        onLayout={(event) => {
          setBarWidth(event.nativeEvent.layout.width);
        }}>
        {selectedProjects && (
          <>
            <PopupTaskButtonWeb
              title={t('projects.taskList.project')}
              boxProps={{
                position: 'right',
                offset: [0, popupMarginTop],
              }}
              selectedProjects={selectedProjects}
              onSelectProjects={setSelectedProjects}
            />
            <Box mr='xs'></Box>
          </>
        )}

        <Box
          style={{
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}></Box>
        <>
          <PopupTaskButtonWeb
            selectedPriorities={selectedPriorities}
            title={t('models:projects.taskList.priority')}
            boxProps={offsetPopButton}
            onSelectPriority={setSelectedPriorities}
          />
          <Box ml='xs'></Box>
          <PopupTaskButtonWeb
            selectedDuedate={selectedDueDateRange}
            title={t('models:projects.taskList.dueDate')}
            boxProps={offsetPopButton}
            onSelectDuedate={setSelectedDueDateRange}
          />
          <Box ml='xs'></Box>
          <PopupTaskButtonWeb
            title={t('models:projects.taskList.assignee')}
            boxProps={offsetPopButton}
            onSelectAssignee={setSelectedAssignees}
            selectedAssignee={selectedAssignees}
            initialAssignees={initialAssignees}
          />
        </>
      </Box>
      {(selectedAssignees.length > 0 ||
        selectedPriorities.length > 0 ||
        (selectedProjects && selectedProjects.length > 0) ||
        selectedDueDateRange.start ||
        selectedDueDateRange.end) && (
        <Box
          mb='xxs'
          ml='m'
          mr='s'
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}>
          {selectedAssignees.length > 0 && (
            <Box
              style={{
                marginRight:
                  filterAssigneeTagWidth < barWidth
                    ? Math.min(
                        barWidth - filterAssigneeTagWidth,
                        spaceBetweenTags
                      )
                    : '0',
                maxWidth: `100%`,
              }}>
              {AssigneeTag()}
            </Box>
          )}
          {selectedDueDateRange.start && (
            <Box
              style={{
                marginRight:
                  filterDueDateTagWidth < barWidth
                    ? Math.min(
                        barWidth - filterDueDateTagWidth,
                        spaceBetweenTags
                      )
                    : '0',
                maxWidth: `100%`,
              }}>
              {DueDateRangeTag()}
            </Box>
          )}
          {selectedPriorities.length > 0 && (
            <Box
              style={{
                marginRight:
                  filterPriorityTagWidth < barWidth
                    ? Math.min(
                        barWidth - filterPriorityTagWidth,
                        spaceBetweenTags
                      )
                    : '0',
                maxWidth: `100%`,
              }}>
              {PriorityTag()}
            </Box>
          )}
          {selectedProjects && selectedProjects.length > 0 && (
            <Box
              style={{
                marginRight:
                  filterProjectTagWidth < barWidth
                    ? Math.min(
                        barWidth - filterProjectTagWidth,
                        spaceBetweenTags
                      )
                    : '0',
                maxWidth: `100%`,
              }}>
              {ProjectTag()}
            </Box>
          )}
          {ClearButton()}
        </Box>
      )}
    </>
  );
};
