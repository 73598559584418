import { BottomSheetBackdropProps } from '@gorhom/bottom-sheet';
import React from 'react';
// eslint-disable-next-line import/default
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated';

import theme from '@themes/theme';

export const BottomSheetBackdrop = ({
  animatedIndex,
  style,
}: BottomSheetBackdropProps) => {
  // animated variables
  const containerAnimatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      animatedIndex.value,
      [-1, 0], // value
      [0, 0.5], // opacity
      Extrapolate.CLAMP
    ),
  }));

  const containerStyle = [
    style,
    {
      backgroundColor: theme.colors.black,
    },
    containerAnimatedStyle,
  ];

  return <Animated.View style={containerStyle} />;
};
