import { Project, ProjectMemberRole } from '@graphql/generated';
import useMe from '@hooks/useMe';

type ProjectMembership = {
  isMember: boolean;
  isViewer: boolean;
  isEditor: boolean;
  isAdmin: boolean;
  isOwner: boolean;
  isMemberOrOwner: boolean;
  isAdminOrHigher: boolean;
  isEditorOrHigher: boolean;
};

export const useProjectMembership = (
  project?: Pick<Project, 'members' | 'creator' | 'currentUserRole'>
): ProjectMembership => {
  const { me } = useMe();
  if (!project) {
    return {
      isMember: false,
      isViewer: false,
      isEditor: false,
      isAdmin: false,
      isOwner: false,
      isMemberOrOwner: false,
      isAdminOrHigher: false,
      isEditorOrHigher: false,
    };
  }
  const { members, currentUserRole } = project;

  const isMember = members?.some((u) => u.user.id === me?.id);
  const isOwner = currentUserRole === ProjectMemberRole.Owner;
  const isAdmin = currentUserRole === ProjectMemberRole.Admin;
  const isEditor = currentUserRole === ProjectMemberRole.Editor;
  const isViewer = currentUserRole === ProjectMemberRole.Viewer;
  const isMemberOrOwner = isMember || isOwner;
  const isAdminOrHigher =
    currentUserRole === ProjectMemberRole.Owner ||
    currentUserRole === ProjectMemberRole.Admin;
  const isEditorOrHigher =
    currentUserRole === ProjectMemberRole.Editor || isAdminOrHigher;

  return {
    isMember,
    isViewer,
    isEditor,
    isAdmin,
    isOwner,
    isMemberOrOwner,
    isAdminOrHigher,
    isEditorOrHigher,
  };
};
