import { FlashList } from '@shopify/flash-list';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import { Box, ShadowBox, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import SearchInput from '@components/shared/SearchInput/SearchInput.web';
import { CheckBoxButton } from '@components/Web/CheckBoxButton';
import {
  Project,
  ProjectSortOption,
  SearchableResult,
  useSearchLazyQuery,
} from '@graphql/generated';
import { useListProjectsFromQuery } from '@hooks/useListProjectsFromQuery';
import useNewColors from '@hooks/useNewColors';
import { Search, SearchResultType } from '@root/types';
import Images from '@themes/images';
import theme from '@themes/theme';
import { isProjectSearchResult } from '@utils/typeGuards';

export type PopupTaskFilterProjectProps = {
  onSelectProject?: (project: Project[]) => void;
  selecteds: Project[];
};
const ESTIMATED_CONTACT_ITEM_SIZE = 40;

const DEBOUNCE = 500;

export const PopupTaskFilterProject: React.FC<PopupTaskFilterProjectProps> = ({
  onSelectProject,
  selecteds,
}) => {
  const { projects, fetchFromCursor } = useListProjectsFromQuery({
    first: 25,
    sortBy: ProjectSortOption.NameAsc,
    archivesOnly: false,
  });

  const [loading, setLoading] = useState(false);
  const [globalSearch, { data }] = useSearchLazyQuery();
  const searchData = (data?.search as Search<SearchableResult>[]) || [];

  const [search, setSearch] = useState<string>('');
  const [selected, setSelected] = useState<Project[]>(selecteds);
  const [hoveredId, setHoveredId] = useState('');

  const initialResults = {
    messages: [],
    tasks: [],
    documents: [],
    projects: [],
  };

  const results =
    searchData.reduce<SearchResultType>((previous, result) => {
      if (isProjectSearchResult(result))
        return { ...previous, projects: [...previous.projects, result] };

      return previous;
    }, initialResults) || initialResults;
  const globalSearchCall = () =>
    globalSearch({
      variables: {
        term: search,
        size: 20,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: true,
        includeTasks: false,
      },
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    });

  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [search]
  );

  const projectListData =
    search != '' ? results.projects.map((r) => r.record) : [];

  useEffect(() => {
    if (search != '') {
      setLoading(true);
      debouncedGlobalSearch();
    }

    return () => {
      debouncedGlobalSearch.cancel();
      setLoading(false);
    };
  }, [search]);
  const onPressProject = (project: Project) => {
    const isIn = selected.some((c) => c.id === project.id);
    const updatedSelected = isIn
      ? selected.filter((c) => c.id !== project.id)
      : [...selected, project];
    setSelected(updatedSelected);
    onSelectProject && onSelectProject(updatedSelected);
  };

  const { getMappedColor } = useNewColors();

  return (
    <ShadowBox
      flex={1}
      width={339}
      height={232}
      alignItems='center'
      justifyContent='center'
      borderRadius='xs'
      variant='base'
      backgroundColor='white'>
      <>
        <Box
          marginHorizontal='m'
          marginTop='m'
          style={{ width: 307, backgroundColor: 'grey02' }}
          justifyContent='center'
          flexDirection='row'
          alignItems='flex-start'>
          <SearchInput
            value={search}
            height={40}
            onTextChanged={(value: string) => {
              setSearch(value);
            }}
            placeHolder='Search Project'
          />
        </Box>
        <Box mt='s' mb='s' flex={1} style={{ maxHeight: 152, width: 339 }}>
          {(search == '' ? projects : projectListData).length == 0 &&
            loading && (
              <Box mb='l'>
                <ActivityIndicatorLoading />
              </Box>
            )}
          <FlashList
            onEndReached={fetchFromCursor}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            ListFooterComponent={() => <Box marginBottom='m' />}
            data={search == '' ? projects : projectListData}
            renderItem={({ item: project }) => {
              const { id, name, icon, archivedAt, color } = project;
              return (
                <Box
                  style={{
                    paddingVertical: 9,
                    backgroundColor:
                      hoveredId !== '' && hoveredId == project.id
                        ? theme.colors.grey01
                        : theme.colors.white,
                  }}
                  onMouseEnter={() => {
                    setHoveredId(id);
                  }}
                  onMouseLeave={() => {
                    setHoveredId('');
                  }}>
                  <TouchableOpacity
                    onPress={() => {
                      onPressProject(project);
                    }}
                    accessibilityLabel={name}>
                    <Box
                      flexDirection='row'
                      alignItems='center'
                      marginLeft='m'
                      justifyContent='space-between'>
                      <CheckBoxButton
                        checked={selecteds.some((p) => p.id === id)}
                      />
                      <Box
                        alignItems='center'
                        justifyContent='center'
                        mx='xs'
                        style={{
                          borderRadius: 4,
                          padding: 6,
                          backgroundColor: archivedAt
                            ? theme.colors.grey03
                            : getMappedColor(color),
                        }}>
                        <Icon
                          name={
                            icon
                              ? (icon as keyof typeof Images)
                              : 'BuildingHomeHouse'
                          }
                          color='white'
                          variant='m'
                        />
                      </Box>
                      <Box mr='m' flex={1}>
                        <Text
                          variant='webBodySecondary'
                          color='textPrimary'
                          numberOfLines={1}>
                          <Highlighter
                            highlightStyle={{
                              backgroundColor: theme.colors.yellowBright,
                            }}
                            autoEscape
                            searchWords={search?.split(' ') || ''}
                            textToHighlight={name}
                          />
                        </Text>
                      </Box>
                    </Box>
                  </TouchableOpacity>
                </Box>
              );
            }}
            estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
          />
        </Box>
      </>
    </ShadowBox>
  );
};
