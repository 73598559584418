import React, { createContext, useContext, useState } from 'react';

import { TagsCollectionType } from '@components/Chat/TagsCollection';
import { Chat } from '@graphql/generated';

type ChatId = TagInputCollection['chatId'];

type TagInputCollection = {
  chatId: Chat['id'];
  tags: TagsCollectionType[];
};

type TagInputContext = {
  tags: (chatId: ChatId) => TagsCollectionType[];
  setTags: (chatId: ChatId, tags: TagsCollectionType[]) => void;
  tagsLength: number;
};

const tagInputContext = createContext<TagInputContext | undefined>(undefined);

export const TagInputProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = tagInputContext;
  const [tagInputCollection, setTagInputCollection] = useState<
    TagInputCollection[]
  >([]);

  const tags = (chatId: ChatId) => {
    return tagInputCollection.find((t) => t.chatId == chatId)?.tags || [];
  };

  const setTags = (chatId: ChatId, tags: TagsCollectionType[]) => {
    if (tagInputCollection.some((t) => t.chatId == chatId)) {
      setTagInputCollection((prev) => {
        return prev.map((t) => {
          if (t.chatId == chatId) {
            return { chatId, tags };
          } else {
            return t;
          }
        });
      });
    } else {
      setTagInputCollection((prev) => [...prev, { chatId, tags }]);
    }
  };

  const tagsLength = tagInputCollection.reduce(
    (acc, curVal) => acc + (curVal?.tags || []).length,
    0
  );

  return (
    <Provider
      value={{
        tags,
        setTags,
        tagsLength,
      }}>
      {children}
    </Provider>
  );
};

const useTagInput = (): TagInputContext => {
  const context = useContext(tagInputContext);
  if (context === undefined) {
    throw new Error('useTagInput must be used within a Provider');
  }
  return context;
};

export default useTagInput;
