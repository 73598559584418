import { useNavigation } from '@react-navigation/native';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Alert, Image, Dimensions } from 'react-native';

import Avatar from '@components/Avatar/Avatar';
import { Box, ShadowBox, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import PhoneField from '@components/shared/PhoneField/PhoneField';
import { PopupTimeZoneButtonWeb } from '@components/Web/PopupTimeZoneButton.web';
import { useGoogleAuth } from '@hooks/useGoogleAuth';
import { RegisterStackScreenProps } from '@navigation/auth/register-stack-new.web';
import { humanTimeZoneName } from '@utils/formatters/date';
import { getApiConfig } from '@utils/getApiConfig';

import { RegisterFormValues } from './RegisterForm';

const apiConfig = getApiConfig();

const CreateProfileForGoogleAndApple: React.FC<
  RegisterStackScreenProps<'create-profile-for-google-and-apple'>
> = () => {
  const navigation = useNavigation();
  const [bodyWidth, setBodyWidth] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);
  const { t } = useTranslation();
  const {
    setFieldValue,
    errors,
    setErrors,
    values,
    touched,
    isValid,
    setTouched,
    submitForm,
  } = useFormikContext<RegisterFormValues>();

  const [loading, setLoading] = useState<boolean>(false);

  const { userInfo } = useGoogleAuth();

  useEffect(() => {
    if (userInfo) {
      userInfo?.email && setFieldValue('email', userInfo?.email);
      userInfo?.given_name && setFieldValue('first_name', userInfo?.given_name);
      userInfo?.family_name &&
        setFieldValue('last_name', userInfo?.family_name);
      userInfo?.provider && setFieldValue('provider', userInfo?.provider);
      userInfo?.id && setFieldValue('uid', userInfo?.id);
    }
  }, [userInfo]);

  const setTimeZone = (value: string) => {
    setFieldValue('time_zone', value);
  };

  useEffect(() => {
    setFieldValue('step', 2);
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      setFieldValue('step', 1);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const verifyPhoneNumber = async () => {
    setLoading(true);
    const verifyPhoneNumberURL = `/api/v2/phone_number_available.json`;
    const userPayload = {
      phone_number: values.phone,
    };

    const authEndpoint = `${apiConfig.apiUrl}${verifyPhoneNumberURL}`;

    await fetch(authEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userPayload),
    })
      .then((response) => response.json())
      .then(async (response) => {
        setLoading(false);
        if (response?.error) {
          setErrors({
            phone:
              response.error === 'Phone number has already been taken'
                ? 'This phone number already exists. Please use other phone number or login.'
                : response.error,
          });
        } else {
          if (response.success) {
            if (process.env.PHONE_VERIFICATION_ENABLED === 'true') {
              navigation.navigate('register-with-phone-verify-code', {
                isRegiserWithPhoneNumber: false,
              });
            } else {
              const teamInvitationCode = window.sessionStorage.getItem(
                'account_invitations'
              );
              if (teamInvitationCode) {
                window.sessionStorage.setItem(
                  'do_not_show_invite_message',
                  '1'
                );
                submitForm();
              } else {
                navigation.navigate('create-team');
              }
            }
          } else {
            Alert.alert('Phone number available method error.');
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const disabled =
    (touched.first_name && !!errors.first_name) ||
    (!!errors.first_name && !isValid) ||
    (touched.last_name && !!errors.last_name) ||
    (!!errors.last_name && !isValid) ||
    (touched.phone && !!errors.phone) ||
    (!!errors.phone && !isValid);

  const offsetPopButton: Partial<any> = {
    position: 'left',
    offset: [0, 6],
  };

  return (
    <Box
      flex={1}
      backgroundColor='grey01'
      alignItems='center'
      justifyContent='center'>
      <ShadowBox
        variant='base'
        backgroundColor='white'
        borderRadius='m'
        style={{
          width: 492,
        }}
        onLayout={(e) => {
          setBodyWidth(e.nativeEvent.layout.width);
          setBodyHeight(e.nativeEvent.layout.height);
        }}>
        <Box
          alignItems='center'
          borderRadius='m'
          px='xl'
          mx='xxs'
          py='l'
          my='xs'
          backgroundColor='white'>
          <Icon name='Logo' width={142} height={43} marginBottom='xl' />
          <Text variant='heading2' marginTop='xxs' style={{ marginBottom: 52 }}>
            {t('models:onBoarding.createYourProfile')}
          </Text>
          {loading && (
            <Box marginBottom='s'>
              <ActivityIndicator />
            </Box>
          )}
          <Box
            flex={1}
            flexDirection='column'
            alignItems='center'
            marginBottom='l'>
            {userInfo?.picture ? (
              <Image
                source={{ uri: encodeURI(userInfo?.picture) }}
                resizeMode='cover'
                referrerPolicy='no-referrer'
                style={{
                  width: 94,
                  height: 94,
                  borderRadius: 47,
                }}
              />
            ) : (
              <Avatar
                id={userInfo?.id}
                label={
                  (userInfo?.given_name ?? '') +
                  ' ' +
                  (userInfo?.family_name ?? '')
                }
                size='large'
                width={94}
                height={94}
              />
            )}
            <Text
              variant='heading2'
              numberOfLines={1}
              ellipsizeMode='tail'
              style={{ marginTop: 10 }}>
              {userInfo?.given_name + ' ' + userInfo?.family_name || ''}
            </Text>
          </Box>

          <Field
            component={PhoneField}
            label='Phone Number'
            name='phone'
            placeholder='(xxx)xxx-xxxx'
            isRequired
            isLarge
            accessibilityLabel='Phone Number'
            phoneIn
            value={values.phone}
          />

          <Box width={bodyWidth - 80} marginBottom='l'>
            <Text color='textPrimary' variant='labelSmall' marginBottom='xs'>
              Time Zone
            </Text>
            <PopupTimeZoneButtonWeb
              title={humanTimeZoneName(values.time_zone ?? '') ?? ''}
              setTimeZone={setTimeZone}
              boxProps={offsetPopButton}
              maxHeight={
                (Dimensions.get('window').height - bodyHeight) / 2 + 80
              }
            />
          </Box>

          <Button
            disabled={loading || disabled}
            variant='primary'
            fullWidth
            isSmall
            onPress={() => {
              setTouched({
                ...touched,
                first_name: true,
                last_name: true,
                phone: true,
              });
              if (
                !(
                  (!!errors.first_name && !isValid) ||
                  (!!errors.last_name && !isValid) ||
                  (!!errors.phone && !isValid)
                )
              ) {
                verifyPhoneNumber();
              }
            }}
            accessibilityLabel={t('shared:createAccount')}>
            {t('shared:createAccount')}
          </Button>
        </Box>
      </ShadowBox>
    </Box>
  );
};

export default CreateProfileForGoogleAndApple;
