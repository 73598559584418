import React from 'react';

import { Box } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon, { RestyleProps } from '@components/shared/Icon/RestyleIcon';

interface Props {
  createNewButtonProps: {
    text: string;
    onPress: () => void;
    prefix?: React.ReactChild;
    variant?: 'primary' | 'editBold';
    style?: RestyleProps;
  };
}

/**
 * Action header component for list screens that includes a create new
 * button and search input
 */
export const ListScreenActionHeader = ({
  createNewButtonProps: {
    onPress,
    text: createNewText,
    prefix,
    variant = 'primary',
    style,
  },
}: // search: { value: searchValue, onTextChanged: onSearchTextChanged },
Props) => {
  return (
    <Box flexDirection='row' justifyContent='space-between'>
      <Box
        flex={1}
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-start'>
        <Button
          prefix={prefix ?? <Icon variant='l' name='Plus' color='white' />}
          borderRadius='xl'
          variant={variant}
          isSmall
          prefixMarginRight='s'
          color='onSurface'
          onPress={onPress}
          {...style}>
          {createNewText}
        </Button>
      </Box>
    </Box>
  );
};
