import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { HeaderWrapper } from './HeaderWrapper';
import { Box, Text } from '../Restyle';

type FloatingHeaderProps = {
  disabled?: boolean;
  onSave?: () => void;
  simple?: boolean;
  title: string;
  saveButtonTitle?: string;
  hideBorder?: boolean;
  hideCancel?: boolean;
  disablePadding?: boolean;
  onCancel?: () => void;
};

export const FloatingHeader: React.FC<FloatingHeaderProps> = ({
  simple,
  disabled,
  onSave,
  title,
  saveButtonTitle,
  hideBorder,
  hideCancel,
  disablePadding,
  onCancel,
}) => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const renderHeader = (
    <Box flexDirection='row' justifyContent='space-between'>
      {!hideCancel && (
        <Box style={styles.back}>
          <TouchableOpacity
            onPress={onCancel ? onCancel : () => navigation.goBack()}
            accessibilityLabel={t('shared:cancel')}>
            <Text color='greenSecondary'>{t('shared:cancel')}</Text>
          </TouchableOpacity>
        </Box>
      )}

      <Box flex={1}>
        <Text variant='labelEmphasized' textAlign='center'>
          {title}
        </Text>
      </Box>

      {onSave && (
        <Box style={styles.save}>
          {!disabled ? (
            <TouchableOpacity
              onPress={() => onSave()}
              accessibilityLabel={
                saveButtonTitle ? saveButtonTitle : t('shared:save')
              }>
              <Text color='greenSecondary'>
                {saveButtonTitle ? saveButtonTitle : t('shared:save')}
              </Text>
            </TouchableOpacity>
          ) : (
            <Text color='grey04'>
              {saveButtonTitle ? saveButtonTitle : t('shared:save')}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
  if (hideBorder)
    return (
      <Box paddingHorizontal={disablePadding ? undefined : 'm'}>
        {renderHeader}
      </Box>
    );
  return <HeaderWrapper simple={simple}>{renderHeader}</HeaderWrapper>;
};

const styles = StyleSheet.create({
  back: {
    zIndex: 99,
    position: 'absolute',
    left: 0,
  },
  save: {
    zIndex: 99,
    position: 'absolute',
    right: 0,
  },
});
