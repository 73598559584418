import { useNavigation } from '@react-navigation/native';
import * as ExpoLocation from 'expo-location';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import {
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from 'react-native-google-places-autocomplete';
import Highlighter from 'react-native-highlight-words';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import theme from '@themes/theme';

export type Location = {
  address: string;
  latitude: number;
  longitude: number;
};

interface LocationSearchProps {
  onSelect: (location: Location) => void;
  onClose?: () => void;
  isShowCancel?: boolean;
}

export const LocationSearch: React.FC<LocationSearchProps> = ({
  onSelect,
  onClose,
  isShowCancel = true,
}) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const insets = useSafeAreaInsets();
  const ref = useRef<GooglePlacesAutocompleteRef>();
  const [enableHighAccuracy, setEnableHighAccuracy] = useState(false);

  const requestLocationPermissions = async () => {
    const response = await ExpoLocation.requestForegroundPermissionsAsync();

    if (response.granted) {
      setEnableHighAccuracy(true);
    }
  };

  useEffect(() => {
    requestLocationPermissions();
  }, []);

  const getRenderRow = (data: any, index: number) => {
    let name = data.description;
    let address = '';
    if (data.description.indexOf(',') > 0) {
      name = data.description.substring(0, data.description.indexOf(','));
      address = data.description.substring(data.description.indexOf(',') + 2);
    }
    return (
      <Box accessibilityLabel='Address' key={index} alignItems='flex-start'>
        <Text variant='buttonLabel' color='textPrimary' numberOfLines={1}>
          <Highlighter
            autoEscape
            highlightStyle={{
              color: theme.colors.grey04,
              fontFamily: 'Inter_500Medium',
              backgroundColor: theme.colors.background,
            }}
            searchWords={[ref?.current?.getAddressText() || '']}
            textToHighlight={name}
          />
        </Text>
        {address && (
          <Text
            variant='labelSmall'
            color='grey04'
            marginTop='xxs'
            numberOfLines={1}>
            {address}
          </Text>
        )}
      </Box>
    );
  };

  return (
    <Box flex={1} style={{ marginTop: insets.top }} backgroundColor='white'>
      <Box flexDirection='row' alignItems='center'>
        <Box
          style={{
            ...StyleSheet.absoluteFillObject,
            zIndex: 2,
            paddingTop: theme.spacing.m,
            paddingLeft: theme.spacing.xs,
          }}
          pointerEvents='none'>
          <Icon name='Search' variant='m' color='grey04' />
        </Box>

        <GooglePlacesAutocomplete
          ref={ref}
          enableHighAccuracyLocation={enableHighAccuracy}
          placeholder='Search'
          query={{
            key: process.env.GOOGLE_PLACES_API_KEY,
            language: 'en',
          }}
          requestUrl={{
            useOnPlatform: 'web',
            url: 'https://cjf69wd314.execute-api.us-east-1.amazonaws.com/dev',
          }}
          fetchDetails={true}
          onPress={(data, details = null) => {
            const location = {
              address: details?.formatted_address || data.description,
              latitude: details?.geometry.location.lat || 0,
              longitude: details?.geometry.location.lng || 0,
            };
            onSelect(location);
            onClose ? onClose() : navigation.pop();
          }}
          textInputProps={{
            InputComp: TextInput,
            autoFocus: true,
            errorStyle: { color: theme.colors.alertRed },
            clearButtonMode: true,
            leftIcon: () => <Icon name='Search' variant='m' color='black' />,
          }}
          listEmptyComponent={() => (
            <Box flex={1} paddingTop='m' alignItems='center' marginBottom='xs'>
              <Text>{t('models:location.notFound')}</Text>
            </Box>
          )}
          renderRow={(data, index) => getRenderRow(data, index)}
          renderRightButton={() =>
            isShowCancel ? (
              <TouchableOpacity
                onPress={() => (onClose ? onClose() : navigation.pop())}>
                <Text marginLeft='xs' variant='buttonLabel' color='blue'>
                  {t('shared:cancel')}
                </Text>
              </TouchableOpacity>
            ) : (
              <Box />
            )
          }
          styles={{
            textInput: {
              height: 36,
              backgroundColor: theme.colors.grey02,
              paddingVertical: 0,
              paddingLeft: theme.spacing.xl,
              marginTop: theme.spacing.xs,
              fontSize: 16,
              fontWeight: '500',
              borderRadius: theme.borderRadii.xs,
            },
            textInputContainer: {
              alignItems: 'center',
            },
            row: {
              paddingVertical: theme.spacing.xxs,
            },
            separator: {
              height: 1,
              backgroundColor: theme.colors.grey02,
            },
            poweredContainer: {
              justifyContent: 'center',
              borderColor: theme.colors.grey02,
              borderTopWidth: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};
