import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActivityList from '@components/ActivityList/ActivityList';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import {
  GetProjectDocument,
  useMarkAuditsReadMutation,
} from '@graphql/generated';
import { useListProjectActivityFromQuery } from '@hooks/useListProjectsActivityFromQuery';
import { useProjectFromQuery } from '@hooks/useProjectFromQuery';
import { ProjectsStackScreenProps } from '@navigation/projects/projects-stack';
const PER_PAGE_COUNT = 10;
const ProjectActivityLog: React.FC = () => {
  const {
    params: { projectId },
  } = useRoute<ProjectsStackScreenProps<'project-activity-log'>['route']>();
  const [loadingMore, setLoadingMore] = useState(false);
  const { t } = useTranslation();

  const { project } = useProjectFromQuery({
    projectId,
  });

  const {
    activity: allProjectsActivity,
    refetch: refetchProjects,
    refreshing: refreshingProjects,
    pageInfo: pageInfo,
    setRefreshing: setRefreshingProjects,
    fetchMoreFromCursor: fetchMoreProjectsFromCursor,
    loading,
  } = useListProjectActivityFromQuery({
    projectId,
    first: PER_PAGE_COUNT,
    onCompleted: () => {
      setLoadingMore(false);
    },
  });
  const [markAuditsRead] = useMarkAuditsReadMutation({
    refetchQueries: [
      { query: GetProjectDocument, variables: { id: projectId } },
    ],
  });

  useEffect(() => {
    if (project?.unreadAudits?.length ?? 0 > 0) {
      markAuditsRead({
        variables: {
          attributes: { auditIds: project?.unreadAudits?.map((a) => a.id) },
        },
      });
    }
  }, []);
  if (loading) {
    return <ActivityIndicatorLoading />;
  }

  const onLoadMore = () => {
    setLoadingMore(true);
    fetchMoreProjectsFromCursor();
  };

  const renderEmptyActiveComponent = () => {
    return (
      <Box
        alignItems='center'
        alignSelf='center'
        alignContent='center'
        justifyContent='center'>
        <Text color='grey04' textAlign='center'>
          {t('NoRecordFound')}
        </Text>
      </Box>
    );
  };

  return (
    <Box flex={1}>
      <ActivityList
        activityType='Project'
        refreshing={refreshingProjects}
        // must convert id's to number to sort correctly
        audits={allProjectsActivity}
        onEndReached={onLoadMore}
        projectId={projectId}
        onRefresh={() => {
          setRefreshingProjects(true);
          refetchProjects();
        }}
        fetchMore={fetchMoreProjectsFromCursor}
        loadingMore={loadingMore}
        renderEmptyActiveComponent={renderEmptyActiveComponent}
        listFooterComponent={() => (
          <>
            {pageInfo?.hasNextPage ? (
                <Box mb='listFooter'>
                  {loadingMore && <ActivityIndicatorLoading />}
                </Box>
            ) : (
              <Box mb='l'></Box>
            )}
          </>
        )}
      />
    </Box>
  );
};
export default ProjectActivityLog;
