import React from 'react';

import { GroupChatMembersList } from '@components/Invite/GroupChatMemberList';
import { WebModal } from '@components/Modals/WebModal.web';

interface addMembersWebProps {
  onNewMemberClose: () => void;
  showAddNewMembersModal: boolean;
  chatId: string;
}

const AddMembersWeb: React.FC<addMembersWebProps> = ({
  onNewMemberClose,
  showAddNewMembersModal,
  chatId,
}) => {
  return (
    <WebModal
      onClose={onNewMemberClose}
      visible={showAddNewMembersModal}
      width={504}
      title='Add Members'
      height={692}
      children={
        <GroupChatMembersList chatId={chatId} onClose={onNewMemberClose} />
      }
    />
  );
};

export default AddMembersWeb;
