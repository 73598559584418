import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, SectionList } from 'react-native';

import EmptyStateNoFiles from '@components/EmptyState/EmptyStateNoFiles';
import { NotPaidConfirmModal } from '@components/Modals/NotPaidConfirmModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import ImageGrid from '@components/shared/ImageGrid.web';
import { Document } from '@graphql/generated';
import { useListProjectDocumentsFromQuery } from '@hooks/useListProjectDocumentsFromQuery';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import { ProjectFilesProps } from '@screens/Projects/ProjectFiles.web';
import { filterByDateRange } from '@utils/tasks';

export const ProjectMedia: React.FC<ProjectFilesProps> = ({
  projectId,
  ownerId,
  sortBy,
  isSelectMode,
  onSelectedModeChange,
  selectedList,
  handelSelectedItem,
  dateRange,
}) => {
  const navigation = useNavigation();
  const { setPreviewDocuments, setActiveIndex } = usePreviewDocuments();
  const { t } = useTranslation('format');
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);

  const { isPaid, setPaid } = useMe();
  const [isPaidUser, setPaidUser] = useState<boolean>(!!isPaid);
  const [showNotPaidConfirmModal, setShowNotPaidConfirmModal] =
    useState<boolean>(false);

  const showNotPaidModal = (file: Document) => {
    const createdAt = dayjs(file.createdAt);
    const expired = createdAt?.diff(new Date(), 'day');
    if (!isPaidUser && expired < -1000) {
      if (Platform.OS === 'web') {
        setShowNotPaidConfirmModal(true);
      } else {
        Alert.alert(
          'Project ' + t('shared:attachmentsExpired'),
          t('shared:attachmentsExpiredMessage'),
          [
            { text: 'Cancel' },
            {
              text: t('shared:upgrade'),
              style: 'destructive',
              onPress: () => {
                setPaid(true);
                setPaidUser(true);
              },
            },
          ]
        );
      }
      return true;
    }
    return false;
  };

  const { documents, loading, pageInfo, fetchFromCursor } =
    useListProjectDocumentsFromQuery({
      projectIds: [projectId],
      ownerIds: ownerId ? [ownerId] : undefined,
      sortBy,
      includeMedia: true,
      first: 30,
    });

  const renderFooterUI = (isLoadingMore: boolean) => {
    return (
      <Box marginBottom='listFooter'>
        {isLoadingMore && <ActivityIndicatorLoading />}
      </Box>
    );
  };

  const sectionData = () => {
    if (documents?.length === 0) {
      return [];
    }

    const filteredDocuments = dateRange
      ? filterByDateRange(documents, ({ createdAt }) => createdAt, dateRange)
      : documents;

    const list = filteredDocuments?.reduce(
      (acc: Record<string, Document[]>, obj: Document) => {
        const { createdAt: date } = obj;
        const dateAsKey = t('monthAndDayFormatDash', {
          val: date,
        });
        const existingValue = acc[dateAsKey];

        return Object.assign({}, acc, {
          [dateAsKey]: [...(existingValue ? existingValue : []), obj],
        });
      },
      {}
    );

    if (list) {
      const documentList = Object.entries(list).map(([key, value]) => {
        return { title: key, data: [value] };
      });
      const sectionDocumentsByDate = documentList.sort(
        (a, b) => +new Date(b.title) - +new Date(a.title)
      );
      return sectionDocumentsByDate;
    } else {
      return [];
    }
  };

  const renderItem = ({ item }: { item: Document[] }) => {
    return (
      <Box marginHorizontal='m' marginBottom='xs'>
        <ImageGrid
          list={item}
          selectedList={selectedList}
          isSelectMode={isSelectMode}
          onPress={(image) => {
            if (showNotPaidModal(image as Document)) return;
            if (isSelectMode) {
              handelSelectedItem(image);
            } else {
              setPreviewDocuments(item);
              setActiveIndex(item.findIndex((i) => i.id === image.id));
              if (Platform.OS === 'web') {
                setImageCarouselVisible(true);
              } else {
                navigation.navigate('images-preview', {
                  deleteCall: 'project',
                });
              }
            }
          }}
          onLongPress={(image) => {
            onSelectedModeChange(true);
            handelSelectedItem(image);
          }}
        />
      </Box>
    );
  };

  if (loading) return <ActivityIndicatorLoading />;

  return (
    <Box flex={1}>
      <SectionList
        showsVerticalScrollIndicator={false}
        stickySectionHeadersEnabled={false}
        sections={sectionData()}
        renderItem={renderItem}
        ItemSeparatorComponent={() => <Box marginBottom='l' />}
        ListFooterComponent={() => renderFooterUI(pageInfo?.hasNextPage)}
        onEndReached={() => {
          fetchFromCursor();
        }}
        onEndReachedThreshold={0.5}
        ListHeaderComponent={() => <Box marginTop='xs' />}
        renderSectionHeader={({ section: { title } }) => (
          <Box marginTop='m' marginBottom='xs' marginHorizontal='m'>
            <Text variant='bodyTaskName' color='textPrimary'>
              {title}
            </Text>
          </Box>
        )}
        ListEmptyComponent={() => <EmptyStateNoFiles />}
        contentContainerStyle={{ flexGrow: 1 }}
      />
      {Platform.OS === 'web' && isImageCarouselVisible && (
        <ImagesPreview onDismiss={() => setImageCarouselVisible(false)} />
      )}
      {showNotPaidConfirmModal && (
        <NotPaidConfirmModal
          showModal={showNotPaidConfirmModal}
          onClose={() => setShowNotPaidConfirmModal(false)}
          onPress={() => {
            setShowNotPaidConfirmModal(false);
            setPaid(true);
            setPaidUser(true);
          }}
          title='Project'
        />
      )}
    </Box>
  );
};
