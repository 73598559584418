import React, { useMemo } from 'react';
import Highlighter from 'react-native-highlight-words';

import { ProjectAvatar } from '@components/ProjectCard/ProjectAvatar';
import Icon from '@components/shared/Icon/Icon';
import useColors from '@hooks/useColors';
import { ProjectTagType } from '@src/types/project';
import theme from '@themes/theme';

import { Box, Text } from '../Restyle';

export interface ProjectTagProps {
  project: ProjectTagType;
  filterVal?: string;
  iconColor?: keyof typeof theme.colors;
  isFrom?: string;
}

export const ProjectTag = ({
  project,
  filterVal = '',
  isFrom,
}: ProjectTagProps) => {
  const { name, color, initials, team } = project;
  const { getMappedColor } = useColors();
  const isPersonalProject = team?.personal ?? false;

  const renderIcon = useMemo(() => {
    return (
      <ProjectAvatar
        color={getMappedColor(color)}
        initials={initials}
        dimensions={{
          width: 48,
          height: 48,
        }}
        personal={isPersonalProject}
      />
    );
  }, []);

  const renderHighlighter = useMemo(() => {
    return (
      <Highlighter
        autoEscape
        highlightStyle={{
          color: theme.colors.black,
          fontFamily: 'Inter_600SemiBold',
          backgroundColor: theme.colors.yellowBright,
        }}
        searchWords={filterVal?.split(' ') || ''}
        textToHighlight={name}
      />
    );
  }, [filterVal]);

  return (
    <Box flexDirection='row' alignItems='center'>
      {isFrom === 'Chat' ? <Icon name='TTProject' variant='m' /> : renderIcon}
      <Box flex={1} marginLeft='xs'>
        <Text variant='labelEmphasized' numberOfLines={1} lineHeight={21}>
          {renderHighlighter}
        </Text>
      </Box>
    </Box>
  );
};
