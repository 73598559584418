/* eslint-disable import/no-named-as-default-member */
import React, {
  ComponentProps,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Platform,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from 'react-native';

import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import useGlobalSearch from '@hooks/useGlobalSearch';
import theme from '@themes/theme';

type SearchInputProps = {
  onTextChanged: (searchText: string) => void;
  value?: string;
  onEscPress?: () => void;
  onTabOut?: () => void;
  onCancel?: () => void;
  onViewAll?: () => void;
  isChatRelatedTagSearch?: boolean;
  isTagSearch?: boolean;
  placeHolder?: string;
  height?: number;
} & Pick<
  ComponentProps<typeof TextInput>,
  'onFocus' | 'onBlur' | 'autoFocus' | 'editable'
>;

const SearchInput = React.forwardRef<TextInput, SearchInputProps>(
  (
    {
      onTextChanged,
      autoFocus,
      onFocus,
      onBlur,
      onEscPress,
      onTabOut,
      value,
      editable,
      isChatRelatedTagSearch,
      isTagSearch,
      onCancel,
      placeHolder,
      height = 0,
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    const [internalValue, setInternalValue] = useState('');
    const { isSearchFocus, isViewAllPress, setViewAllEvent } =
      useGlobalSearch();
    const PLACEHOLDER = isTagSearch ? 'Search Tag' : 'Search';
    const ref_inputBox = useRef<TextInput>(null);
    useImperativeHandle(ref, () => ref_inputBox.current as TextInput);

    useEffect(() => {
      (isSearchFocus || autoFocus) && ref_inputBox?.current?.focus();
    }, [isSearchFocus, autoFocus]);

    return (
      <Box flex={1} flexDirection='row' alignItems='center'>
        {Platform.OS !== 'web' && !isViewAllPress && (
          <Icon
            name='ChevronLeft'
            variant='xl'
            onPress={() => setViewAllEvent(true)}
          />
        )}
        <Box
          flex={1}
          backgroundColor='grey01'
          borderRadius='xs'
          flexDirection='row'
          alignItems='center'
          paddingVertical={isChatRelatedTagSearch ? 'xs' : 'xxs'}
          paddingHorizontal={isChatRelatedTagSearch ? 'm' : 'xs'}
          accessibilityLabel='Search'>
          <Icon
            name={isTagSearch ? 'HashSearch' : 'Search'}
            variant='l'
            color={focused ? 'textPrimary' : 'textSecondary'}
          />
          <TextInput
            editable={editable}
            ref={ref_inputBox}
            placeholderTextColor={theme.colors.grey04}
            style={[
              styles.input,
              theme.textVariants.bodySecondary,
              height > 0 && { height: height },
            ]}
            placeholder={placeHolder ? placeHolder : PLACEHOLDER}
            value={Platform.OS === 'web' ? value ?? internalValue : value}
            autoCapitalize='none'
            autoFocus={autoFocus || isSearchFocus}
            onKeyPress={({ nativeEvent: { key } }) => {
              key === 'Tab' && focused && onTabOut && onTabOut();
              key === 'Escape' && focused && onEscPress && onEscPress();
            }}
            onBlur={(e) => {
              onBlur?.(e);
              setFocused(false);
            }}
            onFocus={(e) => {
              setTimeout(() => {
                onFocus?.(e);
                setFocused(true);
              }, 500);
            }}
            onChangeText={(str) => {
              onTextChanged(str);
            }}
          />
          <TouchableOpacity>
            {(!!internalValue || !!value) && (
              <Icon
                name='X'
                variant='l'
                color='textPrimary'
                onPress={() => {
                  ref_inputBox?.current?.focus();
                  setFocused(true);
                  onTextChanged('');
                  setInternalValue('');
                  if (onCancel) {
                    setViewAllEvent(false);
                    onCancel();
                  }
                }}
              />
            )}
          </TouchableOpacity>
        </Box>
      </Box>
    );
  }
);
const styles = StyleSheet.create({
  input: {
    marginLeft: theme.spacing.xs,
    flex: 1,
    ...Platform.select({ web: { outlineStyle: 'none' } }),
  },
});

export default SearchInput;
