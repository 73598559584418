/* eslint-disable no-nested-ternary */
import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import OnboardingModal from '@components/Modals/OnboardingModal';
import { modalStyle } from '@navigation/helpers';
import CreateProfile from '@screens/Auth/CreateProfile.web';
import { EmailLogin } from '@screens/Auth/EmailLogin.web';
import { ForgotPassword } from '@screens/Auth/ForgotPassword.web';
import InvitedUser from '@screens/Auth/InvitedUser';
import { LoadingScreen } from '@screens/Auth/LoadingScreen.web';
import Onboarding from '@screens/Auth/Onboarding';
import RegisterForm from '@screens/Auth/RegisterForm.web';
import { ResetPassword } from '@screens/Auth/ResetPassword.web';

type AuthStackParamList = {
  onboarding: undefined;
  'onboarding-modal': undefined;
  'invited-user': undefined;
  'onboarding-web': { isLogin?: boolean };
  login: { fromReset?: boolean };
  'password-reset': undefined;
  'create-profile': { socialSignUp?: boolean };
  register: { routeName: string };
  'forgot-password': { email: string };
  LoadingScreen: undefined;
};

export type AuthStackScreenProps<T extends keyof AuthStackParamList> =
  StackScreenProps<AuthStackParamList, T>;

const Stack = createStackNavigator<AuthStackParamList>();

const AuthStack: React.FC = () => {
  const { t } = useTranslation();
  const title = t('shared:appName');

  const queryParameters = new URLSearchParams(window.location.search);
  let invitationToken = queryParameters.get('invitation_token');
  const type = queryParameters.get('type') ?? '';
  const id = queryParameters.get('id') ?? '';
  if (invitationToken == null) {
    invitationToken = window.sessionStorage.getItem('invitation_token') ?? '';
  } else {
    window.sessionStorage.setItem('invitation_token', invitationToken);
    window.sessionStorage.setItem('task_or_project', type);
    window.sessionStorage.setItem('task_or_project_id', id);
  }

  const isRegister =
    window.location.pathname === '/register' ||
    window.location.pathname === '/register/signup-with-email';
  const isLogin = window.location.pathname === '/log-in';

  const isInvitedUser = window.location.pathname === '/invited-user';

  return (
    <Stack.Navigator
      screenOptions={{ title }}
      initialRouteName={
        isLogin
          ? 'login'
          : isInvitedUser
          ? 'invited-user'
          : invitationToken || isRegister
          ? 'register'
          : 'login'
      }>
      <Stack.Screen
        name='login'
        component={EmailLogin}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name='LoadingScreen'
        component={LoadingScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name='forgot-password'
        component={ForgotPassword}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name='password-reset'
        component={ResetPassword}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name='register'
        component={RegisterForm}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name='onboarding'
        component={Onboarding}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name='onboarding-modal'
        component={OnboardingModal}
        options={{ ...modalStyle }}
      />
      <Stack.Screen
        name='onboarding-web'
        component={OnboardingModal}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name='invited-user'
        component={InvitedUser}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name='create-profile'
        component={CreateProfile}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default AuthStack;
