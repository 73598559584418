import React from "react";
import { useTranslation } from "react-i18next";

import { WebModal } from "@components/Modals/WebModal.web";
import { CopyProjectDetails } from "@components/Projects/CopyProjectDetails";
import { useAppNavigation } from "@navigation/useAppNavigation";

export const ProjectCopySelect: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();

  return (
    <WebModal visible title={t("shared:select")} onClose={navigation.goBack}>
      <CopyProjectDetails />
    </WebModal>
  );
};
