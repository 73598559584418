import ds from '../assets/documentIcons/3ds.svg';
import aac from '../assets/documentIcons/aac.svg';
import ai from '../assets/documentIcons/ai.svg';
import avi from '../assets/documentIcons/avi.svg';
import bmp from '../assets/documentIcons/bmp.svg';
import cad from '../assets/documentIcons/cad.svg';
import cdr from '../assets/documentIcons/cdr.svg';
import css from '../assets/documentIcons/css.svg';
import csv from '../assets/documentIcons/csv.svg';
import dat from '../assets/documentIcons/dat.svg';
import dll from '../assets/documentIcons/dll.svg';
import dmg from '../assets/documentIcons/dmg.svg';
import doc from '../assets/documentIcons/doc.svg';
import eps from '../assets/documentIcons/eps.svg';
import file from '../assets/documentIcons/file.svg';
import fla from '../assets/documentIcons/fla.svg';
import flv from '../assets/documentIcons/flv.svg';
import folder from '../assets/documentIcons/folder.svg';
import gif from '../assets/documentIcons/gif.svg';
import html from '../assets/documentIcons/html.svg';
import jpeg from '../assets/documentIcons/img.svg';
import indd from '../assets/documentIcons/indd.svg';
import iso from '../assets/documentIcons/iso.svg';
import jpg from '../assets/documentIcons/jpg.svg';
import js from '../assets/documentIcons/js.svg';
import midi from '../assets/documentIcons/midi.svg';
import mov from '../assets/documentIcons/mov.svg';
import mp3 from '../assets/documentIcons/mp3.svg';
import mp4 from '../assets/documentIcons/mp4.svg';
import mpg from '../assets/documentIcons/mpg.svg';
import pdf from '../assets/documentIcons/pdf.svg';
import php from '../assets/documentIcons/php.svg';
import png from '../assets/documentIcons/png.svg';
import ppt from '../assets/documentIcons/ppt.svg';
import ps from '../assets/documentIcons/ps.svg';
import psd from '../assets/documentIcons/psd.svg';
import raw from '../assets/documentIcons/raw.svg';
import sql from '../assets/documentIcons/sql.svg';
import svg from '../assets/documentIcons/svg.svg';
import tif from '../assets/documentIcons/tif.svg';
import txt from '../assets/documentIcons/txt.svg';
import unknown from '../assets/documentIcons/unknown.svg';
import quicktime from '../assets/documentIcons/vid.svg';
import webm from '../assets/documentIcons/webm.svg';
import webp from '../assets/documentIcons/webp.svg';
import wmv from '../assets/documentIcons/wmv.svg';
import xls from '../assets/documentIcons/xls.svg';
import xml from '../assets/documentIcons/xml.svg';
import zip from '../assets/documentIcons/zip.svg';

const documentIcons = {
  ds,
  aac,
  ai,
  avi,
  bmp,
  cad,
  cdr,
  css,
  csv,
  dat,
  dll,
  dmg,
  doc,
  eps,
  file,
  fla,
  flv,
  folder,
  gif,
  html,
  indd,
  iso,
  jpg,
  js,
  midi,
  mov,
  mp3,
  mpg,
  pdf,
  php,
  png,
  ppt,
  ps,
  psd,
  raw,
  sql,
  svg,
  tif,
  txt,
  wmv,
  xls,
  xml,
  zip,
  mp4,
  jpeg,
  quicktime,
  webm,
  webp,
  unknown,
};

export default documentIcons;
