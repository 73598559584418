import React, { createContext, useContext } from 'react';

import { useListContactUsersQuery, User } from '@graphql/generated';
import { useAuthContext } from '@src/context/authContext';

type UsersContext = {
  users: User[];
};

const usersContext = createContext<UsersContext | undefined>(undefined);

export const UsersProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = usersContext;
  const { token } = useAuthContext();
  const { data: usersData } = useListContactUsersQuery({ skip: !token });
  const { listContactUsers } = usersData || { listContactUsers: [] };
  const users = listContactUsers.map((u) => ({
    ...u,
    displayName: u.name,
  }));

  return <Provider value={{ users }}>{children}</Provider>;
};

const useUsers = (): UsersContext => {
  const context = useContext(usersContext);
  if (context === undefined) {
    throw new Error('useUsers must be used within a Provider');
  }
  return context;
};

export default useUsers;
