// import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';

import Popup, { MenuItem } from '@components/shared/Popup/Popup';
// import { ProjectsStackScreenProps } from '@navigation/projects/projects-stack';

type ProjectDetailPopupProps = {
  closePopFn?: () => void;
};

export const ProjectTasksPopup: React.FC<ProjectDetailPopupProps> = ({
  closePopFn,
}) => {
  // const navigation =
  //   useNavigation<ProjectsStackScreenProps<'project-tasks'>['navigation']>();

  // const {
  //   params: { projectId },
  // } = useRoute<ProjectsStackScreenProps<'project-tasks'>['route']>();

  const menuList: MenuItem[] = [
    {
      iconName: 'Edit',
      name: 'Edit',
    },
    // commenting code for future reference as per TA-3310
    // {
    //   iconName: 'Plus',
    //   name: 'Create Phase',
    //   onSelect: () => {
    //     navigation.navigate('create-phase-stack', { projectId });
    //   },
    // },
  ];
  return <Popup width={188} menuList={menuList} closePopFn={closePopFn} />;
};
