import { FlashList } from '@shopify/flash-list';
import React from 'react';
import { FlatList } from 'react-native';

import { Box, Text } from '@components/Restyle';


export const AlphaSortSlider = ({
  flashListRef,
  alphabetizeList,
}: {
  flashListRef: React.RefObject<FlashList<string>>;
  alphabetizeList: string[];
}) => {
  const firstLetterPosition = {
    x: 0,
    y: 0,
    isWaiting: true,
  };
  const onScrollToItem = (item: string) => {
    flashListRef.current?.scrollToItem({
      animated: true,
      item,
    });
  };

  const clickAlphabetAndScrollToSection = (item: string) => {
    onScrollToItem(item);
  };
  const calFirstLetterPosition = (rect: any, index: number) => {
    firstLetterPosition.x = rect.width - rect.x;
    firstLetterPosition.y = rect.height - rect.y - 15 * index;
  };
  const detectAndScrollToSection = (event: any, index: number) => {
    const ev = event.nativeEvent;
    const rect = {
      width: ev.pageX,
      height: ev.pageY,
      x: ev.locationX,
      y: ev.locationY,
    };
    if (firstLetterPosition.x === 0) {
      calFirstLetterPosition(rect, index);
    } else {
      const currentItemIndex = Math.ceil(
        (rect.height - firstLetterPosition.y) / 15 - 1
      );
      if (
        firstLetterPosition.isWaiting &&
        currentItemIndex > -1 &&
        currentItemIndex < alphabetizeList.length
      ) {
        firstLetterPosition.isWaiting = false;
        setTimeout(() => {
          firstLetterPosition.isWaiting = true;
          onScrollToItem(alphabetizeList[currentItemIndex]);
        }, 50);
      }
    }
  };

  return (
    <Box
      width={20}
      height='100%'
      position='absolute'
      right={0}
      flexDirection='column'
      justifyContent='center'>
      <Box>
        <FlatList
          bounces={false}
          keyExtractor={(item, index) => item + index}
          data={alphabetizeList}
          initialNumToRender={27}
          renderItem={({ item, index }) => {
            if (typeof item === 'string') {
              return (
                <Box
                  onStartShouldSetResponder={() => {
                    return true;
                  }}
                  onMoveShouldSetResponder={() => {
                    return true;
                  }}
                  onResponderGrant={() => {
                    clickAlphabetAndScrollToSection(item);
                  }}
                  onResponderMove={(event) => {
                    detectAndScrollToSection(event, index);
                  }}
                  hitSlop={{ top: 0, bottom: 0, left: 0, right: 0 }}>
                  <Text
                    variant='typoMetadata'
                    color='grey04'
                    textAlign='center'>
                    {item}
                  </Text>
                </Box>
              );
            } else {
              return null;
            }
          }}
        />
      </Box>
    </Box>
  );
};
