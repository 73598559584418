/* eslint-disable import/no-default-export */
import { createTheme } from '@shopify/restyle';
import { Platform } from 'react-native';

const palette = {
  greenBrand: '#00D9A5',
  greenSecondary: '#18A87D',
  greenSecondaryMild: '#DCF2EC',
  greenDark: '#035B60',
  yellow: '#E65E43',
  yellowBright: '#FBE676',
  yellowVivid: '#FBBD42',
  yellowVividMild: '#FDF4E0',
  alertRed: '#FF4444',
  alertRedMild: '#FFDADA',
  textPrimary: '#0A1629',
  textSecondary: '#303742',
  pastelBlue: '#1572A1',
  pastelPurple: '#655D8A',
  pastelMagenta: '#A85796',
  pastelOrange: '#CC7351',
  pastelYellow: '#E6B566',
  white: '#FFFFFF',
  grey01: '#F7F8FA',
  grey02: '#E8E8E8',
  grey03: '#BDBDBD',
  grey04: '#828282',
  grey05: '#303742',
  grey: ' #3C3C435C',
  black: '#000000',
  blue: '#138EFF',
  blueAlert: '#007AFF',
  blueMild: '#DCEEFF',
  purple: '#7B61FF',
  purpleMild: '#EBE7FF',
  magentaLight: '#C072CD',
  magentaLightMild: '#F6EAF8',
  magentaDark: '#A620B2',
  orange: '#FC7F5B',
  orangeMild: '#FFECE6',
  lightPurple: '#D9D5E3',
  grey06: 'rgba(0,0,0,0.5)',
  transparent: 'transparent',
};

const theme = createTheme({
  colors: {
    background: palette.white,
    onBackground: palette.textPrimary,
    onBackgroundSecondary: palette.textSecondary,
    onBackgroundTertiary: palette.grey04,
    surface: palette.white,
    onSurface: palette.textPrimary,
    onSurfaceSecondary: palette.textSecondary,
    buttonBackground: palette.black,
    androidShadow: `rgba(0,0,0,0.2)`,
    buttonText: palette.white,
    linkColor: palette.blue,
    ...palette,
  },
  spacing: {
    none: 0,
    xxxs: 2,
    xxs: 4,
    xs: 8,
    s: 12,
    m: 16,
    l: 24,
    xl: 36,
    xxl: 48,
    listFooter: 80,
  } as const,
  borderRadii: {
    none: 0,
    xxxs: 2,
    xxs: 4,
    xs: 8,
    s: 12,
    m: 16,
    l: 24,
    xl: 32,
    xxl: 48,
  } as const,
  breakpoints: {
    phone: 0,
    tablet: 768,
  },
  textVariants: {
    defaults: {
      // same as body
      fontFamily: 'Inter_400Regular',
      fontSize: {
        phone: 16,
      },
      lineHeight: 24,
      color: 'onSurface',
    },
    heading1: {
      fontFamily: 'Inter_600SemiBold',
      fontSize: {
        phone: 28,
        tablet: 32,
      },
      lineHeight: {
        phone: 32,
        tablet: 36,
      },
      color: 'onSurface',
    },
    heading1Web: {
      fontFamily: 'Inter_600SemiBold',
      fontSize: 28,
      lineHeight: 32,
    },
    heading2: {
      fontFamily: 'Inter_600SemiBold',
      fontSize: 22,
      lineHeight: 32,
      color: 'onSurface',
    },
    heading3: {
      fontFamily: 'Inter_600SemiBold',
      fontSize: 32,
      lineHeight: 36,
      color: 'textPrimary',
    },
    labelLarge: {
      fontFamily: 'Inter_500Medium',
      fontSize: 18,
      lineHeight: 24,
      color: 'onSurface',
    },
    labelEmphasized: {
      fontFamily: 'Inter_600SemiBold',
      fontSize: 16,
      lineHeight: 24,
      color: 'onSurface',
    },
    labelEmphasizedChatDetialTitle: {
      fontFamily: 'Inter_600SemiBold',
      fontSize: 16,
      lineHeight: 21,
      color: 'textPrimary',
    },
    labelEmphasizedResponsive: {
      fontFamily: 'Inter_600SemiBold',
      fontSize: 12,
      lineHeight: 24,
      color: 'textSecondary',
    },
    bodyTaskName: {
      fontWeight: 500,
      fontFamily: 'Inter_500Medium',
      fontSize: 14,
      lineHeight: 16,
      color: 'textPrimary',
    },
    taskBtnLabel: {
      fontFamily: 'Inter_500Medium',
      fontSize: 14,
      lineHeight: 16,
      color: 'grey04',
      fontWeight: '500',
    },
    labelAssignedTask: {
      fontFamily: 'Inter_500Medium',
      fontSize: 14,
      lineHeight: 16,
      color: 'pastelMagenta',
    },
    labelCompletedTask: {
      fontFamily: 'Inter_500Medium',
      fontSize: 14,
      lineHeight: 16,
      color: 'greenDark',
    },
    buttonLabel: {
      fontFamily: 'Inter_500Medium',
      fontSize: 16,
      lineHeight: 24,
      color: 'buttonText',
    },
    searchLabel: {
      fontFamily: 'Inter_500Medium',
      fontSize: 16,
      lineHeight: 24,
    },
    labelSmall: {
      fontFamily: 'Inter_500Medium',
      fontSize: 14,
      lineHeight: 18,
    },
    labelSearch: {
      fontFamily: 'Inter_500Medium',
      fontSize: 16,
      lineHeight: 21,
    },
    labelRequired: {
      fontFamily: 'Inter_500Medium',
      fontSize: 10,
      lineHeight: 16,
    },
    headerLabel: {
      fontWeight: 600,
      fontFamily: 'Inter_500Medium',
      fontSize: 16,
      lineHeight: 21,
      color: 'textSecondary',
    },
    body: {
      fontFamily: 'Inter_400Regular',
      fontSize: 16,
      lineHeight: 24,
      color: 'onSurface',
    },
    body1: {
      fontFamily: 'Inter_400Regular',
      fontSize: 16,
      lineHeight: 21,
      color: 'onSurfaceSecondary',
    },
    link: {
      fontFamily: 'Inter_400Regular',
      fontSize: 14,
      lineHeight: 20,
      color: 'linkColor',
    },
    bodySecondary: {
      fontFamily: 'Inter_400Regular',
      fontSize: {
        phone: 14,
      },
      lineHeight: {
        phone: 20,
      },
      letterSpacing: 0.2,
      color: 'onSurface',
    },
    webBody: {
      fontFamily: 'Inter_500Medium',
      fontSize: 14,
      fontWeight: '500',
      lineHeight: 16,
      letterSpacing: 0.2,
      color: 'textPrimary',
    },
    webSmall: {
      fontFamily: 'Inter_500Medium',
      fontSize: 14,
      fontWeight: '500',
      lineHeight: 16,
      color: 'textSecondary',
    },
    webBodySecondary: {
      fontFamily: 'Inter_400Regular',
      fontSize: 14,
      fontWeight: '400',
      lineHeight: 16,
      letterSpacing: 0.2,
      color: 'textPrimary',
    },
    metadata: {
      fontFamily: 'Inter_400Regular',
      fontSize: 12,
      lineHeight: 16,
      color: 'onSurface',
    },
    metadata1: {
      fontFamily: 'Inter_400Regular',
      fontSize: 14,
      lineHeight: 16,
      color: 'textSecondary',
    },
    metadataSecondary: {
      fontFamily: 'Inter_400Regular',
      fontSize: 10,
      lineHeight: 16,
      color: 'onSurface',
    },
    typoMetadata: {
      fontFamily: 'Inter_400Regular',
      fontWeight: '400',
      fontSize: 11,
      lineHeight: 15,
      color: 'surface',
    },
    error: {
      fontFamily: 'Inter_400Regular',
      fontSize: 12,
      lineHeight: 16,
      color: 'alertRed',
    },
    labelTabHeaderText: {
      letterSpacing: 0,
      fontFamily: 'Inter_500Medium',
      fontSize: 10,
      lineHeight: 16,
      color: 'grey04',
    },
    labelTableBodyText: {
      fontFamily: 'Inter_400Regular',
      fontSize: 14,
      lineHeight: 16,
      color: 'textPrimary',
    },
    labelDueDateText: {
      fontFamily: 'Inter_400Regular',
      fontSize: 14,
      lineHeight: 16,
      color: 'textPrimary',
      textAlign: 'center',
      //marginRight: 'm',
    },
    alertLabel: {
      fontFamily: 'Inter_500Medium',
      fontWeight: '600',
      fontSize: 17,
      lineHeight: 22,
    },
  },
  shadowBoxVariants: {
    defaults: {
      borderColor: 'grey02',
      borderStyle: 'solid',
      backgroundColor: 'white',
      elevation: 5,
      shadowColor:
        Platform.OS === 'android' ? 'androidShadow' : 'buttonBackground',
      shadowOpacity: 0.05,
      shadowOffset: { width: 0, height: 5 },
      shadowRadius: 25,
    },
    base: {
      elevation: 5,
      shadowColor:
        Platform.OS === 'android' ? 'androidShadow' : 'buttonBackground',
      shadowOpacity: 0.05,
      shadowOffset: { width: 0, height: 5 },
      shadowRadius: 25,
    },
    dark: {
      elevation: 5,
      shadowColor:
        Platform.OS === 'android' ? 'androidShadow' : 'buttonBackground',
      shadowOpacity: 0.1,
      shadowOffset: { width: 0, height: 5 },
      shadowRadius: 12,
    },
    thin: {
      elevation: 4,
      shadowColor:
        Platform.OS === 'android' ? 'androidShadow' : 'buttonBackground',
      shadowOpacity: 0.06,
      shadowOffset: { width: 0, height: 4 },
      shadowRadius: 6,
    },
    none: {
      shadowOpacity: 0,
    },
  },
  buttonVariants: {
    defaults: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'grey02',
      padding: 's',
      borderRadius: 'xs',
    },
    primary: {
      backgroundColor: 'black',
      borderColor: 'black',
      borderWidth: 1,
    },
    cancel: {
      backgroundColor: 'white',
      borderColor: 'grey02',
      borderWidth: 1,
    },
    text: {
      backgroundColor: undefined,
      borderWidth: 0,
    },
    textweb: {
      backgroundColor: undefined,
      borderWidth: 0,
    },
    textSecondary: {
      backgroundColor: undefined,
      borderWidth: 0,
    },
    create: {
      borderWidth: 1,
      borderColor: 'grey03',
      backgroundColor: 'grey01',
    },
    edit: {
      borderWidth: 1,
      borderColor: 'grey03',
      backgroundColor: 'grey01',
    },
    editSelected: {
      borderWidth: 1,
      borderColor: 'grey03',
      backgroundColor: 'grey01',
    },
    clearFilter: {
      backgroundColor: 'white',
      color: 'grey04',
    },
    editBold: {
      borderWidth: 1,
      borderColor: 'grey03',
      backgroundColor: 'grey01',
    },
    reversed: {
      borderWidth: 1,
      borderColor: 'black',
      backgroundColor: 'white',
    },
    icon: {
      borderWidth: 0,
      backgroundColor: 'black',
      borderRadius: 'l',
      padding: 's',
    },
    delete: {
      borderWidth: 0,
      backgroundColor: 'grey02',
    },
    logout: {
      borderWidth: 0,
      backgroundColor: 'white',
    },
    webprimary: {
      backgroundColor: 'black',
      borderColor: 'black',
      borderWidth: 1,
    },
  },
  badgeVariants: {
    defaults: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'grey02',
      padding: 'xs',
      borderRadius: 'xs',
    },
    red: {
      backgroundColor: 'alertRedMild',
    },
    yellow: {
      backgroundColor: 'yellowVividMild',
    },
    grey: {
      backgroundColor: 'grey02',
    },
    green: {
      backgroundColor: 'greenSecondary',
    },
    grey01: {
      backgroundColor: 'grey01',
    },
  },
  tagVariants: {
    defaults: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'grey01',
      color: 'black',
      padding: 's',
      borderRadius: 'xs',
    },
    reversed: {
      backgroundColor: 'black',
      color: 'white',
      borderWidth: 0,
    },
    primary: {
      backgroundColor: 'blueMild',
      color: 'blue',
    },
    primaryReversed: {
      backgroundColor: 'blue',
      color: 'white',
    },
    secondary: {
      backgroundColor: 'greenSecondaryMild',
      color: 'greenSecondary',
    },
    filters: {
      backgroundColor: 'greenSecondaryMild',
      color: 'greenDark',
    },
    secondaryReversed: {
      backgroundColor: 'greenSecondary',
      color: 'white',
    },
    tertiary: {
      backgroundColor: 'magentaLightMild',
      color: 'magentaLight',
    },
    tertiaryReversed: {
      backgroundColor: 'magentaLight',
      color: 'white',
    },
    info: {
      backgroundColor: 'purpleMild',
      color: 'purple',
    },
    infoReversed: {
      backgroundColor: 'purple',
      color: 'white',
    },
    infoSecondary: {
      backgroundColor: 'orangeMild',
      color: 'orange',
    },
    infoSecondaryReversed: {
      backgroundColor: 'orange',
      color: 'white',
    },
    badge: {
      backgroundColor: 'white',
      color: 'black',
      borderWidth: 1,
      borderColor: 'grey02',
    },
    picker: {
      backgroundColor: 'grey01',
      color: 'grey05',
    },
    pickerForWeb: {
      backgroundColor: 'greenSecondaryMild',
      color: 'greenDark',
    },
    webFolder: {
      backgroundColor: 'transparent',
      color: 'grey04',
    },
    folder: {
      backgroundColor: 'grey02',
      color: 'grey05',
    },
    folderGreen: {
      backgroundColor: 'greenSecondaryMild',
      color: 'greenSecondary',
    },
    status: {
      backgroundColor: 'grey01',
      color: 'textPrimary',
    },
    textSecondary: {
      backgroundColor: 'grey01',
      color: 'textSecondary',
    },
    filter: {
      backgroundColor: 'grey01',
      color: 'greenSecondary',
    },
    assignedTask: {
      backgroundColor: 'magentaLightMild',
      color: 'pastelMagenta',
    },
    completedTask: {
      backgroundColor: 'greenSecondaryMild',
      color: 'greenDark',
    },
  },
});
// TODO: Add the web text styles

export type Theme = typeof theme;
export default theme;
