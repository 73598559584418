import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';
import { Platform } from 'react-native';

import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import { FilesTeamWiseProjects } from '@components/Files/FilesTeamWiseProjects';
import { Box } from '@components/Restyle';
import AllFiles from '@screens/Files/AllFiles';
import RecentFiles from '@screens/Files/RecentFiles';
import theme from '@themes/theme';

const Files: React.FC = () => {
  const Tab = createMaterialTopTabNavigator();

  return (
    <Box flex={1}>
      <Tab.Navigator
        screenOptions={{ swipeEnabled: Platform.OS !== 'web' }}
        sceneContainerStyle={{
          backgroundColor: 'transparent',
        }}
        tabBar={(props) => (
          <Box
            flexDirection='row'
            alignItems='center'
            paddingHorizontal='m'
            paddingBottom='xs'
            maxHeight={50}>
            <CustomTabBar {...props} spacing={theme.spacing.l} />
          </Box>
        )}
        initialRouteName='recent'
        backBehavior='none'>
        <Tab.Screen
          name='recent-files'
          options={{
            tabBarLabel: 'Recent',
          }}
          initialParams={{ tabBarLabel: 'Recent' }}
          children={() => (
            <Box flex={1}>
              <RecentFiles />
            </Box>
          )}
        />
        <Tab.Screen
          name='all-files'
          options={{
            tabBarLabel: 'All Files',
          }}
          initialParams={{ tabBarLabel: 'All Files' }}
          children={() => (
            <Box flex={1}>
              <AllFiles />
            </Box>
          )}
        />
        <Tab.Screen
          name='team-files'
          options={{
            tabBarLabel: 'Teams',
          }}
          initialParams={{ tabBarLabel: 'Teams' }}
          children={() => (
            <Box flex={1}>
              <FilesTeamWiseProjects />
            </Box>
          )}
        />
      </Tab.Navigator>
    </Box>
  );
};

export default Files;
