import { useNavigation, useRoute } from '@react-navigation/native';
import React, { forwardRef } from 'react';

import { DateSelectPicker } from '@components/FormModals/DateSelect/DateSelectPicker';
import { WebModal } from '@components/Modals/WebModal.web';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';

export const EditTaskDates = forwardRef(() => {
  const route =
    useRoute<TasksStackScreenProps<'task-detail-edit-dates'>['route']>();
  const {
    params: { fieldName },
  } = route || {};

  const navigation = useNavigation();
  return (
    <WebModal visible width={504} height={512} onClose={navigation.goBack}>
      <DateSelectPicker
        onClose={navigation.goBack}
        formField={fieldName}
        submitWhenSelected={true}
      />
    </WebModal>
  );
});
