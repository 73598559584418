import { useNavigation } from '@react-navigation/native';
import { useFormikContext } from 'formik';
import React from 'react';
import { FlatList, StyleSheet, View, TouchableOpacity } from 'react-native';

import { Text, ShadowBox, Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import useNewColors, { ColorType } from '@hooks/useNewColors';

interface ColorPickerProps {
  isBottomSheet?: boolean;
  onSelectFinish?: () => void;
}

const ITEM_HEIGHT = 32;

const ColorPicker: React.FC<ColorPickerProps> = ({
  isBottomSheet,
  onSelectFinish,
}) => {
  const { colors } = useNewColors();
  const { setFieldValue, getFieldProps, touched, setTouched } =
    useFormikContext();
  const { name: fieldName, value } = getFieldProps('color');
  const navigation = useNavigation();
  const getCircleStyles = ({ value }: ColorType) => ({
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: value,
  });

  const renderItem = ({ item }: { item: ColorType }) => {
    const { id: itemId, name }: ColorType = item;
    const circleStyles = getCircleStyles(item);

    return (
      <TouchableOpacity
        onPress={() => {
          setFieldValue(fieldName, itemId);
          setTouched({ ...touched, [fieldName]: true });
          onSelectFinish ? onSelectFinish() : navigation.pop();
        }}>
        <Box flexDirection='row' alignItems='center'>
          <Box flex={1} flexDirection='row' alignItems='center'>
            <View accessibilityLabel={name} style={circleStyles} />
            <Text variant='body' color='black' marginLeft='xs'>
              {name}
            </Text>
          </Box>
          {value == itemId && (
            <Icon name='Check' color='greenSecondary' variant='s' />
          )}
        </Box>
      </TouchableOpacity>
    );
  };

  const getColorList = () => {
    return (
      <Box padding='m' borderRadius='xs'>
        <FlatList
          data={colors}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          getItemLayout={(_data, index) => ({
            length: ITEM_HEIGHT,
            offset: ITEM_HEIGHT * index,
            index,
          })}
          ItemSeparatorComponent={() => <Box marginBottom='m' />}
          ListEmptyComponent={() => {
            return (
              <Text textAlign='center' style={styles.noData}>
                No Data Found
              </Text>
            );
          }}
        />
      </Box>
    );
  };

  if (isBottomSheet) {
    return getColorList();
  }

  return (
    <Box flex={1} justifyContent='center' alignItems='center'>
      <TouchableOpacity
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        onPress={() => {
          navigation.goBack();
        }}
        activeOpacity={1}
      />
      <ShadowBox
        variant='thin'
        borderRadius='xs'
        backgroundColor='white'
        style={{ width: 200 }}>
        {getColorList()}
      </ShadowBox>
    </Box>
  );
};

const styles = StyleSheet.create({
  noData: {
    paddingVertical: 45,
  },
});

export default ColorPicker;
