import { ColorValue } from "react-native";

import { Box, Text } from "@components/Restyle";
import Icon from "@components/shared/Icon/RestyleIcon";
import theme from "@themes/theme";

export const ProjectAvatar = ({
  archived,
  color,
  initials,
  personal,
  dimensions = { width: 48, height: 48 },
  absolute,
}: {
  absolute?: { top?: number; bottom?: number; right?: number; left?: number };
  archived?: boolean;
  color: ColorValue;
  initials: string;
  personal?: boolean;
  dimensions: { width: number; height: number };
}) => (
  <Box
    {...dimensions}
    position={absolute ? "absolute" : undefined}
    {...absolute}
    borderRadius="xs"
    alignItems="center"
    justifyContent="center"
    style={{
      backgroundColor: archived ? theme.colors.grey04 : color,
    }}
  >
    <Text variant="labelLarge" color="white">
      {initials}
    </Text>
    <Box
      position="absolute"
      bottom={0}
      right={0}
      width={14}
      height={14}
      backgroundColor="black"
      borderRadius="xxxs"
      alignItems="center"
      justifyContent="center"
    >
      {personal ? (
        <Icon name="Office" variant="xxxs" color="white" />
      ) : (
        <Icon name="TT" variant="xs" />
      )}
    </Box>
  </Box>
);
