import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/Restyle';
import { Badge } from '@components/shared/Badges/Badge';
import Icon from '@components/shared/Icon/RestyleIcon';
import { Maybe, TaskPriority } from '@graphql/generated';

type Priority = {
  label: string;
  variant: ComponentProps<typeof Badge>['variant'];
};

const useGetPriorityData = (priority?: Maybe<TaskPriority>): Priority => {
  const { t } = useTranslation('models');
  switch (priority) {
    case TaskPriority.High:
      return { label: t('High'), variant: 'red' };
    case TaskPriority.Medium:
      return { label: t('Medium'), variant: 'yellow' };
    case TaskPriority.Low:
    default:
      return { label: t('Low'), variant: 'grey01' };
  }
};

export const TaskPriorityBadge = ({
  priority,
}: {
  priority?: Maybe<TaskPriority>;
}) => {
  const priorityData = useGetPriorityData(priority);
  return (
    <Box marginHorizontal='xxs'>
      {priorityData.label === 'High' && (
        <Icon name='StatusHigh' variant='l' color='textPrimary' />
      )}
      {priorityData.label === 'Medium' && (
        <Icon name='StatusMedium' variant='l' color='textPrimary' />
      )}
    </Box>
  );
};
