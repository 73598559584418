import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MessageParts from '@components/Chat/MessageParts';
import { triggersConfig } from '@utils/mentions';

import Avatar from '../../components/Avatar/Avatar';
import Icon from '../../components/shared/Icon/Icon';
import { User } from '../../graphql/generated';
import { Box, Text } from '../Restyle';

interface ActivityItemProps {
  user: User;
  message: string;
  createdAt: Date;
  index: number;
  children: ReactNode;
  onChatPress?: () => void;
  filterVal?: string;
}

export const ActivityItem: React.FC<ActivityItemProps> = (
  ActivityItemProps
) => {
  const { t } = useTranslation();
  const { user, message, createdAt, index, children, filterVal, onChatPress } =
    ActivityItemProps;

  return (
    <Box flexDirection='row' testID={`ActivityItem-${index}`}>
      <Box alignItems='center'>
        <Box
          backgroundColor='greenSecondary'
          padding='xs'
          borderRadius='xs'></Box>
        <Box
          style={{ marginTop: 6, width: 2 }}
          backgroundColor='grey02'
          flex={1}
        />
      </Box>
      <Box flex={1} marginLeft='xs'>
        <Box flexDirection='row'>
          <Avatar
            id={user?.id}
            avatar={user.avatar}
            label={user.name}
            size='xs'
          />
          <Text
            variant='labelEmphasized'
            color='textPrimary'
            marginLeft='xs'
            style={{ flexGrow: 1 }}>
            {user.firstName}
          </Text>
          {onChatPress && (
            <TouchableOpacity onPress={onChatPress}>
              <Icon name='CornerUpLeft' color='black' variant='s' />
            </TouchableOpacity>
          )}
        </Box>
        <Box flexDirection='row'>
          <Box marginTop='s' flex={1}>
            <MessageParts
              value={message}
              configs={[triggersConfig.mention]}
              styleLinks
              navigateToChatOnMentionPress
              filterVal={filterVal}
            />
          </Box>
          <Text
            marginTop='s'
            marginLeft='xxxs'
            variant='metadata'
            color='textSecondary'>
            {t('format:timeAgoActivityItemWeb', {
              val: createdAt > new Date() ? new Date() : createdAt,
            })}
          </Text>
        </Box>

        <Box marginTop='s'>{children}</Box>
      </Box>
    </Box>
  );
};
