import { Message, useDeleteMessageMutation } from '@graphql/generated';

export const useDeleteMessage = () => {
  const [deleteMessage] = useDeleteMessageMutation({
    refetchQueries: ['listMessages', 'listChats'],
  });

  const deleteMessageById = (message: Message, onComplete?: () => void) => {
    message?.id &&
      deleteMessage({
        variables: { id: message?.id },
        onCompleted: onComplete,
        refetchQueries: ['listChats'],
        update(cache) {
          const cacheIdentifier = cache.identify(message);
          cache.evict({ id: cacheIdentifier });
          cache.gc();
        },
      });
  };

  return { deleteMessageById };
};
