/* eslint-disable no-magic-numbers */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from 'react-native';

import { beginningOfToday } from '@utils/formatters/date';

import theme from '../../../themes/theme';
import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

type DateRangePicker2Props = {
  start?: Date;
  end?: Date;
  onSelect: (start: Date | undefined, end: Date | undefined) => void;
};

const MONTH_NAME = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const GetDays = (year: number, month: number) => {
  const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
    days[1] = 29;
  }
  return days[month];
};

export const DateRangePicker2: React.FC<DateRangePicker2Props> = ({
  start,
  end,
  onSelect,
}) => {
  const date = beginningOfToday();
  const { t } = useTranslation('format');
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());

  const [weeks, setWeeks] = useState<any[]>([]);
  const [currentMonthDay, setCurrentMonthDay] = useState<any[]>([]);

  const [startDate, setStartDate] = useState(start);
  const [untilDate, setUntilDate] = useState(end);

  useEffect(() => {
    const _weeks = getWeek(currentMonthDay);
    setWeeks(_weeks);
  }, [startDate, untilDate]);

  useEffect(() => {
    GetMonthData(year, month);
  }, [year, month]);

  const GetMonthData = (year: number, month: number) => {
    const currentMonthDays = [];

    const days = GetDays(year, month);

    const firstDay = new Date(year, month, 1);

    const week = firstDay.getDay();

    for (let i = 0; i < week; i++) {
      currentMonthDays.push(0);
    }

    for (let i = 1; i <= days; i++) {
      currentMonthDays.push(i);
    }

    const offset = currentMonthDays.length % 7;

    if (offset != 0) {
      for (let i = 0; i < 7 - offset; i++) {
        currentMonthDays.push(0);
      }
    }

    setCurrentMonthDay(currentMonthDays);
    const _weeks = getWeek(currentMonthDays);
    setWeeks(_weeks);
  };

  const onSelectDay = (day: number) => {
    const dateSelected = new Date(year, month, day);
    if (!startDate) {
      setStartDate(dateSelected);
    } else if (!untilDate) {
      if (dateSelected >= startDate) {
        setUntilDate(dateSelected);
      } else {
        setStartDate(dateSelected);
      }
    } else {
      setStartDate(dateSelected);
      setUntilDate(undefined);
    }
    // onSelect(startDate, untilDate);
  };

  const monthPre = () => {
    if (month == 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const monthNext = () => {
    if (month == 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const setDayStyle = (year: number, month: number, day: number) => {
    const thisDate = new Date(year, month, day);
    // const currentDate = new Date(currentYear, currentMonth, currentDay);

    let style = undefined;

    if (
      dayjs(thisDate).format('DD/MM/YYY') ===
      dayjs(new Date()).format('DD/MM/YYY')
    ) {
      style = styles.dayToday;
    }

    if (
      (startDate &&
        dayjs(thisDate).format('DD/MM/YYY') ===
          dayjs(startDate).format('DD/MM/YYY')) ||
      (untilDate &&
        dayjs(thisDate).format('DD/MM/YYY') ===
          dayjs(untilDate).format('DD/MM/YYY'))
    ) {
      if (
        startDate &&
        untilDate &&
        dayjs(untilDate).format('DD/MM/YYY') !==
          dayjs(startDate).format('DD/MM/YYY')
      ) {
        if (
          dayjs(thisDate).format('DD/MM/YYY') ===
          dayjs(startDate).format('DD/MM/YYY')
        ) {
          style = styles.daySelectStart;
        } else {
          style = styles.daySelectEnd;
        }
      } else {
        style = styles.daySelect;
      }
    }
    if (
      startDate &&
      untilDate &&
      startDate < thisDate &&
      untilDate > thisDate
    ) {
      style = styles.dayInSelection;
    }

    if (day < 1) {
      style = styles.dayViewItem;
    }

    return style;
  };

  const getWeek = (days: number[]) => {
    const theFirstDay = new Date(year, month, 1);
    const theLastDay = new Date(year, month, GetDays(year, month));
    const _weeks = [];
    let week = [];

    for (let i = 1; i <= days.length; i++) {
      const dayStyle = setDayStyle(year, month, days[i - 1]);
      week.push(
        <TouchableOpacity
          onPress={() => {
            onSelectDay(days[i - 1]);
          }}
          key={year + month + 'touch' + i}>
          <Box
            key={year + month + 'index' + i}
            alignItems='center'
            marginHorizontal={Platform.OS !== 'web' ? 'xxs' : 's'}
            justifyContent='center'>
            <Text variant='metadata' style={[dayStyle, styles.dayViewItem]}>
              {days[i - 1] > 0 ? days[i - 1] : ' '}
            </Text>
            {days[i - 1] == 0 &&
              i < 7 &&
              startDate &&
              untilDate &&
              theFirstDay > startDate &&
              theFirstDay <= untilDate && (
                <Box
                  style={
                    i == 1
                      ? styles.selectedDayContainer2RowStart
                      : styles.selectedDayContainer2
                  }></Box>
              )}
            {days[i - 1] == 0 &&
              i > 7 &&
              startDate &&
              untilDate &&
              theLastDay >= startDate &&
              theLastDay < untilDate && (
                <Box
                  style={
                    i == days.length
                      ? styles.selectedDayContainer2RowEnd
                      : styles.selectedDayContainer2
                  }></Box>
              )}
            {(dayStyle === styles.daySelect ||
              dayStyle === styles.dayToday) && (
              <Box style={styles.selectedDayContainer}>
                {dayStyle === styles.daySelect && (
                  <Box style={styles.selectedDay} />
                )}
                {dayStyle === styles.dayToday && (
                  <Box style={styles.dayToday} />
                )}
              </Box>
            )}

            {dayStyle === styles.daySelectStart &&
              (i % 7 == 0 ? (
                <Box style={styles.selectedDayContainerStartWhenInRowEnd}>
                  <Box style={styles.selectedDayStart} />
                </Box>
              ) : (
                <Box style={styles.selectedDayContainerStart}>
                  <Box style={styles.selectedDayStart} />
                </Box>
              ))}
            {dayStyle === styles.daySelectEnd &&
              (i - 1 > 0 && (i - 1) % 7 == 0 ? (
                <Box style={styles.selectedDayContainerEndWhenInRowStart}>
                  <Box style={styles.selectedDayEndWhenInRowStart} />
                </Box>
              ) : (
                <Box style={styles.selectedDayContainerEnd}>
                  <Box style={styles.selectedDayEnd} />
                </Box>
              ))}
            {dayStyle === styles.dayInSelection &&
              (i % 7 == 0 ? (
                <Box style={styles.selectedDayContainer2RowEnd}></Box>
              ) : (
                <Box
                  style={
                    i - 1 > 0 && (i - 1) % 7 == 0
                      ? styles.selectedDayContainer2RowStart
                      : styles.selectedDayContainer2
                  }></Box>
              ))}
          </Box>
        </TouchableOpacity>
      );

      if (i % 7 == 0) {
        _weeks.push(
          <Box
            px='m'
            backgroundColor='white'
            key={year + month + 'week' + i}
            flex={1}
            flexDirection='row'
            paddingTop='xs'
            justifyContent='space-between'>
            {week}
          </Box>
        );
        week = [];
      }
    }
    return _weeks;
  };

  return (
    <Box flex={Platform.OS === 'web' ? 1 : 0.7}>
      <Box
        px='m'
        height={72}
        marginTop='m'
        justifyContent='flex-start'
        flexDirection='row'
        alignItems='flex-start'>
        <Box flex={1} mr='m'>
          <Text variant='labelSmall' color='textPrimary' numberOfLines={1}>
            {t('models:projects.taskList.dateRangeStart')}
          </Text>
          <Box
            flex={1}
            mt='xs'
            backgroundColor='white'
            borderColor='grey02'
            style={{ borderWidth: 1 }}
            borderRadius='xs'
            flexDirection='row'
            alignItems='center'
            accessibilityLabel={t('models:projects.taskList.dateRangeStart')}>
            <TextInput
              editable={false}
              style={[
                styles.input,
                theme.textVariants.webBodySecondary,
                { height: 40 },
              ]}
              value={startDate ? dayjs(startDate).format('MM / DD / YYYY') : ''}
              autoCapitalize='none'
            />
          </Box>
        </Box>
        <Box flex={1}>
          <Text variant='labelSmall' color='textPrimary' numberOfLines={1}>
            {t('models:projects.taskList.dateRangeEnd')}
          </Text>
          <Box
            flex={1}
            mt='xs'
            backgroundColor='white'
            borderColor='grey02'
            style={{ borderWidth: 1 }}
            borderRadius='xs'
            flexDirection='row'
            alignItems='center'
            accessibilityLabel={t('models:projects.taskList.dateRangeEnd')}>
            <TextInput
              editable={false}
              style={[
                styles.input,
                theme.textVariants.webBodySecondary,
                { height: 40 },
              ]}
              value={untilDate ? dayjs(untilDate).format('MM / DD / YYYY') : ''}
              autoCapitalize='none'
            />
          </Box>
        </Box>
      </Box>
      <Box
        px='m'
        flexDirection='row'
        flexWrap='wrap'
        justifyContent='space-between'
        marginBottom='s'
        alignItems='flex-start'>
        <Box alignItems='center' flexDirection='row' flex={1} height={40}>
          <Icon
            onPress={monthPre}
            name='ChevronLeft'
            variant='l'
            color='textPrimary'
          />

          <Box alignItems='center' flex={1}>
            <Text textAlign='center' variant='bodyTaskName'>
              {MONTH_NAME[month]} {year}
            </Text>
          </Box>

          <Icon
            onPress={monthNext}
            name='ChevronRight'
            variant='l'
            color='textPrimary'
          />
        </Box>
      </Box>
      <Box
        flexDirection='row'
        style={{
          shadowColor: 'black',
          shadowOpacity: 0.06,
          shadowRadius: 6,
          elevation: 1,
          shadowOffset: { width: 0, height: 4 },
          backgroundColor: theme.colors.white,
          height: 32,
          marginBottom: 18,
        }}
        px='m'
        justifyContent='space-around'>
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((i, index) => (
          <Text
            key={index}
            variant='webBodySecondary'
            textAlign='center'
            marginTop='xs'>
            {i}
          </Text>
        ))}
      </Box>

      {weeks}

      <Box
        flexDirection='row'
        justifyContent='flex-end'
        alignItems='center'
        mt='s'
        height={64}
        left={0}
        right={0}
        marginHorizontal='m'>
        <Box flex={1}></Box>
        <TouchableOpacity
          accessibilityLabel={t('shared:clear')}
          onPress={() => {
            setStartDate(undefined);
            setUntilDate(undefined);
          }}>
          <Box height={32} alignItems='center' justifyContent='center'>
            <Text color='textPrimary' variant='labelSmall'>
              {t('shared:clear')}
            </Text>
          </Box>
        </TouchableOpacity>

        <TouchableOpacity
          accessibilityLabel={t('shared:confirm')}
          onPress={() => {
            onSelect(startDate, untilDate);
          }}>
          <Box
            ml='l'
            height={32}
            width={102}
            flexDirection='row'
            borderWidth={1}
            px='m'
            py='xs'
            backgroundColor='black'
            borderRadius='xs'
            alignItems='center'
            justifyContent='center'>
            <Box
              alignItems='center'
              justifyContent='center'
              style={{ flex: 1 }}>
              <Text variant='labelSmall' color='white'>
                {t('shared:confirm')}
              </Text>
            </Box>
          </Box>
        </TouchableOpacity>
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  input: {
    marginLeft: theme.spacing.xs,
    flex: 1,
    ...Platform.select({ web: { outlineStyle: 'none' } }),
  },
  dayViewItem: {
    width: 40,
    height: 40,
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    paddingVertical: 10,
  },
  daySelect: {
    color: theme.colors.white,
  },
  daySelectStart: {
    color: theme.colors.white,
    zIndex: 100,
  },
  daySelectEnd: {
    color: theme.colors.white,
    zIndex: 101,
  },
  dayInSelection: {
    backgroundColor: theme.colors.grey02,
    width: 40,
    height: 40,
    alignSelf: 'center',
    color: theme.colors.textPrimary,
  },
  dayNotSelect: {
    color: theme.colors.grey04,
  },
  dayContainer: {
    shadowColor: 'black',
    marginHorizontal: Platform.OS === 'ios' ? -4 : -2,
    shadowOpacity: 0.06,
    shadowOffset: { width: 0, height: 12 },
    shadowRadius: 12,
    backgroundColor: 'white',
  },
  selectedDayContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'white',
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
  },
  selectedDayContainerStart: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 40,
    backgroundColor: theme.colors.grey02,
    left: 20,
    zIndex: -1,
  },
  selectedDayContainerStartWhenInRowEnd: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 32.5,
    backgroundColor: theme.colors.grey02,
    left: 20,
    zIndex: -1,
  },
  selectedDayContainerEnd: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 30,
    backgroundColor: theme.colors.grey02,
    left: -10,
    zIndex: -1,
  },
  selectedDayContainerEndWhenInRowStart: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 31.5,
    backgroundColor: theme.colors.grey02,
    left: -11.5,
    zIndex: -1,
  },
  selectedDayContainer2: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 95,
    backgroundColor: theme.colors.grey02,
    left: -27.5,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
  },
  selectedDayContainer2RowEnd: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 80,
    backgroundColor: theme.colors.grey02,
    left: -27.5,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
  },
  selectedDayContainer2RowStart: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 80,
    backgroundColor: theme.colors.grey02,
    left: -11.5,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
  },
  dayToday: {
    backgroundColor: theme.colors.white,
    width: 40,
    height: 40,
    alignSelf: 'center',
    borderRadius: 20,
    borderColor: theme.colors.grey05,
    borderWidth: 1,
  },
  selectedDay: {
    backgroundColor: theme.colors.black,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  selectedDayStart: {
    position: 'absolute',
    top: 0,
    left: -20,
    backgroundColor: theme.colors.black,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  selectedDayEnd: {
    position: 'absolute',
    top: 0,
    left: 10,
    backgroundColor: theme.colors.black,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  selectedDayEndWhenInRowStart: {
    position: 'absolute',
    top: 0,
    left: 11.5,
    backgroundColor: theme.colors.black,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
});
