import { WatchQueryFetchPolicy } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  Modal,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';

import { DateRange } from '@components/DateRangePicker/DateRangePickerModal';
import { ContactType } from '@components/Invite/Contact';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { TaskListNewTable } from '@components/TaskList/TaskListNewTable';
import { TaskSection } from '@components/TaskList/TaskListTable';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { TasksFilterBar } from '@components/Web/TasksFilterBar';
import {
  Scalars,
  TaskGroup,
  TaskPriority,
  TaskSortOption,
  TaskStatus,
  User,
} from '@graphql/generated';
import { useListTasksFromQuery } from '@hooks/useListTasksFromQuery';
import { useProjectFromQuery } from '@hooks/useProjectFromQuery';
import { useProjectMembership } from '@hooks/useProjectMembership';
import theme from '@themes/theme';
import { convertUsersToContacts } from '@utils/convertUsersToContacts';
import { beginningOfDayUTC } from '@utils/formatters/date';

import type { ProjectDetailTabsScreenProps } from '@navigation/projects/project-detail-tabs.web';

const PER_PAGE_COUNT = 50;

type TaskSearchParam = {
  fetchPolicy: WatchQueryFetchPolicy;
  skip: boolean;
  projectIds?: string[];
  priority?: TaskPriority[];
  dueDateBegin?: Scalars['ISO8601DateTime'];
  dueDateEnd?: Scalars['ISO8601DateTime'];
  memberIds?: string[];
};

export const ProjectTasks: React.FC = () => {
  const {
    params: { projectId },
  } = useRoute<ProjectDetailTabsScreenProps<'tasks'>['route']>();

  const { t } = useTranslation('models');
  const { project } = useProjectFromQuery({
    projectId,
  });
  const { isMemberOrOwner } = useProjectMembership(project);

  const isTeamOwnerOrAdmin =
    project?.currentUserTeamRole === 'OWNER' ||
    project?.currentUserTeamRole === 'ADMIN';

  const nonMember = !(isMemberOrOwner || isTeamOwnerOrAdmin);

  const [isMaximize] = useState(false);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [selectedAssignees, setSelectedAssignees] = useState<ContactType[]>([]);
  const [selectedDueDateRange, setSelectedDueDateRange] = useState<DateRange>({
    start: undefined,
    end: undefined,
  });
  const [selectedPriorities, setSelectedPriorities] = useState<TaskPriority[]>(
    []
  );

  const param: TaskSearchParam = {
    fetchPolicy: 'network-only',
    skip: !projectId,
    projectIds: [projectId],
    priority:
      selectedPriorities.length > 0 ? [...selectedPriorities] : undefined,
    dueDateBegin: selectedDueDateRange.start
      ? beginningOfDayUTC(
          dayjs(selectedDueDateRange.start).toDate()
        ).toISOString()
      : undefined,
    dueDateEnd: selectedDueDateRange.end
      ? beginningOfDayUTC(
          dayjs(selectedDueDateRange.end).toDate()
        ).toISOString()
      : undefined,
    memberIds:
      selectedAssignees.length > 0
        ? selectedAssignees.map((t) => t.id)
        : undefined,
  };

  const { groupedCurrentTasks } = useGetCurrentTasks(param);

  const { groupedUpcomingTasks } = useGetUpcomingTasks(param);

  const { groupedPastTasks } = useGetPastTasks(param);

  const { groupedCompletedTasks } = useGetCompletedTasks(param);

  const [initialAssignees, setInitialAssignees] = useState<User[]>([]);

  useEffect(() => {
    const initialMembers: User[] = [...initialAssignees];
    const allTasks = [
      ...groupedCurrentTasks.sectionData.data,
      ...groupedPastTasks.sectionData.data,
      ...groupedUpcomingTasks.sectionData.data,
      ...groupedCompletedTasks.sectionData.data,
    ];
    for (const task of allTasks) {
      for (const tm of task.members) {
        if (!initialMembers.some((ta) => ta.id === tm.user.id)) {
          initialMembers.push(tm.user);
        }
      }
    }
    setInitialAssignees(initialMembers);
  }, [
    groupedCurrentTasks,
    groupedPastTasks,
    groupedUpcomingTasks,
    groupedCompletedTasks,
  ]);

  const { setIsTaskWebPanelOpen, setIdTaskDetailOpen, setMainPanelWidth } =
    useWebDrawer();

  if (nonMember)
    return (
      <Box
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
        marginTop='l'>
        <Icon
          name='AlertTriangle'
          color='textPrimary'
          variant='l'
          marginRight='xs'
        />
        <Text color='textPrimary' variant='bodySecondary'>
          {t('models:projects.nonMember')}
        </Text>
      </Box>
    );

  return (
    <>
      <Modal animationType='fade' transparent={true} visible={modalOpen}>
        <TouchableWithoutFeedback onPress={() => setModalOpen(!modalOpen)}>
          <Box style={styles.modal}></Box>
        </TouchableWithoutFeedback>
      </Modal>
      <Box
        onLayout={(e) =>
          setMainPanelWidth(
            e.nativeEvent.layout.width + theme.spacing.xs + theme.spacing.m
          )
        }
        flex={1}
        flexDirection='row'
        justifyContent='space-between'
        ml='xs'
        mr='m'>
        {!isMaximize && (
          <Box flex={1} mt='xs'>
            <TasksFilterBar
              initialAssignees={convertUsersToContacts(initialAssignees)}
              selectedAssignees={selectedAssignees}
              setSelectedAssignees={setSelectedAssignees}
              selectedPriorities={selectedPriorities}
              setSelectedPriorities={setSelectedPriorities}
              selectedDueDateRange={selectedDueDateRange}
              setSelectedDueDateRange={setSelectedDueDateRange}
            />
            <TaskListNewTable
              key={projectId}
              tasks={[
                groupedCurrentTasks,
                groupedPastTasks,
                groupedUpcomingTasks,
                groupedCompletedTasks,
              ]}
              onPress={(task) => {
                setIdTaskDetailOpen(task.id);
                setIsTaskWebPanelOpen(true);
              }}
              isHeaderTabsShow={true}
              isArrowVisible={true}
              isFromMyTask={true}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    height: Dimensions.get('window').height,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  dependencyBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    justifyContent: 'center',
  },
  popupBox: {
    position: 'absolute',
    right: theme.spacing.m,
    zIndex: 99,
  },
  topShadow: {
    backgroundColor: 'white',
    shadowColor: 'black',
    shadowOpacity: 0.06,
    shadowOffset: { width: 0, height: 25 },
    shadowRadius: 25,
    zIndex: 99,
  },
});

const useGetCurrentTasks = (param: TaskSearchParam) => {
  const { tasks, fetchFromCursor, pageInfo } = useListTasksFromQuery({
    first: PER_PAGE_COUNT,
    sortBy: TaskSortOption.DueDateDesc,
    ...param,
    dueGroup: TaskGroup.Today,
    excludeCompleted: true,
  });

  const groupedCurrentTasks: TaskSection = {
    sectionData: {
      sectionHeader: {
        id: TaskGroup.Today,
        name: TaskGroup.Today,
        count: tasks.length,
      },
      data: tasks,
      hasNextPage: pageInfo?.hasNextPage,
    },
    loadMore: fetchFromCursor,
  };

  return { groupedCurrentTasks };
};

const useGetUpcomingTasks = (param: TaskSearchParam) => {
  const { tasks, fetchFromCursor, pageInfo } = useListTasksFromQuery({
    first: PER_PAGE_COUNT,
    sortBy: TaskSortOption.DueDateDesc,
    ...param,
    dueGroup: TaskGroup.Upcoming,
    excludeCompleted: true,
  });

  const groupedUpcomingTasks: TaskSection = {
    sectionData: {
      sectionHeader: {
        id: TaskGroup.Upcoming,
        name: TaskGroup.Upcoming,
        count: tasks.length,
      },
      data: tasks,
      hasNextPage: pageInfo?.hasNextPage,
    },
    loadMore: fetchFromCursor,
  };
  return { groupedUpcomingTasks };
};

const useGetPastTasks = (param: TaskSearchParam) => {
  const { tasks, fetchFromCursor, pageInfo } = useListTasksFromQuery({
    first: PER_PAGE_COUNT,
    sortBy: TaskSortOption.DueDateDesc,
    ...param,
    dueGroup: TaskGroup.Overdue,
    excludeCompleted: true,
  });

  const groupedPastTasks: TaskSection = {
    sectionData: {
      sectionHeader: {
        id: TaskGroup.Overdue,
        name: TaskGroup.Overdue,
        count: tasks.length,
      },
      data: tasks,
      hasNextPage: pageInfo?.hasNextPage,
    },
    loadMore: fetchFromCursor,
  };

  return { groupedPastTasks, fetchMoreCurrentTasks: fetchFromCursor };
};

const useGetCompletedTasks = (param: TaskSearchParam) => {
  const { tasks, fetchFromCursor, pageInfo } = useListTasksFromQuery({
    first: PER_PAGE_COUNT,
    sortBy: TaskSortOption.DueDateDesc,
    ...param,
    // collaboratorsOnly: true,
    status: TaskStatus.Completed,
  });

  const groupedCompletedTasks: TaskSection = {
    sectionData: {
      sectionHeader: {
        id: 'COMPLETED',
        name: 'COMPLETED',
        count: tasks.length,
      },
      data: tasks,
      hasNextPage: pageInfo?.hasNextPage,
    },
    loadMore: fetchFromCursor,
  };
  return { groupedCompletedTasks };
};
