import React, { ComponentProps } from 'react';

import { ChatDrawerScreen } from '@components/Web/Drawer/WebDrawerContext';
import { PopupIcon } from '@components/Web/PopupIcon';
import { Chat, useGetChatQuery } from '@graphql/generated';
import { useAppNavigation } from '@navigation/useAppNavigation';
import theme from '@themes/theme';

type Props = Pick<ComponentProps<typeof PopupIcon>, 'backgroundColor'> & {
  chatId: Chat['id'];
};

const ChatAttachments = (props: Props) => {
  const { navigateToChatDrawer } = useAppNavigation();
  const { chatId } = props;

  const { data } = useGetChatQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: chatId,
    },
    skip: !chatId,
  });

  const { getChat: chat } = data || { getChat: undefined };

  const menuList: ComponentProps<typeof PopupIcon>['popupProps']['menuList'] = [
    {
      name: 'Assign Task',
      iconName: 'Clipboard',
      onSelect: () =>
        chat &&
        navigateToChatDrawer({
          screen: ChatDrawerScreen.assignTaskModal,
          activeChat: chat,
        }),
      numberOfLines: 1,
      isComingSoon: true,
    },
    {
      name: 'Complete Task',
      iconName: 'Check',
      onSelect: () =>
        chat &&
        navigateToChatDrawer({
          screen: ChatDrawerScreen.completeTaskModal,
          activeChat: chat,
        }),
      numberOfLines: 1,
      isComingSoon: true,
    },
  ];

  return (
    <PopupIcon
      enableHoverEffect={false}
      {...props}
      name='Plus'
      variant='m'
      color='textPrimary'
      popupProps={{
        menuList,
        position: ['right', 'top'],
        offset: [0, theme.spacing.m],
      }}
    />
  );
};

export default ChatAttachments;
