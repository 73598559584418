"use strict";
import React, { memo } from "react";
import {
  Platform,
  TouchableWithoutFeedback,
  useWindowDimensions,
} from "react-native";

import { dayJsMod } from "./helper";
import theme from "../../../themes/theme";
import { Box, Text } from "../Restyle";

const areEqual = (prevProps, nextProps) => {
  if (nextProps.day.type != prevProps.day.type) {
    return false;
  }

  if (nextProps.onSelectDate != prevProps.onSelectDate) {
    return false;
  }

  return true;
};

export type DayPropsType = {
  day: {
    date: string;
    type: string;
  };
  flag: boolean;
  dayProps: {
    dayBackgroundColor: string;
    dayTextColor: string;
    pointBackgroundColor: string;
    pointTextColor: string;
    selectedBackgroundColor: string;
    betweenBackgroundColor: string;
    selectedTextColor: string;
    dayContainerOffset: number;
  };
};

const Day = memo((props: DayPropsType) => {
  const dimensions = useWindowDimensions();

  const DEVICE_WIDTH = Platform.OS !== "web" ? dimensions.width : 375;
  const { day, dayProps, flag } = props;

  const {
    dayBackgroundColor,
    dayTextColor,
    pointBackgroundColor,
    pointTextColor,
    selectedBackgroundColor,
    betweenBackgroundColor,
    selectedTextColor,
    dayContainerOffset = 8,
  } = dayProps;

  const side = Math.floor((DEVICE_WIDTH - 32) / 7) + 6;
  const dayContainerStyle = {
    width: side,
    height: 40,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  };

  const beforeDayStyle = {
    flex: 1,
    height: 40,
    backgroundColor: "transparent",
  };

  const dayWrapperStyle = {
    width: 40,
    height: 40,
    flexDirection: "row",
    justifyContent: "center",
  };

  const dayStyle = {
    width: 40,
    height: 40,
    // flex: 0,
    backgroundColor: dayBackgroundColor || "transparent",
    position: "relative",
    borderRadius: side - dayContainerOffset,
  };
  const afterDayStyle = {
    flex: 1,
    height: 40,
    backgroundColor: "transparent",
  };

  const textDayStyle = { color: dayTextColor || "black" };

  switch (day.type) {
    case "single":
      dayStyle.backgroundColor =
        pointBackgroundColor || selectedBackgroundColor;
      textDayStyle.color = pointTextColor || selectedTextColor;
      break;
    case "first":
      dayStyle.backgroundColor =
        pointBackgroundColor || selectedBackgroundColor;
      textDayStyle.color = pointTextColor || selectedTextColor;
      dayWrapperStyle.backgroundColor = betweenBackgroundColor;
      dayWrapperStyle.borderBottomLeftRadius = 40;
      dayWrapperStyle.borderTopLeftRadius = 40;
      afterDayStyle.backgroundColor = betweenBackgroundColor;
      break;
    case "last":
      dayStyle.backgroundColor =
        pointBackgroundColor || selectedBackgroundColor;
      textDayStyle.color = pointTextColor || selectedTextColor;
      dayWrapperStyle.backgroundColor = betweenBackgroundColor;
      dayWrapperStyle.borderBottomRightRadius = 40;
      dayWrapperStyle.borderTopRightRadius = 40;
      beforeDayStyle.backgroundColor = betweenBackgroundColor;
      break;
    case "between":
      // dayStyle.backgroundColor = betweenBackgroundColor;
      textDayStyle.color = "#000000";
      dayContainerStyle.backgroundColor = betweenBackgroundColor;
      break;
    case "disabled":
    case "blockout":
      textDayStyle.color = "#ccc";
      break;

    default:
      break;
  }

  if (day.date) {
    if (day.type == "today" && flag) {
      return (
        <TouchableWithoutFeedback
          activeOpacity={1}
          onPress={() => props.onSelectDate(dayJsMod(day.date, "YYYYMMDD", ""))}
        >
          <Box style={dayContainerStyle}>
            <Box style={beforeDayStyle}></Box>
            <Box style={dayWrapperStyle}>
              <Box
                style={{
                  ...dayStyle,
                  justifyContent: "center",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: theme.colors.onBackground,
                }}
              >
                <Text
                  variant="body"
                  style={{
                    color: theme.colors.onBackground,
                    textAlign: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  {dayJsMod(day.date, "YYYYMMDD", "").date()}
                </Text>
              </Box>
            </Box>
            <Box style={afterDayStyle}></Box>
          </Box>
        </TouchableWithoutFeedback>
      );
    } else if (day.type == "today" && !flag) {
      return (
        <TouchableWithoutFeedback
          activeOpacity={1}
          onPress={() => props.onSelectDate(dayJsMod(day.date, "YYYYMMDD", ""))}
        >
          <Box style={dayContainerStyle}>
            <Box style={beforeDayStyle}></Box>
            <Box
              style={{
                ...dayWrapperStyle,
                backgroundColor: theme.colors.onBackground,
                borderRadius: 40,
              }}
            >
              <Box
                style={{
                  justifyContent: "center",
                }}
              >
                <Text
                  variant="body"
                  style={{
                    color: theme.colors.background,
                    textAlign: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  {dayJsMod(day.date, "YYYYMMDD", "").date()}
                </Text>
              </Box>
            </Box>
            <Box style={afterDayStyle}></Box>
          </Box>
        </TouchableWithoutFeedback>
      );
    } else {
      if (day.type == "disabled")
        return (
          <TouchableWithoutFeedback activeOpacity={1}>
            <Box style={{ dayWrapperStyle }}>
              <Box style={{ ...dayStyle, justifyContent: "center" }}>
                <Text
                  variant="body"
                  style={{
                    ...textDayStyle,
                    textAlign: "center",
                    backgroundColor: "transparent",
                    //fontSize: Math.floor(DEVICE_WIDTH / 26),
                  }}
                >
                  {dayJsMod(day.date, "YYYYMMDD", "").date()}
                </Text>
                {day.date == dayJsMod(null, null, null).format("YYYYMMDD") ? (
                  <Box
                    style={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      justifyContent: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: "400",
                        color: "#ccc",
                        textAlign: "center",
                      }}
                    >
                      __
                    </Text>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </TouchableWithoutFeedback>
        );
      else if (day.type == "blockout") {
        const strikeTop = Math.floor(DEVICE_WIDTH / -22);
        return (
          <TouchableWithoutFeedback activeOpacity={1}>
            <Box style={{ dayContainerStyle, backgroundColor: "blue" }}>
              <Box style={dayWrapperStyle}>
                <Box style={{ ...dayStyle, justifyContent: "center" }}>
                  <Text
                    variant="labelEmphasized"
                    style={{
                      ...textDayStyle,
                      textAlign: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    {dayJsMod(day.date, "YYYYMMDD", "").date()}
                  </Text>
                  <Box
                    style={{
                      position: "absolute",
                      top: strikeTop,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      justifyContent: "center",
                      backgroundColor: "transparent",
                    }}
                  ></Box>
                </Box>
              </Box>
            </Box>
          </TouchableWithoutFeedback>
        );
      } else
        return (
          <TouchableWithoutFeedback
            activeOpacity={1}
            onPress={() =>
              props.onSelectDate(dayJsMod(day.date, "YYYYMMDD", ""))
            }
          >
            <Box style={dayContainerStyle}>
              <Box style={beforeDayStyle}></Box>
              <Box style={dayWrapperStyle}>
                <Box style={{ ...dayStyle, justifyContent: "center" }}>
                  <Text
                    variant="body"
                    style={{
                      ...textDayStyle,
                      textAlign: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    {dayJsMod(day.date, "YYYYMMDD", "").date()}
                  </Text>
                </Box>
              </Box>
              <Box style={afterDayStyle}></Box>
            </Box>
          </TouchableWithoutFeedback>
        );
    }
  } else
    return (
      <TouchableWithoutFeedback activeOpacity={1}>
        <Box style={dayContainerStyle}>
          <Box
            style={{
              ...dayStyle,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              variant="body"
              style={{
                ...textDayStyle,
                textAlign: "center",
                backgroundColor: "transparent",
              }}
            >
              {null}
            </Text>
          </Box>
        </Box>
      </TouchableWithoutFeedback>
    );
}, areEqual);

export default Day;
