import { Audio } from 'expo-av';
import { RecordingStatus } from 'expo-av/build/Audio';
import * as FileSystem from 'expo-file-system';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, Animated, TouchableOpacity } from 'react-native';
import uuid from 'react-native-uuid';

import { Box, Text } from '@components/Restyle/index';
import Icon from '@components/shared/Icon/Icon';
import { Chat, LocalFile } from '@graphql/generated';
import { MAX_RECORDING_SECONDS } from '@hooks/useChatInput';

import { formatToMMSS } from '../../utils/voiceUtils';

enum FadeDirection {
  In = 1,
  Out = 0,
}

export const VoiceRecord = ({
  setShowRecording,
  voiceFileHandler,
}: {
  setShowRecording: (bool: boolean) => void;
  chatId: Chat['id'];
  voiceFileHandler: (voice: LocalFile) => void;
}) => {
  const { t } = useTranslation();
  const [recording, setRecording] = useState<Audio.Recording>();
  const [recordTime, setRecordTime] = useState(0);

  const fadeAnim = useRef(new Animated.Value(1)).current;

  const fade = (direction: FadeDirection) => {
    // Will change fadeAnim value to 1 in 1 second
    Animated.timing(fadeAnim, {
      toValue: direction,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  };

  const statusUpdate = (d: RecordingStatus) => {
    if (!d.isRecording) return;

    const { durationMillis } = d;
    const seconds = Math.floor(durationMillis / 1000);

    setRecordTime(seconds);

    if (seconds % 2 === 0) {
      fade(FadeDirection.Out);
    } else {
      fade(FadeDirection.In);
    }
  };

  const startRecording = async () => {
    try {
      if (recording) {
        await stopRecording(true);
      }
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });
      const { recording: recordingObject } = await Audio.Recording.createAsync(
        Audio.RecordingOptionsPresets.HIGH_QUALITY
      );
      setRecording(recordingObject);

      recordingObject.setOnRecordingStatusUpdate(statusUpdate);
    } catch (err) {
      console.error('Failed to start recording', err);
    }
  };

  const getVoiceInfo = async (url: string): Promise<LocalFile | undefined> => {
    const clientId = uuid.v4().toString();
    let size = 0;
    const contentType = Platform.OS === 'web' ? 'audio/webm' : 'audio/m4a';

    if (Platform.OS !== 'web') {
      const info = await FileSystem.getInfoAsync(url);
      size = info.size ? info.size : 0;
    }

    return {
      __typename: 'LocalFile',
      id: clientId,
      url,
      name: new Date().toISOString(),
      size,
      contentType,
      clientId,
      isImage: false,
      cdnBaseUrl: '',
      path: '',
      isAudio: true,
      duration: recordTime,
    };
  };

  const stopRecording = async (save: boolean) => {
    try {
      await recording?.stopAndUnloadAsync();

      // must set allowsRecordingIOS back to false in order for speaker playback
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
      });

      if (save) {
        const uri = recording?.getURI();
        const voiceFile = await getVoiceInfo(uri || '');
        if (voiceFile) {
          voiceFileHandler(voiceFile);
        }
      }

      setRecording(undefined);
      setRecordTime(0);
      setShowRecording(false);
    } catch (err) {
      console.error('Failed to stop recording', err);
    }
  };

  useEffect(() => {
    if (recordTime >= MAX_RECORDING_SECONDS) {
      stopRecording(true);
    }
  }, [recordTime]);
  return (
    <Box
      flexDirection='row'
      alignItems='center'
      marginTop='m'
      marginBottom='m'
      marginHorizontal='l'
      justifyContent='space-between'>
      <Box alignItems='center' flexDirection='row'>
        {recording && (
          <Animated.View style={{ opacity: fadeAnim }}>
            <Icon
              name='RecordingCircle'
              width={8}
              height={8}
              marginRight='xs'
            />
          </Animated.View>
        )}
        <TouchableOpacity onPress={() => setShowRecording(false)}>
          <Text variant='body1' color='greenSecondary'>
            {recording
              ? formatToMMSS(recordTime)
              : t('models:chat.input.cancel')}
          </Text>
        </TouchableOpacity>
      </Box>
      {recording && (
        <Box
          style={{
            // absolute so that record time changing does not affect position
            ...StyleSheet.absoluteFillObject,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text variant='body1' color='grey04' textAlign='center'>
            {t('models:chat.input.maxRecordingTime')}
          </Text>
        </Box>
      )}
      <Icon
        name='Recording'
        width={54}
        height={54}
        color='black'
        onPressIn={startRecording}
        onPressOut={() => recording && stopRecording(false)}
        onPress={() => recording && stopRecording(true)}
        delayPressOut={500}
      />
    </Box>
  );
};
