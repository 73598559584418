import * as Updates from 'expo-updates';

type ApiConfigType = {
  webSocketUrl: string;
  apiUrl: string;
};

export const getApiConfig = (): ApiConfigType => {
  if (process.env.PROD_APIS === 'true' || Updates.channel === 'production') {
    const config = {
      webSocketUrl: process.env.PROD_GRAPHQL_WS_ENDPOINT,
      apiUrl: process.env.PROD_GRAPHQL_ENDPOINT,
    };

    console.info('====  API_ENDPOINT: ', config.apiUrl);
    console.info('====  API_WS_ENDPOINT: ', config.webSocketUrl);

    return config;
  }

  const webSocketUrl = process.env.GRAPHQL_WS_ENDPOINT;
  const apiUrl = process.env.GRAPHQL_ENDPOINT;

  if (process.env.NODE_ENV !== 'test') {
    console.info('==== API_ENDPOINT: ', apiUrl);
    console.info('==== API_WS_ENDPOINT: ', webSocketUrl);
  }

  return {
    webSocketUrl,
    apiUrl,
  };
};
