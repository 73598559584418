import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DateRange,
  DateRangePickerModal,
} from '@components/DateRangePicker/DateRangePickerModal.web';
import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';

interface Props {
  variant?: string;
  dateRange?: DateRange;
  onChange?: (dateRange: DateRange) => void;
}

export const DateRangePickerIcon = ({
  variant,
  dateRange,
  onChange,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t: f } = useTranslation('format');
  const { start, end } = dateRange || {};

  const openModal = useCallback(() => setModalOpen(true), [setModalOpen]);
  const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);

  return (
    <Box accessibilityLabel='Filter Calendar'>
      {!start && !end ? (
        <Icon
          name='Calendar'
          variant={variant === 's' ? 's' : 'l'}
          color='grey05'
          onPress={openModal}
        />
      ) : (
        <Box>
          <Tag
            key='dateRange'
            label={`Date: ${f('abbrMonthAndDay', {
              val: start,
            })} - ${f('abbrMonthAndDay', {
              val: end,
            })}`}
            labelVariant='metadata'
            variant='pickerForWeb'
            padding='xxs'
            marginTop='xxs'
            marginRight='xxs'
            borderRadius='xxs'
            suffix={
              <Icon
                name='X2'
                height={16}
                onPress={() => {
                  onChange?.({});
                }}
              />
            }
            onPress={openModal}
          />
        </Box>
      )}
      <DateRangePickerModal
        visible={modalOpen}
        dateRange={dateRange}
        onClose={closeModal}
        onConfirm={(dateRange) => {
          onChange?.(dateRange);
          closeModal();
        }}
      />
    </Box>
  );
};
