import { useRoute } from '@react-navigation/native';
import React from 'react';

import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { useProjectFromQuery } from '@hooks/useProjectFromQuery';
import { ProjectsStackScreenProps } from '@navigation/projects/projects-stack';

import ProjectFormStack, { FormValues } from './ProjectFormStack';

const EditProject: React.FC = () => {
  const {
    params: { projectId },
  } = useRoute<ProjectsStackScreenProps<'project-edit'>['route']>();

  const { project, loading } = useProjectFromQuery({ projectId });

  if (!project || loading) return <ActivityIndicatorLoading />;

  const initialMembers = project.members.map((m) => {
    return {
      ...m.user,
      role: m.role,
    };
  });

  const initialValues: FormValues = {
    location: project.address
      ? {
          address: project.address,
          latitude: project?.latitude || 0,
          longitude: project?.longitude || 0,
        }
      : null,
    color: project.color,
    description: project.description || '',
    id: project.id,
    name: project.name || '',
    teamId: project.team?.id || '',
    users: initialMembers,
    currentTeam: project.team,
  };

  return (
    <ProjectFormStack projectId={projectId} initialValues={initialValues} />
  );
};

export default EditProject;
