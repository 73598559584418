import { ApolloError } from '@apollo/client';

import { logNonFatalErrorImpl } from './logNonFatalError';

type GenericError = {
  message: string;
  extra?: Record<string, unknown>[];
  errorMessage: string;
};

export type LogNetworkError = GenericError & {
  statusCode: number;
};

export type LogGraphError = GenericError & {
  operationName: string;
};

export type LogErrorProps = LogNetworkError | LogGraphError;

export const logError = (event: LogErrorProps) => {
  logNonFatalErrorImpl(event);
};

/**
 * Logs an `ApolloError` object.
 * @param error The apollo error object
 * @param messagePrefix The message prefix to describe the error
 * @param extra Extras to provide for logging
 */
export const logApolloError = (
  error: ApolloError,
  messagePrefix: string,
  extra: LogErrorProps['extra']
) => {
  if (error.networkError) {
    const event: LogNetworkError = {
      message: `${messagePrefix} Network Failure`,
      errorMessage: error.message,
      statusCode:
        'statusCode' in error.networkError ? error.networkError.statusCode : -1,
      extra,
    };
    logError(event);
  }
  error.graphQLErrors.forEach((graphError) => {
    const event: LogGraphError = {
      message: `${messagePrefix} GraphQL Failure`,
      errorMessage: graphError.message,
      operationName: graphError.path?.join('.') || '',
      extra,
    };
    logError(event);
  });
};
