import React, { useState } from 'react';
import { FlatList } from 'react-native';

import { WebModal } from '@components/Modals/WebModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { MemberRole } from '@components/User/MemberRole.web';
import {
  useUpdateTaskMutation,
  ListTasksDocument,
  ListProjectsDocument,
  useGetTaskQuery,
  Task,
  TaskMemberRole,
  useUpdateTaskMembersMutation,
  User,
  ProjectMemberRole,
  TeamMemberRole,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import useSearch from '@hooks/useSearch';
import { useTaskMembership } from '@hooks/useTaskMembership';
import { AddTaskMembersStack } from '@navigation/addMembers/add-task-members-stack.web';
import { MemberListActions } from '@screens/Members/MemberListActions';
import { getTaskRoleEnumValue, selectableTaskRoles } from '@utils/tasks';

// TODO: Most of this is duplicated with mobile and requires refactoring.
export const TaskMembers = ({
  taskId = '',
  isTaskAssignee,
  onPressMember,
}: {
  taskId: string;
  isTaskAssignee: boolean;
  onPressMember?: (item: User) => void;
}) => {
  const { me } = useMe();

  const { data: taskData, loading } = useGetTaskQuery({
    variables: {
      id: taskId,
    },
    skip: !taskId,
  });

  const { getTask: task } = taskData || { getTask: {} };

  const { search } = useSearch();
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);

  const {
    id,
    currentUserProjectRole,
    currentUserProjectTeamRole,
    name,
    projectId,
    members = [],
  } = task as Task;

  const { canEdit } = useTaskMembership(task as Task);
  const isEditorOrHigher = currentUserProjectRole !== ProjectMemberRole.Viewer;
  const isOwner =
    currentUserProjectRole === ProjectMemberRole.Owner || TaskMemberRole.Owner;
  const isProjectTeamOwnerOrAdmin =
    currentUserProjectTeamRole === TeamMemberRole.Owner || TeamMemberRole.Admin;

  const [updateTaskMembers] = useUpdateTaskMembersMutation({
    refetchQueries: [
      { query: ListTasksDocument },
      { query: ListProjectsDocument },
    ],
  });

  const [removeTaskMember] = useUpdateTaskMutation({
    refetchQueries: [
      { query: ListTasksDocument },
      { query: ListProjectsDocument },
    ],
  });

  const removeMemberFromTask = (memberId: string) => {
    const users = members.filter((item) => item.user.id !== memberId);

    const newMembers = users.map((u) => {
      return {
        userId: u.user.id,
        role: u.role,
      };
    });

    const newMembersOptimistic = users.map((u) => {
      return {
        id: u.user.id,
        role: u.role,
        user: u.user,
      };
    });

    removeTaskMember({
      variables: {
        id,
        attributes: {
          name: name,
          projectId: projectId,
          members: newMembers,
        },
      },
      optimisticResponse: {
        updateTask: {
          ...task,
          members: newMembersOptimistic,
        },
      },
    });
  };

  const updateTaskMember = (userId: User['id'], role: TaskMemberRole) => {
    updateTaskMembers({
      variables: {
        id,
        attributes: [
          {
            userId,
            role: getTaskRoleEnumValue(role),
          },
        ],
      },
    });
  };

  if (loading) {
    return <ActivityIndicatorLoading />;
  }

  if (!task) {
    return (
      <Box flex={1} paddingTop='m' alignItems='center'>
        <Text>Task not found</Text>
      </Box>
    );
  }

  return (
    <Box flex={1} backgroundColor='white'>
      <MemberListActions
        onAddMemberClick={() => {
          setShowAddMembersModal(true);
        }}
      />
      <FlatList
        data={members}
        renderItem={({ item }) => (
          <Box marginLeft='m' marginRight='l'>
            <MemberRole<TaskMemberRole>
              roles={
                !isEditorOrHigher && isTaskAssignee
                  ? [TaskMemberRole.Viewer]
                  : selectableTaskRoles
              }
              member={item}
              updateRole={
                ((canEdit || isEditorOrHigher) &&
                  item.role !== TaskMemberRole.Owner &&
                  isOwner) ||
                (isTaskAssignee && item.role == TaskMemberRole.Viewer) ||
                (isProjectTeamOwnerOrAdmin &&
                  item.role !== TaskMemberRole.Owner)
                  ? (i) => updateTaskMember(item.user.id, i)
                  : undefined
              }
              removeMember={
                (isEditorOrHigher || isProjectTeamOwnerOrAdmin) &&
                item.user.id !== me?.id
                  ? (member) => removeMemberFromTask(member.user.id)
                  : undefined
              }
              filterVal={search}
              onPressMember={onPressMember}
            />
          </Box>
        )}
        keyExtractor={(item) => item.user.id.toString()}
        ItemSeparatorComponent={() => <Box marginTop='m' />}
        ListFooterComponent={() => <Box marginBottom='listFooter' />}
        ListHeaderComponent={() => <Box marginTop='xs' />}
      />
      {task && (
        <WebModal
          onClose={() => setShowAddMembersModal(false)}
          visible={showAddMembersModal}
          width={504}
          height={512}>
          <AddTaskMembersStack
            taskId={taskId}
            isTaskAssignee={isTaskAssignee}
            onComplete={() => setShowAddMembersModal(false)}
            onClose={() => setShowAddMembersModal(false)}
          />
        </WebModal>
      )}
    </Box>
  );
};
