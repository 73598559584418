import React, { createContext, useContext, useReducer } from 'react';

import { ActionMap } from '@src/../types';

export const searchTags = ['Tags', 'Files', 'Check Ins'] as const;
type SearchTag = (typeof searchTags)[number];

enum SearchActionType {
  setSearch,
  toggleFilter,
  setIsSearching,
}

type SearchStatePayload = {
  [SearchActionType.setSearch]: string;
  [SearchActionType.toggleFilter]: SearchTag;
  [SearchActionType.setIsSearching]: boolean;
};

type SearchState = {
  search: string;
  filter: SearchTag[];
  isSearching: boolean;
};

type SearchContext = SearchState & {
  setSearch: (search: SearchState['search']) => void;
  toggleFilter: (filter: SearchState['filter'][number]) => void;
  setIsSearching: (isSearching: SearchState['isSearching']) => void;
};

const initialSearchState: SearchState = {
  search: '',
  filter: [],
  isSearching: false,
};

type SearchActions =
  ActionMap<SearchStatePayload>[keyof ActionMap<SearchStatePayload>];
const reducer = (state: SearchState, action: SearchActions): SearchState => {
  switch (action.type) {
    case SearchActionType.setSearch:
      return {
        ...state,
        isSearching: true,
        search: action.payload,
      };
    case SearchActionType.toggleFilter: {
      return {
        ...state,
        isSearching: true,
        filter: [action.payload],
      };
    }
    case SearchActionType.setIsSearching: {
      if (action.payload) {
        return { ...state, isSearching: true };
      } else {
        return {
          ...state,
          isSearching: false,
          search: '',
          filter: [],
        };
      }
    }
    default:
      return state;
  }
};

const searchContext = createContext<SearchContext | undefined>(undefined);

export const SearchProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = searchContext;
  const [state, dispatch] = useReducer(reducer, initialSearchState);

  const setSearch = (search: typeof state.search) => {
    dispatch({ type: SearchActionType.setSearch, payload: search });
  };

  const toggleFilter = (filter: (typeof state.filter)[number]) => {
    dispatch({ type: SearchActionType.toggleFilter, payload: filter });
  };

  const setIsSearching = (isSearching: typeof state.isSearching) => {
    dispatch({ type: SearchActionType.setIsSearching, payload: isSearching });
  };

  return (
    <Provider
      value={{
        ...state,
        setSearch,
        toggleFilter,
        setIsSearching,
      }}>
      {children}
    </Provider>
  );
};

const useSearch = (): SearchContext => {
  const context = useContext(searchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a Provider');
  }
  return context;
};

export default useSearch;
