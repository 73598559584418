import React from 'react';
import { useTranslation } from 'react-i18next';

import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';

interface confirmModalProps {
  showModal: boolean;
  onClose?: () => void;
  onPress: () => void;
  buttonText: string;
  title: string;
  message: string;
  isAlert?: boolean;
}

export const ConfirmModal: React.FC<confirmModalProps> = ({
  showModal,
  onClose,
  onPress,
  buttonText,
  title,
  message,
  isAlert = false,
}) => {
  const { t } = useTranslation();

  return (
    <WebModal
      title={title}
      onClose={onClose}
      visible={showModal}
      width={483}
      height={message !== '' ? 184 : undefined}>
      <>
        {message !== '' && (
          <Text variant='bodySecondary' marginHorizontal='m' marginVertical='m'>
            {message}
          </Text>
        )}
        <Box
          flexDirection='row'
          flex={1}
          alignItems='flex-end'
          paddingHorizontal='m'
          mb='m'
          mt='m'
          alignSelf='flex-end'>
          {!isAlert && (
            <Button
              onPress={onClose}
              borderColor='textPrimary'
              isSmall
              backgroundColor='white'>
              <Text variant='buttonLabel' color='textPrimary' mr='m'>
                {t('shared:cancel')}
              </Text>
            </Button>
          )}
          <Button
            onPress={onPress}
            borderColor='textPrimary'
            isSmall
            backgroundColor='black'>
            <Text variant='buttonLabel' color='white'>
              {buttonText}
            </Text>
          </Button>
        </Box>
      </>
    </WebModal>
  );
};
