import React from "react";
import { useTranslation } from "react-i18next";

import { User, Project, Task } from "../../graphql/generated";
import Avatar from "../Avatar/Avatar";
import TagsCollection from "../Chat/TagsCollection";
import { Box, Text } from "../Restyle";

interface FileActivityCardInterface {
  author: User;
  actionType: "Upload" | "Move"; // etc
  projects: Project[];
  tasks?: Task[];
  date: Date;
}

enum ActionType {
  Upload = "Uploaded a file in",
  Move = "Moved a file to",
}

const FileActivityCard: React.FC<FileActivityCardInterface> = ({
  author,
  actionType,
  projects,
  tasks,
  date,
  children,
}) => {
  const { t } = useTranslation();
  const { id, name } = author;

  return (
    <Box>
      <Box flexDirection="row" alignItems="center">
        <Avatar id={id} size="xs" />
        <Text
          variant="labelEmphasized"
          color="textPrimary"
          marginLeft="xs"
          style={{ flex: 1 }}
        >
          {name}
        </Text>
        <Text variant="metadataSecondary" color="textSecondary">
          {t("format:timeAgo", { val: date })}
        </Text>
      </Box>
      <Box flexDirection="row" flexWrap="wrap" alignItems="center">
        <Text variant="labelSmall" color="textPrimary">
          {ActionType[actionType]}
        </Text>
        {projects.map((item) => {
          return (
            <Box flex={1} marginTop="s" key={item.id}>
              <TagsCollection
                tagsCollection={{
                  tasks: tasks || item.tasks || [],
                  project: item,
                  author,
                }}
                showAuthor={false}
                borderless
              />
            </Box>
          );
        })}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default FileActivityCard;
