import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '@components/Restyle';
import { User } from '@graphql/generated';
import { Contacts } from '@screens/Contacts/Contacts.web';

export type ContactsTabBarParamList = {
  contacts?: { userId: User['id'] };
};

export type ContactsTabStackScreenProps<
  T extends keyof ContactsTabBarParamList
> = StackScreenProps<ContactsTabBarParamList, T>;

const Stack = createStackNavigator<ContactsTabBarParamList>();

export const ContactsStack: React.FC = () => {
  const { t } = useTranslation('models');
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => {
          return (
            <Box
              pl='m'
              pb='xs'
              pt='s'
              borderBottomWidth={1}
              borderBottomColor='grey02'>
              <Box
                width={95}
                pb='xs'
                borderBottomWidth={2}
                borderBottomColor='greenSecondary'>
                <Text variant='labelEmphasized'>
                  {t('contacts.tabs.allContacts')}
                </Text>
              </Box>
            </Box>
          );
        },
      }}>
      <Stack.Screen name='contacts' component={Contacts} />
    </Stack.Navigator>
  );
};
