import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform } from 'react-native';

import EmptyStateNoFiles from '@components/EmptyState/EmptyStateNoFiles';
import FileCard from '@components/Files/FileCard';
import { NotPaidConfirmModal } from '@components/Modals/NotPaidConfirmModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { CollapsableSectionList } from '@components/shared/CollapsableSectionList';
import Icon from '@components/shared/Icon/Icon';
import { Document, Task, TaskSortOption } from '@graphql/generated';
import { useListProjectDocumentsFromQuery } from '@hooks/useListProjectDocumentsFromQuery';
import { useListTasksFromQuery } from '@hooks/useListTasksFromQuery';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import { ProjectFilesProps } from '@screens/Projects/ProjectFiles.web';
import { openInNewTab } from '@utils/fileUtils';
import { filterByDateRange } from '@utils/tasks';

type SectionData = {
  sectionHeader: Task;
  data: Document[];
};

export const ProjectTaskFiles: React.FC<ProjectFilesProps> = ({
  projectId,
  ownerId,
  sortBy,
  isSelectMode,
  deleteloader,
  onSelectedModeChange,
  selectedList,
  handelSelectedItem,
  dateRange,
}) => {
  const navigation = useNavigation();
  const { setPreviewDocuments, setActiveIndex } = usePreviewDocuments();
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);
  const [taskIdsOfProject, setTaskIdsOfProject] = useState<Task['id'][]>([]);

  const { t } = useTranslation();
  const { isPaid, setPaid } = useMe();
  const [isPaidUser, setPaidUser] = useState<boolean>(!!isPaid);
  const [showNotPaidConfirmModal, setShowNotPaidConfirmModal] =
    useState<boolean>(false);

  const showNotPaidModal = (file: Document) => {
    const createdAt = dayjs(file.createdAt);
    const expired = createdAt?.diff(new Date(), 'day');
    if (!isPaidUser && expired < -1000) {
      if (Platform.OS === 'web') {
        setShowNotPaidConfirmModal(true);
      } else {
        Alert.alert(
          'Task ' + t('shared:attachmentsExpired'),
          t('shared:attachmentsExpiredMessage'),
          [
            { text: 'Cancel' },
            {
              text: t('shared:upgrade'),
              style: 'destructive',
              onPress: () => {
                setPaid(true);
                setPaidUser(true);
              },
            },
          ]
        );
      }
      return true;
    }
    return false;
  };
  const { documents, loading, refetch, pageInfo, fetchFromCursor } =
    useListProjectDocumentsFromQuery({
      projectIds: [projectId],
      ownerIds: ownerId ? [ownerId] : undefined,
      sortBy,
    });

  const filteredDocuments = dateRange
    ? filterByDateRange(documents, ({ createdAt }) => createdAt, dateRange)
    : documents;

  const renderFooterUI = (isLoadingMore: boolean) => {
    return (
      <Box marginBottom='listFooter'>
        {isLoadingMore && <ActivityIndicatorLoading />}
      </Box>
    );
  };

  const { tasks } = useListTasksFromQuery({
    projectIds: [projectId],
    skip: !projectId,
    sortBy: TaskSortOption.NameAsc,
    onCompleted: () => {
      const ids = tasks?.map((t) => t.id) || [];
      setTaskIdsOfProject(ids);
    },
  });

  useEffect(() => {
    refetch({
      projectIds: [projectId],
      taskIds: taskIdsOfProject,
      sortBy,
    });
  }, [taskIdsOfProject, sortBy]);

  const removeDuplicates = (docs: Document[]) => {
    return docs?.reduce((doc: Document[], current) => {
      if (!doc.find((item) => item.id === current.id)) {
        doc.push(current);
      }
      return doc;
    }, []);
  };

  const sectionData = () => {
    if (tasks?.length == 0 || filteredDocuments?.length == 0) {
      return [];
    }

    const section = tasks?.map((i): SectionData => {
      return {
        sectionHeader: i,
        data: [],
      };
    });

    const docs = removeDuplicates(filteredDocuments);
    docs &&
      docs.forEach((d) => {
        const taskIdsOfDoc = d?.taskDocuments?.map((t) => t.task?.id) || [];
        taskIdsOfDoc &&
          taskIdsOfDoc.forEach((taskId) => {
            section
              ?.find((s) => s.sectionHeader?.id === taskId)
              ?.data.push({
                ...d,
                id: taskId + '-' + d.id,
                selectedTaskFileId: d.taskDocuments?.filter((item) => {
                  return item.task.id == taskId;
                }),
              });
          });
      });

    const sectionDocumentsByTask =
      section?.filter((m) => m.data.length > 0) || [];
    return sectionDocumentsByTask;
  };

  if ((loading && tasks?.length < 1) || deleteloader)
    return <ActivityIndicatorLoading />;

  const sectionHeader = (item: Task) => {
    return (
      <>
        <Box
          backgroundColor='black'
          height={24}
          alignItems='center'
          flexDirection='row'
          justifyContent='center'
          borderRadius='xxs'
          paddingHorizontal='xxs'>
          <Icon name='Hash' color='white' variant='s' />
          <Text marginLeft='xxs' variant='labelSmall' color='white'>
            {tasks?.find((i) => i.id === item.id)?.name}
          </Text>
        </Box>
        <Box flex={1}></Box>
      </>
    );
  };

  const renderItem = (item: Document) => {
    return (
      <Box
        key={item.id}
        paddingVertical='s'
        paddingLeft='m'
        marginLeft='xxs'
        borderLeftColor='black'
        borderLeftWidth={1}>
        <FileCard
          document={item}
          onMorePress={
            (Platform.OS !== 'web' &&
              (() => {
                const taskIdsOfDoc =
                  item?.taskDocuments?.map((t) => t.task?.id) || [];
                setPreviewDocuments([{ ...item }]);
                navigation.navigate('task-file-modal', {
                  taskId: taskIdsOfDoc[0],
                  projectId,
                });
              })) ||
            undefined
          }
          onPress={() => {
            if (showNotPaidModal(item)) {
              return;
            }

            if (isSelectMode) {
              handelSelectedItem(item);
            } else {
              setPreviewDocuments([item]);
              setActiveIndex([item].length - 1);

              if (Platform.OS === 'web') {
                if (item.contentType?.startsWith('image/')) {
                  return setImageCarouselVisible(true);
                } else {
                  return openInNewTab(item);
                }
              } else {
                if (item.isImage || item.contentType?.startsWith('image/')) {
                  navigation.navigate('images-preview', { deleteCall: 'task' });
                } else {
                  navigation.navigate('file-preview', { location: 'task' });
                }
              }
            }
          }}
          showTags={false}
          selectedList={selectedList}
          isSelectMode={isSelectMode}
          onLongPress={() => {
            onSelectedModeChange(true);
            handelSelectedItem(item);
          }}
        />
      </Box>
    );
  };

  return (
    <Box flex={1}>
      <CollapsableSectionList<Task, Document>
        ListHeaderComponent={() => <Box />}
        data={sectionData()}
        renderSectionHeader={(item) => sectionHeader(item)}
        renderItem={(item) => renderItem(item)}
        dataListHeaderStyle={{ marginVertical: 'm' }}
        ListFooterComponent={renderFooterUI(pageInfo?.hasNextPage || false)}
        onEndReached={fetchFromCursor}
        onEndReachedThreshold={0.5}
        renderEmptyComponent={<EmptyStateNoFiles />}
      />
      {showNotPaidConfirmModal && (
        <NotPaidConfirmModal
          showModal={showNotPaidConfirmModal}
          onClose={() => setShowNotPaidConfirmModal(false)}
          onPress={() => {
            setShowNotPaidConfirmModal(false);
            setPaid(true);
            setPaidUser(true);
          }}
          title='Task'
        />
      )}
      {Platform.OS === 'web' && isImageCarouselVisible && (
        <ImagesPreview onDismiss={() => setImageCarouselVisible(false)} />
      )}
    </Box>
  );
};
