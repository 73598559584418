import { useNavigation } from '@react-navigation/native';
import { Field, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, FlatList, TouchableOpacity } from 'react-native';
import { InferType } from 'yup';

import Avatar from '@components/Avatar/Avatar';
import EmptyStateNoTeams from '@components/EmptyState/EmptyStateNoTeams';
import { SkillSelect } from '@components/FormModals/SkillSelect.web';
import {
  LocationSearch,
  Location,
} from '@components/LocationSearch/LocationSearch.web';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import TextField from '@components/shared/TextField/TextField';
import ToggleSwitch from '@components/Tasks/ToggleSwitch.web';
import { TeamListItem } from '@components/Team/TeamListItem';
import { PopupSetNotificationButtonWeb } from '@components/Web/PopupSetNotificationButton.web';
import { PopupTimeZoneButtonWeb } from '@components/Web/PopupTimeZoneButton.web';
import {
  GetMeDocument,
  NotificationSetting,
  NotificationSettingDuration,
  useUpdateMeMutation,
  useUpdateNotificationSettingMutation,
} from '@graphql/generated';
import useFileProcessor from '@hooks/useFileProcessor';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useMe from '@hooks/useMe';
import { useSkills } from '@hooks/useSkills';
import saveProfileSchema from '@schemas/saveProfileSchema';
import theme from '@themes/theme';
import { useFilePicker } from '@utils/filePicker';
import { guessTimeZone, humanTimeZoneName } from '@utils/formatters/date';

export type SaveProfileFormValues = InferType<typeof saveProfileSchema>;

type NotificationSettingsType = {
  title: string;
  mute: boolean;
  durationStr: string;
  settingType: NotificationSetting;
  duration: NotificationSettingDuration;
};
const EditProfile: React.FC = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { launchImageSelection } = useFilePicker();
  const { me } = useMe();
  const skills = useSkills();
  const [overrideSaveAlert, setOverrideSaveAlert] = useState(false);
  const [skillsModalOpen, setSkillsModalOpen] = useState<boolean>(false);
  const [locationModalOpen, setLocationModalOpen] = useState<boolean>(false);

  const { processFiles } = useFileProcessor();

  const [updateMe] = useUpdateMeMutation();

  const { teams } = useListTeamsFromQuery();
  const sortedTeams = teams
    ? [...teams].sort((teamA, teamB) => {
        // Compare team titles, move "Personal Projects" to the top
        if (teamA.name === 'Personal Projects') return -1;
        if (teamB.name === 'Personal Projects') return 1;
        // Sort other teams alphabetically
        return teamA.name.localeCompare(teamB.name);
      })
    : [];

  const onSubmit = (values: SaveProfileFormValues) => {
    const {
      name,
      phoneNumber,
      skillIds: skillIdsRaw,
      location,
      timeZone,
    } = values;
    const { address, latitude, longitude } = location || {
      address: undefined,
      latitude: undefined,
      longitude: undefined,
    };
    const skillIds = skillIdsRaw.filter((s) => s) as string[];
    if (values.avatar && me) {
      const { clientId, contentType, isImage, size, duration, cdnBaseUrl } =
        values.avatar;
      // avatar is a local file at this point
      const doc: Document = {
        __typename: 'Document',
        id: 'avatar',
        owner: me,
        name,
        clientId,
        contentType,
        isImage,
        createdAt: new Date().toISOString(),
        size,
        file: {
          __typename: 'File',
          id: new Date().toISOString(),
          cdnBaseUrl,
          url: '',
          path: '',
          ...values.avatar,
        },
        isAudio: false,
        duration,
      };
      const documents = [doc];

      processFiles(documents)
        .then((response) => {
          const { blobId } = response[0];
          updateMe({
            variables: {
              attributes: {
                name,
                phoneNumber,
                timeZone,
                skillIds,
                avatarBlobId: blobId,
                address,
                latitude,
                longitude,
              },
            },
            onError: (e) => {
              alert(e);
            },
          });
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      updateMe({
        variables: {
          attributes: {
            name,
            phoneNumber,
            timeZone,
            skillIds,
            avatarBlobId: '',
            address,
            latitude,
            longitude,
          },
        },
        onError: (e) => {
          alert(e);
        },
      });
    }
  };

  const mySkills =
    me?.skills?.map((item) => {
      return item.id;
    }) || [];

  const initialValues: SaveProfileFormValues = {
    avatar: null,
    name: me?.name || '',
    email: me?.email || '',
    phoneNumber: me?.phoneNumber || '',
    timeZone: me?.timeZone || guessTimeZone(),
    location: me?.address
      ? {
          latitude: me?.latitude || 0,
          longitude: me?.longitude || 0,
          address: me.address,
        }
      : null,
    skillIds:
      me?.skills?.map((item) => {
        return item.id;
      }) || [],
  };

  const ProfileComponent = (props: FormikProps<SaveProfileFormValues>) => {
    const { values, touched, setTouched, setFieldValue } = props;
    const { avatar, name } = me || {};
    const { skillIds, avatar: newAvatar, location } = values;

    const getStrFromDuration = (duration: NotificationSettingDuration) => {
      switch (duration) {
        case NotificationSettingDuration.Week:
          return '1 week';
        case NotificationSettingDuration.Forever:
          return 'Forever';
        default:
          return '1 day';
      }
    };

    const noti = me?.notificationSetting
      ?.filter((t) => t.settingType === NotificationSetting.Chat)
      ?.at(0);

    const initailItems = [
      {
        title: 'Chat',
        settingType: NotificationSetting.Chat,
        mute: noti?.mute ?? false,
        duration: noti?.duration ?? NotificationSettingDuration.Day,
        durationStr: getStrFromDuration(
          noti?.duration ?? NotificationSettingDuration.Day
        ),
      } as NotificationSettingsType,
    ];

    const [items, setItems] =
      useState<NotificationSettingsType[]>(initailItems);

    const [updateNotificationSetting] = useUpdateNotificationSettingMutation({
      refetchQueries: [{ query: GetMeDocument }, 'getMe'],
      onError: (_error: any) => {
        console.log('Apollo Error, ', _error);
      },
    });

    const getDurationFromStr = (str: string) => {
      switch (str) {
        case '1 week':
          return NotificationSettingDuration.Week;
        case 'Forever':
          return NotificationSettingDuration.Forever;
        default:
          return NotificationSettingDuration.Day;
      }
    };

    useEffect(() => {
      setItems(initailItems);
    }, []);

    const openGallery = async () => {
      await launchImageSelection().then((r) => {
        r && setFieldValue('avatar', r[0]);

        setTouched({ ...touched, avatar: true });
      });
    };

    const saveAlert = () => {
      Alert.alert(
        t('models:saveProject.title'),
        t('models:saveProject.message'),
        [
          {
            text: t('shared:cancel'),
          },
          {
            text: t('shared:leavePage'),

            onPress: () => {
              setOverrideSaveAlert(true);
              if (touched.avatar) {
                values.avatar = null;
              }
              if (touched.name) {
                values.name = me?.name ?? '';
              }
              if (touched.phoneNumber) {
                values.phoneNumber = me?.phoneNumber ?? '';
              }
              if (touched.location) {
                values.location = '';
              }
              if (touched.skillIds) {
                values.skillIds =
                  me?.skills?.map((item) => {
                    return item.id;
                  }) || [];
              }
              // debounce, else alert will show twice
              setTimeout(() => {
                setTouched({});
                navigation.goBack();
              }, 10);
            },
          },
        ]
      );
    };
    const setTimeZone = (value: string) => {
      if (touched.timeZone) {
        values.timeZone = me?.timeZone ?? '';
      }
      setTouched({ ...touched, timeZone: true });

      setFieldValue('timeZone', value);
    };

    useEffect(
      () =>
        navigation.addListener('beforeRemove', (e) => {
          if (overrideSaveAlert) {
            return;
          }
          if (Object.entries(touched).length > 0) {
            e.preventDefault();
            saveAlert();
          } else {
            return;
          }
        }),
      [navigation, touched, overrideSaveAlert]
    );

    useEffect(() => {
      values.avatar !== null && onSubmit(values);
    }, [values.avatar]);

    useEffect(() => {
      values.name !== me?.name && onSubmit(values);
    }, [values.name]);

    useEffect(() => {
      values.location?.address !== me?.address && onSubmit(values);
    }, [values.location]);

    useEffect(() => {
      values.timeZone !== me?.timeZone && onSubmit(values);
    }, [values.timeZone]);

    useEffect(() => {
      const compare = (a: string | undefined, b: string | undefined) =>
        (a ?? '').localeCompare(b ?? '');
      values.skillIds.toSorted(compare).toString() !==
        mySkills.toSorted(compare).toString() && onSubmit(values);
    }, [values.skillIds]);

    const offsetPopButton: Partial<any> = {
      position: 'right',
      offset: [0, 6],
    };

    return (
      <Box>
        <Box width={602}>
          <Box marginBottom='m' marginTop='l' alignItems='center'>
            <Avatar
              avatar={newAvatar ? newAvatar : avatar}
              width={94}
              height={94}
              label={name}
            />

            <TouchableOpacity
              onPress={openGallery}
              accessibilityLabel={t('models:saveProfile.editPhoto')}>
              <Text marginTop='m' color='greenSecondary' variant='webSmall'>
                {t('models:saveProfile.editPhoto')}
              </Text>
            </TouchableOpacity>
          </Box>

          <Field
            component={TextField}
            label='Name'
            name='name'
            accessibilityLabel={t('models:saveProfile.name')}
            placeholder={t('models:saveProfile.name')}
            textInputProps={{ editable: true }}
          />

          <Text color='textPrimary' variant='webSmall'>
            {t('models:saveProfile.location')}
          </Text>
          <TouchableOpacity
            accessibilityLabel={t('models:saveProfile.location')}
            onPress={() => setLocationModalOpen(true)}>
            <Box
              flexDirection='row'
              borderColor='grey02'
              borderWidth={1}
              height={48}
              alignItems='center'
              borderRadius='xs'
              paddingHorizontal='m'
              mt='xs'
              mb='m'>
              {location && (
                <Text
                  variant='bodySecondary'
                  color='black'
                  numberOfLines={1}
                  mr='m'>
                  {location.address}
                </Text>
              )}
              <Box flex={1}></Box>
              <Icon name='MapPin' variant='l' color='black' />
            </Box>
          </TouchableOpacity>

          <Field
            component={TextField}
            label='Phone Number'
            name='phoneNumber'
            accessibilityLabel={t('models:saveProfile.phoneNumber')}
            placeholder={t('models:saveProfile.phoneNumber')}
            customBorderColor={theme.colors.grey02}
            customBackgroundColor={theme.colors.grey01}
            textInputProps={{
              editable: false,
            }}
          />

          <Field
            component={TextField}
            label='Email'
            name='email'
            accessibilityLabel={t('models:saveProfile.email')}
            placeholder={t('models:saveProfile.email')}
            customBorderColor={theme.colors.grey02}
            customBackgroundColor={theme.colors.grey01}
            textInputProps={{
              editable: false,
            }}
          />

          <Text color='textPrimary' variant='webSmall' marginBottom='xs'>
            {t('models:saveProfile.timeZone')}
          </Text>
          <PopupTimeZoneButtonWeb
            title={humanTimeZoneName(values.timeZone ?? '') ?? ''}
            setTimeZone={setTimeZone}
            boxProps={offsetPopButton}
            width={602}
            maxHeight={150}
          />

          <Box marginTop='m' marginBottom='xs' flexDirection='row'>
            <Text marginRight='xs' variant='webSmall' color='textPrimary'>
              {t('models:saveProfile.skills')}
            </Text>
            <Icon name='AlertCircle' variant='s' color='textPrimary' />
          </Box>
          {/* eslint-disable-next-line no-magic-numbers */}
          {skillIds && skillIds.length > 0 && (
            <Box flexDirection='row' flexWrap='wrap' marginBottom='s'>
              <Tag
                label='+ Add Skills'
                variant='reversed'
                marginRight='xxs'
                padding='xxs'
                borderRadius='xxs'
                onPress={() => setSkillsModalOpen(true)}
              />

              {skillIds.map((item) => {
                const skill = skills.find((i) => i.id === item);

                if (!skill) return null;

                const { id: skillId, name: skillName } = skill;

                return (
                  <Tag
                    key={skillId}
                    label={skillName}
                    labelVariant='webSmall'
                    variant='status'
                    marginRight='xxs'
                    padding='xxs'
                    borderRadius='xxs'
                    onDismiss={() => {
                      const skillIds = values.skillIds.filter(
                        (i) => i !== skillId
                      );
                      setTouched({ ...touched, skillIds: true });
                      setFieldValue('skillIds', [...skillIds]);
                    }}
                    marginBottom='xs'
                  />
                );
              })}
            </Box>
          )}
          {/* eslint-disable-next-line no-magic-numbers */}
          {!!skillIds && skillIds.length < 1 && (
            <Box width={92.22} marginRight='xxs'>
              <Tag
                prefix={
                  <Icon
                    variant='xs'
                    name='Plus'
                    width={14.22}
                    height={14.22}
                    color='white'
                  />
                }
                iconWidth={14.22}
                iconHeight={14.22}
                prefixMarginRight='xxs'
                label='Add Skills'
                labelVariant='webSmall'
                variant='reversed'
                padding='xxs'
                borderRadius='xxs'
                onPress={() => setSkillsModalOpen(true)}
              />
            </Box>
          )}

          <Box style={{ marginTop: 40 }}>
            <Box>
              <Text variant='heading2' color='black'>
                {t('models:projects.create.teams.title')}
              </Text>
            </Box>
            <Box flex={1}>
              {!teams ? (
                <EmptyStateNoTeams />
              ) : (
                <FlatList
                  data={sortedTeams}
                  keyExtractor={(item) => item.id}
                  renderItem={({ item }) => (
                    <Box>
                      <TeamListItem team={item} onSelect={() => {}} />
                    </Box>
                  )}
                  ListHeaderComponent={() => <Box marginTop='m' />}
                  ItemSeparatorComponent={() => <Box marginBottom='m' />}
                />
              )}
            </Box>
          </Box>

          <Box style={{ marginTop: 40 }}>
            <Box>
              <Text variant='heading2' color='black'>
                {t('models:profile.settings.notifications.title')}
              </Text>
            </Box>
            {items.map((item) => {
              return (
                <Box
                  flex={1}
                  flexDirection='row'
                  justifyContent='flex-start'
                  alignContent='center'>
                  <Box justifyContent='center' mr='m' style={{ height: 88 }}>
                    <ToggleSwitch
                      onValueChange={async (v) => {
                        item.mute = v;

                        await updateNotificationSetting({
                          variables: {
                            attributes: [
                              {
                                settingType: item.settingType,
                                mute: v,
                                duration: item.duration,
                              },
                            ],
                          },
                          onCompleted: () => {
                            setItems([...items]);
                          },
                        });
                      }}
                      value={item.mute}
                    />
                  </Box>
                  <Box
                    justifyContent='center'
                    width={216}
                    style={{ height: 88 }}>
                    <Text variant='labelSmall' color='textPrimary'>
                      {t(
                        'models:profile.settings.notifications.pausePushNotification'
                      )}
                    </Text>
                    <Text
                      variant='metadata'
                      color='textSecondary'
                      marginTop='xs'>
                      {t(
                        'models:profile.settings.notifications.sendRelatedNotifications',
                        { title: item.title }
                      )}
                    </Text>
                  </Box>
                  {item.mute && (
                    <Box justifyContent='center' ml='xs' style={{ height: 88 }}>
                      <Text
                        variant='labelSmall'
                        color='textPrimary'
                        marginBottom='xs'>
                        {t('models:profile.settings.notifications.pauseFor')}
                      </Text>
                      <PopupSetNotificationButtonWeb
                        title={item.durationStr}
                        setPauseFor={async (durationStr: string) => {
                          const duration = getDurationFromStr(durationStr);
                          const settingType = item.settingType;

                          item.duration = duration;
                          item.durationStr = durationStr;
                          setItems([...items]);
                          await updateNotificationSetting({
                            variables: {
                              attributes: [
                                {
                                  settingType,
                                  mute: item.mute,
                                  duration,
                                },
                              ],
                            },
                          });
                        }}
                        boxProps={{
                          position: 'right',
                          offset: [0, -56],
                        }}
                        width={140}
                        maxHeight={150}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
          <Box style={{ marginTop: 40 }}>
            <Box>
              <Text variant='heading2' color='black'>
                {t('models:profile.settings.preferences.title')}
              </Text>
            </Box>
            <Box
              flex={1}
              flexDirection='row'
              justifyContent='flex-start'
              alignContent='center'
              paddingVertical='xs'>
              <Box justifyContent='center' mr='m'>
                <ToggleSwitch onValueChange={() => {}} value={false} />
              </Box>
              <Box justifyContent='center' width={216}>
                <Text variant='labelSmall' color='textPrimary'>
                  {t('models:profile.settings.preferences.searchVisibility')}
                </Text>
                <Text variant='metadata' color='textSecondary' marginTop='xs'>
                  {t('models:profile.settings.preferences.setTeamToPrivate')}
                </Text>
              </Box>
              <Box justifyContent='flex-start' ml='xs' my='xs'>
                <Tag
                  label={t('shared:comingSoon')}
                  labelVariant='metadata'
                  variant='secondary'
                  padding='xxs'
                  borderRadius='xxs'
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <WebModal
          onClose={() => setLocationModalOpen(false)}
          visible={locationModalOpen}
          width={504}
          title='Location'
          height={512}>
          <Box flex={1} margin='m'>
            <LocationSearch
              isShowCancel={false}
              onClose={() => setLocationModalOpen(false)}
              onSelect={(location: Location) => {
                setFieldValue('location', location);
                setTouched({ ...touched, location: true });
              }}
            />
          </Box>
        </WebModal>
        {skillsModalOpen && (
          <SkillSelect onClose={() => setSkillsModalOpen(false)} />
        )}
      </Box>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={saveProfileSchema}>
      {(props) => {
        return ProfileComponent(props);
      }}
    </Formik>
  );
};

export default EditProfile;
