import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { SelectTeamMembersList } from '@components/Invite/SelectTeamMembersList';
import { Box } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { ButtonNext } from '@components/shared/Button/ButtonNext';
import Icon from '@components/shared/Icon/Icon';
import theme from '@themes/theme';

type SelectMembersFromTeamProps = {
  onSubmit?: () => void;
  disabled?: boolean;
  loading?: boolean;
} & ComponentProps<typeof SelectTeamMembersList>;

export const SelectMembersFromTeam: React.FC<SelectMembersFromTeamProps> = ({
  onSubmit,
  loading,
  ...selectTeamMemberListProps
}) => {
  const { t } = useTranslation();

  return (
    <Box flex={1}>
      <SelectTeamMembersList {...selectTeamMemberListProps} />
      <Box style={styles.bottomButton}>
        <ButtonNext
          suffix={
            <Icon
              variant='l'
              name='ArrowRight'
              color={
                loading || selectTeamMemberListProps.users?.length === 0
                  ? 'grey04'
                  : 'white'
              }
            />
          }
          onPress={onSubmit}
          disabled={loading || selectTeamMemberListProps.users?.length === 0}
          variant='primary'
          borderRadius='xl'
          paddingHorizontal='l'
          accessibilityLabel={t('shared:next')}>
          {loading ? <ActivityIndicatorLoading /> : t('shared:next')}
        </ButtonNext>
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  bottomButton: {
    position: 'absolute',
    bottom: theme.spacing.l,
    right: theme.spacing.l,
  },
});
