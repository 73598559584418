import dayjs from 'dayjs';

import { DateRangePicker } from '@components/DateRangePicker/Index';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box } from '@components/Restyle';

import type { Dayjs } from 'dayjs';

export type DateRange = {
  start?: Dayjs;
  end?: Dayjs;
};

interface Props {
  visible?: boolean;
  dateRange?: DateRange;
  onConfirm?: (range: DateRange) => void;
  onClose?: () => void;
}

export const DateRangePickerModal = ({
  visible,
  dateRange: { start, end } = {},
  onClose,
  onConfirm,
}: Props) => {
  return (
    <WebModal
      visible={!!visible}
      onClose={onClose}
      height={512}
      title='Filter by Date'>
      <Box flex={1} backgroundColor='white' paddingHorizontal='m'>
        <DateRangePicker
          lazyLoad
          startDate={start?.format('YYYYMMDD')}
          untilDate={end?.format('YYYYMMDD')}
          onConfirm={(startDate: string, untilDate: string) => {
            onConfirm?.({
              start: (startDate && dayjs(startDate)) || undefined,
              end: (untilDate && dayjs(untilDate)) || undefined,
            });
          }}
        />
      </Box>
    </WebModal>
  );
};
