import React from 'react';
import { TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import { Box, ShadowBox, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import useChatInput from '@hooks/useChatInput';
import theme from '@themes/theme';

export type PopupProps = {
  opacity?: number;
  showIcon?: boolean;
  showStep?: boolean;
  showImage?: boolean;
  imageName?: string;
  step?: string;
  title?: string;
  message?: string;
  highlight?: string[];
  width?: number;
  dismissBtn?: boolean;
  continueBtn?: boolean;
  nextBtn?: boolean;
  finishBtn?: boolean;
  closePopFn?: () => void;
  continueFn?: () => void;
  nextFn?: () => void;
  finishFn?: () => void;
};

const MessagePopup: React.FC<PopupProps> = ({
  opacity = 1,
  showIcon = false,
  showStep = false,
  showImage = false,
  imageName = 'Message21',
  step,
  title,
  message,
  highlight = [''],
  width,
  dismissBtn,
  continueBtn,
  nextBtn,
  finishBtn,
  closePopFn,
  continueFn,
  nextFn,
  finishFn,
}) => {
  const { setMessageModalHeight } = useChatInput();
  const getWidth = () => {
    let width = 0;
    switch (imageName) {
      case 'AssignTaskMsg1':
        width = 236;
        break;
      case 'AssignTaskMsg2':
        width = 240;
        break;
      case 'AssignTaskMsg3':
        width = 235;
        break;
      default:
        width = 222;
        break;
    }
    return width;
  };

  const getHeight = () => {
    let height = 0;
    switch (imageName) {
      case 'AssignTaskMsg1':
        height = 203;
        break;
      case 'AssignTaskMsg2':
        height = 96;
        break;
      case 'AssignTaskMsg3':
        height = 83;
        break;
      case 'Message1':
        height = 62;
        break;
      default:
        height = 117;
        break;
    }
    return height;
  };

  return (
    <ShadowBox
      style={{ opacity: opacity }}
      onLayout={(event) =>
        setMessageModalHeight(event.nativeEvent.layout.height)
      }
      flex={1}
      width={width}
      alignItems='center'
      justifyContent='center'
      borderRadius='m'
      paddingHorizontal='m'
      paddingVertical='s'
      variant='base'
      backgroundColor='white'>
      <Box flex={1} alignItems='center' justifyContent='center'>
        {showIcon && (
          <Box
            alignItems='center'
            justifyContent='center'
            borderRadius='xs'
            backgroundColor='greenSecondaryMild'
            marginBottom='xs'
            style={{ width: 40, height: 40 }}>
            <Icon name='LightbulbAlt1' color='greenBrand' variant='l' />
          </Box>
        )}
        {showStep && (
          <Box
            flexDirection='row'
            alignItems='center'
            justifyContent='center'
            marginBottom='xs'>
            <Box
              alignItems='center'
              justifyContent='center'
              style={{ width: 24, height: 24 }}>
              <Icon
                name='Close'
                color='white'
                variant='l'
                width={12.01}
                height={12.01}
              />
            </Box>
            <Box flex={1} alignItems='center' justifyContent='center'>
              <Text variant='metadata' color='grey04'>
                {step}
              </Text>
            </Box>
            <TouchableOpacity onPress={closePopFn}>
              <Box
                alignItems='center'
                justifyContent='center'
                style={{ width: 24, height: 24 }}>
                <Icon
                  name='Close'
                  color='black'
                  variant='l'
                  width={12.01}
                  height={12.01}
                />
              </Box>
            </TouchableOpacity>
          </Box>
        )}
        {title && (
          <Text variant='labelLarge' color='black' marginBottom='xs'>
            {title}
          </Text>
        )}
        {message && (
          <Text
            variant='body'
            color='grey05'
            marginBottom='xs'
            style={{ lineHeight: showStep ? 20 : 24, textAlign: 'center' }}>
            <Highlighter
              highlightStyle={{
                color: theme.colors.greenSecondary,
                backgroundColor: theme.colors.background,
              }}
              searchWords={highlight}
              textToHighlight={message}
            />
          </Text>
        )}
        {showImage && (
          <Box
            alignItems='center'
            justifyContent='center'
            paddingVertical='s'
            style={{
              paddingRight:
                imageName === 'AssignTaskMsg1' ||
                imageName === 'AssignTaskMsg2' ||
                imageName === 'AssignTaskMsg3'
                  ? 0
                  : 14,
            }}>
            <Icon name={imageName} width={getWidth()} height={getHeight()} />
          </Box>
        )}
        <Box
          flex={1}
          flexDirection='row'
          alignItems='center'
          justifyContent='center'>
          {dismissBtn && (
            <TouchableOpacity onPress={closePopFn}>
              <Box
                alignItems='center'
                justifyContent='center'
                borderRadius='xs'
                marginRight='xs'
                backgroundColor='grey01'
                style={{ width: 114, height: 40 }}>
                <Text variant='buttonLabel' color='black'>
                  Dismiss
                </Text>
              </Box>
            </TouchableOpacity>
          )}
          {continueBtn && (
            <TouchableOpacity onPress={continueFn}>
              <Box
                alignItems='center'
                justifyContent='center'
                borderRadius='xs'
                backgroundColor='black'
                style={{ width: 114, height: 40 }}>
                <Text variant='buttonLabel' color='white'>
                  Continue
                </Text>
              </Box>
            </TouchableOpacity>
          )}
          {nextBtn && (
            <TouchableOpacity style={{ flex: 1 }} onPress={nextFn}>
              <Box
                flex={1}
                alignItems='center'
                justifyContent='center'
                borderRadius='xs'
                backgroundColor='black'
                style={{ height: 40 }}>
                <Text variant='buttonLabel' color='white'>
                  Next
                </Text>
              </Box>
            </TouchableOpacity>
          )}
          {finishBtn && (
            <TouchableOpacity style={{ flex: 1 }} onPress={finishFn}>
              <Box
                flex={1}
                alignItems='center'
                justifyContent='center'
                borderRadius='xs'
                backgroundColor='black'
                style={{ height: 40 }}>
                <Text variant='buttonLabel' color='white'>
                  Finish
                </Text>
              </Box>
            </TouchableOpacity>
          )}
        </Box>
      </Box>
    </ShadowBox>
  );
};

export default MessagePopup;
