import { useNavigation } from '@react-navigation/native';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { Alert } from '@components/Alert';
import { CancelConfirmTaskAndProjectModal } from '@components/Modals/CancelConfirmTaskAndProjectModal';
import { Box, Text } from '@components/Restyle';
import { Badge } from '@components/shared/Badges/Badge';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import ListNavigationItem from '@components/shared/ListNavigationItem/ListNavigationItem';
import TextField from '@components/shared/TextField/TextField';
import { getPriority } from '@components/TaskCard/TaskCard';
import { FormValues } from '@components/Tasks/TaskFormStack';
import { MemberRole } from '@components/User/MemberRole';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  ProjectSortOption,
  TaskMemberRole,
  useGetProjectLazyQuery,
} from '@graphql/generated';
import { useListProjectsBasicInfoFromQuery } from '@hooks/useListProjectsBasicInfoFromQuery';
import useNewColors from '@hooks/useNewColors';
import useSelectedMembers from '@hooks/useSelectedMembers';
import useTabFocus from '@hooks/useTabFocus';
import Images from '@themes/images';
import theme from '@themes/theme';
import { localDateFromUTCDate } from '@utils/formatters/date';
import { getTaskRoleEnumValue, selectableTaskRoles } from '@utils/tasks';

interface TaskFormProps {
  parentProject?: boolean;
  screenName?: string;
}

export const TaskForm: React.FC<TaskFormProps> = ({
  parentProject,
  screenName,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { tabFocus } = useTabFocus();
  const { getMappedColor } = useNewColors();

  const {
    values,
    errors,
    touched,
    setTouched,
    submitForm,
    setFieldValue,
    isValid,
    isSubmitting,
  } = useFormikContext<FormValues>();
  const { id: taskId, users, projectId, priority, startDate, dueDate } = values;

  const {
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
  } = useWebDrawer();

  const owner = values.users.find((i) => i.role === TaskMemberRole.Owner);

  const { projects, loading: projectsLoading } =
    useListProjectsBasicInfoFromQuery({
      first: 10,
      sortBy: ProjectSortOption.NameAsc,
    });

  const [getProjectLazy, { data }] = useGetProjectLazyQuery({
    variables: {
      id: projectId,
    },
  });
  const { getProject: selectedProject } = data || {
    selectedProject: undefined,
  };
  const sortedUsers = users.filter(
    (i) =>
      i.role === TaskMemberRole.Viewer || i.role === TaskMemberRole.Assignee
  );
  const { selectedMembers, setSelectedMembers } = useSelectedMembers();
  useEffect(() => {
    setSelectedMembers(sortedUsers);
  }, []);

  const priorities = [
    ['Low', 'LOW'],
    ['Medium', 'MEDIUM'],
    ['High', 'HIGH'],
  ];

  const buttonWidth = (Dimensions.get('screen').width - 32 - 12) / 3;
  const isLast = (index: number): boolean => !!((index + 1) % 3);

  useEffect(() => {
    if (!projects && !projectsLoading) {
      Alert.alert(
        t('models:tasks.noProjects.title'),
        t('models:tasks.noProjects.message'),
        [
          { text: t('shared:cancel'), onPress: () => navigation.goBack() },

          {
            text: t('shared:yes'),
            onPress: () =>
              navigation.navigate('projects-stack', {
                screen: 'project-create',
              }),
          },
        ]
      );
    }
  }, [projects]);

  useEffect(() => {
    if (projectId) {
      getProjectLazy({
        variables: {
          id: projectId,
        },
      });
    }
  }, [projectId]);

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const onPressCancel = () => {
    setIsCancelModalVisible(false);
    if (Platform.OS === 'web') {
      setIsTaskWebPanelOpen(false);
      setIsTaskWebPanelMaximize(false);
      setIdTaskDetailOpen('');
      setIdProjectOfTaskDetailOpen('');
    } else {
      if (projectId && tabFocus === 'Projects') {
        navigation.navigate('projects-stack', {
          screen: 'details',
          params: { projectId },
        });
      } else {
        navigation.navigate('tasks-stack', {
          screen: 'tasks',
        });
      }
    }
  };

  const updateMember = (userId: string, role: string) => {
    const members = sortedUsers?.map((u) => {
      if (u.id !== userId) return u;
      else {
        return { ...u, role: getTaskRoleEnumValue(role) };
      }
    });
    setSelectedMembers(members);
    setFieldValue('users', members);
    setTouched({ ...touched, projectId: true });
  };

  const removeMemberAlert = (memberId: string) => {
    const updateMember = sortedUsers?.filter((u) => u.id !== memberId);
    setSelectedMembers(updateMember);
    setFieldValue('users', updateMember);
    setTouched({ ...touched, projectId: true });
  };

  const createButton = () => {
    return (
      <Button
        marginTop={Platform.OS === 'web' ? 'none' : 'xl'}
        disabled={
          !isValid || isSubmitting || Object.entries(touched).length < 1
        }
        variant='primary'
        onPress={submitForm}
        float={Platform.OS === 'web' ? undefined : 'bottom-inset'}
        accessibilityLabel={
          taskId ? t('models:tasks.save.title') : t('models:tasks.create.title')
        }>
        {taskId ? t('models:tasks.save.title') : t('models:tasks.create.title')}
      </Button>
    );
  };

  const addMembers = () => {
    navigation.navigate('add-edit-task-modal-members', {
      ownerId: owner?.id,
    });
  };

  return (
    <Box
      flex={1}
      maxWidth={Platform.OS === 'web' ? 560 : undefined}
      backgroundColor='white'>
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <Box flex={1} marginTop='l' style={{ paddingBottom: 140 }}>
          {!taskId && Platform.OS !== 'web' && (
            <Box
              borderBottomColor='grey02'
              borderBottomWidth={1}
              marginBottom='l'>
              <ListNavigationItem
                title={t('models:tasks.create.copyCta')}
                iconName='Copy'
                // task-copy-search sits in task-form-stack for web. In mobile, it is in tasks-stack
                onPress={() =>
                  navigation.navigate(
                    Platform.OS === 'web' ? 'task-copy-search' : 'task-search'
                  )
                }
                hideChevron={Platform.OS === 'web'}
              />
            </Box>
          )}

          <Field
            component={TextField}
            label='Name'
            accessibilityLabel='Name'
            name='name'
            isRequired
            placeholder={t('models:tasks.placeholders.name')}
            showCharsRemaining
            textInputProps={{ maxLength: 25 }}
          />
          <Field
            component={TextField}
            label='Description'
            accessibilityLabel='Description'
            name='description'
            placeholder={t('models:tasks.placeholders.description')}
            showCharsRemaining
            textInputProps={{ maxLength: 125 }}
          />

          <Box
            marginTop='s'
            marginBottom='xs'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'>
            <Text variant='labelSmall'>
              {t('models:tasks.create.project.title')}
            </Text>
            <Text variant='metadataSecondary' color='textSecondary'>
              {t('shared:required')}
            </Text>
          </Box>
          {selectedProject && projectId ? (
            <Button
              variant='edit'
              fullWidth
              prefix={
                <Box
                  alignItems='center'
                  justifyContent='center'
                  marginRight='xs'
                  style={{
                    borderRadius: 5,
                    padding: 6,
                    backgroundColor: getMappedColor(selectedProject.color),
                  }}>
                  <Icon
                    name={
                      selectedProject.icon
                        ? (selectedProject.icon as keyof typeof Images)
                        : 'IconStart22'
                    }
                    variant='m'
                    color='white'
                  />
                </Box>
              }
              onPress={
                !!taskId || parentProject
                  ? undefined
                  : () => navigation.navigate('project-select')
              }
              isSmall
              accessibilityLabel='Add to project'>
              {selectedProject.name}
            </Button>
          ) : (
            <>
              <Button
                variant='edit'
                fullWidth
                onPress={() => navigation.navigate('project-select')}
                isSmall
                accessibilityLabel='Add to project'>
                {t('models:tasks.create.project.add')}
              </Button>
              {errors.projectId && touched.projectId && (
                <Box flexDirection='row' alignItems='center' marginTop='s'>
                  <Icon
                    name='AlertTriangle'
                    color='alertRed'
                    variant='s'
                    marginRight='xxs'
                  />
                  <Text variant='error'>{errors.projectId}</Text>
                </Box>
              )}
            </>
          )}

          <Text variant='labelSmall' marginTop='l'>
            {t('models:tasks.create.dates.title')}
          </Text>
          <Box
            marginTop='xs'
            flexDirection='row'
            justifyContent='space-between'>
            <Box flex={1} marginRight='s'>
              <Button
                variant={startDate ? 'editSelected' : 'edit'}
                onPress={() => {
                  if (Platform.OS === 'web') {
                    navigation.navigate('date-select', {
                      field: 'startDate',
                    });
                  } else {
                    navigation.navigate('edit-date-select', {
                      fieldName: 'startDate',
                      submitOnSelected: false,
                    });
                  }
                }}
                prefixMarginRight='xs'
                prefix={
                  <Icon
                    name='Calendar'
                    color={startDate ? 'textPrimary' : 'grey04'}
                  />
                }
                isSmall
                accessibilityLabel='Start Date'>
                {startDate
                  ? t('format:monthAndDay', {
                      val: localDateFromUTCDate(startDate),
                    })
                  : t('models:tasks.create.dates.startDate')}
              </Button>
            </Box>
            <Box flex={1} marginLeft='s'>
              <Button
                variant={dueDate ? 'editSelected' : 'edit'}
                onPress={() => {
                  if (Platform.OS === 'web') {
                    navigation.navigate('date-select', { field: 'dueDate' });
                  } else {
                    navigation.navigate('edit-date-select', {
                      fieldName: 'dueDate',
                      submitOnSelected: false,
                    });
                  }
                }}
                prefixMarginRight='xs'
                prefix={
                  <Icon
                    name='Calendar'
                    color={dueDate ? 'textPrimary' : 'grey04'}
                  />
                }
                isSmall
                accessibilityLabel='Due Date'>
                {dueDate
                  ? t('format:monthAndDay', {
                      val: localDateFromUTCDate(dueDate),
                    })
                  : t('models:tasks.create.dates.dueDate')}
              </Button>
            </Box>
          </Box>

          <Text marginTop='s' marginBottom='xs' variant='labelSmall'>
            {t('models:tasks.create.priority.title')}
          </Text>
          {/* PRIORITY */}
          <Box
            flexDirection='row'
            justifyContent={
              Platform.OS === 'web' ? 'space-between' : 'flex-start'
            }>
            {priorities.map(([key, value], index) => (
              <Box
                key={index}
                style={isLast(index) && { marginRight: 6 }}
                flex={Platform.OS === 'web' ? 1 : undefined}>
                <Badge
                  label={key}
                  variant={
                    getPriority(value === priority ? priority : null).variant
                  }
                  onPress={() => {
                    setTouched({ ...touched, priority: true });
                    setFieldValue(
                      'priority',
                      value === priority ? null : value
                    );
                  }}
                  width={buttonWidth}
                  height={36}
                  borderRadius='xxs'
                />
              </Box>
            ))}
          </Box>

          <Box flexDirection='column' marginTop='l'>
            <Box
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'>
              <Text variant='labelSmall' marginBottom='xs'>
                {t('models:projects.create.members.title')}
              </Text>

              {sortedUsers && sortedUsers.length > 0 && (
                <TouchableOpacity onPress={() => addMembers()}>
                  <Box flexDirection='row' alignItems='center'>
                    <Icon
                      variant='s'
                      name='Plus'
                      color='greenSecondary'
                      marginRight='xxs'
                    />
                    <Text
                      variant='labelSmall'
                      textAlign='center'
                      numberOfLines={1}
                      color='greenSecondary'>
                      {t('shared:addMembers')}
                    </Text>
                  </Box>
                </TouchableOpacity>
              )}
            </Box>

            {sortedUsers && sortedUsers.length < 1 && (
              <Box
                flex={1}
                flexDirection='row'
                alignItems='flex-start'
                justifyContent='flex-start'
                flexWrap='wrap'>
                <Box
                  justifyContent='center'
                  alignItems='center'
                  marginRight='m'>
                  <TouchableOpacity
                    onPress={() => addMembers()}
                    accessibilityLabel={t('shared:addMembers')}>
                    <Box
                      flexDirection='row'
                      alignItems='center'
                      justifyContent='center'
                      marginRight='m'>
                      <Box
                        alignItems='center'
                        justifyContent='center'
                        borderRadius='xxl'
                        bg='black'
                        width={40}
                        height={40}>
                        <Icon variant='xl' name='Plus' color='white' />
                      </Box>
                      <Text variant='labelSmall' marginLeft='xs' color='grey04'>
                        {t('shared:addMembers')}
                      </Text>
                    </Box>
                  </TouchableOpacity>
                </Box>
              </Box>
            )}

            {selectedMembers && selectedMembers.length > 0 && (
              <Box>
                {selectedMembers?.map((item, index) => (
                  <Box
                    key={item.id}
                    style={{
                      marginBottom:
                        index === users.length - 1
                          ? theme.spacing.listFooter
                          : theme.spacing.s,
                    }}>
                    <MemberRole
                      isCreateTask={true}
                      roles={selectableTaskRoles}
                      member={item}
                      updateRole={(i: string) => {
                        updateMember(item.id, i);
                      }}
                      removeMember={(member) => removeMemberAlert(member.id)}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          {/* Status: check code here on branch : TA-2464-please-remove-task-status-chips-i-e-not-started-ongoing-on-hold-completed-overdue OR ticket No TA-2464 */}
          {/* Task Phases, Requiring Task, and Task Dependency: check code here on branch:TA-3310-hide-functionality-task-phases-requiring-task-and-task-dependency OR ticket No TA-3310*/}
        </Box>
      </KeyboardAwareScrollView>
      {Platform.OS === 'web' && (
        <Box
          flex={1}
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
          }}
          flexDirection='row'
          justifyContent='space-between'>
          <Box flex={1} marginRight='s'>
            <Button
              backgroundColor='white'
              borderColor='black'
              variant='cancel'
              color='black'
              onPress={() => {
                setIsCancelModalVisible(true);
              }}
              accessibilityLabel={t('shared:cancel')}>
              {t('shared:cancel')}
            </Button>
          </Box>
          <Box flex={1}>{createButton()}</Box>
        </Box>
      )}
      {Platform.OS !== 'web' && createButton()}
      {isCancelModalVisible && (
        <CancelConfirmTaskAndProjectModal
          showModal={isCancelModalVisible}
          onClose={() => setIsCancelModalVisible(false)}
          onPress={onPressCancel}
          buttonTextOne={
            screenName == 'Create'
              ? t('models:tasks.cancel.btnCreateText')
              : t('models:tasks.cancel.btnEditText')
          }
          buttonText={
            screenName == 'Create'
              ? t('models:tasks.cancel.removeTask')
              : t('models:tasks.cancel.btnCancelText')
          }
          title={
            screenName == 'Create'
              ? t('models:tasks.cancel.title')
              : t('models:tasks.cancel.cancelEditingTitle')
          }
          message={
            screenName == 'Create'
              ? t('models:tasks.cancel.message')
              : t('models:tasks.cancel.editMessage')
          }
        />
      )}
    </Box>
  );
};
