import { useState } from "react";

export function useSelected<T extends { id: string }>(): [
  T[],
  (i: T) => void,
  (i: T[]) => void,
  () => void
] {
  const [selected, setSelected] = useState<T[]>([]);

  const toggleSelected = (i: T) => {
    if (selected.length > 0) {
      const arr = selected.filter((item) => item.id !== i.id); // rm

      if (arr.length !== selected.length) {
        setSelected(arr);
      } else {
        setSelected((current) => [...current, i]); // add
      }
    } else {
      setSelected((current) => [...current, i]); // add first
    }
  };

  const selectAll = (data: T[]) => {
    selected.length !== data.length ? setSelected(data) : setSelected([]);
  };

  const unSelectAll = () => {
    setSelected([]);
  };

  return [selected, toggleSelected, selectAll, unSelectAll];
}
