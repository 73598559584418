import { array, object, string } from 'yup';

import { userSchema } from '@schemas/userSchema';
export const MAX_GROUP_NAME_LENGTH = 100;

export const createGroupSchema = object({
  name: string().max(
    MAX_GROUP_NAME_LENGTH,
    `Group name must not exceed ${MAX_GROUP_NAME_LENGTH} characters`
  ),
  users: array()
    .of(userSchema)
    .ensure()
    .min(1, 'At least 1 member is required')
    .required('required'),
});
