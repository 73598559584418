import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import RestyleIcon from '@components/shared/Icon/RestyleIcon';
import { useApolloClient } from '@hooks/useApolloClient';
import useChatAvatar from '@hooks/useChatAvatar';
import useChatInput from '@hooks/useChatInput';
import { useGetChatFromCache } from '@hooks/useGetChatFromCache';
import useMe from '@hooks/useMe';

import { Chat, User } from '../../graphql/generated';
import useGlobalSearch from '../../hooks/useGlobalSearch';
import useSearch from '../../hooks/useSearch';
import Avatar from '../Avatar/Avatar';
import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';
import { SearchInputWithTags } from '../shared/SearchInput/SearchInputWithTags';

type ChatHeaderProps = {
  chatId: Chat['id'];
};

const ChatHeader: React.FC<ChatHeaderProps> = ({ chatId: id }) => {
  const { t } = useTranslation('models');
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { isSearching, setIsSearching } = useSearch();
  const { client } = useApolloClient();

  const { chat } = useGetChatFromCache(id);

  const {
    id: chatId,
    avatar,
    isGroupChat,
    name,
    settings,
    users = [],
  } = chat || {};
  const { me } = useMe();

  const chatUser: User = users.find((i) => i.id !== me?.id) || me;
  const isArchived = settings?.archivedAt;
  const { isSearching: isGlobalSearching } = useGlobalSearch();

  const { getChatAvatar, getChatAvatarLabel } = useChatAvatar();
  const { subscribeToChatUpdates } = useChatInput();

  useEffect(() => {
    return () => {
      setIsSearching(false);
    };
  }, []);

  useFocusEffect(
    useCallback(() => {
      // Subscribe to chat
      const unsubscribe = subscribeToChatUpdates && subscribeToChatUpdates();

      // Unsubscribe from chat updates when the screen is blurred
      return () => {
        unsubscribe && unsubscribe();
      };
    }, [client])
  );

  return (
    <Box
      paddingHorizontal='m'
      paddingBottom={!isSearching ? 'xs' : undefined}
      borderBottomColor='grey02'
      borderBottomWidth={1}
      flexDirection='row'
      alignItems='center'
      marginTop='xs'
      style={{ paddingTop: insets.top }}>
      {!isSearching ? (
        <>
          <Box>
            <TouchableOpacity
              onPress={() =>
                isGlobalSearching ? navigation.pop() : navigation.popToTop()
              }
              hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
              <RestyleIcon
                name='ChevronLeft'
                variant='xl'
                color='textPrimary'
                //marginRight='xs'
              />
            </TouchableOpacity>
          </Box>
          <Box marginLeft='xs'>
            <Avatar
              id={chatUser.id}
              size='small'
              isMultiple={isGroupChat}
              label={name && !isGroupChat ? name : getChatAvatarLabel(chat)}
              avatar={avatar ? avatar : getChatAvatar(chat)}
              disableAvatarPress={isGroupChat}
            />
          </Box>
          <Box flex={1} marginHorizontal='xs'>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate(
                  isGroupChat
                    ? 'group-chat-settings'
                    : 'individual-chat-settings',
                  { chatId }
                )
              }
              accessibilityLabel={isGroupChat ? 'Group Info' : 'Chat Info'}>
              <Text
                variant='labelEmphasized'
                color='textPrimary'
                numberOfLines={1}>
                {name || getChatAvatarLabel(chat) || ' '}
              </Text>
              {isArchived && (
                <Text variant='metadataSecondary' color='grey04'>
                  {t('shared:archived')}
                </Text>
              )}
              {!isArchived && (
                <Text variant='metadataSecondary' color='textSecondary'>
                  {isGroupChat
                    ? t('chat.labels.groupChatSettingsButton')
                    : t('chat.labels.individualChatSettingsButton') || ' '}
                </Text>
              )}
            </TouchableOpacity>
          </Box>

          <Icon
            name='Search'
            variant='m'
            color='textPrimary'
            onPress={() => setIsSearching(true)}
            accessibilityLabel='Search'
          />
        </>
      ) : (
        <SearchInputWithTags showCancel autofocus />
      )}
    </Box>
  );
};

export default ChatHeader;
