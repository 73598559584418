import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { Message, MessageDetailFragmentDoc } from '@graphql/generated';

export const getMessageFromCache = (
  client: ApolloClient<NormalizedCacheObject>,
  cacheIdentifier: string | null | undefined
): Message | undefined | null => {
  if (!cacheIdentifier) return null;

  return client.cache.readFragment({
    id: cacheIdentifier,
    fragment: MessageDetailFragmentDoc,
    fragmentName: 'MessageDetail',
  });
};
