import EmojiPicker, { EmojiStyle, SuggestionMode } from 'emoji-picker-react';
import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';

import useChatInput from '@hooks/useChatInput';
import useEmoji from '@hooks/useEmoji';
import theme from '@themes/theme';

type EmojiModalProps = {
  onEmoji: (emoji: string) => void;
};

export const EmojiModal: React.FC<EmojiModalProps> = ({ onEmoji }) => {
  const { message, setMessage } = useChatInput();
  const { from } = useEmoji();

  return (
    <TouchableOpacity style={styles.container} onPress={() => onEmoji('')}>
      <TouchableOpacity
        activeOpacity={1}
        onPress={(e) => e.stopPropagation()}
        style={styles.outer}>
        <EmojiPicker
          width='100%'
          height='100%'
          emojiStyle={EmojiStyle.NATIVE}
          emojiVersion='5.0'
          searchDisabled={true}
          previewConfig={{ showPreview: false }}
          suggestedEmojisMode={SuggestionMode.RECENT}
          onEmojiClick={({ emoji }) => {
            if (from == 'webchat-input') {
              setMessage(message + emoji);
            }
            onEmoji(emoji);
          }}
        />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    bottom: 0,
    zIndex: 100,
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTopLeftRadius: theme.borderRadii.m,
    borderTopRightRadius: theme.borderRadii.m,
    paddingHorizontal: theme.spacing.m,
    paddingBottom: theme.spacing.m,
  },
  outer: {
    width: '100%',
    height: '60%',
    alignItems: 'center',
  },
});
