import { array, object, string } from 'yup';
import 'yup-phone';

const registerSocialSchema = object({
  email: string().email('Invalid email address').required('Required'),
  first_name: string().required('Required'),
  last_name: string().required('Required'),
  phone: string()
    .phone(undefined, false, 'The phone number is invalid.')
    .required('Required'),
  invitation_token: string(),
  provider: string().notRequired(),
  uid: string().notRequired(),
  team_name: string(),
  team_emails: array().of(string()),
});

export default registerSocialSchema;
