import React from 'react';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import theme from '@themes/theme';

interface ModalHeaderProps {
  title: string;
  titleVariant?: keyof typeof theme.textVariants;
  titleColor?: keyof typeof theme.colors;
  marginHorizontal?: keyof typeof theme.spacing;
  onClose?: () => void;
}

export const SimpleHeader: React.FC<ModalHeaderProps> = ({
  title,
  titleVariant,
  titleColor,
  marginHorizontal,
  onClose,
}) => {
  return (
    <Box
      accessibilityLabel={title}
      flexDirection='row'
      justifyContent='space-between'
      marginHorizontal={marginHorizontal ?? 'm'}
      mt='l'>
      <Text variant={titleVariant ?? 'labelLarge'} color={titleColor}>
        {title}
      </Text>
      {!!onClose && (
        <Icon
          accessibilityLabel={'Close ' + title}
          name='X'
          variant='m'
          color='black'
          onPress={onClose}
        />
      )}
    </Box>
  );
};
