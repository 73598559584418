import { useNavigation } from '@react-navigation/native';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal, { ModalItemType } from '@components/Modals/Modal';
import { FormValues } from '@components/Tasks/TaskFormStack';
import { TaskPriority } from '@graphql/generated';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';

export const TaskDetailEditPriority = () => {
  const { t } = useTranslation();
  const { values, isSubmitting, submitForm, setFieldValue } =
    useFormikContext<FormValues>();
  const { priority } = values;

  const navigation =
    useNavigation<
      TasksStackScreenProps<'task-detail-edit-status'>['navigation']
    >();

  const priorities: { title: string; value: TaskPriority }[] = [
    { title: t('models:tasks.create.priority.low'), value: TaskPriority.Low },
    {
      title: t('models:tasks.create.priority.medium'),
      value: TaskPriority.Medium,
    },
    { title: t('models:tasks.create.priority.high'), value: TaskPriority.High },
  ];

  const [selectedPriority, setSelectedPriority] = useState(priority);
  const navItems: ModalItemType[] = priorities.map<ModalItemType>(
    ({ title, value }) => ({
      title: title,
      trailingIconName: value == selectedPriority ? 'Check' : undefined,
      onSelect: () => {
        setSelectedPriority(value);
      },
    })
  );
  return (
    <Modal
      goBack={() => {
        navigation.goBack();
      }}
      showHeader={true}
      headerTitle={t('models:tasks.create.priority.title')}
      items={navItems}
      headerSaveDisable={selectedPriority === priority || isSubmitting}
      onSave={() => {
        setFieldValue('priority', selectedPriority);
        submitForm();
      }}
    />
  );
};
