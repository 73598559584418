import { WatchQueryFetchPolicy } from '@apollo/client';
import { useState } from 'react';
import { RefreshControl } from 'react-native';

import {
  Project,
  Task,
  TaskSortOption,
  TaskStatus,
  Team,
  User,
  useListTasksQuery,
  TaskGroup,
  TaskPriority,
  Scalars,
} from '@graphql/generated';
import {
  logError,
  LogGraphError,
  LogNetworkError,
} from '@utils/logNonFatalError';
/**
 * Retrieves a list of tasks
 */

const DEFAULT_PER_PAGE_COUNT = 10;

export const useListTasksFromQuery = ({
  userId = undefined,
  projectIds = undefined,
  sortBy = undefined,
  status = undefined,
  first = DEFAULT_PER_PAGE_COUNT,
  last = undefined,
  after = undefined,
  before = undefined,
  fetchPolicy = 'cache-and-network',
  withoutPhase = false,
  onCompleted = undefined,
  onError = undefined,
  skip = false,
  memberIds = undefined,
  teamIds = undefined,
  excludeCompleted = false,
  collaboratorsOnly = false,
  dueGroup = undefined,
  priority = undefined,
  dueDateBegin,
  dueDateEnd,
}: {
  userId?: string;
  memberIds?: User['id'][];
  teamIds?: Team['id'][];
  projectIds?: Project['id'][];
  sortBy?: TaskSortOption;
  status?: TaskStatus;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
  withoutPhase?: boolean;
  onCompleted?: () => void;
  onError?: () => void;
  skip?: boolean;
  excludeCompleted?: boolean;
  collaboratorsOnly?: boolean;
  dueGroup?: TaskGroup;
  priority?: TaskPriority[];
  dueDateBegin?: Scalars['ISO8601DateTime'];
  dueDateEnd?: Scalars['ISO8601DateTime'];
}) => {
  const [refreshing, setRefreshing] = useState(false);

  const { data, loading, error, refetch, fetchMore } = useListTasksQuery({
    variables: {
      userId,
      projectIds,
      sortBy,
      status,
      first,
      last,
      before,
      after,
      memberIds,
      teamIds,
      withoutPhase,
      excludeCompleted,
      collaboratorsOnly,
      dueGroup,
      priority,
      dueDateBegin,
      dueDateEnd,
    },
    fetchPolicy,
    onCompleted,
    onError: (error) => {
      if (error.networkError) {
        const event: LogNetworkError = {
          message: 'List Tasks Network Failure',
          errorMessage: error.message,
          statusCode:
            'statusCode' in error.networkError
              ? error.networkError.statusCode
              : -1,
          extra: [{ projectIds }],
        };
        logError(event);
      }
      error.graphQLErrors.forEach((graphError) => {
        const event: LogGraphError = {
          message: 'List Tasks GraphQL Error',
          errorMessage: graphError.message,
          operationName: 'listTasks',
          extra: [{ projectIds }],
        };
        logError(event);
      });

      onError?.();
    },
    skip,
  });

  const onRefresh = () => {
    setRefreshing(true);
    refetch();
  };

  const refreshControl = (
    <RefreshControl
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true);
        refetch();
      }}
    />
  );

  const fetchFromCursor = () => {
    if (!tasks || !pageInfo?.hasNextPage || loading) return;

    const cursor = pageInfo.endCursor;
    fetchMore({
      variables: {
        after: cursor,
        first,
      },
    });
  };

  const { listTasks } = data || { listTasks: undefined };
  const { edges = [], pageInfo } = listTasks || { edges: [] };
  // TODO: This needs to be more properly typed to the returned graphql value.
  const tasks =
    edges?.reduce((acc, curr) => {
      if (curr && curr.node && curr.node !== undefined) {
        return [...acc, curr.node as Task];
      } else {
        return acc;
      }
    }, [] as Task[]) || [];

  // pagination returns duplicate tasks when tasks are named the same resulting in duplicate key errors
  // can be removed when pagination is fixed
  const taskIds = tasks.map((t) => t.id);
  const filterOutDuplicates = tasks.filter((t, index) => {
    return !taskIds.includes(t.id.toString(), index + 1);
  });

  return {
    tasks: filterOutDuplicates,
    loading,
    error,
    pageInfo,
    refetch,
    fetchMore,
    fetchFromCursor,
    refreshing,
    setRefreshing,
    onRefresh,
    refreshControl,
  };
};
