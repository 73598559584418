import { useCallback } from 'react';
import uuid from 'react-native-uuid';

import { useApolloClient } from '@hooks/useApolloClient';
import useMe from '@hooks/useMe';

import {
  MessageTask,
  MessageProject,
  Document,
  MessageDetailFragmentDoc,
  DocumentDetailFragmentDoc,
  ListMessagesDocument,
  ListDraftDocumentsDocument,
  MessageEdge,
  Message,
  Attachment,
  ListDraftMessagesDocument,
  ListDraftMessagesQuery,
} from '../graphql/generated';

const useSendMessage = () => {
  const { me } = useMe();
  const { client } = useApolloClient();
  const { cache } = client || {};

  const sendDraftMessage = useCallback(
    async (attributes: Message) => {
      setTimeout(() => {
        const { chatId, attachments, replyId, replyMessage, checkin } =
          attributes;
        const messageTasks = (attachments || []).filter(
          (item) => item.__typename === 'MessageTask'
        ) as MessageTask[];
        const taskIds = messageTasks.map((item) => item.taskId);

        const messageProjects = (attachments || []).filter(
          (item) => item.__typename === 'MessageProject'
        ) as MessageProject[];
        const projectIds = messageProjects.map((item) => item.projectId);

        const documents = (attachments || []).filter(
          (item) => item.__typename === 'Document'
        ) as Document[];

        const messageDocuments = documents.map((item) => {
          const {
            contentType,
            clientId: documentClientId,
            name,
            isImage,
            isAudio,
            duration,
            file,
          } = item;

          return {
            id: documentClientId,
            name,
            contentType,
            blobId: '',
            createdAt: new Date().toISOString(),
            projectIds,
            taskIds,
            owner: me,
            isImage,
            clientId: documentClientId,
            isAudio,
            duration,
            file,
            size: 1,
            __typename: 'Document',
          } as Attachment;
        });

        if (!cache) return undefined;

        messageDocuments.forEach((item) => {
          const newDocument = {
            id: cache.identify(item),
            fragment: DocumentDetailFragmentDoc,
            fragmentName: 'DocumentDetail',
            data: item,
          };
          cache.writeFragment(newDocument);
        });
        cache?.updateQuery(
          {
            query: ListMessagesDocument,
            variables: {
              chatId: chatId,
              before: '',
              last: 15,
            },
          },
          (data) => {
            if (!data) return null;
            const { listMessages } = data;
            const edges = listMessages?.edges || [];
            const createMessageId = cache.identify(attributes);
            const id = uuid.v4().toString();
            const newMessage = {
              id: createMessageId,
              fragment: MessageDetailFragmentDoc,
              fragmentName: 'MessageDetail',
              data: {
                ...attributes,
                id,
                cursor: 'draft-cursor',
                replyId: replyId || null,
                replyMessage: replyMessage || null,
                attachments: [
                  ...messageProjects,
                  ...messageTasks,
                  ...messageDocuments,
                ],
                reaction: [],
                failedAt: null,
                checkin: checkin || null,
              },
            };

            cache.writeFragment(newMessage);

            const newMessageIdentity = cache.identify(newMessage);

            const edgeExists = edges.some((item: MessageEdge) => {
              if (!item?.node) return false;
              const itemIdentity = cache.identify(item.node);
              return itemIdentity == newMessageIdentity;
            });

            if (edgeExists) return null;

            const cursor = uuid.v4().toString();
            const newEdges = edges.concat({
              __typename: 'MessageEdge',
              cursor,
              node: newMessage.data,
            } as MessageEdge);

            return {
              listMessages: {
                ...data.listMessages,
                edges: newEdges,
              },
            };
          }
        );

        cache.updateQuery<ListDraftMessagesQuery>(
          { query: ListDraftMessagesDocument },
          (data) => {
            const newMessage = {
              ...attributes,
              author: me,
              authorId: me?.id,
              replyMessage: attributes.replyMessage || null,
              replyId: attributes.replyId || null,
              failedAt: null,
              checkin: attributes.checkin || null,
            };
            const updateData = [...(data?.listDraftMessages || []), newMessage];
            return {
              listDraftMessages: [...updateData],
            };
          }
        );

        if (messageDocuments.length) {
          const draftDocuments = messageDocuments.map((m) => {
            return {
              ...m,
              messageClientId: attributes.clientId,
            };
          });
          cache.updateQuery(
            {
              query: ListDraftDocumentsDocument,
              variables: {
                chatId: chatId,
              },
            },
            (data) => ({
              listDraftDocuments: [
                ...(data?.listDraftDocuments || []),
                ...draftDocuments,
              ],
            })
          );
        }
      }, 0);
    },
    [me?.id, me?.name, me?.avatar?.url]
  );

  return { sendDraftMessage };
};

export default useSendMessage;
