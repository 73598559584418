import groupBy from './groupBy';
import { TagsCollectionType } from '../components/Chat/TagsCollection';
import {
  Attachment,
  MessageTask,
  MessageProject,
  User,
  Maybe,
  Document,
} from '../graphql/generated';

export const isProjectAttachment = (item: Attachment): item is MessageProject =>
  item.__typename === 'MessageProject';

export const isTaskAttachment = (item: Attachment): item is MessageTask =>
  item.__typename === 'MessageTask';

export const isDocumentAttachment = (item: Attachment): item is Document =>
  item.__typename === 'Document';

export const isImageAttachment = (item: Attachment): item is Document =>
  item.__typename === 'Document' && item.isImage;

export type TaggableAttachment = MessageTask | MessageProject;

export type AuthorContextAttachment = {
  author: Maybe<User> | undefined;
  projects: MessageProject[];
  tasks: MessageTask[];
};

export const filterTaggableAttachments = (attachments: Attachment[] = []) =>
  attachments.filter(
    (a) => isProjectAttachment(a) || isTaskAttachment(a)
  ) as TaggableAttachment[];

export const groupByAuthor = (attachments: TaggableAttachment[]) =>
  Object.values(
    groupBy(attachments, (item) => {
      return item?.author?.id;
    })
  );

export const hoistAuthorContext = (authorTagsGroup: TaggableAttachment[][]) => {
  return authorTagsGroup.map((authorTags) => {
    const taggedTasks: MessageTask[] = authorTags.filter(isTaskAttachment);
    const taggedProjects: MessageProject[] =
      authorTags.filter(isProjectAttachment);

    return {
      author: authorTags[0].author,
      projects: taggedProjects,
      tasks: taggedTasks,
    };
  });
};

export const transformIntoTagsCollections = (
  tagsByAuthor: AuthorContextAttachment[]
) => {
  return tagsByAuthor.map((authorTags) => {
    const projectsById = groupBy(
      authorTags.projects,
      (item: MessageProject) => {
        return item.project!.id;
      }
    );

    const tagCollections: TagsCollectionType[] = Object.entries(
      projectsById
    ).map(([projectId, projects]) => {
      const tasks = authorTags.tasks
        .filter((messageTask) => messageTask.task!.projectId === projectId)
        .map((messageTask) => messageTask.task);

      return {
        project: projects[0].project,
        tasks,
        author: projects[0].author,
      } as TagsCollectionType;
    });

    return tagCollections;
  });
};

export const generateTagCollections = (attachments: Attachment[]) => {
  const filteredTaggables = filterTaggableAttachments(attachments);
  const groupedByAuthor = groupByAuthor(filteredTaggables);
  const withAuthorContext = hoistAuthorContext(groupedByAuthor);
  const transformedTagsCollections =
    transformIntoTagsCollections(withAuthorContext);

  return transformedTagsCollections;
};

export const tagsCollectionToTaskIds = (
  tagsCollection: TagsCollectionType[]
) => {
  return tagsCollection
    .filter((item) => !!item?.tasks?.length)
    .map((item) => item.tasks.map((task) => task.id))
    .flat();
};

export const tagsCollectionToProjectIds = (
  tagsCollection: TagsCollectionType[]
) => tagsCollection.map((item) => item.project.id);

export const tagsCollectionToMessageTasks = (
  tagsCollection: TagsCollectionType[],
  messageTaskId: string,
  author: User
) => {
  return tagsCollection
    .filter((item) => {
      return !!item?.tasks?.length;
    })
    .map((item, outerIndex) => {
      return item.tasks.map((task, innerIndex) => ({
        __typename: 'MessageTask',
        id: `${messageTaskId}-${outerIndex}-${innerIndex}`,
        authorId: author.id,
        author,
        taskId: task.id,
        task,
      }));
    })
    .flat();
};

export const tagsCollectionToMessageProjects = (
  tagsCollection: TagsCollectionType[],
  messageTaskId: string,
  author: User
) => {
  return tagsCollection.map((item, index) => {
    return {
      __typename: 'MessageProject',
      id: `${messageTaskId}-${index}`,
      projectId: item.project.id,
      authorId: author.id,
      author,
      project: item.project,
    };
  });
};
