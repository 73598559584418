/* eslint-disable no-magic-numbers */
/* eslint-disable no-nested-ternary */
import React, { ComponentProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';
// eslint-disable-next-line import/default

import Avatar from '@components/Avatar/Avatar';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import { Badge } from '@components/shared/Badges/Badge';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import {
  ProjectMemberRole,
  Task,
  TaskMemberRole,
  TaskPriority,
  TaskStatus,
} from '@graphql/generated';
import Images from '@themes/images';
import theme from '@themes/theme';
import {
  beginningOfToday,
  beginningOfYesterday,
  localDateFromUTCDate,
} from '@utils/formatters/date';

interface TaskProps {
  listStyle?: boolean;
  task: Task;
  isSearch?: boolean;
  filterVal?: string;
  onPress?: (task: Task) => void;
  onDelete?: (task: Task) => void;
  isSelected?: boolean;
  isEditing?: boolean;
  onDependencyPress?: (dependencyIds: string[]) => void;
  borderLess?: boolean;
  collapsable?: boolean;
  isCollapsed?: boolean;
  toggleCompleteTask?: (task: Task) => void;
}

type Status = { label: string; icon: keyof typeof Images };

export const getStatus = (status: TaskStatus): Status => {
  switch (status) {
    case TaskStatus.OnHold:
      return { label: 'On hold', icon: 'Pause' };
    case TaskStatus.Ongoing:
      return { label: 'Ongoing', icon: 'RefreshCw' };
    case TaskStatus.Overdue:
      return { label: 'Overdue', icon: 'Frown' };
    case TaskStatus.NotStarted:
      return { label: 'Not started', icon: 'MinusSquare' };
    case TaskStatus.Completed:
      return { label: 'Completed', icon: 'CheckSquare' };
    default:
      return { label: 'On hold', icon: 'Pause' };
  }
};

type Priority = {
  label: string;
  variant: ComponentProps<typeof Badge>['variant'];
};

export const getPriority = (priority: TaskPriority): Priority => {
  switch (priority) {
    case TaskPriority.High:
      return { label: 'High', variant: 'red' };
    case TaskPriority.Medium:
      return { label: 'Medium', variant: 'yellow' };
    case TaskPriority.Low:
      return { label: 'Low', variant: 'grey' };
    default:
      return { label: 'Low', variant: 'grey01' };
  }
};

const TaskCard = ({
  listStyle = false,
  task,
  isSelected,
  isSearch = false,
  filterVal = '',
  onPress,
  onDelete,
  isEditing,
  onDependencyPress,
  collapsable,
  toggleCompleteTask,
}: TaskProps) => {
  const { t } = useTranslation('models');
  const { t: f } = useTranslation('format');
  const [collapsed, setCollapsed] = useState(true);

  const { completedAt, currentUserRole, currentUserProjectRole } = task;

  const isEditorOrHigher = currentUserProjectRole !== ProjectMemberRole.Viewer;
  const isTeamMember = task.project?.currentUserTeamRole === 'MEMBER';

  const canComplete =
    isEditorOrHigher || !isTeamMember
      ? isEditorOrHigher || !isTeamMember
      : currentUserRole === TaskMemberRole.Assignee ||
        currentUserRole === TaskMemberRole.Owner;

  const nameAndRadioButton = (
    <>
      {toggleCompleteTask && (
        <Box mr='xs'>
          <RadioButton
            disabled={!canComplete}
            onPress={() => toggleCompleteTask(task)}
            isSelected={completedAt}
            iconVariant='m'
          />
        </Box>
      )}
      <Box flex={1}>
        <Text
          variant='labelEmphasized'
          numberOfLines={1}
          style={{ alignSelf: `flex-start` }}
          marginRight='l'>
          <Highlighter
            autoEscape
            highlightStyle={{
              color: theme.colors.greenSecondary,
              backgroundColor: theme.colors.background,
            }}
            searchWords={filterVal.split(' ')}
            textToHighlight={task.name}
          />
        </Text>
      </Box>
    </>
  );

  const collapsedCard = (
    <Box flex={1} flexDirection='row' alignItems='center'>
      {nameAndRadioButton}

      <Box alignItems='flex-start'>
        <Tag
          label={task.project?.name}
          variant='folder'
          prefix={<Icon name='Folder' variant='xs' color='grey05' />}
          padding='xxs'
          borderRadius='xxs'
        />
      </Box>
      <Icon
        name='ChevronDown'
        variant='m'
        color='black'
        marginLeft='xs'
        onPress={() => setCollapsed(false)}
      />
    </Box>
  );
  const isOlderThanYesterday = (dueDate: any) => {
    if (!dueDate) {
      return false;
    }
    const yesterday = beginningOfYesterday();
    const taskDate = localDateFromUTCDate(dueDate);
    return taskDate < yesterday;
  };

  const isToday = (dueDate: any) => {
    if (!dueDate) {
      return false;
    }
    const today = beginningOfToday();
    const taskDate = localDateFromUTCDate(dueDate);
    return (
      today.getFullYear() === taskDate.getFullYear() &&
      today.getMonth() === taskDate.getMonth() &&
      today.getDate() === taskDate.getDate()
    );
  };
  const isYesterday = (dueDate: any) => {
    if (!dueDate) {
      return false;
    }

    const yesterday = beginningOfYesterday();
    const taskDate = localDateFromUTCDate(dueDate);

    return (
      yesterday.getFullYear() === taskDate.getFullYear() &&
      yesterday.getMonth() === taskDate.getMonth() &&
      yesterday.getDate() === taskDate.getDate()
    );
  };

  const getTaskCard = () => {
    const isDueToday = isToday(task.dueDate);
    const isDueYesterday = isYesterday(task.dueDate);
    const isDueOlderThanYesterday = isOlderThanYesterday(task.dueDate);
    let dateColor = 'textPrimary';
    let dateText = '';

    if (isDueToday) {
      dateColor = 'greenSecondary';
      dateText = 'Today';
    } else if (isDueYesterday) {
      dateColor = 'alertRed';
      dateText = 'Yesterday';
    } else if (isDueOlderThanYesterday) {
      dateColor = 'alertRed';
      dateText = f('monthAndDay', { val: localDateFromUTCDate(task.dueDate) });
    } else {
      dateColor = 'textPrimary';
      dateText = f('monthAndDay', { val: localDateFromUTCDate(task.dueDate) });
    }

    if (collapsed && collapsable) {
      return collapsedCard;
    }
    return (
      <Box backgroundColor='white'>
        <Box
          justifyContent='space-between'
          flexDirection='row'
          alignItems='center'>
          {nameAndRadioButton}
          <Box
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-start'>
            <Avatar
              id={task.creator?.id}
              avatar={task.creator?.avatar}
              size='xs'
              label={task.creator.name}
            />

            {task.priority && (
              <Badge
                label={getPriority(task.priority).label}
                variant={getPriority(task.priority).variant}
                marginLeft='xs'
                borderRadius='xxs'
                paddingHorizontal='xs'
                paddingVertical='xxs'
              />
            )}
            {collapsable && (
              <Icon
                name='ChevronUp'
                variant='m'
                color='black'
                marginLeft='xs'
                onPress={() => setCollapsed(true)}
              />
            )}
          </Box>
        </Box>
        <Box flexDirection='row' justifyContent='space-between' marginTop='m'>
          <Box flexDirection='row' flex={1} style={{ marginRight: 20 }}>
            <Box flex={1} alignItems='flex-start'>
              <Tag
                filterVal={filterVal}
                label={task.project?.name}
                variant='folder'
                prefix={<Icon name='Folder' variant='xs' color='grey05' />}
                padding='xxs'
                borderRadius='xxs'
              />
            </Box>
          </Box>
          {task.dueDate && (
            <Box
              flexDirection='row'
              alignItems='center'
              justifyContent='flex-end'>
              <Text letterSpacing={0.2} variant='link' color={dateColor}>
                {dateText}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const getTaskCardWeb = () => (
    <Box backgroundColor='white'>
      <Box justifyContent='space-between' flexDirection='row'>
        <Box flex={1}>
          <Text
            variant='labelEmphasized'
            numberOfLines={1}
            style={{ alignSelf: `flex-start` }}
            marginRight='l'>
            <Highlighter
              autoEscape
              highlightStyle={{
                color: theme.colors.greenSecondary,
                backgroundColor: theme.colors.background,
              }}
              searchWords={filterVal.split(' ')}
              textToHighlight={task.name}
            />
          </Text>
        </Box>
        {task.status && !isSearch && (
          <Box
            ml='m'
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-start'>
            <Tag
              label={getStatus(task.status).label}
              variant='status'
              paddingHorizontal='xs'
              paddingVertical='xxs'
              borderRadius='xxs'
              prefixMarginRight='xxs'
              prefix={
                <Icon
                  name={getStatus(task.status).icon}
                  variant='xs'
                  color='grey05'
                />
              }
            />
          </Box>
        )}

        {task.priority && !isSearch && (
          <Box
            ml='m'
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-start'>
            <Badge
              label={getPriority(task.priority).label}
              variant={getPriority(task.priority).variant}
              borderRadius='xxs'
              paddingHorizontal='xs'
              paddingVertical='xxs'
            />
          </Box>
        )}
        {task.dueDate && (
          <Box
            ml='m'
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-end'>
            <Box marginRight='xs'>
              <Icon name='Calendar' variant='s' color='grey05' />
            </Box>
            <Text letterSpacing={0.2} variant='link' color='textPrimary'>
              {f('monthAndDay', {
                val: localDateFromUTCDate(task.dueDate),
              })}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Box backgroundColor='white' borderRadius='xs'>
      <TouchableOpacity
        onPress={() => {
          if (collapsed && collapsable) {
            setCollapsed((c) => !c);
          } else {
            onPress && onPress(task);
          }
        }}>
        <Box flexDirection='row' alignItems='center'>
          {isEditing && !isSearch && (
            <Box marginRight='xs'>
              <RadioButton
                onPress={() => onPress && onPress(task)}
                isSelected={isSelected || false}
              />
            </Box>
          )}

          {listStyle && Platform.OS === 'web' ? (
            <Box flex={1}>{getTaskCardWeb()}</Box>
          ) : (
            <Box
              flex={1}
              borderWidth={1}
              padding='xs'
              borderRadius='xs'
              borderColor='grey02'>
              {getTaskCard()}
            </Box>
          )}

          {isSearch ? null : onDelete ? (
            <Icon
              name='X2'
              variant='l'
              color='textPrimary'
              onPress={() => onDelete(task)}
              marginLeft='xs'
            />
          ) : null}
        </Box>
      </TouchableOpacity>
      {onDependencyPress &&
      !isSearch &&
      task.dependencyIds &&
      task.dependencyIds.length > 0 ? (
        <TouchableOpacity
          onPress={() =>
            onDependencyPress && onDependencyPress(task?.dependencyIds || [])
          }>
          <Box flexDirection='row' alignItems='center' marginTop='xs'>
            <Icon name='CornerDownRight' variant='s' color='greenSecondary' />
            <Text
              letterSpacing={0.2}
              marginLeft='xs'
              variant='link'
              color='greenSecondary'>
              {t('tasks.dependency.title', {
                count: task.dependencyIds?.length,
              })}
            </Text>
          </Box>
        </TouchableOpacity>
      ) : null}
    </Box>
  );
};

export default TaskCard;
