import React from 'react';

import { Message } from '@graphql/generated';
import { useApolloClient } from '@hooks/useApolloClient';
import { useGetMessageFromCache } from '@hooks/useGetMessageFromCache';
import { ForwardMessageContent } from '@screens/Chats/ForwardMessageContent';

interface ForwardMessageProps {
  messageData: Message;
  onCancelPress?: () => void;
  onForwardCompleted: () => void;
}
export const ForwardMessage: React.FC<ForwardMessageProps> = ({
  messageData,
  onCancelPress,
  onForwardCompleted,
}) => {
  const { client } = useApolloClient();
  const cacheIdentifier = client?.cache.identify(messageData);
  const message = useGetMessageFromCache(cacheIdentifier);
  return (
    <ForwardMessageContent
      messageData={message}
      onCancelPress={onCancelPress}
      onForwardCompleted={onForwardCompleted}
    />
  );
};
