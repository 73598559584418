import React from 'react';

import Contact from '@components/Invite/Contact.web';
import { Box } from '@components/Restyle';
import GlobalSearchList from '@components/Search/GlobalSearchList';
import { User } from '@graphql/generated';
import { Search } from '@root/types';

interface IContactSearch {
  search: string;
  contacts: Search<User>[];
  loading: boolean;
  onPress?: (item: User) => void;
  onMessagePress?: (item: User) => void;
}
const ContactsSearch: React.FC<IContactSearch> = ({
  search,
  contacts,
  loading,
  onPress,
  onMessagePress,
}) => {
  return (
    <GlobalSearchList
      loading={loading}
      search={search}
      onPress={() => {}}
      isViewAllContacts={false}
      lists={[
        {
          title: 'Contacts',
          totalCount: contacts.length,
          data: contacts,
          render: (contact) => {
            const item = {
              ...contact.record,
              firstName: contact.record.firstName ?? undefined,
              lastName: contact.record.lastName ?? undefined,
              displayName: contact.record.fullName ?? '',
              initials: '',
              avatar: contact.record.avatar ?? undefined,
              avatarSize: 'small',
            };
            return (
              <Contact
                style={{ paddingHorizontal: 'l', paddingVertical: 'xs' }}
                key={item.id}
                contact={item}
                filterVal={search}
                onPress={() => {
                  onPress && onPress(item);
                }}
                showMessageIcon
                onMessagePress={() => {
                  onMessagePress && onMessagePress(item);
                }}
              />
            );
          },
          itemSeparatorComponent: (
            <Box
              borderBottomWidth={1}
              borderColor='grey01'
              marginHorizontal='m'
            />
          ),
          sectionHeaderMarginBottom: 'xs',
        },
      ]}
    />
  );
};

export default ContactsSearch;
