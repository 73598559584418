import { useNetInfo } from '@react-native-community/netinfo';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Document,
  ListDraftMessagesDocument,
  Message,
  MessageDetailFragmentDoc,
  MessageProject,
  MessageTask,
  useCreateMessageIndividualMutation,
  useListDraftMessagesQuery,
  ListDraftMessagesQuery,
  MessageDetailFragment,
} from '@graphql/generated';
import { useApolloClient } from '@hooks/useApolloClient';

import useMe from './useMe';

type MessageParams = Pick<
  Message,
  | 'body'
  | 'chatId'
  | 'clientId'
  | 'projectIds'
  | 'replyId'
  | 'replyMessage'
  | 'taskIds'
  | 'publish'
  | 'attachments'
  | 'failedAt'
  | 'checkin'
>;

type ChatProcessingContext = {
  retryFailedMessage: (clientId: string, authorId: string) => void;
  retrySendFailedMessage: (clientId: string, authorId: string) => void;
};

const chatProcessingContext = createContext<ChatProcessingContext | undefined>(
  undefined
);

let messageClientId = '';
export const ChatProcessingProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { Provider } = chatProcessingContext;
  const { client } = useApolloClient();
  const { me } = useMe();
  const { isConnected } = useNetInfo();

  const { data } = useListDraftMessagesQuery();
  const { listDraftMessages } = data || {};

  const [createMessageIndividual] = useCreateMessageIndividualMutation({
    onCompleted: (data) => {
      removeProcessedMessage(data.createMessageIndividual.clientId);
    },
    onError: (_err, options) => {
      const message = options?.variables?.attributes;
      if (!message) return;
      const clientId = message.clientId;
      removeProcessedMessage(clientId);
      handleFailedMessage(message);
    },
  });

  const [messagesInProcess, setMessagesInProcess] = useState<string[]>([]);

  const removeProcessedMessage = (clientId: string) => {
    setMessagesInProcess((prev) => prev.filter((m) => m != clientId));
    client?.cache.updateQuery<ListDraftMessagesQuery>(
      {
        query: ListDraftMessagesDocument,
      },
      (data) => {
        const { listDraftMessages } = data || {};
        if (!listDraftMessages) return;
        const newMessages = listDraftMessages.filter(
          (m) => m.clientId != clientId
        );

        return {
          listDraftMessages: newMessages,
        };
      }
    );
  };

  const handleFailedMessage = (message: MessageParams) => {
    if (!me?.id) {
      console.log('!!!!something went horribly wrong!!!!!');
      return;
    }

    client?.cache.updateFragment<MessageDetailFragment>(
      {
        id: client.cache.identify({
          ...message,
          authorId: me?.id,
          __typename: 'Message',
        }),
        fragment: MessageDetailFragmentDoc,
        fragmentName: 'MessageDetail',
      },
      (data) => {
        return {
          ...data,
          failedAt: new Date().toISOString(),
        };
      }
    );
  };
  const retrySendFailedMessage = (clientId: string, authorId: string) => {
    if (!client || !client.cache) return;
    const message = client.cache.updateFragment<MessageDetailFragment>(
      {
        id: client.cache.identify({
          clientId,
          authorId,
          __typename: 'Message',
        }),
        fragment: MessageDetailFragmentDoc,
        fragmentName: 'MessageDetail',
      },
      (data) => {
        ({
          ...data,
          body:
            __DEV__ && data.body?.toLowerCase().includes('fail this')
              ? data?.body.toLowerCase().replace('fail this', 'succeed this')
              : data?.body,
          createdAt: new Date().toISOString(),
          failedAt: null,
        });
      }
    );
    client.cache.updateQuery<ListDraftMessagesQuery>(
      {
        query: ListDraftMessagesDocument,
      },
      (data) => {
        if (!data && message) {
          processMessageInBackground(message);
        } else {
          const draftMessages = data.listDraftMessages || [];

          // Add message from cache if it doesn't exist in draft messages
          if (!draftMessages.some((m) => m.clientId == clientId)) {
            return {
              listDraftMessages: [...draftMessages, message],
            };
          }

          return {
            listDraftMessages: draftMessages,
          };
        }
      }
    );
  };

  const retryFailedMessage = (clientId: string, authorId: string) => {
    if (!client || !client.cache) return;
    const message = client.cache.updateFragment<MessageDetailFragment>(
      {
        id: client.cache.identify({
          clientId,
          authorId,
          __typename: 'Message',
        }),
        fragment: MessageDetailFragmentDoc,
        fragmentName: 'MessageDetail',
      },
      (data) => ({
        ...data,
        body:
          __DEV__ && data.body?.toLowerCase().includes('fail this')
            ? data?.body.toLowerCase().replace('fail this', 'succeed this')
            : data?.body,
        createdAt: new Date().toISOString(),
        failedAt: null,
      })
    );

    client.cache.updateQuery<ListDraftMessagesQuery>(
      {
        query: ListDraftMessagesDocument,
      },
      (data) => {
        if (!data) return;

        const draftMessages = data.listDraftMessages || [];

        // Add message from cache if it doesn't exist in draft messages
        if (!draftMessages.some((m) => m.clientId == clientId)) {
          return {
            listDraftMessages: [...draftMessages, message],
          };
        }

        return {
          listDraftMessages: draftMessages,
        };
      }
    );
  };

  const processMessageInBackground = (message: MessageParams) => {
    const clientId = message.clientId;
    // TODO Figure out how to handle edits
    if (messagesInProcess.some((m) => m == clientId)) return;

    // Add to queue watcher
    setMessagesInProcess((prev) => [...prev, clientId]);
    if (messageClientId != clientId) {
      if (isConnected) messageClientId = clientId;

      sendDraftMessage(message);
    } else {
      messageClientId = '';
    }
    // Remove to queue watcher
  };

  useEffect(() => {
    if (!(listDraftMessages || []).filter) return;
    const nonFailedDrafts = (listDraftMessages || []).filter(
      (m) => m.failedAt == null
    );
    nonFailedDrafts.forEach((m) => {
      processMessageInBackground(m);
    });
  }, [listDraftMessages]);

  const sendDraftMessage = useCallback(
    (attributes: MessageParams) => {
      const cache = client?.cache;
      if (!cache) return;
      const {
        body,
        chatId,
        clientId,
        attachments,
        publish,
        replyId,
        replyMessage,
        checkin,
      } = attributes;

      const messageTasks = (attachments || []).filter(
        (item) => item.__typename === 'MessageTask'
      ) as MessageTask[];
      const taskIds = messageTasks.map((item) => item.task?.id);

      const messageProjects = (attachments || []).filter(
        (item) => item.__typename === 'MessageProject'
      ) as MessageProject[];
      const projectIds = messageProjects.map((item) => item.project?.id);

      const documents = (attachments || []).filter(
        (item) => item.__typename === 'Document'
      ) as Document[];

      let newCheckin = null;
      if (checkin && checkin.latitude) {
        const { __typename, id: __, ...rest } = checkin;
        newCheckin = { ...rest };
      }
      const params = {
        body,
        chatId,
        clientId,
        projectIds,
        replyId,
        replyMessage,
        taskIds,
        publish,
        checkin: newCheckin,
      };

      delete params.replyMessage;

      createMessageIndividual({
        variables: {
          attributes: {
            ...params,
            publish: documents.length < 1,
          },
        },
      });
    },
    [me?.id, me?.name, me?.avatar?.url]
  );

  return (
    <Provider
      value={
        {
          retryFailedMessage,
          retrySendFailedMessage,
        } as ChatProcessingContext
      }>
      {children}
    </Provider>
  );
};

const useChatProcessing = (): ChatProcessingContext => {
  const context = useContext(chatProcessingContext);
  if (context === undefined) {
    throw new Error('useChatProcessing must be used within a Provider');
  }
  return context;
};

export default useChatProcessing;
