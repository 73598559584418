import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity } from 'react-native';

import Avatar from '@components/Avatar/Avatar';
import { Box, Text } from '@components/Restyle';
import { Project } from '@graphql/generated';
import { ellipsizeByCharCount } from '@utils/ellipsizeByCharCount';

export const Creator = ({
  creator,
  ellipsizeAfter = 32,
  onPress,
  disabled,
  showRole = false,
  showFullName = false,
}: {
  creator: Pick<Project['creator'], 'id' | 'avatar' | 'name' | 'firstName'>;
  ellipsizeAfter?: number;
  onPress?: () => void;
  disabled?: boolean;
  showRole?: boolean;
  showFullName?: boolean;
}) =>
  creator && (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <Box accessibilityLabel='Creator' flexDirection='row' alignItems='center'>
        <Box borderWidth={1} borderColor='white' borderRadius='s'>
          <Avatar
            onPress={onPress}
            id={creator.id}
            avatar={creator.avatar}
            label={creator.name}
            size='medium'
          />
        </Box>

        <Box>
          <Text
            variant='bodySecondary'
            color='textPrimary'
            marginLeft='xxs'
            letterSpacing={0.2}
            numberOfLines={1}
            ellipsizeMode='tail'>
            {Platform.OS !== 'web'
              ? ellipsizeByCharCount(
                  (showFullName ? creator.name : creator.firstName) || '',
                  ellipsizeAfter
                )
              : (showFullName ? creator.name : creator.firstName) || ''}
          </Text>
          {showRole && (
            <Text variant='metadata' color='grey04' marginLeft='xxs'>
              {useTranslation().t('shared:owner')}
            </Text>
          )}
        </Box>
      </Box>
    </TouchableOpacity>
  );
