import { createStackNavigator } from '@react-navigation/stack';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { InferType } from 'yup';

import ColorPicker from '@components/FormModals/ColorPicker';
import IconPicker from '@components/FormModals/IconPicker.web';
import { MemberSelect } from '@components/FormModals/MemberSelect';
import { TeamSelect } from '@components/FormModals/TeamSelect';
import SecondaryHeader from '@components/Headers/SecondaryHeader';
import { WebModal } from '@components/Modals/WebModal.web';
import { ProjectCreateBottomSheet } from '@components/Projects/ProjectCreateBottomSheet';
import ProjectForm from '@components/Projects/ProjectForm';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  ListProjectsDocument,
  Project,
  ProjectMemberAttributes,
  ProjectMemberRole,
  useCreateProjectMutation,
  useUpdateProjectMutation,
} from '@graphql/generated';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { projectFormSchema } from '@schemas/projectFormSchema';
import { ProjectCopySearch } from '@screens/Projects/ProjectCopySearch';
import { ProjectCopySelect } from '@screens/Projects/ProjectCopySelect.web';
import { ProjectLocationSearch } from '@screens/Projects/ProjectLocationSearch';
import { defaultProjectRole } from '@utils/projects';

export type FormValues = InferType<typeof projectFormSchema>;

export type ProjectFormStackParamList = {
  'create-project-form': { projectId?: Project['id'] };
  'project-location-search': undefined;
  'member-select': undefined;
  'team-select': undefined;
  'color-select': undefined;
  'icon-select': undefined;
  'project-copy-select': undefined;
  'project-copy-search': undefined;
  'create-project-modal': undefined;
};

const Stack = createStackNavigator<ProjectFormStackParamList>();

const ProjectFormStack: React.FC<{
  projectId?: string | undefined;
  initialValues: FormValues;
  isShortModal?: boolean;
  externalNavigate?: any;
}> = ({ projectId, initialValues, isShortModal, externalNavigate }) => {
  const navigation = externalNavigate ? externalNavigate : useAppNavigation();
  const { t } = useTranslation();
  const { setIsCreateProjectOpen, setEditProjectId } = useWebDrawer();

  useEffect(() => {
    if (Platform.OS === 'web') {
      const unsubscribe = navigation
        .getParent()
        ?.addListener('drawerItemPress', (e) => {
          navigation.reset({ routes: [{ name: 'tabs' }], index: 0 });
          e.preventDefault();
        });
      return unsubscribe;
    }
  }, [navigation]);

  const [createProject] = useCreateProjectMutation({
    onCompleted: ({ createProject }) => {
      if (!createProject) return;

      // close modal first on mobile
      if (Platform.OS !== 'web') {
        navigation.goBack();
      } else {
        setIsCreateProjectOpen(false);
        setEditProjectId('');
      }

      navigation.navigateToProject(createProject);
    },
    onError: (e) => console.log('err creating project', e),
    refetchQueries: [
      { query: ListProjectsDocument },
      'listProjects',
      'listProjectsRecent',
      'listTeams',
    ],
  });

  const [updateProject] = useUpdateProjectMutation({
    onCompleted: () => {
      if (Platform.OS !== 'web') {
        navigation.goBack();
      } else {
        setIsCreateProjectOpen(false);
        setEditProjectId('');
      }
    },
    onError: (e) => console.log('err updating project', e),
    refetchQueries: [
      { query: ListProjectsDocument },
      'listProjects',
      'listProjectsRecent',
    ],
  });

  const submit = (values: FormValues) => {
    const { teamId, name, location, description, color, icon, users } = values;
    const { address, latitude, longitude } = location || {
      address: undefined,
      latitude: undefined,
      longitude: undefined,
    };

    const members: ProjectMemberAttributes[] = (users || []).map((u) => {
      return {
        userId: u.id,
        role: u.role || defaultProjectRole,
      };
    });
    const projectName = name.trim();
    const attributes = {
      teamId,
      name: projectName,
      address,
      latitude,
      longitude,
      description,
      color,
      icon,
      members,
    };
    if (projectId) {
      updateProject({ variables: { id: projectId, attributes } });
    } else {
      createProject({ variables: { attributes } });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submit(values)}
      validationSchema={projectFormSchema}>
      {({ values }) => {
        const ownerId = values.users.find(
          (u) => u.role === ProjectMemberRole.Owner
        )?.id;
        if (isShortModal) return <ProjectCreateBottomSheet />;

        return (
          <Stack.Navigator
            initialRouteName='create-project-form'
            screenOptions={{
              headerShown: Platform.OS !== 'web',
            }}>
            <Stack.Screen
              name='create-project-form'
              options={() => ({
                header: () => (
                  <SecondaryHeader
                    title={
                      projectId
                        ? t('models:projects.edit.title')
                        : t('models:projects.create.title')
                    }
                    searchable={false}
                  />
                ),
              })}>
              {() => <ProjectForm screenName={projectId ? 'Edit' : 'Create'} />}
            </Stack.Screen>
            <Stack.Screen
              name='project-location-search'
              component={ProjectLocationSearch}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Group
              screenOptions={{
                headerShown: false,
                presentation: 'transparentModal',
                cardShadowEnabled: Platform.OS === 'web',
                cardStyleInterpolator: ({ current: { progress } }) => ({
                  containerStyle: {
                    opacity: progress.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 1],
                      extrapolate: 'clamp',
                    }),
                  },
                  overlayStyle: {
                    opacity: progress.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 0.6],
                      extrapolate: 'clamp',
                    }),
                  },
                }),
              }}>
              <Stack.Screen name='member-select' options={{ title: 'Members' }}>
                {() => (
                  <MemberSelect
                    defaultRole={defaultProjectRole}
                    ownerId={ownerId}
                  />
                )}
              </Stack.Screen>
              <Stack.Screen
                name='team-select'
                component={TeamSelect}
                options={{ title: 'Team' }}
              />
              <Stack.Screen
                name='color-select'
                options={{
                  headerShown: false,
                }}>
                {(props) =>
                  Platform.OS !== 'web' ? (
                    <ColorPicker />
                  ) : (
                    <WebModal visible onClose={props.navigation.goBack}>
                      <ColorPicker />
                    </WebModal>
                  )
                }
              </Stack.Screen>
              <Stack.Screen
                name='icon-select'
                options={{
                  headerShown: false,
                }}>
                {(props) =>
                  Platform.OS !== 'web' ? (
                    <IconPicker />
                  ) : (
                    <WebModal visible onClose={props.navigation.goBack}>
                      <IconPicker />
                    </WebModal>
                  )
                }
              </Stack.Screen>
            </Stack.Group>

            <Stack.Group
              screenOptions={{
                headerShown: false,
                presentation: 'transparentModal',
              }}>
              <Stack.Screen
                name='project-copy-search'
                component={ProjectCopySearch}
                options={{ title: 'Copy' }}
              />
              <Stack.Screen
                name='project-copy-select'
                component={ProjectCopySelect}
                options={{ title: 'Select' }}
              />
            </Stack.Group>
          </Stack.Navigator>
        );
      }}
    </Formik>
  );
};

export default ProjectFormStack;
