import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectModal } from '@components/FormModals/SelectModal';
import useMe from '@hooks/useMe';
import useSelectedMembers from '@hooks/useSelectedMembers';
import useUsers from '@hooks/useUsers';
import { useAppNavigation } from '@navigation/useAppNavigation';

interface MemberSelectProps {
  defaultRole?: string;
  ownerId?: string;
}

export const MemberSelect = ({ defaultRole, ownerId }: MemberSelectProps) => {
  const { t } = useTranslation('models');
  const { users } = useUsers();
  const { me } = useMe();
  const { setFieldValue, setTouched, touched, getFieldProps } =
    useFormikContext();
  const { setSelectedMembers } = useSelectedMembers();
  const { name: fieldName, value } = getFieldProps('users');
  const navigation = useAppNavigation();

  const usersAndMe = [...users];
  const filteredUsers = usersAndMe.filter(
    (u) => u?.id !== ownerId && u?.id !== me.id
  );
  const filteredValues = value.filter(
    (m) => m.id !== ownerId && m.id !== me.id
  );
  const owner = value.find((v) => v.id === ownerId);
  const sortedFilteredUsers = filteredUsers
    .slice()
    .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));

  return (
    <SelectModal
      title='Members'
      list={sortedFilteredUsers}
      showAvatar
      showIcon={false}
      showBackground={false}
      onClose={navigation.goBack}
      setSelected={(ids: any) => {
        const newUsers = [...(ids instanceof Array ? ids : [ids])].map((i) => {
          return {
            ...usersAndMe.find((u) => u.id === i),
            role: value.find((m) => m.id === i)?.role || defaultRole,
          };
        });
        setTouched({ ...touched, [fieldName]: true });
        setFieldValue(fieldName, owner ? [owner, ...newUsers] : [...newUsers]);
        setSelectedMembers(owner ? [owner, ...newUsers] : [...newUsers]);
      }}
      value={filteredValues.map((u) => {
        if (!u) return;
        return u.id;
      })}
      emptyContentMessage={t('resourceNotFound.message', {
        resource: 'member',
        resourcePlural: 'members',
      })}
    />
  );
};
