import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';

interface SearchLoadMoreProps {
  onLoadMore?: () => void;
  loading?: boolean;
}

const SearchLoadMore: React.FC<SearchLoadMoreProps> = ({
  onLoadMore,
  loading,
}) => {
  const { t } = useTranslation();
  return loading ? (
    <Box alignItems='center' minHeight={40} p='s' minWidth={100}>
      <ActivityIndicatorLoading />
    </Box>
  ) : (
    <Box alignItems='center'>
      <TouchableOpacity
        accessibilityLabel={t('shared:loadMore')}
        onPress={onLoadMore}>
        <Box
          marginVertical='s'
          backgroundColor='grey01'
          p='s'
          borderRadius='xs'
          alignSelf='flex-start'
          minWidth={100}
          minHeight={40}>
          <Text variant='labelSmall' textAlign='center'>
            {t('shared:loadMore')}
          </Text>
        </Box>
      </TouchableOpacity>
    </Box>
  );
};

export default SearchLoadMore;
