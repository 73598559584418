import * as ImagePicker from 'expo-image-picker';
import { Alert, Linking, Platform } from 'react-native';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import uuid from 'react-native-uuid';

import { contentType, documentSize } from './fileUtils';
import { LocalFile } from '../graphql/generated';

export const selectImageFromGallery = async (
  selectionLimit?: number
): Promise<LocalFile[] | undefined> => {
  const result = await launchImageLibrary({
    mediaType: 'photo',
    quality: 0.1,
    selectionLimit: selectionLimit || 1,
  });

  if (!result.didCancel) {
    return (
      result?.assets?.map((item): LocalFile => {
        const clientId = uuid.v4().toString();

        if (!item.fileSize && item.uri) {
          item.fileSize = documentSize(item.uri);
        }

        if (!item.type && item.uri?.startsWith('data:')) {
          item.type = contentType(item.uri);
        }

        // NOTE: cdnBaseUrl and path values are empty string to satisfy
        // Image type constraints. These are ignored by local images and
        // included when an Image is returned from the server.
        return {
          __typename: 'LocalFile',
          id: clientId,
          name: item.fileName || uuid.v4().toString(),
          size: item.fileSize,
          clientId,
          url: item.uri || '',
          cdnBaseUrl: '',
          path: '',
          contentType: item.type || 'application/octet-stream',
          isImage: Platform.select({
            default: true,
            web: item.type?.startsWith('image'),
          }),
          isAudio: false,
        };
      }) || []
    );
  }
};

export const selectImageFromCamera = async (
  denyAccessTitle: string,
  denyAccessMessage: string
): Promise<LocalFile[] | undefined> => {
  const { status } = await ImagePicker.requestCameraPermissionsAsync();

  if (status === 'granted') {
    const result = await launchCamera({
      mediaType: 'photo',
    });

    if (!result.didCancel) {
      return (
        result?.assets?.map((item): LocalFile => {
          const clientId = uuid.v4().toString();

          return {
            __typename: 'LocalFile',
            id: clientId,
            name: item.fileName || uuid.v4().toString(),
            size: item.fileSize,
            clientId,
            url: item.uri || '',
            cdnBaseUrl: '',
            path: '',
            contentType: item.type || 'application/octet-stream',
            isImage: true,
            isAudio: false,
          };
        }) || []
      );
    }
  } else {
    Alert.alert(denyAccessTitle, denyAccessMessage, [
      { text: 'Cancel' },
      {
        text: 'Settings',
        onPress: () => Linking.openSettings(),
      },
    ]);
    return;
  }
  return;
};
