import { FlashList } from '@shopify/flash-list';
import React, { useRef } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { AlphaSortSlider } from '@components/AlphaSortSlider/AlphaSortSlider';
import EmptyStateNoProjects from '@components/EmptyState/EmptyStateNoProjects';
import ProjectCard from '@components/ProjectCard/ProjectCard.web';
import { ProjectCardGrid } from '@components/ProjectList/ProjectCardGrid.web';
import { Box, Text } from '@components/Restyle/index';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { Project } from '@graphql/generated';

export interface ProjectListProps {
  expanded: boolean;
  projects: Project[];
  onClick: (project: Project) => void;
  refreshControl?: JSX.Element;
  loading: boolean;
  listEmptyComponent?: () => JSX.Element;
  highlightedValue?: string;
  alphabetize?: boolean;
  fetchMore?: () => void;
  refreshing?: boolean;
  alphabetical?: boolean;
}

const ProjectList = ({
  expanded,
  projects,
  onClick,
  refreshControl,
  loading,
  listEmptyComponent,
  highlightedValue,
  alphabetize,
  fetchMore,
  refreshing,
  alphabetical = true,
}: ProjectListProps) => {
  let currentLetter = '';
  const flashListRef = useRef<FlashList<string>>(null);
  const alphabetizeList: string[] = [];

  const sortProjects = (list: Project[]) => {
    const numsSpecials: Project[] = []; // numbers and ASCII Before A
    const letters: Project[] = []; // letters
    const asterisks: Project[] = []; //ASCII after z

    list.forEach((s) => {
      if (s.name) {
        if (s.name[0].match(/[a-z]/i)) {
          // check first letter
          letters.push(s);
        } else if (s.name[0].charCodeAt(0) < 65) {
          numsSpecials.push(s);
        } else {
          asterisks.push(s);
        }
      }
    });

    return { letters, numsSpecials, asterisks };
  };

  const alphabetizedSectionList = () => {
    const { letters, numsSpecials, asterisks } = sortProjects(projects);

    const formattedAlpha: (Project | string)[] = [];
    const formattedNumSpecial: (Project | string)[] = [];
    const formattedAsterisk: (Project | string)[] = [];

    if (numsSpecials.length > 0) {
      formattedNumSpecial.push('#');
      numsSpecials.forEach((obj) => {
        formattedNumSpecial.push(obj);
      });
    }
    alphabetizeList.push('#');
    letters.forEach((obj) => {
      const key = obj.name.charAt(0).toUpperCase();

      if (formattedAlpha.includes(key)) {
        formattedAlpha.push(obj);
      } else {
        formattedAlpha.push(key);
        formattedAlpha.push(obj);
      }
    });
    for (
      let charCode = 'A'.charCodeAt(0);
      charCode <= 'Z'.charCodeAt(0);
      charCode++
    ) {
      alphabetizeList.push(String.fromCharCode(charCode));
    }

    if (asterisks.length > 0) {
      formattedAsterisk.push('*');
      asterisks.forEach((obj) => {
        formattedAsterisk.push(obj);
      });
    }
    alphabetizeList.push('*');

    return [...formattedNumSpecial, ...formattedAlpha, ...formattedAsterisk];
  };

  const getProjectCard = (item: Project) => {
    return (
      <ProjectCard
        expanded={expanded}
        project={item}
        onClick={() => onClick(item)}
        filterVal={highlightedValue || ''}
        alphabetical={alphabetical}
        alphabetize={alphabetize}
      />
    );
  };
  const getExpandedBox = (item: Project) => {
    if (!alphabetize && !alphabetical) {
      return (
        <Box flex={1} marginRight='m'>
          {getProjectCard(item)}
        </Box>
      );
    }
    return <Box marginHorizontal='m'>{getProjectCard(item)}</Box>;
  };
  const getCondensedBox = (item: Project) => {
    if (!alphabetize && !alphabetical) {
      return getProjectCard(item);
    }
    return (
      <Box>
        <Box marginHorizontal='m' marginVertical='xs'>
          {getProjectCard(item)}
        </Box>
      </Box>
    );
  };
  const renderItem = ({ item }: ListRenderItemInfo<Project>) => {
    if (alphabetize) {
      return getCondensedBox(item);
    } else if (expanded) {
      return getExpandedBox(item);
    } else {
      if (item.name.charAt(0).toUpperCase() !== currentLetter && alphabetical) {
        currentLetter = item.name.charAt(0).toUpperCase();
        return (
          <Box>
            <Box
              justifyContent='center'
              paddingHorizontal='m'
              paddingVertical='xs'
              backgroundColor='grey01'>
              <Text variant='labelEmphasized'>{currentLetter}</Text>
            </Box>
            <Box marginHorizontal='m' marginVertical='xs'>
              {getProjectCard(item)}
            </Box>
          </Box>
        );
      }
      return getCondensedBox(item);
    }
  };

  if (loading && projects?.length < 1) return <ActivityIndicatorLoading />;

  return (
    <>
      {alphabetize && (
        <Box flex={1} position='relative'>
          <FlashList
            refreshing={refreshing}
            ref={flashListRef}
            refreshControl={refreshControl && refreshControl}
            keyboardDismissMode='on-drag'
            onEndReached={fetchMore}
            ListFooterComponent={() => <Box paddingBottom='listFooter' />}
            data={alphabetizedSectionList()}
            renderItem={({ item }) => {
              if (typeof item === 'string') {
                return (
                  <Box
                    backgroundColor='grey01'
                    paddingVertical='xs'
                    paddingLeft='m'>
                    <Text color='textPrimary' variant='labelEmphasized'>
                      {item}
                    </Text>
                  </Box>
                );
              } else {
                return renderItem({ item } as ListRenderItemInfo<Project>);
              }
            }}
            getItemType={(item) => {
              // To achieve better performance, specify the type based on the item
              return typeof item === 'string' ? 'sectionHeader' : 'row';
            }}
            estimatedItemSize={100}
          />
          <AlphaSortSlider
            flashListRef={flashListRef}
            alphabetizeList={alphabetizeList}
          />
        </Box>
      )}
      {!expanded && (
        <Box flex={1} alignContent='center' justifyContent='center'>
          <FlatList
            refreshing={refreshing}
            contentContainerStyle={!projects.length && { flex: 1 }}
            ListEmptyComponent={listEmptyComponent || EmptyStateNoProjects}
            keyboardDismissMode='on-drag'
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            data={projects}
            renderItem={renderItem}
            refreshControl={refreshControl && refreshControl}
            keyExtractor={(item) => item.id.toString()}
            onEndReached={fetchMore}
            ListFooterComponent={() => {
              if (projects?.length < 1) return null;

              return (
                <Box
                  height={1}
                  backgroundColor='grey02'
                  marginHorizontal={
                    !alphabetize && !alphabetical ? undefined : 'm'
                  }
                  marginBottom='listFooter'
                />
              );
            }}
          />
        </Box>
      )}
      {expanded && (
        <ProjectCardGrid numColumns={4} list={projects} onPress={onClick} />
      )}
    </>
  );
};

export default ProjectList;
