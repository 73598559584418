import React, { useState } from 'react';

import { Box, Text } from '@components/Restyle';

export type PopupHiddenSkills1Props = {
  showHiddenSkillsFirstIndex: number;
  skills: [];
};

export const PopupHiddenSkills: React.FC<PopupHiddenSkills1Props> = ({
  showHiddenSkillsFirstIndex,
  skills,
}) => {
  const skillsWidth = 234;
  const [skillWidth, setSkillWidth] = useState(0);

  const getHiddenSkills = () => {
    if (showHiddenSkillsFirstIndex === 0) return;
    const hiddenSkills = [];
    const hiddenSkillsLength = skills.length - showHiddenSkillsFirstIndex;
    const moreThanThreeSkill =
      hiddenSkillsLength > 3 ? (
        <Box
          marginBottom='xs'
          padding='xxs'
          borderRadius='xxs'
          borderColor='grey01'
          backgroundColor='white'
          onLayout={(e) => {
            setSkillWidth(e.nativeEvent.layout.width);
          }}>
          <Text variant='bodyTaskName' color='textPrimary' numberOfLines={1}>
            {'+' + (hiddenSkillsLength - 3)}
          </Text>
        </Box>
      ) : undefined;
    for (let i = showHiddenSkillsFirstIndex; i < skills.length; i++) {
      if (i < showHiddenSkillsFirstIndex + 3) {
        hiddenSkills.push(
          <Box
            maxWidth={
              i < showHiddenSkillsFirstIndex + 2
                ? skillsWidth - 24
                : skillsWidth - 24 - skillWidth - 8 - 1
            }
            marginRight='xs'
            marginBottom='xs'
            padding='xxs'
            borderRadius='xxs'
            borderColor='grey01'
            backgroundColor='white'>
            <Text variant='bodyTaskName' color='textPrimary' numberOfLines={1}>
              {skills[i].name}
            </Text>
          </Box>
        );
      } else if (i === showHiddenSkillsFirstIndex + 3) {
        hiddenSkills.push(moreThanThreeSkill);
      }
    }
    return hiddenSkills;
  };

  return (
    <Box
      flex={1}
      width={skillsWidth}
      flexDirection='row'
      borderRadius='xs'
      backgroundColor='grey05'
      padding='s'
      paddingBottom='xxs'
      flexWrap='wrap'>
      {getHiddenSkills()}
    </Box>
  );
};
