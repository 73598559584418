import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { useGetChatQuery } from '@graphql/generated';
import useMe from '@hooks/useMe';
import { GroupChatMembersContent } from '@screens/Chats/GroupChatMembersContent';
import AddMembersWeb from '@screens/Members/AddMembersModal.web';

interface groupChatProps {
  chatId: string;
  showAddMembersModal: boolean;
  onClose: () => void;
}

export const GroupChatMembers: React.FC<groupChatProps> = ({
  chatId,
  showAddMembersModal,
  onClose,
}) => {
  const { t } = useTranslation();
  const { me } = useMe();

  const [showAddNewMembersModal, setShowAddNewMemberModal] = useState(false);

  const { data } = useGetChatQuery({
    variables: { id: chatId },
    skip: !chatId,
  });

  const { getChat: chat } = data || { chat: undefined };
  const ownerId = chat?.owner?.id;
  const isOwner = me?.id === ownerId;

  const onNewMemberClose = () => {
    setShowAddNewMemberModal(false);
  };

  return (
    <WebModal
      onClose={onClose}
      visible={showAddMembersModal}
      width={504}
      title='Members'
      height={512}>
      <>
        {isOwner && (
          <Box flexDirection='row' alignItems='center' mx='m' my='m' mt='l'>
            <TouchableOpacity onPress={() => setShowAddNewMemberModal(true)}>
              <Box
                accessibilityLabel='Add Members'
                flexDirection='row'
                alignItems='center'>
                <Icon name='UserPlus' variant='l' backgroundColor='grey01' />
                <Text variant='labelEmphasized' ml='xs'>
                  {t('shared:addNewMembers')}
                </Text>
              </Box>
            </TouchableOpacity>
          </Box>
        )}
        <GroupChatMembersContent chatId={chatId} />
      </>

      {showAddNewMembersModal && (
        <AddMembersWeb
          onNewMemberClose={onNewMemberClose}
          showAddNewMembersModal={showAddNewMembersModal}
          chatId={chatId}
        />
      )}
    </WebModal>
  );
};
