import { FileChecksum } from "@rails/activestorage/src/file_checksum";

const calculateChecksum = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error);
        return;
      }

      resolve(checksum);
    });
  });
};

const getFileMetadata = (
  file: File
): Promise<{
  checksum: string;
  filename: string;
  content_type: string;
  byte_size: number;
}> => {
  return new Promise((resolve, reject) => {
    calculateChecksum(file).then((checksum: string) => {
      const { name, type, size } = file;

      if (!checksum) reject(`Checksum for ${name} failed`);

      resolve({
        checksum,
        filename: name,
        content_type: type,
        byte_size: size,
      });
    });
  });
};

export { calculateChecksum, getFileMetadata };
