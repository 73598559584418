import React from "react";
import { Image } from "react-native";

import { Team } from "../../graphql/generated";
import { Box, Text } from "../Restyle";
import Icon from "../shared/Icon/Icon";

interface TeamAvatarProps {
  team: Team;
}

const TeamAvatar: React.FC<TeamAvatarProps> = ({ team }) => {
  const { avatar, name, personal } = team;

  return (
    <>
      {avatar ? (
        <Image
          source={{ uri: avatar }}
          style={{
            width: 40,
            height: 40,
            borderRadius: 8,
          }}
        />
      ) : (
        <Box
          width={40}
          height={40}
          borderRadius="xs"
          backgroundColor="greenSecondary"
          alignItems="center"
          justifyContent="center"
        >
          {personal ? (
            <Icon name="Office" color="white" variant="l" />
          ) : (
            <Text color="white" variant="labelLarge">
              {name.charAt(0)}
            </Text>
          )}
        </Box>
      )}
    </>
  );
};

export default TeamAvatar;
