import React, { createContext, useContext, useState } from 'react';

import { ProjectMemberRole } from '@graphql/generated';
import { PickEnum } from '@root/types';

export type FilterableProjectRolesOptions = PickEnum<
  ProjectMemberRole,
  | ProjectMemberRole.Owner
  | ProjectMemberRole.Admin
  | ProjectMemberRole.Editor
  | ProjectMemberRole.Viewer
>;

type projectsFilterContext = {
  roleFilter: FilterableProjectRolesOptions | undefined;
  setRoleFilter: React.Dispatch<
    React.SetStateAction<FilterableProjectRolesOptions | undefined>
  >;
  isMapview: boolean;
  setMapview: React.Dispatch<boolean>;
};

const projectsFilterContext = createContext<projectsFilterContext | undefined>(
  undefined
);

export const ProjectsFilterProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { Provider } = projectsFilterContext;

  const [roleFilter, setRoleFilter] = useState<FilterableProjectRolesOptions>();
  const [isMapview, setMapview] = useState(false);

  return (
    <Provider
      value={{
        roleFilter,
        setRoleFilter,
        isMapview,
        setMapview,
      }}>
      {children}
    </Provider>
  );
};

export const useProjectsFilter = (): projectsFilterContext => {
  const context = useContext(projectsFilterContext);
  if (context === undefined) {
    throw new Error('useProjectsFilter must be used within a Provider');
  }
  return context;
};
