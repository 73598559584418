import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '../Restyle';

const EmptyStateNoActiveTasks: React.FC = () => {
  const { t } = useTranslation('models');

  return (
    <Box p='s'>
      <Text color='grey04' textAlign='center'>
        {t('models:tasks.filter.activeTasksEmpty')}
      </Text>
    </Box>
  );
};

export default EmptyStateNoActiveTasks;
