const pad = (num: number): string => {
  return ("0" + num).slice(-2);
};

export const formatToMMSS = (secs: number): string => {
  let minutes = Math.floor(secs / 60);

  secs = secs % 60;
  minutes = minutes % 60;

  return pad(minutes) + ":" + pad(secs);
};
