import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import Avatar, { AvatarProps } from '@components/Avatar/Avatar';
import MemberListSummary from '@components/MemberListSummary/MemberListSummary';
import { BoxHover } from '@components/Restyle/BoxHover.web';
import { Box, Text } from '@components/Restyle/index';
import Icon from '@components/shared/Icon/Icon';
import { Project, ProjectMember } from '@graphql/generated';
import useNewColors from '@hooks/useNewColors';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { useAppNavigation } from '@navigation/useAppNavigation';
import Images from '@themes/images';
import theme from '@themes/theme';

interface ProjectCardProps {
  filterVal?: string;
  expanded?: boolean;
  project: Project;
  onClick: (project: Project) => void;
  onDelete?: () => void;
  onUnPinClick?: () => void;
  disabled?: boolean;
  suffixText?: string;
  width?: number;
  alphabetize?: boolean;
  alphabetical?: boolean;
  isRecent?: boolean;
  isGlobalSearch?: boolean;
}

const ProjectCard = ({
  filterVal = '',
  expanded = true,
  project,
  onClick,
  onDelete,
  onUnPinClick,
  disabled,
  suffixText,
  width,
  alphabetize,
  alphabetical,
  isRecent,
  isGlobalSearch,
}: ProjectCardProps) => {
  const navigation = useAppNavigation();
  const { color, icon, name, creator, archivedAt }: Project = project;
  const pin = false;
  const { t } = useTranslation('shared');
  const { selectedProject, setSelectedProject } = useWebDrawerNavigator();

  const { getMappedColor } = useNewColors();

  const [teamAndOwnerWidth, setTeamAndOwnerWidth] = useState(0);
  const [teamWidth, setTeamWidth] = useState(0);
  const [ownerWidth, setOwnerWidth] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredTitle, setIsHoveredTitle] = useState(false);
  const [isPressing, setIsPressing] = useState(false);
  const mainWidth = Dimensions.get('window').width;
  const maxTeamWidth =
    teamWidth > 0 || ownerWidth > 0
      ? ((teamAndOwnerWidth - theme.spacing.xs) * teamWidth) /
        (teamWidth + ownerWidth)
      : teamAndOwnerWidth / 2;
  const maxOwnerWidth =
    teamWidth > 0 || ownerWidth > 0
      ? ((teamAndOwnerWidth - theme.spacing.xs) * ownerWidth) /
        (teamWidth + ownerWidth)
      : teamAndOwnerWidth / 2;
  const minWidth =
    ((Dimensions.get('window').width <= 1280 ? 639 : 742) -
      theme.spacing.l * 2) /
      4 -
    theme.spacing.m;

  const bgHovered = isHovered ? 'grey01' : 'white';

  const getNameAndAddressContainer = () => {
    const bgHovered = isHovered ? undefined : 'grey01';
    return (
      <Box>
        <Box flexDirection='row' alignItems='center'>
          <Box
            alignItems='center'
            justifyContent='center'
            marginRight='xs'
            style={{
              borderRadius: 5,
              padding: 6,
              backgroundColor: archivedAt
                ? theme.colors.grey03
                : getMappedColor(color),
            }}>
            <Icon
              name={icon ? (icon as keyof typeof Images) : 'BuildingHomeHouse'}
              color='white'
              variant='m'
            />
          </Box>
          {(mainWidth >= 1440 || (mainWidth <= 1280 && isRecent)) && (
            <Box
              flex={1}
              flexDirection='row'
              alignItems='center'
              onMouseEnter={() => setIsHoveredTitle(true)}
              onMouseLeave={() => setIsHoveredTitle(false)}>
              <Text
                numberOfLines={2}
                variant='bodyTaskName'
                ellipsizeMode='tail'
                textDecorationLine={isHoveredTitle ? 'underline' : undefined}>
                <Highlighter
                  autoEscape
                  highlightStyle={{
                    color: theme.colors.greenSecondary,
                    backgroundColor: theme.colors.background,
                  }}
                  searchWords={filterVal?.split(' ') || ''}
                  textToHighlight={name}
                />
              </Text>
            </Box>
          )}
          <Box
            flex={
              mainWidth < 1440 && (mainWidth > 1280 || !isRecent)
                ? 1
                : undefined
            }
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-end'>
            {pin && (
              <Icon
                name='Pin'
                color='grey03'
                variant='xxs'
                marginHorizontal='xxs'
                onPress={() => {
                  onUnPinClick && onUnPinClick();
                }}
              />
            )}
          </Box>
        </Box>
        {mainWidth < 1440 && (mainWidth > 1280 || !isRecent) && (
          <Box
            flex={1}
            flexDirection='row'
            alignItems='center'
            marginTop='m'
            onMouseEnter={() => setIsHoveredTitle(true)}
            onMouseLeave={() => setIsHoveredTitle(false)}>
            <Text
              numberOfLines={1}
              variant='bodyTaskName'
              ellipsizeMode='tail'
              textDecorationLine={isHoveredTitle ? 'underline' : undefined}>
              <Highlighter
                autoEscape
                highlightStyle={{
                  color: theme.colors.greenSecondary,
                  backgroundColor: theme.colors.background,
                }}
                searchWords={filterVal?.split(' ') || ''}
                textToHighlight={name}
              />
            </Text>
          </Box>
        )}
        <Box
          flexDirection={
            mainWidth < 1440 && (mainWidth > 1280 || !isRecent)
              ? 'column'
              : 'row'
          }
          alignItems='flex-start'
          marginTop='l'
          onLayout={(e) => setTeamAndOwnerWidth(e.nativeEvent.layout.width)}>
          <Box
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-start'
            backgroundColor={bgHovered}
            padding='xxs'
            borderRadius='xxs'
            marginRight='xs'
            maxWidth={
              mainWidth < 1440 && (mainWidth > 1280 || !isRecent)
                ? teamAndOwnerWidth
                : maxTeamWidth
            }
            onLayout={(e) => setTeamWidth(e.nativeEvent.layout.width)}>
            <Icon
              marginRight='xs'
              name={project.team?.personal ? 'Office' : 'TT24'}
              variant='l'
              color={project.team?.personal ? 'white' : 'textPrimary'}
              backgroundColor={
                project.team?.personal ? 'greenSecondary' : 'textPrimary'
              }
              padding={project.team?.personal ? 'xxs' : 'none'}
              width={project.team?.personal ? 16 : 24}
              height={project.team?.personal ? 16 : 24}
              borderRadius='xxs'
            />
            <Text numberOfLines={1} variant='metadata' ellipsizeMode='tail'>
              {project.team?.name}
            </Text>
          </Box>
          <Box
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-start'
            backgroundColor={bgHovered}
            padding='xxs'
            borderRadius='xxs'
            marginTop={
              mainWidth < 1440 && (mainWidth > 1280 || !isRecent)
                ? 'xxs'
                : undefined
            }
            maxWidth={
              mainWidth < 1440 && (mainWidth > 1280 || !isRecent)
                ? teamAndOwnerWidth
                : maxOwnerWidth
            }
            onLayout={(e) => setOwnerWidth(e.nativeEvent.layout.width)}>
            <Box borderWidth={1} borderColor='white' borderRadius='s'>
              <Avatar
                id={creator.id}
                avatar={creator.avatar}
                label={creator.name}
                size='xs'
              />
            </Box>
            <Text
              variant='metadata'
              color='textPrimary'
              marginLeft='xs'
              letterSpacing={0.2}
              numberOfLines={1}
              ellipsizeMode='tail'>
              {creator.name || ''}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  };

  const getNameAndAddressContainerCondensed = () => (
    <Box flexDirection='row' alignItems='center' height={48}>
      <Box
        alignItems='center'
        justifyContent='center'
        marginRight='xs'
        style={{
          borderRadius: 5,
          padding: 6,
          backgroundColor: archivedAt
            ? theme.colors.grey03
            : getMappedColor(color),
        }}>
        <Icon
          name={icon ? (icon as keyof typeof Images) : 'BuildingHomeHouse'}
          color='white'
          variant='m'
        />
      </Box>
      <Box
        flex={mainWidth < 1025 ? 1 : 2}
        flexDirection='row'
        alignItems='center'
        paddingRight='m'>
        <Text
          numberOfLines={1}
          variant={isGlobalSearch ? 'webBodySecondary' : 'labelTableBodyText'}
          ellipsizeMode='tail'
          letterSpacing={0.2}
          textDecorationLine={isHoveredTitle ? 'underline' : undefined}
          onMouseEnter={() => setIsHoveredTitle(true)}
          onMouseLeave={() => setIsHoveredTitle(false)}>
          <Highlighter
            autoEscape
            highlightStyle={{
              color: isGlobalSearch
                ? theme.colors.textPrimary
                : theme.colors.greenSecondary,
              backgroundColor: isGlobalSearch
                ? theme.colors.yellowBright
                : theme.colors.background,
            }}
            searchWords={filterVal?.split(' ') || ''}
            textToHighlight={name}
          />
        </Text>
        {pin && (
          <Icon
            name='Pin'
            color='grey03'
            variant='xxs'
            marginHorizontal='xs'
            onPress={() => {
              onUnPinClick && onUnPinClick();
            }}
          />
        )}
      </Box>
      {!isGlobalSearch && creator && (
        <Box
          flex={1}
          flexDirection='row'
          alignItems='flex-start'
          paddingRight='m'>
          <Box borderWidth={1} borderColor='white' borderRadius='s'>
            <Avatar
              id={creator.id}
              avatar={creator.avatar}
              label={creator.name}
              size='small'
            />
          </Box>
          <Box flex={1} alignItems='flex-start' justifyContent='center'>
            <Text
              variant='labelTableBodyText'
              marginLeft='xs'
              letterSpacing={0.2}
              numberOfLines={1}
              ellipsizeMode='tail'>
              {creator.name || ''}
            </Text>
            <Text variant='labelRequired' color='grey04' marginLeft='xs'>
              {t('owner')}
            </Text>
          </Box>
        </Box>
      )}
      {isGlobalSearch && (
        <Box
          flex={1}
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-end'>
          <Text
            marginRight='xs'
            numberOfLines={1}
            ellipsizeMode='tail'
            variant='webBodySecondary'>
            {project.team?.name}
          </Text>
          <Icon
            name={project.team?.personal ? 'Office' : 'TT24'}
            variant='l'
            color={project.team?.personal ? 'white' : 'textPrimary'}
            backgroundColor={
              project.team?.personal ? 'greenSecondary' : 'textPrimary'
            }
            padding={project.team?.personal ? 'xxs' : 'none'}
            width={project.team?.personal ? 16 : 24}
            height={project.team?.personal ? 16 : 24}
            borderRadius='xxs'
          />
        </Box>
      )}
      {!isGlobalSearch && (
        <>
          <Box
            flex={1}
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-start'
            padding='xxs'
            paddingRight='m'>
            <Icon
              marginRight='xs'
              name={project.team?.personal ? 'Office' : 'TT24'}
              variant='l'
              color={project.team?.personal ? 'white' : 'textPrimary'}
              backgroundColor={
                project.team?.personal ? 'greenSecondary' : 'textPrimary'
              }
              padding={project.team?.personal ? 'xxs' : 'none'}
              width={project.team?.personal ? 16 : 24}
              height={project.team?.personal ? 16 : 24}
              borderRadius='xxs'
            />
            <Text
              numberOfLines={1}
              ellipsizeMode='tail'
              variant='labelTableBodyText'
              letterSpacing={0.2}>
              {project.team?.name}
            </Text>
          </Box>
          <Box
            flex={0.7}
            flexDirection='row'
            justifyContent='flex-end'
            paddingRight='m'>
            <MemberListSummary
              avatars={userAvatars(project.members)}
              onPress={() => navigateToProjectMembers()}
              maxAvatars={3}
            />
          </Box>
        </>
      )}
    </Box>
  );

  const userAvatars = (members: ProjectMember[]): AvatarProps[] => {
    return (
      members?.map((u) => {
        const { user } = u;
        const { name, id, avatar } = user;
        return {
          id: id,
          label: name,
          avatar: avatar,
          size: 'memberList',
        };
      }) || []
    );
  };

  const navigateToProjectMembers = () => {
    navigation.navigateToProject(project);
    setTimeout(() => {
      navigation.navigate('members', { projectId: project.id });
    }, 1500);
  };

  const expandedBox = () => {
    const borderColorHovered = isHovered ? 'grey01' : 'grey02';
    return (
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        width={width}
        borderColor={borderColorHovered}
        borderWidth={1}
        backgroundColor={bgHovered}
        borderRadius='xs'
        padding='m'
        minWidth={minWidth}>
        {getNameAndAddressContainer()}
      </Box>
    );
  };
  const condensedBox = () => {
    return (
      <BoxHover
        noItemSeparatorComponent={isGlobalSearch}
        isHovered={isHovered}
        isPressing={isPressing}
        isSelected={selectedProject?.id === project.id}
        alphabetize={alphabetize}
        alphabetical={alphabetical}
        isProject={true}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <Box
          marginLeft={isGlobalSearch ? undefined : 'm'}
          paddingHorizontal={isGlobalSearch ? 'l' : undefined}
          flex={1}>
          {getNameAndAddressContainerCondensed()}
        </Box>
        <Box flexDirection='row' alignItems='center' justifyContent='center'>
          {suffixText && (
            <Text variant='buttonLabel' color='black' mr='xs'>
              {suffixText}
            </Text>
          )}

          {onDelete && (
            <Icon
              name='X2'
              variant='l'
              color='black'
              onPress={onDelete}
              accessibilityLabel='Delete'
            />
          )}
        </Box>
      </BoxHover>
    );
  };
  return (
    <TouchableOpacity
      onPress={() => {
        setTimeout(() => {
          onClick && onClick(project);
        }, 500);
      }}
      onPressIn={() => {
        setIsPressing(true);
      }}
      onPressOut={() => {
        setIsPressing(false);
        setSelectedProject(project);
      }}
      disabled={disabled}
      accessibilityLabel='Project'>
      {expanded ? expandedBox() : condensedBox()}
    </TouchableOpacity>
  );
};

export default ProjectCard;
