import { useNavigation } from '@react-navigation/native';
import React, { ComponentProps } from 'react';

import { DateSelectPicker } from '@components/FormModals/DateSelect/DateSelectPicker';
import { WebModal } from '@components/Modals/WebModal.web';

import type { DateSelect as DateSelectNative } from '@components/FormModals/DateSelect';

type SelectModalProps = ComponentProps<typeof DateSelectNative>;

export const DateSelect: React.FC<SelectModalProps> = () => {
  const navigation = useNavigation();

  return (
    <WebModal visible width={504} height={512} onClose={navigation.goBack}>
      <DateSelectPicker onClose={navigation.goBack} />
    </WebModal>
  );
};
