/* eslint-disable no-magic-numbers */
import React, { ComponentProps, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import RestyleIcon from './RestyleIcon';
import { Box } from '../../Restyle';

/*
  Example pre-defined size:
    <Icon name="Activity1" variant="s" color="magentaDark" />

  Example custom size:
    <Icon name="Activity1" color="magentaDark" width={30} height={30} />
*/
type Props = ComponentProps<typeof RestyleIcon> &
  Pick<
    ComponentProps<typeof TouchableOpacity>,
    'onPress' | 'onPressIn' | 'disabled' | 'onPressOut' | 'delayPressOut'
  > & {
    accessibilityLabel?: string;
  };

export const WebIcon = ({
  onPress,
  onPressIn,
  disabled,
  accessibilityLabel,
  onPressOut,
  delayPressOut,
  testId,
  ...rest
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const onHoverIn = () => setIsHovered(true);
  const onHoverOut = () => setIsHovered(false);

  return (
    <Box
      pointerEvents={onPress ? 'auto' : 'none'}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      backgroundColor={isHovered ? 'grey01' : undefined}
      borderRadius='xs'
      padding='xxs'>
      <TouchableOpacity
        testID={testId}
        disabled={disabled}
        onPressIn={onPressIn}
        onPress={(e) => {
          setIsHovered(false);
          onPress && onPress(e);
        }}
        onPressOut={onPressOut ? onPressOut : undefined}
        delayPressOut={delayPressOut ? delayPressOut : 0}
        activeOpacity={(onPress || onPressIn) && !onPressOut ? undefined : 1}
        accessibilityLabel={accessibilityLabel}>
        <RestyleIcon {...rest} />
      </TouchableOpacity>
    </Box>
  );
};
