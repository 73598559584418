import { ComponentProps, ReactNode, useState } from 'react';
import { PanGestureHandler } from 'react-native-gesture-handler';

import { Hoverable } from '@components/Hoverable';
import { Box } from '@components/Restyle';
import theme from '@themes/theme';

const thumbHeightPct = 25;

export const Scrollbar = ({
  HoverableProps,
  inverted,
  children,
}: {
  HoverableProps: Omit<ComponentProps<typeof Hoverable>, 'children'>;
  inverted?: boolean;
  children: (data: {
    setContentOffset: (offset: { x: number; y: number }) => void;
    setContentSize: (size: number) => void;
    scrollTo?: number;
  }) => ReactNode;
}) => {
  const [deltaY, setDeltaY] = useState<number | undefined>(undefined);
  const [contentOffset, setContentOffset] = useState({ x: 0, y: 0 });
  const [scrollStartOffset, setScrollStartOffset] = useState<
    { x: number; y: number } | undefined
  >();
  const [contentSize, setContentSize] = useState(0);
  const [scrollViewHeight, setScrollViewHeight] = useState(0);

  // Calculate the new top position for the scrollbar
  const scrollPerc =
    (contentOffset.y / (contentSize - scrollViewHeight)) *
    (100 - thumbHeightPct);

  // If dragging, determine a new scroll position for the content
  const scrollTo =
    (!!deltaY &&
      !!scrollStartOffset &&
      ((deltaY / scrollViewHeight) * contentSize +
        (scrollStartOffset?.y || 0) * (inverted ? -1 : 1)) *
        (inverted ? -1 : 1)) ||
    undefined;

  const scrollbarPosition = `${Number(scrollPerc || 0).toFixed(0)}%`;

  return (
    <Hoverable {...HoverableProps}>
      {({ hover }) => {
        return (
          <>
            {children({
              setContentOffset,
              setContentSize,
              scrollTo,
            })}

            <Box
              width={theme.spacing.m}
              height='100%'
              position='absolute'
              right={0}
              onLayout={({
                nativeEvent: {
                  layout: { height },
                },
              }) => setScrollViewHeight(height)}
              py='s'>
              <Box
                height='100%'
                flexDirection='row'
                justifyContent='center'
                style={[
                  {
                    // Using opacity instead of visible prop to allow for transition.
                    opacity: hover && contentSize > scrollViewHeight ? 1 : 0,
                  },
                  {
                    // TransitionDuration is not officially supported by React Native.
                    // TransitionDuration is valid for web though.
                    // We have to box and unbox to force the types to be correct.
                    transitionDuration: '.25s',
                  } as unknown as ComponentProps<typeof Box>['style'],
                ]}>
                <PanGestureHandler
                  onActivated={() => setScrollStartOffset({ ...contentOffset })}
                  onEnded={() => {
                    setDeltaY(undefined);
                    setScrollStartOffset(undefined);
                  }}
                  onGestureEvent={({ nativeEvent: { translationY } }) => {
                    setDeltaY(translationY);
                  }}
                  hitSlop={{
                    horizontal: 10,
                    vertical: 10,
                  }}>
                  <Box
                    style={[
                      {
                        position: 'absolute',
                        top: inverted ? undefined : scrollbarPosition,
                        bottom: inverted ? scrollbarPosition : undefined,
                        width: theme.spacing.xxs,
                        borderRadius: theme.spacing.xs,
                        backgroundColor: theme.colors.grey02,
                        height: `${thumbHeightPct}%`,
                      },
                      {
                        // TransitionDuration is not officially supported by React Native.
                        // TransitionDuration is valid for web though.
                        // We have to box and unbox to force the types to be correct.
                        transitionDuration: '.5s',
                      } as unknown as ComponentProps<typeof Box>['style'],
                    ]}
                  />
                </PanGestureHandler>
              </Box>
            </Box>
          </>
        );
      }}
    </Hoverable>
  );
};
