import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { Alert } from '@components/Alert';
import { ModalItemType } from '@components/Modals/Modal';
import { MenuItem } from '@components/shared/Popup/PopupItem';
import { ChatDrawerScreen } from '@components/Web/Drawer/WebDrawerContext';
import {
  ListProjectsDocument,
  ListTasksDocument,
  ProjectMemberRole,
  TeamMemberRole,
  Task,
  TaskMemberRole,
  useCreateChatMutation,
  useDeleteTaskMutation,
  useLeaveTaskMutation,
  useListChatsQuery,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import useSelectedMembers from '@hooks/useSelectedMembers';
import useTask from '@hooks/useTask';
import { useAppNavigation } from '@navigation/useAppNavigation';

export const useTaskDetailMenuActions = (
  task?: Pick<
    Task,
    | 'id'
    | 'userIds'
    | 'currentUserProjectRole'
    | 'currentUserProjectTeamRole'
    | 'currentUserRole'
    | 'creator'
    | 'projectId'
  >
) => {
  const { me } = useMe();
  const navigation = useAppNavigation();
  const { setCurrentTask } = useTask();
  const { setSelectedMembers } = useSelectedMembers();
  const { setShowDeleteTaskModal } = useTask();

  const { data: listChatsData } = useListChatsQuery({
    fetchPolicy: 'cache-only',
  });
  const {
    id: taskId,
    userIds = [],
    currentUserProjectRole,
    currentUserProjectTeamRole,
    currentUserRole,
    creator,
    projectId,
  } = task || { userIds: [] };
  const filteredUserIds = userIds?.filter((id) => id !== me?.id);

  const [createChat] = useCreateChatMutation({
    onCompleted: (data) => {
      const { createChat: createChatData } = data;
      navigation.navigateToChatDrawer({
        screen: ChatDrawerScreen.details,
        chatId: createChatData.id,
      });
    },
    refetchQueries: ['listChats'],
  });

  const onPressAnnouncement = () => {
    if (!filteredUserIds || filteredUserIds.length < 1) return;

    // check for existing group chat with these users
    // if exists, nav to the group chat, else create group chat
    const { listChats: currentChats } = listChatsData || { listChats: [] };

    const currentUserIds = currentChats.map((chat) => {
      return {
        id: chat.id,
        userIds: chat.users.map((u) => u.id),
        chat,
      };
    });

    const existingChatWithSelectedIds = currentUserIds.find((c) => {
      return (
        c.userIds.length === userIds?.length &&
        c.userIds.every((id) => !!userIds.includes(id))
      );
    });

    if (existingChatWithSelectedIds?.id) {
      if (Platform.OS === 'web') {
        navigation.navigateToChatDrawer({
          screen: ChatDrawerScreen.details,
          activeChat: existingChatWithSelectedIds.chat,
        });
      } else {
        navigation.navigateToChatDrawer({
          screen: ChatDrawerScreen.details,
          chatId: existingChatWithSelectedIds.id,
        });
      }
    } else {
      createChat({
        variables: {
          attributes: {
            name: '',
            userIds: filteredUserIds, //do not add yourself to the mutation
            groupChat: filteredUserIds.length > 1,
          },
        },
      });
    }
  };

  const [leaveTask] = useLeaveTaskMutation({
    refetchQueries: [
      { query: ListTasksDocument },
      'listTasks',
      { query: ListProjectsDocument },
    ],
  });

  const leaveThisTask = () => {
    taskId &&
      leaveTask({
        variables: {
          id: taskId,
          userId: me?.id || '',
        },
      });
  };
  const { t } = useTranslation();
  const creatorLeaveTaskAlert = () => {
    Alert.alert(
      t('models:teams.leave.title'),
      t('models:tasks.creatorLeaveMessage'),
      [
        { text: t('shared:cancel') },
        {
          text: t('shared:assign'),

          onPress: () => navigation.navigate('assign-new-creator', { taskId }),
        },
      ]
    );
  };
  const assigneeLeaveTaskAlert = () => {
    Alert.alert(
      t('models:teams.leave.titleTask'),
      t('models:tasks.assigneeLeaveMessage'),
      [
        { text: t('shared:cancel') },
        {
          text: t('shared:LeaveTask'),

          onPress: () => leaveThisTask(),
        },
      ]
    );
  };
  const [deleteTask] = useDeleteTaskMutation({
    onCompleted: () => navigation.goBack(),
    refetchQueries: [
      { query: ListTasksDocument, variables: { id: projectId } },
      'listTasks',
    ],
  });

  const deleteAlert = () => {
    if (Platform.OS === 'web') {
      setShowDeleteTaskModal(true);
      return;
    }
    Alert.alert(t('models:deleteTask.title'), t('models:deleteTask.message'), [
      {
        text: 'Cancel',
      },
      {
        text: t('models:deleteTask.title'),
        style: 'destructive',
        onPress: () => {
          deleteTask({
            variables: {
              id: taskId,
            },
          });
        },
      },
    ]);
  };

  const fullNavItems: (
    | (ModalItemType & {
        allowedRoles?: (TaskMemberRole | ProjectMemberRole | TeamMemberRole)[];
      })
    | (MenuItem & {
        allowedRoles?: (TaskMemberRole | ProjectMemberRole | TeamMemberRole)[];
      })
  )[] = [
    ...(filteredUserIds && filteredUserIds.length
      ? ([
          {
            iconName: 'Chat',
            title: 'Announcement',
            name: 'Announcement',
            onSelect: onPressAnnouncement,
            isComingSoon: Platform.OS === 'web',
            allowedRoles: [
              TaskMemberRole.Assignee,
              TaskMemberRole.Owner,
              ProjectMemberRole.Owner,
              ProjectMemberRole.Admin,
              ProjectMemberRole.Editor,
              TeamMemberRole.Admin,
              TeamMemberRole.Owner,
            ],
          },
        ] as (
          | (ModalItemType & {
              allowedRoles?: (
                | TaskMemberRole
                | ProjectMemberRole
                | TeamMemberRole
              )[];
            })
          | (MenuItem & {
              allowedRoles?: (
                | TaskMemberRole
                | ProjectMemberRole
                | TeamMemberRole
              )[];
            })
        )[])
      : []),
    {
      iconName: 'Duplicate',
      title: 'Duplicate',
      name: 'Duplicate',
      onSelect: () => {
        setCurrentTask(undefined);
        setSelectedMembers([]);
        navigation.navigate('task-copy-modal', { taskId });
      },
      allowedRoles: [
        TaskMemberRole.Owner,
        TaskMemberRole.Assignee,
        ProjectMemberRole.Owner,
        ProjectMemberRole.Admin,
        ProjectMemberRole.Editor,
        TeamMemberRole.Admin,
        TeamMemberRole.Owner,
      ],
    },
    {
      iconName: 'LogOut',
      title: 'Leave',
      name: 'Leave',
      isComingSoon: Platform.OS === 'web',
      onSelect: () => {
        if (creator?.id === me?.id) {
          creatorLeaveTaskAlert();
        } else {
          assigneeLeaveTaskAlert();
        }
      },
      allowedRoles: [
        TaskMemberRole.Owner,
        TaskMemberRole.Assignee,
        TeamMemberRole.Admin,
        TeamMemberRole.Owner,
      ],
    },
    {
      iconName: 'Edit',
      title: 'Edit Task',
      name: 'Edit Task',
      onSelect: () => {
        navigation.navigate('task-edit', {
          taskId,
        });
      },
      allowedRoles: [
        TaskMemberRole.Owner,
        TaskMemberRole.Assignee,
        ProjectMemberRole.Owner,
        ProjectMemberRole.Admin,
        ProjectMemberRole.Editor,
        TeamMemberRole.Admin,
        TeamMemberRole.Owner,
      ],
    },
    {
      iconName: 'Forward',
      title: 'Forward',
      name: 'Forward',
      isComingSoon: Platform.OS === 'web',
      allowedRoles: [
        TaskMemberRole.Owner,
        TaskMemberRole.Assignee,
        ProjectMemberRole.Owner,
        ProjectMemberRole.Admin,
        ProjectMemberRole.Editor,
        ProjectMemberRole.Viewer,
        TeamMemberRole.Admin,
        TeamMemberRole.Owner,
      ],
      onSelect: () => {
        navigation.navigate('forward-message', { taskId });
      },
    },

    {
      iconName: 'Trash2',
      title: 'Delete',
      name: 'Delete',
      color: 'alertRed',
      onSelect: deleteAlert,
      allowedRoles: [
        TaskMemberRole.Owner,
        ProjectMemberRole.Owner,
        ProjectMemberRole.Admin,
        ProjectMemberRole.Editor,
        TeamMemberRole.Admin,
        TeamMemberRole.Owner,
      ],
    },
  ];

  const navItems = fullNavItems.filter((i) => {
    if (i.title == 'Leave')
      return i.allowedRoles?.some((r) => r === currentUserRole);
    else {
      return i.allowedRoles?.some(
        (r) =>
          r === currentUserRole ||
          r === currentUserProjectRole ||
          r === currentUserProjectTeamRole
      );
    }
  });
  return navItems;
};
