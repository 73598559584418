import { Alert } from 'react-native';
import DocumentPicker from 'react-native-document-picker';
import uuid from 'react-native-uuid';

import { LocalFile } from '../graphql/generated';

const ALLOWED_EXTENSIONS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.svg',
  '.tiff',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.txt',
  '.rtf',
  '.odt',
  '.mp3',
  '.wav',
  '.aac',
  '.ogg',
  '.mp4',
  '.avi',
  '.mov',
  '.wmv',
  '.zip',
  '.tar',
  '.7z',
];

const DANGEROUS_EXTENSIONS = [
  '.php',
  '.php5',
  '.pht',
  '.phtml',
  '.shtml',
  '.asa',
  '.cer',
  '.asax',
  '.swf',
  '.xap',
  '.asp',
  '.aspx',
  '.jsp',
  '.exe',
];

const isValidExtension = (filename) => {
  const lowerCaseFilename = filename.toLowerCase();
  const extension = lowerCaseFilename.slice(lowerCaseFilename.lastIndexOf('.'));

  // Check for allowed extensions
  if (!ALLOWED_EXTENSIONS.includes(extension)) {
    return false;
  }

  // Check for double extensions
  const parts = lowerCaseFilename.split('.');
  if (
    parts.length > 2 ||
    DANGEROUS_EXTENSIONS.includes(`.${parts[parts.length - 2]}`)
  ) {
    return false;
  }

  // Check for dangerous patterns
  const dangerousPatterns = [
    /;\w+/, // e.g. file.asp;.jpg
    /\/\w+/, // e.g. file.php/filename
    /\\\w+/, // e.g. file.php\filename
    /\.\w+::$Index_Allocation/, // e.g. folder.asp::$Index_Allocation
    /\.\w+:$/, // e.g. file.asax:.
    /%00/, // e.g. null character
    /\.$/, // e.g. trailing dot
    /\\$/, // e.g. trailing backslash
  ];

  for (const pattern of dangerousPatterns) {
    if (pattern.test(lowerCaseFilename)) {
      return false;
    }
  }

  return true;
};

export const selectFiles = async (
  allowMultiSelection?: boolean
): Promise<LocalFile[] | undefined> => {
  let result;
  try {
    result = await DocumentPicker.pickMultiple({
      allowMultiSelection,
      copyTo: 'cachesDirectory',
    });
  } catch (e) {
    if (DocumentPicker.isCancel(e)) {
      return undefined;
    } else {
      throw e;
    }
  }

  if (result) {
    const invalidFiles = result.filter((item) => !isValidExtension(item.name));
    if (invalidFiles.length > 0) {
      Alert.alert(
        'Upload failed',
        'The selected file type is not supported for upload. To check allowed list of file types, please visit https://tasktag.com/faq.'
      );
      return undefined;
    }

    return (
      result?.map((item): LocalFile => {
        const uri = (item.fileCopyUri || item.uri).toString();
        const { name, type, size } = item;
        const clientId = uuid.v4().toString();

        return {
          __typename: 'LocalFile',
          id: clientId,
          url: uri,
          name: name || '',
          size,
          contentType: type || '',
          clientId,
          isImage: false,
          cdnBaseUrl: '',
          path: '',
        };
      }) || []
    );
  }
};
