import { getDocumentAsync } from 'expo-document-picker';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { Alert } from '@components/Alert';
import { LocalFile } from '@graphql/generated';
import { fileToLocalFile } from '@utils/fileUtils';
import { selectFiles } from '@utils/selectFiles';
import { selectImageFromGallery } from '@utils/selectImage';

export const useFilePicker = () => {
  const { t } = useTranslation();
  return {
    launchImageSelection: (
      selectionLimit?: ImageSelectionOptions['selectionLimit']
    ) =>
      launchImageSelection({
        denyAccessTitle: t('accessDenied.camera.title'),
        denyAccessMessage: t('accessDenied.camera.message'),
        selectionLimit,
      }),
    launchFileSelection,
  };
};

type ImageSelectionOptions = {
  denyAccessTitle: string;
  denyAccessMessage: string;
  selectionLimit?: number;
};

const launchImageSelection = (options: ImageSelectionOptions) => {
  return Platform.select({
    web: async ({ selectionLimit }: ImageSelectionOptions) =>
      launchFileSelectionWeb(selectionLimit, 'photo'),
    default: async ({ selectionLimit }: ImageSelectionOptions) => {
      return await selectImageFromGallery(selectionLimit);
    },
  })(options);
};

const launchFileSelection = (selectionLimit?: number) => {
  return Platform.select({
    web: async (selectionLimit?: number) =>
      launchFileSelectionWeb(selectionLimit, 'file'),
    default: (selectionLimit?: number) =>
      selectFiles(!!selectionLimit && selectionLimit > 1),
  })(selectionLimit);
};

const launchFileSelectionWeb = async (
  selectionLimit?: number,
  type: 'photo' | 'file' = 'photo'
): Promise<LocalFile[] | undefined> => {
  const result = await getDocumentAsync({
    multiple: selectionLimit === undefined || selectionLimit > 1,
    type: type === 'photo' ? 'image/*' : undefined,
  });
  if (result.type === 'cancel' || !result.output) {
    return;
  }

  const files = Array.from(result.output);
  if (files && (!selectionLimit || files?.length <= selectionLimit)) {
    return Promise.all(files.map((file) => fileToLocalFile(file)));
  } else if (selectionLimit && files.length > selectionLimit) {
    Alert.alert(`Max selection limit of ${selectionLimit} has been reached`);
  }

  return;
};
