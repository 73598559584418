import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { StyleSheet } from 'react-native';

import { DateSelect } from '@components/FormModals/DateSelect';
import { Box } from '@components/Restyle';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';
import theme from '@themes/theme';

export const EditTaskDatesBottomSheet = forwardRef((_) => {
  const route =
    useRoute<TasksStackScreenProps<'task-detail-edit-dates'>['route']>();
  const {
    params: { fieldName, submitOnSelected },
  } = route || {};

  const navigation = useNavigation();
  const [modalPosition, setModalPosition] = useState(0);

  const bottomSheetDateFormRef = useRef<BottomSheetModal>(null);
  const snapPoints = ['70%', '80%', '90%'];

  // callbacks
  const handleFormSheetChange = (index: number) => {
    setModalPosition(index);
    if (index === -1) {
      navigation.goBack();
    }
  };

  const dismissDateModal = useCallback(() => {
    if (bottomSheetDateFormRef.current) {
      navigation.goBack();
      bottomSheetDateFormRef.current.dismiss();
    }
  }, []);

  useEffect(() => {
    if (bottomSheetDateFormRef.current) {
      bottomSheetDateFormRef.current.present();
    }
  }, [bottomSheetDateFormRef]);

  const handleComponent = () => <Box style={styles.closeLine} />;
  return (
    <>
      <BottomSheetModal
        index={modalPosition}
        keyboardBehavior='fillParent'
        name='Edit Task Dates'
        ref={bottomSheetDateFormRef}
        snapPoints={snapPoints}
        handleComponent={handleComponent}
        onChange={handleFormSheetChange}>
        <DateSelect
          isEditTaskDate
          isBottomSheet
          onSelectFinish={dismissDateModal}
          formField={fieldName}
          submitWhenSelected={submitOnSelected ?? true}
        />
      </BottomSheetModal>
    </>
  );
});

const styles = StyleSheet.create({
  closeLine: {
    alignSelf: 'center',
    width: 56,
    height: 4,
    borderRadius: 2.5,
    backgroundColor: theme.colors.grey03,
    margin: theme.spacing.l,
  },
});
