import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';

import CustomTabPanel from '@components/CustomTabBar/CustomTabPanel.web';
import ProfileCard from '@components/Profile/ProfileCard';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { createTabNavigator } from '@components/Web/Navigator/TabNavigator';
import { useGoogleAuth } from '@hooks/useGoogleAuth';
import useMe from '@hooks/useMe';
import { useNotification } from '@hooks/useNotification';
import { useAuthContext } from '@src/context/authContext';

const Tabs = createTabNavigator();

const MyProfile: React.FC = () => {
  const { removeToken: removeAuthToken } = useAuthContext();
  const { deleteToken: deleteNotificationToken } = useNotification();
  const { me } = useMe();
  const { clearUserInfo } = useGoogleAuth();
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  const logout = async () => {
    try {
      clearUserInfo && clearUserInfo();

      // eslint-disable-next-line no-console
      await deleteNotificationToken();
    } catch (e) {
      /* empty */
    } finally {
      removeAuthToken();
    }
  };

  if (!me)
    return (
      <Box marginHorizontal='m' marginTop='m'>
        <Text textAlign='center' marginBottom='m' color='grey04'>
          no user found
        </Text>
        <Button onPress={logout} accessibilityLabel='Log Out'>
          <Text variant='buttonLabel' color='alertRed'>
            Log out
          </Text>
        </Button>
      </Box>
    );

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <Box>
        <Box
          flex={1}
          flexDirection='row'
          alignItems='center'
          style={styles.topShadow}>
          <CustomTabPanel
            {...props}
            isProfileTabs
            contentContainerStyle={styles.contentContainerPanelStyle}
          />
          <Box flexDirection='row' mr='l' justifyContent='flex-end'>
            <Button
              accessibilityLabel={t('models:saveProfile.logout')}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              isSmall
              height={48}
              backgroundColor={isHovered ? 'grey01' : 'white'}
              prefix={<Icon variant='s' name='LogOut' color='alertRed' />}
              onPress={logout}
              borderRadius='xs'
              borderColor={isHovered ? 'grey01' : 'white'}
              variant='logout'
              prefixMarginRight='xs'
              paddingHorizontal='m'
              paddingVertical='xs'>
              {t('models:saveProfile.logout')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box flex={1} mt='m' minWidth={650}>
      <Tabs.Navigator
        screenOptions={{ swipeEnabled: false }}
        tabBar={renderTabBar}>
        <Tabs.Screen
          name='my-account'
          options={{ tabBarLabel: 'My Account' }}
          children={() => (
            <Box flex={1} alignItems='center'>
              <ScrollView
                showsVerticalScrollIndicator={false}
                keyboardShouldPersistTaps='always'
                scrollEnabled
                style={{ width: '100%' }}>
                <Box flex={1} alignItems='center' marginBottom='listFooter'>
                  <ProfileCard user={me} isMe />
                </Box>
              </ScrollView>
            </Box>
          )}
        />
        <Tabs.Screen
          name='subscription-plans'
          options={{ tabBarLabel: 'Subscription Plans' }}
          listeners={{
            tabPress: (e) => {
              e.preventDefault();
            },
          }}
          children={() => <Box></Box>}
        />
        <Tabs.Screen
          name='team-plan'
          options={{ tabBarLabel: 'Team Plan' }}
          listeners={{
            tabPress: (e) => {
              e.preventDefault();
            },
          }}
          children={() => <Box></Box>}
        />
      </Tabs.Navigator>
    </Box>
  );
};

export default MyProfile;

const styles = StyleSheet.create({
  topShadow: {
    backgroundColor: 'white',
  },
  contentContainerPanelStyle: {
    paddingLeft: 24,
    backgroundColor: '#ffffff',
  },
});
