import React from "react";
import { useTranslation } from "react-i18next";

import { WebModal } from "@components/Modals/WebModal.web";
import CopyTaskDetails from "@components/Tasks/CopyTaskDetails";
import { useAppNavigation } from "@navigation/useAppNavigation";

export const TaskCopySelect: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();

  return (
    <WebModal visible title={t("shared:select")} onClose={navigation.goBack}>
      <CopyTaskDetails />
    </WebModal>
  );
};
