import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionList, SectionListData } from 'react-native';

import EmptyStateNoSearch from '@components/EmptyState/EmptyStateNoSearch';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import ToggleSwitch from '@components/Tasks/ToggleSwitch.web';
import { PopupFilesFilterButtonWeb } from '@components/Web/PopupFilesFilterButton.web';
import { SearchableResult } from '@graphql/generated';
import { Search } from '@root/types';
import theme from '@themes/theme';

type GlobalSearchList = {
  title: string;
  totalCount: number;
  data: Search<SearchableResult>[];
  render: (item: Search<SearchableResult>, index?: number) => JSX.Element;
  itemSeparatorComponent?: JSX.Element;
  headerRight?: JSX.Element;
  sectionFooter?: JSX.Element;
  sectionHeaderMarginBottom?: keyof typeof theme.spacing;
};

type GlobalSearchListProps = {
  lists: GlobalSearchList[];
  loading: boolean;
  isViewAllContacts: boolean;
  onPress: () => void;
  isChatsSearch?: boolean;
  search?: string;
  isFilesSearch?: boolean;
  isMyUploads?: boolean;
  setIsMyUploads?: (value: boolean) => void;
  filesFilter?: string;
  setFilesFilter?: (value: string) => void;
};

const GlobalSearchList = ({
  lists,
  loading,
  onPress,
  isViewAllContacts,
  isChatsSearch = false,
  search,
  isFilesSearch = false,
  isMyUploads = false,
  setIsMyUploads,
  filesFilter = 'All',
  setFilesFilter,
}: GlobalSearchListProps) => {
  const { t } = useTranslation();
  const allCountsZero = lists.every((list) => list.totalCount === 0);
  const renderHeader = ({
    section: { sectionHeaderMarginBottom, title, totalCount, headerRight },
  }: {
    section: SectionListData<Search<SearchableResult>, GlobalSearchList>;
  }) => {
    if (isChatsSearch && !search && !loading) {
      return <Box />;
    }

    const offsetPopButton: Partial<any> = {
      position: 'left',
      offset: [0, 6],
    };

    const filesFilterBox = () => {
      return (
        <Box flexDirection='row' alignItems='center'>
          <Box
            accessibilityLabel={t(
              'models:projects.projectDetails.filesWithMedia.filter.myUploads'
            )}>
            <ToggleSwitch
              onValueChange={(v) => {
                setIsMyUploads && setIsMyUploads(v);
              }}
              value={isMyUploads}
            />
          </Box>
          <Text
            variant='webBodySecondary'
            marginHorizontal='xs'
            paddingRight='m'
            onPress={() => {
              setIsMyUploads && setIsMyUploads(!isMyUploads);
            }}>
            {t(
              'models:projects.projectDetails.filesWithMedia.filter.myUploads'
            )}
          </Text>
          <PopupFilesFilterButtonWeb
            title={filesFilter}
            setFilesFilter={(value: string) =>
              setFilesFilter && setFilesFilter(value)
            }
            boxProps={offsetPopButton}
          />
        </Box>
      );
    };

    if (allCountsZero && !loading) {
      if (allCountsZero && search) {
        return (
          <>
            <Box
              paddingVertical='xs'
              paddingHorizontal={!isChatsSearch ? 'l' : 'm'}
              justifyContent='space-between'
              flexDirection='row'
              alignItems='center'
              marginBottom={sectionHeaderMarginBottom || undefined}>
              <Text variant='webSmall' color='grey05'>
                Results (0)
              </Text>
              {isFilesSearch && filesFilterBox()}
              {headerRight && (
                <Box flex={1} alignItems='flex-end'>
                  {headerRight}
                </Box>
              )}
            </Box>
            <EmptyStateNoSearch showSmall={!isChatsSearch} />
          </>
        );
      } else {
        return title !== 'Contacts' && <EmptyStateNoSearch />;
      }
    }

    return (
      <Box
        paddingVertical={isFilesSearch ? undefined : 'xs'}
        paddingHorizontal={!isChatsSearch ? 'l' : 'm'}
        justifyContent='space-between'
        flexDirection='row'
        alignItems='center'
        marginBottom={sectionHeaderMarginBottom || undefined}>
        <Text variant='webSmall' color='grey05'>
          Results ({totalCount})
        </Text>
        {headerRight && (
          <Box flex={1} alignItems='flex-end'>
            {headerRight}
          </Box>
        )}
        {isFilesSearch && filesFilterBox()}
        {totalCount > 5 && title === 'Contacts' && isViewAllContacts && (
          <Text onPress={onPress} variant='labelSmall'>
            View all
          </Text>
        )}
      </Box>
    );
  };

  return (
    <Box flex={1} accessibilityLabel={lists[0].title + ' Search results'}>
      {loading ? (
        <ActivityIndicatorLoading />
      ) : (
        <SectionList
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps='always'
          keyboardDismissMode='on-drag'
          sections={lists.map((list, index) => ({ ...list, index }))}
          renderItem={({ item, index, section: { render } }) =>
            render(item, index)
          }
          ItemSeparatorComponent={() => <></>}
          renderSectionFooter={({ section: { sectionFooter } }) =>
            sectionFooter || <Box mb='s' />
          }
          renderSectionHeader={renderHeader}
        />
      )}
    </Box>
  );
};

export default GlobalSearchList;
