import { array, boolean, object, number, SchemaOf, string } from 'yup';

import { LocalFile } from '@graphql/generated';

export const MAX_DOC_COUNT = 9;
export const MAX_AUDIO_RECORDING_COUNT = 1;
export const MAX_IMAGE_COUNT = 8;
export const MAX_RECORDING_SECONDS = 120;

export const fileSchema: SchemaOf<LocalFile> = object({
  __typename: string().required(),
  cdnBaseUrl: string().nullable().notRequired(),
  clientId: string().required(),
  contentType: string().nullable().notRequired(),
  id: string().defined(),
  isAudio: boolean().defined(),
  isImage: boolean().defined(),
  name: string().required(),
  path: string().nullable().notRequired(),
  size: number().required(),
  url: string().defined(),
  duration: number().nullable().notRequired(),
});

export const filesSchema = array()
  .of(fileSchema)
  .max(MAX_DOC_COUNT)
  .when('audioRecordings', (audioRecordings, schema) => {
    return audioRecordings
      ? schema.max(0, 'AUDIO CONFLICT MESSAGE')
      : schema.max(MAX_AUDIO_RECORDING_COUNT);
  })
  .when('images', (images, schema) => {
    return images
      ? schema.max(0, 'IMAGE CONFLICT MESSAGE')
      : schema.max(MAX_AUDIO_RECORDING_COUNT);
  });

const tagCollectionSchema = object({
  tasks: array(),
  project: object(),
  author: object(),
});

export const chatInputSchema = object({
  body: string().when(
    ['localFiles', 'checkin'],
    (localFiles, checkin, schema) => {
      return localFiles?.length > 0 || checkin
        ? schema.nullable().notRequired()
        : schema.required();
    }
  ),

  tagsCollection: array().of(tagCollectionSchema).ensure(),

  localFiles: array()
    .of(fileSchema)
    .ensure()
    .test('localFiles', 'NO mixed media', (value = []) => {
      const audioMessages = value.some((item) => item.isAudio);
      const images = value.some((item) => item.isImage);
      const documents = value.some((item) => !item.isImage && !item.isAudio);
      const attachments = [audioMessages, images, documents];
      const numberOfDifferentAttachments = attachments.filter((a) => a).length;

      return numberOfDifferentAttachments <= 1;
    })
    .test({
      name: 'audioRecordings',
      exclusive: true,
      test: (localFiles = []) => {
        if (localFiles?.length < 1) return true;

        const localFile = localFiles[0];
        if (
          localFile &&
          localFile.isAudio &&
          localFiles.length > MAX_AUDIO_RECORDING_COUNT
        ) {
          return !localFiles.some((item) => !item.isAudio);
        }
        return true;
      },
    })
    .test({
      name: 'imagesCount',
      exclusive: true,
      test: (localFiles = []) => {
        if (localFiles?.length < 1) return true;

        const localFile = localFiles[0];
        if (
          localFile &&
          localFile.isImage &&
          localFiles.length > MAX_IMAGE_COUNT
        ) {
          return !localFiles.some((item) => !item.isImage);
        }
        return true;
      },
    })
    .test({
      name: 'documentsCount',
      exclusive: true,
      test: (localFiles = []) => {
        const localFile = localFiles[0];
        if (
          localFile &&
          localFile.isAudio &&
          localFiles.length > MAX_DOC_COUNT
        ) {
          return !localFiles.some((item) => !(item.isImage && item.isAudio));
        }
        return true;
      },
    }),
});
