import { MaterialTopTabView } from '@react-navigation/material-top-tabs';
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import { Box } from '@components/Restyle';
import { MenuItem } from '@components/shared/Popup/PopupItem';
import { PopupIcon } from '@components/Web/PopupIcon';
import theme from '@themes/theme';

import type {
  MaterialTopTabBarProps,
  MaterialTopTabNavigationConfig,
  MaterialTopTabNavigationEventMap,
  MaterialTopTabNavigationOptions,
} from '@react-navigation/material-top-tabs/lib/typescript/src/types';

/**
 * Uses the base `createMaterialTopTabNavigator` as a base.
 * See https://github.com/react-navigation/react-navigation/blob/main/packages/material-top-tabs/src/navigators/createMaterialTopTabNavigator.tsx
 */

type BaseProps = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  MaterialTopTabNavigationOptions,
  MaterialTopTabNavigationEventMap
> &
  TabRouterOptions &
  Omit<MaterialTopTabNavigationConfig, 'tabBar'>;

type Props = BaseProps &
  (
    | {
        tabBar?: never;
        useCustomTabBar: true;
        threeDotMenuList: MenuItem[];
      }
    | ({ useCustomTabBar?: false; threeDotMenuList?: never } & Pick<
        MaterialTopTabNavigationConfig,
        'tabBar'
      >)
  );

const TabNavigator = ({
  id,
  initialRouteName,
  backBehavior,
  children,
  screenListeners,
  screenOptions,
  useCustomTabBar,
  threeDotMenuList,
  tabBar,
  ...rest
}: Props) => {
  const { state, descriptors, navigation, NavigationContent } =
    useNavigationBuilder<
      TabNavigationState<ParamListBase>,
      TabRouterOptions,
      TabActionHelpers<ParamListBase>,
      MaterialTopTabNavigationOptions,
      MaterialTopTabNavigationEventMap
    >(TabRouter, {
      id,
      initialRouteName,
      backBehavior,
      children,
      screenListeners,
      screenOptions,
    });

  // On web we modify the tab screen to account for the chat drawer
  const modified =
    Platform.OS === 'web'
      ? state.routes.reduce((prev, curr) => {
          const descriptor = prev[curr.key];
          const { render } = descriptor;

          descriptor.render = () => {
            return (
              <Box flex={1} flexDirection='row' justifyContent='space-between'>
                <Box flex={1}>{render()}</Box>
              </Box>
            );
          };

          return { ...prev, [curr.key]: { ...descriptor } };
        }, descriptors)
      : descriptors;

  const effectiveTabBar = useCustomTabBar
    ? (props: MaterialTopTabBarProps) => {
        const bbw = screenOptions?.tabBarStyle?.borderBottomWidth === 0 ? 0 : 1;
        return (
          <Box
            px='m'
            pb='s'
            borderBottomColor='grey02'
            borderBottomWidth={bbw}
            flexDirection='row'>
            <CustomTabBar {...props} />
            {!!threeDotMenuList && (
              <Box justifyContent='center'>
                <PopupIcon
                  name='MoreVertical'
                  color='textPrimary'
                  popupProps={{
                    position: 'left',
                    offset: [0, theme.spacing.m],
                    menuList: threeDotMenuList,
                  }}
                />
              </Box>
            )}
          </Box>
        );
      }
    : tabBar;

  return (
    <NavigationContent>
      <MaterialTopTabView
        {...rest}
        tabBar={effectiveTabBar}
        state={state}
        navigation={navigation}
        descriptors={modified}
      />
    </NavigationContent>
  );
};

/**
 * Creates a tab navigator that extends Material Top Tab Navigator
 * to allow for web to account for chat drawer spacing.
 */
export const createTabNavigator = createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  MaterialTopTabNavigationOptions,
  MaterialTopTabNavigationEventMap,
  typeof TabNavigator
>(TabNavigator);
