import { useRoute } from '@react-navigation/native';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, TouchableOpacity } from 'react-native';

import { ProjectIconGrid } from '@components/FormModals/ProjectIconGrid.web';
import { WebModal } from '@components/Modals/WebModal';
import { FormValues } from '@components/Projects/ProjectFormStack';
import { Text, Box } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import {
  ListProjectsDocument,
  ProjectMemberAttributes,
  useUpdateProjectMutation,
} from '@graphql/generated';
import useNewColors, { ColorType } from '@hooks/useNewColors';
import { useProjectFromQuery } from '@hooks/useProjectFromQuery';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { ProjectsStackScreenProps } from '@navigation/projects/projects-stack';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { projectFormSchema } from '@schemas/projectFormSchema';
import Images from '@themes/images';
import { defaultProjectRole } from '@utils/projects';

type SelectModalProps = {
  onClose?: () => void;
};

const ITEM_HEIGHT = 32;

export const ColorAndIconSelect: React.FC<SelectModalProps> = ({ onClose }) => {
  const { t } = useTranslation('models');
  const { colors } = useNewColors();
  const navigation = useAppNavigation();
  const { showProjectColorAndIconModal, setShowProjectColorAndIconModal } =
    useWebDrawerNavigator();

  const {
    params: { projectId },
  } = useRoute<ProjectsStackScreenProps<'project-edit'>['route']>();

  const { project } = useProjectFromQuery({ projectId });

  const initialMembers = project?.members.map((m) => {
    return {
      ...m.user,
      role: m.role,
    };
  });

  const initialValues: FormValues = {
    location: project?.address
      ? {
          address: project?.address,
          latitude: project?.latitude || 0,
          longitude: project?.longitude || 0,
        }
      : null,
    color: project?.color || '',
    icon: project?.icon || '',
    description: project?.description || '',
    id: project?.id,
    name: project?.name || '',
    teamId: project?.team?.id || '',
    users: initialMembers,
    currentTeam: project?.team,
  };

  const [selectedColor, setSelectedColor] = useState<string | undefined>(
    project?.color
  );
  const [selectedIcon, setSelectedIcon] = useState<keyof typeof Images>(
    project?.icon as keyof typeof Images
  );

  const getCircleStyles = ({ value }: ColorType) => ({
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: value,
    borderWidth: 2,
    shadowOpacity: 0.25,
    shadowRadius: 6,
    elevation: 2,
    shadowOffset: { width: 0, height: 0 },
  });

  const renderItem = ({ item }: { item: ColorType }) => {
    const circleStyles = getCircleStyles(item);

    return (
      <TouchableOpacity
        onPress={() => {
          setSelectedColor(item.id);
        }}>
        <Box height={40} px='xxs' justifyContent='center'>
          <Box
            style={[
              circleStyles,
              {
                borderColor: selectedColor === item.id ? 'white' : item.value,
                shadowColor: selectedColor === item.id ? 'black' : 'white',
              },
            ]}
          />
        </Box>
      </TouchableOpacity>
    );
  };

  const getColorList = () => {
    return (
      <Box p='m' borderRadius='xs'>
        <FlatList
          horizontal
          data={colors}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          getItemLayout={(_data, index) => ({
            length: ITEM_HEIGHT,
            offset: ITEM_HEIGHT * index,
            index,
          })}
          ListEmptyComponent={() => {
            return (
              <Text textAlign='center' style={styles.noData}>
                No Data Found
              </Text>
            );
          }}
        />
      </Box>
    );
  };

  const iconList = () => {
    const icons: (keyof typeof Images)[] = [];
    for (let i = 0; i < 40; i++) {
      icons.push(('IconStart' + i) as keyof typeof Images);
    }
    return icons;
  };

  const [updateProject] = useUpdateProjectMutation({
    onCompleted: () => {
      setShowProjectColorAndIconModal(false);
      navigation.goBack();
    },
    onError: (e) => console.log('err updating project', e),
    refetchQueries: [
      { query: ListProjectsDocument },
      'listProjects',
      'listProjectsRecent',
    ],
  });

  const submit = (values: FormValues) => {
    const { teamId, name, location, description, color, users } = values;
    const { address, latitude, longitude } = location || {
      address: undefined,
      latitude: undefined,
      longitude: undefined,
    };

    const members: ProjectMemberAttributes[] = (users || []).map((u) => {
      return {
        userId: u.id,
        role: u.role || defaultProjectRole,
      };
    });
    const projectName = name.trim();
    const attributes = {
      teamId,
      name: projectName,
      address,
      latitude,
      longitude,
      description,
      color: selectedColor ?? color,
      icon: selectedIcon,
      members,
    };
    if (projectId) {
      updateProject({ variables: { id: projectId, attributes } });
    }
  };

  return (
    <WebModal
      visible={!!showProjectColorAndIconModal}
      onClose={() => (onClose ? onClose() : navigation.goBack())}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submit(values)}
        validationSchema={projectFormSchema}>
        {({ values }) => {
          return (
            <>
              <Box
                flexDirection='row'
                justifyContent='space-between'
                marginHorizontal='m'
                my='l'>
                <Text variant='bodyTaskName'>
                  {t('projects.edit.setColorAndIcon')}
                </Text>
                {!!onClose && (
                  <Icon
                    name='X'
                    variant='m'
                    color='black'
                    onPress={() => (onClose ? onClose() : navigation.goBack())}
                  />
                )}
              </Box>
              <Text marginHorizontal='m' variant='bodyTaskName'>
                {t('projects.edit.chooseColor')}
              </Text>
              {getColorList()}
              <Text marginHorizontal='m' variant='bodyTaskName'>
                {t('projects.edit.chooseIcon')}
              </Text>
              <Box margin='m'>
                <ProjectIconGrid
                  selectedIcon={selectedIcon}
                  bodyWidth={356}
                  numColumns={10}
                  list={iconList()}
                  onPress={(icon: keyof typeof Images) => {
                    setSelectedIcon(icon);
                  }}
                />
              </Box>
              <Box
                paddingHorizontal='m'
                paddingBottom='m'
                flexDirection='row'
                justifyContent='flex-end'>
                <Button
                  isSmall
                  color='black'
                  backgroundColor='white'
                  accessibilityLabel={t('shared:cancel')}
                  onPress={() => (onClose ? onClose() : navigation.goBack())}>
                  {t('shared:cancel')}
                </Button>
                <Box marginLeft='m' marginRight='xs' width={107}>
                  <Button
                    isSmall
                    fullWidth
                    variant='primary'
                    accessibilityLabel={t('forms:save')}
                    onPress={() => submit(values)}>
                    {t('forms:save')}
                  </Button>
                </Box>
              </Box>
            </>
          );
        }}
      </Formik>
    </WebModal>
  );
};

const styles = StyleSheet.create({
  noData: {
    paddingVertical: 45,
  },
});
