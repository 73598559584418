/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ParamListBase, useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyleSheet,
  Image,
  Modal,
  TouchableWithoutFeedback,
} from 'react-native';

import { Box, ShadowBox, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';

export const WelcomeModal: React.FC<ParamListBase> = () => {
  const navigation = useNavigation();
  const { t } = useTranslation('models');
  const doNotShowInviteMessage = window.sessionStorage.getItem(
    'do_not_show_invite_message'
  );
  const [showMessage, setShowMessage] = useState(
    doNotShowInviteMessage !== '1'
  );
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => setShowMessage(false), 5000);
  }, []);

  return (
    <Modal animationType='fade' transparent={true} visible={visible}>
      <Box style={styles.modalContainer}>
        <TouchableWithoutFeedback style={styles.modal}>
          <Box style={styles.modal}></Box>
        </TouchableWithoutFeedback>
        <ShadowBox
          variant='thin'
          style={styles.popUp}
          borderRadius='m'
          overflow='hidden'
          width={541}
          height={710}>
          <Box
            alignItems='center'
            borderRadius='m'
            backgroundColor='white'
            style={{
              paddingVertical: 24,
              paddingHorizontal: 48,
              position: 'absolute',
              width: 541,
              overflow: 'hidden',
            }}>
            <Image
              style={{ width: 391, height: 332 }}
              source={require('../../../assets/images/team_work_monochromatic.png')}
            />
            <Text
              variant='heading1Web'
              color='textPrimary'
              textAlign='center'
              style={{ marginTop: 40 }}>
              {t('onBoarding.modal.welcomeYourTrialStarts')}
            </Text>
            <Text variant='body' color='textSecondary' mt='m'>
              {t('onBoarding.modal.weAreHappy')}
              <Text
                variant='body'
                color='purple'
                style={{
                  lineHeight: 24,
                  fontFamily: 'Inter_600SemiBold',
                  fontWeight: 700,
                  fontSize: 16,
                }}>
                {t('onBoarding.modal.freeTeamSubscription')}
              </Text>
              <Text variant='body' color='textSecondary'>
                {t('onBoarding.modal.asAPromotion')}
              </Text>
            </Text>
            <Text variant='body' color='textSecondary' mt='m'>
              {t('onBoarding.modal.onceYourTrialEnds')}
            </Text>
            <Button
              marginTop='m'
              marginBottom='m'
              onPress={async () => {
                window.sessionStorage.setItem(
                  'do_not_show_invite_message',
                  '0'
                );
                window.sessionStorage.setItem('goto_welcome', '0');
                setVisible(false);
                navigation.navigate('allprojects');
              }}
              fullWidth
              isSmall={true}
              height={48}
              backgroundColor='black'
              accessibilityLabel={t('payments.freeTrialCta')}>
              <Text
                variant='labelSmall'
                color='white'
                style={{ lineHeight: 16 }}>
                {t('payments.freeTrialCta')}
              </Text>
            </Button>
          </Box>
        </ShadowBox>
        {showMessage && (
          <Box
            backgroundColor='black'
            borderRadius='xs'
            px='s'
            py='xs'
            style={{
              opacity: 0.8,
              position: 'absolute',
              bottom: 40,
            }}>
            <Text variant='webSmall' color='white'>
              {t('models:onBoarding.invitationsHaveBeenSent')}
            </Text>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  modalContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  popUp: {
    position: 'absolute',
    backgroundColor: 'white',
  },
});
