import { useFormikContext } from "formik";
import React from "react";

import {
  LocationSearch,
  Location,
} from "@components/LocationSearch/LocationSearch";
import { FormValues } from "@components/Projects/ProjectFormStack";

export const ProjectLocationSearch: React.FC = () => {
  const { setFieldValue, setTouched, touched } = useFormikContext<FormValues>();

  return (
    <LocationSearch
      onSelect={(location: Location) => {
        setFieldValue("location", location);
        setTouched({ ...touched, location: true });
      }}
    />
  );
};
