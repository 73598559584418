/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import theme from '@themes/theme';

export enum EmptyStateType {
  Chat,
  Banner,
  Popup,
}

interface ChatEmptyStateProps {
  onClose: () => void;
  onContentPress?: () => void;
  type: EmptyStateType;
}

export const ChatEmptyState: React.FC<ChatEmptyStateProps> = ({
  onClose,
  onContentPress,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {type == EmptyStateType.Chat && (
        <Box
          mx='m'
          width={526}
          height={244}
          backgroundColor='black'
          borderRadius='xs'
          flexDirection='row'>
          <Box flexDirection='column' p='l' flex={1}>
            <Icon name='WinnerTwoColor' width={76} height={66} />
            <Box flex={1} justifyContent='flex-end'>
              <Text
                textAlign='left'
                marginTop='s'
                color='white'
                variant='labelLarge'
                style={{ fontSize: 18 }}>
                <Highlighter
                  autoEscape
                  highlightStyle={{
                    color: theme.colors.greenSecondary,
                    backgroundColor: 'transparent',
                  }}
                  searchWords={['Sync']}
                  textToHighlight={t('models:chat.labels.syncContactsList')}
                />
              </Text>
              <Text
                textAlign='left'
                marginTop='s'
                color='white'
                variant='bodySecondary'
                style={{ fontSize: 14 }}>
                <Highlighter
                  autoEscape
                  highlightStyle={{
                    color: theme.colors.greenSecondary,
                    backgroundColor: 'transparent',
                  }}
                  searchWords={['Scan', 'install', 'to start a chat']}
                  textToHighlight={t('models:chat.labels.scanQR')}
                />
              </Text>
            </Box>
          </Box>
          <Box flexDirection='column' flex={1} justifyContent='flex-end'>
            <Image
              style={{ width: 216, height: 224 }}
              source={require('../../../../assets/images/qr_code.png')}
            />
          </Box>
          <TouchableOpacity onPress={onClose}>
            <Box position='absolute' top={16} right={16}>
              <Icon name='X' variant='s' color='grey04'></Icon>
            </Box>
          </TouchableOpacity>
        </Box>
      )}

      {type == EmptyStateType.Banner && (
        <Box
          position='absolute'
          bottom={0}
          left={0}
          right={0}
          p='m'
          width='100%'
          flexDirection='row'>
          <Box
            width='100%'
            height={58}
            backgroundColor='black'
            borderRadius='xs'
            flexDirection='row'
            alignItems='center'>
            <Box flex={1}>
              <TouchableOpacity
                onPress={() => {
                  onContentPress && onContentPress();
                }}>
                <Box flex={1} flexDirection='row'>
                  <Icon name='WinnerTwoColor' width={50} height={42} />

                  <Box flexDirection='column' flex={1}>
                    <Box flex={1} justifyContent='flex-end'>
                      <Text
                        textAlign='left'
                        color='white'
                        variant='labelSmall'
                        style={{ fontSize: 14 }}>
                        <Highlighter
                          autoEscape
                          highlightStyle={{
                            color: theme.colors.greenSecondary,
                            backgroundColor: 'transparent',
                          }}
                          searchWords={['Sync']}
                          textToHighlight={t(
                            'models:chat.labels.syncContactsList'
                          )}
                        />
                      </Text>
                      <Text
                        textAlign='left'
                        marginTop='xxs'
                        color='white'
                        variant='metadata'
                        style={{ fontSize: 12 }}>
                        <Highlighter
                          autoEscape
                          highlightStyle={{
                            color: theme.colors.greenSecondary,
                            backgroundColor: 'transparent',
                          }}
                          searchWords={['Scan', 'install', 'to start a chat']}
                          textToHighlight={t('models:chat.labels.scanQR')}
                        />
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </TouchableOpacity>
            </Box>
            <Box mb='l' mr='xs'>
              <TouchableOpacity onPress={onClose}>
                <Icon name='X' variant='s' color='grey04'></Icon>
              </TouchableOpacity>
            </Box>
          </Box>
        </Box>
      )}

      {type == EmptyStateType.Popup && (
        <Box
          flex={1}
          backgroundColor='black'
          flexDirection='column'
          alignItems='center'>
          <Box alignSelf='flex-end' padding='l'>
            <TouchableOpacity onPress={onClose}>
              <Text variant='metadata1' color='grey04' textAlign='right'>
                {t('shared:skip')}
              </Text>
            </TouchableOpacity>
          </Box>
          <Box style={{ paddingHorizontal: 78 }}>
            <Text textAlign='center' color='white' variant='heading2'>
              <Highlighter
                autoEscape
                highlightStyle={{
                  color: theme.colors.greenSecondary,
                  backgroundColor: 'transparent',
                }}
                searchWords={['Sync']}
                textToHighlight={t('models:chat.labels.syncContactsList')}
              />
            </Text>
            <Text
              textAlign='center'
              marginTop='xs'
              color='white'
              variant='metadata1'>
              <Highlighter
                autoEscape
                highlightStyle={{
                  color: theme.colors.greenSecondary,
                  backgroundColor: 'transparent',
                }}
                searchWords={['Scan', 'install', 'to start a chat']}
                textToHighlight={t('models:chat.labels.scanQR')}
              />
            </Text>
          </Box>
          <Box flexDirection='column' flex={1} justifyContent='flex-end'>
            <Image
              style={{ width: 265, height: 270 }}
              source={require('../../../../assets/images/qr_code.png')}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
