import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectModal } from '@components/FormModals/SelectModal';
import { TagSelectType } from '@components/FormModals/SelectModal/SelectModalContent';
import { useSkills } from '@hooks/useSkills';

interface SkillSelectProps {
  onClose?: () => void;
}

export const SkillSelect: React.FC<SkillSelectProps> = ({ onClose }) => {
  const { t } = useTranslation('models');
  const skills = useSkills();
  const { setFieldValue, setTouched, touched, getFieldProps } =
    useFormikContext();
  const { name: fieldName, value } = getFieldProps('skillIds');

  return (
    <SelectModal
      title='Skills'
      list={skills}
      showIcon={false}
      onClose={onClose}
      setSelected={(ids: TagSelectType['id'][]) => {
        setTouched({ ...touched, [fieldName]: true });
        setFieldValue(fieldName, [...ids]);
      }}
      value={value}
      emptyContentMessage={t('resourceNotFound.message', {
        resource: 'skill',
        resourcePlural: 'skills',
      })}
    />
  );
};
