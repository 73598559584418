import moment from 'moment-timezone';

import timezones from '@utils/dictionaries/timezones.json';

const DEFAULT_TIMEZONE = 'Central Time (US & Canada)';

export const beginningOfToday = () => {
  return beginningOfDay(new Date());
};
export const beginningOfYesterday = () => {
  const date = beginningOfToday();
  date.setDate(date.getDate() - 1);
  return date;
};

export const beginningOfDay = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const beginningOfDayUTC = (sdate: Date) => {
  //TODO: it's a hack for copy task feature where string is assigned, string shuold be converted to date in CopyTask
  const date = new Date(sdate);
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};

export const localDateFromUTCDate = (sdate: Date) => {
  const date = new Date(sdate);
  return date.getUTCHours() === 0
    ? new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    : date;
};

export const guessTimeZone = () => {
  const guess = moment.tz.guess();
  const zone = timezones.find((zoneItem) => zoneItem.alt === guess)?.zone;
  return zone || DEFAULT_TIMEZONE;
};

export const humanTimeZoneName = (timeZone: string) => {
  return timezones.find((item) => item.zone === timeZone)?.label;
};
