import React, { ComponentProps, useCallback, useState } from 'react';
import { Modal } from 'react-native';

import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { WebIcon } from '@components/shared/Icon/WebIcon';
import Popup from '@components/shared/Popup/Popup';
import { useOutsideClick } from '@hooks/useOutsideClick.web';
/**
 * Computes an object that contains left and top offsets, that when applied to the popup container
 * will place that component relative to the client component in a fullscreen
 * absolutely positioned container.
 * @param position How to compute the position of the popup relative to the client
 * @param offset Additional vertical or horizontal offset value(s) to apply
 * @param client The client dom bounds
 * @param popupLayout The width and height of the popup container
 * @returns
 */
const adjustBounds = (
  position: Props['popupProps']['position'] = 'bottom',
  offset: Props['popupProps']['offset'] = 0,
  client: DOMRect,
  popupLayout: { width: number; height: number }
) => {
  const [offsetLeftRight, offsetTopBottom] =
    typeof offset === 'number' ? [offset, offset] : offset;

  const normalizedPosition = (typeof position !== 'string' && position) ||
    ((position === 'left' || position === 'right') && [position, 'bottom']) ||
    ((position === 'top' || position === 'bottom') && ['left', position]) || [
      'left',
      'bottom',
    ];

  const [leftRight, topBottom] = normalizedPosition;
  const bounds = {
    top:
      topBottom === 'top'
        ? client.top - popupLayout.height - offsetTopBottom
        : client.top + client.height + offsetTopBottom,
    left:
      leftRight === 'left'
        ? client.left + client.width - popupLayout.width - offsetLeftRight
        : client.left + offsetLeftRight,
  };

  return bounds;
};

type PositionTuple = ['left' | 'right', 'top' | 'bottom'];

type Position = PositionTuple | 'top' | 'bottom' | 'left' | 'right';

type Props = Omit<ComponentProps<typeof Icon>, 'onPress'> & {
  onDismiss?: any;
  popupProps: Pick<ComponentProps<typeof Popup>, 'menuList'> &
    Partial<{
      /** How to position the popup relative to the icon */
      position: Position;
      /** An additional offset to apply to the popup positioning */
      offset: [number, number] | number;
    }>;
  enableHoverEffect?: boolean;
};

export const PopupIcon = ({
  onDismiss,
  popupProps: { position, offset, ...popupPropsRest },
  enableHoverEffect = true,
  ...iconProps
}: Props) => {
  const [visible, setVisible] = useState(false);

  const togglePopup = () => setVisible((v) => !v);

  const handleOutsideClick = useCallback(() => {
    setVisible(false);
  }, []);

  const ref = useOutsideClick<HTMLDivElement>(handleOutsideClick);

  // Store the natural dimensions of the popup container
  const [layout, setLayout] = useState<
    { width: number; height: number } | undefined
  >();

  const adjustedBounds =
    ref.current &&
    layout &&
    adjustBounds(position, offset, ref.current.getBoundingClientRect(), layout);

  const onModalDismiss = () => {
    onDismiss && onDismiss();
    setVisible(false);
  };

  return (
    <Box ref={ref}>
      {enableHoverEffect && <WebIcon {...iconProps} onPress={togglePopup} />}
      {!enableHoverEffect && <Icon {...iconProps} onPress={togglePopup} />}
      {/** The modal is an absolutely positioned container that fills the whole screen.
       * Because of this, the children also fill the whole screen.
       * To get the natural dimensions of the popup, we render the popup offscreen
       * and record the layout.
       * Using an absolute position to the left of the screen ensures that the component
       * renders but does not cause a scrollbar to appear.
       */}
      {!layout && (
        <Box
          position='absolute'
          left='-200vw'
          visible={!layout}
          onLayout={({ nativeEvent: { layout } }) =>
            setLayout((curr) => curr ?? layout)
          }>
          <Popup {...popupPropsRest} />
        </Box>
      )}
      <Modal
        visible={visible}
        transparent
        onDismiss={() => onModalDismiss()}
        onRequestClose={() => onModalDismiss()}>
        <Box style={{ ...adjustedBounds }} width={layout?.width}>
          <Box flexShrink={1}>
            <Popup {...popupPropsRest} closePopFn={() => setVisible(false)} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
