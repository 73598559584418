import { useApolloClient } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
import dayjs from 'dayjs';
import { useState, useCallback, useMemo } from 'react';

import { Chat, ListChatsDocument, ListChatsQuery } from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';

export type ChatsProps = {
  listChats: Chat[];
  loading: boolean;
  refresh: () => void;
  refreshing: boolean;
};

type ChatsDataProps = {
  children: (props: ChatsProps) => JSX.Element;
};

export const chatSort = (a: Chat | undefined, b: Chat | undefined) => {
  const { recentActivityAt: recentActivityAtA } = a || {};
  const { recentActivityAt: recentActivityAtB } = b || {};
  const recentActivityAtA1 = dayjs(recentActivityAtA);
  const recentActivityAtB1 = dayjs(recentActivityAtB);

  if (recentActivityAtA1 < recentActivityAtB1) return 1;
  if (recentActivityAtB1 < recentActivityAtA1) return -1;

  return 0;
};

const ChatData = ({ children }: ChatsDataProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listChats, setListChats] = useState<Chat[]>([]);
  const client = useApolloClient();
  const { subscribeToChatUpdates } = useChatInput();

  const queryObservable = client.watchQuery<ListChatsQuery>({
    query: ListChatsDocument,
    fetchPolicy: 'cache-and-network',
  });

  useFocusEffect(
    useCallback(() => {
      const unsubscribeQuery = queryObservable.subscribe({
        next: ({ data, loading }) => {
          setRefreshing(false);
          setLoading(loading);
          setListChats(data.listChats as Chat[]);
        },

        error: (e) => {
          setRefreshing(false);
          setLoading(false);
          console.error(e);
        },
      });

      // Subscribe to chat
      const unsubscribe = subscribeToChatUpdates && subscribeToChatUpdates();

      // Unsubscribe from chat updates when the screen is blurred
      return () => {
        unsubscribeQuery.unsubscribe();
        unsubscribe && unsubscribe();
      };
    }, [client])
  );

  const sortedListChats = useMemo(() => {
    const pinnedChats = listChats.filter((item) => item?.settings?.pin);
    const sortedPinnedChats = pinnedChats.sort(chatSort);
    const nonPinnedChats = listChats.filter((item) => !item?.settings?.pin);
    const sortedNonPinnedChats = nonPinnedChats.sort(chatSort);

    return [...sortedPinnedChats, ...sortedNonPinnedChats];
  }, [listChats]);

  const refresh = () => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
      setLoading(false);
    }, 2000);
    queryObservable.refetch().catch(() => {
      setRefreshing(false);
      setLoading(false);
    });
  };

  return children({
    listChats: sortedListChats,
    loading,
    refresh,
    refreshing,
  });
};
export const ChatsData = Sentry.withProfiler(ChatData);

// ChatsData.whyDidYouRender = true
