import React from "react";
import { Dimensions } from "react-native";

import { Box } from "@components/Restyle";
import theme from "@themes/theme";

/** Adds gesture margin for tab navigator to allow access to parent navigator gesture */
export const TabNavigatorSwipeSpacer = () => (
  <Box
    style={{
      position: "absolute",
      width: theme.spacing.m,
      height: Dimensions.get("window").height,
    }}
  />
);
