/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigation, useRoute } from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useTranslation } from 'react-i18next';

import { Alert } from '@components/Alert/index.web';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { useGoogleAuth } from '@hooks/useGoogleAuth';
import { useAuthContext } from '@src/context/authContext';
import { getApiConfig } from '@utils/getApiConfig';
import { AsyncStorage } from '@utils/storage';

import { Box, Text } from '../Restyle';

type OnboardingModalProps = {
  onSelect: (type: string) => void;
};

type UserInfo = {
  id: string;
  email: string;
  given_name: string;
  family_name: string;
  provider?: string;
};

const OnboardingModal: React.FC<OnboardingModalProps> = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState<boolean>(false);
  const { prompt, userInfo, clearUserInfo, setUserDetail } = useGoogleAuth();
  const apiConfig = getApiConfig();
  const { setAuthToken } = useAuthContext();
  const { params } = useRoute();
  const { t } = useTranslation('models');
  const [showProviderModal, setShowProviderModal] = useState(false);
  const [errorName, setErrorName] = useState('');

  useEffect(() => {
    userInfo && setLoading(true);
    userInfo && verifyUserEmail(userInfo);
  }, [userInfo]);

  const verifyUserEmail = async (userInfo: UserInfo) => {
    const verifyUser = `/api/v1/verify_user.json`;
    const userPayload = {
      user: {
        email: userInfo?.email?.toLowerCase(),
        provider: userInfo?.provider,
        uid: userInfo?.id,
        is_verified: false,
      },
    };
    const authEndpoint = `${apiConfig.apiUrl}${verifyUser}`;
    await fetch(authEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userPayload),
    })
      .then((response) => response.json())
      .then(async (response) => {
        setLoading(false);
        if (response?.error) {
          if (response.auth_provider) {
            setShowProviderModal(true);
            setErrorName(response.error);
          } else {
            navigation.navigate('register', {
              socialSignUp: true,
            });
          }
        } else {
          if (response.user) {
            try {
              window.sessionStorage.setItem('invitation_token', '');

              await AsyncStorage.setItem(
                'authToken',
                response.user.api_tokens[0].token
              );
            } catch (e) {
              // console.log("catch error::",e);
            } finally {
              Sentry.setContext('userInfo', {
                email: response?.user?.email || userInfo?.email,
                username: response?.user?.name || userInfo?.given_name,
                id: response?.user?.id || userInfo?.id,
                provider: userInfo?.provider,
              });
              setAuthToken(response.user.api_tokens[0].token);
            }
          }
        }
      })
      .catch((_err) => {
        // console.log('err', err);
      });
  };

  const appleSignInWeb = async (response: any) => {
    try {
      const localResponse = await AsyncStorage.getItem('appleUserInfo');
      let decodeEmail = '';

      if (!localResponse) {
        const token = response.authorization.id_token;
        const decoded = jwt_decode(token);
        if (decoded) {
          decodeEmail = decoded.email;
        }
      }
      const userData = JSON.parse(localResponse);
      const userInfoPayload = {
        id: response.authorization.id_token || userData?.authorization.id_token,
        email: response?.user?.email || userData?.user?.email || decodeEmail,
        given_name:
          response?.user?.name?.firstName || userData?.user?.name?.firstName,
        family_name:
          response?.user?.name?.lastName || userData?.user?.name?.lastName,
        provider: 'Apple',
      };
      if (response.user && response.user.email) {
        await AsyncStorage.setItem('appleUserInfo', JSON.stringify(response));
        setUserDetail && setUserDetail(userInfoPayload);
      } else {
        if (response) {
          setUserDetail && setUserDetail(userInfoPayload);
        } else {
          Alert.alert('TaskTag', t('onBoarding.error.emailNotFound'));
        }
      }
    } catch (e) {
      // console.log('Apple Sign In Error:-', e);
    }
  };

  const buttonName = params?.isLogin
    ? t('onBoarding.modal.logInWithEmail')
    : t('onBoarding.modal.signUpWithEmail');

  const title = params?.isLogin ? 'Login' : 'Get started with TaskTag';
  return (
    <Box backgroundColor='grey03' height='100%'>
      <Box
        marginBottom='m'
        marginHorizontal='m'
        alignItems='center'
        justifyContent='center'
        height='100%'>
        <Box
          alignItems='center'
          borderRadius='m'
          borderColor='grey02'
          borderWidth={1}
          py='l'
          px='xxl'
          backgroundColor='white'
          style={{
            position: 'absolute',
            height: 472,
            maxHeight: 472,
            width: 533,
            overflow: 'hidden',
          }}>
          <Icon name='Logo' width={106} height={32} marginBottom='xl' />
          <Text marginBottom='l' variant='labelLarge'>
            {title}
          </Text>
          <Button
            onPress={() => {
              prompt();
            }}
            prefix={
              <Icon
                variant='l'
                marginLeft='s'
                name='Google'
                color='textPrimary'
                accessibilityLabel='googleIcon'
              />
            }
            marginTop='s'
            disabled={loading}
            borderWidth={1}
            borderColor='textPrimary'
            backgroundColor='white'
            accessibilityLabel={t('onBoarding.modal.continueWithGoogle')}
            alignTextLeft
            fullWidth>
            <Text variant='buttonLabel' color='textPrimary'>
              {t('onBoarding.modal.continueWithGoogle')}
            </Text>
          </Button>
          <AppleSignin
            /** Auth options passed to AppleID.auth.init()  */
            authOptions={{
              clientId: process.env.APPLE_CLIENT_ID_WEB,
              scope: 'name email',
              redirectURI: process.env.APPLE_REDIRECT_UI,
              state: '',
              nonce: 'nonce',
              usePopup: true,
            }}
            render={(props) => (
              <Button
                fullWidth
                borderWidth={1}
                borderColor='textPrimary'
                backgroundColor='white'
                alignTextLeft
                marginTop='s'
                accessibilityLabel={t('onBoarding.modal.continueWithApple')}
                prefix={
                  <Icon
                    variant='l'
                    marginLeft='s'
                    name='Apple'
                    color='textPrimary'
                    accessibilityLabel={t('onBoarding.modal.continueWithApple')}
                  />
                }
                {...props}>
                <Text variant='buttonLabel' color='textPrimary'>
                  {t('onBoarding.modal.continueWithApple')}
                </Text>
              </Button>
            )}
            uiType='light'
            className='apple-auth-btn'
            onSuccess={(response: any) => appleSignInWeb(response)} // default = undefined
            // eslint-disable-next-line no-console
            onError={(error: any) => console.error('error', error)} // default = undefined
          />

          <Button
            onPress={() => {
              navigation.pop();
              clearUserInfo && clearUserInfo();
              {
                params?.isLogin
                  ? navigation.navigate('login-modal')
                  : navigation.navigate('register', { routeName: 'email' });
              }
            }}
            prefix={
              <Icon
                variant='l'
                marginLeft='s'
                name='Mail'
                color='textPrimary'
              />
            }
            borderWidth={1}
            marginTop='s'
            borderColor='textPrimary'
            backgroundColor='white'
            alignTextLeft
            fullWidth>
            <Text variant='buttonLabel' color='textPrimary'>
              {buttonName}
            </Text>
          </Button>
        </Box>
      </Box>
      {showProviderModal && (
        <ConfirmModal
          showModal={showProviderModal}
          onClose={() => setShowProviderModal(false)}
          onPress={() => setShowProviderModal(false)}
          buttonText={t('onBoarding.error.okPress')}
          title={t('onBoarding.error.title')}
          message={t('onBoarding.error.userAlreadyRegisteredWithProvider', {
            provider: errorName,
          })}
        />
      )}
    </Box>
  );
};

export default OnboardingModal;
