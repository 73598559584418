/* eslint-disable no-magic-numbers */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';

import { FloatingHeader } from '@components/Headers/FloatingHeader';
import { beginningOfToday } from '@utils/formatters/date';

import theme from '../../../themes/theme';
import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

export type DateType = {
  year: number;
  month: number;
  day: number;
};

type DatePickerProps = {
  minDate?: Date;
  maxDate?: Date;
  selectedDate: Date;
  onSelectDate: (date: Date | undefined) => void;
  isBottomSheet?: boolean;
  onClose?: () => void;
  title?: string;
};

const MONTH_NAME = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const GetDays = (year: number, month: number) => {
  const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
    days[1] = 29;
  }
  return days[month];
};

const DatePicker: React.FC<DatePickerProps> = ({
  onSelectDate,
  selectedDate,
  minDate,
  maxDate,
  isBottomSheet,
  onClose,
  title,
}) => {
  const date = selectedDate || beginningOfToday();
  const { t } = useTranslation('format');
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());
  const [currentDay, setCurrentDay] = useState(
    selectedDate ? selectedDate.getDate() : undefined
  );
  const [currentMonth, setCurrentMonth] = useState(
    selectedDate ? selectedDate.getMonth() : undefined
  );
  const [currentYear, setCurrentYear] = useState(
    selectedDate ? selectedDate.getFullYear() : undefined
  );
  const [weeks, setWeeks] = useState<any[]>([]);
  const [currentMonthDay, setCurrentMonthDay] = useState<any[]>([]);

  useEffect(() => {
    const _weeks = getWeek(currentMonthDay);
    setWeeks(_weeks);
  }, [currentDay, currentMonth, currentYear]);

  useEffect(() => {
    GetMonthData(year, month);
  }, [year, month]);

  const GetMonthData = (year: number, month: number) => {
    const currentMonthDays = [];

    const days = GetDays(year, month);

    const firstDay = new Date(year, month, 1);

    const week = firstDay.getDay();

    for (let i = 0; i < week; i++) {
      currentMonthDays.push(0);
    }

    for (let i = 1; i <= days; i++) {
      currentMonthDays.push(i);
    }

    const offset = currentMonthDays.length % 7;

    if (offset != 0) {
      for (let i = 0; i < 7 - offset; i++) {
        currentMonthDays.push(0);
      }
    }

    setCurrentMonthDay(currentMonthDays);
    const _weeks = getWeek(currentMonthDays);
    setWeeks(_weeks);
  };

  const onSelectDay = (day: number) => {
    const dateSelected = new Date(year, month, day);
    if (currentDay == day && currentMonth == month && currentYear == year) {
      setCurrentDay(undefined);
      setCurrentYear(undefined);
      setCurrentMonth(undefined);
      return;
    }
    if (day > 0) {
      if (
        (minDate && minDate > dateSelected) ||
        (maxDate && maxDate < dateSelected)
      ) {
        return;
      } else {
        setCurrentDay(day);
        setCurrentYear(year);
        setCurrentMonth(month);
      }
    }
  };

  const monthPre = () => {
    if (month == 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const monthNext = () => {
    if (month == 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const doneSelectDate = (): void => {
    const date = currentDay
      ? new Date(currentYear, currentMonth, currentDay)
      : undefined;
    onSelectDate(date);
  };

  const setDayStyle = (year: number, month: number, day: number) => {
    const thisDate = new Date(year, month, day);
    const currentDate = new Date(currentYear, currentMonth, currentDay);

    let style = undefined;

    if (
      dayjs(thisDate).format('DD/MM/YYY') ===
      dayjs(new Date()).format('DD/MM/YYY')
    ) {
      style = styles.dayToday;
    }

    if (
      dayjs(thisDate).format('DD/MM/YYY') ===
      dayjs(currentDate).format('DD/MM/YYY')
    ) {
      style = styles.daySelect;
    }

    if (minDate && minDate > thisDate) {
      style = styles.dayNotSelect;
    }

    if (maxDate && maxDate < thisDate) {
      style = styles.dayNotSelect;
    }

    if (day < 1) {
      style = styles.dayViewItem;
    }

    return style;
  };

  const getWeek = (days: number[]) => {
    const _weeks = [];
    let week = [];
    for (let i = 1; i <= days.length; i++) {
      const dayStyle = setDayStyle(year, month, days[i - 1]);
      week.push(
        <TouchableOpacity
          onPress={() => {
            onSelectDay(days[i - 1]);
          }}
          key={year + month + 'touch' + i}>
          <Box
            key={year + month + 'index' + i}
            alignItems='center'
            marginHorizontal={Platform.OS !== 'web' ? 'xxs' : 's'}
            justifyContent='center'>
            <Text variant='metadata' style={[dayStyle, styles.dayViewItem]}>
              {days[i - 1] > 0 ? days[i - 1] : ' '}
            </Text>
            {(dayStyle === styles.daySelect ||
              dayStyle === styles.dayToday) && (
              <Box style={styles.selectedDayContainer}>
                {dayStyle === styles.daySelect && (
                  <Box style={styles.selectedDay} />
                )}
                {dayStyle === styles.dayToday && (
                  <Box style={styles.dayToday} />
                )}
              </Box>
            )}
          </Box>
        </TouchableOpacity>
      );

      if (i % 7 == 0) {
        _weeks.push(
          <Box
            backgroundColor='white'
            key={year + month + 'week' + i}
            flex={1}
            flexDirection='row'
            paddingTop='s'
            justifyContent='space-between'>
            {week}
          </Box>
        );
        week = [];
      }
    }
    return _weeks;
  };

  return (
    <Box
      paddingVertical={isBottomSheet ? undefined : 'm'}
      paddingHorizontal='m'
      flex={Platform.OS === 'web' ? 1 : 0.7}>
      {isBottomSheet ? (
        <FloatingHeader
          title={title ?? t('models:tasks.create.dates.modalTitle')}
          saveButtonTitle={t('shared:done')}
          onSave={doneSelectDate}
          simple
          hideBorder
          disablePadding
          onCancel={onClose}
        />
      ) : (
        <Box
          flexDirection='row'
          justifyContent={Platform.OS === 'web' ? 'space-between' : 'flex-end'}>
          {Platform.OS === 'web' && <Box width={96}></Box>}
          <Box>
            <Text variant='labelLarge'>{title ?? t('shared:calendar')}</Text>
          </Box>
          <Box alignItems='flex-end' width={96}>
            <TouchableOpacity onPress={doneSelectDate}>
              <Text color='greenSecondary' variant='buttonLabel'>
                Done
              </Text>
            </TouchableOpacity>
          </Box>
        </Box>
      )}
      <Box
        flexDirection='row'
        flexWrap='wrap'
        justifyContent='space-between'
        marginTop='m'
        marginBottom='s'
        alignItems='flex-start'>
        <Box alignItems='center' flexDirection='row' flex={1} height={40}>
          <Icon
            onPress={monthPre}
            name='ChevronLeft'
            variant='l'
            color='textPrimary'
          />

          <Box alignItems='center' flex={1}>
            <Text textAlign='center' variant='bodyTaskName'>
              {MONTH_NAME[month]} {year}
            </Text>
          </Box>

          <Icon
            onPress={monthNext}
            name='ChevronRight'
            variant='l'
            color='textPrimary'
          />
        </Box>
      </Box>

      <Box
        flexDirection='row'
        style={styles.dayContainer}
        justifyContent='space-around'
        paddingBottom='xs'>
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((i, index) => (
          <Text key={index} variant='metadata' textAlign='center'>
            {i}
          </Text>
        ))}
      </Box>

      {weeks}
    </Box>
  );
};

const styles = StyleSheet.create({
  dayViewItem: {
    width: 37,
    height: 37,
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    paddingVertical: 10,
  },
  daySelect: {
    color: theme.colors.white,
  },
  dayNotSelect: {
    color: theme.colors.grey04,
  },
  dayContainer: {
    shadowColor: 'black',
    marginHorizontal: Platform.OS === 'ios' ? -4 : -2,
    shadowOpacity: 0.06,
    shadowOffset: { width: 0, height: 12 },
    shadowRadius: 12,
    backgroundColor: 'white',
  },
  selectedDayContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
  },
  dayToday: {
    backgroundColor: theme.colors.white,
    width: 37,
    height: 37,
    alignSelf: 'center',
    borderRadius: 18,
    borderColor: theme.colors.grey05,
    borderWidth: 1,
  },
  selectedDay: {
    backgroundColor: theme.colors.black,
    width: 37,
    height: 37,
    borderRadius: 20,
  },
});

export default DatePicker;
