import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
} from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { FlatList, Platform } from 'react-native';
import { InferType } from 'yup';

import Avatar from '@components/Avatar/Avatar';
import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import EmptyStateNoTeams from '@components/EmptyState/EmptyStateNoTeams';
import { ContactType } from '@components/Invite/Contact';
import ContactList from '@components/Invite/ContactList';
import { Box, Text } from '@components/Restyle';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { TabNavigatorSwipeSpacer } from '@components/shared/TabNavigatorSwipeSpacer';
import { TeamListItem } from '@components/Team/TeamListItem';
import { useListUsersLazyQuery, User } from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import { ThemedNavigationContainer } from '@navigation/ThemedNavigationContainer';
import { userSchema } from '@schemas/userSchema';
import theme from '@themes/theme';
import { convertUsersToContacts } from '@utils/convertUsersToContacts';

const TabNavigationContainer = Platform.select({
  default: ({ children }: { children: ReactNode }) => <>{children}</>,
  web: ({ children }: { children: ReactNode }) => (
    <ThemedNavigationContainer independent>
      {children}
    </ThemedNavigationContainer>
  ),
});

export type AddMemberType = InferType<typeof userSchema>;
export interface SelectMembersListProps {
  contacts: User[];
  hideTeamTab?: boolean;
  hideSelectedUserList?: boolean;
  showTaskTagUsers?: boolean;
  selectedUserIds?: string[];
  users?: AddMemberType[];
  updateUsers: (newUsers: AddMemberType[]) => void;
  isInviting?: boolean;
  loading?: boolean;
  alphabetize?: boolean;
  setSelectTab?: (val: string) => void;
}

export const SelectMembersList: React.FC<SelectMembersListProps> = ({
  contacts,
  hideTeamTab,
  hideSelectedUserList,
  showTaskTagUsers,
  selectedUserIds,
  users = [],
  updateUsers,
  isInviting,
  loading,
  alphabetize = true,
  setSelectTab,
}) => {
  const Tab = createMaterialTopTabNavigator();
  const navigation = useNavigation();
  const { teamsWithoutPersonal: teams } = useListTeamsFromQuery();
  const [search, setSearch] = useState<string>('');
  const [listUsers, { data: taskTagUsers }] = useListUsersLazyQuery();

  const roleLabel = 'projects.roles.member';

  const listContactsToContactType = convertUsersToContacts(contacts).map(
    (c) => {
      if (selectedUserIds?.includes(c.id)) {
        return {
          ...c,
          roleLabel,
        };
      } else {
        return c;
      }
    }
  );

  // additionally filters out listContacts => rm filter when backend does this
  const listUsersToContactType = convertUsersToContacts(
    (taskTagUsers?.listUsers as User[]) || []
  ).filter((u) => !listContactsToContactType.some((c) => c.id === u.id));

  useEffect(() => {
    search && showTaskTagUsers && listUsers({ variables: { term: search } });
  }, [search]);

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        paddingHorizontal='m'
        paddingBottom='xs'
        maxHeight={50}>
        <CustomTabBar {...props} spacing={theme.spacing.l} />
      </Box>
    );
  };

  const onPress = useCallback(
    (user: ContactType) => {
      const newSelection: AddMemberType = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        name: user.name,
        avatar: user.avatar,
        phoneNumber: user.phoneNumber,
      };

      if (users?.find((u) => u.id === user.id)) {
        const selectedUsers = users.filter((u) => u.id !== user.id);
        updateUsers(selectedUsers);
      } else {
        updateUsers([...users, newSelection]);
      }
    },
    [users]
  );

  const renderContactList = () => {
    return (
      <ContactList
        shouldDisplayPhoneContactLabel={false}
        isInviting={isInviting ?? false}
        alphabetize={alphabetize}
        disabledRoles={[roleLabel]}
        contacts={listContactsToContactType}
        filterVal={search}
        onPress={onPress}
        isEditing
        loading={loading}
        selected={users.map((u) => u.id)}
        taskTagUsers={listUsersToContactType}
        showEmptyStateNoSearch
      />
    );
  };

  return (
    <Box flex={1}>
      <Box
        marginHorizontal='m'
        marginTop='m'
        flexDirection='row'
        alignItems='center'>
        <SearchInput
          onTextChanged={(t: string) => setSearch(t)}
          value={search}
        />
      </Box>
      <Box>
        {users.length && !hideSelectedUserList ? (
          <FlatList
            style={{
              marginTop: theme.spacing.m,
              paddingBottom: theme.spacing.m,
            }}
            horizontal
            data={users}
            ItemSeparatorComponent={() => <Box marginRight='m' />}
            ListHeaderComponent={() => <Box marginRight='m' />}
            ListFooterComponent={() => <Box marginRight='m' />}
            renderItem={({ item, index }) => {
              const { id, avatar, name, firstName } = item;

              return (
                <Box key={index} alignItems='center' alignSelf='flex-start'>
                  <Avatar
                    avatar={avatar}
                    label={name}
                    onDismiss={() => {
                      const newUsers = users.filter((i) => i.id !== id);
                      updateUsers(newUsers);
                    }}
                  />
                  {firstName && (
                    <Text
                      variant='labelSmall'
                      marginTop='xs'
                      style={{ maxWidth: 64 }}
                      numberOfLines={1}>
                      {firstName}
                    </Text>
                  )}
                </Box>
              );
            }}
          />
        ) : (
          <Box marginTop='m' />
        )}
      </Box>
      <Box flex={1} style={{ height: `100%` }}>
        {!hideTeamTab ? (
          <>
            <TabNavigationContainer>
              <Tab.Navigator
                screenOptions={{ swipeEnabled: Platform.OS !== 'web' }}
                sceneContainerStyle={{
                  backgroundColor: 'transparent',
                }}
                tabBar={renderTabBar}
                initialRouteName='all-contacts'
                backBehavior='none'>
                <Tab.Screen
                  name='all-contacts'
                  options={{
                    tabBarLabel: 'All Contacts',
                    tabBarAccessibilityLabel: 'All Contacts',
                  }}
                  listeners={{
                    tabPress: () => {
                      setSelectTab && setSelectTab('all-contacts');
                    },
                  }}
                  children={renderContactList}
                />

                <Tab.Screen
                  name='teams'
                  listeners={{
                    tabPress: () => {
                      setSelectTab && setSelectTab('teams');
                    },
                  }}
                  options={{
                    tabBarLabel: 'Teams',
                    tabBarAccessibilityLabel: 'Teams',
                  }}
                  children={() => (
                    <Box flex={1}>
                      <FlatList
                        data={teams}
                        ListEmptyComponent={
                          <Box pt='xxl'>
                            <EmptyStateNoTeams />
                          </Box>
                        }
                        keyExtractor={(item) => item.id}
                        renderItem={({ item, item: { id } }) => (
                          <Box marginHorizontal='m'>
                            <TeamListItem
                              team={item}
                              onSelect={() => {
                                navigation.navigate(
                                  'select-members-from-team',
                                  {
                                    teamId: id,
                                  }
                                );
                              }}
                            />
                          </Box>
                        )}
                        ListFooterComponent={() => (
                          <Box marginBottom='listFooter' />
                        )}
                        ListHeaderComponent={() => <Box marginTop='m' />}
                        ItemSeparatorComponent={() => <Box marginBottom='l' />}
                      />
                    </Box>
                  )}
                />
              </Tab.Navigator>
            </TabNavigationContainer>
            <TabNavigatorSwipeSpacer />
          </>
        ) : (
          <>{renderContactList()}</>
        )}
      </Box>
    </Box>
  );
};
