import { WatchQueryFetchPolicy } from '@apollo/client';
import { useState } from 'react';

import { Audit, Task, useListActivitiesByTaskQuery } from '@graphql/generated';
/**
 * Retrieves a list of tasks
 */

const DEFAULT_PER_PAGE_COUNT = 10;

export const useListTaskActivityFromQuery = ({
  taskId = undefined,
  first = DEFAULT_PER_PAGE_COUNT,
  last = undefined,
  after = undefined,
  before = undefined,
  fetchPolicy = 'cache-and-network',
  onCompleted = undefined,
  onError = undefined,
  skip = false,
}: {
  taskId?: Task['id'];
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
  onCompleted?: () => void;
  onError?: () => void;
  skip?: boolean;
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const { data, loading, error, refetch, fetchMore } =
    useListActivitiesByTaskQuery({
      variables: {
        taskId,
        first,
        last,
        before,
        after,
      },
      fetchPolicy,
      onCompleted,
      onError,
      skip,
    });

  const fetchMoreFromCursor = () => {
    if (!Activities || !pageInfo?.hasNextPage || loading) return;
    const cursor = pageInfo.endCursor;
    fetchMore({
      variables: {
        after: cursor,
        first,
      },
    });
  };
  const { listActivitiesByTask } = data || {
    listActivitiesByTask: undefined,
  };

  const { edges = [], pageInfo } = listActivitiesByTask || { edges: [] };
  // TODO: This needs to be more properly typed to the returned graphql value.
  const Activities =
    (edges || []).reduce((acc, curr) => {
      if (curr && curr.node && curr.node !== undefined) {
        return [...acc, curr.node as Audit];
      } else {
        return acc;
      }
    }, [] as Audit[]) || [];

  // pagination returns duplicate tasks when tasks are named the same resulting in duplicate key errors
  // can be removed when pagination is fixed
  const ActivityIds = Activities.map((t) => t.id);
  const filterOutDuplicates = Activities.filter((t, index) => {
    return !ActivityIds.includes(t.id.toString(), index + 1);
  });
  return {
    activity: filterOutDuplicates,
    loading,
    error,
    pageInfo,
    refetch,
    fetchMore,
    fetchMoreFromCursor,
    refreshing,
    setRefreshing,
  };
};
