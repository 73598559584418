import { ProjectMemberRole, Team, useListTeamsQuery } from '@graphql/generated';

interface ListTeamsFromQueryProps {
  role?: ProjectMemberRole;
}
export const useListTeamsFromQuery = (props?: ListTeamsFromQueryProps) => {
  const {
    data: { listTeams } = {},
    loading,
    error,
    refetch,
  } = useListTeamsQuery({
    variables: { role: props?.role },
    fetchPolicy: 'cache-and-network',
  });
  const teams = (listTeams || []).map((t) => {
    if (t.personal) {
      return { ...t, name: 'Personal Projects' };
    }

    return t;
  }) as Team[];

  const teamsWithoutPersonal = (teams || []).filter((t) => !t.personal);

  return { teams, teamsWithoutPersonal, loading, error, refetch };
};
