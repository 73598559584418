import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform } from 'react-native';

import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { MemberRole, MemberType } from '@components/User/MemberRole';
import {
  GetChatDocument,
  useGetChatQuery,
  User,
  useUpdateChatAdminMutation,
  useUpdateChatMutation,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import useSearch from '@hooks/useSearch';

interface groupChatProps {
  chatId: string;
}

export const GroupChatMembersContent: React.FC<groupChatProps> = ({
  chatId,
}) => {
  const navigation = useNavigation();
  const { search } = useSearch();
  const { t } = useTranslation();
  const { me } = useMe();
  const { data, loading } = useGetChatQuery({
    variables: { id: chatId },
    skip: !chatId,
  });
  const { getChat: chat } = data || { chat: undefined };
  const { owner, leaveOn, settings } = chat || {};
  const archivedAt = !!settings?.archivedAt;
  const ownerId = owner?.id;
  const convertMembersToMembersWithRoles = (users: User[]): MemberType[] => {
    return users.map((i) => {
      return {
        user: i,
        role: i.id === ownerId ? 'Admin' : undefined,
      };
    });
  };

  const membersWithRoles = convertMembersToMembersWithRoles(
    (chat?.users as User[]) || []
  );

  useEffect(() => {
    const membersWithRoles = convertMembersToMembersWithRoles(
      (chat?.users as User[]) || []
    );
    membersWithRoles.sort((a, b) => a.user.name.localeCompare(b.user.name));
    setMemberRoleData(membersWithRoles);
    setFilteredMembers(membersWithRoles);
  }, [chat?.owner]);

  const [memberRoleData, setMemberRoleData] =
    useState<MemberType[]>(membersWithRoles);

  const [filteredMembers, setFilteredMembers] =
    useState<MemberType[]>(membersWithRoles);

  const [UpdateChatAdmin] = useUpdateChatAdminMutation();
  const [updateChat] = useUpdateChatMutation();

  const onRemoveMemberChat = (userId: string) => {
    const existingUserIds = chat?.users.map((u) => u.id) || [];
    const updateArr = existingUserIds.filter((id) => id != userId);
    updateChat({
      variables: {
        id: chatId,
        attributes: {
          userIds: updateArr,
        },
      },
    });
  };

  const onAssignAdmin = (userId: string) => {
    UpdateChatAdmin({
      variables: {
        id: chatId,
        userId: userId,
      },
      refetchQueries: [{ query: GetChatDocument }, 'getChat'],
    });
  };
  useEffect(() => {
    search
      ? setFilteredMembers(
          memberRoleData.filter((m) =>
            m?.user?.name
              ?.trim()
              .toLowerCase()
              .includes(search.trim().toLowerCase())
          )
        )
      : setFilteredMembers(
          convertMembersToMembersWithRoles((chat?.users as User[]) || [])
        );
  }, [search]);

  useEffect(() => {
    const membersWithRoles = convertMembersToMembersWithRoles(
      (chat?.users as User[]) || []
    );
    setMemberRoleData(membersWithRoles);
    setFilteredMembers(membersWithRoles);
  }, [chat?.users]);

  if (!chat || loading) {
    return <ActivityIndicatorLoading />;
  }

  return (
    <Box flex={1}>
      {Platform.OS === 'web' && (
        <Box mx='m' my='s'>
          <Text variant='labelSmall'>
            {filteredMembers.length} {t('shared:members')}
          </Text>
        </Box>
      )}
      <FlatList
        data={filteredMembers}
        renderItem={({ item }) => {
          return (
            <Box marginLeft='m' marginRight='l'>
              <MemberRole
                member={item}
                filterVal={search}
                isAdmin={ownerId == me?.id}
                onAssignAdmin={onAssignAdmin}
                onRemoveMemberChat={onRemoveMemberChat}
                onPress={() =>
                  navigation.navigate('group-chat-member-options-modal', {
                    userId: item.user.id.toString(),
                    isAdmin: ownerId == me?.id,
                    chatId: chatId,
                    userName: item.user.name,
                  })
                }
              />
            </Box>
          );
        }}
        keyExtractor={(item) => item.user.id.toString()}
        ItemSeparatorComponent={() => <Box marginTop='m' />}
        ListFooterComponent={() => <Box marginBottom='listFooter' />}
        ListHeaderComponent={() => <Box marginTop='m' />}
      />

      {Platform.OS !== 'web' &&
        me?.id === ownerId &&
        !leaveOn &&
        !archivedAt && (
          <Button
            prefix={<Icon variant='l' name='Plus' color='white' />}
            onPress={() => {
              navigation.navigate('add-chat-members-stack', {
                chatId: chat?.id,
              });
            }}
            borderRadius='xl'
            variant='primary'
            prefixMarginRight='s'
            float='bottom-right-inset'
            accessibilityLabel={t('shared:add')}>
            {t('shared:add')}
          </Button>
        )}
    </Box>
  );
};
