import React, { createContext, useContext, useState } from 'react';
import { Platform } from 'react-native';

type TabFocus =
  | 'Chats'
  | 'Projects'
  | 'Tasks'
  | 'Files'
  | 'Contacts'
  | 'Teams'
  | '';

type TabFocusContext = {
  setTabFocus: React.Dispatch<React.SetStateAction<TabFocus>>;
  tabFocus: TabFocus;
};

const tabFocusContext = createContext<TabFocusContext | undefined>(undefined);

export const TabFocusProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { Provider } = tabFocusContext;

  const [tabFocus, setTabFocus] = useState<TabFocus>(
    Platform.OS === 'web' ? '' : 'Chats'
  );

  return (
    <Provider
      value={{
        tabFocus,
        setTabFocus,
      }}>
      {children}
    </Provider>
  );
};

const useTabFocus = (): TabFocusContext => {
  const context = useContext(tabFocusContext);

  if (context === undefined) {
    throw new Error('useTabFocus must be used within a Provider');
  }
  return context;
};

export default useTabFocus;
