import React, { useState } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';

import { Box, ShadowBox } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import Text from '@components/Text/Text';
import theme from '@themes/theme';

export type SetNotificationPopupProps = {
  onDismiss?: any;
  setPauseFor: (value: string) => void;
  width?: number;
  maxHeight?: number;
  initialValue: string;
};

export const SetNotificationPopup: React.FC<SetNotificationPopupProps> = ({
  onDismiss,
  setPauseFor,
  width = 412,
  maxHeight = 120,
  initialValue,
}) => {
  const [hoveredId, setHoveredId] = useState('');
  const options = ['1 day', '1 week', 'Forever'];

  return (
    <ShadowBox
      flex={1}
      maxHeight={maxHeight}
      alignItems='flex-start'
      justifyContent='center'
      borderRadius='xs'
      variant='base'
      backgroundColor='white'>
      <Box flex={1} marginVertical='s' style={{ maxHeight: maxHeight }}>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={options}
          renderItem={({ item }) => {
            return (
              <Box
                width={width}
                paddingHorizontal='m'
                style={{
                  paddingVertical: 9,
                  backgroundColor:
                    hoveredId !== '' && hoveredId == item
                      ? theme.colors.grey01
                      : theme.colors.white,
                }}
                onMouseEnter={() => {
                  setHoveredId(item);
                }}
                onMouseLeave={() => {
                  setHoveredId('');
                }}>
                <TouchableOpacity
                  onPress={() => {
                    setPauseFor(item);
                    onDismiss && onDismiss();
                  }}>
                  <Box flexDirection='row'>
                    <Text variant='webBodySecondary'>{item}</Text>
                    <Box flex={1}></Box>
                    {item === initialValue && (
                      <Icon name='Check' variant='s' color='greenSecondary' />
                    )}
                  </Box>
                </TouchableOpacity>
              </Box>
            );
          }}
        />
      </Box>
    </ShadowBox>
  );
};
