import { useNavigation } from '@react-navigation/native';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Avatar from '@components/Avatar/Avatar';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import TextField from '@components/shared/TextField/TextField';
import { Chat, LocalFile } from '@graphql/generated';
import { MAX_GROUP_NAME_LENGTH } from '@schemas/createGroupSchema';
import { GroupChatSettingsValues } from '@screens/Chats/GroupChatSettingsProfileForm';
import { useFilePicker } from '@utils/filePicker';

interface GroupChatSettingsProfileProps {
  chat: Chat;
  loading: boolean;
}

export const GroupChatSettingsProfileContent: React.FC<
  GroupChatSettingsProfileProps
> = ({ chat, loading }) => {
  const insets = useSafeAreaInsets();
  const inputHeight = 56 + insets.bottom;
  const navigation = useNavigation();
  const { launchImageSelection } = useFilePicker();
  const { values, isValid, submitForm, touched, setTouched, setFieldValue } =
    useFormikContext<GroupChatSettingsValues>();

  const { avatar, name, isGroupChat, id: chatId } = chat;
  const { avatar: newAvatar } = values;

  const openCamera = async () => {
    navigation.navigate('expo-camera-photo-add-file', {
      onCallback: onPictureTaken,
      photoCount: 1,
    });
  };

  const onPictureTaken = async (r: LocalFile[]) => {
    r && setFieldValue('avatar', r[0]);
    setTouched({ ...touched, avatar: true });
  };

  const openGallery = async () => {
    await launchImageSelection(1).then((r) => {
      setTouched({ ...touched, avatar: true });
      r && setFieldValue('avatar', r[0]);
    });
  };

  return (
    <>
      <ScrollView style={{ flex: 1 }}>
        <Box flex={1} marginTop='l' alignItems='center' marginHorizontal='m'>
          <Avatar
            id={chatId}
            avatar={newAvatar ? newAvatar : avatar}
            size='large'
            isMultiple
            label={!isGroupChat && name ? name : null}
          />
          <TouchableOpacity
            onPress={() => {
              Platform.OS !== 'web'
                ? navigation.navigate('image-picker-modal', {
                    openCamera,
                    openGallery,
                  })
                : openGallery();
            }}>
            <Text variant='labelSmall' color='greenSecondary' marginTop='m'>
              {avatar ? 'Edit Photo' : 'Add Photo'}
            </Text>
          </TouchableOpacity>
          <Box width='100%' marginTop='l'>
            <Field
              component={TextField}
              name='name'
              label='Group Name'
              isLarge
              isRequired={true}
              showCharsRemaining
              textInputProps={{ maxLength: MAX_GROUP_NAME_LENGTH }}
            />
            <Field
              component={TextField}
              name='description'
              label='Group Description'
              placeholder='Enter description here...'
              isLarge
            />
          </Box>
        </Box>
      </ScrollView>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        keyboardVerticalOffset={Platform.select({
          ios: inputHeight,
          android: 500,
        })}>
        {Platform.OS === 'web' ? (
          <Box padding='m' flexDirection='row' justifyContent='flex-end'>
            <Button
              padding='l'
              variant='primary'
              onPress={submitForm}
              disabled={!isValid || Object.entries(touched).length < 1}>
              Save
            </Button>
          </Box>
        ) : (
          <Box marginBottom='l' marginHorizontal='m' marginTop='s'>
            <Button
              onPress={submitForm}
              variant='primary'
              disabled={
                !isValid ||
                Object.entries(touched).length < 1 ||
                loading ||
                !values.name
              }>
              Save
            </Button>
          </Box>
        )}
      </KeyboardAvoidingView>
    </>
  );
};
