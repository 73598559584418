import { PlatformPressable } from '@react-navigation/elements';
import {
  MaterialTopTabBarProps,
  createMaterialTopTabNavigator,
} from '@react-navigation/material-top-tabs';
import { NavigationContainer } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ScrollView,
  TextInput,
  StyleSheet,
  Platform,
  TouchableOpacity,
} from 'react-native';
import Toast from 'react-native-toast-notifications';

import Avatar from '@components/Avatar/Avatar';
import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import EmptyStateNoTeams from '@components/EmptyState/EmptyStateNoTeams';
import { ContactType } from '@components/Invite/Contact';
import ContactList from '@components/Invite/ContactList';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import { CollapsableSectionList } from '@components/shared/CollapsableSectionList';
import Icon from '@components/shared/Icon/Icon';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import {
  User,
  useCreateChatMutation,
  useCreateInviteMutation,
  useListContactUsersQuery,
  useListUsersLazyQuery,
} from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useMe from '@hooks/useMe';
import { MAX_GROUP_NAME_LENGTH } from '@schemas/createGroupSchema';
import theme from '@themes/theme';
import { convertUsersToContacts } from '@utils/convertUsersToContacts';

interface ModalProps {
  showModal: boolean;
  onClose?: () => void;
  onOpenChat: (data: any) => void;
}

type TeamHeader = {
  id: string;
  name: string;
  personal: boolean;
  avatar: string;
  userCount: number;
};

type TeamContent = {
  id: string;
  data: ContactType[];
};

type TeamSection = {
  sectionHeader: TeamHeader;
  data: TeamContent[];
};

export const NewChatModal: React.FC<ModalProps> = ({
  showModal,
  onClose,
  onOpenChat,
}) => {
  const Tab = createMaterialTopTabNavigator();
  const { t } = useTranslation();
  const [isGroupChat, setIsGroupChat] = useState(false);
  const { me } = useMe();
  const toastRef = useRef();

  const { data: listUsersData } = useListContactUsersQuery();
  const [listUsers, { data: taskTagUsers }] = useListUsersLazyQuery();
  const { listContactUsers } = listUsersData || { listContactUsers: [] };
  const { teamsWithoutPersonal: teams } = useListTeamsFromQuery();

  const [createChat] = useCreateChatMutation({
    onCompleted: (data) => {
      const { createChat: createChatData } = data;
      onOpenChat(createChatData);
    },
    refetchQueries: ['listChats'],
  });
  const [search, setSearch] = useState<string>('');
  const [groupUsers, setGroupUsers] = useState<ContactType[]>([]);
  const [groupStep, setGroupStep] = useState(0);
  const [groupDetaultName, setGroupDetaultName] = useState<string>('');
  const [groupInputName, setGroupInputName] = useState<string>('');

  const ref_inputBox = useRef<TextInput>(null);

  useEffect(() => {
    let newname = '';
    groupUsers.map((user) => {
      const { firstName } = user;
      if (newname != '') {
        newname += ',  ' + firstName;
      } else {
        newname += firstName;
      }
    });
    setGroupDetaultName(newname);
  }, [groupUsers]);

  const listUsersToContacts = convertUsersToContacts(
    listContactUsers as User[]
  );

  const listUsersToContactType = convertUsersToContacts(
    (taskTagUsers?.listUsers as User[]) || []
  ).filter((u) => !listUsersToContacts.some((c) => c.id === u.id));

  const sortedListUsersToContacts = listUsersToContacts.slice().sort((a, b) => {
    return a.firstName?.localeCompare(b.firstName);
  });

  const openChat = ({ firstName, lastName, id }: ContactType) => {
    const chatName = [firstName, lastName].filter((item) => !item).join(' ');
    createChatFc(chatName, [id]);
  };

  const createGroupChat = () => {
    let chatName = '';
    if (groupUsers.length == 1) {
      const { firstName, lastName } = groupUsers[0];
      chatName = [firstName, lastName].filter((item) => !item).join(' ');
    } else {
      chatName = groupInputName;
    }

    createChatFc(
      chatName,
      groupUsers.map((u) => u.id)
    );
  };

  const createChatFc = (name: string, userIds: string[]) => {
    createChat({
      variables: {
        attributes: {
          name,
          userIds,
          groupChat: userIds.length > 1,
        },
      },
    });
  };
  const [createInviteMutation] = useCreateInviteMutation({
    variables: {},
    onCompleted: (data) => {
      copyToClipboard(
        data?.createInvite.replace(
          'register/register-with-email',
          'invited-user'
        )
      );
    },
  });

  const copyToClipboard = async (link: string) => {
    toastRef.current.show('Copied', {
      duration: 2000,
    });

    await Clipboard.setStringAsync(link || '');
  };

  useEffect(() => {
    search && listUsers({ variables: { term: search } });
  }, [search]);

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        paddingHorizontal='m'
        paddingBottom='xs'
        maxHeight={50}>
        <CustomTabBar {...props} spacing={theme.spacing.l} />
      </Box>
    );
  };

  const allTeamsWithContacts: TeamSection[] = teams.map((team) => {
    const sectionHeader = {
      id: team.id,
      name: team.name,
      personal: team.personal,
      avatar: team.avatar,
      userCount: team.users.length,
    };
    const listUsersToContacts_t = convertUsersToContacts(team.users as User[]);
    const sectionContent = {
      id: team.id,
      data: listUsersToContacts_t,
    };
    return { sectionHeader: sectionHeader, data: [sectionContent] };
  });

  const sectionHeader = (item: TeamHeader) => {
    const isPersonalProject = item?.personal ?? false;
    return (
      <Box flex={1} flexDirection='row'>
        <Box
          width={40}
          height={40}
          alignItems='center'
          justifyContent='center'
          backgroundColor={isPersonalProject ? 'greenSecondary' : 'black'}
          borderRadius='xs'
          marginRight='xs'>
          {isPersonalProject ? (
            <Icon name='Office' variant='xxl' color='white' disabled={false} />
          ) : (
            <Icon name='TT' variant='xxl' color='white' disabled={false} />
          )}
        </Box>
        <Box flex={1} flexDirection='column'>
          <Text color='textPrimary' variant='labelEmphasized'>
            {isPersonalProject ? 'Personal Projects' : item.name}
          </Text>
          <Text color='textPrimary' variant='metadata1'>
            {t('models:teams.placeholders.members', {
              count: item.userCount,
            })}
          </Text>
        </Box>
      </Box>
    );
  };

  const selectContact = (item: ContactType) => {
    if (!isGroupChat) {
      openChat(item);
    } else {
      if (item.email == me?.email) {
        return;
      }
      if (groupUsers?.find((u) => u.id === item.id)) {
        const selectedUsers = groupUsers.filter((u) => u.id !== item.id);
        setGroupUsers(selectedUsers);
      } else {
        setGroupUsers([...groupUsers, item]);
      }
      setSearch('');
      ref_inputBox.current?.focus();
    }
  };

  return (
    <WebModal
      title={
        isGroupChat
          ? t('models:chat.labels.newGroupChat')
          : t('models:chat.labels.newChat')
      }
      onClose={onClose}
      visible={showModal}
      width={504}
      height={692}>
      <Box flex={1}>
        {!isGroupChat && (
          <Box m='m'>
            <PlatformPressable
              onPress={() => {
                setIsGroupChat(true);
                setGroupStep(1);
              }}>
              <Box
                accessibilityLabel='New Group Chat'
                flexDirection='row'
                justifyContent='flex-start'
                alignItems='center'
                mt='m'>
                <Icon name='Users' variant='m' />
                <Box flex={1}>
                  <Text ml='m' variant='labelEmphasized'>
                    {t('models:chat.labels.newGroupChat')}
                  </Text>
                </Box>
                <Icon name='ChevronRight' variant='m' />
              </Box>
            </PlatformPressable>
          </Box>
        )}
        {groupStep < 2 && (
          <>
            {!isGroupChat && (
              <Box m='m'>
                <Text variant='labelSmall'>
                  {t('shared:members') +
                    ' (' +
                    sortedListUsersToContacts.length +
                    ')'}
                </Text>
              </Box>
            )}
            {isGroupChat && (
              <Box m='m'>
                <Text variant='labelSmall'>
                  {t('shared:addMembers') + ' (' + groupUsers.length + ')'}
                </Text>
              </Box>
            )}

            <Box marginHorizontal='m' flexDirection='row' alignItems='center'>
              <SearchInput
                onTextChanged={setSearch}
                value={search}
                ref={ref_inputBox}
              />
            </Box>
          </>
        )}
        {groupStep == 2 && (
          <>
            <Box m='m'>
              <Text variant='labelSmall'>Give your group a name</Text>
              <TextInput
                autoFocus
                maxLength={MAX_GROUP_NAME_LENGTH}
                placeholder={
                  groupDetaultName
                    ? groupDetaultName
                    : t('models:chat.placeholders.groupChatName')
                }
                style={styles.textInput}
                placeholderTextColor={theme.colors.grey04}
                onChangeText={setGroupInputName}
                value={groupInputName}
              />
            </Box>
            <Box m='m'>
              <Text variant='labelSmall'>
                {t('shared:addMembers') + ' (' + groupUsers.length + ')'}
              </Text>
            </Box>
          </>
        )}
        {isGroupChat && groupUsers.length > 0 && (
          <Box mx='m' mt='l' maxHeight='50%'>
            <ScrollView>
              <Box flexDirection='row' flexWrap='wrap'>
                {groupUsers.map((user) => {
                  const { name, avatar, id, firstName } = user;
                  return (
                    <Box
                      key={id}
                      alignItems='center'
                      alignSelf='flex-start'
                      marginRight='s'
                      marginBottom='xs'
                      width={56}>
                      <Avatar
                        avatar={avatar}
                        label={name}
                        onDismiss={() => {
                          const newUsers = groupUsers.filter(
                            (u) => u.id !== id
                          );
                          setGroupUsers(newUsers);
                        }}
                      />
                      <Text
                        variant='metadata'
                        marginTop='xs'
                        style={{ maxWidth: 56, textOverflow: 'hidden' }}
                        numberOfLines={1}>
                        {firstName}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            </ScrollView>
          </Box>
        )}

        <Box flex={1} marginTop='m'>
          {groupStep < 2 && (
            <NavigationContainer independent={true}>
              <Tab.Navigator
                screenOptions={{ swipeEnabled: Platform.OS !== 'web' }}
                sceneContainerStyle={{
                  backgroundColor: 'transparent',
                }}
                tabBar={renderTabBar}
                initialRouteName='recent'
                backBehavior='none'>
                <Tab.Screen
                  name='all-contacts'
                  options={{
                    tabBarAccessibilityLabel: t(
                      'models:contacts.tabs.allContacts'
                    ),
                    tabBarLabel: t('models:contacts.tabs.allContacts'),
                    title: isGroupChat
                      ? t('models:chat.labels.newGroupChat')
                      : t('models:chat.labels.newChat'),
                  }}
                  initialParams={{
                    tabBarLabel: t('models:contacts.tabs.allContacts'),
                  }}
                  children={() => (
                    <Box flex={1}>
                      <ContactList
                        contacts={sortedListUsersToContacts}
                        taskTagUsers={
                          search ? listUsersToContactType : undefined
                        }
                        filterVal={search}
                        excludeList={groupUsers}
                        onPress={(item: ContactType) => selectContact(item)}
                        showEmptyStateNoSearch
                      />
                    </Box>
                  )}
                />
                <Tab.Screen
                  name='teams'
                  options={{
                    tabBarAccessibilityLabel: t('shared:teams'),
                    tabBarLabel: t('shared:teams'),
                    title: isGroupChat
                      ? t('models:chat.labels.newGroupChat')
                      : t('models:chat.labels.newChat'),
                  }}
                  initialParams={{ tabBarLabel: t('shared:teams') }}
                  children={() => (
                    <Box flex={1}>
                      <CollapsableSectionList<TeamHeader, TeamContent>
                        renderEmptyComponent={<EmptyStateNoTeams />}
                        data={allTeamsWithContacts}
                        renderSectionHeader={(item) => sectionHeader(item)}
                        renderItem={(item) => {
                          return (
                            <Box style={{ marginLeft: -16, marginRight: -16 }}>
                              <ContactList
                                contacts={item.data}
                                filterVal={search}
                                excludeList={groupUsers}
                                footerSpace={24}
                                onPress={(item: ContactType) =>
                                  selectContact(item)
                                }
                              />
                            </Box>
                          );
                        }}
                        dataListHeaderStyle={{
                          paddingBottom: 'xs',
                          marginBottom: 'xs',
                          backgroundColor: 'white',
                        }}
                      />
                    </Box>
                  )}
                />
              </Tab.Navigator>
            </NavigationContainer>
          )}
        </Box>

        {!isGroupChat && (
          <TouchableOpacity
            onPress={() => {
              createInviteMutation();
            }}>
            <Box
              mx='m'
              padding='l'
              borderTopColor='grey02'
              borderTopWidth={1}
              flexDirection='row'
              justifyContent='center'>
              <Icon
                name='Link'
                variant='s'
                color='greenSecondary'
                marginRight='xs'></Icon>
              <Text variant='labelSmall' color='greenSecondary'>
                {t('shared:copyLinkToInvite')}
              </Text>
            </Box>
          </TouchableOpacity>
        )}

        {isGroupChat && (
          <Box
            mx='m'
            px='l'
            py='m'
            flexDirection='row'
            justifyContent='flex-end'>
            <Button
              onPress={() => {
                if (groupStep == 1) {
                  setIsGroupChat(false);
                }
                setGroupStep((s) => s - 1);
              }}
              borderColor='textPrimary'
              isSmall
              backgroundColor='white'>
              <Text variant='buttonLabel' color='textPrimary' mr='m'>
                {t('shared:back')}
              </Text>
            </Button>
            <Button
              onPress={() => {
                if (groupStep == 1) {
                  setGroupStep(2);
                } else {
                  createGroupChat();
                }
              }}
              accessibilityLabel={
                groupStep == 1 ? t('shared:next') : t('shared:createGroup')
              }
              borderColor='textPrimary'
              isSmall
              backgroundColor='black'
              disabled={groupUsers.length == 0}>
              <Box px='s'>
                <Text variant='buttonLabel' color='white'>
                  {groupStep == 1 ? t('shared:next') : t('shared:createGroup')}
                </Text>
              </Box>
            </Button>
          </Box>
        )}
      </Box>
      <Toast ref={toastRef} />
    </WebModal>
  );
};

const styles = StyleSheet.create({
  textInput: {
    height: 40,
    backgroundColor: theme.colors.white,
    fontWeight: '500',
    borderRadius: 8,
    fontSize: 14,
    lineHeight: 40,
    padding: theme.spacing.m,
    borderWidth: 1,
    borderColor: theme.colors.grey02,
    marginTop: theme.spacing.m,
  },
});
