import React from 'react';
import { useWindowDimensions } from 'react-native';

import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

const EmptyStateNoFiles: React.FC = () => {
  const { height, width } = useWindowDimensions();
  const EMPTY_ICON_WIDTH = width > 375 ? width / 2 : width / 1.8;
  const EMPTY_ICON_HEIGHT = height > 750 ? height / 6.2 : height / 5;
  return (
    <Box flex={1} padding='xs' justifyContent='center'>
      <Box alignItems='center' marginTop='l'>
        <Icon
          name='NoFilesMob'
          width={EMPTY_ICON_WIDTH}
          height={EMPTY_ICON_HEIGHT}></Icon>
      </Box>

      <Box flexDirection='column' marginVertical='l'>
        <Text
          variant='heading1'
          textAlign='center'
          paddingBottom='xs'
          color='onSurfaceSecondary'>
          No files
        </Text>
        <Text
          variant='body'
          textAlign='center'
          color='onSurfaceSecondary'
          marginHorizontal='m'>
          Your files will be located here once you upload to project or tag them
          in chat.
        </Text>
      </Box>
    </Box>
  );
};

export default EmptyStateNoFiles;
