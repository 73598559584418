import React from "react";
import { FlatList, ListRenderItemInfo } from "react-native";
import { ShadowBox, Text } from "../../Restyle";
import type { MenuItem } from "./PopupItem";
import PopupItem from "./PopupItem";

export type PopupProps = {
  title?: string;
  menuList: MenuItem[];
  width?: number;
  closePopFn?: () => void;
};

const Popup: React.FC<PopupProps> = ({
  menuList,
  width,
  closePopFn,
  title,
}) => {
  const renderItem = ({ item }: ListRenderItemInfo<MenuItem>) => {
    return <PopupItem menuItem={item} closePopFn={closePopFn} />;
  };

  return (
    <ShadowBox
      flex={1}
      width={width}
      alignItems="flex-start"
      justifyContent="center"
      borderRadius="xs"
      paddingHorizontal="m"
      paddingTop="m"
      variant="base"
      backgroundColor="white"
    >
      <>
        {title && (
          <Text variant="labelSmall" color="grey04" marginBottom="m">
            {title}
          </Text>
        )}
        <FlatList
          style={{ width: `100%` }}
          data={menuList}
          renderItem={renderItem}
          keyExtractor={(item) => item.name}
        />
      </>
    </ShadowBox>
  );
};

export default Popup;
export { MenuItem };
