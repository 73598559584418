import { AuthSessionResult, makeRedirectUri } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import { maybeCompleteAuthSession } from 'expo-web-browser';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { ActionMap } from '@src/types/actionmap';

maybeCompleteAuthSession();

const enum AuthStateActionType {
  prompt = 'PROMPT',
  setResponse = 'SET_RESPONSE',
  setUserInfo = 'SET_USER_INFO',
  resetUserInfo = 'RESET_USER_INFO',
}
type UserInfo = {
  email: string;
  given_name: string;
  family_name: string;
  provider?: string;
  id: string;
};

type AuthState = {
  loading: boolean;
  response?: AuthSessionResult;
  userInfo?: UserInfo;
  clearUserInfo?: () => void;
  setUserDetail?: (value: any) => void;
};

const initialAuthState: AuthState = {
  loading: false,
};

type AuthStatePayload = {
  [AuthStateActionType.prompt]: never;
  [AuthStateActionType.setResponse]: Required<AuthState>['response'];
  [AuthStateActionType.setUserInfo]: Required<AuthState>['userInfo'];
  [AuthStateActionType.resetUserInfo]: Required<AuthState>['userInfo'];
};

type AuthStateActions =
  ActionMap<AuthStatePayload>[keyof ActionMap<AuthStatePayload>];

const authStateReducer = (
  state: AuthState,
  action: AuthStateActions
): AuthState => {
  switch (action.type) {
    case AuthStateActionType.prompt: {
      return {
        ...state,
        loading: true,
        response: undefined,
        userInfo: undefined,
      };
    }
    case AuthStateActionType.setResponse: {
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    }
    case AuthStateActionType.setUserInfo: {
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      };
    }
    case AuthStateActionType.resetUserInfo: {
      return {
        ...state,
        loading: false,
        userInfo: undefined,
        response: null,
      };
    }
    default: {
      return state;
    }
  }
};

type GoogleAuthContext = AuthState & {
  prompt: () => void;
};

const googleAuthContext = createContext<GoogleAuthContext | undefined>(
  undefined
);

export const GoogleAuthProvider: React.FC = ({ children }) => {
  const [authState, dispatch] = useReducer(authStateReducer, initialAuthState);
  const { Provider } = googleAuthContext;
  const redirectUri = makeRedirectUri();

  const [_request, googleResponse, promptAsync] = Google.useAuthRequest({
    expoClientId: process.env.G_AUTH_EXPO_CLIENT_ID,
    iosClientId: process.env.G_AUTH_IOS_CLIENT_ID,
    androidClientId: process.env.G_AUTH_ANDROID_CLIENT_ID,
    webClientId: process.env.G_AUTH_WEB_CLIENT_ID,
    redirectUri,
  });
  const { loading, userInfo, response } = authState;

  useEffect(() => {
    if (loading) {
      clearUserInfo();
      promptAsync();
    }
  }, [loading]);

  useEffect(() => {
    if (googleResponse) {
      getUserInfo(googleResponse?.authentication?.accessToken);
    }
  }, [googleResponse]);

  const getUserInfo = async (token: string) => {
    const userInfo = `/userinfo/v2/me`;
    const googleEndpoint = `${process.env.G_SIGN_IN_URL}${userInfo}`;
    try {
      const responseGoogle = await fetch(googleEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const user = await responseGoogle.json();

      if (user?.error?.code === 401) {
        console.log('Google OAuth Error', user?.error?.message);
      } else {
        dispatch({
          type: AuthStateActionType.setResponse,
          payload: { ...googleResponse, provider: 'Google' },
        });
        dispatch({
          type: AuthStateActionType.setUserInfo,
          payload: { ...user, provider: 'Google' },
        });
      }
    } catch (error) {
      // Add your own error handler here
    }
  };

  const clearUserInfo = () => {
    dispatch({
      type: AuthStateActionType.resetUserInfo,
      payload: {},
      response: null,
      userInfo: undefined,
    });
  };
  const setUserDetail = (value: UserInfo) => {
    dispatch({
      type: AuthStateActionType.setUserInfo,
      payload: value,
    });
  };

  const value = useMemo(
    () => ({
      prompt: () => dispatch({ type: AuthStateActionType.prompt }),
      userInfo,
      loading,
      response,
      clearUserInfo,
      setUserDetail,
    }),
    [userInfo, loading, response, clearUserInfo, setUserDetail]
  );

  return <Provider value={value}>{children}</Provider>;
};

const useGoogleAuth = (): GoogleAuthContext => {
  const context = useContext(googleAuthContext);
  if (context === undefined) {
    throw new Error('useGoogleAuth must be used within a Provider');
  }
  return context;
};

export { useGoogleAuth };
