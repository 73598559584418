import { useNavigation, StackActions } from '@react-navigation/native';
import debounce from 'lodash.debounce';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { WebModal } from '@components/Modals/WebModal.web';
import ProjectList from '@components/ProjectList/ProjectList';
import { Box, Text } from '@components/Restyle';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { useSearchLazyQuery, SearchableResult } from '@graphql/generated';
import { Search, SearchResultType } from '@root/types';
import { isProjectSearchResult } from '@utils/typeGuards';

const DEBOUNCE = 500;

export const ProjectCopySearch = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const [searchVal, setSearchVal] = useState('');
  const [loading, setLoading] = useState(false);

  const [globalSearch, { data }] = useSearchLazyQuery();
  const searchData = (data?.search as Search<SearchableResult>[]) || [];
  const initialResults = {
    messages: [],
    tasks: [],
    documents: [],
    projects: [],
  };

  const results =
    searchData.reduce<SearchResultType>((previous, result) => {
      if (isProjectSearchResult(result))
        return { ...previous, projects: [...previous.projects, result] };

      return previous;
    }, initialResults) || initialResults;

  const globalSearchCall = () =>
    globalSearch({
      variables: {
        term: searchVal,
        size: 20,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: true,
        includeTasks: false,
      },
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    });

  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [searchVal]
  );

  useEffect(() => {
    if (searchVal) {
      setLoading(true);
      debouncedGlobalSearch();
    }

    return () => {
      debouncedGlobalSearch.cancel();
      setLoading(false);
    };
  }, [searchVal]);

  const projectListData = searchVal
    ? results.projects.map((r) => r.record)
    : [];

  return (
    <WebModal
      visible
      title={t('models:projects.copy.searchModalTitle')}
      onClose={navigation.goBack}
      width={504}
      height={514}>
      <>
        <Box
          flexDirection='row'
          alignItems='center'
          marginTop='m'
          marginHorizontal='m'>
          <SearchInput
            onTextChanged={(newText) => setSearchVal(newText)}
            value={searchVal}
          />
        </Box>
        {searchVal ? (
          <ProjectList
            loading={loading}
            projects={projectListData}
            expanded={false}
            highlightedValue={searchVal}
            onClick={(item) => {
              navigation.dispatch(
                StackActions.replace('project-copy-select', {
                  projectId: item.id,
                })
              );
            }}
            listEmptyComponent={() => (
              <Box mt='xs' marginHorizontal='m'>
                <Text color='grey04' variant='body' textAlign='center'>
                  {t('models:projects.copy.noResults')}
                </Text>
              </Box>
            )}
          />
        ) : (
          <Text variant='body' color='grey04' textAlign='center' mt='m'>
            {t('models:projects.copy.searchCta')}
          </Text>
        )}
      </>
    </WebModal>
  );
};
