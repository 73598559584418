import { t } from 'i18next';
import React from 'react';

import { WebModal } from '@components/Modals/WebModal.web';
import { Chat } from '@graphql/generated';
import { GroupChatSettingsProfileContent } from '@screens/Chats/GroupChatSettingsProfileContent';

interface GroupChatSettingsProfileProps {
  chat: Chat;
  loading: boolean;
  onClose: () => void;
}

export const GroupChatSettingsProfile: React.FC<
  GroupChatSettingsProfileProps
> = ({ chat, loading, onClose }) => {
  return (
    <WebModal
      visible
      title={t('models:chat.chatMenuList.edit')}
      onClose={onClose}
      width={504}
      height={514}>
      <>
        <GroupChatSettingsProfileContent chat={chat} loading={loading} />;
      </>
    </WebModal>
  );
};
