import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import { Creator } from '@components/ProjectDetail/Creator';
import { Box, Text } from '@components/Restyle';
import { Badge } from '@components/shared/Badges/Badge';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import { getPriority } from '@components/TaskCard/TaskCard';
import TaskNameAndDescription from '@components/Tasks/TaskNameAndDescription';
import { ProjectMemberRole, Task, TeamMemberRole } from '@graphql/generated';
import { useTaskMembership } from '@hooks/useTaskMembership';
import { localDateFromUTCDate } from '@utils/formatters/date';
// TODO: Most of this is duplicated with mobile and requires refactoring.
export const TaskDetail: React.FC<{
  task: Task;
  isFromMyTask: boolean;
  onCreatorPress?: () => void;
  onEditDatePress?: (fieldName: string) => void;
  onPressProject?: () => void;
}> = ({
  task,
  isFromMyTask,
  onCreatorPress,
  onEditDatePress,
  onPressProject,
}) => {
  const { t } = useTranslation('models');

  const {
    name,
    description,
    skills,
    creator,
    project,
    priority,
    status,
    startDate,
    currentUserProjectRole,
    currentUserProjectTeamRole,
    dueDate,
    fixedOrder,
    dependencies = [],
    completedAt,
  } = task as Task;
  const navigation = useNavigation();
  const { isMemberOrOwner } = useTaskMembership(task);
  const isEditorOrHigher = currentUserProjectRole !== ProjectMemberRole.Viewer;
  const nonMemberView = isMemberOrOwner ? isMemberOrOwner : isEditorOrHigher;
  const isProjectTeamOwnerOrAdmin =
    currentUserProjectTeamRole === TeamMemberRole.Owner ||
    currentUserProjectTeamRole === TeamMemberRole.Admin;

  const completed = !!completedAt;
  const { name: projectName } = project || { name: undefined };
  const projectColor = undefined;

  useEffect(() => {
    if (isFromMyTask) {
      setTimeout(() => {
        navigation.navigate('members');
      }, 1000);
    }
  }, [isFromMyTask]);

  return (
    <ScrollView style={{ flex: 1, backgroundColor: 'white' }}>
      <Box
        backgroundColor='white'
        flex={1}
        marginTop='s'
        marginHorizontal='m'
        paddingBottom='listFooter'>
        <TaskNameAndDescription
          name={name}
          description={description ? description : undefined}
          completed={completed}
        />
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          marginTop='m'>
          <TouchableOpacity onPress={onPressProject}>
            <Box
              accessibilityLabel='Project title'
              flexDirection='row'
              alignItems='center'>
              <Box
                style={{ backgroundColor: projectColor }}
                padding='xxxs'
                borderRadius='xxxs'>
                <Icon
                  name='Folder'
                  color='white'
                  variant='xxs'
                  backgroundColor='greenSecondary'
                  padding='xxxs'
                  borderRadius='none'
                />
              </Box>
              <Text marginLeft='xs' variant='labelSmall'>
                {projectName}
              </Text>
            </Box>
          </TouchableOpacity>
          <Box flexDirection='row' alignItems='center'>
            <Creator
              creator={creator}
              onPress={onCreatorPress}
              disabled={
                !(
                  isMemberOrOwner ||
                  isEditorOrHigher ||
                  isProjectTeamOwnerOrAdmin
                )
              }
            />
          </Box>
        </Box>
        <Text variant='labelSmall' marginTop='m'>
          Dates
        </Text>
        <Box marginTop='xs' flexDirection='row' justifyContent='space-between'>
          <Box flex={1} marginRight='s'>
            <Button
              accessibilityLabel='Start date'
              variant={startDate ? 'editSelected' : 'edit'}
              prefixMarginRight='xs'
              prefix={
                <Icon
                  name='Calendar'
                  color={startDate ? 'textPrimary' : 'grey04'}
                />
              }
              backgroundColor='grey01'
              activeOpacity={1}
              isSmall
              onPress={() => onEditDatePress && onEditDatePress('startDate')}
              disabled={
                !(
                  isMemberOrOwner ||
                  isEditorOrHigher ||
                  isProjectTeamOwnerOrAdmin
                )
              }>
              {startDate
                ? t('format:monthAndDay', {
                    val: localDateFromUTCDate(startDate),
                  })
                : 'Start Date'}
            </Button>
          </Box>
          <Box flex={1} marginLeft='s'>
            <Button
              accessibilityLabel='Due date'
              variant={dueDate ? 'editSelected' : 'edit'}
              prefixMarginRight='xs'
              prefix={
                <Icon
                  name='Calendar'
                  color={dueDate ? 'textPrimary' : 'grey04'}
                />
              }
              activeOpacity={1}
              isSmall
              onPress={() => onEditDatePress && onEditDatePress('dueDate')}
              disabled={
                !(
                  isMemberOrOwner ||
                  isEditorOrHigher ||
                  isProjectTeamOwnerOrAdmin
                )
              }>
              {dueDate
                ? t('format:monthAndDay', {
                    val: localDateFromUTCDate(dueDate),
                  })
                : 'Due Date'}
            </Button>
          </Box>
        </Box>
        {(completed || status || priority) && (
          <Box
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-end'
            marginTop='m'>
            {/* commenting code for future reference as per TA-2464  */}
            {/* {status && (
              <TaskStatusChip
                status={task.completedAt ? TaskStatus.Completed : task.status}
              />
            )} */}
            {priority && (
              <Box accessibilityLabel='Priority'>
                <Badge
                  label={getPriority(priority).label}
                  variant={getPriority(priority).variant}
                  borderRadius='xxs'
                  paddingHorizontal='xs'
                  paddingVertical='xxs'
                  activeOpacity={1}
                />
              </Box>
            )}
          </Box>
        )}
        {(isMemberOrOwner || isEditorOrHigher || isProjectTeamOwnerOrAdmin) &&
          dependencies &&
          dependencies.length > 0 &&
          fixedOrder && (
            <>
              <Text variant='metadata' color='grey04' marginTop='m'>
                {t('models:tasks.dependency.label', {
                  count: dependencies.length,
                })}
              </Text>
              <Box flexDirection='row' flexWrap='wrap' alignItems='center'>
                <Text variant='labelSmall' color='textPrimary'>
                  {t('models:tasks.dependency.start')}
                </Text>
                {dependencies.map((d: Task) => (
                  <Box marginTop='xs' key={d.id}>
                    <Tag
                      prefix={<Icon name='Hash' color='white' variant='xs' />}
                      variant='reversed'
                      padding='xxs'
                      borderRadius='xxs'
                      marginHorizontal='xxs'
                      prefixMarginRight='xxs'
                      labelVariant='labelSmall'
                      label={d.name}
                    />
                  </Box>
                ))}
                <Text variant='labelSmall' color='textPrimary'>
                  {t('models:tasks.dependency.complete', {
                    count: dependencies.length,
                  })}
                </Text>
              </Box>
            </>
          )}
        {(isMemberOrOwner || isEditorOrHigher || isProjectTeamOwnerOrAdmin) &&
          skills &&
          skills.length > 0 && (
            <>
              <Text variant='metadata' color='grey04' marginTop='m'>
                Skills
              </Text>
              <Box flexDirection='row' flexWrap='wrap' marginTop='xxs'>
                {skills?.map(({ id, name }) => (
                  <Box key={id} marginRight='xxs' marginBottom='xs'>
                    <Tag
                      variant='status'
                      label={name}
                      padding='xxs'
                      borderRadius='xxs'
                    />
                  </Box>
                ))}
              </Box>
            </>
          )}
        {!nonMemberView && !isProjectTeamOwnerOrAdmin && (
          <Box
            flexDirection='row'
            justifyContent='center'
            alignItems='center'
            marginTop='l'>
            <Icon
              name='AlertTriangle'
              color='textPrimary'
              variant='l'
              marginRight='xs'
            />
            <Text color='textPrimary' variant='bodySecondary'>
              {t('models:tasks.nonMember')}
            </Text>
          </Box>
        )}
      </Box>
    </ScrollView>
  );
};
