import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Team } from '@graphql/generated';

interface TeamListItemProps {
  team: Pick<Team, 'id' | 'name' | 'avatar' | 'usersCount' | 'personal'>;
  onSelect: () => void;
}

export const TeamListItem: React.FC<TeamListItemProps> = ({
  team,
  onSelect,
}) => {
  const { name, usersCount, avatar, personal } = team;

  const renderAvatar = () => {
    if (personal)
      return (
        <Icon
          variant='xxl'
          padding='xxs'
          name='Office'
          backgroundColor='greenSecondaryMild'
          isCircle={false}
        />
      );
    return (
      <Icon
        variant='xxl'
        width={40}
        height={40}
        padding='none'
        name={(avatar as keyof typeof Icon) || 'TTProject'}
        backgroundColor='black'
        isCircle={false}
      />
    );
  };
  return (
    <TouchableOpacity onPress={onSelect} accessibilityLabel={name}>
      <Box flexDirection='row' alignItems='center'>
        <Box borderRadius='xs'>{renderAvatar()}</Box>

        <Box flex={1} ml='xs'>
          <Text
            variant='labelSmall'
            color='textPrimary'
            numberOfLines={1}
            ellipsizeMode='tail'>
            {name}
          </Text>
          <Text variant='metadata' color='textSecondary' mt='xs'>
            {usersCount} Members
          </Text>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};
