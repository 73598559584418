import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { ConfirmModal } from '@components/Modals/ConfirmModal';

interface NotPaidConfirmModalProps {
  showModal: boolean;
  onClose: () => void;
  onPress: () => void;
  title: string;
}

export const NotPaidConfirmModal: React.FC<NotPaidConfirmModalProps> = ({
  showModal,
  onClose,
  onPress,
  title,
}) => {
  const { t } = useTranslation();

  if (Platform.OS === 'web') {
    return (
      <ConfirmModal
        showModal={showModal}
        onClose={onClose}
        onPress={onPress}
        buttonText={t('shared:upgrade')}
        title={title + ' ' + t('shared:attachmentsExpired')}
        message={t('shared:attachmentsExpiredMessage')}
      />
    );
  }
  return null;
};
