import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
} from '@react-navigation/material-top-tabs';
import { NavigationContainer } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, TextInput } from 'react-native';
import { InferType } from 'yup';

import Avatar from '@components/Avatar/Avatar';
import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import EmptyStateNoTeams from '@components/EmptyState/EmptyStateNoTeams';
import { ContactType } from '@components/Invite/Contact';
import ContactList from '@components/Invite/ContactList';
import { AddMemberType } from '@components/Invite/SelectMembersList';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import { CollapsableSectionList } from '@components/shared/CollapsableSectionList';
import Icon from '@components/shared/Icon/Icon';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import {
  GetChatDocument,
  useGetChatQuery,
  useListContactUsersQuery,
  useListUsersLazyQuery,
  User,
  useUpdateChatMutation,
} from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useMe from '@hooks/useMe';
import addMembersSchema from '@schemas/addMembersSchema';
import theme from '@themes/theme';
import { convertUsersToContacts } from '@utils/convertUsersToContacts';

export type FormValues = InferType<typeof addMembersSchema>;

type GroupMemberListProps = {
  disabled?: boolean;
  loading?: boolean;
  chatId: string;
  onClose?: () => void;
};

type TeamHeader = {
  id: string;
  name: string;
  personal: boolean;
  avatar: string;
  userCount: number;
};

type TeamContent = {
  id: string;
  data: ContactType[];
};

type TeamSection = {
  sectionHeader: TeamHeader;
  data: TeamContent[];
};

export const GroupChatMembersList = ({
  chatId,
  onClose,
}: GroupMemberListProps) => {
  const Tab = createMaterialTopTabNavigator();

  const { data: listUsersData } = useListContactUsersQuery();
  const [{ data: taskTagUsers }] = useListUsersLazyQuery();
  const { listContactUsers } = listUsersData || { listContactUsers: [] };
  const { teamsWithoutPersonal: teams } = useListTeamsFromQuery();

  const { t } = useTranslation();
  const [selected, setSelected] = useState<AddMemberType[]>();
  const [showAddMember, setShowAddMember] = useState(false);
  const { me } = useMe();
  const [search, setSearch] = useState<string>('');
  const [groupUsers, setGroupUsers] = useState<ContactType[]>([]);

  const ref_inputBox = useRef<TextInput>(null);

  const listUsersToContacts = convertUsersToContacts(
    listContactUsers as User[]
  );

  const listUsersToContactType = convertUsersToContacts(
    (taskTagUsers?.listUsers as User[]) || []
  ).filter((u) => !listUsersToContacts.some((c) => c.id === u.id));

  const sortedListUsersToContacts = listUsersToContacts.slice().sort((a, b) => {
    return a.firstName?.localeCompare(b.firstName);
  });

  useEffect(() => {
    setSelected(selected);
  }, []);

  const { data } = useGetChatQuery({
    variables: { id: chatId },
    skip: !chatId,
  });

  const { getChat: chat } = data || { chat: undefined };

  const newMemberClose = () => {
    onClose && onClose();
  };

  const [updateChat] = useUpdateChatMutation({
    onCompleted: () => newMemberClose(),
    refetchQueries: [
      { query: GetChatDocument, variables: { id: chatId } },
      'getChat',
    ],
  });

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        paddingHorizontal='m'
        paddingBottom='xs'
        maxHeight={50}>
        <CustomTabBar {...props} spacing={theme.spacing.l} />
      </Box>
    );
  };

  const allTeamsWithContacts: TeamSection[] = teams.map((team) => {
    const sectionHeader = {
      id: team.id,
      name: team.name,
      personal: team.personal,
      avatar: team.avatar,
      userCount: team.users.length,
    };
    const listUsersToContacts_t = convertUsersToContacts(team.users as User[]);
    const sectionContent = {
      id: team.id,
      data: listUsersToContacts_t,
    };
    return { sectionHeader: sectionHeader, data: [sectionContent] };
  });

  const sectionHeader = (item: TeamHeader) => {
    const isPersonalProject = item?.personal ?? false;
    return (
      <Box flex={1} flexDirection='row'>
        <Box
          width={40}
          height={40}
          alignItems='center'
          justifyContent='center'
          backgroundColor={isPersonalProject ? 'greenSecondary' : 'black'}
          borderRadius='xs'
          marginRight='xs'>
          {isPersonalProject ? (
            <Icon name='Office' variant='xxl' color='white' disabled={false} />
          ) : (
            <Icon name='TT' variant='xxl' color='white' disabled={false} />
          )}
        </Box>
        <Box flex={1} flexDirection='column'>
          <Text color='textPrimary' variant='labelEmphasized'>
            {isPersonalProject ? 'Personal Projects' : item.name}
          </Text>
          <Text color='textPrimary' variant='metadata1'>
            {t('models:teams.placeholders.members', {
              count: item.userCount,
            })}
          </Text>
        </Box>
      </Box>
    );
  };

  const selectContact = (item: ContactType) => {
    if (
      item.email == me?.email ||
      chat!.users.filter((u) => u.email == item.email).length > 0
    ) {
      return;
    }
    if (groupUsers?.find((u) => u.id === item.id)) {
      const selectedUsers = groupUsers.filter((u) => u.id !== item.id);
      setGroupUsers(selectedUsers);
    } else {
      setGroupUsers([...groupUsers, item]);
    }
    setSearch('');
    ref_inputBox.current?.focus();
  };

  const addMembersToGroup = () => {
    const existingUserIds = chat?.users.map((u) => u.id) || [];

    if (!chat?.id || !existingUserIds) return;

    const newUserIds: string[] = groupUsers.map((u) => u.id);

    updateChat({
      variables: {
        id: chat.id,
        attributes: {
          userIds: [...existingUserIds, ...newUserIds],
        },
      },
    });
  };

  const addMemberAlert = () => {
    addMembersToGroup();
  };

  return (
    <>
      <Box flex={1}>
        <Box m='m'>
          <Text variant='labelSmall'>
            {t('shared:addMembers') + ' (' + groupUsers.length + ')'}
          </Text>
        </Box>

        <Box marginHorizontal='m' flexDirection='row' alignItems='center'>
          <SearchInput
            onTextChanged={setSearch}
            value={search}
            ref={ref_inputBox}
          />
        </Box>

        {groupUsers.length > 0 && (
          <Box mx='m' mt='l' maxHeight='50%'>
            <ScrollView>
              <Box flexDirection='row' flexWrap='wrap'>
                {groupUsers.map((user) => {
                  const { name, avatar, id, firstName } = user;
                  return (
                    <Box
                      key={id}
                      alignItems='center'
                      alignSelf='flex-start'
                      marginRight='s'
                      marginBottom='xs'
                      width={56}>
                      <Avatar
                        avatar={avatar}
                        label={name}
                        onDismiss={() => {
                          const newUsers = groupUsers.filter(
                            (u) => u.id !== id
                          );
                          setGroupUsers(newUsers);
                        }}
                      />
                      <Text
                        variant='metadata'
                        marginTop='xs'
                        style={{ maxWidth: 56, textOverflow: 'hidden' }}
                        numberOfLines={1}>
                        {firstName}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            </ScrollView>
          </Box>
        )}

        <Box flex={1} marginTop='m'>
          <NavigationContainer independent={true}>
            <Tab.Navigator
              screenOptions={{ swipeEnabled: Platform.OS !== 'web' }}
              sceneContainerStyle={{
                backgroundColor: 'transparent',
              }}
              tabBar={renderTabBar}
              initialRouteName='recent'
              backBehavior='none'>
              <Tab.Screen
                name='all-contacts'
                options={{
                  tabBarAccessibilityLabel: t(
                    'models:contacts.tabs.allContacts'
                  ),
                  tabBarLabel: t('models:contacts.tabs.allContacts'),
                  title: t('models:chat.labels.newGroupChat'),
                }}
                initialParams={{
                  tabBarLabel: t('models:contacts.tabs.allContacts'),
                }}
                children={() => (
                  <Box flex={1}>
                    <ContactList
                      contacts={sortedListUsersToContacts}
                      taskTagUsers={search ? listUsersToContactType : undefined}
                      filterVal={search}
                      excludeList={[...groupUsers, ...chat!.users]}
                      onPress={(item: ContactType) => selectContact(item)}
                      showEmptyStateNoSearch
                    />
                  </Box>
                )}
              />
              <Tab.Screen
                name='teams'
                options={{
                  tabBarAccessibilityLabel: t('shared:teams'),
                  tabBarLabel: t('shared:teams'),
                  title: t('models:chat.labels.newGroupChat'),
                }}
                initialParams={{ tabBarLabel: t('shared:teams') }}
                children={() => (
                  <Box flex={1}>
                    <CollapsableSectionList<TeamHeader, TeamContent>
                      renderEmptyComponent={<EmptyStateNoTeams />}
                      data={allTeamsWithContacts}
                      renderSectionHeader={(item) => sectionHeader(item)}
                      renderItem={(item) => {
                        return (
                          <Box
                            style={{
                              marginLeft: -16,
                              marginRight: -16,
                            }}>
                            <ContactList
                              contacts={item.data}
                              filterVal={search}
                              excludeList={groupUsers}
                              footerSpace={24}
                              onPress={(item: ContactType) =>
                                selectContact(item)
                              }
                            />
                          </Box>
                        );
                      }}
                      dataListHeaderStyle={{
                        paddingBottom: 'xs',
                        marginBottom: 'xs',
                        backgroundColor: 'white',
                      }}
                    />
                  </Box>
                )}
              />
            </Tab.Navigator>
          </NavigationContainer>
        </Box>
        <Box mx='m' px='l' py='m' flexDirection='row' justifyContent='flex-end'>
          <Button
            onPress={() => {
              onClose && onClose();
            }}
            borderColor='textPrimary'
            isSmall
            backgroundColor='white'>
            <Text variant='buttonLabel' color='textPrimary' mr='m'>
              {t('shared:back')}
            </Text>
          </Button>
          <Button
            onPress={() => {
              setShowAddMember(true);
            }}
            borderColor='textPrimary'
            isSmall
            backgroundColor='black'
            disabled={groupUsers.length == 0}>
            <Box px='s'>
              <Text variant='buttonLabel' color='white'>
                {t('shared:add')}
              </Text>
            </Box>
          </Button>
        </Box>
      </Box>

      {showAddMember && (
        <ConfirmModal
          showModal={showAddMember}
          onClose={() => setShowAddMember(false)}
          onPress={() => addMemberAlert()}
          buttonText={t('shared:add')}
          title={t('models:chat.addMembers.cta')}
          message={t('models:chat.addMembers.messageInterval', {
            postProcess: 'interval',
            count: groupUsers.length,
            members: groupUsers.map((u) => u.firstName).slice(0, 4),
            chatName: chat?.name,
            restCount: groupUsers.length - 4,
          })}
        />
      )}
    </>
  );
};
