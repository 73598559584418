import dayjs from 'dayjs';

import { Task, TaskMemberRole, TaskStatus } from '@graphql/generated';
import { TaskTagType } from '@src/types/task';

import type { DateRange } from '@components/DateRangePicker/DateRangePickerModal';
import type { Dayjs } from 'dayjs';

export const filterOutStatusFromTasks = (
  tasks: Task | Task[] | TaskTagType[],
  excludeStatus: TaskStatus
): Task[] | TaskTagType[] => {
  return tasks.filter((t) => t.status !== excludeStatus);
};

export const filterByDateRange = <T extends Record<string, unknown>>(
  tasks: T[],
  keyFunc: (obj: T) => Dayjs | string,
  { start, end }: DateRange
) => {
  return tasks.filter((item) => {
    const dueDate = dayjs(keyFunc(item));
    if (start && end) {
      const diff1 = dueDate.diff(start, 'day') > -1;
      const diff2 = dueDate.diff(end, 'day') < 1;
      return diff1 && diff2;
    } else if (start && !end) {
      const diff1 = dueDate.diff(start, 'day') > -1;
      return diff1;
    } else if (!start && end) {
      const diff2 = dueDate.diff(end, 'day') < 1;
      return diff2;
    } else {
      return true;
    }
  });
};

export const selectableTaskRoles = Object.values(TaskMemberRole).filter(
  (r) => r !== TaskMemberRole.Owner
);
export const defaultTaskRole = TaskMemberRole.Assignee;
export const defaultAssignMemberTaskRole = TaskMemberRole.Assignee;
export const getTaskRoleEnumValue = (role: string) => {
  const indexOfRole = Object.values(TaskMemberRole).indexOf(
    role as TaskMemberRole
  );
  return Object.values(TaskMemberRole)[indexOfRole];
};
