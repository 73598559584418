"use strict";
import PropTypes from "prop-types";
import React, { memo } from "react";

import DayRow from "./DataRow";
import { DayPropsType } from "./Day";
import DayHeader from "./DayHeader";
import { dayJsMod } from "./helper";
import { Box, Text } from "../Restyle";

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const areEqual = (prevProps, nextProps) => {
  if (nextProps.minDate != prevProps.minDate) return false;

  if (nextProps.maxDate != prevProps.maxDate) return false;

  if (nextProps.availableDates != prevProps.availableDates) return false;

  if (nextProps.startDate != prevProps.startDate) return false;

  if (nextProps.untilDate != prevProps.untilDate) return false;

  return true;
};

export type MonthPropsType = {
  month: string;
  dayProps: DayPropsType;
  titleStyle: string;
  titleFormat: string;
  capitalizeTitle: string;
  dayHeaderProps: string;
  startDate: string;
  untilDate: string;
  availableDates: string;
  minDate: string;
  maxDate: string;
  ignoreMinDate: string;
  selectFlag: boolean;
};

const Month = memo((props: MonthPropsType) => {
  const {
    month,
    dayProps,
    titleStyle,
    titleFormat,
    capitalizeTitle,
    dayHeaderProps,
    selectFlag,
  } = props;
  const getDayStack = (month) => {
    const res = [];
    const currMonth = dayJsMod(month).month(); //get this month
    let currDate = dayJsMod(month).startOf("month"); //get first day in this month

    let dayColumn = [];
    const dayRow = [];
    let dayObject = {};
    const {
      startDate,
      untilDate,
      availableDates,
      minDate,
      maxDate,
      ignoreMinDate,
    } = props;

    do {
      dayColumn = [];
      for (let i = 0; i < 7; i++) {
        dayObject = {
          type: null,
          date: null,
        };
        if (
          currDate.format("YYYYMMDD") == dayJsMod(new Date()).format("YYYYMMDD")
        ) {
          dayObject.type = "today";
        }
        if (i == currDate.day() && currDate.month() == currMonth) {
          if (
            minDate &&
            minDate.format("YYYYMMDD") &&
            currDate.format("YYYYMMDD") < minDate.format("YYYYMMDD")
          ) {
            if (
              startDate &&
              startDate.format("YYYYMMDD") > currDate.format("YYYYMMDD") &&
              currDate.format("YYYYMMDD") >
                dayJsMod(null, null, null).format("YYYYMMDD") &&
              ignoreMinDate
            ) {
            } else {
              dayObject.type = "disabled";
            }
          }
          if (
            maxDate &&
            maxDate.format("YYYYMMDD") &&
            currDate.format("YYYYMMDD") > maxDate.format("YYYYMMDD")
          ) {
            dayObject.type = "disabled";
          }
          if (
            availableDates &&
            availableDates.indexOf(currDate.format("YYYYMMDD")) == -1
          ) {
            dayObject.type = "blockout";
          }
          if (
            startDate &&
            startDate.format("YYYYMMDD") == currDate.format("YYYYMMDD")
          ) {
            if (!untilDate) {
              dayObject.type = "single";
            } else {
              dayObject.type = "first";
            }
          }
          if (
            untilDate &&
            untilDate.format("YYYYMMDD") == currDate.format("YYYYMMDD")
          ) {
            dayObject.type = "last";
          }
          if (
            startDate &&
            startDate.format("YYYYMMDD") < currDate.format("YYYYMMDD") &&
            untilDate &&
            untilDate.format("YYYYMMDD") > currDate.format("YYYYMMDD")
          ) {
            dayObject.type = "between";
          }

          dayObject.date = currDate.clone().format("YYYYMMDD");
          dayColumn.push(dayObject);
          currDate = currDate.add(1, "day");
        } else {
          if (
            startDate &&
            untilDate &&
            startDate.format("YYYYMMDD") < currDate.format("YYYYMMDD") &&
            untilDate.format("YYYYMMDD") >= currDate.format("YYYYMMDD")
          ) {
            dayObject.type = "between";
          }

          dayColumn.push(dayObject);
        }
      }

      dayRow.push(dayColumn);
    } while (currDate.month() == currMonth);

    return dayRow;
  };

  const dayStack = getDayStack(dayJsMod(month, "YYYYMM"));

  return (
    <Box>
      <Text style={{ color: props.textColor, ...titleStyle }}>
        {capitalizeTitle
          ? capitalize(dayJsMod(month, "YYYYMM").format(titleFormat))
          : dayJsMod(month, "YYYYMM").format(titleFormat)}
      </Text>
      {props.showDaysHeader && <DayHeader {...dayHeaderProps} />}
      <Box>
        {dayStack.map((days, i) => {
          return (
            <DayRow
              days={days}
              flag={selectFlag}
              dayProps={dayProps}
              key={i}
              onSelectDate={props.onSelectDate}
              textColor={props.textColor}
            />
          );
        })}
      </Box>
    </Box>
  );
}, areEqual);

Month.defaultProps = {
  titleFormat: "MMMM",
  titleStyle: { fontSize: 16, fontWeight: "600" },
  dayHeaderProps: {},
  showDaysHeader: false,
  capitalizeTitle: false,
};

Month.propTypes = {
  titleFormat: PropTypes.string,
  titleStyle: PropTypes.object,
  dayHeaderProps: PropTypes.object,
  showDaysHeader: PropTypes.bool,
  capitalizeTitle: PropTypes.bool,
};

export default Month;
