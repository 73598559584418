import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import theme from '../../../themes/theme';
import { Box, Text } from '../Restyle';

interface ModalHeaderProps {
  title: string;
  submitLabel?: string;
  onCancel: () => void;
  onSubmit?: () => void;
  showCancel?: boolean;
  cancelColor?: keyof typeof theme.colors;
  submitColor?: keyof typeof theme.colors;
  disabled?: boolean;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  submitLabel,
  onCancel,
  onSubmit,
  showCancel,
  cancelColor,
  submitColor,
  disabled = false,
}) => {
  const { t } = useTranslation('shared');
  return (
    <Box flexDirection='row' justifyContent='space-between'>
      {showCancel && (
        <TouchableOpacity onPress={onCancel} accessibilityLabel='Cancel'>
          <Text variant='buttonLabel' color={cancelColor || 'grey04'}>
            {t('cancel')}
          </Text>
        </TouchableOpacity>
      )}
      <Box
        style={{ position: `absolute`, left: 0, right: 0 }}
        alignItems='center'
        pointerEvents='none'>
        <Text variant='labelEmphasized'>{title}</Text>
      </Box>
      {onSubmit && (
        <TouchableOpacity
          onPress={onSubmit}
          disabled={disabled}
          accessibilityLabel={submitLabel}>
          <Text variant='buttonLabel' color={submitColor || 'greenSecondary'}>
            {submitLabel}
          </Text>
        </TouchableOpacity>
      )}
    </Box>
  );
};
