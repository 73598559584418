import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, AlertButton, TouchableOpacity } from 'react-native';

import { Alert } from '@components/Alert/index.web';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import { getApiConfig } from '@utils/getApiConfig';

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const apiConfig = getApiConfig();
  const [resetLoadingState, setResetLoadingState] = useState<boolean>(false);
  const route = useRoute();
  const xemail = route?.params?.email ?? '';

  const [countdown, setCountdown] = useState(30);
  const [isActive, setIsActive] = useState(true);

  const startCountdown = () => {
    setCountdown(30);
    setIsActive(true);
  };

  const stopCountdown = () => {
    setIsActive(false);
  };

  useEffect(() => {
    let _timer: NodeJS.Timer;

    if (isActive && countdown > 0) {
      _timer = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsActive(false);
    }

    return () => clearInterval(_timer);
  }, [isActive, countdown]);

  const forgotPassword = async () => {
    if (xemail.length == 0) return;
    if (resetLoadingState) return;
    const resetEndpoint = `${apiConfig.apiUrl}/api/v1/password.json`;

    setResetLoadingState(true);

    await fetch(resetEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          email: xemail,
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.success) {
          const title = t('shared:error');
          const okayButton: AlertButton = {
            text: t('shared:okay'),
            style: 'cancel',
          };
          Alert.alert(title, response?.error, [okayButton]);
        } else {
          startCountdown();
        }
      })
      .catch((err) => console.log('err===', err))
      .finally(() => setResetLoadingState(false));
  };

  return (
    <Box backgroundColor='grey01' height='100%'>
      <Box alignItems='center' justifyContent='center' height='100%'>
        <Box
          alignItems='center'
          borderRadius='m'
          backgroundColor='white'
          style={{
            paddingVertical: 32,
            paddingHorizontal: 40,
            shadowColor: 'black',
            shadowOpacity: 0.05,
            shadowOffset: { width: 0, height: 25 },
            shadowRadius: 25,
            position: 'absolute',
            width: 440,
            overflow: 'hidden',
          }}>
          <Icon name='Logo' width={142} height={43} />
          <Text
            style={{ marginTop: 40 }}
            mb='xs'
            variant='heading2'
            color='black'>
            {t('shared:resetPassword')}
          </Text>
          <Text variant='webBodySecondary' color='black'>
            {t('models:onBoarding.modal.checkInbox')}
          </Text>
          <Box mt='l' height={48}>
            <Text variant='webBodySecondary' color='black'>
              {t('models:onBoarding.modal.openEmailApp')}
            </Text>
          </Box>
          <Box alignItems='center' flexDirection='row'>
            {(countdown == 0 || !isActive) && (
              <Text variant='webBodySecondary' color='textSecondary'>
                {t('models:onBoarding.modal.didntReceiveTheEmail')}{' '}
                <Text
                  variant='webBodySecondary'
                  color='greenSecondary'
                  disabled={resetLoadingState}
                  onPress={forgotPassword}>
                  {t('models:onBoarding.modal.resendEmail')}
                </Text>
              </Text>
            )}
            {(countdown == 0 || !isActive) && resetLoadingState && (
              <Box marginLeft='s'>
                <ActivityIndicator style={{ height: 16 }} />
              </Box>
            )}
            {countdown > 0 && isActive && (
              <Text variant='webBodySecondary' color='textSecondary'>
                {t('models:onBoarding.modal.resendEmailIn', {
                  time:
                    (countdown.toString().length == 1 ? '00:0' : '00:') +
                    countdown.toString(),
                })}
              </Text>
            )}
          </Box>

          <TouchableOpacity
            onPress={() => {
              stopCountdown();
              navigation.goBack();
            }}>
            <Box
              flexDirection='row'
              mt='l'
              height={48}
              alignItems='center'
              accessibilityLabel={t('models:onBoarding.modal.backToLogin')}>
              <Icon name='ArrowLeft' variant='s' marginRight='xs' />
              <Text variant='labelSmall' color='black'>
                {t('models:onBoarding.modal.backToLogin')}
              </Text>
            </Box>
          </TouchableOpacity>
        </Box>
      </Box>
    </Box>
  );
};
