import { StackNavigationOptions } from "@react-navigation/stack";

export const modalStyle: StackNavigationOptions = {
  headerShown: false,
  headerTransparent: true,
  presentation: "transparentModal",

  cardStyleInterpolator: ({ current: { progress } }) => ({
    cardStyle: {
      transform: [
        {
          translateY: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [500, 0],
            extrapolate: "clamp",
          }),
        },
      ],
    },
    overlayStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 0.6],
        extrapolate: "clamp",
      }),
    },
  }),
  cardOverlayEnabled: true,
  gestureDirection: "vertical",
};

export const clearModalStyle: StackNavigationOptions = {
  headerShown: false,
  headerTransparent: true,
  presentation: "transparentModal",

  cardStyleInterpolator: ({ current: { progress } }) => ({
    cardStyle: {
      transform: [
        {
          translateY: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [500, 0],
            extrapolate: "clamp",
          }),
        },
      ],
    },
    overlayStyle: {
      opacity: 0,
    },
  }),
  cardOverlayEnabled: true,
  gestureDirection: "vertical",
};

export const fadeStyle = {
  cardStyleInterpolator: ({ current: { progress } }) => ({
    cardStyle: {
      opacity: progress,
    },
  }),
};

export type NestedNavigatorParams<ParamList> = {
  [K in keyof ParamList]: undefined extends ParamList[K]
    ? { screen: K; params?: ParamList[K] }
    : { screen: K; params: ParamList[K] };
}[keyof ParamList];
