import { t } from 'i18next';
import React from 'react';

import { WebModal } from '@components/Modals/WebModal.web';

import { ChatRelatedTagsContent } from './ChatRelatedTagsContent.web';

interface ChatRelatedTagsProps {
  chatId?: string;
  onClose: () => void;
}

export const ChatRelatedTags: React.FC<ChatRelatedTagsProps> = ({
  chatId,
  onClose,
}) => {
  return (
    <WebModal
      visible
      title={t('models:chat.chatMenuList.relatedTags')}
      onClose={onClose}
      width={504}
      height={514}>
      <>
        <ChatRelatedTagsContent
          chatId={chatId}
          onClose={onClose}
          filterVal=''
          isFrom='Chat'
        />
      </>
    </WebModal>
  );
};
