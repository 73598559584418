import { ContactType } from '@components/Invite/Contact';
import { User } from '@graphql/generated';

export const convertUsersToContacts = (contacts: User[]): ContactType[] => {
  const launchOptimistic = contacts.map(function (u) {
    const lastName = u?.lastName ?? '';

    const name = u.firstName + ' ' + lastName;
    return {
      ...u,
      firstName: u.firstName ?? undefined,
      lastName: u.lastName ?? undefined,
      displayName: u.name ?? name ?? '',
      initials: '',
      avatar: u.avatar ?? undefined,
      email: u.email ?? undefined,
      friendRequestPending: u.friendRequestPending ?? false,
    };
  });
  return launchOptimistic;
};
