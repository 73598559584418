import {
  BottomSheetModal,
  BottomSheetModalProvider,
} from '@gorhom/bottom-sheet';
import { useNavigation } from '@react-navigation/native';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, StyleSheet } from 'react-native';

import { Alert } from '@components/Alert';
import { AddMembersSection } from '@components/Common/AddMembersSection';
import { TeamSelect } from '@components/FormModals/TeamSelect';
import { FloatingHeader } from '@components/Headers/FloatingHeader';
import { BottomSheetBackdrop } from '@components/Modals/BottomSheetBackdrop';
import { FormValues } from '@components/Projects/ProjectFormStack';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import ListNavigationItem from '@components/shared/ListNavigationItem/ListNavigationItem';
import TextField from '@components/shared/TextField/TextField';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useSelectedMembers from '@hooks/useSelectedMembers';
import theme from '@themes/theme';
import { getProjectRoleEnumValue } from '@utils/projects';

export const ProjectCreateBottomSheet: React.FC = () => {
  const navigation = useNavigation();
  const { teams } = useListTeamsFromQuery();
  const { t } = useTranslation();
  const subSnapPoints = ['50%', '75%'];
  const snapPoints = [...subSnapPoints, '90%'];

  const handleSheetChanges = (index: number) => {
    if (index < 0) {
      navigation.goBack();
    }
  };
  const bottomSheetRef = useRef<BottomSheetModal>(null);
  const {
    setSelectedMembers,
    selectedMembers,
    showTeamSelect,
    setShowTeamSelect,
  } = useSelectedMembers();

  const { submitForm, setFieldValue, isSubmitting, values, errors } =
    useFormikContext<FormValues>();

  const { teamId = '', name, description, currentTeam } = values;

  const selectedTeam = teams.find((item) => item.id === teamId);
  const { name: selectedTeamName } = selectedTeam || {};

  const [touchedTeam, setTouchedTeam] = useState(false);

  const removeMemberAlert = (memberId: string) => {
    Alert.alert(
      t('models:editMembers.removeMember.removeTitle'),
      t('models:editMembers.removeMember.messageGeneric'),
      [
        {
          text: t('shared:cancel'),
        },
        {
          text: t('shared:remove'),

          onPress: () => {
            setSelectedMembers(
              selectedMembers?.filter((u) => u.id !== memberId)
            );
          },
        },
      ]
    );
  };

  const updateMember = (userId: string, role: string) => {
    const members = selectedMembers?.map((u) => {
      if (u.id !== userId) return u;
      else {
        return { ...u, role: getProjectRoleEnumValue(role) };
      }
    });
    setSelectedMembers(members);
  };

  const dismissProjectFormModal = () => {
    if (bottomSheetRef.current) {
      bottomSheetRef.current.dismiss();
    }
  };

  useEffect(() => {
    if (bottomSheetRef.current) {
      bottomSheetRef.current.present();
    }
  }, [bottomSheetRef]);

  useEffect(() => {
    setFieldValue('users', selectedMembers);
  }, [selectedMembers]);

  useEffect(() => {
    setSelectedMembers([]);
    return () => setSelectedMembers([]);
  }, []);

  const bottomSheetTeamModalRef = useRef<BottomSheetModal>(null);
  const openTeamSelectModal = () => {
    if (bottomSheetTeamModalRef.current) {
      bottomSheetTeamModalRef.current.present();
    }
  };
  const dismissTeamSelectModal = () => {
    if (bottomSheetTeamModalRef.current) {
      bottomSheetTeamModalRef.current.dismiss();
    }
  };

  return (
    <>
      <BottomSheetModalProvider>
        <BottomSheetModal
          ref={bottomSheetRef}
          index={1}
          enablePanDownToClose
          snapPoints={snapPoints}
          onChange={handleSheetChanges}
          backdropComponent={BottomSheetBackdrop}
          handleComponent={() => <Box style={styles.closeLine} />}>
          <Box flex={1}>
            <FloatingHeader
              title={t('models:projects.create.title')}
              onSave={submitForm}
              saveButtonTitle={t('shared:done')}
              disabled={!name || !teamId || isSubmitting}
              simple
              hideBorder
              onCancel={dismissProjectFormModal}
            />
            <ScrollView style={{ flex: 1 }}>
              <Box
                flex={1}
                marginHorizontal='m'
                marginTop='s'
                style={{ paddingBottom: 120 }}>
                <Box
                  borderBottomColor='grey02'
                  borderBottomWidth={1}
                  marginBottom='l'
                  marginTop='m'>
                  <ListNavigationItem
                    isLarge
                    hideChevron
                    title='Copy from existing project'
                    iconName='Copy'
                    // project-copy-search sits in project-form-stack for web. In mobile, it is in project-stack
                    onPress={() => {
                      navigation.goBack();
                      navigation.navigate(
                        Platform.OS === 'web'
                          ? 'project-copy-search'
                          : 'project-search'
                      );
                    }}
                  />
                </Box>
                <Field
                  component={TextField}
                  name='name'
                  isRequired
                  autoFocus
                  borderless
                  isLargeText
                  showCharsRemaining={name?.length > 0}
                  textInputProps={{ maxLength: 25 }}
                  placeholder={t('models:projects.placeholders.name')}
                />
                <Field
                  component={TextField}
                  label='Description'
                  name='description'
                  borderless
                  isLargeText
                  showCharsRemaining={description?.length > 0}
                  textInputProps={{ maxLength: 125 }}
                  placeholder={t('models:projects.placeholders.description')}
                  accessibilityLabel={t(
                    'models:projects.placeholders.description'
                  )}
                />
                <Box
                  flexDirection='row'
                  alignItems='flex-end'
                  justifyContent='center'>
                  <Text marginBottom='xs' variant='labelSearch'>
                    {t('models:projects.create.teams.team')}
                  </Text>
                  <Box flex={1}></Box>
                  <Text marginBottom='xs' variant='labelRequired'>
                    {t('models:projects.create.required')}
                  </Text>
                </Box>

                {selectedTeam && teamId ? (
                  <Button
                    variant='edit'
                    marginBottom='s'
                    fullWidth
                    isSmall
                    prefixMarginRight='xs'
                    prefix={
                      <Box
                        width={24}
                        height={24}
                        alignItems='center'
                        justifyContent='center'
                        backgroundColor={
                          selectedTeam?.personal ? 'greenSecondary' : undefined
                        }
                        borderRadius='xxs'
                        marginRight='m'>
                        {selectedTeam?.personal ? (
                          <Icon
                            name='Office'
                            variant='s'
                            color='white'
                            disabled={false}
                          />
                        ) : (
                          <Icon
                            name='Logo'
                            variant='s'
                            color='white'
                            disabled={false}
                          />
                        )}
                      </Box>
                    }
                    onPress={() => {
                      setTouchedTeam(true);
                      setShowTeamSelect(true);
                      openTeamSelectModal();
                    }}
                    accessibilityLabel={t(
                      'models:projects.create.teams.addTeam'
                    )}>
                    {(selectedTeamName || currentTeam?.name) ??
                      t('models:projects.create.teams.addTeam')}
                  </Button>
                ) : (
                  <>
                    <Button
                      accessibilityLabel={t(
                        'models:projects.create.teams.addTeam'
                      )}
                      variant='edit'
                      fullWidth
                      onPress={() => {
                        setTouchedTeam(true);
                        setShowTeamSelect(true);
                        openTeamSelectModal();
                      }}
                      isSmall>
                      {t('models:projects.create.teams.addTeam')}
                    </Button>
                    {errors.teamId && touchedTeam && !showTeamSelect && (
                      <Box
                        flexDirection='row'
                        alignItems='center'
                        marginTop='s'>
                        <Icon
                          name='AlertTriangle'
                          color='alertRed'
                          variant='s'
                          marginRight='xxs'
                        />
                        <Text variant='error'>{errors.teamId}</Text>
                      </Box>
                    )}
                  </>
                )}
                <AddMembersSection
                  isCreateProject
                  members={selectedMembers}
                  updateMember={(member, role) => updateMember(member.id, role)}
                  removeMember={(member) => removeMemberAlert(member.id)}
                  addMembers={() =>
                    navigation.navigate('add-project-modal-members')
                  }
                />
              </Box>
            </ScrollView>
          </Box>
        </BottomSheetModal>
        <BottomSheetModal
          name='Team Select Modal'
          index={0}
          stackBehavior='push'
          ref={bottomSheetTeamModalRef}
          enablePanDownToClose
          snapPoints={snapPoints}
          backdropComponent={BottomSheetBackdrop}
          handleComponent={() => <Box style={styles.closeLine} />}>
          <>
            <FloatingHeader
              title={t('models:projects.create.teams.title')}
              simple
              hideBorder
              onCancel={dismissTeamSelectModal}
              onSave={dismissTeamSelectModal}
              saveButtonTitle='Done'
              disabled
            />
            <TeamSelect isBottomSheet onSelectFinish={dismissTeamSelectModal} />
          </>
        </BottomSheetModal>
      </BottomSheetModalProvider>
    </>
  );
};
const styles = StyleSheet.create({
  closeLine: {
    alignSelf: 'center',
    width: 56,
    height: 4,
    borderRadius: 2.5,
    backgroundColor: theme.colors.grey03,
    margin: theme.spacing.l,
  },
});
