import { createContext, useContext, useEffect, useState } from 'react';

import { Document } from '@graphql/generated';

type UploadFilesContext = {
  uploadingFiles: (Document | undefined)[];
  setUploadingFiles: React.Dispatch<
    React.SetStateAction<(Document | undefined)[]>
  >;
  uploadingErrorFiles: (Document | undefined)[];
  setUploadingErrorFiles: React.Dispatch<
    React.SetStateAction<(Document | undefined)[]>
  >;
  uploadingSuccessFiles: (Document | undefined)[];
  setUploadingSuccessFiles: React.Dispatch<
    React.SetStateAction<(Document | undefined)[]>
  >;
  showMsg: boolean;
  setShowMsg: React.Dispatch<React.SetStateAction<boolean>>;
};

const uploadFilesContext = createContext<UploadFilesContext | undefined>(
  undefined
);

export const UploadFilesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = uploadFilesContext;
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = useState<
    (Document | undefined)[]
  >([]);
  const [uploadingErrorFiles, setUploadingErrorFiles] = useState<
    (Document | undefined)[]
  >([]);
  const [uploadingSuccessFiles, setUploadingSuccessFiles] = useState<
    (Document | undefined)[]
  >([]);

  useEffect(() => {
    if (uploadingSuccessFiles.length > 0 || uploadingErrorFiles.length > 0) {
      setShowMsg(true);
      setTimeout(() => {
        setShowMsg(false);
        setUploadingFiles([]);
        setUploadingErrorFiles([]);
        setUploadingSuccessFiles([]);
      }, 5000);
    }
  }, [
    uploadingFiles.length,
    uploadingSuccessFiles.length,
    uploadingErrorFiles.length,
  ]);

  return (
    <Provider
      value={{
        uploadingFiles,
        setUploadingFiles,
        uploadingErrorFiles,
        setUploadingErrorFiles,
        uploadingSuccessFiles,
        setUploadingSuccessFiles,
        showMsg,
        setShowMsg,
      }}>
      {children}
    </Provider>
  );
};

export const useUploadFiles = (): UploadFilesContext => {
  const context = useContext(uploadFilesContext);
  if (context === undefined) {
    throw new Error('useUploadFiles must be used within a Provider');
  }
  return context;
};
