import { FieldProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { TouchableOpacity } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import theme from '@themes/theme';

interface EmailsFieldProps {
  autoFocus: boolean;
  label: string;
  isFullWidth?: boolean;
  isRequired?: boolean;
  isLarge?: boolean;
  showCharsRemaining?: boolean;
  borderless?: boolean;
  marginBottom?: keyof typeof theme.spacing;
  accessibilityLabel?: string;
  validateOnChange?: boolean;
  placeholder?: string;
  enable?: boolean;
}

export const EmailsField: React.FC<EmailsFieldProps & FieldProps> = ({
  autoFocus,
  label,
  isFullWidth = true,
  isRequired,
  field,
  isLarge,
  marginBottom,
  form: { touched, errors, setTouched, setFieldValue },
  borderless,
  accessibilityLabel,
  validateOnChange = true,
  placeholder,
  enable = true,
}) => {
  const { t } = useTranslation('forms');
  const scroll = useRef<ScrollView>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <Box
      width={isFullWidth ? '100%' : undefined}
      marginBottom={isLarge ? 'l' : marginBottom || 'm'}>
      {label && (
        <Box
          flexDirection='row'
          alignItems='flex-start'
          justifyContent='space-between'
          marginBottom={isLarge ? 's' : 'xs'}>
          {!borderless && <Text variant='labelSmall'>{label}</Text>}
          {isRequired && !borderless && (
            <Text variant='metadataSecondary'>{t('required')}</Text>
          )}
        </Box>
      )}

      <Box
        accessibilityLabel={accessibilityLabel}
        flexDirection='row'
        style={
          !isLarge && {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
          }
        }>
        <ScrollView
          ref={scroll}
          showsVerticalScrollIndicator={false}
          style={{
            width: 412,
            height: 92,
            borderWidth: isFocused ? 2 : 1,
            borderRadius: theme.spacing.xs,
            borderColor: isFocused ? theme.colors.black : theme.colors.grey02,
            backgroundColor: theme.colors.white,
          }}>
          {!enable && (
            <Text
              variant='webBodySecondary'
              color='grey04'
              marginLeft='m'
              marginTop='m'>
              {placeholder}
            </Text>
          )}
          {enable && (
            <ReactMultiEmail
              enable={() => enable}
              placeholder={
                <Text variant='webBodySecondary' color='grey04'>
                  {placeholder}
                </Text>
              }
              style={{
                width: 380,
                paddingLeft: theme.spacing.m,
                paddingRight: theme.spacing.m,
                borderWidth: 0,
                backgroundColor: theme.colors.white,
                fontFamily: 'Inter_400Regular',
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <Box
                    alignItems='center'
                    justifyContent='center'
                    marginRight='xxs'
                    marginBottom='xxs'
                    padding='xxs'
                    borderRadius='xxs'
                    backgroundColor='grey01'
                    flexDirection='row'
                    key={index}
                    maxWidth={380}>
                    <Text variant='metadata' color='black' numberOfLines={1}>
                      {email}
                    </Text>
                    <TouchableOpacity onPress={() => removeEmail(index)}>
                      <Box
                        alignItems='center'
                        justifyContent='center'
                        marginLeft='xxs'
                        style={{ padding: 4.74 }}>
                        <Icon
                          name='Close'
                          color='black'
                          width={4.74}
                          height={4.74}
                          onPress={() => removeEmail(index)}
                        />
                      </Box>
                    </TouchableOpacity>
                  </Box>
                );
              }}
              onBlur={() => {
                setTouched({ ...touched, [field.name]: true });
                setIsFocused(false);
              }}
              autoFocus={autoFocus}
              onFocus={() => {
                setIsFocused(true);
              }}
              onKeyUp={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                if (evt.key === 'Enter') scroll.current?.scrollToEnd();
              }}
              onChange={(emails: string[]) => {
                setTouched({ ...touched, [field.name]: validateOnChange });
                setFieldValue(field.name, emails);
              }}
            />
          )}
        </ScrollView>
        {borderless && isRequired && !field.value?.length && (
          <Text variant='metadataSecondary'>{t('required')}</Text>
        )}
      </Box>
      {!!touched[field.name] && !!errors[field.name] && (
        <Box
          flexDirection='row'
          alignItems='center'
          marginTop={isLarge ? 's' : 'xs'}>
          <Icon
            name='AlertTriangle'
            color='alertRed'
            variant='s'
            marginRight='xxs'
          />
          <Text variant='error'>{errors[field.name]}</Text>
        </Box>
      )}
    </Box>
  );
};
