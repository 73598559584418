import { useNetInfo } from '@react-native-community/netinfo';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { FlashList, ViewToken } from '@shopify/flash-list';
import debounce from 'lodash.debounce';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import uuid from 'react-native-uuid';

import ChatForm from '@components/Chat/ChatForm';
import ChatMessageList from '@components/Chat/ChatMessageList.web';
import { EditMyTagsModal } from '@components/Chat/EditMyTagsModal.web';
import { MyTagModal } from '@components/Chat/MyTagModal.web';
import { DropZone } from '@components/DropZone';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { NetInfoModal } from '@components/Modals/NetInfoModal.web';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import {
  Message,
  Task,
  Project,
  MessageTask,
  MessageProject,
  Document,
  Chat,
  useSearchLazyQuery,
  useGetChatQuery,
  useMarkChatsReadMutation,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import useChatInput from '@hooks/useChatInput';
import { useDeleteMessage } from '@hooks/useDeleteMessage';
import useMe from '@hooks/useMe';
import { useOutsideClick } from '@hooks/useOutsideClick.web';
import useSearch from '@hooks/useSearch';
import useSendMessage from '@hooks/useSendMessage';
import { ForwardMessage } from '@screens/Chats/ForwardMessage.web';
import theme from '@themes/theme';

const IOS_KEYBOARD_PADDING = 75;
const ChatDetail: React.FC<{
  messages: Message[];
  fetchBeforeCursor: (cursor: string) => void;
  fetchAfterCursor: (cursor: string) => void;
  loading: boolean;
  refreshing: boolean;
  onRefresh: () => void;
  chatId: Chat['id'];
  messageCursor?: string;
  hasNextPage?: boolean;
}> = ({
  messages,
  fetchBeforeCursor,
  fetchAfterCursor,
  loading,
  refreshing,
  onRefresh,
  chatId,
  messageCursor = '',
  hasNextPage = true,
}) => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { search, filter, isSearching } = useSearch();
  const {
    activeChat,
    isEditTagModalOpen,
    setIsEditTagModalOpen,
    isForwardMessageModalOpen,
    setIsForwardMessageModalOpen,
    isDeleteMessageModalOpen,
    setIsDeleteMessageModalOpen,
  } = useActiveChat();
  const { me } = useMe();
  const { sendDraftMessage } = useSendMessage();
  const { t } = useTranslation();
  const _listRef = createRef<FlashList>();
  const { isConnected } = useNetInfo();
  const [isTagSelected, setTagSelected] = useState(false);
  const [isFilesSelected, setFilesSelected] = useState(false);
  const [replyedFromMessageId, setReplyedFromMessageId] = useState('');
  const [isNetInfoModalVisible, setIsNetInfoModalVisible] = useState(false);

  const [markChatsRead] = useMarkChatsReadMutation();

  const derivedChatId = activeChat?.id || chatId;
  const { data: chatData } = useGetChatQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: derivedChatId },
    skip: !derivedChatId,
  });
  const { getChat: chat } = chatData || {};
  const { isGroupChat, leaveOn, users, settings, friendRequestAccepted } =
    chat || {};

  const {
    message,
    selectedMessage,
    isTagModalOpen,
    getTagsCollection,
    getLocalFiles,
    getReplyMessage,
    setIsTagModalOpen,
    subscribeToChatUpdates,
  } = useChatInput();

  const tagsCollection = getTagsCollection(chatId);
  const localFiles = getLocalFiles(chatId);
  const replyMessage = getReplyMessage(chatId);

  //close pop when user navigate to other chat window
  const closeTagModal = useCallback(() => {
    setIsForwardMessageModalOpen(false);
    setIsEditTagModalOpen(false), setIsTagModalOpen(false);
  }, []);

  const tagRef = useOutsideClick(closeTagModal);

  const [filteredMessages, setFilteredMessages] = useState<Message[]>([
    ...messages,
  ]);

  const [globalSearch, { data, loading: loadingSearch }] = useSearchLazyQuery();
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [searchFrom, setSearchFrom] = useState(0);

  const globalSearchCall = (
    search: string,
    isTag: boolean,
    isFile: boolean
  ) => {
    globalSearch({
      variables: {
        term: search,
        size: 20,
        from: searchFrom,
        chatId: chatId,
        includeMessages: true,
        includeDocuments: false,
        includeProjects: false,
        includeTasks: false,
        tags: isTag,
        files: isFile,
        media: false,
      },
      onCompleted: (data1) => {
        if (
          data1.search.length > 0 &&
          data1.search[0].total > searchFrom + data1.search.length
        ) {
          setSearchFrom(searchFrom + data1.search.length);
          setCanLoadMore(true);
        } else {
          setCanLoadMore(false);
        }
      },
    });
  };

  const debouncedGlobalSearch = debounce(
    () => globalSearchCall(search, isTagSelected, isFilesSelected),
    1000
  );

  useEffect(() => {
    setSearchFrom(0);
    setCanLoadMore(false);
    if (search) {
      debouncedGlobalSearch();
    }

    return () => {
      debouncedGlobalSearch.cancel();
    };
  }, [search]);

  useEffect(() => {
    if (search) debouncedGlobalSearch();
  }, [isFilesSelected, isTagSelected]);

  const { id } = activeChat || {};
  const activeChatId = id;

  useEffect(() => {
    if (activeChatId) {
      markChatsRead({
        variables: { attributes: { chatIds: [activeChatId] } },
      });
    }
    setShowNewMessageIndicator(false);
    setFirstNewMsgId('');
  }, [activeChatId]);

  useEffect(() => {
    return () => {
      if (activeChatId) {
        markChatsRead({
          variables: { attributes: { chatIds: [activeChatId] } },
        });
      }
    };
  }, []);

  const sendMessage = () => {
    if (!chat || !me) return false;

    const projects = tagsCollection?.map((item) => item.project) || [];
    const projectIds = projects.map((item) => item.id);

    const tasks = tagsCollection?.flatMap((item) => item.tasks) || [];
    const taskIds = tasks.map((item) => item.id);

    // NOTE: draftMessageId has multiple purposes:
    // - Default identity in Apollo uses ID and __typename. Assigning a UUID as a draft message ID is sufficiently unique.
    // - Associated objects with a draft message also need an ID. Reusing the same UUID in combination with a different __typename ensures uniqueness.
    //    For example, a draft message may have associated projects, tasks, and/or documents.
    // - Since a draft message may have multiple associated objects of the same type, we append an index value to maintain uniqueness.
    const draftMessageId = uuid.v4().toString();

    const messageTasks = tasks.map(
      (item: Task, index): MessageTask => ({
        __typename: 'MessageTask',
        id: `${draftMessageId}-${index}`,
        authorId: me.id,
        author: me,
        taskId: item.id,
        task: item,
      })
    );

    const messageProjects = projects.map(
      (item: Project, index): MessageProject => ({
        __typename: 'MessageProject',
        id: `${draftMessageId}-${index}`,
        authorId: me.id,
        author: me,
        projectId: item.id,
        project: item,
      })
    );

    const documents = localFiles.map((item, index): Document => {
      const {
        name,
        clientId,
        contentType,
        isImage = false,
        isAudio = false,
        duration = null,
      } = item;
      const documentId = `${draftMessageId}-${index}`;

      return {
        __typename: 'Document',
        id: documentId,
        owner: me,
        name: name || clientId,
        clientId,
        contentType: contentType,
        isImage,
        createdAt: new Date().toISOString(),
        size: item.size,
        file: {
          ...item,
          __typename: 'File',
          id: documentId,
          cdnBaseUrl: '',
          url: item.url,
        },
        isAudio,
        duration,
      };
    });

    const draftMessage = {
      __typename: 'Message' as const,
      id: draftMessageId,
      chatId: derivedChatId,
      clientId: draftMessageId,
      body: message.trimEnd(),
      tasks,
      taskIds,
      projects,
      projectIds,
      authorId: me.id,
      author: me,
      createdAt: new Date().toISOString(),
      isSender: true,
      replyId: replyMessage?.id || null,
      replyMessage: replyMessage ? replyMessage : null,
      isDraft: true,
      publishedAt: false,
      publish: false,
      readAt: null,
      readReceipts: null,
      isRetry: !isConnected,
      chat: {
        ...chat,
      },
      attachments: [...messageTasks, ...messageProjects, ...documents],
    };
    if (documents.length > 0 && !isConnected) {
      setIsNetInfoModalVisible(true);
    } else {
      setIsNetInfoModalVisible(false);

      sendDraftMessage(draftMessage);
      // scroll to bottom on send
      messages.length &&
        _listRef.current?.scrollToIndex({
          index: 0,
          animated: false,
        });
    }
  };

  const selectedOption = (type: string) => {
    switch (type) {
      case 'Tags':
        setTagSelected(true);
        setFilesSelected(false);
        break;

      case 'Files':
        setFilesSelected(true);
        setTagSelected(false);
        break;
      default:
        setFilesSelected(false);
        setTagSelected(false);
        return false;
    }
  };

  useEffect(() => {
    if (filter.length > 0) {
      selectedOption(filter.toString());
    } else {
      selectedOption('');
    }
    if (search) {
      const filterData: Message[] = data?.search.map((item) => {
        return item.record;
      });
      if (filterData) {
        if (searchFrom > 0) {
          const newList = [
            ...filteredMessages,
            ...filterData.filter(
              (m) => !filteredMessages.some((m1) => m1.cursor === m.cursor)
            ),
          ];
          setFilteredMessages(newList);
        } else {
          setFilteredMessages(filterData);
        }
      }
    } else {
      setFilteredMessages(messages);
    }
  }, [search, filter, messages, data]);

  useFocusEffect(
    useCallback(() => {
      // Subscribe to chat
      const unsubscribe = subscribeToChatUpdates && subscribeToChatUpdates();

      // Unsubscribe from chat updates when the screen is blurred
      return () => {
        unsubscribe && unsubscribe();
      };
    }, [])
  );

  const closeTagModalOnBack = (e) => {
    if (!isTagModalOpen) {
      return;
    } else {
      e.preventDefault();
      setIsTagModalOpen(false);
      setIsEditTagModalOpen(false);
    }
  };
  useEffect(() => {
    navigation.addListener('beforeRemove', closeTagModalOnBack);
    return () => {
      navigation.removeListener('beforeRemove', closeTagModalOnBack);
    };
  }, [navigation, isTagModalOpen]);

  useEffect(() => {
    const scrollNode = _listRef.current?.getScrollableNode();
    if (!scrollNode) {
      return;
    }

    const listener = scrollNode.addEventListener(
      'wheel',
      (e: { deltaY: number; preventDefault: () => void }) => {
        scrollNode.scrollTop -= e.deltaY;
        e.preventDefault();
      }
    );

    return () => scrollNode.removeEventListener('wheel', listener);
  }, []);

  const { dropFileHandler } = useChatInput();
  const [showNewMessageIndicator, setShowNewMessageIndicator] = useState(false);
  const [firstNewMsgId, setFirstNewMsgId] = useState('');

  const onViewableMsgsChanged = (items: ViewToken[]) => {
    if (
      items.some(
        (x) => filteredMessages.length && x.item.id == filteredMessages[0].id
      )
    ) {
      setShowNewMessageIndicator(false);
      setFirstNewMsgId('');
    }
  };

  const scrollToFirstNewMsg = () => {
    setShowNewMessageIndicator(false);
    let xindex = 0;
    filteredMessages.forEach((v, i) => {
      if (v.id == firstNewMsgId) {
        xindex = i;
        setFirstNewMsgId('');
      }
    });
    filteredMessages.length &&
      _listRef.current?.scrollToIndex({
        index: xindex,
        animated: false,
      });
  };

  const debouncedHighlightedGrey = useCallback(
    debounce(() => {
      setReplyedFromMessageId('');
    }, 2000),
    [replyedFromMessageId]
  );

  useEffect(() => {
    if (replyedFromMessageId !== '') {
      debouncedHighlightedGrey();
    }

    return () => {
      debouncedHighlightedGrey.cancel();
    };
  }, [replyedFromMessageId]);

  //find reply(scrollToReplyMessage) to message code https://github.com/Lovett-Commercial/tasktag-mobile/pull/1985 , TA-3313

  const onDrop = (files: File[]) => dropFileHandler(chatId, files);

  const { deleteMessageById } = useDeleteMessage();
  const onCompleted = () => {
    setIsDeleteMessageModalOpen(false);
  };

  const groupCreatedInfo = () => {
    const owner = chat?.owner;
    const textComponet = (searchWords: string[], textToHighlight: string) => {
      return (
        <Box alignItems='center' height='l' style={{ marginTop: 6 }}>
          <Text variant='metadata' color='grey04' numberOfLines={1}>
            <Highlighter
              autoEscape
              highlightStyle={{
                fontFamily: 'Inter_600SemiBold',
                backgroundColor: theme.colors.greenSecondaryMild + '27',
                color: theme.colors.grey04,
              }}
              searchWords={searchWords}
              textToHighlight={textToHighlight}
            />
          </Text>
        </Box>
      );
    };

    return (
      <Box alignItems='center' marginBottom='s'>
        {textComponet(
          [owner?.name ?? '', chat?.name ?? ''],
          ` ${owner?.name ?? ''} has created a group “${chat?.name ?? ''}”`
        )}
        {users &&
          users
            .filter((a) => owner && a.id !== owner.id)
            .map((a) =>
              textComponet(
                [owner?.name ?? '', a?.name ?? ''],
                ` ${owner?.name ?? ''} has added ${a?.name ?? ''}`
              )
            )}
      </Box>
    );
  };

  return (
    <DropZone onDrop={onDrop}>
      {({ Input: DropZoneInput, isDragActive }) => (
        <Box
          ref={tagRef}
          flex={1}
          style={{
            paddingBottom: insets.bottom ? theme.spacing.m : undefined,
          }}>
          <Box
            style={{ display: isDragActive ? 'flex' : 'none' }}
            position='absolute'
            zIndex={1}
            top={0}
            bottom={0}
            right={0}
            left={0}
            alignContent='center'
            justifyContent='center'>
            <Box
              backgroundColor='greenBrand'
              position='absolute'
              opacity={0.9}
              top={0}
              bottom={0}
              right={0}
              left={0}
            />
            <Box flex={1} justifyContent='center'>
              <Text
                variant='labelEmphasized'
                color='black'
                style={{
                  textAlign: 'center',
                }}>
                Drop files here to attach them to your message.
              </Text>
              <DropZoneInput />
            </Box>
          </Box>

          <Box flex={1}>
            {!loading && messages.length == 0 && isGroupChat && (
              <Box
                mb='s'
                flex={1}
                justifyContent='flex-end'
                alignItems='center'
                alignContent='center'
                flexDirection='column'>
                {groupCreatedInfo()}
              </Box>
            )}
            {!loading && messages.length == 0 && isGroupChat === false && (
              <Box
                flex={1}
                justifyContent='center'
                alignItems='center'
                alignContent='center'
                flexDirection='column'>
                <Icon name='WinnerIsometric2' width={182} height={124} />
                <Text
                  variant='labelLarge'
                  textAlign='center'
                  paddingBottom='xs'
                  mt='xs'
                  color='onSurfaceSecondary'>
                  {t('models:chat.emptyState.noMessage')}
                </Text>
              </Box>
            )}

            <Box flex={messages.length > 0 ? 1 : 0}>
              <ChatMessageList
                hasNextPage={isSearching ? canLoadMore : hasNextPage}
                firstNewMsgId={firstNewMsgId}
                showNewMessageIndicator={(newMsgId) => {
                  if (!isSearching) {
                    if (firstNewMsgId === '') {
                      setFirstNewMsgId(newMsgId);
                    }
                    setShowNewMessageIndicator(true);
                  } else {
                    setShowNewMessageIndicator(false);
                  }
                }}
                onViewableMsgItemsChanged={onViewableMsgsChanged}
                list={filteredMessages}
                filterVal={search}
                ref={_listRef}
                fetchBeforeCursor={(cursor: string) => {
                  fetchBeforeCursor(cursor);
                }}
                fetchAfterCursor={(cursor: string) => {
                  if (isSearching && searchFrom > 0) {
                    globalSearchCall(search, isTagSelected, isFilesSelected);
                  } else {
                    fetchAfterCursor(cursor);
                  }
                }}
                loading={isSearching ? loadingSearch : loading}
                refreshing={refreshing}
                onRefresh={onRefresh}
                onLongPress={undefined}
                // onPress={scrollToReplyMessage}
                onEditTagPress={() => {
                  setIsEditTagModalOpen(true);
                }}
                onForwardPress={() => {
                  setIsForwardMessageModalOpen(true);
                }}
                onDeletedPress={() => {
                  setIsDeleteMessageModalOpen(true);
                }}
                highlightedToGrey01MessageId={replyedFromMessageId}
                scrollToCursor={messageCursor}
                isArchived={chat?.settings?.archivedAt ?? false}
              />
              {showNewMessageIndicator && (
                <TouchableOpacity
                  style={{
                    width: 150,
                    position: 'absolute',
                    bottom: 11,
                    right: 17,
                  }}
                  activeOpacity={0.2}
                  onPress={() => {
                    scrollToFirstNewMsg();
                  }}>
                  <Box
                    borderColor='grey02'
                    flexDirection='row'
                    style={{
                      borderRadius: 21,
                      height: 35,
                      shadowColor: 'black',
                      shadowOpacity: 0.12,
                      shadowOffset: { width: 2, height: 2 },
                      shadowRadius: 12,
                    }}
                    alignItems='center'
                    justifyContent='center'
                    pointerEvents='none'
                    backgroundColor='grey01'>
                    <Text variant='labelSmall' marginLeft='xxs'>
                      New Message
                    </Text>
                    <Icon
                      name='ArrowDown'
                      variant='m'
                      color='textPrimary'
                      marginTop='xxxs'
                    />
                  </Box>
                </TouchableOpacity>
              )}
            </Box>
          </Box>

          {isTagModalOpen && (
            <Box
              position='absolute'
              bottom={90}
              width={440}
              height='60vh'
              zIndex={3}
              ml='l'>
              <MyTagModal chatId={chatId} filterVal='' />
            </Box>
          )}

          {isEditTagModalOpen && (
            <Box
              position='absolute'
              bottom={90}
              width={440}
              height='60vh'
              zIndex={3}
              ml='l'>
              <EditMyTagsModal
                onDonePress={() => {
                  setIsEditTagModalOpen(false);
                }}
                onCancelPress={() => {
                  setIsEditTagModalOpen(false);
                }}
              />
            </Box>
          )}
          {isForwardMessageModalOpen && (
            <ForwardMessage
              messageData={selectedMessage}
              onForwardCompleted={() => {
                setIsForwardMessageModalOpen(false);
              }}
              onCancelPress={() => {
                setIsForwardMessageModalOpen(false);
              }}
            />
          )}
          {!isForwardMessageModalOpen && friendRequestAccepted && (
            <KeyboardAvoidingView
              behavior={undefined}
              keyboardVerticalOffset={IOS_KEYBOARD_PADDING + insets.bottom}>
              <Box>
                <ChatForm
                  onSend={sendMessage}
                  isGroupChat={isGroupChat}
                  chatUsers={users || []}
                  chatId={derivedChatId}
                  isBlocked={chat?.isUserBlocked}
                  showLeaveMessage={leaveOn}
                  disableInput={!!settings?.archivedAt || chat?.leaveOn}
                />
              </Box>
            </KeyboardAvoidingView>
          )}
          {isDeleteMessageModalOpen && (
            <ConfirmModal
              showModal={isDeleteMessageModalOpen}
              onClose={() => setIsDeleteMessageModalOpen(false)}
              onPress={() =>
                selectedMessage &&
                deleteMessageById(selectedMessage, onCompleted)
              }
              buttonText={t('shared:delete')}
              title={t('models:chat.delete.message.cta')}
              message={t('models:chat.delete.message.message')}
            />
          )}
          {isNetInfoModalVisible && (
            <NetInfoModal
              onClose={() => setIsNetInfoModalVisible(false)}
              showModal={isNetInfoModalVisible}
              title={t('models:chat.netInfoTitle')}
              message={t('models:chat.notConnectedToInternet')}
              buttonTextOne={t('models:onBoarding.error.okPress')}
            />
          )}
        </Box>
      )}
    </DropZone>
  );
};

export default ChatDetail;
