import React, { createContext, useContext, useState } from 'react';

import { AddMemberType } from '@components/Invite/SelectMembersList';

type SelectedMembersContext = {
  selectedMembers: AddMemberType[];
  setSelectedMembers: React.Dispatch<React.SetStateAction<AddMemberType[]>>;
  showTeamSelect: boolean;
  setShowTeamSelect: React.Dispatch<React.SetStateAction<boolean>>;
};

const selectedMembersContext = createContext<
  SelectedMembersContext | undefined
>(undefined);

export const SelectedMembersProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { Provider } = selectedMembersContext;
  const [selectedMembers, setSelectedMembers] = useState<AddMemberType[]>([]);
  const [showTeamSelect, setShowTeamSelect] = useState(false);

  return (
    <Provider
      value={{
        selectedMembers,
        setSelectedMembers,
        showTeamSelect,
        setShowTeamSelect,
      }}>
      {children}
    </Provider>
  );
};

const useSelectedMembers = (): SelectedMembersContext => {
  const context = useContext(selectedMembersContext);
  if (context === undefined) {
    throw new Error('useSelectedMembers must be used within a Provider');
  }
  return context;
};

export default useSelectedMembers;
