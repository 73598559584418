import { ComponentProps } from "react";
import { ScrollView } from "react-native";

import { Hoverable } from "@components/Hoverable";

/**
 * A `ScrollView` component that enables scroll only on hover.
 * Hover is only enabled for the web platform.
 */
export const HoverableScrollView = ({
  HoverableProps,
  ...props
}: ComponentProps<typeof ScrollView> & {
  HoverableProps?: Omit<ComponentProps<typeof Hoverable>, "children">;
}) => (
  <Hoverable {...HoverableProps}>
    {({ hover }) => (
      <ScrollView
        {...props}
        // Toggle indicator instead of scroll functionality
        showsVerticalScrollIndicator={hover}
        showsHorizontalScrollIndicator={hover}
      />
    )}
  </Hoverable>
);
