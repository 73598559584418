import { useRoute } from '@react-navigation/native';
import React from 'react';

import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';
import { localDateFromUTCDate } from '@utils/formatters/date';

import { Task, useGetTaskQuery } from '../../graphql/generated';
import TaskFormStack, { FormValues } from '../Tasks/TaskFormStack';

export const EditTask = ({
  taskId: taskIdProp = '',
}: {
  taskId?: Task['id'];
}) => {
  const route = useRoute<TasksStackScreenProps<'task-edit'>['route']>();

  const { params } = route || {};
  const { taskId = taskIdProp } = params || { taskId: '' };

  const { data: taskData, loading } = useGetTaskQuery({
    variables: {
      id: taskId,
    },
    skip: !taskId,
  });

  if (loading || !taskData) return <ActivityIndicatorLoading />;

  const { getTask: task } = taskData || { getTask: {} };

  const {
    startDate: initialStartDate,
    dueDate: initialDueDate,
    dependencies,
    description: initialDescription,
    name: initialName,
  } = task as Task;

  const startDate = initialStartDate && localDateFromUTCDate(initialStartDate);
  const dueDate = initialDueDate && localDateFromUTCDate(initialDueDate);

  const dependencyIds = dependencies?.map((d) => d.id) || [];

  const description = initialDescription || '';
  const name = initialName || '';

  const initialMembers = task.members.map((m) => {
    return {
      ...m.user,
      role: m.role,
    };
  });

  const initialValues: FormValues = {
    ...(!!task && task),
    users: initialMembers,
    ...{ startDate, dueDate, name, description },
    dependencyIds,
  };

  return (
    <TaskFormStack
      taskId={taskId}
      initialValues={initialValues}
      screenName='Edit'
    />
  );
};
