import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, TouchableOpacity } from 'react-native';

import { Box, Text } from '@components/Restyle';
import { useUploadFiles } from '@hooks/useUploadFiles';
import theme from '@themes/theme';

export const UploadFiles: React.FC = () => {
  const { t } = useTranslation('models');

  const {
    uploadingFiles,
    setUploadingFiles,
    uploadingErrorFiles,
    setUploadingErrorFiles,
    uploadingSuccessFiles,
    setUploadingSuccessFiles,
    showMsg,
    setShowMsg,
  } = useUploadFiles();

  useEffect(() => {
    setShowMsg(false);
    setUploadingFiles([]);
    setUploadingErrorFiles([]);
    setUploadingSuccessFiles([]);
  }, []);

  const screenWidth = Dimensions.get('screen').width;

  return (
    <>
      {(uploadingFiles.length > 0 || uploadingSuccessFiles.length > 0) && (
        <Box
          backgroundColor='black'
          opacity={0.8}
          marginHorizontal='s'
          paddingHorizontal='s'
          paddingVertical='xs'
          borderRadius='xs'
          style={{
            position: 'absolute',
            bottom: 80,
            width: screenWidth - theme.spacing.s * 2,
          }}>
          <Text color='white' variant='labelSmall' lineHeight={16}>
            {uploadingFiles.length > 0 &&
              uploadingSuccessFiles.length === 0 &&
              uploadingErrorFiles.length === 0 &&
              t('files.startUploadFiles', { count: uploadingFiles.length })}
            {showMsg &&
              uploadingSuccessFiles.length > 0 &&
              uploadingErrorFiles.length === 0 &&
              t('files.uploadFilesSuccess', {
                count: uploadingSuccessFiles.length,
              })}
          </Text>
        </Box>
      )}
      {showMsg && uploadingErrorFiles.length > 0 && (
        <Box
          backgroundColor='alertRedMild'
          flexDirection='row'
          marginHorizontal='s'
          paddingHorizontal='s'
          paddingVertical='xs'
          borderRadius='xs'
          style={{
            position: 'absolute',
            bottom: 80,
            width: screenWidth - theme.spacing.s * 2,
          }}>
          <Box flex={1}>
            <Text color='alertRed' variant='labelSmall' lineHeight={16}>
              {t('files.uploadFilesError', {
                uploadingErrorFilesCount: uploadingErrorFiles.length,
                uploadingFilesCount:
                  uploadingSuccessFiles.length + uploadingErrorFiles.length,
              })}
            </Text>
          </Box>
          <TouchableOpacity
            onPress={() => {
              setUploadingFiles(uploadingErrorFiles);
            }}>
            <Text color='alertRed' variant='labelSmall' lineHeight={16}>
              {t('files.retry')}
            </Text>
          </TouchableOpacity>
        </Box>
      )}
    </>
  );
};
