import {
  CompositeScreenProps,
  getFocusedRouteNameFromRoute,
  Route,
} from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from '@components/Avatar/Avatar';
import { CoreProviders } from '@components/CoreProviders';
import { WelcomeModal } from '@components/Modals/WelcomeModal.web';
import { NetworkStatusIndicator } from '@components/NetworkStatusIndicator';
import { Box } from '@components/Restyle';
import { createWebDrawerNavigator } from '@components/Web/Navigator/WebDrawerNavigator';
import { useAcceptTeamInvitationMutation } from '@graphql/generated';
import useMe from '@hooks/useMe';
import { ChatsStackParamList } from '@navigation/chats/chats-stack';
import {
  ContactsStack,
  ContactsTabBarParamList,
} from '@navigation/contacts/contacts-stack.web';
import FilesTabStack from '@navigation/files/files-tab-stack.web';
import { NestedNavigatorParams } from '@navigation/helpers';
import {
  ProjectsStack,
  ProjectStackParamsList,
} from '@navigation/projects/projects-stack.web';
import { TabNavigatorParamsList } from '@navigation/tab-navigator';
import { TasksStackParamsList } from '@navigation/tasks/tasks-stack';
import TasksTabStack from '@navigation/tasks/tasks-tab-stack';
import GlobalSearch from '@screens/Global/GlobalSearch';
import MyProfile from '@screens/Profile/MyProfile';

import { FilesStackParamsList } from './files/files-stack';

/**
 * Params for nested stacks
 */
export type AppStackParamList = {
  home: NestedNavigatorParams<TabNavigatorParamsList>;
  'chats-stack': NestedNavigatorParams<ChatsStackParamList>;
  'projects-stack': NestedNavigatorParams<ProjectStackParamsList>;
  'tasks-stack': NestedNavigatorParams<TasksStackParamsList>;
  'files-stack': NestedNavigatorParams<FilesStackParamsList>;
  'contacts-stack': NestedNavigatorParams<ContactsTabBarParamList>;
  'teams-stack': undefined;
  'global-search': undefined;
  'my-profile': undefined;
  'help-stack': undefined;
};

/**
 * Params for direct app stack
 */
export type AppStackScreenParams = {
  home: undefined;
  'chats-stack': ChatsStackParamList['chat-detail'];
  'projects-stack': undefined;
  'tasks-stack': undefined;
  'files-stack': undefined;
  'contacts-stack': undefined;
  'teams-stack': undefined;
  'global-search': undefined;
  'my-profile': undefined;
  'help-stack': undefined;
};

/**
 * Screen props for combined app stack and nested stacks
 */
export type AppStackScreenProps<T extends keyof AppStackParamList> =
  CompositeScreenProps<
    StackScreenProps<AppStackScreenParams, T>,
    StackScreenProps<AppStackParamList | AppStackScreenParams>
  >;

const Stack = createWebDrawerNavigator<
  AppStackParamList | AppStackScreenParams
>();

export const AppStack = () => {
  const { t } = useTranslation('models');

  const getProjectHeaderTitle = (route: Route<string, object | undefined>) => {
    const routeName =
      getFocusedRouteNameFromRoute(route) ?? t('models:navigation.projects');

    switch (routeName) {
      case 'new':
        return t('models:projects.create.title');
      default:
        return t('models:navigation.projects');
    }
  };

  const getTasksHeaderTitle = (route: Route<string, object | undefined>) => {
    const routeName =
      getFocusedRouteNameFromRoute(route) ?? t('models:navigation.myTasks');

    switch (routeName) {
      case 'task-create':
        return t('models:tasks.create.title');
      default:
        return t('models:navigation.myTasks');
    }
  };

  const teamInvitationCode = window.sessionStorage.getItem(
    'account_invitations'
  );
  const [acceptTeamInvitationMutation] = useAcceptTeamInvitationMutation({
    onCompleted: (data) => {
      console.log('Accept Team Invitation Successfully, ', data);
    },
    onError: (err) => {
      console.log('Accept Team Invitation Error, ', err.message);
    },
  });

  if (teamInvitationCode && teamInvitationCode.length > 0) {
    window.sessionStorage.setItem('account_invitations', '');
    acceptTeamInvitationMutation({
      variables: { invitationToken: teamInvitationCode },
    });
  }

  const isFromMyProfile = window.location.pathname.startsWith('/my-profile');
  return (
    <Box style={{ height: '100vh', maxHeight: '100vh' }}>
      <NetworkStatusIndicator />
      <CoreProviders>
        <Stack.Navigator
          initialRouteName={isFromMyProfile ? 'my-profile' : 'projects-stack'}>
          <Stack.Screen
            name='projects-stack'
            component={ProjectsStack}
            options={({ route }) => ({
              iconProps: { name: 'Project' },
              drawerLabel: t('navigation.projects'),
              title: getProjectHeaderTitle(route),
              unmountOnBlur: true,
              headerTransparent: true,
            })}
          />
          <Stack.Screen
            name='tasks-stack'
            component={TasksTabStack}
            options={({ route }) => ({
              iconProps: { name: 'Hash' },
              drawerLabel: t('navigation.myTasks'),
              title: getTasksHeaderTitle(route),
              unmountOnBlur: true,
            })}
          />
          <Stack.Screen
            name='files-stack'
            component={FilesTabStack}
            options={{
              iconProps: { name: 'File' },
              drawerLabel: t('navigation.files'),
              unmountOnBlur: true,
              headerTransparent: true,
            }}
          />
          <Stack.Screen
            name='contacts-stack'
            component={ContactsStack}
            options={() => ({
              iconProps: { name: 'User' },
              drawerLabel: t('navigation.contacts'),
              title: t('navigation.contacts'),
              unmountOnBlur: true,
              headerTransparent: true,
            })}
          />
          {/*<Stack.Screen*/}
          {/*  name='teams-stack'*/}
          {/*  options={{*/}
          {/*    iconProps: { name: 'Office' },*/}
          {/*    drawerLabel: t('navigation.teams'),*/}
          {/*    title: t('navigation.teams'),*/}
          {/*  }}>*/}
          {/*  {() => null}*/}
          {/*</Stack.Screen>*/}
          <Stack.Screen
            name='global-search'
            component={GlobalSearch}
            options={{
              drawerLabel: t('navigation.search'),
              hideMenuItem: true,
            }}
          />

          <Stack.Screen
            name='help-stack'
            options={{
              iconProps: { name: 'HelpCircle' },
              drawerLabel: t('navigation.help'),
              showBelowTheFold: true,
            }}>
            {() => null}
          </Stack.Screen>
          <Stack.Screen
            name='my-profile'
            component={MyProfile}
            options={{
              showBelowTheFold: true,
              drawerLabel: t('navigation.myProfile'),
              drawerIcon: () => {
                const { me: { avatar, firstName = '', lastName = '' } = {} } =
                  useMe();
                const initials =
                  (firstName?.charAt(0).toLocaleLowerCase() || '') +
                  (lastName?.charAt(0).toLocaleLowerCase() || '');
                return (
                  <Avatar avatar={avatar} label={initials} size='medium' />
                );
              },
            }}
          />
        </Stack.Navigator>
      </CoreProviders>
      {window.sessionStorage.getItem('goto_welcome') === '1' && (
        <WelcomeModal />
      )}
    </Box>
  );
};
