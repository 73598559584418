import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';

import Avatar from '@components/Avatar/Avatar';
import { ContactInfo } from '@components/Contacts/ContactInfo.web';
import { ContactProjects } from '@components/Contacts/ContactProjects.web';
import { ContactTasks } from '@components/Contacts/ContactTasks.web';
import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import {
  ChatDrawerScreen,
  useWebDrawer,
} from '@components/Web/Drawer/WebDrawerContext';
import {
  useCreateChatMutation,
  useGetProfileQuery,
  useListContactUsersQuery,
  User,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import { useAppNavigation } from '@navigation/useAppNavigation';
import theme from '@themes/theme';

export interface ContactProfileProps {
  userId: User['id'];
  getProjectsCount?: (count: number) => void;
}

const Tabs = createMaterialTopTabNavigator();

export const ContactTabs = ({
  isMaximize,
  userId,
  onClose,
  onMaximize,
}: {
  isMaximize: boolean;
  userId: User['id'];
  onClose: () => void;
  onMaximize: () => void;
}) => {
  const { t } = useTranslation('models');
  const navigation = useAppNavigation();
  const { data: listContacts } = useListContactUsersQuery();
  const { teams } = useListTeamsFromQuery();
  const [projectsCount, setProjectsCount] = useState(0);
  const { setActiveMessageCursor, setIsOpenChat } = useActiveChat();
  const { chatExpanded, chatListOpen, setChatListOpen, setShouldChatListOpen } =
    useWebDrawer();
  const [isHoveredText, setIsHoveredText] = useState<boolean>(false);

  const { data } = useGetProfileQuery({
    variables: { id: userId || '' },
  });
  const { getProfile: user } = data || {
    getProfile: undefined,
  };
  const { userActiveTasksCount } = user || {};

  const contact = listContacts?.listContactUsers.find(
    (contact) => contact.id === userId
  );

  const [createChat] = useCreateChatMutation({
    onCompleted: (data) => {
      const { createChat: createChatData } = data;
      if (!chatExpanded && chatListOpen) {
        setShouldChatListOpen(true);
        setChatListOpen(false);
      }
      setIsOpenChat(true);
      setActiveMessageCursor && setActiveMessageCursor(undefined);
      navigation.navigateToChatDrawer({
        screen: ChatDrawerScreen.details,
        activeChat: createChatData,
      });
    },
    refetchQueries: ['listChats'],
  });
  const openChat = (id: string) => {
    createChat({
      variables: {
        attributes: {
          userIds: [id],
        },
      },
    });
  };

  const isInSameTeam = teams.some((team) => {
    return team.users.some((user) => user.id === userId);
  });

  const getInitialRouteName = () => {
    const path = window.location.pathname;
    if (path.includes('/projects')) {
      return 'Projects' + ' (' + projectsCount + ')';
    } else if (path.includes('/tasks')) {
      return 'Tasks' + ' (' + userActiveTasksCount + ')';
    } else {
      return 'Profile';
    }
  };

  return (
    <Tabs.Navigator
      screenOptions={{ swipeEnabled: false }}
      tabBar={(props) => (
        <Box pb='s'>
          <Box flexDirection='row' justifyContent='space-between'>
            <Box flex={1} alignItems='flex-end'>
              <Box flexDirection='row'>
                <Icon
                  paddingHorizontal='xs'
                  onPress={onMaximize}
                  name={isMaximize ? 'Minimize2' : 'Maximize2'}
                  color='textPrimary'
                />
                <Icon name='X' onPress={onClose} />
              </Box>
            </Box>
          </Box>
          <Box mb='s'>
            <Box style={styles.person}>
              <Avatar
                avatar={contact?.avatar}
                size='large'
                label={contact?.name}
              />
              <Box marginLeft='l' flex={1}>
                <Text
                  variant='heading2'
                  color={contact?.name ? 'textPrimary' : 'grey04'}>
                  {contact?.name ? contact?.name : t('models:contacts.noName')}
                </Text>
              </Box>
              <TouchableOpacity
                onPress={() => contact?.id && openChat(contact.id)}
                style={styles.sendMessage}>
                <Icon name='MessageCircle' />
                <Text
                  variant='labelSmall'
                  marginLeft='s'
                  textDecorationLine={isHoveredText ? 'underline' : undefined}
                  onMouseEnter={() => setIsHoveredText(true)}
                  onMouseLeave={() => setIsHoveredText(false)}>
                  {t('models:contacts.sendAMessage')}
                </Text>
              </TouchableOpacity>
            </Box>
          </Box>
          <Box>
            <CustomTabBar {...props} initialRouteName={getInitialRouteName()} />
          </Box>
        </Box>
      )}>
      <Tabs.Screen
        name='contact-info'
        children={() => <ContactInfo userId={userId} />}
        options={{
          tabBarLabel: t('contacts.tabs.profile'),
          tabBarAccessibilityLabel: t('contacts.tabs.profile'),
        }}
      />

      {isInSameTeam && (
        <>
          <Tabs.Screen
            name='contact-projects'
            initialParams={{ userId }}
            children={() => (
              <ContactProjects
                userId={userId}
                getProjectsCount={(count: number) => setProjectsCount(count)}
              />
            )}
            options={{
              tabBarLabel: t('contacts.tabs.projectsWithCount', {
                count: projectsCount,
              }),
              tabBarAccessibilityLabel: t('contacts.tabs.projectsWithCount', {
                count: projectsCount,
              }),
            }}
          />
          <Tabs.Screen
            name='contact-tasks'
            initialParams={{ userId }}
            children={() => <ContactTasks userId={userId} />}
            options={{
              tabBarLabel: t('contacts.tabs.tasksWithCount', {
                count: userActiveTasksCount,
              }),
              tabBarAccessibilityLabel: t('contacts.tabs.tasksWithCount', {
                count: userActiveTasksCount,
              }),
            }}
          />
        </>
      )}
    </Tabs.Navigator>
  );
};

const styles = StyleSheet.create({
  sendMessage: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  person: {
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: theme.spacing.l,
  },
});
