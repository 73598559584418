import React, { createContext, useContext, useState } from 'react';

import { Project, TaskSortOption, Team, User } from '@graphql/generated';
import { PickEnum } from '@root/types';

export type ProjectFilter = {
  type: 'TasksFilterProject';
  name: string;
  value: Project['id'];
};

export type UserFilter = {
  type: 'TasksFilterUser';
  name: string;
  value: User['id'];
};

export type TeamFilter = {
  type: 'TasksFilterTeam';
  name: string;
  value: Team['id'];
};

export type SortFilter = {
  type: 'TasksFilterSortOption';
  name: SortFilterName;
  value: FilterableTaskSortOptions;
};

export enum SortFilterName {
  DueDateAsc = 'Due Date',
  DueDateDesc = 'Reverse Due Date',
  NameDesc = 'Reverse Alphabetical',
  NameAsc = 'Alphabetical',
}

export type SectionFilter = {
  type: 'TasksFilterSectionOption';
  name: string;
  value: TaskSectionOptions;
};

export type FilterItem =
  | ProjectFilter
  | UserFilter
  | TeamFilter
  | SortFilter
  | SectionFilter;
export type FilterItems = FilterItem[];

export enum TaskSectionOptions {
  Active = 'Active Tasks',
  Completed = 'Completed Tasks',
}

export enum ProjectTaskSectionOptions {
  Active = 'Active Tasks',
  Completed = 'Completed Tasks',
}

export type FilterableTaskSortOptions = PickEnum<
  TaskSortOption,
  | TaskSortOption.DueDateAsc
  | TaskSortOption.DueDateDesc
  | TaskSortOption.NameAsc
  | TaskSortOption.NameDesc
>;

type tasksFilterContext = {
  sectionOption: TaskSectionOptions;
  setSectionOption: React.Dispatch<React.SetStateAction<TaskSectionOptions>>;
  projectFilters: ProjectFilter[];
  setProjectFilters: React.Dispatch<React.SetStateAction<ProjectFilter[]>>;
  sortByFilter: SortFilter | undefined;
  setSortByFilter: React.Dispatch<React.SetStateAction<SortFilter | undefined>>;
  userFilters: UserFilter[];
  setUserFilters: React.Dispatch<React.SetStateAction<UserFilter[]>>;
  teamFilters: TeamFilter[];
  setTeamFilters: React.Dispatch<React.SetStateAction<TeamFilter[]>>;
  projectId: string | undefined;
  setProjectId: React.Dispatch<React.SetStateAction<string | undefined>>;
  userNotViewer: boolean | undefined;
  userTeamMember: boolean | undefined;
  setUserNotViewer: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setUserTeamMember: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

const tasksFilterContext = createContext<tasksFilterContext | undefined>(
  undefined
);

export const TasksFilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = tasksFilterContext;

  const [projectFilters, setProjectFilters] = useState<ProjectFilter[]>([]);
  const [userFilters, setUserFilters] = useState<UserFilter[]>([]);
  const [sortByFilter, setSortByFilter] = useState<SortFilter>();
  const [teamFilters, setTeamFilters] = useState<TeamFilter[]>([]);
  const [sectionOption, setSectionOption] = useState<TaskSectionOptions>(
    TaskSectionOptions.Active
  );
  const [projectId, setProjectId] = useState<string>();
  const [userNotViewer, setUserNotViewer] = useState<boolean>();
  const [userTeamMember, setUserTeamMember] = useState<boolean>();

  return (
    <Provider
      value={{
        sectionOption,
        setSectionOption,
        projectFilters,
        setProjectFilters,
        sortByFilter,
        setSortByFilter,
        userFilters,
        setUserFilters,
        teamFilters,
        setTeamFilters,
        projectId,
        setProjectId,
        userNotViewer,
        setUserNotViewer,
        userTeamMember,
        setUserTeamMember,
      }}>
      {children}
    </Provider>
  );
};

export const useTasksFilter = (): tasksFilterContext => {
  const context = useContext(tasksFilterContext);
  if (context === undefined) {
    throw new Error('useTasksFilter must be used within a Provider');
  }
  return context;
};
