import { useNavigation } from '@react-navigation/native';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, TouchableOpacity, View } from 'react-native';

import { Alert } from '@components/Alert';
import Box from '@components/Box/Box';
import { AddMembersSection } from '@components/Common/AddMembersSection.web';
import { CancelConfirmTaskAndProjectModal } from '@components/Modals/CancelConfirmTaskAndProjectModal';
import { FormValues } from '@components/Projects/ProjectFormStack';
import { Text } from '@components/Restyle/index';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import ListNavigationItem from '@components/shared/ListNavigationItem/ListNavigationItem';
import TextField from '@components/shared/TextField/TextField';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { ProjectMemberRole } from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useNewColors, { ColorType } from '@hooks/useNewColors';
import useTabFocus from '@hooks/useTabFocus';
import Images from '@themes/images';
import theme from '@themes/theme';
import { getProjectRoleEnumValue } from '@utils/projects';

type ProjectFormProps = Record<string, unknown>;

const ProjectForm: React.FC<ProjectFormProps> = ({ screenName }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const navigator = useNavigation();
  const { teams } = useListTeamsFromQuery();
  const { colors } = useNewColors();
  const [overrideSaveAlert, setOverrideSaveAlert] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const { tabFocus } = useTabFocus();
  const { setIsCreateProjectOpen, setEditProjectId } = useWebDrawer();
  const [touchedTeam, setTouchedTeam] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isCancelHovered, setIsCancelHovered] = useState(false);
  const {
    values,
    touched,
    submitForm,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = useFormikContext<FormValues>();
  const {
    teamId = '',
    users,
    color,
    icon,
    id: projectId,
    location,
    currentTeam,
  } = values;

  const sortedUsers = [...users].sort((u) =>
    u.role !== ProjectMemberRole.Owner ? 1 : -1
  );

  const selectedTeam = teams.find((item) => item.id === teamId);
  const { name: selectedTeamName } = selectedTeam || {};

  const selectedColor = colors.find((item: ColorType) => item.id === color);

  const updateMember = (userId: string, role: string) => {
    const members = users?.map((u) => {
      if (u.id !== userId) return u;
      else {
        return { ...u, role: getProjectRoleEnumValue(role) };
      }
    });
    setFieldTouched('users');
    setFieldValue('users', [...members]);
  };

  const saveAlert = () => {
    Alert.alert(
      t('models:saveProject.title'),
      t('models:saveProject.message'),
      [
        {
          text: t('shared:cancel'),
        },
        {
          text: t('shared:leavePage'),

          onPress: () => {
            setOverrideSaveAlert(true);

            // debounce, else alert will show twice
            setTimeout(() => {
              navigation.goBack();
            }, 10);
          },
        },
      ]
    );
  };
  const onPressCancel = () => {
    setIsCancelModalVisible(false);
    setIsCreateProjectOpen(false);
    setEditProjectId('');
    if (projectId && tabFocus === 'Projects') {
      navigation.navigate('projects-stack', {
        screen: 'details',
        params: { projectId },
      });
    } else {
      navigation.navigate('allprojects');
    }
  };

  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (overrideSaveAlert) {
          return;
        }
        if (projectId && Object.entries(touched).length > 0) {
          e.preventDefault();
          saveAlert();
        } else {
          return;
        }
      }),
    [navigation, touched, overrideSaveAlert]
  );

  useEffect(() => {
    Object.entries(touched).length;
  }, [touched]);

  const createButton = () => {
    const disabled =
      isSubmitting ||
      Object.entries(touched).length < 1 ||
      !(selectedTeamName || currentTeam?.name);
    const bgColor = isHovered ? 'grey05' : 'black';
    return (
      <Button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isSmall
        disabled={disabled}
        backgroundColor={disabled ? undefined : bgColor}
        borderColor={disabled ? undefined : bgColor}
        variant='webprimary'
        marginTop='l'
        accessibilityLabel={
          projectId
            ? t('models:projects.update.title')
            : t('models:projects.create.title')
        }
        onPress={() => {
          setOverrideSaveAlert(true);
          submitForm();
        }}>
        {projectId
          ? t('models:projects.update.title')
          : t('models:projects.create.title')}
      </Button>
    );
  };

  const teamIcon =
    selectedTeamName || currentTeam?.name ? (
      <Icon name='Logo' variant='l' color='white' disabled={false} />
    ) : undefined;

  return (
    <Box
      flex={1}
      backgroundColor='white'
      maxWidth={Platform.OS === 'web' ? 600 : undefined}>
      <Box
        style={{ paddingBottom: Platform.OS !== 'web' ? 120 : undefined }}
        flex={1}>
        <ScrollView>
          {!projectId && (
            <Box
              borderBottomColor='grey02'
              borderBottomWidth={1}
              marginBottom='l'>
              <ListNavigationItem
                title='Copy from existing project'
                iconName='Copy'
                // project-copy-search sits in project-form-stack for web. In mobile, it is in project-stack
                onPress={() =>
                  navigation.navigate(
                    Platform.OS === 'web'
                      ? 'project-copy-search'
                      : 'project-search'
                  )
                }
                hideChevron={Platform.OS === 'web'}
              />
            </Box>
          )}
          <Field
            component={TextField}
            label='Name'
            name='name'
            autoFocus={Platform.OS !== 'web' ? true : undefined}
            accessible={true}
            isRequired
            placeholder={t('models:projects.placeholders.nameForWeb')}
            textInputProps={{ maxLength: 25 }}
            showCharsRemaining
            accessibilityLabel={t('models:projects.placeholders.nameForWeb')}
          />
          <Field
            component={TextField}
            label='Description'
            name='description'
            autoFocus={Platform.OS !== 'web' ? true : undefined}
            accessible={true}
            textInputProps={{ maxLength: 125 }}
            placeholder={t('models:projects.placeholders.description')}
            accessibilityLabel={t('models:projects.placeholders.description')}
          />

          <Text color='textPrimary' variant='labelSmall'>
            {t('shared:address')}
          </Text>
          <TouchableOpacity
            onPress={() => navigation.navigate('project-location-search')}>
            <Box
              accessibilityLabel='Address'
              flexDirection='row'
              borderColor='grey02'
              borderWidth={1}
              height={48}
              alignItems='center'
              borderRadius='xs'
              paddingHorizontal='m'
              mt='xs'
              mb='m'>
              <Icon name='MapPin' variant='m' color='grey04' />
              {location && (
                <Text
                  variant='bodySecondary'
                  color='black'
                  numberOfLines={1}
                  mr='m'
                  ml='xs'>
                  {location.address}
                </Text>
              )}
            </Box>
          </TouchableOpacity>

          <Box flexDirection='row' mb='m'>
            <Box flex={1}>
              <Box
                flexDirection='row'
                alignItems='flex-end'
                justifyContent='center'>
                <Text marginBottom='xs' variant='bodyTaskName'>
                  {t('models:projects.create.teams.team')}
                </Text>
                <Box flex={1}></Box>
                <Text marginBottom='xs' variant='labelRequired'>
                  {t('models:projects.create.required')}
                </Text>
              </Box>

              <Button
                variant='edit'
                fullWidth
                isSmall
                onPress={() => {
                  navigator.navigate('team-select');
                  setTimeout(() => {
                    setTouchedTeam(true);
                  }, 500);
                }}
                prefix={
                  <Box
                    width={24}
                    height={24}
                    alignItems='center'
                    justifyContent='center'
                    backgroundColor={
                      selectedTeam?.personal ? 'greenSecondary' : undefined
                    }
                    borderRadius='xxs'
                    marginRight='m'>
                    {selectedTeam?.personal ? (
                      <Icon
                        name='Office'
                        variant='s'
                        color='white'
                        disabled={false}
                      />
                    ) : (
                      teamIcon
                    )}
                  </Box>
                }
                accessibilityLabel='Assign Team'>
                <Text
                  variant='bodyTaskName'
                  color={
                    selectedTeamName || currentTeam?.name
                      ? 'textPrimary'
                      : 'grey04'
                  }>
                  {selectedTeamName || currentTeam?.name || 'Assign Team'}
                </Text>
              </Button>

              {touchedTeam && !(selectedTeamName || currentTeam?.name) && (
                <Box flexDirection='row' alignItems='center' marginBottom='s'>
                  <Icon
                    name='AlertTriangle'
                    color='alertRed'
                    variant='s'
                    marginRight='xxs'
                  />
                  <Text variant='error'>
                    {t('models:projects.create.teams.teamIsRequired')}
                  </Text>
                </Box>
              )}
            </Box>
            <Box marginLeft='m'>
              <Text marginBottom='xs' variant='bodyTaskName'>
                {t('models:projects.color')}
              </Text>
              <TouchableOpacity
                accessibilityLabel='Color'
                style={{
                  width: 72,
                  height: 48,
                  paddingHorizontal: theme.spacing.l,
                  paddingVertical: theme.spacing.s,
                  borderRadius: theme.spacing.xs,
                  borderWidth: 1,
                  borderColor: theme.colors.grey03,
                  backgroundColor: theme.colors.grey01,
                }}
                onPress={() => navigator.navigate('color-select')}>
                {selectedColor ? (
                  <View
                    style={{
                      backgroundColor: selectedColor.value,
                      borderRadius: 24,
                      width: 24,
                      height: 24,
                      marginRight: theme.spacing.xxs,
                    }}></View>
                ) : undefined}
              </TouchableOpacity>
            </Box>
            <Box marginLeft='m'>
              <Text marginBottom='xs' variant='bodyTaskName'>
                {t('models:projects.icon')}
              </Text>
              <TouchableOpacity
                accessibilityLabel='Icon'
                style={{
                  width: 72,
                  height: 48,
                  paddingHorizontal: theme.spacing.l,
                  paddingVertical: theme.spacing.s,
                  borderRadius: theme.spacing.xs,
                  borderWidth: 1,
                  borderColor: theme.colors.grey03,
                  backgroundColor: theme.colors.grey01,
                }}
                onPress={() => navigator.navigate('icon-select')}>
                {icon ? (
                  <Icon
                    name={icon as keyof typeof Images}
                    variant='l'
                    color='textPrimary'
                  />
                ) : undefined}
              </TouchableOpacity>
            </Box>
          </Box>

          <AddMembersSection
            isCreateProject={!projectId}
            isEditProject={!!projectId}
            members={sortedUsers}
            updateMember={(member, role) => updateMember(member.id, role)}
            removeMember={(member) => {
              const members = users?.filter((u) => u.id !== member.id);
              setFieldTouched('users');
              setFieldValue('users', [...members]);
            }}
            addMembers={() => navigator.navigate('member-select')}
          />
        </ScrollView>
      </Box>
      <Box flexDirection='row'>
        <Box flex={1}>
          <Button
            onMouseEnter={() => setIsCancelHovered(true)}
            onMouseLeave={() => setIsCancelHovered(false)}
            isSmall
            marginTop='l'
            marginRight='xs'
            borderWidth={1}
            backgroundColor={isCancelHovered ? 'grey02' : 'white'}
            borderColor={isCancelHovered ? 'grey02' : 'black'}
            borderRadius='xs'
            variant='textweb'
            onPress={() => {
              setIsCancelModalVisible(true);
            }}
            float='bottom'
            accessibilityLabel={t('shared:cancel')}>
            {t('shared:cancel')}
          </Button>
        </Box>
        <Box flex={1}>{createButton()}</Box>
      </Box>

      <CancelConfirmTaskAndProjectModal
        showModal={isCancelModalVisible}
        onClose={() => {
          setIsCancelModalVisible(false);
        }}
        onPress={onPressCancel}
        buttonTextOne={
          screenName == 'Create'
            ? t('models:tasks.cancel.btnCreateText')
            : t('models:tasks.cancel.btnEditText')
        }
        buttonText={
          screenName == 'Create'
            ? t('models:tasks.cancel.removeProjectBtnText')
            : t('models:tasks.cancel.btnCancelText')
        }
        title={
          screenName == 'Create'
            ? t('models:tasks.cancel.projectCancelTitle')
            : t('models:tasks.cancel.cancelEditingTitle')
        }
        message={
          screenName == 'Create'
            ? t('models:tasks.cancel.projectCreateMessage')
            : t('models:tasks.cancel.projectEditMessage')
        }
      />
    </Box>
  );
};

export default ProjectForm;
