import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

import Avatar from '@components/Avatar/Avatar';
import { ModalHeader } from '@components/Headers/ModalHeader';
import { ContactType } from '@components/Invite/Contact';
import ContactList from '@components/Invite/ContactList';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import TextField from '@components/shared/TextField/TextField';
import { ChatDrawerScreen } from '@components/Web/Drawer/WebDrawerContext';
import { useCreateChatMutation } from '@graphql/generated';
import { useContacts } from '@hooks/useContacts';
import { FormValues } from '@navigation/chats/create-group-stack';
import { useAppNavigation } from '@navigation/useAppNavigation';
import {
  createGroupSchema,
  MAX_GROUP_NAME_LENGTH,
} from '@schemas/createGroupSchema';
import { convertUsersToContacts } from '@utils/convertUsersToContacts';

export const CreateGroupChat: React.FC = () => {
  const { navigateToChatDrawer } = useAppNavigation();
  const { contacts } = useContacts();
  const listUsersToContacts = convertUsersToContacts(contacts);
  const sortedListUsersToContacts = [...listUsersToContacts].sort((a, b) =>
    a.firstName?.localeCompare(b.firstName)
  );
  const { t } = useTranslation();
  const initialValues: FormValues = {
    name: '',
    users: [],
  };
  const [search, setSearch] = useState('');
  const [createChat] = useCreateChatMutation({
    onCompleted: (data) => {
      const { createChat: createChatData } = data;

      navigateToChatDrawer({
        screen: ChatDrawerScreen.details,
        activeChat: createChatData,
      });
    },
  });
  const submit = (values: FormValues) => {
    const { users, name } = values;
    const userIds = users.map((u) => u.id);
    createChat({
      variables: {
        attributes: {
          name,
          userIds,
          groupChat: true,
        },
      },
    });
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submit(values)}
      validationSchema={createGroupSchema}>
      {({ values, submitForm, errors, setFieldValue, setFieldTouched }) => {
        const { users } = values;

        return (
          <Box flex={1}>
            <Box p='m' borderBottomColor='grey02' borderBottomWidth={1}>
              <ModalHeader
                onCancel={() =>
                  navigateToChatDrawer({ screen: ChatDrawerScreen.newChat })
                }
                cancelColor='greenSecondary'
                title={t('models:chat.labels.newGroupChat')}
                onSubmit={submitForm}
                submitLabel={t('forms:Confirm')}
              />
            </Box>
            <Box mx='m' mt='m' flex={1}>
              <Text marginBottom='m' variant='labelSmall'>
                {t('models:chat.labels.groupChatName')}
              </Text>
              <Field
                component={TextField}
                name='name'
                showCharsRemaining
                textInputProps={{ maxLength: MAX_GROUP_NAME_LENGTH }}
                placeholder={t('models:chat.placeholders.groupChatName')}
              />

              <Text variant='labelSmall' color='textPrimary'>
                {t('models:chat.labels.groupMembersWithCount', {
                  count: users.length,
                })}
              </Text>
              <Box mt='m' flexDirection='column' flex={1}>
                <Box flexDirection='row' alignContent='center'>
                  <SearchInput onTextChanged={setSearch} value={search} />
                </Box>

                <Box mt='m' flex={1}>
                  <ScrollView>
                    <ContactList
                      contacts={sortedListUsersToContacts}
                      filterVal={search}
                      onPress={(item: ContactType) => {
                        setFieldTouched('users');

                        if (users?.find((u) => u.id === item.id)) {
                          const selectedUsers = users.filter(
                            (u) => u.id !== item.id
                          );
                          setFieldValue('users', selectedUsers);
                        } else {
                          setFieldValue('users', [...users, item]);
                        }
                      }}
                      isEditing
                      selected={users.map((u) => u.id)}
                      footerSpace={0}
                    />
                  </ScrollView>
                </Box>
                <Box marginTop='m' maxHeight='50%'>
                  <ScrollView>
                    <Box flexDirection='row' flexWrap='wrap'>
                      {users.map((user) => {
                        const { name, avatar, id, firstName } = user;
                        return (
                          <Box
                            key={id}
                            alignItems='center'
                            alignSelf='flex-start'
                            marginRight='s'
                            marginBottom='xs'
                            width={56}>
                            <Avatar
                              avatar={avatar}
                              label={name}
                              onDismiss={() => {
                                const newUsers = users.filter(
                                  (u) => u.id !== id
                                );
                                setFieldValue('users', newUsers);
                              }}
                            />
                            <Text
                              variant='metadata'
                              marginTop='xs'
                              style={{ maxWidth: 56 }}
                              numberOfLines={1}>
                              {firstName}
                            </Text>
                          </Box>
                        );
                      })}
                    </Box>
                  </ScrollView>
                </Box>
              </Box>
              {errors.users && (
                <Box flexDirection='row' alignItems='center'>
                  <Icon
                    name='AlertTriangle'
                    color='alertRed'
                    variant='s'
                    marginRight='xxs'
                  />
                  <Text variant='error'>{errors.users.toString()}</Text>
                </Box>
              )}
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};
