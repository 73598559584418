import { MutableRefObject, useEffect, useRef } from "react";

/**
 * Calls `callback` when a click is observed outside the `ref` element.
 * This currently only supports web.
 * @param callback Outside click handler. For best results, do not use an
 * inline function as callback.
 * @returns Ref to determine whether the click is inside or outside.
 */
export const useOutsideClick = <T extends HTMLElement | undefined = undefined>(
  callback: (event: MouseEvent) => void
): MutableRefObject<T | undefined> => {
  const ref = useRef<T>();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        ref.current &&
        event.target &&
        !ref.current.contains(event.target as Node)
      ) {
        callback(event);
      }
    };

    window?.document?.addEventListener("click", handleClick, true);

    return () => {
      window?.document?.removeEventListener("click", handleClick, true);
    };
  }, [ref, callback]);

  return ref;
};
