import { array, object, string } from 'yup';

import { locationSchema } from '@schemas/locationSchema';
import { userSchema } from '@schemas/userSchema';

export const projectFormSchema = object({
  id: string().nullable(),
  name: string()
    .matches(
      /^$|^[0-9a-zA-Z][^]*$/,
      'Name cannot start with special characters/emoji.'
    )
    .required('Name is a required field'),
  location: locationSchema.nullable(),
  description: string().nullable(),
  teamId: string().required('Team is required'),
  users: array().of(userSchema).ensure(),
  color: string().required(),
  icon: string().nullable(),
  currentTeam: object().nullable(),
});
