import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, StyleSheet, FlatList } from 'react-native';

import { Box, ShadowBox, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import Tag from '@components/shared/Tags/Tag';
import useChatInput from '@hooks/useChatInput';
import useMe from '@hooks/useMe';
import documentIcons from '@themes/documentIcons';
import Images from '@themes/images';
import theme from '@themes/theme';

interface ChatModalProps {
  onDismiss?: () => void;
  onEditPress: () => void;
  onChatMembersPress: () => void;
  onRelatedTagsPress: () => void;
  onMuteChatPress: () => void;
  onPinChatPress: () => void;
  onArchiveChatPress: () => void;
  onLeaveChatPress: () => void;
  onDeleteChatPress: () => void;
}

export const ChatModal: React.FC<ChatModalProps> = ({
  onDismiss,
  onEditPress,
  onChatMembersPress,
  onRelatedTagsPress,
  onMuteChatPress,
  onPinChatPress,
  onArchiveChatPress,
  onLeaveChatPress,
  onDeleteChatPress,
}) => {
  const { t } = useTranslation();

  const { me } = useMe();
  const { selectedChat: chat } = useChatInput();
  const { owner, settings, leaveOn } = chat || {};
  const ownerId = owner?.id;
  const isOwner = me?.id === ownerId;
  const isArchived = settings?.archivedAt;
  const isGroupChat = chat?.isGroupChat;
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredMenuName, setHoveredMenuName] = useState<string | undefined>();

  const menuList: {
    name: string;
    iconName: keyof typeof Images | keyof typeof documentIcons;
    onSelect: () => void;
    comingSoon?: boolean;
    color?: keyof typeof theme.colors | undefined;
    numberOfLines?: number;
  }[] = [
    ...(isGroupChat && !leaveOn
      ? [
          {
            name: t('models:chat.chatMenuList.edit'),
            iconName: 'Edit',
            onSelect: () => {
              onEditPress();
            },
            numberOfLines: 1,
          },
        ]
      : []),
    ...(isGroupChat
      ? [
          {
            name: t('models:chat.chatMenuList.chatMembers'),
            iconName: 'Users',
            onSelect: () => {
              onChatMembersPress();
            },
            numberOfLines: 1,
          },
        ]
      : []),
    {
      name: t('models:chat.chatMenuList.relatedTags'),
      iconName: 'Hash',
      onSelect: () => {
        onRelatedTagsPress();
      },
      numberOfLines: 1,
    },
    ...(!leaveOn
      ? [
          {
            name: settings?.mute
              ? t('models:chat.chatMenuList.unmuteChat')
              : t('models:chat.chatMenuList.muteChat'),
            iconName: 'VolumeX',
            onSelect: () => {
              onMuteChatPress();
            },
            numberOfLines: 1,
          },
          {
            name: settings?.pin
              ? t('models:chat.chatMenuList.unpinChat')
              : t('models:chat.chatMenuList.pinChat'),
            iconName: 'Pin2',
            onSelect: () => {
              onPinChatPress();
            },
            numberOfLines: 1,
          },
        ]
      : []),

    ...(isGroupChat && !isArchived && isOwner
      ? [
          {
            name: t('models:chat.chatMenuList.archiveForEveryone'),
            iconName: 'Archive',
            color: 'alertRed',
            onSelect: () => {
              onArchiveChatPress();
            },
            numberOfLines: 1,
          },
        ]
      : []),

    ...(!isGroupChat && !isArchived
      ? [
          {
            name: t('models:chat.chatMenuList.archiveChatHistory'),
            iconName: 'Archive',
            onSelect: () => {
              onArchiveChatPress();
            },
            numberOfLines: 1,
          },
        ]
      : []),
    {
      name: t('models:chat.chatMenuList.clearChatHistory'),
      iconName: 'Chat',
      comingSoon: true,
      numberOfLines: 1,
    },
    ...(isGroupChat && !isOwner && !isArchived && !leaveOn
      ? [
          {
            name: t('models:chat.chatMenuList.leaveChat'),
            iconName: 'Leave',
            color: 'alertRed',
            onSelect: () => {
              onLeaveChatPress();
            },
            numberOfLines: 1,
          },
        ]
      : []),
    ...(isGroupChat && leaveOn
      ? [
          {
            name: t('models:chat.deleteGroup.cta'),
            iconName: 'LogOut',
            numberOfLines: 1,
            color: 'alertRed',
            onSelect: () => {
              onDeleteChatPress();
            },
          },
        ]
      : []),
  ];

  return (
    <TouchableOpacity
      style={styles.container}
      onMouseLeave={() => {
        onDismiss && onDismiss();
      }}>
      <TouchableOpacity
        activeOpacity={1}
        onPress={(e) => e.stopPropagation()}
        style={styles.outer}>
        <ShadowBox
          backgroundColor='white'
          py='s'
          borderRadius='xs'
          style={{
            width: '100%',
          }}>
          {menuList && (
            <FlatList
              data={menuList}
              scrollEnabled={false}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    item.onSelect();
                  }}
                  accessibilityLabel={item.name}>
                  <Box
                    onMouseEnter={() => {
                      setIsHovered(true);
                      setHoveredMenuName(item.name);
                    }}
                    onMouseLeave={() => {
                      setIsHovered(false);
                      setHoveredMenuName(undefined);
                    }}
                    flexDirection='row'
                    py='s'
                    px='m'
                    alignItems='center'
                    style={{
                      backgroundColor:
                        isHovered && hoveredMenuName === item.name
                          ? theme.colors.grey01
                          : 'white',
                    }}>
                    <Box flex={1} flexDirection='row'>
                      <Icon
                        name={item.iconName}
                        variant='m'
                        color={item.color || 'textPrimary'}
                      />
                      <Text
                        marginLeft='xs'
                        variant='metadata1'
                        color={item.color || 'textPrimary'}>
                        {item.name}
                      </Text>
                    </Box>
                    {item.comingSoon && (
                      <Tag
                        label={t('shared:comingSoon')}
                        labelVariant='metadata'
                        variant='secondary'
                        padding='xxs'
                        borderRadius='xxs'
                      />
                    )}
                  </Box>
                </TouchableOpacity>
              )}
            />
          )}
        </ShadowBox>
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    zIndex: 100,
    alignItems: 'center',
    borderRadius: theme.borderRadii.xs,
  },
  outer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
});
