import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';

export const EmptyChatList: React.FC = () => {
  const { t } = useTranslation('models');
  return (
    <Box flex={1} padding='m' justifyContent='center'>
      <Box
        flex={1}
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        marginVertical='m'>
        <Icon name='NoChat' width={200} height={151} />
        <Text
          variant='heading1'
          textAlign='center'
          paddingBottom='xs'
          color='onSurfaceSecondary'>
          {t('chat.emptyState.title')}
        </Text>
        <Text variant='body' textAlign='center' color='onSurfaceSecondary'>
          {t('chat.emptyState.message')}
        </Text>
      </Box>
    </Box>
  );
};
