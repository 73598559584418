import React, { useEffect, useState } from 'react';
import { Platform, TouchableOpacity, useWindowDimensions } from 'react-native';
// use gesture handler to solve android absolute position flatlist bug
import { FlatList } from 'react-native-gesture-handler';
import Highlighter from 'react-native-highlight-words';

import Avatar from '@components/Avatar/Avatar';
import { Box, Text } from '@components/Restyle';
import { User } from '@graphql/generated';
import useMe from '@hooks/useMe';
import theme from '@themes/theme';

type EveryoneType = {
  id: string;
  name: string;
};

const EVERYONE_LABEL = '@Everyone';
const EVERYONE_STRING = 'Everyone';
export const EVERYONE_ID = 'everyone';

const DEBOUNCE_VALUE = 10;

type SuggestionsProvidedProps = {
  keyword?: string;
  isGroupChat?: boolean;
  chatInputHeight: number;
  keyboardShow: boolean;
  onSuggestionPress?: (item: User | EveryoneType) => void;
  onSelect?: (item: User | EveryoneType) => void;
  chatUsers: User[];
};

const MentionSuggestions: React.FC<SuggestionsProvidedProps> = ({
  keyword,
  isGroupChat,
  chatInputHeight,
  keyboardShow,
  onSuggestionPress,
  onSelect,
  chatUsers,
}) => {
  const { height } = useWindowDimensions();
  const { me } = useMe();
  const [chatUserData, setChatUsers] = useState<User[]>(chatUsers);

  // throttle keyword to prevent suggestion box  flashing
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  useEffect(() => {
    if (Platform.OS === 'web' && isGroupChat) {
      const mentionUserData = Object.assign([
        { id: EVERYONE_ID, name: EVERYONE_STRING },
        ...chatUsers,
      ]);
      setChatUsers(mentionUserData);
    } else {
      setChatUsers(chatUsers);
    }
  }, [chatUsers]);

  useEffect(() => {
    const timeout = setTimeout(
      () => setDebouncedKeyword(keyword),
      DEBOUNCE_VALUE
    );
    return () => clearTimeout(timeout);
  }, [keyword]);

  const filteredUsers = chatUserData.filter((user) =>
    user?.name
      ?.toLocaleLowerCase()
      .includes(debouncedKeyword?.toLocaleLowerCase())
  );

  if (debouncedKeyword === undefined) return null;

  const displayEveryoneLabel =
    (EVERYONE_LABEL.toLowerCase().startsWith(
      debouncedKeyword.toLowerCase(),
      1
    ) ||
      !debouncedKeyword) &&
    isGroupChat;

  if (!filteredUsers.length && !displayEveryoneLabel) return null;

  // leading space - do not suggest
  if (debouncedKeyword.trimStart().length !== debouncedKeyword.length)
    return null;

  const setFlatListHeight = (isKeyboardShow: boolean) => {
    if (Platform.OS === 'web') {
      return '30vh';
    } else {
      if (isKeyboardShow) {
        if (height < 700) {
          if (chatInputHeight < 220) {
            return 140;
          } else if (chatInputHeight > 220) {
            return 80;
          }
        } else {
          return 140;
        }
      } else {
        return 316;
      }
    }
  };

  return (
    <FlatList
      style={{
        backgroundColor: 'white',
        marginBottom:
          Platform.OS === 'web' ? theme.spacing.l : -theme.spacing.s,
        borderRadius: theme.borderRadii.m,
        marginHorizontal: theme.spacing.s,
        maxHeight: setFlatListHeight(keyboardShow),
        shadowColor: 'black',
        shadowOpacity: Platform.OS === 'android' ? 0.5 : 0.25,
        shadowOffset: { width: 0, height: Platform.OS === 'web' ? 5 : 25 },
        shadowRadius: 20,
        elevation: Platform.OS === 'web' ? 0 : 25,
        borderColor: theme.colors.grey02,
        borderWidth: 1,
      }}
      keyboardShouldPersistTaps='always'
      data={filteredUsers}
      renderItem={({ item: user }) => (
        <TouchableOpacity
          key={user.id}
          onPress={() =>
            Platform.OS === 'web' ? onSuggestionPress(user) : onSelect(user)
          }>
          <Box
            alignItems='center'
            alignSelf='flex-start'
            flexDirection='row'
            paddingHorizontal='m'>
            <Avatar avatar={user?.avatar} size='small' label={user.name} />
            {user?.name && (
              <Text variant='labelSmall' numberOfLines={1} marginLeft='xs'>
                <Highlighter
                  autoEscape
                  highlightStyle={{
                    color: theme.colors.blue,
                    fontFamily: 'Inter_600SemiBold',
                    backgroundColor: theme.colors.background,
                  }}
                  searchWords={[debouncedKeyword]}
                  textToHighlight={
                    user.name + (me?.id === user.id ? ' (you)' : '')
                  }
                />
              </Text>
            )}
          </Box>
        </TouchableOpacity>
      )}
      ItemSeparatorComponent={() => (
        <Box
          borderColor='grey01'
          borderBottomWidth={1}
          paddingTop='xs'
          marginBottom='s'
          marginHorizontal='m'
        />
      )}
      ListHeaderComponent={() => {
        if (Platform.OS !== 'web' && displayEveryoneLabel) {
          return (
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web'
                  ? onSuggestionPress({
                      id: EVERYONE_ID,
                      name: EVERYONE_STRING,
                    })
                  : onSelect({ id: EVERYONE_ID, name: EVERYONE_STRING })
              }>
              <Box
                marginTop='m'
                marginBottom='s'
                borderColor='grey01'
                borderBottomWidth={1}
                paddingBottom='xs'
                marginHorizontal='m'>
                <Text variant='labelSmall'>
                  <Highlighter
                    autoEscape
                    highlightStyle={{
                      color: theme.colors.greenSecondary,
                      backgroundColor: theme.colors.background,
                    }}
                    searchWords={[debouncedKeyword]}
                    textToHighlight={EVERYONE_LABEL}
                  />
                </Text>
              </Box>
            </TouchableOpacity>
          );
        } else {
          return <Box paddingTop='xs' />;
        }
      }}
      ListFooterComponent={() => <Box marginBottom='s' />}
    />
  );
};

export default MentionSuggestions;
