import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet } from 'react-native';

import Avatar from '@components/Avatar/Avatar';
import CustomTabPanel from '@components/CustomTabBar/CustomTabPanel.web';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import { MenuItem } from '@components/shared/Popup/PopupItem';
import { createTabNavigator } from '@components/Web/Navigator/TabNavigator';
import {
  ListProjectsDocument,
  Project,
  Task,
  useDeleteProjectMutation,
} from '@graphql/generated';
import { useProjectFromQuery } from '@hooks/useProjectFromQuery';
import { useProjectMembership } from '@hooks/useProjectMembership';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { NestedNavigatorParams } from '@navigation/helpers';
import { ProjectStackScreenProps } from '@navigation/projects/projects-stack.web';
import { TaskPanelParamsList } from '@navigation/tasks/task-panel';
import { useAppNavigation } from '@navigation/useAppNavigation';
import ProjectActivityLog from '@screens/Projects/ProjectActivityLog';
import ProjectFiles from '@screens/Projects/ProjectFiles.web';
import { ProjectMembers } from '@screens/Projects/ProjectMembers.web';
import { ProjectTasks } from '@screens/Projects/ProjectTasks.web';

export type ProjectDetailTabsParamList = {
  'project-details': undefined;
  'activity-log': { projectId: Project['id'] };
  tasks: {
    projectId: Project['id'];
    taskId?: Task['id'];
    isFromMyTask?: boolean;
  } & NestedNavigatorParams<TaskPanelParamsList>;
  members: { projectId: Project['id'] };
  files: { projectId: Project['id'] };
};

export type ProjectDetailTabsScreenProps<
  T extends keyof ProjectDetailTabsParamList
> = StackScreenProps<ProjectDetailTabsParamList, T>;

const Tabs = createTabNavigator<ProjectDetailTabsParamList>();

export const ProjectDetailTabs = ({
  route,
}: ProjectStackScreenProps<'details'>) => {
  const { setHeaderTitle, setSelectedProject } = useWebDrawerNavigator();
  const { t } = useTranslation('models');
  const navigation = useAppNavigation();
  const [headerWidth, setHeaderWidth] = useState(0);
  const [tabsWidth, setTabsWidth] = useState(0);
  const [showTeamAndOwner, setShowTeamAndOwner] = useState<boolean>(false);

  const {
    params: { projectId },
  } = route;
  const { project, loading } = useProjectFromQuery({
    projectId,
  });

  useEffect(() => {
    if (project?.name) {
      setHeaderTitle(project?.name);
      setSelectedProject(project);
    }
    return () => {
      (!navigation.isFocused() && setHeaderTitle(undefined)) || void 0;
      if (!navigation.isFocused()) {
        setSelectedProject(undefined);
      }
    };
  }, [project?.name]);

  useEffect(() => {
    const unsubscribe = navigation
      .getParent()
      ?.addListener('drawerItemPress', (e) => {
        navigation.reset({ routes: [{ name: 'allprojects' }], index: 0 });
        e.preventDefault();
      });

    return unsubscribe;
  }, [navigation]);

  const { members, description, creator } = project || {};
  const { isMemberOrOwner, isAdminOrHigher, isEditorOrHigher, isMember } =
    useProjectMembership(project);

  const isTeamMember = project?.currentUserTeamRole === 'MEMBER';
  const isTeamOwnerOrAdmin =
    project?.currentUserTeamRole === 'OWNER' ||
    project?.currentUserTeamRole === 'ADMIN';

  const nonMember = !(isMemberOrOwner || isTeamOwnerOrAdmin);

  const [deleteProject] = useDeleteProjectMutation({
    onCompleted: () => navigation.goBack(),
    refetchQueries: [
      { query: ListProjectsDocument },
      'listProjects',
      'listProjectsRecent',
    ],
  });

  const [modal, setShowModal] = useState(false);

  const deleteFunction = () => {
    return (
      <ConfirmModal
        showModal={true}
        onClose={() => setShowModal(false)}
        onPress={() => {
          deleteProject({
            variables: {
              id: projectId,
            },
          });
          setShowModal(false);
        }}
        buttonText={t('deleteProject.title')}
        title={t('deleteProject.title')}
        message={t('deleteProject.message')}
      />
    );
  };

  const menuList: MenuItem[] = [
    ...(isAdminOrHigher || isTeamOwnerOrAdmin
      ? ([
          {
            iconName: 'Edit',
            name: t('projects.edit.title'),
            onSelect: () => {
              navigation.navigate('projects-stack', {
                screen: 'edit',
                params: { projectId },
              });
            },
          },
        ] as MenuItem[])
      : []),
    ...(isEditorOrHigher || isTeamOwnerOrAdmin
      ? ([
          {
            iconName: 'Duplicate',
            name: 'Duplicate',
            onSelect: () =>
              navigation.navigate('projects-stack', {
                screen: 'duplicate',
                params: { projectId },
              }),
          },
        ] as MenuItem[])
      : []),
    ...((isEditorOrHigher || isTeamOwnerOrAdmin) && members && members.length
      ? ([
          {
            iconName: 'Chat',
            name: 'Announcement',
            isComingSoon: true,
          },
        ] as MenuItem[])
      : []),
    {
      iconName: 'Share',
      name: 'Share',
      isComingSoon: true,
    },
    ...(!isTeamOwnerOrAdmin || (isTeamOwnerOrAdmin && isMember)
      ? [
          {
            iconName: 'LogOut',
            name: 'Leave',
            isComingSoon: true,
          },
        ]
      : []),
    ...(isAdminOrHigher || !isTeamMember
      ? ([
          {
            iconName: 'Archive',
            name: 'Archive',
            isComingSoon: true,
          },
          {
            iconName: 'Trash2',
            name: 'Delete',
            isRed: true,
            isComingSoon: false,
            onSelect: () => {
              setShowModal(true);
            },
          },
        ] as MenuItem[])
      : []),
  ];

  if (loading) {
    return <ActivityIndicatorLoading />;
  }

  if (modal) {
    return deleteFunction();
  }

  if (!project) {
    return (
      <Box flex={1} paddingTop='m' alignItems='center'>
        <Text>Project not found</Text>
      </Box>
    );
  }

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <Box>
        <Box
          flexDirection='row'
          alignItems='center'
          style={styles.topShadow}
          onLayout={(e) => setHeaderWidth(e.nativeEvent.layout.width)}>
          <Box
            flexDirection='row'
            alignItems='center'
            style={styles.topShadow}
            onLayout={(e) => setTabsWidth(e.nativeEvent.layout.width)}>
            <CustomTabPanel
              {...props}
              contentContainerStyle={styles.contentContainerPanelStyle}
              isProjectTabs
              nonMember={nonMember}
              members={
                Dimensions.get('window').width > 1440 ? members : undefined
              }
            />
          </Box>
          {Dimensions.get('window').width > 1280 && (
            <Box
              flex={1}
              width={headerWidth - tabsWidth}
              flexDirection='row'
              mr='l'
              justifyContent='flex-end'>
              {headerWidth - tabsWidth - 40 > 0 && (
                <Box
                  alignItems='flex-end'
                  justifyContent='flex-end'
                  marginHorizontal='xs'
                  maxWidth={headerWidth - tabsWidth - 40}>
                  <Text
                    accessibilityLabel='Project Owner'
                    variant='bodyTaskName'
                    marginLeft='xs'
                    color={nonMember ? 'grey04' : 'textPrimary'}
                    letterSpacing={0.2}
                    numberOfLines={1}
                    ellipsizeMode='tail'>
                    {creator?.name || ''}
                  </Text>
                  <Text
                    variant='labelRequired'
                    color='grey04'
                    marginLeft='xs'
                    numberOfLines={1}>
                    {project.team?.name}
                  </Text>
                </Box>
              )}
              <Box
                borderWidth={1}
                borderColor='white'
                borderRadius='s'
                onMouseEnter={() => setShowTeamAndOwner(true)}
                onMouseLeave={() => setShowTeamAndOwner(false)}>
                <Avatar
                  id={creator?.id}
                  avatar={creator?.avatar}
                  label={creator?.name}
                  size='small'
                />
              </Box>
            </Box>
          )}
        </Box>
        {Dimensions.get('window').width <= 1280 && (
          <Box
            mx='l'
            mt='m'
            pt='xxs'
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-start'>
            <Box borderWidth={1} borderColor='white' borderRadius='s'>
              <Avatar
                id={creator?.id}
                avatar={creator?.avatar}
                label={creator?.name}
                size='small'
              />
            </Box>
            <Box
              alignItems='flex-start'
              justifyContent='flex-start'
              marginLeft='xs'>
              <Text
                variant='bodyTaskName'
                color={nonMember ? 'grey04' : 'textPrimary'}
                letterSpacing={0.2}
                numberOfLines={1}
                ellipsizeMode='tail'>
                {creator?.name || ''}
              </Text>
              <Text variant='labelRequired' color='grey04' numberOfLines={1}>
                {project.team?.name}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const getTeamAndOwner = () => {
    return (
      <>
        <Box
          style={{
            position: 'absolute',
            top: 42,
            right: 6,
          }}
          flex={1}
          alignItems='flex-end'
          justifyContent='flex-end'
          paddingRight='l'>
          <Icon name='Arrow' color='grey05' height={14} />
        </Box>
        <Box
          style={{
            position: 'absolute',
            top: 55,
            right: 6,
          }}>
          <Box borderRadius='xs' backgroundColor='grey05' padding='s'>
            <Box marginVertical='xs' flexDirection='row'>
              <Icon name='TT' variant='xxl' color='textPrimary' />
              <Box marginLeft='xs'>
                <Text
                  numberOfLines={1}
                  ellipsizeMode='tail'
                  variant='bodyTaskName'
                  color='white'>
                  {project.team?.name}
                </Text>
                <Text variant='labelRequired' color='grey02'>
                  {t('shared:team')}
                </Text>
              </Box>
            </Box>
            {creator && (
              <Box flexDirection='row' marginVertical='xs'>
                <Box borderWidth={1} borderColor='white' borderRadius='m'>
                  <Avatar
                    id={creator.id}
                    avatar={creator.avatar}
                    label={creator.name}
                    size='small'
                  />
                </Box>
                <Box marginLeft='xs'>
                  <Text
                    variant='bodyTaskName'
                    letterSpacing={0.2}
                    numberOfLines={1}
                    ellipsizeMode='tail'
                    color='white'>
                    {creator.name || ''}
                  </Text>
                  <Text variant='labelRequired' color='grey02'>
                    {t('shared:owner')}
                  </Text>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box
      flex={1}
      mt='m'
      minWidth={Dimensions.get('window').width <= 1280 ? 639 : 742}>
      <Text
        accessibilityLabel='Description title'
        variant='bodySecondary'
        marginBottom='m'
        marginHorizontal='l'
        color='grey05'>
        {description}
      </Text>
      <Tabs.Navigator
        screenOptions={{ swipeEnabled: false }}
        tabBar={renderTabBar}
        threeDotMenuList={menuList}>
        {(isMemberOrOwner || isTeamMember || isTeamOwnerOrAdmin) && (
          <>
            <Tabs.Screen
              name='tasks'
              component={ProjectTasks}
              initialParams={{ projectId }}
              navigationKey={`tasks-${projectId}`}
              options={{
                tabBarLabel: t('projects.tasksWithCount', {
                  context: 'guest',
                }),
              }}
            />
            <Tabs.Screen
              name='files'
              options={{ tabBarLabel: 'Files & Media' }}
              component={ProjectFiles}
              initialParams={{ projectId }}
              navigationKey={`files-${projectId}`}
            />
            <Tabs.Screen
              name='activity-log'
              component={ProjectActivityLog}
              initialParams={{ projectId }}
              navigationKey={`activity-log-${projectId}`}
              options={{
                tabBarLabel: 'Activity Log',
              }}
            />
            <Tabs.Screen
              name='members'
              component={ProjectMembers}
              initialParams={{ projectId }}
              navigationKey={`members-${projectId}`}
              options={{
                tabBarLabel: t('projects.membersWithCount', {
                  context: 'guest',
                }),
              }}
            />
          </>
        )}
      </Tabs.Navigator>
      {showTeamAndOwner && getTeamAndOwner()}
    </Box>
  );
};

const styles = StyleSheet.create({
  topShadow: {
    backgroundColor: 'white',
  },
  contentContainerPanelStyle: {
    paddingHorizontal: 24,
    // paddingVertical: 4,
    backgroundColor: '#ffffff',
  },
});
