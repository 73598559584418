import { object, string } from 'yup';

import { MAX_GROUP_NAME_LENGTH } from '@schemas/createGroupSchema';

export const groupChatSettingsSchema = object({
  name: string()
    .max(
      MAX_GROUP_NAME_LENGTH,
      `Group name must not exceed ${MAX_GROUP_NAME_LENGTH} characters`
    )
    .required('Please fill in the group name field'),
  description: string(),
  avatar: object().nullable(),
});
