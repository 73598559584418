import { number, object, string, array } from 'yup';
import 'yup-phone';

const registerSchema = object({
  email: string().email('Invalid email address').required('Required'),
  password: string().required('Required'),
  first_name: string().required('Required'),
  last_name: string().required('Required'),
  phone: string()
    .phone(undefined, false, 'The phone number is invalid.')
    .required('Required'),
  invitation_token: string(),
  provider: string(),
  uid: string(),
  time_zone: string(),
  auth_token: string(),
  step: number().nullable().notRequired(),
  verify_code: string(),
  team_name: string(),
  team_emails: array().of(string()),
});

export default registerSchema;
