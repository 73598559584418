import { useListChatsQuery } from '@graphql/generated';
import {
  logError,
  LogGraphError,
  LogNetworkError,
} from '@utils/logNonFatalError';

/**
 * Retrieves a list of projects
 */

export const useListChatsFromQuery = ({
  archivesOnly = false,
  onCompleted = undefined,
  onError = undefined,
}: {
  archivesOnly?: boolean;
  onCompleted?: () => void;
  onError?: () => void;
}) => {
  const { data, loading, error, refetch } = useListChatsQuery({
    variables: {
      archivesOnly: archivesOnly,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted,
    onError: (error) => {
      if (error.networkError) {
        const event: LogNetworkError = {
          message: 'List Chats Network Failure',
          errorMessage: error.message,
          statusCode:
            'statusCode' in error.networkError
              ? error.networkError.statusCode
              : -1,
          extra: [{ archivesOnly }],
        };
        logError(event);
      }
      error.graphQLErrors.forEach((graphError) => {
        const event: LogGraphError = {
          message: 'List Chats GraphQL Error',
          errorMessage: graphError.message,
          operationName: 'listChats',
          extra: [{ archivesOnly }],
        };
        logError(event);
      });

      onError?.();
    },
  });

  return {
    chats: data?.listChats || [],
    loading,
    error,
    refetch,
  };
};
