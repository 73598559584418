type LogEventParams = {
  [key: string]: unknown;
};

type DataLayerPushProps = {
  event: LogEventParams['name'];
  [key: string]: unknown;
};
type GTMDataLayer = {
  push: (props: DataLayerPushProps) => void;
};
export type LogEventProps = {
  name: string;
  params?: LogEventParams;
};

export const logEvent = (props: LogEventProps) => {
  const { name, params } = props;
  if (window && 'dataLayer' in window === false) {
    console.log('Event error: Tag Manager is not configured', name, params);
  }

  window &&
    'dataLayer' in window &&
    (window.dataLayer as GTMDataLayer).push({ event: name, ...params });
};
