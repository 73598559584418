import {
  createRestyleComponent,
  createVariant,
  color,
  border,
  spacing,
  ColorProps,
  BorderProps,
  SpacingProps,
  VariantProps,
} from '@shopify/restyle';
import { ComponentProps, ReactNode } from 'react';
import { TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import theme, { Theme } from '@themes/theme';
const maxChars = 10;
type RestyleProps = ColorProps<Theme> &
  BorderProps<Theme> &
  SpacingProps<Theme> &
  VariantProps<Theme, 'tagVariants'>;

const RestyleTag = createRestyleComponent<RestyleProps, Theme>([
  color,
  border,
  spacing,
  createVariant({ themeKey: 'tagVariants' }),
]);

export type Props = RestyleProps & {
  label: string;
  labelVariant?:
    | 'webSmall'
    | 'labelSmall'
    | 'metadata'
    | 'label'
    | 'labelCompletedTask';
  prefixMarginRight?: keyof typeof theme.spacing;
  isPill?: boolean;
  onDismiss?: () => void;
  onPress?: () => void;
  onLongPress?: () => void;
  prefix?: ReactNode;
  suffix?: ReactNode;
  filterVal?: string;
  type?: string;
  deletedAt?: string;
  isCompleted?: boolean;
  isFrom?: string;
  iconWidth?: number;
  iconHeight?: number;
};

export const Tag: React.FC<Props> = ({
  label,
  labelVariant = 'metadata',
  onDismiss = undefined,
  prefix,
  prefixMarginRight,
  suffix,
  onPress,
  onLongPress,
  filterVal = '',
  type,
  deletedAt,
  isCompleted = false,
  isFrom,
  iconWidth,
  iconHeight,
  ...rest
}: Props) => {
  const color: ComponentProps<typeof Text>['color'] =
    (theme.tagVariants[rest.variant as keyof typeof theme.tagVariants]
      ?.color as ComponentProps<typeof Text>['color']) ?? 'blue';
  const projectName = (name: string) => {
    const projName =
      name?.length > maxChars ? name?.substring(0, maxChars) + '...' : name;
    return projName;
  };
  return (
    <TouchableOpacity
      disabled={deletedAt ? true : !onPress && !onDismiss}
      onPress={onPress}
      onLongPress={onLongPress}
      accessibilityLabel={label}
      activeOpacity={onPress ? 0.2 : 1}
      style={{ cursor: onPress ? 'pointer' : 'default' }}>
      <RestyleTag {...rest}>
        {prefix && (
          <Box
            width={iconWidth ?? 16}
            height={iconHeight ?? 16}
            marginRight={prefixMarginRight || 'xs'}
            alignItems='center'
            justifyContent='center'>
            {prefix}
          </Box>
        )}

        <Text
          variant={labelVariant}
          color={deletedAt ? 'grey04' : color}
          numberOfLines={1}
          style={
            type === 'tasks' && deletedAt
              ? {
                  textDecorationLine: 'line-through',
                  textDecorationStyle: 'solid',
                }
              : {
                  flexShrink: 1,
                  alignSelf: 'flex-start',
                }
          }>
          <Highlighter
            autoEscape
            highlightStyle={{
              color: theme.colors.black,
              backgroundColor: theme.colors.yellowBright,
              fontFamily: 'Inter_600SemiBold',
            }}
            searchWords={filterVal?.split(' ') || ''}
            textToHighlight={
              isFrom === 'EditTags' && type !== 'tasks'
                ? projectName(label)
                : label
            }
          />
        </Text>

        {suffix && !isCompleted && (
          <Box
            width={iconWidth ?? 16}
            height={iconHeight ?? 16}
            marginLeft='xs'
            alignItems='center'
            justifyContent='center'>
            {suffix}
          </Box>
        )}
        {!!onDismiss && (
          <TouchableOpacity
            onPress={onDismiss}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}>
            <Icon
              name='X2'
              marginLeft='xxs'
              color='black'
              variant='s'
              accessibilityLabel='Remove'
            />
          </TouchableOpacity>
        )}
        {isCompleted && (
          <Box
            flex={1}
            height={16}
            alignItems='flex-end'
            justifyContent='center'>
            {suffix}
          </Box>
        )}
      </RestyleTag>
    </TouchableOpacity>
  );
};

export default Tag;
