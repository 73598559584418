import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, TouchableOpacity } from 'react-native';

import { Box, Text } from '@components/Restyle';
import useDownloadFiles from '@hooks/useDownloadFiles';
import theme from '@themes/theme';

export const DownloadFiles: React.FC = () => {
  const { t } = useTranslation('models');
  const {
    selectedFiles,
    setSelectedFiles,
    downloadingSuccessFiles,
    setDownloadingSuccessFiles,
    downloadingErrorFiles,
    setDownloadingErrorFiles,
    downloadFiles,
    showMsg,
    setShowMsg,
    setIsDownloading,
  } = useDownloadFiles();

  useEffect(() => {
    setIsDownloading(false);
    setShowMsg(false);
    setSelectedFiles([]);
    setDownloadingSuccessFiles([]);
    setDownloadingErrorFiles([]);
  }, []);

  const screenWidth = Dimensions.get('screen').width;

  return (
    <>
      {(selectedFiles.length > 0 || downloadingSuccessFiles.length > 0) && (
        <Box
          backgroundColor='black'
          opacity={0.8}
          marginHorizontal='s'
          paddingHorizontal='s'
          paddingVertical='xs'
          borderRadius='xs'
          style={{
            position: 'absolute',
            bottom: 80,
            width: screenWidth - theme.spacing.s * 2,
          }}>
          <Text color='white' variant='labelSmall' lineHeight={16}>
            {!showMsg &&
              selectedFiles.length > 0 &&
              t('files.startDownloadFiles', {
                count: selectedFiles.length,
              })}
            {downloadingSuccessFiles.length > 0 &&
              downloadingErrorFiles.length === 0 &&
              t('files.downloadFilesSuccess', {
                downloadingFilesSuccessCount: downloadingSuccessFiles.length,
                downloadingFilesCount:
                  selectedFiles.length +
                  downloadingSuccessFiles.length +
                  downloadingErrorFiles.length,
              })}
          </Text>
        </Box>
      )}
      {downloadingErrorFiles.length > 0 && selectedFiles.length === 0 && (
        <Box
          backgroundColor='alertRedMild'
          flexDirection='row'
          marginHorizontal='s'
          paddingHorizontal='s'
          paddingVertical='xs'
          borderRadius='xs'
          style={{
            position: 'absolute',
            bottom: 80,
            width: screenWidth - theme.spacing.s * 2,
          }}>
          <Box flex={1}>
            <Text color='alertRed' variant='labelSmall' lineHeight={16}>
              {t('files.downloadFilesError', {
                downloadingFilesErrorCount: downloadingErrorFiles.length,
                downloadingFilesCount:
                  selectedFiles.length +
                  downloadingSuccessFiles.length +
                  downloadingErrorFiles.length,
              })}
            </Text>
          </Box>
          <TouchableOpacity
            onPress={() => {
              downloadFiles(downloadingErrorFiles, true);
            }}>
            <Text color='alertRed' variant='labelSmall' lineHeight={16}>
              {t('files.retry')}
            </Text>
          </TouchableOpacity>
        </Box>
      )}
    </>
  );
};
