import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColorAndIconSelect } from '@components/FormModals/ColorAndIconSelect.web';
import { WebModal } from '@components/Modals/WebModal';
import { CopyProjectDetails } from '@components/Projects/CopyProjectDetails';
import CreateProject from '@components/Projects/CreateProject';
import EditProject from '@components/Projects/EditProject';
import { Project } from '@graphql/generated';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { NestedNavigatorParams } from '@navigation/helpers';
import {
  ProjectDetailTabs,
  ProjectDetailTabsParamList,
} from '@navigation/projects/project-detail-tabs.web';
import { AllProjects } from '@screens/Projects/AllProjects.web';
import { ArchivedProjects } from '@screens/Projects/ArchivedProjects';

export type ProjectStackParamsList = {
  allprojects: undefined;
  summary: {
    projectId: Project['id'];
  } & Partial<NestedNavigatorParams<ProjectDetailTabsParamList>>;
  details: {
    projectId: Project['id'];
  } & Partial<NestedNavigatorParams<ProjectDetailTabsParamList>>;
  'archived-projects': undefined;
  new: undefined;
  edit: { projectId: Project['id'] };
  duplicate: { projectId: Project['id'] };
  colorSelect: { projectId: Project['id'] };
};

export type ProjectStackScreenProps<T extends keyof ProjectStackParamsList> =
  StackScreenProps<ProjectStackParamsList, T>;

const Stack = createStackNavigator<ProjectStackParamsList>();

export const ProjectsStack = () => {
  const { t } = useTranslation('shared');
  const { showProjectColorAndIconModal, setShowProjectColorAndIconModal } =
    useWebDrawerNavigator();
  return (
    <Stack.Navigator
      initialRouteName='allprojects'
      screenOptions={{ headerShown: false }}>
      {/* <Stack.Screen name='tabs' component={ProjectTabBar} /> */}
      <Stack.Screen name='allprojects' component={AllProjects} />
      <Stack.Screen name='details' component={ProjectDetailTabs} />
      <Stack.Screen name='archived-projects' component={ArchivedProjects} />
      <Stack.Screen
        name='new'
        component={CreateProject}
        options={{
          title: 'NEW title',
        }}
      />
      <Stack.Screen name='edit' component={EditProject} />
      {showProjectColorAndIconModal && (
        <Stack.Group>
          <Stack.Screen
            name='duplicate'
            options={{
              presentation: 'transparentModal',
              cardOverlayEnabled: true,
            }}>
            {({ navigation }) => (
              <WebModal
                visible
                width={504}
                title={t('shared:select')}
                onClose={() => {
                  setShowProjectColorAndIconModal(false);
                  navigation.goBack();
                }}>
                <CopyProjectDetails />
              </WebModal>
            )}
          </Stack.Screen>
          <Stack.Screen
            name='colorSelect'
            options={{
              presentation: 'transparentModal',
              cardOverlayEnabled: true,
            }}>
            {({ navigation }) => (
              <ColorAndIconSelect
                onClose={() => {
                  setShowProjectColorAndIconModal(false);
                  navigation.goBack();
                }}
              />
            )}
          </Stack.Screen>
        </Stack.Group>
      )}
    </Stack.Navigator>
  );
};
