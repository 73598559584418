import { useNavigation } from '@react-navigation/native';
import { Field, useFormikContext } from 'formik';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useTranslation } from 'react-i18next';
import { Alert, Linking } from 'react-native';

import { Box, ShadowBox, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import TextField from '@components/shared/TextField/TextField';
import { useGoogleAuth } from '@hooks/useGoogleAuth';
import { RegisterFormValues } from '@screens/Auth/RegisterForm';
import { getApiConfig } from '@utils/getApiConfig';
import { AsyncStorage } from '@utils/storage';

const SignUpWithEmail: React.FC = () => {
  const navigation = useNavigation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation('shared');
  const [loading, setLoading] = useState<boolean>(false);
  const { prompt, userInfo, setUserDetail } = useGoogleAuth();
  const [bodyWidth, setBodyWidth] = useState(0);
  const [textWidth, setTextWidth] = useState(0);
  const [pwdFocused, setPwdFocused] = useState(false);
  const [pwdHas8Chars, setPwdHas8Chars] = useState(false);
  const [pwdHas1Number, setPwdHas1Number] = useState(false);
  const [pwdHas1Uppercase, setPwdHas1Uppercase] = useState(false);
  const [pwdHas1SpecialChar, setPwdHas1SpecialChar] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isHoveredGoogle, setIsHoveredGoogle] = useState(false);
  const [isHoveredApple, setIsHoveredApple] = useState(false);
  const [isHoveredTerms, setIsHoveredTerms] = useState(false);
  const [isHoveredPrivacyPolicy, setIsHoveredPrivacyPolicy] = useState(false);
  const [isHoveredLogin, setIsHoveredLogin] = useState(false);

  const { errors, touched, isValid, setErrors, setTouched, values } =
    useFormikContext<RegisterFormValues>();

  useEffect(() => {
    userInfo && setLoading(true);
    userInfo && verifyUserEmail(userInfo.email);
  }, [userInfo]);

  const apiConfig = getApiConfig();
  const checkEmail = () => {
    setTouched({ ...touched, email: true });
    !errors.email && verifyUserEmail(values.email);
  };

  useEffect(() => {
    if (!values.password) {
      pwdHas8Chars && setPwdHas8Chars(false);
      pwdHas1Number && setPwdHas1Number(false);
      pwdHas1Uppercase && setPwdHas1Uppercase(false);
      pwdHas1SpecialChar && setPwdHas1SpecialChar(false);
    } else checkPassword();
  }, [values.password]);

  const checkPassword = () => {
    setTouched({ ...touched, password: true });

    // Check if the password field is empty
    if (!values.password) {
      setErrors({
        ...errors,
        password: 'Password is required.',
      });
    } else {
      const pwdHas8Chars = /.{8,}/.test(values.password);
      setPwdHas8Chars(pwdHas8Chars);

      const pwdHas1Number = /[0-9]/.test(values.password);
      setPwdHas1Number(pwdHas1Number);

      const pwdHas1Uppercase = /[A-Z]/.test(values.password);
      setPwdHas1Uppercase(pwdHas1Uppercase);

      const pwdHas1SpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(values.password);
      setPwdHas1SpecialChar(pwdHas1SpecialChar);

      // Check password against Yup validation schema
      const isPasswordValid =
        /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)(?=.*[A-Z]).{8,}$/.test(
          values.password
        );
      setIsPasswordValid(isPasswordValid);
    }
  };
  const disabled =
    (touched.email && !!errors.email) ||
    (!!errors.email && !isValid) ||
    (touched.password && !!errors.password) ||
    (!!errors.password && !isValid);

  const verifyUserEmail = async (email: string) => {
    const verifyUser = `/api/v1/verify_user.json`;
    const userPayload = {
      user: {
        email: email.toLowerCase(),
        is_verified: true,
      },
    };
    const authEndpoint = `${apiConfig.apiUrl}${verifyUser}`;

    await fetch(authEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userPayload),
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (response.success) {
          setErrors({
            email:
              'This email already exists. Please use other email or login.',
          });
        } else if (response.error) {
          navigation.navigate(
            userInfo ? 'create-profile-for-google-and-apple' : 'create-profile',
            { socialSignUp: true }
          );
        }
      })
      .catch((_err) => {
        // console.log('err', err);
      });
  };

  const appleSignInWeb = async (response: any) => {
    try {
      const localResponse = await AsyncStorage.getItem('appleUserInfo');
      let decodeEmail = '';

      if (!localResponse) {
        const token = response.authorization.id_token;
        const decoded = jwt_decode(token);
        if (decoded) {
          decodeEmail = decoded.email;
        }
      }
      const userData = JSON.parse(localResponse);
      const userInfoPayload = {
        id:
          response.authorization.id_token || userData?.authorization?.id_token,
        email: response?.user?.email || userData?.user?.email || decodeEmail,
        given_name:
          response?.user?.name?.firstName || userData?.user?.name?.firstName,
        family_name:
          response?.user?.name?.lastName || userData?.user?.name?.lastName,
        provider: 'Apple',
      };
      if (response.user && response.user.email) {
        await AsyncStorage.setItem('appleUserInfo', JSON.stringify(response));
        setUserDetail && setUserDetail(userInfoPayload);
      } else {
        if (response) {
          setUserDetail && setUserDetail(userInfoPayload);
        } else {
          Alert.alert('TaskTag', t('onBoarding.error.emailNotFound'));
        }
      }
    } catch (e) {
      // console.log('Apple Sign In Error:-', e);
    }
  };

  const getCheckNode = (flg: boolean, label: string) => {
    return (
      <Box flexDirection='row' mb='xxs'>
        {flg ? (
          <Icon name='Check' variant='s' color='greenSecondary' />
        ) : (
          <Icon name='Dot' variant='s' />
        )}
        <Text
          ml='xxxs'
          variant='metadata'
          color={flg ? 'greenSecondary' : 'textSecondary'}>
          {label}
        </Text>
      </Box>
    );
  };

  return (
    <Box
      flex={1}
      backgroundColor='grey01'
      alignItems='center'
      justifyContent='center'>
      <ShadowBox
        variant='base'
        backgroundColor='white'
        borderRadius='m'
        style={{
          width: 492,
        }}
        onLayout={(e) => {
          setBodyWidth(e.nativeEvent.layout.width);
        }}>
        <Box
          alignItems='center'
          borderRadius='m'
          px='xl'
          mx='xxs'
          py='l'
          my='xs'
          backgroundColor='white'>
          <Icon name='Logo' width={142} height={43} marginBottom='xl' />
          <Text variant='heading2' marginVertical='xxs'>
            {t('models:onBoarding.createAnAccount')}
          </Text>
          <Text variant='webBodySecondary' marginBottom='l'>
            {t('models:onBoarding.letsGetStartedWithYour30DaysFreeTrial')}
          </Text>
          <Field
            component={TextField}
            label={t('email')}
            name='email'
            placeholder='example@site.com'
            marginBottom='xs'
            textInputProps={{
              autoCapitalize: 'none',
              autoCorrect: false,
              keyboardType: 'email-address',
              onSubmitEditing: () => {
                setTouched({ ...touched, password: true });
                checkPassword();
                if (values.email && isPasswordValid && !disabled) checkEmail();
              },
            }}
            accessibilityLabel={t('shared:pleaseEnterYourEmail')}
            validateOnChange={false}
          />
          <Box
            flex={1}
            alignItems='center'
            alignSelf='flex-start'
            marginBottom='l'>
            <Text variant='metadata'>
              {t(
                'models:onBoarding.aVerificationEmailWillBeSentToYouToVerifyYourAddress'
              )}
            </Text>
          </Box>
          <Field
            component={TextField}
            label={t('password')}
            name='password'
            accessibilityLabel={t('models:onBoarding.enterPassword')}
            placeholder={t('models:onBoarding.enterPassword')}
            marginBottom='xs'
            suffix={
              <Icon
                name={showPassword ? 'Eye' : 'EyeOff'}
                variant='l'
                color='textPrimary'
                onPress={() => setShowPassword(!showPassword)}
              />
            }
            textInputProps={{
              autoCapitalize: 'none',
              autoCorrect: false,
              secureTextEntry: !showPassword,
              onSubmitEditing: async () => {
                checkPassword();
                if (isPasswordValid) {
                  setTouched({ ...touched, email: true });
                  if (
                    !(
                      (!!errors.email && !isValid) ||
                      (!!errors.password && !isValid)
                    )
                  ) {
                    await verifyUserEmail(values.email);
                  }
                }
              },
            }}
            onChangeText={() => {
              checkPassword();
            }}
            onFocus={(b: boolean) => setPwdFocused(b)}
            validateOnChange={true}
          />
          {pwdFocused && (
            <Box
              alignSelf='flex-start'
              marginBottom='xs'
              width={bodyWidth - 80}>
              <Text variant='metadata' color='textSecondary' mb='xxs'>
                {t('models:onBoarding.yourPasswordMustContain')}
              </Text>
              {getCheckNode(
                pwdHas8Chars,
                t('models:onBoarding.atLeast8Characters')
              )}
              {getCheckNode(
                pwdHas1Number,
                t('models:onBoarding.atLeast1Number')
              )}
              {getCheckNode(
                pwdHas1Uppercase,
                t('models:onBoarding.atLeast1UppcaseLetter')
              )}
              {getCheckNode(
                pwdHas1SpecialChar,
                t('models:onBoarding.atLeast1SpecialCharacter')
              )}
            </Box>
          )}
          <Box
            flex={1}
            flexDirection='row'
            marginTop='m'
            marginBottom='l'
            alignSelf='flex-start'>
            <Text variant='typoMetadata' color='textPrimary'>
              {t('models:onBoarding.bySigningUpIAgreeToTheTaskTag')}
              <Text
                accessibilityLabel={t('models:onBoarding.termsAndConditions')}
                variant='typoMetadata'
                color='greenSecondary'
                textDecorationLine={isHoveredTerms ? 'underline' : undefined}
                onMouseEnter={() => setIsHoveredTerms(true)}
                onMouseLeave={() => setIsHoveredTerms(false)}
                onPress={async () => {
                  await Linking.openURL(
                    'https://www.tasktag.com/terms-and-conditions'
                  );
                }}>
                {t('models:onBoarding.termsAndConditions')}
              </Text>
              {t('models:onBoarding.and')}
              <Text
                accessibilityLabel={t('models:onBoarding.privacyPolicy')}
                variant='typoMetadata'
                color='greenSecondary'
                textDecorationLine={
                  isHoveredPrivacyPolicy ? 'underline' : undefined
                }
                onMouseEnter={() => setIsHoveredPrivacyPolicy(true)}
                onMouseLeave={() => setIsHoveredPrivacyPolicy(false)}
                onPress={async () => {
                  await Linking.openURL('https://www.tasktag.com/privacy');
                }}>
                {t('models:onBoarding.privacyPolicy')}
              </Text>
              {t('models:onBoarding.dot')}
            </Text>
          </Box>
          <Button
            accessibilityLabel={t('createAccount')}
            variant='webprimary'
            color='grey04'
            fullWidth
            isSmall
            onPress={async () => {
              setTouched({ ...touched, email: true, password: true });
              if (
                !(
                  (!!errors.email && !isValid) ||
                  (!!errors.password && !isValid)
                )
              ) {
                await verifyUserEmail(values.email);
              }
            }}
            disabled={disabled || !isPasswordValid}>
            {t('createAccount')}
          </Button>
          <Box
            flexDirection='row'
            alignItems='center'
            marginTop='l'
            width={bodyWidth - 80}>
            <Box
              width={(bodyWidth - 80 - textWidth - 48) / 2}
              height={1}
              backgroundColor='grey04'
            />
            <Text
              variant='webBodySecondary'
              color='grey04'
              mx='l'
              onLayout={(e) => {
                setTextWidth(e.nativeEvent.layout.width);
              }}>
              {t('models:onBoarding.orSignUpWith')}
            </Text>
            <Box
              width={(bodyWidth - 80 - textWidth - 48) / 2}
              height={1}
              backgroundColor='grey04'
            />
          </Box>
          <Box flexDirection='row' mt='l'>
            <Box mr='xs' width={202}>
              <Button
                onMouseEnter={() => setIsHoveredGoogle(true)}
                onMouseLeave={() => setIsHoveredGoogle(false)}
                onPress={() => {
                  prompt();
                }}
                prefix={
                  <Icon
                    variant='l'
                    marginRight='xs'
                    name='Google'
                    color='textPrimary'
                    accessibilityLabel='googleIcon'
                  />
                }
                disabled={loading}
                borderWidth={1}
                borderColor={isHoveredGoogle ? 'black' : 'grey03'}
                backgroundColor={isHoveredGoogle ? 'grey01' : 'white'}
                accessibilityLabel={t('models:onBoarding.google')}
                fullWidth
                isSmall>
                <Text variant='webBodySecondary'>
                  {t('models:onBoarding.google')}
                </Text>
              </Button>
            </Box>
            <Box width={202}>
              <AppleSignin
                /** Auth options passed to AppleID.auth.init()  */
                authOptions={{
                  clientId: process.env.APPLE_CLIENT_ID_WEB,
                  scope: 'name email',
                  redirectURI: process.env.APPLE_REDIRECT_UI,
                  state: '',
                  nonce: 'nonce',
                  usePopup: true,
                }}
                render={(props) => (
                  <Button
                    onMouseEnter={() => setIsHoveredApple(true)}
                    onMouseLeave={() => setIsHoveredApple(false)}
                    fullWidth
                    isSmall
                    borderWidth={1}
                    borderColor={isHoveredApple ? 'black' : 'grey03'}
                    backgroundColor={isHoveredApple ? 'grey01' : 'white'}
                    accessibilityLabel={t('models:onBoarding.apple')}
                    prefix={
                      <Icon
                        variant='l'
                        name='Apple24'
                        color='textPrimary'
                        accessibilityLabel={t('models:onBoarding.apple')}
                      />
                    }
                    {...props}>
                    <Text variant='webBodySecondary'>
                      {t('models:onBoarding.apple')}
                    </Text>
                  </Button>
                )}
                uiType='light'
                className='apple-auth-btn'
                onSuccess={(response: any) => appleSignInWeb(response)} // default = undefined
                // eslint-disable-next-line no-console
                onError={(error: any) => console.error('error', error)} // default = undefined
              />
            </Box>
          </Box>
          <Box flex={1} flexDirection='row' marginTop='l'>
            <Text variant='webBodySecondary' color='black'>
              {t('models:onBoarding.alreadyHaveAnAccount')}
            </Text>
            <Text
              variant='webBodySecondary'
              color='greenSecondary'
              accessibilityLabel={t('models:onBoarding.logIn')}
              textDecorationLine={isHoveredLogin ? 'underline' : undefined}
              onMouseEnter={() => setIsHoveredLogin(true)}
              onMouseLeave={() => setIsHoveredLogin(false)}
              onPress={() => {
                navigation.navigate('login');
              }}>
              {t('models:onBoarding.logIn')}
            </Text>
          </Box>
        </Box>
      </ShadowBox>
    </Box>
  );
};

export default SignUpWithEmail;
