import {
  createRestyleComponent,
  createVariant,
  color,
  border,
  spacing,
  ColorProps,
  BorderProps,
  SpacingProps,
  VariantProps,
  SpacingShorthandProps,
  LayoutProps,
} from '@shopify/restyle';
import { ComponentProps, ReactNode } from 'react';
import { TouchableOpacity } from 'react-native';

import { Text } from '@components/Restyle';
import { Theme } from '@themes/theme';

type RestyleProps = ColorProps<Theme> &
  BorderProps<Theme> &
  SpacingProps<Theme> &
  SpacingShorthandProps<Theme> &
  VariantProps<Theme, 'badgeVariants'> &
  LayoutProps<Theme>;

const RestyleBadge = createRestyleComponent<
  RestyleProps & { children: ReactNode },
  Theme
>([color, border, spacing, createVariant({ themeKey: 'badgeVariants' })]);

type Props = Omit<ComponentProps<typeof RestyleBadge>, 'children'> & {
  label: string;
  activeOpacity?: number;
  onPress?: () => void;
};

export const Badge: React.FC<Props> = ({
  label,
  activeOpacity = 0.2,
  onPress,
  ...rest
}: Props) => {
  let textColor: ComponentProps<typeof Text>['color'] = 'textSecondary';

  switch (rest.variant) {
    case 'red':
      textColor = 'alertRed';
      break;

    case 'yellow':
      textColor = 'yellowVivid';
      break;

    case 'green':
      textColor = 'greenDark';
      break;

    default:
      textColor = 'textSecondary';
  }

  return (
    <TouchableOpacity
      activeOpacity={activeOpacity}
      onPress={onPress}
      accessibilityLabel={label}>
      <RestyleBadge {...rest} alignSelf='flex-start'>
        <Text
          variant='labelSmall'
          color={textColor}
          textAlign='center'
          style={!!rest.width && { flex: 1 }}>
          {label}
        </Text>
      </RestyleBadge>
    </TouchableOpacity>
  );
};
