import EmptyStateNoFiles from '@components/EmptyState/EmptyStateNoFiles';
import ProjectCard from '@components/ProjectCard/ProjectCard';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { CollapsableSectionList } from '@components/shared/CollapsableSectionList';
import Icon from '@components/shared/Icon/Icon';
import { Project, Team } from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import { useAppNavigation } from '@navigation/useAppNavigation';
import theme from '@themes/theme';

import EmptyStateNoFilesTeams from './EmptyStateNoFilesTeams';

type TeamHeader =
  | Team
  | {
      id: string;
      name: string;
      personal: boolean;
      avatar: string;
    };

type TeamSection = {
  sectionHeader: TeamHeader;
  data: Project[];
};
export const FilesTeamWiseProjects: React.FC = () => {
  const navigation = useAppNavigation();
  const { teams, loading } = useListTeamsFromQuery();

  const sortedTeams = [...teams].sort((a, b) => {
    // First, check if it's a personal project
    const aIsPersonal = a.personal;
    const bIsPersonal = b.personal;

    if (aIsPersonal && !bIsPersonal) {
      return -1; // a should come before b
    } else if (!aIsPersonal && bIsPersonal) {
      return 1; // b should come before a
    }
    // If both are personal or both are not personal, sort alphabetically by team name
    return a.name.localeCompare(b.name);
  });

  const allTeamsWithProjects: TeamSection[] = sortedTeams.map((team) => {
    const projects = team.projects ?? [];
    const sortedProjects = [...projects];
    sortedProjects.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    const sectionHeader = {
      id: team.id,
      name: team.name,
      personal: team.personal,
      avatar: team.avatar,
    };
    return { sectionHeader: sectionHeader, data: sortedProjects };
  });

  if (loading) return <ActivityIndicatorLoading />;

  const sectionHeader = (item: TeamHeader) => {
    const isPersonalProject = item?.personal ?? false;
    return (
      <Box flex={1}>
        <Box flexDirection='row'>
          <Box
            width={24}
            height={24}
            alignItems='center'
            justifyContent='center'
            backgroundColor={isPersonalProject ? 'greenSecondary' : 'black'}
            borderRadius='xxxs'
            marginRight='m'>
            {isPersonalProject ? (
              <Icon name='Office' variant='s' color='white' disabled={false} />
            ) : (
              <Icon name='TT' variant='s' color='white' disabled={false} />
            )}
          </Box>
          <Text color='textPrimary' variant='labelEmphasized'>
            {isPersonalProject ? 'Personal Projects' : item.name}
          </Text>
        </Box>
      </Box>
    );
  };
  const modalloading = teams.length === 1;

  return (
    <Box flex={1}>
      <Box>{modalloading && <EmptyStateNoFilesTeams />}</Box>
      <CollapsableSectionList<TeamHeader, Project>
        renderEmptyComponent={<EmptyStateNoFiles />}
        data={allTeamsWithProjects}
        renderSectionHeader={(item) => sectionHeader(item)}
        renderItem={(item, index) => {
          return (
            <Box style={{ marginTop: index ? 0 : -theme.spacing.xs }}>
              {!!index && <Box height={1} backgroundColor='grey02' />}
              <Box marginVertical='xs'>
                <ProjectCard
                  expanded={false}
                  project={item}
                  onClick={() => {
                    navigation.navigateToProjectFiles(item);
                  }}
                />
              </Box>
            </Box>
          );
        }}
        dataListHeaderStyle={{
          padding: 'xs',
          marginBottom: 'xs',
          backgroundColor: 'grey01',
        }}
      />
    </Box>
  );
};
