/* eslint-disable @typescript-eslint/no-explicit-any */
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, KeyboardAvoidingView } from 'react-native';

import { ProjectTagList } from '@components/Chat/ProjectTagList';
import { TaskTagList } from '@components/Chat/TaskTagList';
import EmptySearchNoTask from '@components/EmptyState/EmptySearchNoTask';
import EmptyStateNoProjectTag from '@components/EmptyState/EmptyStateNoProjectTag';
import { Box } from '@components/Restyle';
import SearchLoadMore from '@components/Search/SearchLoadMore';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import SearchInput from '@components/shared/SearchInput/SearchInput.web';
import {
  SearchableResult,
  Chat,
  useSearchTagsLazyQuery,
  Project,
  Task,
} from '@graphql/generated';
import { useListChatProjectsTasksFromQuery } from '@hooks/useListChatProjectsTasksFromQuery';
import useTagInput from '@hooks/useTagInput';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { Search, SearchResultType } from '@root/types';
import { ProjectTagType } from '@src/types/project';
import { TaskTagType } from '@src/types/task';
import { isProjectSearchResult, isTaskSearchResult } from '@utils/typeGuards';

const DEBOUNCE = 500;
const PAGESIZE = 20;

interface ChatRelatedTagsContentProps {
  bottomInset?: number;
  filterVal: string;
  chatId: Chat['id'];
  isFrom: string;
}

const PER_PAGE_COUNT = 10;

export const ChatRelatedTagsContent: React.FC<ChatRelatedTagsContentProps> = ({
  chatId,
  isFrom,
}) => {
  const [filterVal, setFilterVal] = useState('');

  const { tags } = useTagInput();

  const tagsCollection = tags(chatId);

  const tagedTasksIds: string[] = [];
  tagsCollection.forEach((t2) =>
    t2.tasks.forEach((t3) => tagedTasksIds.push(t3.id))
  );

  const [loading, setLoading] = useState(false);

  const {
    projectsTasks: allProjectsTasks,
    refetch: refetchProjects,
    refreshing: refreshingProjects,
    setRefreshing: setRefreshingProjects,
    pageInfo,
    fetchMoreFromCursor: fetchMoreProjectsFromCursor,
    loading: allProjectsTasksLoading,
  } = useListChatProjectsTasksFromQuery({
    first: PER_PAGE_COUNT,
    chatId: chatId,
  });

  const { navigateToTask, navigateToProject } = useAppNavigation();
  const navigateToProjectDetails = (p: Pick<Project, 'id'>) => {
    navigateToProject(p);
  };

  const navigateToTaskDetails = (t: Pick<Task, 'id' | 'projectId'>) => {
    navigateToTask(t);
  };

  const projectsTasksData = allProjectsTasks;

  const [globalSearch, { data, loading: loadingSearch }] =
    useSearchTagsLazyQuery();
  const searchData = (data?.search as Search<SearchableResult>[]) || [];
  const initialResults = {
    messages: [],
    tasks: [],
    documents: [],
    projects: [],
    contacts: [],
  };

  const [searchResultsPage, setSearchResultsPage] = useState(initialResults);
  const [isProjectLength, setProjectLength] = useState(0);

  const getLoadMore = (results: SearchResultType) => {
    if (
      results.projects.length > 0 &&
      results.projects.length < results.projects[0].total
    ) {
      setProjectLength(results.projects.length);
    } else {
      setProjectLength(0);
    }
  };

  useEffect(() => {
    const results = searchData.reduce<SearchResultType>((previous, result) => {
      if (
        previous.projects.some(
          (existingResult) => existingResult.record.id === result.record.id
        )
      )
        return previous;
      if (isProjectSearchResult(result))
        return { ...previous, projects: [...previous.projects, result] };
      if (
        previous.tasks.some(
          (existingResult) => existingResult.record.id === result.record.id
        )
      )
        return previous;
      if (isTaskSearchResult(result))
        return { ...previous, tasks: [...previous.tasks, result] };
    }, searchResultsPage);
    setSearchResultsPage(results);
    getLoadMore(results);
  }, [searchData]);

  const [projectResults, setProjectResults] = useState([]);
  useEffect(() => {
    const searchResultsProject = searchResultsPage.projects
      ? searchResultsPage.projects.map((r) => r.record)
      : [];

    setProjectResults(searchResultsProject);
  }, [searchResultsPage]);

  const [isLoadMore, setIsLoadMore] = useState(false);
  const from = isProjectLength;
  const globalSearchCall = () => {
    globalSearch({
      variables: {
        term: filterVal,
        from: from,
        size: PAGESIZE,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: true,
        includeTasks: false,
        includeChats: false,
        includeTasksTags: true,
        includeCompletedTask: true,
        chatId: chatId,
      },
      onCompleted: () => {
        setLoading(false);
        !isLoadMore && setIsLoadMore(true);
      },
      onError: () => setLoading(false),
    });
  };

  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [filterVal]
  );

  useEffect(() => {
    setSearchResultsPage(initialResults);
    debouncedGlobalSearch();
    setProjectLength(0);
    setIsLoadMore(false);
    return () => debouncedGlobalSearch.cancel();
  }, [filterVal]);

  useEffect(() => {
    if (filterVal.length) {
      setLoading(true);
      debouncedGlobalSearch();
    }
    return () => {
      debouncedGlobalSearch.cancel();
      setLoading(false);
    };
  }, [filterVal]);

  const renderLoadMoreComponent = () => {
    if (isProjectLength > 0) {
      return (
        <SearchLoadMore
          loading={loadingSearch && isLoadMore}
          onLoadMore={globalSearchCall}
        />
      );
    }
    return <Box mb='s' />;
  };

  const renderItem = (item: Project) => {
    return (
      <Box>
        <ProjectTagList
          key={item.id}
          refreshing={refreshingProjects}
          loading={false}
          isFrom={isFrom}
          onRefresh={() => {
            setRefreshingProjects(true);
            refetchProjects();
          }}
          filterVal={filterVal}
          projectTags={[item]}
          onSelect={(newProject: ProjectTagType) =>
            navigateToProjectDetails(newProject)
          }
        />

        {item?.tasks?.map((taskObj) => {
          return (
            <TaskTagList
              key={taskObj.id}
              filterVal={filterVal}
              taskTags={[taskObj]}
              showMember
              onSelect={(newTask: TaskTagType) =>
                navigateToTaskDetails(newTask)
              }
              loading={false}
            />
          );
        })}
      </Box>
    );
  };
  const renderFooterUI = (isLoadingMore: boolean) => {
    return (
      <Box marginBottom='listFooter'>
        {isLoadingMore && <ActivityIndicatorLoading />}
      </Box>
    );
  };

  const renderSearch = (item: Project) => {
    return (
      <Box>
        <ProjectTagList
          key={item.id}
          refreshing={refreshingProjects}
          loading={false}
          isFrom={isFrom}
          onRefresh={() => {
            setRefreshingProjects(true);
            refetchProjects();
          }}
          filterVal={filterVal}
          projectTags={[item]}
          onSelect={(newProject: ProjectTagType) =>
            navigateToProjectDetails(newProject)
          }
        />

        {item?.tasksTagList?.map((taskObj) => {
          return (
            <TaskTagList
              key={taskObj.id}
              filterVal={filterVal}
              taskTags={[taskObj]}
              showMember
              onSelect={(newTask: TaskTagType) => {
                navigateToTaskDetails(newTask);
              }}
              loading={false}
            />
          );
        })}
      </Box>
    );
  };

  const showEmptyState = projectsTasksData.every((i) => i.data?.length === 0);
  const showNoTask =
    filterVal && !loading && !loadingSearch && projectResults.length === 0;

  return (
    <Box
      backgroundColor='white'
      style={{
        flex: 1,
        height: '100%',
      }}>
      <Box marginTop='xs' paddingHorizontal='m'>
        <Box marginTop='m' flexDirection='row' alignItems='center'>
          <SearchInput
            onTextChanged={(v) => {
              setFilterVal(v);
            }}
            onCancel={() => setFilterVal('')}
            value={filterVal}
            autoFocus
            isTagSearch={true}
            isChatRelatedTagSearch={true}
          />
        </Box>
      </Box>
      <KeyboardAvoidingView style={{ flex: 1, paddingBottom: 10 }}>
        {showEmptyState && !allProjectsTasksLoading && !filterVal && (
          <EmptyStateNoProjectTag />
        )}
        {showNoTask && <EmptySearchNoTask isFromChatTags={true} />}
        {allProjectsTasksLoading && <ActivityIndicatorLoading />}
        {projectsTasksData.length !== 0 &&
          filterVal.length === 0 &&
          !showEmptyState && (
            <FlatList
              data={projectsTasksData}
              keyboardShouldPersistTaps='always'
              showsVerticalScrollIndicator={false}
              renderItem={({ item }) => {
                return <>{renderItem(item)}</>;
              }}
              initialNumToRender={10}
              ListFooterComponent={() =>
                renderFooterUI(pageInfo?.hasNextPage || false)
              }
              onEndReached={() => {
                fetchMoreProjectsFromCursor();
              }}
            />
          )}
        {projectResults.length === 0 && filterVal.length > 0 && !showNoTask && (
          <ActivityIndicatorLoading />
        )}
        {filterVal.length > 0 && projectResults.length !== 0 && (
          <FlatList
            data={projectResults}
            ListFooterComponent={renderLoadMoreComponent()}
            keyboardShouldPersistTaps='always'
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => {
              return <>{renderSearch(item)}</>;
            }}
          />
        )}
      </KeyboardAvoidingView>
    </Box>
  );
};
