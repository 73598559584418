import { useNavigation } from '@react-navigation/native';
import { useFormikContext } from 'formik';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import { ProjectIconGrid } from '@components/FormModals/ProjectIconGrid.web';
import { ShadowBox, Box } from '@components/Restyle';
import Images from '@themes/images';

interface ColorPickerProps {
  isBottomSheet?: boolean;
  onSelectFinish?: () => void;
}

const IconPicker: React.FC<ColorPickerProps> = ({
  isBottomSheet,
  onSelectFinish,
}) => {
  const { setFieldValue, getFieldProps, touched, setTouched } =
    useFormikContext();
  const { name: fieldName, value } = getFieldProps('icon');
  const navigation = useNavigation();

  const iconList = () => {
    const icons: (keyof typeof Images)[] = [];
    for (let i = 0; i < 40; i++) {
      icons.push(('IconStart' + i) as keyof typeof Images);
    }
    return icons;
  };

  const getIconList = () => {
    return (
      <Box padding='m' borderRadius='xs'>
        <ProjectIconGrid
          selectedIcon={value}
          bodyWidth={356}
          numColumns={10}
          list={iconList()}
          onPress={(icon: keyof typeof Images) => {
            setFieldValue(fieldName, icon);
            setTouched({ ...touched, [fieldName]: true });
            onSelectFinish ? onSelectFinish() : navigation.pop();
          }}
        />
      </Box>
    );
  };

  if (isBottomSheet) {
    return getIconList();
  }

  return (
    <Box flex={1} justifyContent='center' alignItems='center'>
      <TouchableOpacity
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        onPress={() => {
          navigation.goBack();
        }}
        activeOpacity={1}
      />
      <ShadowBox variant='thin' borderRadius='xs' backgroundColor='white'>
        {getIconList()}
      </ShadowBox>
    </Box>
  );
};

export default IconPicker;
