import { useListTasksQuery } from '@graphql/generated';

export const useTasks = () => {
  const perPageCount = 15;

  const { data } = useListTasksQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      before: '',
      first: perPageCount,
    },
  });
  const { listTasks } = data || { listTasks: null };
  const { edges } = listTasks || { edges: [], pageInfo: null };
  const tasks = (edges || []).map((item) => item?.node);
  return tasks;
};
