import { useState } from 'react';
import { RefreshControl } from 'react-native';

import {
  Project,
  ProjectMemberRole,
  ProjectSortOption,
  useListProjectsBasicInfoQuery,
} from '@graphql/generated';
import {
  logError,
  LogGraphError,
  LogNetworkError,
} from '@utils/logNonFatalError';

/**
 * Retrieves a list of projects
 */
//changed the size from 10 to 25 to stop project selection modal blinking due to loading data in chunks in single scroll
const DEFAULT_PER_PAGE_COUNT = 25;

interface ListProjectsBasicInfoFromQueryProps {
  userId: string;
  sortBy?: ProjectSortOption;
  role?: ProjectMemberRole;
  archivesOnly?: boolean;
  locationOnly?: boolean;
  onCompleted?: () => void;
  onError?: () => void;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  filterTeamsProjects?: boolean;
}

export const useListProjectsBasicInfoFromQuery = (
  props?: ListProjectsBasicInfoFromQueryProps
) => {
  const {
    userId = undefined,
    sortBy = undefined,
    role = undefined,
    filterTeamsProjects = false,
    archivesOnly = false,
    locationOnly = false,
    onCompleted = undefined,
    onError = undefined,
    first = DEFAULT_PER_PAGE_COUNT,
    last = undefined,
    after = undefined,
    before = undefined,
  } = props || {};

  const [refreshing, setRefreshing] = useState(false);

  const { data, loading, error, refetch, fetchMore } =
    useListProjectsBasicInfoQuery({
      variables: {
        userId,
        sortBy,
        role,
        archivesOnly,
        locationOnly,
        first,
        last,
        filterTeamsProjects,
        before,
        after,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setRefreshing(false);
        onCompleted && onCompleted();
      },
      onError: (error) => {
        if (error.networkError) {
          const event: LogNetworkError = {
            message: 'List Projects Network Failure',
            errorMessage: error.message,
            statusCode:
              'statusCode' in error.networkError
                ? error.networkError.statusCode
                : -1,
            extra: [{ archivesOnly }],
          };
          logError(event);
        }
        error.graphQLErrors.forEach((graphError) => {
          const event: LogGraphError = {
            message: 'List Projects GraphQL Error',
            errorMessage: graphError.message,
            operationName: 'listProjects',
            extra: [{ archivesOnly }],
          };
          logError(event);
        });

        onError?.();
      },
    });

  const refreshControl = (
    <RefreshControl
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true);
        refetch();
      }}
    />
  );

  const fetchFromCursor = () => {
    if (!projects || !pageInfo?.hasNextPage || loading) return;

    const cursor = pageInfo.endCursor;
    fetchMore({
      variables: {
        after: cursor,
        first,
      },
    });
  };

  const { listProjects } = data || { listProjects: undefined };
  const { edges = [], pageInfo } = listProjects || { edges: [] };
  const projects =
    edges?.reduce((acc, curr) => {
      if (curr && curr.node && curr.node !== undefined) {
        return [...acc, curr.node as Project];
      } else {
        return acc;
      }
    }, [] as Project[]) || [];

  return {
    projects,
    loading,
    error,
    pageInfo,
    refetch,
    fetchFromCursor,
    refreshing,
    setRefreshing,
    refreshControl,
  };
};
