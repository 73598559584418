/* eslint-disable no-nested-ternary */
import React from 'react';
import { Dimensions } from 'react-native';

import { Box } from '@components/Restyle';
import { ChatListDrawer } from '@components/Web/Chats/ChatListDrawer.web';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { ChatsData } from '@screens/Chats/ChatsData';

export const ChatListPanel = () => {
  const { chatListOpen } = useWebDrawer();
  const chatWidth = Dimensions.get('window').width <= 1280 ? '480px' : '550px';
  return (
    <Box
      position='absolute'
      zIndex={9}
      top={0}
      right={0}
      bottom={0}
      width={chatListOpen ? chatWidth : 81}
      alignSelf='flex-end'
      flexDirection='row'>
      <ChatsData>{(props) => <ChatListDrawer {...props} />}</ChatsData>
    </Box>
  );
};
