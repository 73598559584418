import { useApolloClient } from '@apollo/client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@components/Alert';
import { TagsCollectionType } from '@components/Chat/TagsCollection';
import {
  Chat,
  ChatDetailFragmentDoc,
  ChatUpdatedDocument,
  ListChatsDocument,
  LocalFile,
  Message,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import useMe from '@hooks/useMe';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { ProjectTagType } from '@src/types/project';
import { TaskTagType } from '@src/types/task';
import { useFilePicker } from '@utils/filePicker';
import { fileToLocalFile } from '@utils/fileUtils';

type ChatInputWithChatId = {
  activeChatId: string;
  chatInput: ChatInputItem;
};

type ChatInputItem = {
  message: string;
  tagsCollection: TagsCollectionType[] | undefined;
  localFiles: LocalFile[];
  replyMessage: Message | undefined;
};

type ChatInputContext = {
  getReplyMessage: (chatId: Chat['id']) => Message | undefined;
  getTagsCollection: (chatId: Chat['id']) => TagsCollectionType[];
  getLocalFiles: (chatId: Chat['id']) => LocalFile[];
  chatInputs: ChatInputWithChatId[];
  setChatInputs: React.Dispatch<React.SetStateAction<ChatInputWithChatId[]>>;
  isTagModalOpen: boolean;
  setIsTagModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  existingTagsCollection: TagsCollectionType[] | undefined;
  setExistingTagsCollection: React.Dispatch<
    React.SetStateAction<TagsCollectionType[] | undefined>
  >;
  filterVal: string;
  setFilterVal: React.Dispatch<React.SetStateAction<string>>;
  selectedMessage: Message | undefined;
  setSelectedMessage: React.Dispatch<React.SetStateAction<Message | undefined>>;
  selectedChat: Chat | undefined;
  setSelectedChat: React.Dispatch<React.SetStateAction<Chat | undefined>>;
  selectedReplyMessage: Message | undefined;
  setSelectedReplyMessage: React.Dispatch<
    React.SetStateAction<Message | undefined>
  >;
  setMessage: (message: string) => void;
  setTagsCollection: (
    chatId: Chat['id'],
    tagsCollection: TagsCollectionType[]
  ) => void;
  updateTagsCollectionTasks: (chatId: Chat['id'], task: TaskTagType) => void;
  updateTagsCollectionProject: (
    chatId: Chat['id'],
    project: ProjectTagType
  ) => void;
  removeTagsCollection: (chatId: Chat['id'], indexToDelete: number) => void;
  removeTagsCollectionTask: (
    chatId: Chat['id'],
    tagIndex: number,
    collectionIndex: number
  ) => void;
  setLocalFiles: (chatId: Chat['id'], files: LocalFile[]) => void;
  setReplyMessage: (chatId: Chat['id'], message?: Message) => void;
  pickChatImages: (chatId: Chat['id']) => Promise<void>;
  pickChatFiles: (chatId: Chat['id']) => Promise<void>;
  launchChatCamera: (chatId: Chat['id']) => Promise<void>;
  focus: boolean;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
  modalHeight: number;
  setModalHeight: React.Dispatch<React.SetStateAction<number>>;
  messageModalHeight: number;
  setMessageModalHeight: React.Dispatch<React.SetStateAction<number>>;
  shouldShowTutorialHashTag: boolean;
  setShouldShowTutorialHashTag: React.Dispatch<React.SetStateAction<boolean>>;
  doNotShowTutorial: boolean;
  setDoNotShowTutorial: React.Dispatch<React.SetStateAction<boolean>>;
  showChatOptionsBar: boolean;
  setShowChatOptionsBar: React.Dispatch<React.SetStateAction<boolean>>;
  dropFileHandler: (chatId: Chat['id'], files: File[]) => Promise<void>;
  playingVoiceMemoId: string;
  setPlayingVoiceMemoId: React.Dispatch<React.SetStateAction<string>>;
  subscribeToChatUpdates: () => () => void;
  getCameraPhoto: (chatId: Chat['id'], r: LocalFile[]) => void;
  hasCameraPermission: boolean;
  setHasCameraPermission: React.Dispatch<React.SetStateAction<boolean>>;
} & Pick<ChatInputItem, 'message'>;

const chatInputContext = createContext<ChatInputContext | undefined>(undefined);

const MAX_DOC_COUNT = 9;
const MAX_IMAGE_COUNT = 8;
export const MAX_RECORDING_SECONDS = 120;

export const ChatInputProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation();
  const { launchImageSelection, launchFileSelection } = useFilePicker();
  const { Provider } = chatInputContext;
  const { activeChat } = useActiveChat();
  const [chatInputs, setChatInputs] = useState<ChatInputWithChatId[]>([]);
  const [isTagModalOpen, setIsTagModalOpen] = useState<boolean>(false);
  const [existingTagsCollection, setExistingTagsCollection] =
    useState<TagsCollectionType[]>();
  const [filterVal, setFilterVal] = useState<string>('');
  const { me } = useMe();
  const [selectedMessage, setSelectedMessage] = useState<Message>();
  const [selectedChat, setSelectedChat] = useState<Message>();
  const [selectedReplyMessage, setSelectedReplyMessage] = useState<Message>();
  const [focus, setFocus] = useState<boolean>(false);
  const [modalHeight, setModalHeight] = useState<number>(0);
  const [messageModalHeight, setMessageModalHeight] = useState<number>(0);
  const [shouldShowTutorialHashTag, setShouldShowTutorialHashTag] =
    useState<boolean>(false);
  const [doNotShowTutorial, setDoNotShowTutorial] = useState<boolean>(false);
  const [showChatOptionsBar, setShowChatOptionsBar] = useState<boolean>(false);
  const navigation = useAppNavigation();
  const [playingVoiceMemoId, setPlayingVoiceMemoId] = useState<''>();
  const [hasCameraPermission, setHasCameraPermission] =
    useState<boolean>(false);
  const client = useApolloClient();

  const initialChatInput = {
    message: '',
    tagsCollection: [],
    localFiles: [],
    replyMessage: undefined,
  };

  const currentChatInput = () => {
    return (
      chatInputs?.find((i) => i.activeChatId === activeChat?.id)?.chatInput ||
      initialChatInput
    );
  };

  const findChatInput = (chatId: Chat['id']) => {
    return (
      chatInputs?.find((i) => i.activeChatId === chatId)?.chatInput ||
      initialChatInput
    );
  };

  const createDraftInput = () => {
    // if no draft exists, create one
    if (!chatInputs.find((i) => i.activeChatId === activeChat?.id)) {
      setChatInputs((chats) => [
        ...chats,
        {
          activeChatId: activeChat?.id || '',
          chatInput: {
            ...initialChatInput,
          },
        },
      ]);
    }
  };

  const updateChatInputs = (newChatInput: ChatInputItem) => {
    const index = chatInputs.findIndex(
      (x) => x.activeChatId === activeChat?.id
    );
    if (index) {
      setChatInputs((curr) => [
        ...curr.slice(0, index),
        Object.assign({}, curr[index], newChatInput),
        ...curr.slice(index + 1),
      ]);
    }
  };

  const updateChatInputsById = (
    chatId: Chat['id'],
    newChatInput: ChatInputItem
  ) => {
    const index = chatInputs.findIndex((x) => x.activeChatId === chatId);
    if (index) {
      setChatInputs((curr) => [
        ...curr.slice(0, index),
        Object.assign({}, curr[index], newChatInput),
        ...curr.slice(index + 1),
      ]);
    }
  };

  const subscribeToChatUpdates = (): (() => void) => {
    const subscription = client
      .subscribe({ query: ChatUpdatedDocument })
      .subscribe({
        next({ data: subscriptionData }) {
          // Handle the incoming chat update data
          const chatUpdated = subscriptionData.chatUpdated;

          if (!chatUpdated) return;

          const { cache } = client;
          const identity = cache.identify(chatUpdated);

          client.writeFragment({
            id: identity,
            fragment: ChatDetailFragmentDoc,
            fragmentName: 'ChatDetail',
            data: chatUpdated,
          });

          cache.updateQuery({ query: ListChatsDocument }, (data) => {
            if (!data) return;
            const { listChats: listChatsData } = data;

            if (
              listChatsData.some(
                (item: Chat) => cache.identify(item) === identity
              )
            ) {
              return undefined;
            }

            return { listChats: [...listChatsData, chatUpdated] };
          });
        },
        error(err) {
          console.error('Chat updates subscription error:', err);
        },
      });

    // Unsubscribe function
    return () => subscription.unsubscribe();
  };

  const resetModal = () => {
    const { message } = currentChatInput();
    // set each one of these methods by chat id

    // clear last index of filter val - 1
    if (filterVal) {
      setMessage(message.replace(`#${filterVal}`, ''));
    } else {
      setMessage(message.replace(`#`, ''));
    }
    setFilterVal('');
    setIsTagModalOpen(false);
  };

  const updateTagsCollectionProject = (
    chatId: Chat['id'],
    project: ProjectTagType
  ) => {
    const updateInput = findChatInput(chatId);

    // exit if exists from another author
    if (existingTagsCollection?.find((e) => e.project.id === project.id)) {
      return;
    }

    if (
      !updateInput.tagsCollection?.find((t) => t.project.id === project.id) &&
      me
    ) {
      setTagsCollection(chatId, [
        ...(updateInput.tagsCollection ?? []),
        {
          tasks: [],
          project: { ...project },
          author: me,
        },
      ]);
      resetModal();
    }
  };

  const updateTagsCollectionTasks = (chatId: Chat['id'], task: TaskTagType) => {
    const updateInput = findChatInput(chatId);

    // exit if exists from another author
    if (
      existingTagsCollection?.find((e) =>
        e.tasks.find((t) => t.id === task.id) ? 1 : 0
      )
    ) {
      return;
    }

    const collectionToUpdate = updateInput.tagsCollection?.findIndex(
      (t) => t.project.id === task.project.id
    );

    if (collectionToUpdate !== undefined && collectionToUpdate !== -1) {
      // put task into correct coll based on project

      if (
        !updateInput.tagsCollection?.[collectionToUpdate].tasks.find(
          (t) => t.id === task.id
        )
      ) {
        const newTagsColl = [...(updateInput.tagsCollection ?? [])];
        newTagsColl[collectionToUpdate] = {
          ...newTagsColl[collectionToUpdate],
          tasks: [...newTagsColl[collectionToUpdate].tasks, task],
        };

        updateInput.tagsCollection = newTagsColl;
        updateChatInputsById(chatId, updateInput);

        resetModal();
      }
    } else if (me) {
      // new tags coll
      setTagsCollection(chatId, [
        ...(updateInput.tagsCollection ?? []),
        {
          tasks: [task],
          project: task.project,
          author: me,
        },
      ]);
      updateChatInputsById(chatId, updateInput);
      resetModal();
    }
  };

  const removeTagsCollection = (chatId: Chat['id'], indexToDelete: number) => {
    const updateInput = findChatInput(chatId);

    updateInput.tagsCollection = updateInput.tagsCollection?.filter(
      (_i, index) => index !== indexToDelete
    );
    updateChatInputsById(chatId, updateInput);
  };

  const removeTagsCollectionTask = (
    chatId: Chat['id'],
    tagIndex: number,
    collectionIndex: number
  ) => {
    const updateInput = findChatInput(chatId);

    const newTagsColl = [...(updateInput.tagsCollection ?? [])] ?? [];
    const newTasksColl = newTagsColl?.[collectionIndex].tasks.filter(
      (_i, index) => index !== tagIndex
    );
    newTagsColl[collectionIndex].tasks = newTasksColl;

    updateInput.tagsCollection = newTagsColl;

    updateChatInputsById(chatId, updateInput);

    // if lone project && others' have that project, remove project
    if (newTagsColl[collectionIndex].tasks.length === 0) {
      // const projectId = newTagsColl.find((t) => t.project.id)?.project.id;
      const projectId = newTagsColl[collectionIndex].project.id;

      if (
        existingTagsCollection?.find((coll) => coll.project.id === projectId)
      ) {
        removeTagsCollection(chatId, collectionIndex);
      }
    }
  };

  const setMessage = (message: string) => {
    const updateInput = currentChatInput();
    currentChatInput().message = message;
    updateChatInputs(updateInput);
  };

  const setLocalFiles = (chatId: Chat['id'], files: LocalFile[]) => {
    const updateInput = findChatInput(chatId);
    updateInput.localFiles = files;
    updateChatInputsById(chatId, updateInput);
  };

  const setTagsCollection = (
    chatId: Chat['id'],
    tagsCollection: TagsCollectionType[]
  ) => {
    const updateInput = findChatInput(chatId);

    updateInput.tagsCollection = tagsCollection;
    updateChatInputsById(chatId, updateInput);
  };

  const setReplyMessage = (chatId: Chat['id'], message?: Message) => {
    const updateInput = findChatInput(chatId);
    updateInput.replyMessage = message;
    updateChatInputsById(chatId, updateInput);
  };

  const pickChatImages = async (chatId: Chat['id']) => {
    if (findChatInput(chatId).localFiles.some((item) => item.isAudio)) {
      Alert.alert(
        t('shared:alert'),
        t('models:chat.input.mediaConflictAudioImages')
      );
      return;
    }
    if (
      findChatInput(chatId).localFiles.some(
        (item) => !item.isImage && !item.isAudio
      )
    ) {
      Alert.alert(t('shared:alert'), t('models:chat.input.mediaConflictFiles'));
      return;
    }
    const selectableImagesCount =
      MAX_IMAGE_COUNT -
      findChatInput(chatId).localFiles.filter((item) => item.isImage).length;

    if (selectableImagesCount > 0) {
      await launchImageSelection(selectableImagesCount).then(
        (r) =>
          r &&
          setLocalFiles(chatId, [...findChatInput(chatId).localFiles, ...r])
      );
    } else {
      Alert.alert(t('models:chat.input.maxSelection'));
    }
  };
  const pickChatFiles = async (chatId: Chat['id']) => {
    if (findChatInput(chatId).localFiles.some((item) => item.isAudio)) {
      Alert.alert(
        t('shared:alert'),
        t('models:chat.input.mediaConflictAudioFiles')
      );
      return;
    }
    if (findChatInput(chatId).localFiles.some((item) => item.isImage)) {
      Alert.alert(
        t('shared:alert'),
        t('models:chat.input.mediaConflictImages')
      );
      return;
    }
    const selectableDocumentsCount =
      MAX_DOC_COUNT -
      findChatInput(chatId).localFiles.filter(
        (item) => !item.isImage && !item.isAudio
      ).length;
    if (selectableDocumentsCount > 0) {
      await launchFileSelection(selectableDocumentsCount).then((r) => {
        if (!r) return;

        const documents = r.map((item) => {
          return {
            ...item,
            path: '',
            isAudio: false,
            isImage: false,
          };
        });

        setLocalFiles(chatId, [
          ...findChatInput(chatId).localFiles,
          ...documents,
        ]);
      });
    } else {
      Alert.alert(t('models:chat.input.maxSelection'));
    }
  };

  const getCameraPhoto = (chatId: Chat['id'], r: LocalFile[]) => {
    const selectableImagesCount =
      MAX_IMAGE_COUNT -
      findChatInput(chatId).localFiles.filter((item) => item.isImage).length;
    if (selectableImagesCount > 0) {
      r && setLocalFiles(chatId, [...findChatInput(chatId).localFiles, ...r]);
    } else {
      Alert.alert(t('models:chat.input.maxSelection'));
    }
  };

  const loadCameraComponent = (chatId: Chat['id']) => {
    navigation.navigate('expo-camera-photo', {
      chatId,
      onCallback: () => getCameraPhoto(chatId),
      photoCount:
        MAX_IMAGE_COUNT -
        findChatInput(chatId).localFiles.filter((item) => item.isImage).length,
    });
  };

  const launchChatCamera = async (chatId: Chat['id']) => {
    if (findChatInput(chatId).localFiles.some((item) => item.isAudio)) {
      Alert.alert(
        t('shared:alert'),
        t('models:chat.input.mediaConflictAudioImages')
      );
      return;
    }
    if (findChatInput(chatId).localFiles.some((item) => !item.isImage)) {
      Alert.alert(t('shared:alert'), t('models:chat.input.mediaConflictFiles'));
      return;
    }

    loadCameraComponent(chatId);
  };

  useEffect(() => {
    // create a draft on active chat change if there isn't one already
    createDraftInput();
    setFocus(false);
  }, [activeChat?.id]);

  const dropFileHandler = async (chatId: Chat['id'], files: File[]) => {
    const selectableDocumentsCount =
      MAX_DOC_COUNT -
      currentChatInput().localFiles.filter((item) => !item.isImage).length;
    if (files.length <= selectableDocumentsCount) {
      // TODO setFileToLocalFile with duration?
      const newLocalFiles = await Promise.all(
        files.map((file) => fileToLocalFile(file, true))
      );

      setLocalFiles(chatId, [
        ...currentChatInput().localFiles,
        ...newLocalFiles,
      ]);
    } else {
      Alert.alert(t('models:chat.input.maxSelection'));
    }
  };

  return (
    <Provider
      value={
        {
          message: currentChatInput().message,
          setMessage,
          isTagModalOpen,
          setIsTagModalOpen,
          getTagsCollection: (chatId) => findChatInput(chatId).tagsCollection,
          getLocalFiles: (chatId) => findChatInput(chatId).localFiles,
          setTagsCollection,
          updateTagsCollectionTasks,
          updateTagsCollectionProject,
          removeTagsCollection,
          removeTagsCollectionTask,
          filterVal,
          setFilterVal,
          setLocalFiles,
          selectedMessage,
          setSelectedMessage,
          selectedChat,
          setSelectedChat,
          selectedReplyMessage,
          setSelectedReplyMessage,
          getReplyMessage: (chatId) => findChatInput(chatId).replyMessage,
          setReplyMessage,
          existingTagsCollection,
          setExistingTagsCollection,
          pickChatImages,
          pickChatFiles,
          launchChatCamera,
          focus,
          setFocus,
          modalHeight,
          setModalHeight,
          messageModalHeight,
          setMessageModalHeight,
          shouldShowTutorialHashTag,
          setShouldShowTutorialHashTag,
          doNotShowTutorial,
          setDoNotShowTutorial,
          showChatOptionsBar,
          setShowChatOptionsBar,
          dropFileHandler,
          playingVoiceMemoId,
          setPlayingVoiceMemoId,
          subscribeToChatUpdates,
          getCameraPhoto,
          hasCameraPermission,
          setHasCameraPermission,
        } as ChatInputContext
      }>
      {children}
    </Provider>
  );
};

const useChatInput = (): ChatInputContext => {
  const context = useContext(chatInputContext);
  if (context === undefined) {
    throw new Error('useChatInput must be used within a Provider');
  }
  return context;
};

export default useChatInput;
