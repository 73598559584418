import React from 'react';
import { useTranslation } from 'react-i18next';
import { parseValue } from 'react-native-controlled-mentions';

import ChatCard from '@components/Chat/ChatCard.web';
import { Box } from '@components/Restyle';
import GlobalSearchList from '@components/Search/GlobalSearchList';
import {
  ChatDrawerScreen,
  useNavigateToChatDrawer,
} from '@components/Web/Drawer/WebDrawerContext';
import { Chat, Message } from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import { Search } from '@root/types';
import { triggersConfigPlain } from '@utils/mentions';

interface IMessageSearch {
  search: string;
  data: Search<Message>[];
  loading: boolean;
  onPress: () => void;
  sectionFooter?: any;
  showFooter?: boolean;
}
const ChatsSearch: React.FC<IMessageSearch> = ({
  search,
  data,
  loading,
  onPress,
  sectionFooter,
  showFooter,
}) => {
  const { t } = useTranslation('models');
  const navigation = useNavigateToChatDrawer();
  const {
    activeChat,
    setActiveMessageCursor,
    setIsOpenChat,
    setCursorScrollRequested,
  } = useActiveChat();

  return (
    <GlobalSearchList
      loading={loading}
      onPress={() => {}}
      isViewAllContacts={false}
      isChatsSearch
      search={search}
      lists={[
        {
          title: 'Chat history',
          data: data,
          totalCount: data.length,
          render: (item) => {
            const record = item.record as Message;
            const { chat: initialChat, cursor } = record;

            const highlightAuthorName = item?.highlight?.authorName;
            const authorName = record.author.name;

            let messageAuthorName =
              initialChat.name === authorName
                ? ''
                : `${highlightAuthorName || authorName}: `;
            let messageBody = '';
            const message = item?.highlight?.body?.[0] || record?.body;

            const { parts } = parseValue(message || '', [
              triggersConfigPlain.mention,
            ]);
            {
              parts.map((item) => {
                messageBody += item.text;
              });
            }
            const chat: Chat = {
              ...initialChat,
              messageBody: `${
                record.isSender ? t('models:chat.isSender') : record.author.name
              }: ${record.body}`,
              recentActivityAt: record.publishedAt,
              users: [record?.chat?.users],
            };

            if (
              messageBody?.trim() === '' &&
              record.attachments &&
              record.attachments.length > 0
            ) {
              const documents = record.attachments.filter(
                (i) => i.__typename === 'Document'
              );
              if (documents.some((i) => 'isImage' in i && i.isImage)) {
                chat.messageIcon = 'Gallery';
                messageBody = t('chat.labels.photo', {
                  count: documents.length,
                });
              } else {
                chat.messageIcon = 'Paperclip';
                messageBody = t('chat.labels.file', {
                  count: documents.length,
                });
              }
              messageAuthorName = '';
            }
            const highlightedBody = `${messageAuthorName}${messageBody}`;
            return (
              <ChatCard
                messageData={[record]}
                filterVal={search}
                chatCard={chat}
                isEditing={false}
                isFromChatSearch={true}
                highlightedBody={highlightedBody}
                onPress={() => {
                  onPress && onPress();
                  if (chat.id === activeChat?.id) {
                    setCursorScrollRequested(true);
                  }
                  setActiveMessageCursor(undefined);
                  setIsOpenChat(true);
                  navigation({
                    screen: ChatDrawerScreen.details,
                    activeChat: chat,
                    messageCursor: cursor,
                  });
                }}
              />
            );
          },
          itemSeparatorComponent: (
            <Box
              borderBottomWidth={1}
              borderColor='grey01'
              marginHorizontal='m'
            />
          ),
          sectionFooter: showFooter ? sectionFooter : <Box mb='xs'></Box>,
        },
      ]}
    />
  );
};

export default ChatsSearch;
