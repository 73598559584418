import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectModal } from '@components/FormModals/SelectModal';
import usePhases from '@hooks/usePhases';
import { useAppNavigation } from '@navigation/useAppNavigation';

const PhaseSelect = () => {
  const { t } = useTranslation('models');
  const { phases } = usePhases();
  const { setFieldValue, getFieldProps } = useFormikContext();
  const { name: fieldName, value } = getFieldProps('phaseId');
  const navigation = useAppNavigation();

  return (
    <SelectModal
      title='Phase'
      list={phases}
      onClose={navigation.goBack}
      setSelected={(id: any) => {
        setFieldValue(fieldName, id);
        navigation.goBack();
      }}
      value={value}
      singleSelect
      emptyContentMessage={t('resourceNotFound.message', {
        resource: 'phase',
        resourcePlural: 'phases',
      })}
    />
  );
};

export default PhaseSelect;
