import { useNavigation } from '@react-navigation/native';
import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectModalContent } from '@components/FormModals/SelectModal/SelectModalContent';
import { SimpleHeader } from '@components/Headers/SimpleHeader.web';
import { WebModal } from '@components/Modals/WebModal';
import { Box } from '@components/Restyle';
import Button from '@components/shared/Button/Button';

import type { SelectModal as SelectModalNative } from '@components/FormModals/SelectModal';

type SelectModalProps = ComponentProps<typeof SelectModalNative>;

export const SelectModal: React.FC<SelectModalProps> = ({
  title,
  onClose,
  ...rest
}) => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  return (
    <WebModal
      visible
      onClose={() => (onClose ? onClose() : navigation.goBack)}
      width={504}
      height={(rest.list.length && 512) || undefined}>
      <SimpleHeader
        title={title}
        onClose={() => (onClose ? onClose() : navigation.goBack)}
      />
      <SelectModalContent {...rest} />
      <Box padding='m' flexDirection='row' justifyContent='flex-end'>
        <Button
          accessibilityLabel='Done'
          variant='primary'
          onPress={() => (onClose ? onClose() : navigation.goBack)}>
          {t('forms:done')}
        </Button>
      </Box>
    </WebModal>
  );
};
