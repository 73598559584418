import {
  VariantProps,
  createRestyleComponent,
  createVariant,
} from "@shopify/restyle";

import { Theme } from "../../../themes/theme";
import Box from "./Box";
import { ReactChild, ReactChildren } from "react";

const BoxContainer = createRestyleComponent<
  VariantProps<Theme, "shadowBoxVariants"> & React.ComponentProps<typeof Box>,
  Theme
>([createVariant({ themeKey: "shadowBoxVariants" })], Box);

type Props = React.ComponentProps<typeof BoxContainer> & {
  children: null | ReactChild | ReactChildren | ReactChild[];
};

const ShadowBox = ({ children, variant, ...rest }: Props) => {
  return (
    <BoxContainer variant={variant} {...rest}>
      {children && <>{children}</>}
    </BoxContainer>
  );
};

export default ShadowBox;
