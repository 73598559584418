import React, { createContext, useState, useContext, useEffect } from 'react';

import { useCreateMessageReactionMutation } from '@graphql/generated';

type EmojiContext = {
  emoji: string;
  from: string;
  isEmojiPickerOpened: boolean;
  showMessageHighlightModal: boolean;
  messageId: string;
  setEmojiValue: React.Dispatch<React.SetStateAction<string>>;
  setFromValue: React.Dispatch<React.SetStateAction<string>>;
  toggleEmojiPickerOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMessageHighlightModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageId: React.Dispatch<React.SetStateAction<string>>;
};

const emojiContext = createContext<EmojiContext | undefined>(undefined);

export const EmojiProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { Provider } = emojiContext;
  const [emoji, setEmojiValue] = useState('');
  const [from, setFromValue] = useState('');
  const [isEmojiPickerOpened, toggleEmojiPickerOpened] = useState(false);
  const [showMessageHighlightModal, setShowMessageHighlightModal] =
    useState(false);
  const [messageId, setMessageId] = useState('');

  const [createMessageReactionMutation] = useCreateMessageReactionMutation({
    onCompleted: () => {},
    onError: (err) => {
      console.log('err', err);
    },
  });

  useEffect(() => {
    if (emoji != '') {
      if (from == 'MessageReaction') {
        createMessageReactionMutation({
          variables: {
            messageId: messageId,
            reaction: emoji,
          },
          onCompleted() {
            setEmojiValue('');
          },
        });
      }
    }
  }, [emoji, messageId]);

  return (
    <Provider
      value={{
        emoji,
        from,
        isEmojiPickerOpened,
        messageId,
        setEmojiValue,
        setFromValue,
        toggleEmojiPickerOpened,
        showMessageHighlightModal,
        setShowMessageHighlightModal,
        setMessageId,
      }}>
      {children}
    </Provider>
  );
};

const useEmoji = (): EmojiContext => {
  const context = useContext(emojiContext);
  if (context === undefined) {
    throw new Error('useIsFilesSelected must be used within a Provider');
  }

  return context;
};

export default useEmoji;
