import { useApolloClient } from '@hooks/useApolloClient';
import { getMessageFromCache } from '@utils/cache/getMessageFromCache';

export const useGetMessageFromCache = (
  cacheIdentifier: string | null | undefined
) => {
  const { client } = useApolloClient();

  if (!cacheIdentifier || !client) return { message: null };

  return getMessageFromCache(client, cacheIdentifier);
};
