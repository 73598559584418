import { useNavigation } from '@react-navigation/native';
import * as FileSystem from 'expo-file-system';
import cloneDeep from 'lodash.clonedeep';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, FlatList, Platform } from 'react-native';
import uuid from 'react-native-uuid';

import { ModalHeader } from '@components/Headers/ModalHeader';
import Contact, { ContactType } from '@components/Invite/Contact';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { ChatDrawerScreen } from '@components/Web/Drawer/WebDrawerContext';
import {
  Document,
  DraftMessageAttributes,
  GetDraftMessageDocument,
  GetDraftMessageQuery,
  LocalFile,
  Message,
  MessageProject,
  MessageTask,
  Project,
  Task,
  useGetTaskQuery,
  useListChatIdentifiersQuery,
  useUpdateDraftMessageMutation,
} from '@graphql/generated';
import { useApolloClient } from '@hooks/useApolloClient';
import useMe from '@hooks/useMe';
import useSendMessage from '@hooks/useSendMessage';
import { useAppNavigation } from '@navigation/useAppNavigation';
import {
  isDocumentAttachment,
  isProjectAttachment,
  isTaskAttachment,
} from '@utils/tagCollectionService';

interface ForwardMessageContentProps {
  messageData:
    | Message
    | { message: null }
    | null
    | undefined
    | {
        attachments: Document[];
        body: string;
        isFromProjectMedia: boolean;
      };
  onCancelPress?: () => void;
  onForwardCompleted: () => void;
  taskId?: string;
}

let listChatsToContactType: ContactType[];

export const ForwardMessageContent: React.FC<ForwardMessageContentProps> = ({
  messageData,
  onCancelPress,
  onForwardCompleted,
  taskId,
}) => {
  const navigation = useNavigation();
  const { navigateToChatDrawer } = useAppNavigation();
  const [filteredChats, setFilteredChats] = useState<ContactType[]>();
  const { t } = useTranslation('shared');
  const [search, setSearch] = useState();
  const { client } = useApolloClient();
  const [updateDraftMessage] = useUpdateDraftMessageMutation();
  const { loading } = useListChatIdentifiersQuery({
    onCompleted: ({ listChats }) => {
      listChatsToContactType = listChats.map((c) => {
        return {
          displayName: c.name
            ? c.name
            : t('myDisplayName', { displayName: me?.name }),
          id: c.id,
          initials: c.name.charAt(0),
          avatar: c.avatar,
        };
      });
      setFilteredChats(listChatsToContactType);
    },
  });

  const { me } = useMe();
  const { sendDraftMessage } = useSendMessage();

  const { data: taskData } = useGetTaskQuery({
    variables: {
      id: taskId,
    },
    skip: !taskId,
  });

  const { getTask: task } = taskData || {};

  const downloadImage = async (uri: string) => {
    const filename = uri.split('/').pop();
    const localUri = `${FileSystem.cacheDirectory}${filename}`;
    await FileSystem.downloadAsync(uri, localUri);
    return localUri;
  };

  const getBase64ImageFromUrl = async (imageUrl: string) => {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  };

  const convertToLocalFileDocument = async (
    attachments: [Document],
    draftMessageId: string
  ) => {
    const newArray = new Array<Document>();
    await Promise.all(
      attachments.map(async (xdoc1: any) => {
        const doc1 = xdoc1 as Document;
        const url = doc1.file.url
          ? doc1.file.url
          : doc1.file.cdnBaseUrl + doc1.file.path;
        return Platform.OS === 'web'
          ? getBase64ImageFromUrl(url)
          : downloadImage(url);
      })
    )
      .then((fileurls) => {
        for (let x = 0; x < fileurls.length; x++) {
          const xitem = attachments[x] as Document;
          const copyItem = cloneDeep(xitem);
          copyItem.file.url = fileurls[x];
          newArray.push(copyItem);
        }
      })
      .catch((err) => {
        console.log('error when forward file to chat', err);
        Alert.alert(
          'Error happened when get file to forward to chat, Please try again.'
        );
      });

    return newArray.map((lf) => {
      const xclientId = uuid.v4().toString();

      const temp = {
        __typename: 'LocalFile',
        id: xclientId,
        name: lf.name,
        size: lf.size,
        clientId: xclientId,
        url: lf.file.url,
        cdnBaseUrl: '',
        path: '',
        contentType: lf.contentType,
        isImage: lf.isImage,
        isAudio: lf.isAudio,
        duration: lf.duration,
      };

      return {
        __typename: 'Document',
        id: xclientId,
        messageClientId: draftMessageId,
        name: temp.name,
        clientId: xclientId,
        contentType: lf.contentType,
        isImage: temp.isImage,
        size: temp.size,
        file: {
          ...temp,
          __typename: 'File',
          id: xclientId,
          cdnBaseUrl: '',
          url: temp.url,
        },
        isAudio: temp.isAudio,
        duration: temp.duration,
      };
    });
  };

  const forwardMessageToChat = async (selectedChat: ContactType) => {
    // make call to forward messageData

    if (!selectedChat || !me || !messageData) return false;

    const {
      attachments,
      body = '',
      isFromProjectMedia = false,
      isFromImagePreview = false,
    } = messageData;

    const draftMessageId = uuid.v4().toString();

    if (isFromImagePreview) {
      const draftMessage = {
        __typename: 'Message' as const,
        id: draftMessageId,
        chatId: selectedChat.id,
        authorId: me.id,
        clientId: draftMessageId,
        body,
        attachments: [
          ...((await convertToLocalFileDocument(attachments, draftMessageId)) ||
            []),
        ],
        author: me,
        createdAt: new Date().toISOString(),
        publishedAt: false,
        publish: false,
        isSender: true,
        chat: {
          ...selectedChat,
        },
        replyId: null,
        replyMessage: null,
        isDraft: true,
      };

      sendDraftMessage({ ...draftMessage });

      {
        Platform.OS === 'web'
          ? onForwardCompleted()
          : navigateToChatDrawer({
              screen: ChatDrawerScreen.details,
              chatId: selectedChat.id,
            });
      }
    } else if (isFromProjectMedia) {
      const params1 = {
        chatId: selectedChat.id,
        body: '',
        tagsCollection: [
          {
            tasks: [task],
            project: task?.project,
            author: me,
          },
        ],
        localFiles: [],
        replyMessage: null,
      };

      updateDraftMessage({
        variables: { chatId: selectedChat.id, attributes: params1 },
        onCompleted: () => {
          const toLocalFiles = attachments.map((doc: Document) => {
            const {
              file,
              clientId,
              contentType,
              duration,
              id,
              isAudio,
              isImage,
              name,
              size,
            } = doc;

            const result: LocalFile = {
              __typename: 'LocalFile',
              id,
              name,
              size,
              clientId,
              url: file.url,
              cdnBaseUrl: file.cdnBaseUrl,
              path: file.path,
              contentType: contentType!,
              isImage,
              duration: duration ?? null,
              isAudio: isAudio ?? false,
            };
            return result;
          });
          const { getDraftMessage: draftMessage } =
            client?.cache?.readQuery<GetDraftMessageQuery>({
              query: GetDraftMessageDocument,
              variables: {
                chatId: selectedChat.id,
              },
            }) || {
              getDraftMessage: {
                chatId: selectedChat.id,
                body,
                localFiles: [],
              },
            };

          const params: DraftMessageAttributes = {
            ...draftMessage,
            body: '',
            localFiles: [...(draftMessage?.localFiles ?? []), ...toLocalFiles],
            tagsCollection: [
              {
                tasks: [task],
                project: task?.project,
                author: me,
              },
            ],
            replyMessage: null,
          };

          updateDraftMessage({
            variables: { chatId: selectedChat.id, attributes: params },
            onCompleted: () => {
              Platform.OS === 'web'
                ? onForwardCompleted()
                : navigateToChatDrawer({
                    screen: ChatDrawerScreen.details,
                    chatId: selectedChat.id,
                  });
            },
            onError: (e) => console.log('err updateDraftMessage', e),
          });
        },
      });
    } else {
      const messageProjects = attachments?.filter(isProjectAttachment);
      const messageTasks = attachments?.filter(isTaskAttachment);
      const messageDocs = attachments?.filter(isDocumentAttachment);
      const projects = messageProjects?.map(
        (messageproject) => messageproject.project
      );
      const tasks = messageTasks?.map((messageTask) => messageTask.task);
      const projectIds = messageProjects?.map(
        (messageProject) => messageProject?.project?.id
      );
      const taskIds = messageTasks?.map((messageTask) => messageTask?.task?.id);

      const chatMessageTasks = tasks.map(
        (item: Task, index): MessageTask => ({
          __typename: 'MessageTask',
          id: `${draftMessageId}-${index}`,
          authorId: me.id,
          author: me,
          taskId: item.id,
          task: item,
        })
      );

      const chatMessageProjects = projects.map(
        (item: Project, index): MessageProject => ({
          __typename: 'MessageProject',
          id: `${draftMessageId}-${index}`,
          authorId: me.id,
          author: me,
          projectId: item.id,
          project: item,
        })
      );

      const chatMessageDocuments = await convertToLocalFileDocument(
        messageDocs,
        draftMessageId
      );

      const draftMessage = {
        __typename: 'Message' as const,
        id: draftMessageId,
        chatId: selectedChat.id,
        forwardingUserId: me.id,
        authorId: me.id,
        clientId: draftMessageId,
        body,
        tasks,
        taskIds,
        projects,
        projectIds,
        attachments: [
          ...(chatMessageProjects || []),
          ...(chatMessageTasks || []),
          ...(messageDocs.length > 0 ? chatMessageDocuments : []),
        ],
        author: me,
        createdAt: new Date().toISOString(),
        publishedAt: false,
        publish: false,
        isSender: true,
        readAt: null,
        readReceipts: null,
        chat: {
          ...selectedChat,
        },
        replyId: null,
        replyMessage: null,
        isDraft: true,
      };

      sendDraftMessage({ ...draftMessage });

      {
        Platform.OS === 'web'
          ? onForwardCompleted()
          : navigateToChatDrawer({
              screen: ChatDrawerScreen.details,
              chatId: selectedChat.id,
            });
      }
    }
  };

  useEffect(() => {
    if (search) {
      setFilteredChats(
        listChatsToContactType.filter((i) =>
          i.displayName.toLowerCase().includes(search.trim().toLowerCase())
        )
      );
    } else {
      setFilteredChats(listChatsToContactType);
    }
  }, [search]);

  const getPT = () => {
    if (loading) {
      return 'xl';
    } else {
      return Platform.OS === 'web' ? 'xs' : 'xxs';
    }
  };
  return (
    <Box overflow='hidden' height='100%' pt={getPT()}>
      <Box marginHorizontal='m'>
        <ModalHeader
          title='Forward'
          onCancel={() =>
            Platform.OS === 'web' ? onCancelPress() : navigation.goBack()
          }
          cancelColor='greenSecondary'
        />
        <Box marginTop='xl' flexDirection='row' alignItems='center'>
          <SearchInput
            value={search}
            onTextChanged={(t: string) => setSearch(t)}
          />
        </Box>
      </Box>
      <Box marginTop='m' backgroundColor='grey01'>
        <Text
          variant='labelEmphasized'
          marginVertical='xs'
          marginHorizontal='m'>
          All chats
        </Text>
      </Box>
      {loading && <ActivityIndicatorLoading />}
      <FlatList
        data={filteredChats}
        ListHeaderComponent={() => <Box marginTop='m' />}
        renderItem={({ item, index }) => {
          return (
            <Contact
              contact={item}
              onPress={() =>
                filteredChats && filteredChats[index]
                  ? forwardMessageToChat(filteredChats[index])
                  : undefined
              }
              filterVal={search}
            />
          );
        }}
        ItemSeparatorComponent={() => <Box marginTop='l' />}
      />
    </Box>
  );
};
