import { TouchableOpacity } from '@gorhom/bottom-sheet';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNetInfo } from '@react-native-community/netinfo';
import { useNavigation } from '@react-navigation/native';
import { Audio } from 'expo-av';
import * as Location from 'expo-location';
import debounce from 'lodash.debounce';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  FlatList,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  useWindowDimensions,
} from 'react-native';
import { useMentions } from 'react-native-controlled-mentions';
import uuid from 'react-native-uuid';

import { Alert } from '@components/Alert';
import ChatImages from '@components/Chat/ChatImages';
import { ChatInputOptionsBar } from '@components/Chat/ChatInputOptionsBar';
import { CheckInCard } from '@components/Chat/CheckInCard';
import DocumentCard from '@components/Chat/DocumentCard';
import MentionSuggestions from '@components/Chat/MentionSuggestions';
import MessageParts from '@components/Chat/MessageParts';
import { ReplyMessage } from '@components/Chat/ReplyMessage';
import TagsCollection from '@components/Chat/TagsCollection';
import { VoiceCard } from '@components/Chat/VoiceCard';
import { VoiceRecord } from '@components/Chat/VoiceRecord';
import { Box, Text } from '@components/Restyle/index';
import Icon from '@components/shared/Icon/Icon';
import {
  Chat,
  DraftMessage,
  GetDraftMessageDocument,
  GetDraftMessageQuery,
  LocalFile,
  Message,
  MessageCheckin,
  useUpdateDraftMessageMutation,
} from '@graphql/generated';
import { useApolloClient } from '@hooks/useApolloClient';
import useChatInput from '@hooks/useChatInput';
import useEmoji from '@hooks/useEmoji';
import { useGetMessageFromCache } from '@hooks/useGetMessageFromCache';
import useMe from '@hooks/useMe';
import useSendMessage from '@hooks/useSendMessage';
import useTagInput from '@hooks/useTagInput';
import {
  chatInputSchema,
  MAX_DOC_COUNT,
  MAX_IMAGE_COUNT,
} from '@schemas/chatInputSchema';
import theme from '@themes/theme';
import { getMessageFromCache } from '@utils/cache/getMessageFromCache';
import { useFilePicker } from '@utils/filePicker';
import { triggersConfig } from '@utils/mentions';
import {
  tagsCollectionToMessageProjects,
  tagsCollectionToMessageTasks,
} from '@utils/tagCollectionService';

const CHAT_INPUT_TEST_ID = 'chat-message-input';

type MyChatFormProps = {
  isGroupChat?: boolean;
  chatInputHeight: number;
  chatId?: Chat['id'];
  chatData?: any;
  toggleTagModalSheet: (value?: boolean) => void;
  onTagChange: (value: string) => void;
  onFormSubmit?: () => void;
  disableInput?: boolean;
  showLeaveMessage?: string;
  showKeyboardFocus?: boolean;
};

export const MyChatForm: React.FC<MyChatFormProps> = memo(
  ({
    isGroupChat,
    chatInputHeight,
    chatId,
    chatData,
    toggleTagModalSheet,
    onTagChange,
    onFormSubmit,
    disableInput,
    showLeaveMessage,
    showKeyboardFocus,
  }) => {
    const navigation = useNavigation();
    const { launchImageSelection, launchFileSelection } = useFilePicker();
    const { t } = useTranslation();
    const dimensions = useWindowDimensions();
    const [scrollEnabled, setScrollEnabled] = useState<boolean>(false);
    const [keyboardShow, setKeyboardShow] = useState(false);
    const [showRecording, setShowRecording] = useState(false);
    const [body, setBody] = useState<string>('');
    const [localFiles, setLocalFiles] = useState<LocalFile[]>([]);
    const { selectedReplyMessage, setSelectedReplyMessage } = useChatInput();
    const [replyMessage, setReplyMessage] = useState<Message | undefined>();
    const { client } = useApolloClient();
    const { me } = useMe();
    const { sendDraftMessage } = useSendMessage();
    const { tags, setTags, tagsLength } = useTagInput();
    const chatInputRef = useRef<TextInput>(null);
    const tagTaskLength = chatId ? JSON.stringify(tags(chatId)) : [];
    const PLACEHOLDER = t('models:chat.typeMessageHint');

    const { setFromValue } = useEmoji();

    const [checkin, setCheckin] = useState<MessageCheckin | undefined>();
    const { isConnected } = useNetInfo();

    const {
      shouldShowTutorialHashTag,
      setShouldShowTutorialHashTag,
      doNotShowTutorial,
      setDoNotShowTutorial,
      setShowChatOptionsBar,
    } = useChatInput();

    useEffect(() => {
      if (selectedReplyMessage) {
        const cacheIdentifier = client?.cache.identify(selectedReplyMessage);
        setReplyMessage({ cacheIdentifier, id: selectedReplyMessage.id });
      }
    }, [selectedReplyMessage]);

    const bodyLastChar = useMemo(() => body.charAt(body.length - 1), [body]);
    const indexBodyFirstChar = useMemo(() => body.lastIndexOf('#'), [body]);

    const tagsCollection = useMemo(() => {
      if (!chatId) return [];

      if (tagsLength > 0 && (bodyLastChar == '#' || indexBodyFirstChar >= 0)) {
        if (body.length <= 1) {
          chatInputRef.current?.clear();
          setBody('');
        } else if (indexBodyFirstChar >= 0 && body.length > 0) {
          setBody((prev) => prev.slice(0, indexBodyFirstChar));
        } else {
          setBody((prev) => prev.slice(0, -1));
        }
        toggleTagModalSheet(false);
      }

      return tags(chatId);
    }, [chatId, tagsLength, tagTaskLength]);

    const isValid = useMemo(() => {
      return chatInputSchema.isValidSync({
        body: body.trimEnd(),
        tagsCollection,
        localFiles,
        checkin,
      });
    }, [body, tagsCollection, localFiles, checkin]);

    const { getChat: chat } = chatData || { getChat: undefined };

    const getDraftMessage = () => {
      const { getDraftMessage: draftMessage } =
        client?.cache?.readQuery<GetDraftMessageQuery>({
          query: GetDraftMessageDocument,
          variables: {
            chatId,
          },
        }) || { getDraftMessage: {} as DraftMessage };

      return draftMessage;
    };

    const openEmojiModal = () => {
      setFromValue('devicechat-input');
      navigation.navigate('emoji-modal', {
        onCallback: (emoji: string) => {
          appendEmoji(emoji);
        },
      });
    };

    const appendEmoji = (emoji: string) => {
      setBody((prevBody) => `${prevBody}${emoji}`);
    };

    const DEBOUNCE = 250;

    const debouncedOnTagChange = useCallback(
      debounce((value: string) => {
        onTagChange?.(value);
      }, DEBOUNCE),
      []
    );

    const debouncedToggleTagModal = useCallback(
      debounce((value: boolean) => {
        toggleTagModalSheet(value);
      }, DEBOUNCE),
      []
    );

    const setMessage = (text: string) => {
      const textLastChar = text.charAt(text.length - 1);
      if (body.includes('#') && !text.includes('#')) {
        toggleTagModalSheet(false);
        onTagChange?.('');
        debouncedToggleTagModal(false);
      }
      if (bodyLastChar != '#' && textLastChar == '#') {
        onTagChange?.('');
        debouncedOnTagChange('');
        debouncedToggleTagModal(true);
      }
      if (text.includes('#') && textLastChar != '#') {
        const tagParts = text.split('#');
        const tag = tagParts[tagParts.length - 1];
        debouncedOnTagChange(tag);
      }

      setBody(text);
    };

    const replyMessageFromCache = useGetMessageFromCache(
      replyMessage?.cacheIdentifier
    );

    const setFiles = (newLocalFiles: LocalFile[]) => {
      if (images.length + newLocalFiles.length > MAX_IMAGE_COUNT) {
        Alert.alert(t('models:chat.input.maxSelection'));
        return;
      }

      const newLocalFilesToSet = newLocalFiles.map((file) => ({
        ...file,
        duration: file.duration || null,
        size: file.size || null,
      }));

      setLocalFiles([...localFiles, ...newLocalFilesToSet]);
    };
    const [updateDraftMessage] = useUpdateDraftMessageMutation();

    const updateDraftMessageCall = () => {
      const replyMessage = replyMessageFromCache || {
        message: null,
      };
      const params = {
        chatId,
        body: body,
        tagsCollection: tagsCollection,
        localFiles: localFiles,
        replyMessage: replyMessage.__typename ? replyMessage : null,
        checkin: checkin,
      };

      updateDraftMessage({
        variables: { chatId, attributes: params },
      });
    };

    useEffect(() => {
      updateDraftMessageCall();
    }, [body, tagsCollection, localFiles, replyMessage, checkin]);

    const removeLocalFile = (file: LocalFile) => {
      const newLocalFiles = localFiles.filter((i) => i.id !== file.id);

      setLocalFiles(newLocalFiles);
    };

    const removeTagsCollection = (indexToDelete: number) => {
      const newTagsCollection = tagsCollection?.filter(
        (_i, index: number) => index !== indexToDelete
      );

      chatId && setTags(chatId, newTagsCollection);
    };

    const removeTagsCollectionTask = (
      tagIndex: number,
      collectionIndex: number
    ) => {
      const newTagsColl = [...(tagsCollection ?? [])] ?? [];
      const newTasksColl = newTagsColl?.[collectionIndex].tasks.filter(
        (_i, index: number) => index !== tagIndex
      );
      newTagsColl[collectionIndex].tasks = newTasksColl;

      chatId && setTags(chatId, newTagsColl);
    };

    const audioMessages = localFiles.filter((item) => item.isAudio);
    const images = localFiles.filter((item) => item.isImage);
    const documents = localFiles.filter(
      (item) => !item.isImage && !item.isAudio
    );

    const { textInputProps, triggers } = useMentions({
      value: body,
      onChange: (t) => {
        setMessage(t);
      },
      triggersConfig,
    });

    const showOptionsBar =
      body ||
      keyboardShow ||
      !!localFiles.length ||
      !!tagsCollection?.length ||
      !!checkin;

    const showInputBox = !showOptionsBar;

    useEffect(() => {
      setShowChatOptionsBar(!!showOptionsBar);
    }, [showOptionsBar]);

    const getCameraPhoto = (r: LocalFile[]) => {
      const selectableImagesCount = MAX_IMAGE_COUNT - images.length;

      if (selectableImagesCount > 0) {
        r && setFiles(r);
      } else {
        Alert.alert(t('models:chat.input.maxSelection'));
      }
    };

    const loadCameraComponent = () => {
      const selectableImagesCount = MAX_IMAGE_COUNT - images.length;
      if (selectableImagesCount > 0) {
        navigation.navigate('expo-camera-photo', {
          onCallback: getCameraPhoto,
          photoCount: selectableImagesCount,
        });
      } else {
        Alert.alert(t('models:chat.input.maxSelection'));
      }
    };

    const launchChatCamera = async () => {
      if (localFiles.some((item) => item.isAudio)) {
        Alert.alert(
          t('shared:alert'),
          t('models:chat.input.mediaConflictAudioImages')
        );
        return;
      }
      if (localFiles.some((item) => !item.isImage)) {
        Alert.alert(
          t('shared:alert'),
          t('models:chat.input.mediaConflictFiles')
        );
        return;
      }

      loadCameraComponent();
    };

    const pickChatImages = async () => {
      setDoNotShowTutorial(true);
      if (localFiles.some((item) => item.isAudio)) {
        Alert.alert(
          t('shared:alert'),
          t('models:chat.input.mediaConflictAudioImages')
        );
        return;
      }
      if (localFiles.some((item) => !item.isImage && !item.isAudio)) {
        Alert.alert(
          t('shared:alert'),
          t('models:chat.input.mediaConflictFiles')
        );
        return;
      }
      const selectableImagesCount = MAX_IMAGE_COUNT - images.length;

      if (selectableImagesCount > 0) {
        await launchImageSelection(selectableImagesCount).then(
          (r) => r && setFiles(r)
        );
      } else {
        Alert.alert(t('models:chat.input.maxSelection'));
      }
    };

    const pickChatFiles = async () => {
      setDoNotShowTutorial(true);
      if (localFiles.some((item) => item.isAudio)) {
        Alert.alert(
          t('shared:alert'),
          t('models:chat.input.mediaConflictAudioFiles')
        );
        return;
      }
      if (localFiles.some((item) => item.isImage)) {
        Alert.alert(
          t('shared:alert'),
          t('models:chat.input.mediaConflictImages')
        );
        return;
      }
      const selectableDocumentsCount =
        MAX_DOC_COUNT -
        localFiles.filter((item) => !item.isImage && !item.isAudio).length;
      if (selectableDocumentsCount > 0) {
        await launchFileSelection(selectableDocumentsCount).then((r) => {
          if (!r) return;

          const documents = r.map((item) => {
            return {
              ...item,
              path: '',
              isAudio: false,
              isImage: false,
            };
          });
          setFiles(documents);
        });
      } else {
        Alert.alert(t('models:chat.input.maxSelection'));
      }
    };

    const showRecordingBox = async () => {
      if (audioMessages.length > 0) {
        Alert.alert(t('shared:alert'), t('models:chat.input.maxAudio'));
        return;
      }
      if (images.length > 0) {
        Alert.alert(
          t('shared:alert'),
          t('models:chat.input.mediaConflictImagesAudio')
        );
        return;
      }
      if (documents.length > 0) {
        Alert.alert(
          t('shared:alert'),
          t('models:chat.input.mediaConflictFilesAudio')
        );
        return;
      }
      try {
        if (Platform.OS === 'web') {
          setShowRecording(true);
        } else {
          const audioRP = await Audio.requestPermissionsAsync();
          if (audioRP.status === 'granted') {
            setShowRecording(true);
          } else {
            Alert.alert(
              t('shared:allowAudioPermission'),
              t('models:chat.audioPermission')
            );
          }
        }
      } catch (err) {
        // console.error('Requesting permissions', err);
      }
    };
    useEffect(() => {
      if (showKeyboardFocus) {
        setKeyboardShow(true);
      }
    }, [showKeyboardFocus]);
    const onAssignTaskPress = () => {
      navigation.navigate('task-modal', { chatId, isFrom: 'Chat' });
    };

    const onCompleteTaskPress = () => {
      navigation.navigate('task-modal', {
        chatId,
        complete: true,
        isFrom: 'Chat',
      });
    };

    const openChatAttachmentsModal = useCallback(() => {
      navigation.navigate('chat-attachments', {
        onCameraPress: launchChatCamera,
        onImagePress: pickChatImages,
        onDocumentPress: pickChatFiles,
        onAssignTaskPress,
        onCompleteTaskPress,
        onCheckinPress: loadCheckin,
      });
    }, [navigation, chatId, localFiles]);

    const loadCheckin = async () => {
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        Alert.alert(
          t('models:accessDenied.location.title'),
          t('models:accessDenied.location.message'),
          [
            { text: 'Cancel', onPress: () => {} },
            {
              text: 'Settings',
              onPress: () => Linking.openSettings(),
            },
          ]
        );
      } else {
        setCheckin(undefined);
        navigation.navigate('checkin-modal', {
          onCallback: (result: MessageCheckin) => setCheckin(result),
        });
      }
    };

    const resetForm = () => {
      toggleTagModalSheet(false);
      setBody('');
      chatId && setTags(chatId, []);
      setLocalFiles([]);
      setReplyMessage(undefined);
      setSelectedReplyMessage(undefined);
      setCheckin(undefined);
    };

    const showTutorialHashTag = async () => {
      const tutorial = await AsyncStorage.getItem('@tutoralizationHashtag');
      if (tutorial !== '1') {
        return true;
      }
      return false;
    };

    let shouldShowTutorialHashTag1 = false;
    useEffect(() => {
      getTutorialHashTag();
    }, []);

    const getTutorialHashTag = async () => {
      shouldShowTutorialHashTag1 = await showTutorialHashTag();
    };

    const submitForm = async () => {
      chatInputRef.current?.clear();

      if (shouldShowTutorialHashTag1 && !doNotShowTutorial) {
        setShouldShowTutorialHashTag(true);
        return;
      }

      if (!me || !client || !chat) return;
      const { cacheIdentifier, id: replyId } = replyMessage || {
        cacheIdentifier: undefined,
      };
      const replyMessageFromCache = getMessageFromCache(
        client,
        cacheIdentifier
      );

      // NOTE: draftMessageId has multiple purposes:
      // - Default identity in Apollo uses ID and __typename. Assigning a UUID as a draft message ID is sufficiently unique.
      // - Associated objects with a draft message also need an ID. Reusing the same UUID in combination with a different __typename ensures uniqueness.
      //    For example, a draft message may have associated projects, tasks, and/or documents.
      // - Since a draft message may have multiple associated objects of the same type, we append an index value to maintain uniqueness.
      const draftMessageId = uuid.v4().toString();

      // TODO memoize?
      const messageProjects = tagsCollectionToMessageProjects(
        tagsCollection || [],
        draftMessageId,
        me
      );

      const messageTasks = tagsCollectionToMessageTasks(
        tagsCollection || [],
        draftMessageId,
        me
      );

      const documents = (localFiles || [])
        .filter((item) => !!item)
        .map((item: LocalFile) => ({
          __typename: 'Document',
          id: item.clientId,
          name: item.name,
          clientId: item.clientId,
          messageClientId: draftMessageId,
          isImage: item.isImage,
          isAudio: item.isAudio,
          contentType: item.contentType,
          size: item.size,
          duration: item.duration || null,
          file: {
            ...item,
            __typename: 'File',
          },
        }));

      const bodyWithoutHash =
        body && bodyLastChar === '#' ? body.slice(0, -1) : body;

      const params = {
        __typename: 'Message' as const,
        id: draftMessageId,
        chatId,
        clientId: draftMessageId,
        body: bodyWithoutHash,
        authorId: me?.id,
        author: me,
        createdAt: new Date().toISOString(),
        isSender: true,
        isDraft: true,
        publishedAt: false,
        publish: false,
        failedAt: null,
        readAt: null,
        isRetry: !isConnected,
        readReceipts: null,
        chat,
        replyId,
        replyMessage: replyMessageFromCache || undefined,
        attachments: [...messageProjects, ...messageTasks, ...documents],
        checkin: checkin,
      };

      const shouldClear =
        !shouldShowTutorialHashTag || shouldShowTutorialHashTag1;

      if (documents.length > 0 && !isConnected) {
        Alert.alert(
          t('models:chat.netInfoTitle'),
          t('models:chat.notConnectedToInternet')
        );
      } else {
        if (shouldClear) {
          sendDraftMessage(params);
          resetForm();
          if (showKeyboardFocus) {
            setKeyboardShow(true);
          }
          setDoNotShowTutorial(false);
        }
      }
    };

    const setupBodyForTagging = () => {
      setDoNotShowTutorial(true);
      if (!body) {
        setMessage('');
      }
      if (body && bodyLastChar !== '#') {
        setBody((prev) => `${prev}`);
      }

      if (bodyLastChar == '#') {
        setBody(body.slice(0, -1));
        toggleTagModalSheet(false);
        updateDraftMessageCall();
        return;
      }
      updateDraftMessageCall();
      toggleTagModalSheet(true);
    };

    const optionsBar = (
      <ChatInputOptionsBar
        onMicSelect={showRecordingBox}
        onTagPress={setupBodyForTagging}
        onImagePress={pickChatImages}
        onCameraPress={launchChatCamera}
        onDocumentPress={pickChatFiles}
        onEmojiPress={openEmojiModal}
        onSend={() => {
          setBody(body.trimEnd());
          onFormSubmit?.();
          submitForm();
        }}
        onPlusSelect={openChatAttachmentsModal}
        chatId={chatId}
        disableSend={!isValid}
      />
    );

    useEffect(() => {
      const {
        body: draftBody,
        tagsCollection: draftTagsCollection,
        localFiles: draftLocalFiles,
        replyMessage: draftReplyMessage,
        checkin: draftCheckin,
      } = getDraftMessage() || {};
      draftBody && setBody(draftBody);
      draftTagsCollection &&
        draftTagsCollection.length > 0 &&
        chatId &&
        setTags(chatId, draftTagsCollection);
      draftLocalFiles && setFiles(draftLocalFiles);
      draftCheckin && setCheckin(draftCheckin);
      if (draftReplyMessage) {
        const cacheIdentifier = client?.cache.identify(draftReplyMessage);
        setReplyMessage({ cacheIdentifier, id: draftReplyMessage.id });
      }
      return () => {};
    }, []);

    if (!chatId) return null;
    return (
      <Box position='relative'>
        <Box
          style={[
            styles.suggestionBox,
            {
              backgroundColor:
                triggers.mention.keyword !== undefined && keyboardShow
                  ? theme.colors.grey06
                  : theme.colors.white,
              height:
                triggers.mention.keyword !== undefined && keyboardShow
                  ? Dimensions.get('window').height
                  : 0,
              justifyContent:
                triggers.mention.keyword !== undefined && keyboardShow
                  ? 'flex-end'
                  : 'center',
            },
          ]}>
          <Box>
            <MentionSuggestions
              {...triggers.mention}
              isGroupChat={isGroupChat}
              chatInputHeight={chatInputHeight}
              keyboardShow={keyboardShow}
              chatUsers={chat?.users}
            />
          </Box>
        </Box>
        {chat?.isUserBlocked && (
          <Box
            backgroundColor='white'
            borderTopStartRadius='m'
            borderTopEndRadius='m'
            borderTopWidth={1}
            borderRightWidth={1}
            borderLeftWidth={1}
            borderColor='grey02'
            zIndex={2}>
            <Text variant='labelSmall' textAlign='center' paddingVertical='m'>
              {t('models:chat.blockmessage')}
            </Text>
          </Box>
        )}
        {!chat?.isUserBlocked && (
          <Box
            backgroundColor='white'
            borderTopStartRadius='m'
            borderTopEndRadius='m'
            borderTopWidth={1}
            borderRightWidth={1}
            borderLeftWidth={1}
            borderColor='grey02'
            zIndex={2}>
            {!showRecording && (
              <>
                <ScrollView
                  keyboardShouldPersistTaps='always'
                  onContentSizeChange={(_w, h) => setScrollEnabled(h > 500)}
                  scrollEnabled={Platform.OS !== 'web' && scrollEnabled}
                  style={{
                    maxHeight: Platform.OS !== 'web' ? 500 : undefined,
                    marginBottom: theme.spacing.s,
                    ...Platform.select({
                      web: {
                        overflow: 'visible',
                        margin: theme.spacing.m,
                      },
                    }),
                  }}>
                  {replyMessage && replyMessageFromCache && (
                    <Box marginTop='xs' marginHorizontal='m'>
                      <ReplyMessage
                        message={replyMessageFromCache}
                        onDismiss={() => {
                          setReplyMessage(undefined);
                          setSelectedReplyMessage(undefined);
                        }}
                      />
                    </Box>
                  )}

                  {/* ATTACHMENTS */}

                  <ScrollView
                    keyboardShouldPersistTaps='always'
                    scrollEnabled
                    style={{
                      maxHeight: Platform.OS !== 'web' ? 300 : undefined,
                    }}>
                    {/* TAG CLOUD */}
                    {tagsCollection?.map((coll, index) => {
                      return (
                        <Box
                          key={index}
                          marginTop={Platform.OS !== 'web' ? 'xs' : undefined}
                          marginVertical={
                            Platform.OS === 'web' ? 'xs' : undefined
                          }
                          marginHorizontal={
                            Platform.OS !== 'web' ? 'm' : undefined
                          }>
                          <TagsCollection
                            tagsCollection={coll}
                            showAuthor={false}
                            onDeleteProject={() => {
                              tagsCollection[index].tasks.length === 0
                                ? removeTagsCollection(index)
                                : undefined;
                            }}
                            onDelete={(i: number) =>
                              removeTagsCollectionTask(i, index)
                            }
                          />
                        </Box>
                      );
                    })}
                    {/* IMAGES */}
                    {images.length > 0 && (
                      <Box
                        marginVertical={
                          Platform.OS === 'web' ? 'xs' : undefined
                        }>
                        <ChatImages onDelete={removeLocalFile} list={images} />
                      </Box>
                    )}

                    {/* VOICE RECORDINGS */}
                    {audioMessages.length > 0 && (
                      <Box marginHorizontal='m'>
                        <VoiceCard
                          voice={{ ...audioMessages[0] }}
                          onDelete={removeLocalFile}
                        />
                      </Box>
                    )}

                    {/* DOCUMENTS */}
                    {documents.length > 0 && (
                      <FlatList
                        style={{
                          marginVertical:
                            Platform.OS === 'web' ? 'xs' : undefined,
                        }}
                        keyboardShouldPersistTaps='always'
                        data={documents}
                        horizontal={Platform.OS !== 'web'}
                        scrollEnabled={
                          Platform.OS !== 'web' && documents.length > 1
                        }
                        keyExtractor={(item) => item.id}
                        renderItem={({ item }) => (
                          <Box
                            style={{
                              width:
                                Platform.OS !== 'web'
                                  ? dimensions.width - 92
                                  : undefined,
                              marginTop:
                                Platform.OS === 'web'
                                  ? theme.spacing.xxs
                                  : undefined,
                            }}>
                            <DocumentCard
                              document={{ ...item }}
                              onDelete={removeLocalFile}
                            />
                          </Box>
                        )}
                        ItemSeparatorComponent={() => <Box marginRight='xxs' />}
                        ListFooterComponent={() => <Box marginRight='xs' />}
                        ListHeaderComponent={() => <Box marginLeft='m' />}
                      />
                    )}
                  </ScrollView>
                  <Box
                    flexDirection={checkin ? 'column' : 'row'}
                    alignItems={Platform.select({
                      default: 'flex-start',
                      web: 'center',
                    })}
                    {...Platform.select({
                      default: {
                        paddingTop: 's',
                        paddingBottom: 'xs',
                        paddingHorizontal: 'xs',
                      },
                      web: {},
                    })}>
                    {showInputBox && !disableInput && (
                      <Icon
                        name='Plus'
                        variant='l'
                        color='textPrimary'
                        onPress={openChatAttachmentsModal}
                        marginTop='xxxs'
                        accessibilityLabel='Chat Options'
                      />
                    )}

                    {keyboardShow || showKeyboardFocus ? (
                      <TextInput
                        autoFocus
                        autoCorrect={true}
                        placeholderTextColor={theme.colors.textSecondary}
                        placeholder={PLACEHOLDER}
                        multiline
                        style={[styles.input]}
                        accessibilityLabel={PLACEHOLDER}
                        testID={CHAT_INPUT_TEST_ID}
                        {...textInputProps}
                        ref={chatInputRef}
                      />
                    ) : (
                      <>
                        {!disableInput && (
                          <Box flex={1} pointerEvents='auto'>
                            <TouchableOpacity
                              onPress={() => setKeyboardShow(true)}>
                              <Box style={[styles.input]}>
                                <Text
                                  color={body ? 'textPrimary' : 'textSecondary'}
                                  accessibilityLabel={PLACEHOLDER}
                                  testID={CHAT_INPUT_TEST_ID}>
                                  {body ? (
                                    <MessageParts
                                      value={body}
                                      configs={[triggersConfig.mention]}
                                      styleLinks
                                    />
                                  ) : (
                                    PLACEHOLDER
                                  )}
                                </Text>
                              </Box>
                            </TouchableOpacity>
                          </Box>
                        )}
                      </>
                    )}
                    {showInputBox && !disableInput && Platform.OS !== 'web' && (
                      <>
                        <Icon
                          name='Emoji'
                          variant='m'
                          color='textPrimary'
                          marginRight='s'
                          marginTop='xxs'
                          onPress={openEmojiModal}
                        />
                        <Icon
                          name='Mic'
                          variant='l'
                          color='textPrimary'
                          onPress={showRecordingBox}
                          marginTop='xxxs'
                        />
                      </>
                    )}
                    {/* CHECKIN */}
                    {checkin && (
                      <Box width='80%' marginLeft='s' marginTop='m'>
                        <CheckInCard
                          __typename='check in'
                          checkin={checkin}
                          onClose={() => setCheckin(undefined)}
                        />
                      </Box>
                    )}
                  </Box>
                </ScrollView>
              </>
            )}

            {showRecording && (
              <VoiceRecord
                chatId={chatId}
                setShowRecording={(bool: boolean) => setShowRecording(bool)}
                voiceFileHandler={(voice) => setFiles([voice])}
              />
            )}
            {showOptionsBar && !showRecording && (
              <ScrollView
                keyboardShouldPersistTaps='always'
                scrollEnabled={false}>
                <Box marginBottom='m'>{optionsBar}</Box>
              </ScrollView>
            )}
            {showLeaveMessage && (
              <Box marginBottom='m' marginHorizontal='m' alignItems='center'>
                <Text variant='labelSmall' color='onSurfaceSecondary'>
                  {t('shared:noLongerMessage')}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  }
);
const styles = StyleSheet.create({
  input: {
    ...((Platform.OS === 'web' && {
      outlineStyle: 'none',
      maxHeight: 5 * theme.textVariants.body1.lineHeight,
    }) || {
      marginHorizontal: theme.spacing.xs,
      marginTop: -theme.spacing.xxxs,
    }),
    flex: 1,
    paddingVertical: theme.spacing.xxxs,
    textAlignVertical: 'center',
    ...theme.textVariants.body,
    color: theme.colors.textPrimary,
    fontSize: theme.textVariants.body1.fontSize,
    fontFamily: theme.textVariants.body1.fontFamily,
    lineHeight: theme.textVariants.body1.lineHeight,
    paddingBottom: -theme.spacing.m,

    maxHeight: theme.textVariants.body1.lineHeight * 6 + theme.spacing.xxs,
  },
  topShadow: {
    shadowColor: 'black',
    shadowOpacity: Platform.OS === 'android' ? 0.5 : 0.25,
    shadowOffset: { width: 0, height: 25 },
    shadowRadius: 20,
    elevation: 25,
  },
  suggestionBox: {
    position: 'absolute',
    bottom: `100%`,
    left: 0,
    right: 0,
    maxHeight: Dimensions.get('window').height < 700 ? 200 : 750, // for iPhone SE
    shadowColor: Platform.OS === 'android' ? `rgba(0,0,0,0.5)` : 'black',
    shadowOpacity: 0.05,
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 25,
    elevation: 20,
    zIndex: 3,
  },
});
