import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import { useApolloClient } from '@hooks/useApolloClient';

export const NetworkStatusIndicator = () => {
  const { wsConnected } = useApolloClient();
  const [showBanner, setShowBanner] = useState(false);
  const insets = useSafeAreaInsets();

  const setBannerStatus = useCallback(
    debounce((wsConnected) => {
      setShowBanner(!wsConnected);
    }, 3000),
    []
  );

  useEffect(() => {
    setBannerStatus(wsConnected);
  }, [wsConnected]);

  if (!showBanner) return null;

  const onWeb = Platform.OS === 'web';

  return (
    <Box
      position={!onWeb ? 'absolute' : undefined}
      style={{ paddingTop: insets.top }}
      width='100%'
      zIndex={1000}>
      <Box
        backgroundColor='grey03'
        justifyContent='center'
        alignItems='center'
        flexDirection='row'>
        <Text variant='labelEmphasized'>Disconnected</Text>

        {onWeb && (
          <Button variant='text' onPress={() => location.reload()}>
            Click here to refresh
          </Button>
        )}
      </Box>
    </Box>
  );
};
