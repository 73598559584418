import React, { createContext, useContext } from "react";

import { useListPhasesQuery, Phase } from "@graphql/generated";

type PhaseContext = {
  phases: Phase[];
};

const phaseContext = createContext<PhaseContext | undefined>(undefined);

export const PhasesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = phaseContext;
  const { data: phasesData } = useListPhasesQuery({
    fetchPolicy: "cache-only",
  });
  const { listPhases: phases } = phasesData || { listPhases: [] };

  return <Provider value={{ phases }}>{children}</Provider>;
};

const usePhases = (): PhaseContext => {
  const context = useContext(phaseContext);
  if (context === undefined) {
    throw new Error("usePhases must be used within a Provider");
  }
  return context;
};

export default usePhases;
